import {ACTION_CODE} from './_actionCode';
import {ExpenseService, ExpenseReportService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_EXPENSE';

export async function deleteExpense(userData: Record<string, any>, expense: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - deleteExpense - Start');

        const deleteExpenseResult = await ExpenseService.deleteExpense(userData, expense.id);

        LoggerService.logInfo('Action - deleteExpense - End - Success');

        const expenseReport = await ExpenseReportService.getExpenseReportById(userData, expense.report);

        if (expenseReport.expenses && !expenseReport.expenses.length) {
            const deleteExpenseReport = await ExpenseReportService.deleteExpenseReportById(userData, expense.report);

            LoggerService.logInfo(`Action - deleteExpenseReport - End - Success ${deleteExpenseReport}`);

            return ResultModel.newSuccessResult({result: deleteExpenseResult, expense_report: false});
        }

        return ResultModel.newSuccessResult({result: deleteExpenseResult, expense_report: true});

    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteExpense - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'un frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
