/* eslint-disable max-len */
export const realRateValue = (productionLoad: Record<string, any>, key: string, status: string): number => {
    const realRate = productionLoad.billing[key][status].daily_rate * productionLoad.billing[key][status].billed_days / productionLoad.billing[key][status].produced_days

    return realRate
}

export const realRateValueCabinet = (productionLoad: Record<string, any>, status: string) => {
    
    let totalDailyTimesDaysRate = 0;
    let totalProducedDays = 0;

    for (const key in productionLoad.billing) {
        totalDailyTimesDaysRate += productionLoad.billing[key][status].daily_rate * productionLoad.billing[key][status].billed_days

        totalProducedDays += productionLoad.billing[key][status].produced_days
    }

    return totalDailyTimesDaysRate / totalProducedDays
}

export const aggregateCabinetData = (productionLoad: Record<string, any>) => {

    const dasCount = Object.keys(productionLoad.billing).length

    let cabinetJuniorValue = 0;
    let cabinetSeniorValue = 0;
    let cabinetConfirmedValue = 0;

    for (const key in productionLoad.billing) {
        cabinetJuniorValue += productionLoad.billing[key].Junior.daily_rate
        cabinetSeniorValue += productionLoad.billing[key].Senior.daily_rate
        cabinetConfirmedValue += productionLoad.billing[key].Confirmé.daily_rate
    }

    return {
        cabinetJuniorValue: cabinetJuniorValue / dasCount,
        cabinetSeniorValue: cabinetSeniorValue / dasCount,
        cabinetConfirmedValue: cabinetConfirmedValue / dasCount
    }

}

export const aggregateCabinetChartData = (productionLoad: Record<string, any>) => {

    const cabinetChartData = {
        cabinetJuniorValue: 0,
        cabinetSeniorValue: 0,
        cabinetConfirmedValue: 0
    }

    for (const key in productionLoad.billing) {
        cabinetChartData.cabinetJuniorValue += productionLoad.billing[key].Junior.billed_days
        cabinetChartData.cabinetSeniorValue += productionLoad.billing[key].Senior.billed_days
        cabinetChartData.cabinetConfirmedValue += productionLoad.billing[key].Confirmé.billed_days
    }

    cabinetChartData.cabinetJuniorValue = parseInt(cabinetChartData.cabinetJuniorValue.toFixed(2))
    cabinetChartData.cabinetSeniorValue = parseInt(cabinetChartData.cabinetSeniorValue.toFixed(2))
    cabinetChartData.cabinetConfirmedValue = parseInt(cabinetChartData.cabinetConfirmedValue.toFixed(2))

    return cabinetChartData
}