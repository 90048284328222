export const salesActivityDto = {
    from(salesActivityResult) {

        const getStudyingData = () => {
            let dasS = {};

            if (!salesActivityResult.studying) {
                return dasS;
            }
            else {
                for (const key in salesActivityResult.studying) {
                    dasS[key] = {
                        "count": salesActivityResult.studying?.[key]?.count ?? 0
                    }
                }

                return dasS;
            }
        }

        const getRespondingData = () => {
            let dasS = {};

            if (!salesActivityResult.responding) {
                return dasS;
            }
            else {
                for (const key in salesActivityResult.responding) {
                    dasS[key] = {
                        "count": salesActivityResult.responding?.[key]?.count ?? 0
                    }
                }

                return dasS;
            }
        }

        const getWaitingData = () => {
            let dasS = {};

            if (!salesActivityResult.waiting) {
                return dasS;
            }
            else {
                for (const key in salesActivityResult.waiting) {
                    dasS[key] = {
                        "0": {
                            "count": salesActivityResult.waiting?.[key]?.['0']?.count ?? 0,
                            "value": salesActivityResult.waiting?.[key]?.['0']?.value ?? 0,
                            "days": salesActivityResult.waiting?.[key]?.['0']?.days ?? 0
                        },
                        "1": {
                            "count": salesActivityResult.waiting?.[key]?.['1']?.count ?? 0,
                            "value": salesActivityResult.waiting?.[key]?.['1']?.value ?? 0,
                            "days": salesActivityResult.waiting?.[key]?.['1']?.days ?? 0
                        },
                        "2": {
                            "count": salesActivityResult.waiting?.[key]?.['2']?.count ?? 0,
                            "value": salesActivityResult.waiting?.[key]?.['2']?.value ?? 0,
                            "days": salesActivityResult.waiting?.[key]?.['2']?.days ?? 0
                        },
                        "3": {
                            "count": salesActivityResult.waiting?.[key]?.['3']?.count ?? 0,
                            "value": salesActivityResult.waiting?.[key]?.['3']?.value ?? 0,
                            "days": salesActivityResult.waiting?.[key]?.['3']?.days ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "studying": getStudyingData(),
                "responding": getRespondingData(),
                "waiting": getWaitingData()
            }
        );
    }
}
