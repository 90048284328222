import {ExpenseTypeDao} from '../../dao';
import {ErrorModel, ExpenseTypeModel} from '../../models';
import {SERVICE_CODE} from '../expenseService/_serviceCode';

const FUNCTION_CODE = 'GET_ALL_EXPENSE_TYPES';

export async function getAllExpenseTypes(
    userData: Record<string, string>,
    params: string,
    page: number
) {
    try {
        const expenses = await ExpenseTypeDao.getAllExpenseTypes(userData.userToken, params, page);

        const expenseParsed = expenses.results.map((expenseType: Record<string, any>) => ExpenseTypeModel.parseExpenseType(expenseType));

        return expenseParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des types de frais',
            error: error
        });
    }
}
