import {useState} from 'react';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import {exportProductionDataToCsv} from '../../functions/exportDataToCsv';

const ProductionsTable = ({data, tableConfig, handleButtonClick, loading, datePickerValue, missionStatus, cannotBeModified }) => {
    const [csvExportLoading, setCsvExportLoading] = useState(false);
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });

    const handleExportProductionDataToCsv = () => exportProductionDataToCsv(
        data,
        setCsvProps,
        setCsvExportLoading,
        datePickerValue
    )
    
    return (
        <ContainerCard
            title='Temps'
            buttonContent='Nouveau temps'
            margin='my-6'
            onButtonClick={handleButtonClick}
            downloadButtonDisplayed={(data.length > 0 && !loading)}
            onDownloadButtonClick={handleExportProductionDataToCsv}
            coloredButtonDisplayed={missionStatus < 2 && !cannotBeModified}
            csvProps={csvProps}
            csvExportLoading={csvExportLoading}
        >
            <Table
                data={data}
                tableConfig={tableConfig}
                height='h-[400px]'
                loading={loading}
            />
        </ContainerCard>
    )
}

export default ProductionsTable;
