import {ACTION_CODE} from './_actionCode';
import {OfficeService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_OFFICES';

export async function getAllOffices(userData: Record<string, string>, params: string) {
    try {
        LoggerService.logInfo('Action - getAllOffices - Start');

        const getAllOfficesResult = await OfficeService.getAllOffices(userData, params);

        LoggerService.logInfo('Action - getAllOffices - Success');

        return ResultModel.newSuccessResult(getAllOfficesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllOffices - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération des bureaux',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
