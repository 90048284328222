import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {frenchFormat} from '../../../../utils';
import ActionsButton from '../../../../components/ui/ActionsButton';
import pdfIcon from '../../../../assets/icons/page/formation-pdf-icon.png';
import emptyFileIcon from '../../../../assets/icons/page/empty-file-icon.png';

export const traininAttendancesTableConfig = (
    handleEditTrainingAttendance,
    handleDeleteTrainingAttendance,
    buttonsDisabled,
    deleteDateLoading,
    handleClickFile,
    cannotBeModified
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Date de formation',
                    value: 'date',
                    width: 'w-[24%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Durée',
                    value: 'duration',
                    width: 'w-[17%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.duration}{' '}h
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Nombre de stagiaires',
                    value: 'students',
                    width: 'w-[17%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.students}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Feuilles d’émargement',
                    value: null,
                    width: 'w-[20%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.file ? (
                                    <div className='cursor-pointer' onClick={() => handleClickFile(item.file?.file)} target="_blank" rel="noreferrer">
                                        <img src={pdfIcon} alt="pdf icon" className="w-5"/>
                                    </div>
                                ) : (
                                    <img src={emptyFileIcon} alt="empty file icon" className="w-5"/>
                                )}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[12%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.parentStatus === 3 || cannotBeModified ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </> :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => handleEditTrainingAttendance(item)}
                                                disabled={buttonsDisabled}
                                                blueStyle={true}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteDateLoading === item.id}
                                                onClick={() => handleDeleteTrainingAttendance(item.id)}
                                                disabled={buttonsDisabled}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce suivi de formation ?'
                                                blueStyle={true}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
