import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {createBrowserHistory} from '@remix-run/router';

import Layout from '../../components/layout/Layout';
import {useSelector} from 'react-redux';
import {InvoiceActions} from '../../actions';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import Button from '../../components/ui/Button/Button';
import plus from '../../assets/icons/button/plus.png';
import InvoiceBalance from './components/InvoiceBalance';
import TabNavigator from '../../components/ui/navigation/TabNavigator';
// import SearchInput from '../../components/ui/Inputs/SearchInput';
import CreateNewInvoicePopup from './components/CreateInvoicePopup/CreateNewInvoicePopup';
//import {firstDayMonth, lastDayMonth} from '../../utils';
import {invoicesConfig} from './configs/invoicesConfig';

function InvoicesPage() {

    const navigate = useNavigate();

    /* -- States -- */

    //general states
    const userData = useSelector((state) => state.UserReducer.userProfileData);
    const [displayedSection, setDisplayedSection] = useState('Factures à émettre');
    //const [searchTerm, setSearchTerm] = useState("");
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    // Table states
    const [invoicesDataTable, setInvoicesDataTable] = useState([]);
    // const [datePickerDate, setDatePickerDate] = useState({
    //     startDate: firstDayMonth(new Date()),
    //     endDate: lastDayMonth(new Date())
    // });
    const [loadingTable, setLoadingTable] = useState(false);

    // Pagination states
    const [invoicesPaginationMetadata, setInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    // Filter states
    const [filterInvoicesQuery, setFilterInvoicesQuery] = useState('');

    // Popups states
    const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
    const [invoiceToBeUpdated, setInvoiceToBeUpdated] = useState(null);

    // Hooks
    const location = useLocation();
    const history = createBrowserHistory();

    if (userData.role === 'CO') {
        navigate('/deals');
    }

    /* -- Effects -- */
    // Check navigation message existence
    useEffect(() => {
        if (location.state === 'invalid_id') {
            notify('Facture inexistante');
        }
        else if (location.state === 'unavailable_id') {
            notify('Facture indisponible');
        }
        else if (location.state === 'sent_for_validation') {
            notify('Envoyée pour validation');
        }
        else if (location.state === 'invoice_deleted') {
            notify('Facture supprimée');
        }
        history.replace({...history.location, state: null});
    }, []);

    const handleGetAllInvoices = async (page, activeLoading = true) => {
        if(activeLoading) {
            setLoadingTable(true);
            setInvoicesDataTable([]);
        }
        // const datePickerBefore = standardFormat(new Date(datePickerDate.endDate));
        // const datePickerAfter = standardFormat(new Date(datePickerDate.startDate));

        const status = displayedSection === 'Factures à émettre' ? 50 : 100;
        // const optionalProps = `?billing_before=${datePickerBefore}&billing_after=${datePickerAfter}&search=${searchTerm}&status=${status}`;

        const result = await InvoiceActions.getAllInvoices(userData,
            `&status=${status}&page_size=${invoicesPaginationMetadata.pageSize}&${filterInvoicesQuery}`, page);

        if (result.success) {
            setInvoicesDataTable(result.data);
            setInvoicesPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (invoicesPaginationMetadata.totalItems !== result.metadata.count) {
                setInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des factures');
        }

        setLoadingTable(false);
    };

    useEffect(() => {
        handleGetAllInvoices(1);
    //}, [searchTerm, datePickerDate, displayedSection])
    }, [displayedSection])

    useEffect(() => {
        !isInvoicePopupOpen && setInvoiceToBeUpdated(null);
    }, [isInvoicePopupOpen])

    useEffect(() => {
        handleGetAllInvoices(1);
    }, [filterInvoicesQuery])

    /* -- Handler Functions -- */
    const refreshData = async (removal) => {
        const checkedPage = (invoicesDataTable.length === 1 && invoicesPaginationMetadata.currentPage > 1 && removal) ?
            invoicesPaginationMetadata.currentPage - 1 :
            invoicesPaginationMetadata.currentPage;

        await handleGetAllInvoices(checkedPage, false);
    };

    // const handleDatePickerChange = (e) => {
    //     setDatePickerDate({
    //         startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
    //         endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    //     });
    // };

    const handleDeleteInvoice = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);

        const result = await InvoiceActions.deleteInvoiceById(userData, item.id);

        if (result.success) {
            await refreshData(true);
            notify('Votre frais à bien été supprimé');
        }
        else {
            notify('Votre frais n\'a pas pu être supprimé');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    const handleSendInvoice = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);

        const result = await InvoiceActions.updateInvoiceById(userData, item.id, {status: 200});

        if (result.success) {
            await refreshData(true);
            notify('Votre facture à bien été émise');
        }
        else {
            notify('Votre facture n\'a pas pu être émise');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
        setIsInvoicePopupOpen(false);
    };

    const handleSendForValidation = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);

        const result = await InvoiceActions.updateInvoiceById(userData, item.id, {status: 100});

        if (result.success) {
            await refreshData(true);
            notify('Votre facture a été envoyée pour validation');
        }
        else {
            notify('Votre facture n\'a pas pu être envoyée pour validation');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
        setIsInvoicePopupOpen(false);
    };

    const filterInvoiceList = async (query) => {
        setFilterInvoicesQuery(query);
    }

    /* -- Configs -- */
    const invoicesConfigReturn = invoicesConfig(
        handleDeleteInvoice,
        handleSendInvoice,
        setIsInvoicePopupOpen,
        setInvoiceToBeUpdated,
        actionButtonLoading,
        actionButtonDisabled,
        displayedSection,
        filterInvoiceList,
        userData
    );

    return (
        <Layout>
            <Toast/>
            <div className="px-20 pt-10 pb-20">
                <TabNavigator
                    displayedSection={displayedSection}
                    setDisplayedSection={setDisplayedSection}
                    sections={
                        [{name:"Factures à émettre"}, {name: "Factures à valider"}]
                    }
                />
                <div className="flex items-center justify-between mt-12 mb-6">
                    <p className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>
                            Factures
                        <span className="text-xs ml-1 text-[#646464]">
                                ({invoicesPaginationMetadata.totalItems})
                        </span>
                    </p>
                    <Button
                        content={"Nouvelle facture"}
                        icon={plus}
                        onClick={() => setIsInvoicePopupOpen(true)}
                    />
                </div>
                {/* <SearchInput
                    style="mt-4 mb-4"
                    placeholder="Rechercher une facture"
                    value={searchTerm}
                    setValue={setSearchTerm}
                /> */}
                <InvoiceBalance
                    // datePickerValue={datePickerDate}
                    // onDatePickerChange={handleDatePickerChange}
                    tableData={invoicesDataTable}
                    tableLoader={loadingTable}
                    invoicesConfig={invoicesConfigReturn}
                    invoicesPaginationMetadata={invoicesPaginationMetadata}
                    handleGetAllInvoices={handleGetAllInvoices}
                />
                <CreateNewInvoicePopup
                    handleSendForValidation={handleSendForValidation}
                    handleSendInvoice={handleSendInvoice}
                    refreshData={refreshData}
                    userData={userData}
                    view={isInvoicePopupOpen}
                    setView={setIsInvoicePopupOpen}
                    invoiceToBeUpdated={invoiceToBeUpdated}
                />
            </div>
        </Layout>
    );
}

export default InvoicesPage;
