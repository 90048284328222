import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {projectsConfig} from '../../../configs/projectsConfig/projectsConfig';
import Table from '../../../../../components/ui/Table/Table';
import Button  from '../../../../../components/ui/Button/Button';
import Popup from '../../../../../components/ui/Popup/Popup';
import {notify} from '../../../../../components/ui/Toast/Toast';
import {ProjectsActions} from '../../../../../actions';
import CreateNewProject from './CreateNewProject';

function ProjectsSection({
    data,
    loadingTable,
    fetchAllProjects,
    refreshProjectsData,
    projectsPaginationMetadata
}) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [toBeUpdated, setToBeUpdated] = useState(null);
    const [actionButtonLoading, setActionButtonLoading] = useState(null);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDeleteProject = async (id) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(id);
        
        const result = await ProjectsActions.deleteProject(userData.userToken, id);

        if (result.success) {
            await refreshProjectsData(true);
            notify(`Le projet a bien été supprimé`);
        }
        else {
            notify(`Le projet n'a pas pu être supprimé`);
        }
        
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    }

    const projectsConfigInit =  projectsConfig(
        handleDeleteProject,
        setIsPopupOpen,
        setToBeUpdated,
        actionButtonLoading,
        actionButtonDisabled,
    );

    return (
        <div className="w-full px-20 pt-4">
            <div className="flex justify-end py-4">
                <Button
                    content={`Ajouter un Projet`}
                    onClick={() => {
                        setToBeUpdated(null);
                        setIsPopupOpen(true)
                    }}
                />
            </div>
            <Table
                data={data}
                tableConfig={projectsConfigInit}
                loading={loadingTable}
                paginationMetadata={projectsPaginationMetadata}
                onPageNumberChange={fetchAllProjects}
            />
            <Popup
                view={isPopupOpen}
                setView={setIsPopupOpen}
                width={'1150px'}
                yOverflow=''
                xOverflow=''
            >
                <CreateNewProject
                    userData={userData}
                    toBeUpdated={toBeUpdated}
                    refreshData={refreshProjectsData}
                    setView={setIsPopupOpen}
                />
            </Popup>
        </div>
    )
}

export default ProjectsSection;
