import {Link} from 'react-router-dom';

import PendingProposals from "../../../components/commercialTab/PendingProposals";
import {aggregateSalesActivity, pieChartData, dasCount, dasDays, dasValue} from "../../../functions/aggregateSalesActivity"

export const pendingProposalsTabConfig = (salesActivityWaiting) => {

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <Link to="proposals-pending">
            <PendingProposals
                data={aggregateSalesActivity(salesActivityWaiting, "cabinetPieChartData")}
                count={aggregateSalesActivity(salesActivityWaiting, "totalCount")}
                days={aggregateSalesActivity(salesActivityWaiting, "totalDays")}
                euros={aggregateSalesActivity(salesActivityWaiting, "totalValue")}
                salesActivityWaiting={salesActivityWaiting}
            />
        </Link>
    }];
    
    for (const key in salesActivityWaiting) {
        const item = {
            id: key,
            label: key,
            component: <Link to="proposals-pending">
                <PendingProposals
                    data={pieChartData(salesActivityWaiting, key)}
                    count={dasCount(salesActivityWaiting, key)}
                    days={dasDays(salesActivityWaiting, key)}
                    euros={dasValue(salesActivityWaiting, key)}
                    salesActivityWaiting={salesActivityWaiting}
                />
            </Link>
        }
    
        tabs.push(item);
    }
    
    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );

};
