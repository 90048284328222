import {useState, useEffect} from 'react';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {handleFilterList, useFilterQuery, fetchSelectData} from '../../../../utils';
import ActionsButton from '../../../../components/ui/ActionsButton';
import { ContractorActions } from '../../../../actions';
import {SelectInput} from './../../../../components/ui/Table';

export const dealSpendingContractorsTableConfig = (
    buttonsDisabled,
    deleteContractorLoading,
    handleEditSpendingContractor,
    handleDeleteContractor,
    handleInvoicePreview,
    blueStyle,
    filterContractorsList,
    userData,
    cannotBeModified
) => {
    const [contractors, setContractors] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        contractor: ''
    });

    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterContractorsList);
    
    // ↓ get data for select input
    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({action: ContractorActions.getAllContractors, search:`&search=${search}`, page, setter: setContractors, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ contractors dropdown values
        (async () => {
            await handleGetContractors('', 1);
        });
    }, []);

    return {
        dropdown: false,
        dropdownLeftPadding: false,
        greyBackground: false,
        subline: false,
        columnConfig: [
            {
                label: 'Sous-traitants',
                value: 'contractor.name',
                width: 'w-[30%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                            {item.contractor.name}
                        </div>
                    );
                },
                filterComponent: () => <SelectInput
                    name="contractor"
                    options={contractors?.options}
                    hasNextPage={contractors?.hasNextPage}
                    isSearchable={true}
                    onChange={handleFilter}
                    fetchFunction={(handleGetContractors)}
                    labelKeys={['name']}
                    valueKey={'id'}
                    labelWidth='w-40'
                />,
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Montant TTC',
                value: 'amount',
                width: 'w-[15%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {parseFloat(item.amount).toFixed(2)}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Montant HT',
                value: 'gross_amount',
                width: 'w-[15%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {parseFloat(item.gross_amount).toFixed(2)}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'TVA réccupérable',
                value: 'recoverable_vat',
                width: 'w-[15%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+item.recoverable_vat}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Notes',
                value: null,
                width: 'w-[27%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item.notes ? item.notes : '-'}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Actions',
                value: null,
                width: 'w-[25%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {
                                item.file ?
                                    <ActionsButton
                                        type="overview"
                                        onClick={() => handleInvoicePreview(item)}
                                        minWidth={'min-w-[30px]'}
                                        blueStyle={blueStyle}
                                    />
                                    : null
                            }
                            {
                                cannotBeModified ?
                                    <>
                                        <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        <div className="w-0 h-0 px-1"/>
                                        <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                    </>
                                    :
                                    <>
                                        <ActionsButton
                                            type="edit"
                                            isLoading={false}
                                            minWidth={'min-w-[30px]'}
                                            onClick={() => handleEditSpendingContractor(item)}
                                            disabled={buttonsDisabled}
                                            blueStyle={blueStyle}
                                        />
                                        <ActionsButton
                                            type="delete"
                                            minWidth={'min-w-[30px]'}
                                            isLoading={deleteContractorLoading === item.id}
                                            onClick={() => handleDeleteContractor(item.id)}
                                            disabled={buttonsDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer ce sous-traitant ?'
                                            blueStyle={blueStyle}
                                        />
                                    </>
                            }
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            }
        ]
    };
};
