import {ACTION_CODE} from './_actionCode';
import {AttachmentService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_ATTACHMENTS';

export async function getAllAttachments(
    userData: Record<string, string>,
    optionalProps?: Record<string, unknown>
) {
    try {

        LoggerService.logInfo('Action - getAllAttachments - Start');

        const getAllAttachmentsResult = await AttachmentService.getAllAttachments(userData, optionalProps);

        LoggerService.logInfo('Action - getAllAttachments - End - Success');

        return ResultModel.newSuccessResult(getAllAttachmentsResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllAttachments - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des s des documents.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
