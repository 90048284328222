import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ParametersPage from '../pages/parametersPage/ParametersPage';
import ContractorsPage from '../pages/contractorsPage/ContractorsPage';
import ContractorsDealsPage from "../pages/contractorsPage/ContractorsDealsPage"
import CustomersPage from '../pages/customersPage/CustomersPage';
import CustomerDetailsPage from '../pages/customersPage/CustomerDetailsPage';

function MyOrganisationRouter() {
    return (
        <Routes>
            <Route path='/customers' element={<CustomersPage/>}/>
            <Route path='customers/:id/*' element={<CustomerDetailsPage/>}/>
            <Route path='/parameters' element={<ParametersPage/>}/>
            <Route path='/contractors' element={<ContractorsPage/>}/>
            <Route path='/contractors/:id/*' element={<ContractorsDealsPage/>}/>
        </Routes>

    );
}

export default MyOrganisationRouter;
