import {ACTION_CODE} from './_actionCode';
import {DealAbandonmentMotivesDao} from '../../dao';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_DEALLOSSMOTIVE';

export async function deleteDealAbandonmentMotive(userData: Record<string, any>, id: number) {
    try {
        LoggerService.logInfo('Action - deleteDealAbandonmentMotive - Start');

        const deleteDealLossMotiveByIdResult = await DealAbandonmentMotivesDao.deleteDealAbandonmentMotive(userData.userToken, id);

        LoggerService.logInfo('Action - deleteDealAbandonmentMotive - End - Success');

        return ResultModel.newSuccessResult(deleteDealLossMotiveByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteDealAbandonmentMotive - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: "Une erreur est survenue lors de la suppression d'un motif d'abandon",
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
