import {useState} from 'react';

function TextInput({
    onChange,
    setFilename,
    filename,
    value,
    label = "",
    type,
    min,
    max,
    placeholder,
    required,
    readOnly,
    disabled,
    error,
    width = 'w-[250px]',
    labelWidth = 'w-36',
    customStyle = '',
    color = 'orange'
}) {
    const [localValue, setLocalValue] = useState('');

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between w-full">
                {
                    label.length > 0 &&
                        <div className={`flex flex-row ${labelWidth}`}>
                            <label className="font-normal text-sm text-[#646464]">
                                {label}
                                {
                                    required &&
                                        <span className="ml-1 text-sm font-normal text-red-500">*</span>
                                }
                            </label>
                        </div>
                }
                <input
                    onChange={(e) => {
                        onChange(filename ? filename : e.target.value);
                        setLocalValue((type === "number" && e.target.value.length) ? parseInt(e.target.value) : e.target.value);
                        setFilename && setFilename(e.target.value);
                    }}
                    className={`
                        ${customStyle} ${width} h-[31px] border border-solid rounded-sm py-1 px-2 leading-tight focus:outline-none border-[#D9D9D9]
                        ${color === 'orange' ? 'focus:border-[#E36D38] hover:border-[#E36D38]' : 'hover:border-[#19b3cc] focus:border-[#19b3cc]'} 
                        placeholder:text-[#21212140]/25 disabled:bg-[#efefef] text-[#212121]
                    `}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                    min={min}
                    max={max}
                    value={value ? (type === "number" ? (value.toString()).toLocaleString() : value) : (type === "number" ? (localValue.toString()).toLocaleString() : localValue)}
                />
            </div>
            {
                error &&
                <div className="text-xs italic text-red-500">
                    {error.message}
                </div>
            }
        </div>
    );
}

export default TextInput;
