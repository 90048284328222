import React, {useEffect, useState} from 'react';
import {Empty} from 'antd';
import {Controller, useForm} from 'react-hook-form';
// import {CaretDownFilled, CaretUpFilled} from '@ant-design/icons'; //TODO: uncomment if needed
import Tooltip from 'antd/lib/tooltip';
import { Pagination } from 'antd';

import Loader from '../../loaders/Loader';
import plus from './../../../assets/icons/table/plus.png';
import minus from './../../../assets/icons/table/minus.png';
import Button from '../Button';
import { ErrorBoundary } from "react-error-boundary";

import "./table.css";

/**
 * Composant Table
 *
 * @param {Object} props - Les propriétés du composant
 * @param {Array} props.data - Les données à afficher dans le tableau
 * @param {Object} props.tableConfig - La configuration du tableau
 * @param {boolean} [props.loading=false] - Indique si le tableau est en cours de chargement
 * @param {Function} [props.onSaveFunction] - La fonction à appeler lorsque les données sont sauvegardées
 * @param {boolean} [props.showSubmitButtons] - Indique si les boutons de soumission doivent être affichés
 * @param {Function} [props.setShowSubmitButtons] - La fonction pour mettre à jour l'affichage des boutons de soumission
 * @param {boolean} [props.submitButtonLoading] - Indique si les boutons de soumission sont en cours de chargement
 * @param {Function} [props.setSubmitButtonLoading] - La fonction pour mettre à jour l'état de chargement des boutons de soumission
 * @param {boolean} [props.blueStyle=false] - Indique si le tableau doit être affiché avec un style bleu
 * @param {boolean} [props.groupedTable=false] - Indique si le tableau doit être affiché en tant que tableau groupé
 * @param {boolean} [props.isScrollable=false] - Indique si le tableau doit être défilable
 * @param {boolean} [props.showSearchBar=false] - Indique si la barre de recherche doit être affichée
 * @param {string} [props.cellHeight='h-fit'] - La hauteur des cellules du tableau
 * @param {string} [props.width='w-full'] - La largeur du tableau
 * @param {string} [props.position=null] - La position du tableau (gauche, droite, centrer)
 * @returns {JSX.Element} Le composant Table
 */

const Table = ({
    tableConfig,
    dropdownConfig,
    data,
    loading = false,
    customData,
    onSaveFunction,
    showSubmitButtons,
    setShowSubmitButtons = () => null,
    submitButtonLoading,
    setSubmitButtonLoading = () => null,
    blueStyle = false,
    groupedTable = false,
    isScrollable = false,
    showSearchBar = false,
    cellHeight = 'h-fit',
    width = 'w-full',
    position = null,
    containerWidth = 'w-full',
    paginationMetadata = null,
    onPageNumberChange = () => null,
    dropdownChildrenKey = 'children',
    displayEmpty = true
}) => {
    const [dropdownIsOpen, setDropdownIsOpen] = useState({});
    const [lineSubmitFunctions, setLineSubmitFunctions] = useState([]);
    const [dataArray, setDataArray] = useState([]);
    // const [sortedData, setSortedData] = useState(data); // TODO: uncomment to activate sort
    // const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // TODO: uncomment to activate sort
    const [resetValuesTrigger, setResetValuesTrigger] = useState(false);

    const isSearchBarVisible = tableConfig?.columnConfig.find((item) => item.filterComponent) || showSearchBar;
    
    // TODO: uncomment for sort
    // useEffect(() => {
    //     if (sortConfig.direction === null) {
    //         return setSortedData(data);
    //     }

    //     const sortedData = [...data].sort((a, b) => {
    //         const valueA = findValue(a, sortConfig.key);
    //         const valueB = findValue(b, sortConfig.key);

    //         const parseValue = (value) => {
    //             if (value === null) return 0;

    //             if (typeof value === 'boolean') return value ? 1 : 0;

    //             return isNaN(value) ? value : parseFloat(value);
    //         };

    //         const parsedValueA = parseValue(valueA);
    //         const parsedValueB = parseValue(valueB);

    //         if (parsedValueA < parsedValueB) {
    //             return sortConfig.direction === 'ascending' ? -1 : 1;
    //         }
    //         else if (parsedValueA > parsedValueB) {
    //             return sortConfig.direction === 'ascending' ? 1 : -1;
    //         }
    //         else {
    //             return 0;
    //         }
    //     });

    //     setSortedData(sortedData);
    // }, [sortConfig, data]);

    // TODO: uncomment for sort function
    // const handleSort = (key) => {
    //     if (sortConfig.key !== key) {
    //         setSortConfig({ key, direction: 'ascending' });
    //     }
    //     else if (sortConfig.direction === 'ascending') {
    //         setSortConfig({ key, direction: 'descending' });
    //     }
    //     else if (sortConfig.direction === 'descending') {
    //         setSortConfig({ key: null, direction: null });
    //     }
    //     else {
    //         setSortConfig({ key, direction: 'ascending' });
    //     }
    // };

    useEffect(() => {
        if (dataArray.length > 0) {
            onSaveFunction(dataArray);
            setDataArray([]);
        }
    }, [dataArray])

    useEffect(() => {
        if (!tableConfig.dropdown) {
            return;
        }
        const dropdownsState = {};

        for (const dataElement of data) {
            dropdownsState[dataElement.id] = false;
        }
        setDropdownIsOpen(dropdownsState);
    }, []);

    const handleClick = (itemId, event) => {
        if ((tableConfig.dropdown && event.target.id !== 'skip')) setDropdownIsOpen({...dropdownIsOpen, [itemId]: !dropdownIsOpen[itemId]});
    };

    const findValue = (obj, value) => {

        if (value) {
            const splitValue = value.split('.');

            let result = obj[splitValue[0]];

            for(let i = 1; i < splitValue.length; i++) {
                if (result[splitValue[i]] !== undefined) {
                    result = result[splitValue[i]];
                }
            }

            return result;
        }
        else return 0;
    }

    const addToArray = (data, id) => {
        setDataArray(prevState => (
            [...prevState, {...data, id}]
        ))
    }

    useEffect(() => {
        if (!showSubmitButtons) setSubmitButtonLoading(false);
    }, [showSubmitButtons]);
   
    let tableStyle;
    let containerStyle;

    if (position === 'left') {
        tableStyle = 'rounded-l-md overflow-hidden table-shadow-left z-[1]';
        containerStyle = '';
    }
    else if (position === 'right') {
        tableStyle = 'rounded-r-md overflow-hidden table-shadow-right z-[1]';
        containerStyle = '';
    }
    else if (position === 'center') {
        tableStyle = '';
        containerStyle = 'overflow-x-auto';
    }
    else {
        tableStyle = 'rounded-md table-shadow-outter overflow-hidden';
        containerStyle = '';
    }

    return (
        <div className={`${containerWidth} ${containerStyle}`}>
            <table className={`${width} ${tableStyle} relative table-auto text-[#212121]`}>
                <thead>
                    {/* Table title */}
                    <tr className={`${isScrollable ? 'bg-[#f1f3f9]' : 'bg-[#d1d3dc]'}`}>
                        {tableConfig.columnConfig.map((item, index) => {
                            return (
                                <th
                                    key={`${index}__${item.value}__${index}`}
                                    // TODO: Uncomment to activate sort function
                                    // onClick={() => {
                                    //     if (item.sort && !groupedTable) {
                                    //         handleSort(item.value);
                                    //     }
                                    // }}
                                    
                                    // TODO: change cursor to pointer
                                    className={`
                                        h-12 group px-3 condensed-alternate text-[13.5px] text-[#1D2433]
                                        ${item.width} ${(item.sort && !groupedTable) ? 'cursor-default' : 'cursor-default'} 
                                    `}
                                >
                                    <div className={`relative h-full flex flex-row justify-center items-center font-bold`}>
                                        {item?.tooltipLabel ? (
                                            <Tooltip title={item.tooltipLabel} placement="top" color='#212121'>
                                                <p className='select-none'>{item.label}</p>
                                            </Tooltip>
                                        ) : (
                                            <p className='select-none justify-self-center'>{item.label}</p>
                                        )}
                                        
                                        {/* TODO: Uncomment to display sort icon */}
                                        {/* {(item.sort && !groupedTable) && (
                                            <div className={`
                                                absolute group-hover:flex right-0 h-full flex items-center bg-black
                                                ${sortConfig.direction && sortConfig.key === item.value ? 'flex' : 'hidden'}
                                            `}>
                                                <div className='w-[13px]'>
                                                    <div className={`flex flex-col`}>
                                                        <CaretUpFilled
                                                            style={{
                                                                color: (sortConfig.direction === 'ascending' && sortConfig.key === item.value) ? '#e37038' : '#fff',
                                                                height: '11px',
                                                                fontSize: '14px'
                                                            }}
                                                        />
                                                        <CaretDownFilled
                                                            style={{
                                                                color: (sortConfig.direction === 'descending' && sortConfig.key === item.value) ? '#e37038' : '#fff',
                                                                height: '11px',
                                                                fontSize: '16px'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>

                    {isSearchBarVisible && (
                        <tr className={`${isScrollable ? 'bg-[#f1f3f9]' : 'bg-[#d1d3dc]'}`}>
                            {tableConfig.columnConfig.map((item, index) => {
                                return (
                                    <React.Fragment key={`${index}__`}>
                                        {
                                            item.filterComponent ?
                                                <th className='h-10 px-0 pb-3'>
                                                    {item.filterComponent()}
                                                </th> :
                                                <th className='h-10 p-0'/>
                                                
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                    )}
                </thead>

                {
                    !loading && data && data.length > 0 ?
                        <tbody className={`${tableConfig.greyBackground && 'bg-[#fafafa]'}`}>
                            {/* Table sub title that show the sum of the column */}
                            {
                                tableConfig.subline && (
                                    <tr className='border-t-2 border-t-[#637381]'>
                                        {tableConfig.columnConfig.map((item, index) => {

                                            return (
                                                <td
                                                    key={`${index}__${index}__${item.value}`}
                                                    className={`h-8 ${isScrollable ? 'bg-[#f1f3f9]' : 'bg-[#d1d3dc]'}`}
                                                >
                                                    <div className="flex text-[13px] flex-row items-center justify-center font-bold roboto text-[#637381]">
                                                        {/* {
                                                            item.sumDisplayed && data && data.length > 0 &&
                                                                (+data.map((el) => tableConfig.columnConfig[index].sumBy === 'item' ? 1 : +findValue(el, item.value))
                                                                    .reduce((a, b) => a + b, 0).toFixed(2)).toLocaleString() + ' ' + item.sumLabel
                                                        } */}
                                                        {item.value === "produced_days" && customData ?
                                                            customData[0] + ' ' + 'jours' : item.value === "billed_days" && customData ?
                                                                customData[1] + ' ' + 'jours'
                                                                :
                                                                item.sumDisplayed && data && data.length > 0 &&
                                                                (+data.map((el) => tableConfig.columnConfig[index].sumBy === 'item' ? 1 : +findValue(el, item.value))
                                                                    .reduce((a, b) => a + b, 0).toFixed(2)).toLocaleString() + ' ' + item.sumLabel
                                                        }
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}

                            {/* Table content fom the data */}

                            {/* TODO: Uncomment to activate sort */}
                            {/* {sortedData && sortedData.length > 0 ? sortedData.map((item, index) => { */}
                            {data && data.length > 0 ? data.map((item, index) => {
                                return (
                                    <Line
                                        lineIndex={index}
                                        addToArray={addToArray}
                                        key={`${item.id}__${index}`}
                                        tableConfig={tableConfig}
                                        item={item}
                                        handleClick={handleClick}
                                        dropdownIsOpen={dropdownIsOpen}
                                        lineSubmitFunctions={lineSubmitFunctions}
                                        setLineSubmitFunctions={setLineSubmitFunctions}
                                        onSaveFunction={onSaveFunction}
                                        resetValuesTrigger={resetValuesTrigger}
                                        cellHeight={cellHeight}
                                    >
                                        {/* ↓ DROPDOWN ↓ */}
                                        {
                                            tableConfig.dropdown && dropdownIsOpen[item.id] && (
                                                <tr>
                                                    <td colSpan={tableConfig.columnConfig.length}>
                                                        <Table tableConfig={dropdownConfig} dropdownConfig={null} data={item[dropdownChildrenKey]}/>
                                                    </td>
                                                </tr>
                                            )}
                                    </Line>
                                );
                            }) : null}
                        </tbody> : (
                            <tbody>
                                <tr>
                                    <td colSpan={tableConfig.columnConfig.length}>
                                        {
                                            loading ?
                                                <div className="flex flex-col items-center justify-center w-full mt-10 mb-7 text-[#646464] font-medium">
                                                    <Loader size={40}/>
                                                    Chargement
                                                </div>
                                                :
                                                <>
                                                    {
                                                        displayEmpty ?
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                                            : null
                                                    }
                                                </>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        )}
            </table>
            
            {showSubmitButtons && data.length > 0 && (
                <div className='flex items-center justify-center mt-6'>
                    <Button
                        content='Annuler'
                        type='secondary'
                        onClick={() => {
                            setResetValuesTrigger(!resetValuesTrigger);
                            setShowSubmitButtons(false)
                        }}
                    />
                    <Button
                        content='Sauvegarder'
                        loading={submitButtonLoading}
                        onClick={() => {
                            setSubmitButtonLoading(true);
                            lineSubmitFunctions.forEach((item) => item.submitFunction(item.lineItemId));
                        }}
                        type={blueStyle ? 'blue' : 'primary'}
                    />
                </div>
            )}

            {
                !loading && paginationMetadata && data.length > 0 ?
                    <div className='flex items-center justify-center w-full mt-7'>
                        <Pagination
                            onChange={onPageNumberChange}
                            total={paginationMetadata?.totalItems ?? 0}
                            current={paginationMetadata?.currentPage ?? 0}
                            pageSize={paginationMetadata.pageSize}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                            className={blueStyle ? 'blue-style' : ''}
                        />
                    </div> :
                    null
            }
        </div>
    );
};

export default Table;

const Line = ({
    children,
    tableConfig,
    item,
    handleClick,
    dropdownIsOpen,
    setLineSubmitFunctions,
    addToArray,
    lineIndex,
    resetValuesTrigger,
    cellHeight
}) => {
    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        const newObj =  tableConfig.columnConfig.reduce((acc, el) => {
            if (el.key) acc[el.key] = item[el.key];

            return acc;
        }, {});

        setDefaultValues(newObj);

        useFormReturn.reset(newObj);
    }, [item]);

    const isLineEven = lineIndex % 2 === 0;

    const useFormReturn = useForm({defaultValues});

    useEffect(() => {
        setLineSubmitFunctions(prevState => (
            [...prevState, {lineItemId: item.id, submitFunction: useFormReturn.handleSubmit(addToArray)}]
        ))

        return () => setLineSubmitFunctions(prevState => (prevState.filter((el) => el.lineItemId !== item.id)));
    }, [])

    useEffect(() => {
        useFormReturn.reset(defaultValues);
    }, [resetValuesTrigger]);

    return (
        <ErrorBoundary FallbackComponent={<div>oups</div>}>
            <React.Fragment>
                <tr
                    onClick={() => handleClick(item.id, event)}
                    className={`
                border-[#f0f0f0] border-b-[1px] 
                ${!isLineEven ? 'bg-[#F8F9FC]' : 'bg-white'}
                ${!tableConfig.dropdown && 'shadow-none'}
                `}>
                    {tableConfig.columnConfig.map((el, index) => {
                        return (
                            <td
                                key={`${el.value}__${index}__${index}`}
                                className={`
                                text-sm font-normal min-h-[3rem] ${cellHeight}
                                ${index === 0 && 'flex flex-row items-center'}
                                ${tableConfig.dropdown ? 'cursor-pointer' : 'cursor-default'}
                                ${tableConfig.dropdownLeftPadding && index === 0 ? 'pl-4' : ''}
                            `}
                            >
                                {
                                    index === 0 && tableConfig.dropdown &&
                                    <img src={dropdownIsOpen[item.id] ? minus : plus} alt="minus" className="w-5 mr-5 max-w-none"/>
                                }
                                <Controller
                                    name={el.key || ''}
                                    control={useFormReturn.control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le nom'
                                    }}}
                                    render={({
                                        field: {onChange, value}
                                    }) => (
                                        <>
                                            {el.component && el.component(item, onChange, value, lineIndex)}
                                        </>
                                    )}
                                />
                            </td>
                        );
                    })}
                </tr>
                {children}
            </React.Fragment>
        </ErrorBoundary>
    );
}
