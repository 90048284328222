import React, {useEffect, useState} from 'react';

import Popup from '../../../../../components/ui/Popup';
import CreateNewProduction from './CreateNewProduction';
import CreateNewVacation from './CreateNewVacation';
import './createnewProductionPopup.css';

function CreateNewProductionPopup({
    view,
    setView,
    isModifyActive,
    userData,
    refreshData
}) {
    const [switchForm, setSwitchForm] = useState('Production');

    useEffect(() => {
        if (isModifyActive?.deal_name === 'Congés') {
            setSwitchForm('Congés');
        }
        else {
            setSwitchForm('Production');
        }
    }, [isModifyActive, view]);

    return (
        <Popup
            view={view}
            setView={setView}
            width={'1098px'}
            xOverflow=''
            yOverflow=''
            maxHeight='40rem'
        >
            {
                switchForm === 'Production' ?
                    <CreateNewProduction
                        userData={userData}
                        switchForm={switchForm}
                        setSwitchForm={setSwitchForm}
                        setView={setView}
                        productionToBeModified={isModifyActive}
                        refreshData={refreshData}
                    /> :
                    <CreateNewVacation
                        userData={userData}
                        switchForm={switchForm}
                        setSwitchForm={setSwitchForm}
                        setView={setView}
                        vacationToBeModified={isModifyActive}
                        refreshData={refreshData}
                    />
            }
        </Popup>
    );
}

export default CreateNewProductionPopup;
