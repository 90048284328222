import {ACTION_CODE} from './_actionCode';
import {ContractorService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_CONTRACTORS';

export async function deleteContractorInvoice(
    userData: Record<string, string>,
    id: number
) {
    try {
        LoggerService.logInfo('Action - deleteContractorInvoice - Start');

        const deleteContractorInvoiceResult = await ContractorService.deleteContractorInvoice(userData, id);

        LoggerService.logInfo('Action - deleteContractorInvoice - End - Success');

        return ResultModel.newSuccessResult(deleteContractorInvoiceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteContractorInvoice - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une factures fournisseurs.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
