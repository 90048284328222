import {ACTION_CODE} from './_actionCode';
import {DealDasService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_DEALDAS';

export async function updateDealDas(
    userData: Record<string, any>,
    dealDasId: number,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateDealDasById - Start');

        const updateDealDasByIdResult =
            await DealDasService.updateDas(userData, dealDasId, payloadUpdate);

        LoggerService.logInfo('Action - updateDealDasById - End - Success');

        return ResultModel.newSuccessResult(updateDealDasByIdResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateDealDasById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
