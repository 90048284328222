import {ContractorInvoicesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_CONTRACTOR_INVOICE';

export async function createContractorInvoice(
    userData: Record<string, string>,
    newContractorInvoice: Record<string, any>
) {

    try {
        return await ContractorInvoicesDao.createContractorInvoice(userData.userToken, newContractorInvoice);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la création du fournisseur',
            error: error
        });
    }
}
