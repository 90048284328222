import {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {DealActions, InvoiceActions, NotificationActions} from "../../actions";
import Layout from "../../components/layout/Layout";
import ConfirmationPopup from '../../components/ui/ConfirmationPopup/ConfirmationPopup';
import NotificationCard from "../../components/ui/NotificationCard";
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import {Toast, notify} from "../../components/ui/Toast/Toast";
import {notificationsCardsConfig} from './configs/notificationCardsConfig';
import PreviewFilePopup from './popups/PreviewFilePopup';
import ReferencePopup from './popups/ReferencePopup';
import ValidatedSurveyPopup from './popups/ValidatedSurveyPopup';
import {addMissionKeys} from "../../utils";

const NotificationPage = () => {
    const [loading, setLoading] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [showReferencePopup, setShowReferencePopup] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showValidationSurveyPopup, setShowValidationSurveyPopup] = useState(false);
    const [showRefusalSurveyPopup, setShowRefusalSurveyPopup] = useState(false);
    const [showPreviewFilePopup, setShowPreviewFilePopup] = useState(false);

    const navigate = useNavigate();
    const { userProfileData: userData } = useSelector((state) => state.UserReducer);

    useEffect(() => {
        if (!showReferencePopup  && !showValidationSurveyPopup && !showRefusalSurveyPopup && !showPreviewFilePopup) {
            setItemToEdit(null);
        }
    }, [showReferencePopup, showPreviewFilePopup, showValidationSurveyPopup, showRefusalSurveyPopup]);

    useEffect(() => {
        refreshNotifications();
    }, []);

    const refreshNotifications = async () => {
        setLoading(true);

        const { success: successDetails, data: details } = await NotificationActions.getDetails(userData);
        const { success: successCount, data: count } = await NotificationActions.getCount(userData);

        if (!successDetails) {
            notify('Erreur de récupération des notifications');
        }
        else {
            setNotificationDetails(details);
        }
      
        if (!successCount) {
            notify('Erreur de récupération du nombre de notifications');
        }
        else {
            setNotificationsCount(count.count);
        }
        setLoading(false);
    };

    const handleValidateInvoice = async (item) => {
        const payload = {
            status: 200
        };

        const invoiceResult = await InvoiceActions.updateInvoiceById(userData, item.id, payload);

        if (!invoiceResult.success) {
            notify('Erreur lors de la mise à jour de la facture');
        }
        else {
            notify('La facture a été validé avec succès');
            await refreshNotifications();
        }
    }

    const handleRedirect = (notification, item) => {
        const { id } = item;

        switch (notification.type) {
            case 'deal':
                navigate(`/deals/${id}`);
                break;
            case 'mission':
                navigate(`/deals/${item.deal.id}/missions/${id}`);
                break;
            case 'reference':
                setItemToEdit(notificationDetails[notification.key].find((reference) => reference.id === id));
                setShowReferencePopup(true);
                break;
            case 'invoice':
                if (notification.key === 'overview') {
                    setItemToEdit(item);
                    setShowPreviewFilePopup(true);
                }
                else if (notification.key === 'validate') {
                    handleValidateInvoice(item);
                }
                else {
                    navigate(`/invoices/${id}`);
                }
                break;
            case 'surveys':
                if(notification.key === 'validated') {
                    setItemToEdit(item);
                    setShowValidationSurveyPopup(true);
                }
                else if (notification.key === 'refused') {
                    setItemToEdit(item);
                    setShowRefusalSurveyPopup(true);
                }
                break;
            default:
                break;
        }
    };

    const handleRefuseSurvey = async () => {
        const payload = {
            satisfaction_survey_status: "ignored"
        };

        const result = await DealActions.updateDeal(userData, payload,itemToEdit.id);

        if (!result.success) {
            notify('Erreur lors du refus de l\'enquete');
        }
        else {
            notify('L\'enquete a été refusé');
            refreshNotifications();
        }
        
        setShowRefusalSurveyPopup(false);
    }

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <div className="px-20 pb-20">
                    <p
                        className="my-8 text-4xl font-bold"
                        style={{ fontFamily: 'DIN Condensed'}}
                    >
                        Mes alertes{' '}
                        <span style={{ fontFamily:  'DIN-Regular' }} className="text-base text-gray-400">({notificationsCount})</span>
                    </p>

                    {notificationDetails && notificationsCardsConfig() && notificationsCardsConfig().map((card, index) => {
                        const filteredData = (card.key === 'missions_to_finish_soon' || card.key === 'mission_to_finish') ?
                            addMissionKeys(notificationDetails[card.key]) :
                            notificationDetails[card.key];
                            
                        const notificationCardQuantity = filteredData.length;

                        if (notificationCardQuantity === 0) {
                            return null;
                        }

                        return (
                            <div className="mb-10" key={index}>
                                <NotificationCard
                                    title={notificationCardQuantity}
                                    titleIconComponent={card.icon}
                                    subTitle={notificationCardQuantity < 2 ? card.title : card.pluralTitle}
                                    tableData={filteredData}
                                    tableConfig={card.tableConfig(handleRedirect)}
                                />
                            </div>
                        )
                    })}
                </div>
            </PageLoader>
            <ReferencePopup
                view={showReferencePopup}
                setView={setShowReferencePopup}
                userData={userData}
                itemToEdit={itemToEdit}
                refreshData={refreshNotifications}
            />
            <ValidatedSurveyPopup
                view={showValidationSurveyPopup}
                setView={setShowValidationSurveyPopup}
                userData={userData}
                refreshData={refreshNotifications}
                itemToEdit={itemToEdit}
            />
            <ConfirmationPopup
                view={showRefusalSurveyPopup}
                setView={setShowRefusalSurveyPopup}
                confirmButtonText='Valider'
                title="Souhaitez-vous refuser l’envoi de l’enquête ?"
                onConfirm={() => handleRefuseSurvey()}
            />
            <PreviewFilePopup
                view={showPreviewFilePopup}
                setView={setShowPreviewFilePopup}
                item={itemToEdit}
                userData={userData}
            />
        </Layout>
    );
}

export default NotificationPage;
