import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'UPDATE_TRAINING_ATTENDANCE';

export async function updateTrainingAttendance(
    userToken: string,
    updatedTrainingAttendances: Record<string, unknown>,
    trainingAttendanceId: string
) {
    try {
        const response = await axios({
            method: 'patch',
            url: `${URL}/training-attendances/${trainingAttendanceId}/`,
            data: updatedTrainingAttendances,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mis à jour de la liste des suivis de stagiaires',
            error: error
        });
    }
}
