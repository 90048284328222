import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import ActionsButton from '../../../../components/ui/ActionsButton';
import {frenchFormat} from '../../../../utils';

export const productionsTableConfig = (
    handleEditProductionClick,
    handleDeleteProductionClick,
    buttonsDisabled,
    deleteProductionLoading,
    missionStatus,
    cannotBeModified
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Type',
                    value: 'category',
                    width: 'w-[25%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.category}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Localisation',
                    value: 'location',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.location}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours Produits',
                    value: 'produced_days',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.produced_days}
                            </div>);
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jours facturés',
                    value: 'billed_days',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.billed_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jours non facturés',
                    value: 'unbilled_days',
                    width: 'w-[13%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+((+item.produced_days - +item.billed_days).toFixed(3))}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <>
                                {
                                    missionStatus === 2 || cannotBeModified ?
                                        <div className="flex flex-row items-center justify-center">
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </div> :
                                        <div className="flex flex-row items-center justify-center">
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => handleEditProductionClick(item)}
                                                disabled={buttonsDisabled}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteProductionLoading === item.id}
                                                onClick={() => handleDeleteProductionClick(item.id)}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce temps ?'
                                                disabled={buttonsDisabled}
                                            />
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
