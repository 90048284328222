import React from 'react';
import Navbar from './navbar/Navbar';
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from 'react-router-dom'; // Import useHistory hook

const Layout = ({ children }) => {
    // const role = "Executive";
    const role = 'Advisor';
    const navigate = useNavigate(); // Initialize useHistory hook

    function handleBackToHome() {
        navigate('/'); // Navigate to homepage
    }

    const ErrorFallback = () => {
        return (
            <div className='w-full min-h-screen justify-center items-center text-center'>
                <div className="pt-40">
                    <p className='text-red-500 font-bold'>Oups! Une erreur est survenue</p>
                    <button
                        onClick={handleBackToHome}
                        className='mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
                    >
                        Retour à l&apos;accueil
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <Navbar/>
            <div className="flex">
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <main className={`w-full pt-20 ${role !== 'Advisor' && 'pl-80'}`}>
                        {children}
                    </main>
                </ErrorBoundary>
            </div>
        </>
    );
};

export default Layout;
