import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux';

import Layout from '../../components/layout';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import Datepicker from '../../components/ui/Datepicker';
import Table from '../../components/ui/Table';
import plus from '../../assets/icons/button/plus.png';
import Button from '../../components/ui/Button/Button';
import {firstDayYear, lastDayYear, standardFormat, getWorkingDaysInYear} from '../../utils';
import StaffingPopup from './components/staffingPopup/StaffingPopup';
import {ContractsStaffingActions} from '../../actions';
import {staffingLeftTableConfig} from './configs/staffingLeftTableConfig';
import {staffingRightTableConfig} from './configs/staffingRightTableConfig';
import {staffingCenterTableConfig} from './configs/staffingCenterTableConfig';
import {ContractActions, UserActions} from '../../actions';

const StaffingPage = () => {
    // data states
    const [staffingData, setStaffingData] = useState([]);
    const [staffingPaginationMetadata, setStaffingPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [staffingFilterQuery, setStaffingFilterQuery] = useState('');
    const [staffingTableLoading, setStaffingTableLoading] = useState(true);

    // other states
    const [showStaffingPopup, setShowStaffingPopup] = useState(false);
    const [staffingItemToBeUpdated, setStaffingItemToBeUpdated] = useState(null);
    const [staffingUserBeUpdated, setStaffingUserToBeUpdated] = useState(null);
    const [actionItemLoading, setActionButtonLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    // date picker
    const [datePickerDate, setDatePickerDate] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    const handleDatePickerChange = (e) => {
        setDatePickerDate({
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        });
    };
    
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // staffing data
    const fetchStaffingData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setStaffingTableLoading(true);
            setStaffingData([]);
        }

        const before = standardFormat(datePickerDate.endDate);
        const after = standardFormat(datePickerDate.startDate);

        const result = await ContractsStaffingActions.getAllContractsStaffing(
            userData.userToken,
            `&before=${before}&after=${after}&page_size=${staffingPaginationMetadata.pageSize}${staffingFilterQuery}`,
            page
        );

        if (result.success) {
            setStaffingData(result.data);
            setStaffingPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (staffingPaginationMetadata.totalItems !== result.metadata.count) {
                setStaffingPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récupération des données');
        }

        setStaffingTableLoading(false);
    }

    const refreshStaffingData = async (removal = false) => {
        const checkedPage = (staffingData.length === 1 && staffingPaginationMetadata.currentPage > 1 && removal) ?
            staffingPaginationMetadata.currentPage - 1 :
            staffingPaginationMetadata.currentPage;

        await fetchStaffingData(checkedPage, false);
    }

    useEffect(() => {
        fetchStaffingData(1);
    }, [datePickerDate, staffingFilterQuery]);

    const handleFilterStaffingChange = async (query) => {
        setStaffingFilterQuery(`&${query}`);
    }

    const getCurentEditedUser = async (userId) => {

        const result = await UserActions.getUser(userData.userToken, userId);
        
        if (result.success) {
            return result;
        }
        else {
            notify("Impossible de récupérer l'utilisateur.");
        }
    };

    // update contract
    const handleEditButtonClick = async (item) => {
        const result = await getCurentEditedUser(item.ressource.id);
        
        if (result.success) {
            setStaffingUserToBeUpdated(result.data);
            setStaffingItemToBeUpdated(item);
        }
        setShowStaffingPopup(true);
    }

    // delete contract
    const handleDeleteStaffingItem = async (id) => {
        setDisabled(true);
        setActionButtonLoading(id);

        const deletionResult = await ContractActions.deleteContract(userData, id);

        if (deletionResult.success) {
            await refreshStaffingData();
            notify('Le contrat a bien été supprimé');
        }
        else {
            notify('Une erreur est survenue lors de la mise à jour de la donnée');
        }

        setActionButtonLoading(null);
        setDisabled(false);
    };

    // configs
    const leftTableConfig = staffingLeftTableConfig(
        userData,
        handleFilterStaffingChange
    );

    const centerTableConfig = staffingCenterTableConfig(staffingData[0]?.staffing);

    const rightTableConfig = staffingRightTableConfig({
        handleEditButtonClick,
        handleDeleteStaffingItem,
        actionItemLoading,
        disabled
    });
    
    useEffect(() => {
        if(!showStaffingPopup) {
            setStaffingItemToBeUpdated(null);
            setStaffingUserToBeUpdated(null);
        }
    }, [showStaffingPopup]);

    return (
        <Layout>
            <Toast/>
            <div className="px-20 pt-10 pb-12">
                <div className="flex items-center justify-between mt-12 mb-6">
                    <p className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>
                        Staffing {new Date().getFullYear()}
                        <span className="text-base ml-1 text-[#262626]" style={{fontFamily: 'din-regular'}}>
                            ({getWorkingDaysInYear(new Date().getFullYear())} jours ouvrés)
                        </span>
                    </p>
                    <Button
                        content="Nouvelle ressource"
                        icon={plus}
                        onClick={() => {
                            setShowStaffingPopup(true);
                        }
                        }
                    />
                </div>
                <Datepicker
                    type="range"
                    picker='month'
                    onChange={handleDatePickerChange}
                    defaultValue={datePickerDate}
                />
                <div className='relative flex flex-row w-full mt-12 mb-24 table-shadow-outter rounded-b-md'>
                    <Table
                        data={staffingData}
                        tableConfig={leftTableConfig}
                        groupedTable={true}
                        showSearchBar={true}
                        cellHeight='h-[40px]'
                        position='left'
                        containerWidth='w-4/12'
                        displayEmpty={false}
                    />
                    <Table
                        data={staffingData}
                        tableConfig={centerTableConfig}
                        groupedTable={true}
                        isScrollable={true}
                        showSearchBar={true}
                        cellHeight='h-[40px]'
                        width='w-full'
                        position='center'
                        containerWidth='w-3/12'
                        loading={staffingTableLoading}
                        paginationMetadata={staffingPaginationMetadata}
                        onPageNumberChange={fetchStaffingData}
                    />
                    <Table
                        data={staffingData}
                        tableConfig={rightTableConfig}
                        groupedTable={true}
                        showSearchBar={true}
                        cellHeight='h-[40px]'
                        position='right'
                        containerWidth='w-5/12'
                        displayEmpty={false}
                    />
                </div>
            </div>
            <StaffingPopup
                setView={setShowStaffingPopup}
                view={showStaffingPopup}
                staffingItemToBeUpdated={staffingItemToBeUpdated}
                setStaffingItemToBeUpdated={setStaffingItemToBeUpdated}
                staffingUserBeUpdated={staffingUserBeUpdated}
                setStaffingUserToBeUpdated={setStaffingUserToBeUpdated}
                userData={userData}
            />
        </Layout>
    )
}

export default StaffingPage;
