import {ACTION_CODE} from './_actionCode';
import {ContractService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_CONTRACT';

export async function updateContract(
    userData: Record<string, string>,
    updated: Record<string, string>,
    contractId: number
) {
    try {
        LoggerService.logInfo('Action - updateContract - Start');

        const updateContractResult = await ContractService.updateContract(userData, updated, contractId);

        LoggerService.logInfo('Action - updateContract - End - Success');

        return ResultModel.newSuccessResult(updateContractResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateContract - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la modification du contrat.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
