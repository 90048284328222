import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {PieChartOutlined, SolutionOutlined, FileExclamationOutlined, UserSwitchOutlined, FileDoneOutlined} from '@ant-design/icons';

import {standardFormat, firstDayYear, lastDayYear} from '../../utils';
import {DealActions, ExpenseReportActions, InvoiceActions, DashboardActions, ContractorInvoiceActions, ExpenseActions} from '../../actions';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import Layout from '../../components/layout/Layout';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import TabNavigator from '../../components/ui/navigation/TabNavigator';
import CommercialIndicator from './components/CommercialIndicator';
import InvoiceIndicator from './components/InvoiceIndicator';
import ExpenseIndicator from './components/ExpenseIndicator';
import ContractorIndicator from './components/ContractorIndicator';
import SatisfactionSurvey from './components/SatisfactionSurvey/SatisfactionSurvey';
import PendingProposalsPage from './PendingProposalsPage';
import AnsweringProposalsPage from './AnsweringProposalsPage';
import InvoicesToIssuePage from './InvoicesToIssuePage';
import InvoicesAwaitingPaymentPage from './InvoicesAwaitingPaymentPage';
import EstimatedInvoicesPage from './EstimatedInvoicesPage';
import ForecastInvoicesDuePage from './ForecastInvoicesDuePage';
import InvoicesToValidate from './InvoicesToValidate';
import ExpensesToCheckPage from './ExpensesToCheckPage';
import ExpensesToPayPage from './ExpensesToPayPage';
import ContractorInvoicesAwaitingPage from './ContractorInvoicesAwaitingPage';
import ContractorInvoicesToPayPage from './ContractorInvoicesToPayPage';
import PurchasesPage from './PurchasesPage';

const OfficeManagerDashboardPage = () => {
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const [billing, setBilling] = useState();
    const [contractors, setContractors] = useState();
    const [purchasesNumber, setPurchasesNumber] = useState(0);
    const [expenses, setExpenses] = useState();
    const [sales, setSales] = useState();

    const [loading, setLoading] = useState(true);

    const [dealsAwaitingResponseTableLoading, setDealsAwaitingResponseTableLoading] = useState(true);
    const [dealsPendingTableLoading, setDealsPendingTableLoading] = useState(true);
    const [invoicesAwaitingPaymentTableLoading, setInvoicesAwaitingPaymentTableLoading] = useState(true);
    const [estimatedInvoicesTableLoading, setEstimatedInvoicesTableLoading] = useState(true);
    const [forecastInvoicesDueTableLoading, setForecastInvoicesDueTableLoading] = useState(true);
    const [expensesToCheckTableLoading, setExpensesToCheckTableLoading] = useState(true);
    const [expensesToPayTableLoading, setExpensesToPayTableLoading] = useState(true);
    const [contractorInvoicesAwaitingTableLoading, setContractorInvoicesAwaitingPayTableLoading] = useState(true);
    const [contractorInvoicesToPayTableLoading, setContractorInvoicesToPayTableLoading] = useState(true);
    const [satisfactionSurveyTableLoading, setSatisfactionSurveyTableLoading] = useState(true);

    useEffect(() => {

        (async () => {
            // --- TAB -> «Facturation» ---
            const billingResult = await DashboardActions.OfficeManagerActions.getBilling(userData, '');

            const invoicesToValidate = await InvoiceActions.getAllInvoices(userData, "&page_size=1&status=100", 1);

            if (billingResult.success) {
                const allInvoicesData = {...billingResult.data, to_validate: invoicesToValidate.metadata.count};

                setBilling(allInvoicesData);
            }
            else {
                notify('Une erreur est survenue lors de la récuperation des données facturation');
            }

            // --- TAB -> «Notes de frais» ---
            const expensesResult = await DashboardActions.OfficeManagerActions.getExpenses(userData, '');

            if (expensesResult.success) {
                setExpenses(expensesResult.data)
            }
            else {
                notify('Une erreur est survenue lors de la récuperation de données de frais');
            }

            // --- TAB -> «Fournisseurs et achats» ---
            const contactorsResult = await DashboardActions.OfficeManagerActions.getContractors(userData, '');

            if (contactorsResult.success) {
                setContractors(contactorsResult.data)
            }
            else {
                notify('Une erreur est survenue lors de la récuperation des données fournisseurs');
            }
        
            const purchasesResult = await ExpenseActions.getAllExpenses(userData, '', '', '', "&page_size=1&report=null&page=1");

            if (purchasesResult.success) {
                setPurchasesNumber(purchasesResult.metadata.count)
            }
            else {
                notify('Une erreur est survenue lors de la récuperation des données achats');
            }

            // --- TAB -> «Commercial» ---
            const salesResult = await DashboardActions.OfficeManagerActions.getSales(userData, '');

            if (salesResult.success) {
                setSales(salesResult.data)
            }
            else {
                notify('Une erreur est survenue lors de la récuperation des données commerciales');
            }
        })();
    }, [])

    useEffect(() => {
        if (sales) {
            setLoading(false)
        }
    }, [sales]);

    // --- TAB -> «Commercial» ---
    // ---------------------------
    // ↓ Table -> Propositions en cours de réponse
    const [dealsAwaitingResponse, setDealsAwaitingResponse] = useState([]);
    const [dealsAwaitingResponsePaginationMetadata, setDealsAwaitingResponsePaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [dealsAwaitingResponseFilterQuery, setDealsAwaitingResponseFilterQuery] = useState('');

    // ↓ Table -> Propositions en attente
    const [dealsPending, setDealsPending] = useState([]);
    const [dealsPendingPaginationMetadata, setDealsPendingPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [dealsPendingFilterQuery, setDealsPendingFilterQuery] = useState('');

    // --- TAB -> «Facturation» ---
    // ----------------------------
    // ↓ Table -> Factures à émettre
    const [invoicesToIssue, setInvoicesToIssue] = useState([]);
    const [invoicesToIssuePaginationMetadata, setInvoicesToIssuePaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [invoicesToIssueFilterQuery, setInvoicesToIssueFilterQuery] = useState('');
    const [invoicesToIssueTableLoading, setInvoicesToIssueTableLoading] = useState(false);

    // ↓ Table -> Factures en attente de paiement
    const [invoicesAwaitingPayment, setInvoicesAwaitingPayment] = useState([]);
    const [invoicesAwaitingPaymentPaginationMetadata, setInvoicesAwaitingPaymentPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [invoicesAwaitingPaymentFilterQuery, setInvoicesAwaitingPaymentFilterQuery] = useState('');

    // ↓ Table -> Factures prévisionnelle
    const [estimatedInvoices, setEstimatedInvoices] = useState([]);
    const [estimatedInvoicesPaginationMetadata, setEstimatedInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [estimatedInvoicesFilterQuery, setEstimatedInvoicesFilterQuery] = useState('');

    // ↓ Table -> Factures prévisionnelles échues
    const [forecastInvoicesDue, setForecastInvoicesDue] = useState([]);
    const [forecastInvoicesDuePaginationMetadata, setForecastInvoicesDuePaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [forecastInvoicesDueFilterQuery, setForecastInvoicesDueFilterQuery] = useState('');

    // --- TAB -> «Note de frais» ---
    // ------------------------------
    // ↓ Table -> Notes de frais à vérifier
    const [expensesToCheck, setExpensesToCheck] = useState([]);
    const [expensesToCheckPaginationMetadata, setExpensesToCheckPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [expensesToCheckFilterQuery, setExpensesToCheckFilterQuery] = useState('');

    // ↓ Table -> Notes de frais à vérifier
    const [expensesToPay, setExpensesToPay] = useState([]);
    const [expensesToPayPaginationMetadata, setExpensesToPayPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [expensesToPayFilterQuery, setExpensesToPayFilterQuery] = useState('');

    // --- TAB -> «Fournisseurs» ---
    // -----------------------------
    // ↓ Table -> Factures fournisseurs en attente
    const [contractorInvoicesAwaiting, setContractorInvoicesAwaiting] = useState([]);
    const [contractorInvoicesAwaitingPaginationMetadata, setContractorInvoicesAwaitingPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [contractorInvoicesAwaitingFilterQuery, setContractorInvoicesAwaitingFilterQuery] = useState('');

    // ↓ Table -> Factures fournisseurs à payer
    const [contractorInvoicesToPay, setContractorInvoicesToPay] = useState([]);
    const [contractorInvoicesToPayPaginationMetadata, setContractorInvoicesToPayPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [contractorInvoicesToPayFilterQuery, setContractorInvoicesToPayFilterQuery] = useState('');

    // --- TAB -> «Enquête de satisfaction» ---
    // ----------------------------------------
    // ↓ Table -> Affaires closes validées à envoyer aux clients
    const [satisfactionSurvey, setSatisfactionSurvey] = useState([]);
    const [satisfactionSurveyPaginationMetadata, setSatisfactionSurveyPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [satisfactionSurveyDatePicker, setSatisfactionSurveyDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // other states
    const [displayedSection, setDisplayedSection] = useState('Commercial');
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    // TabNavigator icons
    const commercialIcon = <PieChartOutlined
        style={{fontSize: '13px'}}
        twoToneColor={displayedSection === 'Ma production' ? '#DC3832' : '#646464'}
    />;
    const invoiceIcon = <SolutionOutlined
        style={{fontSize: '13px'}}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    const expenseIcon = <FileExclamationOutlined
        style={{fontSize: '13px'}}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    const contractorIcon = <UserSwitchOutlined
        style={{fontSize: '13px'}}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    const satisfactionSurveyIcon = <FileDoneOutlined
        style={{fontSize: '13px'}}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;

    const month = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
        "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    const d = new Date();
    const currentMonth = month[d.getMonth()];
    const currentYear = d.getFullYear();
    const currentDate = currentMonth + ' ' + currentYear;

    const location = useLocation();
    const navigate = useNavigate();
    
    // Redirection pour éviter le bug de la page blanche
    useEffect(() => {
        if (location.pathname !== '/dashboard/office-manager') {
            navigate('/dashboard/office-manager');
        }
    }, []);

    // Permet d'éviter la persistance des states filters une fois de retour à la page principale
    useEffect(() => {
        if (location.pathname === '/dashboard/office-manager') {
            if (dealsAwaitingResponseFilterQuery) setDealsAwaitingResponseFilterQuery('');

            if(dealsPendingFilterQuery) setDealsPendingFilterQuery('');

            if(invoicesToIssueFilterQuery) setInvoicesToIssueFilterQuery('');

            if(invoicesAwaitingPaymentFilterQuery) setInvoicesAwaitingPaymentFilterQuery('');

            if(estimatedInvoicesFilterQuery) setEstimatedInvoicesFilterQuery('');

            if(forecastInvoicesDueFilterQuery) setForecastInvoicesDueFilterQuery('');

            if(expensesToCheckFilterQuery) setExpensesToCheckFilterQuery('');

            if(expensesToPayFilterQuery) setExpensesToPayFilterQuery('');

            if(contractorInvoicesAwaitingFilterQuery) setContractorInvoicesAwaitingFilterQuery('');

            if(contractorInvoicesToPayFilterQuery) setContractorInvoicesToPayFilterQuery('');
        }
    }, [location.pathname]);

    // --- TAB -> «Commercial» ---
    // ------------------------------------------------
    // ↓ Fetch data -> Propositions en cours de réponse
    const fetchDealsAwaitingResponseData = async (page) => {
        setDealsAwaitingResponseTableLoading(true);
        setDealsAwaitingResponse([]);
       
        const dealsAwaitingResponseResult = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${dealsAwaitingResponsePaginationMetadata.pageSize}&status=300${dealsAwaitingResponseFilterQuery}`,
            page
        );

        if (dealsAwaitingResponseResult.success) {
            setDealsAwaitingResponse(dealsAwaitingResponseResult.data);
            setDealsAwaitingResponsePaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (dealsAwaitingResponsePaginationMetadata.totalItems !== dealsAwaitingResponseResult.metadata.count) {
                setDealsAwaitingResponsePaginationMetadata(prevState => ({...prevState, totalItems: dealsAwaitingResponseResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des propositions en cours de réponse');
        }

        setDealsAwaitingResponseTableLoading(false);
    }

    useEffect(() => {
        fetchDealsAwaitingResponseData(1);
    }, [dealsAwaitingResponseFilterQuery]);
    // ------------------------------------

    // ---------------------------------------
    // ↓ Fetch data -> Propositions en attente
    const fetchDealsPendingData = async (page) => {
        setDealsPendingTableLoading(true);
        setDealsPending([]);

        const dealsPendingResult = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${dealsPendingPaginationMetadata.pageSize}&status=200${dealsPendingFilterQuery}`,
            page
        );

        if (dealsPendingResult.success) {
            setDealsPending(dealsPendingResult.data);
            setDealsPendingPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (dealsPendingPaginationMetadata.totalItems !== dealsPendingResult.metadata.count) {
                setDealsPendingPaginationMetadata(prevState => ({...prevState, totalItems: dealsPendingResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des propositions en attente');
        }

        setDealsPendingTableLoading(false);
        // loading ? setLoading(false) : null;
    }

    useEffect(() => {
        fetchDealsPendingData(1);
    }, [dealsPendingFilterQuery]);
    // ---------------------------

    // Fonction filter des tables deals ci-dessus
    const filterDealList = async (query, status) => {
        if (status === 'pending') {
            setDealsPendingFilterQuery(`&${query}`);
        }
        else {
            setDealsAwaitingResponseFilterQuery(`&${query}`);
        }
    };

    // --- TAB -> «Facturation» ---
    // -----------------------------
    // ↓ Table -> Factures à émettre
    const fetchInvoicesToIssueData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setInvoicesToIssueTableLoading(true);
            setInvoicesToIssue([]);
        }

        const invoicesToIssueResult = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${invoicesToIssuePaginationMetadata.pageSize}&status=200${invoicesToIssueFilterQuery}`,
            page
        );

        if (invoicesToIssueResult.success) {
            setInvoicesToIssue(invoicesToIssueResult.data);
            setInvoicesToIssuePaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (invoicesToIssuePaginationMetadata.totalItems !== invoicesToIssueResult.metadata.count) {
                setInvoicesToIssuePaginationMetadata(prevState => ({...prevState, totalItems: invoicesToIssueResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des factures à émettre');
        }

        setInvoicesToIssueTableLoading(false);
    }

    const refreshInvoicesToIssueData = async (removal = false) => {
        const checkedPage = (invoicesToIssue.length === 1 && invoicesToIssuePaginationMetadata.currentPage > 1 && removal) ?
            invoicesToIssuePaginationMetadata.currentPage - 1 :
            invoicesToIssuePaginationMetadata.currentPage;

        await fetchInvoicesToIssueData(checkedPage, false);
    }

    useEffect(() => {
        fetchInvoicesToIssueData(1);
    }, [invoicesToIssueFilterQuery]);
    // ------------------------------

    // --------------------------------------
    // ↓ Table -> Factures en attente de paiement
    const fetchInvoicesAwaitingPaymentData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setInvoicesAwaitingPaymentTableLoading(true);
            setInvoicesAwaitingPayment([]);
        }

        const invoicesAwaitingPaymentResult = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${invoicesAwaitingPaymentPaginationMetadata.pageSize}&status=300&status=400${invoicesAwaitingPaymentFilterQuery}`,
            page
        );

        if (invoicesAwaitingPaymentResult.success) {
            setInvoicesAwaitingPayment(invoicesAwaitingPaymentResult.data);
            setInvoicesAwaitingPaymentPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (invoicesToIssuePaginationMetadata.totalItems !== invoicesAwaitingPaymentResult.metadata.count) {
                setInvoicesAwaitingPaymentPaginationMetadata(prevState => ({...prevState, totalItems: invoicesAwaitingPaymentResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des factures en attente de paiement');
        }

        setInvoicesAwaitingPaymentTableLoading(false);
    }

    const refreshInvoicesAwaitingPaymentData = async (removal = false) => {
        const checkedPage = (invoicesAwaitingPayment.length === 1 && invoicesAwaitingPaymentPaginationMetadata.currentPage > 1 && removal) ?
            invoicesAwaitingPaymentPaginationMetadata.currentPage - 1 :
            invoicesAwaitingPaymentPaginationMetadata.currentPage;

        await fetchInvoicesAwaitingPaymentData(checkedPage, false);
    }

    useEffect(() => {
        fetchInvoicesAwaitingPaymentData(1);
    }, [invoicesAwaitingPaymentFilterQuery]);
    // --------------------------------------

    // ----------------------------------
    // ↓ Table -> Factures prévisionnelle
    const fetchEstimatedInvoicesData = async (page) => {
        setEstimatedInvoicesTableLoading(true);
        setEstimatedInvoices([]);

        // ↓ Pour que les resultats inférieurs ou égaux à la current date
        let date = new Date();
        date.setDate(date.getDate() + 1);
        date = standardFormat(date);

        const estimatedInvoicesResult = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${estimatedInvoicesPaginationMetadata.pageSize}&status=0&status=100&status=200${estimatedInvoicesFilterQuery}`,
            page
        );

        if (estimatedInvoicesResult.success) {
            setEstimatedInvoices(estimatedInvoicesResult.data);
            setEstimatedInvoicesPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (estimatedInvoicesPaginationMetadata.totalItems !== estimatedInvoicesResult.metadata.count) {
                setEstimatedInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: estimatedInvoicesResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des factures prévisionnelles');
        }

        setEstimatedInvoicesTableLoading(false);
    }

    useEffect(() => {
        fetchEstimatedInvoicesData(1);
    }, [estimatedInvoicesFilterQuery]);
    // --------------------------------

    // ------------------------------------------
    // ↓ Table -> Factures prévisionnelles échues
    const fetchForecastInvoicesDueData = async (page) => {
        setForecastInvoicesDueTableLoading(true);
        setForecastInvoicesDue([]);

        // ↓ Pour que les resultats supérieurs current date
        const date = standardFormat(new Date());

        const forecastInvoicesDueResult = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${forecastInvoicesDuePaginationMetadata.pageSize}&status=0&billing_before=${date}${forecastInvoicesDueFilterQuery}`,
            page
        );

        if (forecastInvoicesDueResult.success) {
            setForecastInvoicesDue(forecastInvoicesDueResult.data);
            setForecastInvoicesDuePaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (estimatedInvoicesPaginationMetadata.totalItems !== forecastInvoicesDueResult.metadata.count) {
                setForecastInvoicesDuePaginationMetadata(prevState => ({...prevState, totalItems: forecastInvoicesDueResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des factures prévisionnelles échues');
        }

        setForecastInvoicesDueTableLoading(false);
    }

    useEffect(() => {
        fetchForecastInvoicesDueData(1);
    }, [forecastInvoicesDueFilterQuery]);
    // ----------------------------------

    // Fonction filter des tables invoices ci-dessus
    const filterInvoicesList = async (query, status) => {
        if (!status) return;

        if (status === 'invoicesToIssue') {
            setInvoicesToIssueFilterQuery(`&${query}`);
        }
        else if (status === 'invoicesAwaitingPayment') {
            setInvoicesAwaitingPaymentFilterQuery(`&${query}`);
        }
        else if (status === 'estimatedInvoices') {
            setEstimatedInvoicesFilterQuery(`&${query}`);
        }
        else if (status === 'forecastInvoicesDue') {
            setForecastInvoicesDueFilterQuery(`&${query}`);
        }
    };

    // --- TAB -> «Note de frais» ---
    // ------------------------------------
    // ↓ Table -> Notes de frais à vérifier
    const fetchExpensesToCheckData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setExpensesToCheckTableLoading(true);
            setExpensesToCheck([]);
        }

        const expensesToCheckResult = await ExpenseReportActions.getAllExpenseReports(
            userData,
            `&page_size=${expensesToCheckPaginationMetadata.pageSize}&status=1${expensesToCheckFilterQuery}`,
            page
        );

        if (expensesToCheckResult.success) {
            setExpensesToCheck(expensesToCheckResult.data);
            setExpensesToCheckPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (expensesToCheckPaginationMetadata.totalItems !== expensesToCheckResult.metadata.count) {
                setExpensesToCheckPaginationMetadata(prevState => ({...prevState, totalItems: expensesToCheckResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des notes de frais à vérifier');
        }

        setExpensesToCheckTableLoading(false);
    }

    const refreshExpensesToCheckData = async (removal = false) => {
        const checkedPage = (expensesToCheck.length === 1 && expensesToCheckPaginationMetadata.currentPage > 1 && removal) ?
            expensesToCheckPaginationMetadata.currentPage - 1 :
            expensesToCheckPaginationMetadata.currentPage;

        await fetchExpensesToCheckData(checkedPage, false);
    }

    useEffect(() => {
        fetchExpensesToCheckData(1);
    }, [expensesToCheckFilterQuery]);
    // ------------------------------

    // ---------------------------------
    // ↓ Table -> Notes de frais à payer
    const fetchExpensesToPayData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setExpensesToPayTableLoading(true);
            setExpensesToPay([]);
        }

        const expensesToPayResult = await ExpenseReportActions.getAllExpenseReports(
            userData,
            `&page_size=${expensesToPayPaginationMetadata.pageSize}&status=2${expensesToPayFilterQuery}`,
            page
        );

        if (expensesToPayResult.success) {
            setExpensesToPay(expensesToPayResult.data);
            setExpensesToPayPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (expensesToPayPaginationMetadata.totalItems !== expensesToPayResult.metadata.count) {
                setExpensesToPayPaginationMetadata(prevState => ({...prevState, totalItems: expensesToPayResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des notes de frais à payer');
        }

        setExpensesToPayTableLoading(false);
    }

    const refreshExpensesToPayData = async (removal = false) => {
        const checkedPage = (expensesToPay.length === 1 && expensesToPayPaginationMetadata.currentPage > 1 && removal) ?
            expensesToPayPaginationMetadata.currentPage - 1 :
            expensesToPayPaginationMetadata.currentPage;

        await fetchExpensesToPayData(checkedPage, false);
    }

    useEffect(() => {
        fetchExpensesToPayData(1);
    }, [expensesToPayFilterQuery]);
    // ----------------------------

    // Fonction filter des tables expense report ci-dessus
    const filterExpenseReportList = async (query, status) => {
        if (status === 'expensesToCheck') {
            setExpensesToCheckFilterQuery(`&${query}`);
        }
        else if (status === 'expensesToPay') {
            setExpensesToPayFilterQuery(`&${query}`);
        }
    };

    // --- TAB -> «Fournisseurs» ---
    // -------------------------------------------
    // ↓ Table -> Factures fournisseurs en attente
    const fetchContractorInvoicesAwaitingData = async (page) => {
        setContractorInvoicesAwaitingPayTableLoading(true);
        setContractorInvoicesAwaiting([]);

        const contractorInvoicesAwaitingResult = await ContractorInvoiceActions.getContractorInvoices(
            userData,
            // eslint-disable-next-line max-len
            `?page=${page}&page_size=${contractorInvoicesAwaitingPaginationMetadata.pageSize}&paid=false&invoice_paid=false${contractorInvoicesAwaitingFilterQuery}`
        );

        if (contractorInvoicesAwaitingResult.success) {
            setContractorInvoicesAwaiting(contractorInvoicesAwaitingResult.data);
            setContractorInvoicesAwaitingPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (contractorInvoicesAwaitingPaginationMetadata.totalItems !== contractorInvoicesAwaitingResult.metadata.count) {
                setContractorInvoicesAwaitingPaginationMetadata(prevState => ({...prevState, totalItems: contractorInvoicesAwaitingResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures fournisseurs en attente');
        }

        setContractorInvoicesAwaitingPayTableLoading(false);
    }

    useEffect(() => {
        fetchContractorInvoicesAwaitingData(1);
    }, [contractorInvoicesAwaitingFilterQuery]);
    // -----------------------------------------

    // ----------------------------------------
    // ↓ Table -> Factures fournisseurs à payer
    const fetchContractorInvoicesToPayData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setContractorInvoicesToPayTableLoading(true);
            setContractorInvoicesToPay([]);
        }

        const contractorInvoicesToPayResult = await ContractorInvoiceActions.getContractorInvoices(
            userData,
            `?page=${page}&page_size=${contractorInvoicesToPayPaginationMetadata.pageSize}&paid=false&invoice_paid=true${contractorInvoicesToPayFilterQuery}`
        );

        if (contractorInvoicesToPayResult.success) {
            setContractorInvoicesToPay(contractorInvoicesToPayResult.data);
            setContractorInvoicesToPayPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (contractorInvoicesToPayPaginationMetadata.totalItems !== contractorInvoicesToPayResult.metadata.count) {
                setContractorInvoicesToPayPaginationMetadata(prevState => ({...prevState, totalItems: contractorInvoicesToPayResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures fournisseurs à payer');
        }

        setContractorInvoicesToPayTableLoading(false);
    }

    const refreshContractorInvoicesToPayData = async (removal = false) => {
        const checkedPage = (contractorInvoicesToPay.length === 1 && contractorInvoicesToPayPaginationMetadata.currentPage > 1 && removal) ?
            contractorInvoicesToPayPaginationMetadata.currentPage - 1 :
            contractorInvoicesToPayPaginationMetadata.currentPage;

        await fetchContractorInvoicesToPayData(checkedPage, false);
    };

    useEffect(() => {
        fetchContractorInvoicesToPayData(1);
    }, [contractorInvoicesToPayFilterQuery]);
    // --------------------------------------

    // Fonction filter des tables contactor invoices ci-dessus
    const filterContractorInvoiceList = async (query, status) => {
        if (status === 'contractorInvoicesAwaiting') {
            setContractorInvoicesAwaitingFilterQuery(`&${query}`);
        }
        else if (status === 'contractorInvoicesToPay') {
            setContractorInvoicesToPayFilterQuery(`&${query}`);
        }
    }

    // ↓ Table -> Affaires closes validées à envoyer aux clients
    const fetchSatisfactionSurveyData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setSatisfactionSurveyTableLoading(true);
            setSatisfactionSurvey([]);
        }

        const dateAfter = standardFormat(new Date(satisfactionSurveyDatePicker.startDate));
        const dateBefore = standardFormat(new Date(satisfactionSurveyDatePicker.endDate));

        const satisfactionSurveyResult = await DashboardActions.OfficeManagerActions.getSatisfactionSurveys(
            userData,
            `?page=${page}&page_size=${satisfactionSurveyPaginationMetadata.pageSize}&date_after=${dateAfter}&date_before=${dateBefore}`
        );

        if (satisfactionSurveyResult.success) {
            setSatisfactionSurvey(satisfactionSurveyResult.data);
            setSatisfactionSurveyPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (satisfactionSurveyPaginationMetadata.totalItems !== satisfactionSurveyResult.metadata.count) {
                setSatisfactionSurveyPaginationMetadata(prevState => ({...prevState, totalItems: satisfactionSurveyResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des affaires closes validées à envoyer aux clients');
        }

        setSatisfactionSurveyTableLoading(false);
    }

    const refreshSatisfactionSurveysData = async (removal = false) => {
        let checkedPage = (satisfactionSurvey.length === 1 && satisfactionSurveyPaginationMetadata.currentPage > 1 && removal) ?
            satisfactionSurveyPaginationMetadata.currentPage - 1 :
            satisfactionSurveyPaginationMetadata.currentPage;

        // Dans ce cas précis il est possible que de «satisfactionSurveyPaginationMetadata.currentPage» soit undefined
        if (!checkedPage) checkedPage = 1;

        await fetchSatisfactionSurveyData(checkedPage, false);
    };

    useEffect(() => {
        fetchSatisfactionSurveyData(1);
    }, [satisfactionSurveyDatePicker]);
    // --------------------------------

    const handleMissionNavigation = async (dealId) => {
        const getDeal = await DealActions.getDealById(userData, dealId);
        
        if (getDeal.success) {
            const isSale = getDeal.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

            if (userData.role === 'CO' && getDeal.data.production_lead.id !== userData.userId && !isSale) {
                navigate(`/deals/${getDeal.data.id}/missions/${getDeal.data.missions.find((mission) => mission.ressource.id === userData.userId)?.id}`);

                return
            }
            else {
                navigate(`/deals/${dealId}`);
            }
        }
    }

    const refreshContractorInvoicesData = async () => {
        // data cards
        const contactorsResult = await DashboardActions.OfficeManagerActions.getContractors(userData, '');

        if (contactorsResult.success) {
            setContractors(contactorsResult.data)
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des données fournisseurs');
        }

        // data table «Factures fournisseurs en attente»
        await fetchContractorInvoicesAwaitingData(1);

        // data table «Factures fournisseurs à payer»
        await fetchContractorInvoicesToPayData(1);
    }

    const refreshPurchasesData = async () => {
        const purchasesResult = await ExpenseActions.getAllExpenses(userData, '', '', '', "&page_size=1&report=null&page=1");

        if (purchasesResult.success) {
            setPurchasesNumber(purchasesResult.metadata.count)
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des données achats');
        }
    }

    return (
        <Layout>
            <Toast/>
            <PageLoader
                loading={loading}
            >
                <div className="px-20 pt-10 pb-32">
                    <TabNavigator
                        setDisplayedSection={setDisplayedSection}
                        displayedSection={displayedSection}
                        sections={[
                            {name:"Commercial", icon: commercialIcon},
                            {name: "Facturation", icon: invoiceIcon},
                            {name: "Note de frais", icon: expenseIcon},
                            {name: "Sous-traitants et Achats", icon: contractorIcon},
                            {name: "Enquête de satisfaction", icon: satisfactionSurveyIcon}
                        ]}
                        onClick={() => navigate('/dashboard/office-manager')}
                    />
                    {
                        displayedSection === 'Commercial' && (
                            <Routes>
                                <Route path='/' element={
                                    <CommercialIndicator
                                        totalDealsAwaitingResponseItems={sales?.waiting_answer}
                                        totalDealsPendingItems={sales?.response_in_progress}
                                        currentDate={currentDate}
                                    />
                                }/>
                                <Route path='answering-proposals' element={
                                    <AnsweringProposalsPage
                                        tableType={'answering'}
                                        handleMissionNavigation={handleMissionNavigation}
                                        filterDealList={filterDealList}
                                        data={dealsAwaitingResponse}
                                        userData={userData}
                                        paginationMetadata={dealsAwaitingResponsePaginationMetadata}
                                        onPageNumberChange={fetchDealsAwaitingResponseData}
                                        tableLoading={dealsAwaitingResponseTableLoading}
                                    />
                                }/>
                                <Route path='pending-proposals' element={
                                    <PendingProposalsPage
                                        tableType={'pending'}
                                        handleMissionNavigation={handleMissionNavigation}
                                        filterDealList={filterDealList}
                                        data={dealsPending}
                                        userData={userData}
                                        paginationMetadata={dealsPendingPaginationMetadata}
                                        onPageNumberChange={fetchDealsPendingData}
                                        tableLoading={dealsPendingTableLoading}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Facturation' && (
                            <Routes>
                                <Route path='/' element={
                                    <InvoiceIndicator
                                        totalInvoicesToIssueItems={billing?.to_send}
                                        totalInvoicesAwaitingPaymentItems={billing?.to_pay}
                                        totalEstimatedInvoicesItems={billing?.in_draft}
                                        totalForecastInvoicesDueItems={billing?.in_draft_late}
                                        totalInvoicesToValidateItems={billing?.to_validate}
                                        currentDate={currentDate}
                                    />
                                }/>
                                <Route path='invoices-to-issue' element={
                                    <InvoicesToIssuePage
                                        userData={userData}
                                        data={invoicesToIssue}
                                        refreshData={refreshInvoicesToIssueData}
                                        tableType={'invoicesToIssue'}
                                        filterInvoicesList={filterInvoicesList}
                                        actionButtonLoading={actionButtonLoading}
                                        actionButtonDisabled={actionButtonDisabled}
                                        setActionButtonLoading={setActionButtonLoading}
                                        setActionButtonDisabled={setActionButtonDisabled}
                                        paginationMetadata={invoicesToIssuePaginationMetadata}
                                        onPageNumberChange={fetchInvoicesToIssueData}
                                        tableLoading={invoicesToIssueTableLoading}
                                    />
                                }/>
                                <Route path='invoices-awaiting-payment' element={
                                    <InvoicesAwaitingPaymentPage
                                        userData={userData}
                                        data={invoicesAwaitingPayment}
                                        refreshData={refreshInvoicesAwaitingPaymentData}
                                        filterInvoicesList={filterInvoicesList}
                                        tableType={'invoicesAwaitingPayment'}
                                        paginationMetadata={invoicesAwaitingPaymentPaginationMetadata}
                                        onPageNumberChange={fetchInvoicesAwaitingPaymentData}
                                        tableLoading={invoicesAwaitingPaymentTableLoading}
                                    />
                                }/>
                                <Route path='estimated-invoices' element={
                                    <EstimatedInvoicesPage
                                        userData={userData}
                                        data={estimatedInvoices}
                                        filterInvoicesList={filterInvoicesList}
                                        tableType={'estimatedInvoices'}
                                        paginationMetadata={estimatedInvoicesPaginationMetadata}
                                        onPageNumberChange={fetchEstimatedInvoicesData}
                                        tableLoading={estimatedInvoicesTableLoading}
                                    />
                                }/>
                                <Route path='forecast-invoices-due' element={
                                    <ForecastInvoicesDuePage
                                        userData={userData}
                                        data={forecastInvoicesDue}
                                        filterInvoicesList={filterInvoicesList}
                                        tableType={'forecastInvoicesDue'}
                                        paginationMetadata={forecastInvoicesDuePaginationMetadata}
                                        onPageNumberChange={fetchForecastInvoicesDueData}
                                        tableLoading={forecastInvoicesDueTableLoading}
                                    />
                                }/>
                                <Route path='invoices-to-validate' element={
                                    <InvoicesToValidate
                                        userData={userData}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Note de frais' && (
                            <Routes>
                                <Route path='/' element={
                                    <ExpenseIndicator
                                        totalExpensesToCheckItems={expenses.to_verify}
                                        totalExpensesToPayItems={expenses.to_pay}
                                        currentDate={currentDate}
                                    />
                                }/>
                                <Route path='expenses-to-check' element={
                                    <ExpensesToCheckPage
                                        userData={userData}
                                        data={expensesToCheck}
                                        actionButtonDisabled={actionButtonDisabled}
                                        actionButtonLoading={actionButtonLoading}
                                        setActionButtonDisabled={setActionButtonDisabled}
                                        setActionButtonLoading={setActionButtonLoading}
                                        refreshData={refreshExpensesToCheckData}
                                        filterExpenseReportList={filterExpenseReportList}
                                        tableType={'expensesToCheck'}
                                        paginationMetadata={expensesToCheckPaginationMetadata}
                                        onPageNumberChange={fetchExpensesToCheckData}
                                        tableLoading={expensesToCheckTableLoading}
                                    />
                                }/>
                                <Route path='expenses-to-pay' element={
                                    <ExpensesToPayPage
                                        userData={userData}
                                        expensesToPay={expensesToPay}
                                        actionButtonDisabled={actionButtonDisabled}
                                        actionButtonLoading={actionButtonLoading}
                                        setActionButtonDisabled={setActionButtonDisabled}
                                        setActionButtonLoading={setActionButtonLoading}
                                        refreshData={refreshExpensesToPayData}
                                        filterExpenseReportList={filterExpenseReportList}
                                        tableType={'expensesToPay'}
                                        paginationMetadata={expensesToPayPaginationMetadata}
                                        onPageNumberChange={fetchExpensesToPayData}
                                        tableLoading={expensesToPayTableLoading}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Sous-traitants et Achats' && (
                            <Routes>
                                <Route path='/' element={
                                    <ContractorIndicator
                                        userData={userData}
                                        totalContractorInvoicesAwaitingItems={contractors.pending}
                                        totalContractorInvoicesToPayItems={contractors.to_pay}
                                        totalPurchaseItems={purchasesNumber}
                                        currentDate={currentDate}
                                        refreshContractorInvoicesData={refreshContractorInvoicesData}
                                        refreshPurchasesData={refreshPurchasesData}
                                    />
                                }/>
                                <Route path='contractor-invoices-awaiting' element={
                                    <ContractorInvoicesAwaitingPage
                                        data={contractorInvoicesAwaiting}
                                        userData={userData}
                                        filterContractorInvoiceList={filterContractorInvoiceList}
                                        tableType='contractorInvoicesAwaiting'
                                        paginationMetadata={contractorInvoicesAwaitingPaginationMetadata}
                                        onPageNumberChange={fetchContractorInvoicesAwaitingData}
                                        tableLoading={contractorInvoicesAwaitingTableLoading}
                                    />
                                }/>
                                <Route path='contractor-invoices-to-pay' element={
                                    <ContractorInvoicesToPayPage
                                        data={contractorInvoicesToPay}
                                        userData={userData}
                                        filterContractorInvoiceList={filterContractorInvoiceList}
                                        refreshData={refreshContractorInvoicesToPayData}
                                        tableType='contractorInvoicesToPay'
                                        paginationMetadata={contractorInvoicesToPayPaginationMetadata}
                                        onPageNumberChange={fetchContractorInvoicesToPayData}
                                        tableLoading={contractorInvoicesToPayTableLoading}
                                    />
                                }/>
                                <Route path='purchases' element={
                                    <PurchasesPage
                                        userData={userData}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Enquête de satisfaction' && (
                            <Routes>
                                <Route path='/' element={
                                    <SatisfactionSurvey
                                        data={satisfactionSurvey}
                                        datePickerDate={satisfactionSurveyDatePicker}
                                        setDatePickerDate={setSatisfactionSurveyDatePicker}
                                        refreshData={refreshSatisfactionSurveysData}
                                        paginationMetadata={satisfactionSurveyPaginationMetadata}
                                        onPageNumberChange={fetchSatisfactionSurveyData}
                                        tableLoading={satisfactionSurveyTableLoading}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                </div>
            </PageLoader>
        </Layout>
    );
};

export default OfficeManagerDashboardPage;
