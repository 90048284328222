import {ACTION_CODE} from './_actionCode';
import {ProductionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_PRODUCTIONS';

export async function getAllProductions(
    userData: Record<string, string>,
    before: string,
    after: string,
    optionalProps?: Record<string, any>,
    includeParentDeal  = false ,
    query = ''
) {
    try {
        LoggerService.logInfo('Action - getAllProductions - Start');

        const getAllProductionsResult = await ProductionService.getAllProductions(userData, before, after, optionalProps, query);

        const {data, metadata} = getAllProductionsResult;

        const aggregateProductions = includeParentDeal ? _aggregateProductions(data.results) : data.results ;
        
        LoggerService.logInfo('Action - getAllProductions - End - Success');

        return ResultModel.newSuccessResult(aggregateProductions, metadata);

    }
    catch (error) {
        LoggerService.logInfo('Action - getAllProductions - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}

const _aggregateProductions = (data: any) => {
    if (data.length > 0) {
        const result = [];
        const processedDeals: any = {};

        for (const obj of data) {
            const deal = obj.deal;

            if (processedDeals[deal.id]) {
                const processedDeal = result.find((d) => d.id === deal.id);

                const newChild = {
                    id: obj.id,
                    category: obj.category,
                    ressource: obj.ressource,
                    date: obj.date,
                    produced_days: +obj.produced_days,
                    billed_days: +obj.billed_days,
                    unbilled_days: +obj.produced_days - +obj.billed_days,
                    location: obj.location,
                    notes: obj.notes,
                    mission: obj.mission,
                    deal_name: obj.deal.name,
                    short_name: obj.deal.short_name,
                    deal_id: obj.deal.id,
                    deal_type: obj.deal.type,
                    deal,
                    kind: processedDeal.name === 'Congés' ? 'vacations' : 'productions'
                };

                processedDeal.children.push(newChild);

                if (obj.date < processedDeal.start_date) {
                    processedDeal.start_date = obj.date;
                }

                if (obj.date > processedDeal.end_date) {
                    processedDeal.end_date = obj.date;
                }
                processedDeal.produced_days += +obj.produced_days;
                processedDeal.billed_days += +obj.billed_days;
                processedDeal.unbilled_days = processedDeal.produced_days - processedDeal.billed_days;
            }
            else {
                const newDeal = {...deal, children: []};

                const newChild = {
                    id: obj.id,
                    category: obj.category,
                    ressource: obj.ressource,
                    date: obj.date,
                    produced_days: +obj.produced_days,
                    billed_days: +obj.billed_days,
                    unbilled_days: +obj.produced_days - +obj.billed_days,
                    location: obj.location,
                    notes: obj.notes,
                    mission: obj.mission,
                    deal_name: obj.deal.name,
                    short_name: obj.deal.short_name,
                    deal_id: obj.deal.id,
                    deal_type: obj.deal.type,
                    deal,
                    kind: newDeal.name === 'Congés' ? 'vacations' : 'productions'
                };

                newDeal.children.push(newChild);

                newDeal.start_date = obj.date;
                newDeal.end_date = obj.date;
                newDeal.produced_days = +obj.produced_days;
                newDeal.billed_days = +obj.billed_days;
                newDeal.unbilled_days = +obj.produced_days - +obj.billed_days;
                newDeal.kind = newDeal.name === 'Congés' ? 'vacations' : 'productions';

                result.push(newDeal);
                processedDeals[deal.id] = true;
            }
        }
        const productions = result.filter((item) => item.name !== 'Congés');
        const vacations = result.find((item) => item.name === 'Congés');

        return {
            productions: productions,
            vacations: vacations === undefined ? [] : vacations
        };
    }
    else {
        return {
            productions: [],
            vacations: []
        };
    }
};
