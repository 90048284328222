import {ACTION_CODE} from './_actionCode';
import {TrainingService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_TRAINING_BY_ID';

export async function getTrainingById(userData: Record<string, any>, trainingId: number) {
    try {
        LoggerService.logInfo('Action - getTrainingById - Start');

        const getTrainingByIdResult = await TrainingService.getTrainingById(userData, trainingId);

        LoggerService.logInfo('Action - getTrainingById - End - Success');

        return ResultModel.newSuccessResult(getTrainingByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - getTrainingById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération d\'une formation',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
