export const getCurrentMonthAndYear = (monthOffset = 0, monthOnly = false) => {
    const date = new Date();
    date.setMonth(date.getMonth() + monthOffset);
    const month = date.toLocaleString('fr-FR', { month: 'long' });
    const year = date.getFullYear();

    if (monthOnly) {
        return month;
    }

    return month + ' ' + year;
}