import React from 'react';
import Empty from 'antd/lib/empty';
import {DeleteOutlined, FileTextOutlined} from '@ant-design/icons';

import ContainerCard from '../../../components/ui/ContainerCard';
import ActionsButton from '../../../components/ui/ActionsButton';
import {formatBytes, capitalize} from '../../../utils';

const AttachmentsCard = ({
    data,
    onNewAttachmentButtonClick,
    onDownloadButtonClick,
    onDeleteButtonClick,
    onAttachmentItemClick = {onAttachmentItemClick},
    newAttachmentButtonDisabled,
    downloadAttachmentLoading,
    deleteAttachmentLoading,
    buttonsDisabled
}) => {

    const isImage = (filename) => {
        const regex = /\.(jpg|jpeg|png|gif|bmp)$/i;

        return regex.test(filename);
    }

    return (
        <ContainerCard
            title='Justificatifs'
            buttonContent='Nouveau justificatif'
            onButtonClick={onNewAttachmentButtonClick}
            width='w-1/2'
            height='h-[30em]'
            coloredButtonDisabled={newAttachmentButtonDisabled}
        >
            {
                data.length > 0 ?
                    data.map((attachment) => {
                        return (
                            <React.Fragment key={attachment.id}>
                                <div
                                    className={`
                                        flex items-center justify-center w-full py-7
                                        shadow-none hover:shadow-[0_2px_4px_rgba(0,0,0,0.25)] transition-all px-5
                                    `}
                                >
                                    <div
                                        onClick={() => onAttachmentItemClick(attachment.url)}
                                        className="flex items-center justify-start w-full hover:cursor-pointer"
                                    >
                                        {isImage(attachment.name) ? (
                                            <img src={attachment.url} className='object-cover w-12 h-12 mr-4'/>
                                        ) : (
                                            <FileTextOutlined className='mr-4' style={{fontSize: '48px' ,color: '#e5754b'}}/>
                                        )}
                                        <div className='w-[40%]'>
                                            {
                                                attachment.name.length > 20 ?
                                                    `${capitalize(attachment.name.split('.')[0].slice(0, 15))}(..).${attachment.name.split('.')[1]}` :
                                                    capitalize(attachment.name)
                                            }
                                        </div>
                                        <div className='w-[30%] flex justify-center items-center'>
                                            <div className='w-20'>
                                                {formatBytes(attachment.size, 0)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[30%] flex justify-end items-center'>
                                        <ActionsButton
                                            type='download'
                                            onClick={() => onDownloadButtonClick(attachment.id, attachment.name)}
                                            isLoading={downloadAttachmentLoading === attachment.id}
                                            disabled={buttonsDisabled}
                                        />
                                        <div className='px-3'/>
                                        {
                                            newAttachmentButtonDisabled ?
                                                <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/> :
                                                <ActionsButton
                                                    type='delete'
                                                    onClick={() => onDeleteButtonClick(attachment.id)}
                                                    isLoading={deleteAttachmentLoading === attachment.id}
                                                    disabled={buttonsDisabled}
                                                    deleteConfirmationQuestion='Souhaitez-vous supprimer ce justificatif ?'
                                                />
                                        }
                                    </div>
                                </div>
                                <hr className='border-[#f0f0f0] mx-5 mt-[-1px]'/>
                            </React.Fragment>
                        );
                    }) :
                    <div className='flex items-center justify-center w-full h-full'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                    </div>
            }
        </ContainerCard>
    );
}

export default AttachmentsCard
