import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import Table from '../../components/ui/Table';
import {invoicesTableConfig} from './config/invoicesTableConfig';

const EstimatedInvoicesPage = ({
    data,
    filterInvoicesList,
    userData,
    tableType,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {

    const tableConfig = invoicesTableConfig(filterInvoicesList, userData, tableType);

    return (
        <>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures prévisionnelles (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default EstimatedInvoicesPage;
