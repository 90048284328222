import React from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import Table from '../../components/ui/Table';
import {dealsTableConfig} from './configs/dealsTableConfig';

const ProposalsInProgressPage = ({
    data,
    handleMissionNavigation,
    filterDealList,
    userData,
    tableType,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const tableConfig = dealsTableConfig(handleMissionNavigation, filterDealList, userData, tableType);

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/consultant'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Propositions à rédiger (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default ProposalsInProgressPage;
