import storage from 'redux-persist/lib/storage';

export const SET_DAS = 'SET_DAS';

export const persistUserConfig = {
    key: 'das',
    storage
};

/* eslint-disable no-prototype-builtins */
const initialState = {
    das: null
};

function DasReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DAS: {
            return Object.assign({}, state, {
                das: action.payload.das
            });
        }
        default:
            break;
    }

    return state;
}

export default DasReducer;
