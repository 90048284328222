import {ACTION_CODE} from './_actionCode';
import {TrainingAttendancesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_TRAINING_ATTENDANCE';

export async function createTrainingAttendance(
    userData: Record<string, string>,
    newTrainingAttendance: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createTrainingAttendance - Start');

        const createTrainingAttendanceResult = await TrainingAttendancesService.createTrainingAttendance(userData, newTrainingAttendance);

        LoggerService.logInfo('Action - createTrainingAttendance - End - Success');

        return ResultModel.newSuccessResult(createTrainingAttendanceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createTrainingAttendance - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
