export const aggregateGrossMarginObject = (data) => {

    const getSum = (object, key) => {
        let sum = 0;
    
        for (const k in object) {
            sum += object[k][key];
        }
    
        return sum;
    }

    const getAllDas = () => {
        let array = [];

        for(const key in data) {
            const object = {
                id: key,
                name: key,
                revenue: data[key].revenue,
                contractors: data[key].contractors,
                expenses: data[key].expenses,
                procurements: data[key].procurements,
                gross_margin: data[key].gross_margin
            }

            array.push(object);
        }

        return array;
    }

    let allDas = getAllDas();

    allDas.push({
        id: 1,
        name: "Cabinet (TOTAL)",
        revenue: getSum(data, 'revenue'),
        contractors: getSum(data, 'contractors'),
        expenses: getSum(data, 'expenses'),
        procurements: getSum(data, 'procurements'),
        gross_margin: getSum(data, 'gross_margin')
    });

    return allDas;
}
