import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ArrowLeftOutlined} from '@ant-design/icons';

import Table from '../../components/ui/Table';
import {contractorsTableConfig} from './configs/contractorsTableConfig';
import {notify} from '../../components/ui/Toast/Toast';
import {InvoiceActions, ContractorInvoiceActions} from '../../actions';

const PayableInvoicesPage = ({
    data,
    refreshData,
    filterContractorInvoices,
    tableType,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(null);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handlePayInvoice = async (invoice) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${invoice.id}upd`);

        const upInvoiceContractorResult = await ContractorInvoiceActions.updateContractorInvoice(userData, {paid: true}, invoice.id);

        if (upInvoiceContractorResult.success) {
            await refreshData(true);
            notify('La facture a été mise à jour');
        }
        else {
            notify('La facture n\'a pas pu être mise à jour');
        }
        
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    }

    const handleDeleteInvoice = async (invoice) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${invoice.id}del`);

        const getInvoiceById = await InvoiceActions.getInvoiceById(userData, invoice.invoice.id);

        const deleteInvoiceContractorResult = await InvoiceActions.deleteInvoiceContractor(userData, getInvoiceById.data, invoice.id);

        if (deleteInvoiceContractorResult.success) {
            await refreshData(true);
            notify("La facture a été supprimée avec succès");
        }
        else {
            notify("La facture n'a pas pu être supprimée");
        }

        setActionButtonLoading(null);
        setActionButtonDisabled(false);

    }

    const tableConfig = contractorsTableConfig({
        actionButtonDisabled,
        actionButtonLoading,
        handlePayInvoice,
        handleDeleteInvoice,
        filterContractorInvoices,
        tableType,
        userData
    });

    console.log(data)

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/admin'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures fournisseurs à payer (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default PayableInvoicesPage;
