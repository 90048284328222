import {ACTION_CODE} from './_actionCode';
import {BankService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_BANK';

export async function createBank(userData: Record<string, any>, bank: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createBank - Start');

        const createBankResult = await BankService.createBank(userData, bank);

        LoggerService.logInfo('Action - createBank - End - Success');

        return ResultModel.newSuccessResult(createBankResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createBank - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une banque.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
