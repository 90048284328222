import ActionsButton from '../../../components/ui/ActionsButton';

export const staffingRightTableConfig = ({
    handleEditButtonClick,
    handleDeleteStaffingItem,
    actionItemLoading,
    disabled
}) => {

    const calculateStaffingTotal = (staffing) => {
        let total = 0;
        for (const key in staffing) {
            total += staffing[key].staffed;
        }

        return total.toFixed(1);
    };

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Jh',
                    value: 'jh',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {calculateStaffingTotal(item.staffing)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: '€/j (ETP)',
                    value: 'etp',
                    width: 'w-[15%]',
                    sort: true,
                    tooltipLabel: 'Coût journalier du consultant',
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.daily_rate ? `${item.daily_rate} €` : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Obj. Commercial',
                    value: 'sales_objective',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center font-bold">
                                {`${parseInt(item.sales_objective).toLocaleString('fr-FR')} €`}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Obj. Production',
                    value: 'production_objective',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center font-bold">
                                {`${parseInt(item.production_objective).toLocaleString('fr-FR')} J`}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'J'
                },
                {
                    label: 'Obj. Facturation',
                    value: 'billing_objective',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center font-bold">
                                {`${parseInt(item.billing_objective).toLocaleString('fr-FR')} €`}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[15%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full gap-1">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={() => handleEditButtonClick(item)}
                                    disabled={disabled}
                                    minWidth='w-6'
                                />
                                <ActionsButton
                                    type="delete"
                                    isLoading={actionItemLoading === item.id}
                                    onClick={() => handleDeleteStaffingItem(item.id)}
                                    disabled={disabled}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer ce contrat ?'
                                    minWidth='w-6'
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
