import React from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';
import Badge from '../../../components/ui/Badge';
import {frenchFormat} from '../../../utils';

const InformationsCard = ({data}) => {
    return (
        <ContainerCard
            title='Informations'
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[361px]'
        >
            <div className='flex items-start justify-start'>
                <div className='text-[#646464] text-sm mt-[4px] mr-4'>Ressource</div>
                <Badge type='round' color='#dc3832' content={data?.deal?.production_lead ? [data.deal.production_lead] : []}/>
            </div>
            <div className='flex items-start justify-start my-2'>
                <div className='text-[#646464] text-sm mt-[3px] mr-4'>Affaire</div>
                <div className='text-[#212121] text-base'>{data?.deal?.short_name}</div>
            </div>
            <hr className='w-12 my-5 border-[#212121]'/>
            <div className='flex flex-row items-center justify-between'>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Honoraires</div>
                    <div className='text-[#212121] text-base'>{`${data?.billable} € HT`}</div>
                </div>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>TVA</div>
                    <div className='text-[#212121] text-base'>
                        {data.billable_vat_rate ? `${+(+data.billable_vat_rate * 100).toFixed(2)} %` : '-'}
                    </div>
                </div>
            </div>
            <div className='flex flex-row items-center justify-between my-2'>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Frais</div>
                    <div className='text-[#212121] text-base'>{`${data?.expenses} € HT`}</div>
                </div>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>TVA</div>
                    <div className='text-[#212121] text-base'>
                        {data.expenses_vat_rate ? `${+(+data.expenses_vat_rate * 100).toFixed(2)} %` : '-'}
                    </div>
                </div>
            </div>
            <div className='flex items-start justify-start my-2'>
                <div className='text-[#646464] text-sm mt-[3px] mr-4'>Date de facturation</div>
                <div className='text-[#212121] text-base'>{frenchFormat(data?.billing_date)}</div>
            </div>
            <div className='flex items-start justify-start'>
                <div className='text-[#646464] text-sm mt-[3px] mr-4'>Échéance</div>
                <div className='text-[#212121] text-base'>{data?.due_date ? frenchFormat(data?.due_date) : '-'}</div>
            </div>
        </ContainerCard>
    )
}

export default InformationsCard
