
const TextInputBis = ({
    onChange,
    value,
    label,
    type,
    min,
    placeholder,
    required,
    readOnly,
    disabled,
    error,
    width = 'w-[250px]',
    labelWidth = 'w-36',
    customStyle = ''
}) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center w-full">
                {
                    label.length > 0 &&
                        <div className={`flex flex-row ${labelWidth}`}>
                            <label className="font-normal text-sm text-[#646464]">
                                {label}
                                {
                                    required &&
                                        <span className="ml-1 text-sm font-normal text-red-500">*</span>
                                }
                            </label>
                        </div>
                }
                <input
                    onChange={(e) => onChange(e.target.value)}
                    className={`
                    ${customStyle} ${width} h-[31px] border border-solid border-[#D9D9D9] rounded-sm py-1 px-2 leading-tight
                        focus:outline-none focus:border-[#E36D38]  placeholder:text-[#21212140]/25 disabled:bg-[#efefef]
                    `}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                    min={min}
                    value={value}
                />
            </div>
            {
                error &&
                <div className="text-xs italic text-red-500">
                    {error.message}
                </div>
            }
        </div>
    );
}

export default TextInputBis;
