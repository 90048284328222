import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import DonutsChart from "../../../../components/ui/DonutsChart/DonutsChart";
import {contributionToMarginChartConfig} from "../../configs/resultIndicatorTab/contributionToMarginSection/contributionToMarginChartConfig";
import {aggregateContributionToMarginPerStatus} from "../../../codevDashboardPage/functions/aggregateContributionToMargin";

const ContributionToMarginChart = ({data, dasKey}) => {

    const noData = [
        {
            type: 'Junior',
            value: 0
        },
        {
            type: 'Confirmé',
            value: 0
        },
        {
            type: 'Sénior',
            value: 0
        }
    ];

    return (
        <ContainerCard showHeader={false}>
            <DonutsChart
                label={'%'}
                data={data[dasKey] ? aggregateContributionToMarginPerStatus(data, dasKey) : noData}
                config={contributionToMarginChartConfig}
            />
        </ContainerCard>
    )
};

export default ContributionToMarginChart;
