import {useState, useEffect} from 'react';

import SubsidiaryTabs from './SubsidiaryTabs';
import SimpleTab from '../../../../components/ui/SimpleTab/SimpleTab';

const InvoiceAndPayment = ({
    sentInvoicesData,
    draftInvoicesData,
    pendingContractorInvoicesData,
    toPayContractorInvoicesData,
    currentExpenseReportsData,
    toPayExpenseReportsData,
    displayedSection,
    setDisplayedSection,
    subsidiaries,
    currentSubsidiary,
    setCurrentSubsidiary
}) => {
    const [tabSelectedColor, setTabSelectedColor] = useState(subsidiaries[0]?.is_training ? 'background-gradient-blue' : 'background-gradient');

    useEffect(() => {
        const subsidiary = subsidiaries.filter((el) => el.name === displayedSection)[0];

        setCurrentSubsidiary(subsidiary);

        setTabSelectedColor(subsidiary?.is_training ? 'background-gradient-blue' : 'background-gradient');
    }, [displayedSection])

    const date = new Date();
    const month = date.toLocaleString('fr-FR', {month: 'long'});
    const year = date.getFullYear();

    return (
        <div>
            <div className='my-10'>
                <SimpleTab
                    displayedSection={displayedSection}
                    setDisplayedSection={setDisplayedSection}
                    backgroundColor={tabSelectedColor}
                    sections={subsidiaries}
                />
                <p style={{ fontFamily: 'Din condensed' }} className='text-4xl capitalize mt-7'>{month} {year}</p>
            </div>
            <SubsidiaryTabs
                sentInvoicesData={sentInvoicesData}
                draftInvoicesData={draftInvoicesData}
                pendingContractorInvoicesData={pendingContractorInvoicesData}
                toPayContractorInvoicesData={toPayContractorInvoicesData}
                currentExpenseReportsData={currentExpenseReportsData}
                toPayExpenseReportsData={toPayExpenseReportsData}
                isTraining={currentSubsidiary?.is_training}
            />
        </div>
    )
}

export default InvoiceAndPayment;
