import {useState} from 'react';

import { TrainingAttachmentActions,  FileActions} from './../../../../actions'
import FileDragNDropInput from './../../../../components/ui/Inputs/FileDragNDropInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import checkboxIcon from "../../../../assets/icons/blueStyle/checkbox-input-blue.png";
import checkboxEmptyIcon from "../../../../assets/icons/page/checkbox-empty.png";
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const ConceptionAttachmentsPopup = ({
    userData,
    setView,
    refreshData,
    trainingId,
    teachingResourcesAttachements,
    teachingAidAttachements
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [teachingResources, setTeachingResources] = useState([]);
    const [teachingAid, setTeachingAid] = useState([]);
    const [errors, setErrors] = useState(null);

    const onSubmit = async () => {
        setDisabled(true);
        setLoading(true);

        const newTeachingAid = teachingAid.map(file => ({file: file, name: file.name, type: 'teaching_aid'}));
        const newTeachingRessource = teachingResources.map(file => ({file: file, name: file.name, type: 'teaching_resource'}));

        const allFiles = [...newTeachingAid, ...newTeachingRessource];

        if (allFiles.length > 0) {
            let filesResult = [];

            for (let file of allFiles) {
                const newFile = {
                    "type": file.file.type,
                    "name": file.name,
                    "file": file.file
                }

                const result = await FileActions.createFile(userData, newFile);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    filesResult.push({...result.data, type: file.type});
                }
            }

            await Promise.all(filesResult.map(async file => {
                const payload = {
                    file_id: file.id,
                    training_id: trainingId,
                    type: file.type
                };

                const   result = await TrainingAttachmentActions.createTrainingAttachment(userData, payload);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    await refreshData();
                    notify(`Les documents ont été chargés avec succès.`);
                    setView(false);
                }
            }));

            setDisabled(false);
            setLoading(false);
        }
    }

    const handleChangeTeachingAid = (file) => {
        setTeachingAid(file);
    }

    const handleChangeTeachingRessources = (file) => {
        setTeachingResources(file);
    }

    return (
        <ContainerCard
            coloredButtonDisplayed={false}
            title="Checklist qualité - Conception"
            height='h-[450px]'
        >
            <div className="flex flex-col pt-6 mb-5 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(teachingAidAttachements.length || teachingAid.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Supports pédagogiques</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeTeachingAid}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col pt-6 mt-6 mb-8 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(teachingResourcesAttachements.length || teachingResources.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Ressources pédagogiques</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeTeachingRessources}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'blue'}
                        content={`Ajouter`}
                        onClick={onSubmit}
                        loading={loading}
                        disabled={!(teachingAid.length || teachingResources.length)}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    )
};

export default ConceptionAttachmentsPopup;
