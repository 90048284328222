import {ACTION_CODE} from './_actionCode';
import {InvoiceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_INVOICE';

export async function deleteInvoiceItemById(
    userData: Record<string, any>,
    invoiceId: number,
    invoiceItemId: number,
    payloadUpdate: Record<string, any>
) {
    try {
        const invoice = {...payloadUpdate};
        invoice.items = payloadUpdate.items.filter((item: any) => item.id !== invoiceItemId);

        const newInvoice = {
            deal_id: invoice.deal.id,
            customer_id: invoice.deal.customer.id,
            bank_name: invoice.bank.name,
            subsidiary: invoice.subsidiary,
            type: invoice.type,
            status: invoice.status,
            billing_address: invoice.billing_address,
            billing_date: invoice.billing_date,
            due_date: invoice.due_date,
            billable: invoice.billable,
            billable_vat_rate: invoice.billable_vat_rate ,
            expenses: invoice.expenses,
            expenses_vat_rate: invoice.expenses_vat_rate,
            items: invoice.items
        }

        LoggerService.logInfo('Action - updateInvoiceById - Start');

        const updateInvoiceByIdResult = await InvoiceService.updateInvoiceById(userData, invoiceId, newInvoice);

        LoggerService.logInfo('Action - updateInvoiceById - End - Success');

        return ResultModel.newSuccessResult(updateInvoiceByIdResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateInvoiceById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la suppression de la ligne',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
