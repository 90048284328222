import {useEffect, useState} from 'react';
import {DeleteOutlined} from "@ant-design/icons";
import {useNavigate} from 'react-router-dom';

import Badge from "../../../../components/ui/Badge";
import { UserActions, CustomerActions } from '../../../../actions';
import {SelectInput, TableDatePicker} from '../../../../components/ui/Table';
import {frenchFormat, handleFilterList, useFilterQuery, fetchSelectData} from "../../../../utils";
import ActionsButton from "../../../../components/ui/ActionsButton";

export const dealInvoicesTableConfig = (
    handleDeleteInvoice,
    deleteInvoiceLoading,
    buttonsDisabled,
    blueStyle,
    filterInvoiceList,
    userData,
    cannotBeModified
) => {
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        customer: '',
        status: '',
        lead: '',
        billing_before: '',
        billing_after: '',
        payment_before: '',
        payment_after: '',
        due_before: '',
        due_after: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterInvoiceList);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    }

    const handleGetCustomers = async (search, page = 1) => {
        await fetchSelectData({action: CustomerActions.getAllCustomers, search:`&search=${search}`, page: page, setter: setCustomers, userData});
    }

    useEffect(() => {
        // ↓ customer dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })
    }, []);
    
    const navigate = useNavigate();

    const status = [
        {label: 'Prévisionnelle', value: 0, color: null},
        {label: 'A émettre', value: 50, color: '#7FFFD4'},
        {label: 'À valider', value: 100, color: '#f0a841'},
        {label: 'Validée', value: 200, color: '#e36d38'},
        {label: 'Émise', value: 300, color: '#ced95f'},
        {label: 'Partiellement Réouvrée', value: 400, color: '#ced95f'},
        {label: 'Payée', value: 500, color: '#ced95f'},
        {label: 'Annulée', value: 600, color: '#6c757d'}
    ];

    const getInvoiceStatus = (data) => {
        if (data.credit) return {label: 'Avoir', color: '#f9d828', textColor: 'black'};

        switch (data.status) {
            case 0:
                return {label: 'Prévisionnelle', color: null, textColor: 'white'};
            case 50:
                return {label: 'À émettre', color: '#7FFFD4', textColor: 'white'};
            case 100:
                return {label: 'À valider', color: '#f0a841', textColor: 'white'};
            case 200:
                return {label: 'Validée', color: '#e36d38', textColor: 'white'};
            case 300:
                return {label: 'Émise', value: 300, color: '#ced95f', textColor: 'white'}
            case 400:
                return {label: 'Partiellement Réouvrée', color: '#ced95f', textColor: 'white'};
            case 500:
                return {label: 'Payée', color: '#ced95f', textColor: 'white'};
            case 600:
                return {label: 'Annulée', color: '#212121', textColor: 'white'};
            default:
                return {status: 'Indéfini', color: '#6c757d', textColor: 'white'};
        }
    };
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Numéro facture',
                    value: 'official_id',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.official_id ? `N°${item.official_id}` : "N/A"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        const statusData = getInvoiceStatus(item);

                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <Badge content={statusData.label} color={statusData.color} textColor={statusData.textColor}/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="status"
                        options={status}
                        isSearchable={true}
                        onChange={handleFilter}
                        labelKeys={['label']}
                        valueKey={'value'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Resp. production',
                    value: 'deal.production_lead.username',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {
                                    item.deal.production_lead ?
                                        <Badge content={[item.deal.production_lead]} type='round'/>
                                        : "-"
                                }
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="lead"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Client',
                    value: 'deal.customer.name',
                    width: 'w-[18%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item?.customer.name ?? '-'}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="customer"
                        options={customers?.options?.sort((a, b) => a.name.localeCompare(b.name))}
                        hasNextPage={customers?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetCustomers)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: '€/j'
                },
                {
                    label: 'Facturation',
                    value: 'billing_date',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {frenchFormat(item.billing_date)}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='billing' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Echéance',
                    value: 'due_date',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {
                                    item.due_date ?
                                        frenchFormat(item.due_date)
                                        : '-'
                                }
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='due' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Paiement',
                    value: 'payment_date',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.payment_date ? frenchFormat(item.payment_date) : '-'}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='payment' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net HT',
                    value: 'billable',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <p className={`${item.credit && 'text-red-500'}`}>
                                    {`${item.credit ? '-' : '' }${parseInt(+item.billable + +item.expenses).toLocaleString()} €`}
                                </p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total TVA',
                    value: 'billable_vat',
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <p className={`${item.credit && 'text-red-500'}`}>
                                    {`${item.credit ? '-' : ''}${(+item.billable + +item.billable * (+item.billable_vat_rate) + +item.expenses + +item.expenses * (+item.expenses_vat_rate))
                                        .toLocaleString()} €`}
                                </p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[8%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <>
                                {
                                    item.status >= 300 || cannotBeModified ?
                                        <div className="flex flex-row items-center justify-center">
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </div> :
                                        <div className="flex flex-row items-center justify-center">
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteInvoiceLoading === item.id}
                                                onClick={() => handleDeleteInvoice(item.id)}
                                                disabled={buttonsDisabled}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer cette facture ?'
                                                blueStyle={blueStyle}
                                            />
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => navigate(`/invoices/${item.id}`)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
