import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import ActionsButton from '../../../components/ui/ActionsButton';

export const detailsTableConfig = (
    expenses,
    invoiceStatus,
    buttonsDisabled,
    handleEditItem,
    handleDeleteItem,
    deleteItemLoading
) => {
    return {
        dropdown: false,
        dropdownLeftPadding: false,
        greyBackground: false,
        subline: true,
        columnConfig: [
            {
                label: 'Description',
                value: null,
                width: 'w-[29%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item.text}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Quantité',
                value: 'quantity',
                width: 'w-[12%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center text-center">
                            {+item.quantity}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'PU HT',
                value: 'unit_price',
                width: 'w-[12%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+parseInt(item.unit_price).toLocaleString()}
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: '€'
            },
            {
                label: 'Montant HT',
                value: 'ht_amount',
                width: 'w-[12%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {parseInt(item.ht_amount).toLocaleString()}
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: '€'
            },
            {
                label: 'TVA (%)',
                value: 'vat',
                width: 'w-[12%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+item.vat}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Montant TTC',
                value: 'net_amount',
                width: 'w-[12%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+parseInt(item.net_amount).toLocaleString()}
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: '€'
            },
            {
                label: 'Actions',
                value: null,
                width: 'w-[11%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {
                                (invoiceStatus >= 300) ?
                                    <>
                                        <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        <div className="w-0 h-0 px-2.5"/>
                                        <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                    </> :
                                    <>
                                        <ActionsButton
                                            type="edit"
                                            isLoading={false}
                                            onClick={() => handleEditItem(item)}
                                            disabled={buttonsDisabled}
                                        />
                                        <div className="w-0 h-0 px-2"/>
                                        <ActionsButton
                                            type="delete"
                                            isLoading={deleteItemLoading === item.id}
                                            onClick={() => handleDeleteItem(item.id)}
                                            disabled={buttonsDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette ligne ?'
                                        />
                                    </>
                            }
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            }
        ]
    };
};
