import {isVacation} from './../../../utils/calendarUtility';

const ProductionMonthEvents = ({monthData}) => {
    return (
        <>
            <div>
                {monthData?.deals.map((event, index) => {
                    const dotColor = isVacation(event.short_name) ? 'bg-[#646464]' : 'background-gradient';

                    if (index < 2) {
                        return (
                            <div className="flex flex-row items-center mb-1" key={event.id}>
                                <div className={`${dotColor} w-1.5 h-1.5 mr-2 rounded-full`}/>
                                <p className="text-sm font-medium leading-none text-[#212121]">
                                    {event.short_name.length > 50 ? event.short_name.slice(0, 50) + '...' : event.short_name}
                                </p>
                            </div>
                        );
                    }
                })}
            </div>
            {
                monthData?.count > 2 &&
                    <div className="h-5">
                        <p className="flex items-center justify-center h-5 min-w-[20px] w-fit text-[9px] py-[1px] px-1 font-bold leading-none text-left text-white rounded-full roboto bg-[#212121]">
                            +{monthData.count - 2}
                        </p>
                    </div>
            }
        </>
    );
};

export default ProductionMonthEvents;
