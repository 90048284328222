import {ACTION_CODE} from './_actionCode';
import {SubsidiaryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_SUBSIDIARY';

export async function createSubsidiary(userData: Record<string, any>, subsidiary: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createSubsidiary - Start');

        const createSubsidiaryResult = await SubsidiaryService.createSubsidiary(userData, subsidiary);

        LoggerService.logInfo('Action - createSubsidiary - End - Success');

        return ResultModel.newSuccessResult(createSubsidiaryResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createSubsidiary - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une filiale.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
