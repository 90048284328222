import {ActivitiesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_ACTIVITY';

export async function deleteActivity(token: string, activityId: number | string) {
    try {
        const result = await ActivitiesDao.deleteActivity(token, activityId);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression de l\'activité',
            error: error
        });
    }
}
