import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { BankActions, DealActions, InvoiceActions, ServicesActions } from '../../../../actions';
import Button from '../../../../components/ui/Button/Button';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';
import { fetchSelectData, frenchFormat, getErrors, standardFormat } from '../../../../utils';
import { getStatusFromId, getStatusId, getTypeFromId, getTypeId } from '../../functions';

function CreateNewInvoice({userData, setIsInvoicePopupOpen, invoiceToBeUpdated, setView, refreshData}) {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [deals, setDeals] = useState(null);
    const [banks, setBanks] = useState(null);
    const [services, setServices] = useState([]);
    const [types, setTypes] = useState([]);

    const vat = useSelector((state) => state.GlobalsReducer.vat);

    // -- react hook form --
    const defaultValues = {
        status: {name :"Prévisionnelle"},
        deal_id:null ,
        billing_date: dayjs(),
        billable: null,
        expenses:null ,
        bank_name:null,
        type:null ,
        billing_adress:null ,
        due_date: dayjs().add(1, 'month'),
        billable_vat_rate:vat ,
        expenses_vat_rate:vat ,
        notes: '' ,
        customer_id:null
    };

    const {handleSubmit, control, reset, formState: {isValid}, setValue} = useForm({defaultValues: defaultValues});

    // -- fill invoice values in form for update --
    useEffect(() => {
        if (invoiceToBeUpdated && invoiceToBeUpdated.id) {
            reset({
                status: {name: getStatusFromId(invoiceToBeUpdated?.status)},
                deal: invoiceToBeUpdated?.deal,
                billing_date: invoiceToBeUpdated?.billing_date ,
                billable: (+invoiceToBeUpdated?.billable).toString(),
                expenses: (+invoiceToBeUpdated?.expenses).toString(),
                bank: {name: invoiceToBeUpdated?.bank?.name},
                type: {name: getTypeFromId(invoiceToBeUpdated?.type)},
                billing_address: invoiceToBeUpdated?.billing_address,
                due_date: invoiceToBeUpdated?.due_date,
                billable_vat_rate: (+invoiceToBeUpdated?.billable_vat_rate * 100),
                expenses_vat_rate: (+invoiceToBeUpdated?.expenses_vat_rate * 100),
                customer_id: invoiceToBeUpdated?.deal?.customer?.id,
                notes: invoiceToBeUpdated?.notes
            });
        }
    }, [invoiceToBeUpdated]);

    // -- dropdowns values --
    // const typesDropdownValues = [
    //     {name: 'Honoraires'},
    //     {name: 'Mise à disposition de personnel'},
    //     {name: 'Frais'}
    // ];

    // ↓ status dropdown values
    const statusDropdownValues = [
        {name: 'Prévisionnelle'},
        {name: 'À vérifier'},
        {name: 'À émettre'},
        {name: 'Émise'},
        {name: 'Partiellement recouvrée'},
        {name: 'Recouvrée'},
        {name: 'Annulée'}
    ];

    // -- functions that get data for Select Inputs --
    const handleGetBanks = async (search, page = 1) => {
        await fetchSelectData({action: BankActions.getAllBanks, search:`&search=${search}&active=true`, page: page, setter: setBanks, userData, resultPath: 'data'});
    };

    const handleGetDeals = async (search, page = 1) => {
        // eslint-disable-next-line max-len
        await fetchSelectData({action: DealActions.getAllDeals, search:`&type=business&display=${userData.userProfileName}&search=${search}`, page: page, setter: setDeals, userData, resultPath: 'data'});
    };

    const handleGetServices = async (search, page = 1) => {
        // eslint-disable-next-line max-len
        await fetchSelectData({action: ServicesActions.getAllServices, search:`&search=${search}`, page: page, setter: setServices, userData: userData.userToken, resultPath: 'data'});
    };

    useEffect(() => {
        if (!userData) {
            return;
        }
        // ↓ deals dropdown values
        (async () => {
            await handleGetDeals('', 1);
        })();
        // ↓ banks dropdown values
        (async () => {
            await handleGetBanks('', 1);
        })();
        // ↓ services dropdown values
        (async () => {
            await handleGetServices('', 1);
        })();
    }, []);

    //TODO: How to get Honoraire type ID ?
    useEffect(() => {
        if (!services.options) return;
        
        setTypes([
            {name: 'Honoraires'},
            ...services.options.map((service) => ({name: `OD: ${service.short_name}`}))
        ])
    }, [services]);

    const handleSendForValidation = async () => {

        const result = await InvoiceActions.updateInvoiceById(userData, invoiceToBeUpdated.id, {status: 100});

        if (result.success) {
            await refreshData();
            notify('Votre facture a été envoyée pour validation');
        }
        else {
            notify('Votre facture n\'a pas pu être envoyée pour validation');
        }

        setIsInvoicePopupOpen(false);
    };

    const handleSendForIssue = async () => {

        const result = await InvoiceActions.updateInvoiceById(userData, invoiceToBeUpdated.id, {status: 200});

        console.log("result", result);

        if (result.success) {
            await refreshData();
            notify('Votre facture a été envoyée à émettre');
        }
        else {
            notify('Votre facture n\'a pas pu être envoyée à émettre');
        }

        setIsInvoicePopupOpen(false);
    };

    const onButtonClick = () => {
        if (invoiceToBeUpdated?.status === 50) {
            handleSendForValidation();
        }
        else if (invoiceToBeUpdated?.status === 100) {
            handleSendForIssue();
        }
        else return null;
    };

    const getTitle = () => {
        if (invoiceToBeUpdated && invoiceToBeUpdated?.id) {
            switch (invoiceToBeUpdated?.status) {
                case 0:
                    return 'Éditer la facture';
                case 50:
                    return 'Éditer la facture';
                case 100:
                    return 'Éditer la facture';
                case 200:
                    return 'Émettre la facture';
                default:
                    return 'Éditer la facture';
            }
        }
        else {
            return 'Nouvelle Facture';
        }
    }

    const sendButtonDisplay = invoiceToBeUpdated?.status === 50 || invoiceToBeUpdated?.status === 100;

    // -- handler functions --
    const onSubmit = async (data) => {
        setDisabled(true);
        setLoading(true);
        setErrors(null);
    
        // ↓ body to send to the post/patch request (see API documentation)
        const invoice = {
            status: getStatusId(data.status.name),
            deal_id: invoiceToBeUpdated?.deal_id ? data.deal_id : data.deal.id,
            billing_date: standardFormat(data.billing_date),
            billable: data.billable,
            expenses: data.expenses,
            bank_name: data.bank.name,
            type: invoiceToBeUpdated?.type ? getTypeId(data.type.name) : getTypeId(data.type.name),
            billing_address: data.billing_address,
            due_date: standardFormat(data.due_date),
            billable_vat_rate: (data.billable_vat_rate / 100).toString() ,
            expenses_vat_rate: (data.expenses_vat_rate / 100).toString(),
            customer_id: invoiceToBeUpdated?.customer_id ? data.customer_id : data?.deal?.customer?.id,
            notes: invoiceToBeUpdated?.notes ? data.notes : data.notes ? data.notes : "",
            subsidiary: data.deal.subsidiary?.name ?? ''
        };
    
        // ↓ post - new invoice
        if (!invoiceToBeUpdated) {

            const result = await InvoiceActions.createInvoice(userData, invoice);
    
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre facture à bien été créée');
    
                setView(false);
                reset();
            }
    
            setLoading(false);
            setDisabled(false);
        }
        // ↓ patch - update invoice
        else {
              
            const result = await InvoiceActions.updateInvoiceById(userData, invoiceToBeUpdated.id, invoice);
    
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                notify('Votre facture à bien été modifiée');
                await refreshData();
    
                setView(false);
                reset();
            }
            
            setLoading(false);
            setDisabled(false);
        }
    };

    return (
        <ContainerCard
            title={getTitle()}
            buttonContent={invoiceToBeUpdated?.status === 50 ? 'Envoyer à valider' : "Envoyer à émettre"}
            onButtonClick={onButtonClick}
            coloredButtonDisabled={(!deals || !banks) ? true : !isValid}
            coloredButtonDisplayed={sendButtonDisplay}
            overflow='visible'
        >
            <div>
                {
                    ((invoiceToBeUpdated && invoiceToBeUpdated.status < 300) || !invoiceToBeUpdated) ?
                        (<div className="flex flex-row justify-between py-5 pl-4 pr-4">
                            <div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="status"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner un statut.'
                                        }}}
                                        render={({
                                            field: {onChange, value, ref},
                                            fieldState: {error}
                                        }) => (
                                            <SelectInput
                                                label='Statut'
                                                placeholder='Statut'
                                                inputRef={ref}
                                                value={value}
                                                error={error}
                                                options={statusDropdownValues}
                                                onChange={onChange}
                                                loading={!statusDropdownValues}
                                                isSearchable={true}
                                                fetchFunction={handleGetBanks}
                                                labelKeys={['name']}
                                                required={true}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="deal"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une affaire.'
                                        }}}
                                        render={({
                                            field: {onChange, value, ref},
                                            fieldState: {error}
                                        }) => (
                                            <SelectInput
                                                label='Affaire'
                                                placeholder='Affaire'
                                                inputRef={ref}
                                                value={value}
                                                error={error}
                                                options={deals?.options || []}
                                                hasNextPage={deals?.hasNextPage || false}
                                                fetchFunction={handleGetDeals}
                                                loading={!deals}
                                                onChange={onChange}
                                                labelKeys={['short_name']}
                                                isSearchable={true}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="billing_date"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une date.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <DateInput
                                                label={'Date de facturation'}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="billable"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez saisir le montant d\'honoraire.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <TextInput
                                                label={'Honoraires Montant HT [€]'}
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                                placeholder="Honoraires Montant HT"
                                                type="number"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="expenses"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez saisir le frais.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <TextInput
                                                label={'Frais Montant HT [€]'}
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                                placeholder="Frais Montant HT"
                                                type="number"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="bank"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une banque.'
                                        }}}
                                        render={({
                                            field: {onChange, value, ref},
                                            fieldState: {error}
                                        }) => (
                                            <SelectInput
                                                label='Compte bancaire'
                                                placeholder='Compte bancaire'
                                                inputRef={ref}
                                                value={value}
                                                error={error}
                                                options={banks?.options || []}
                                                hasNextPage={banks?.hasNextPage || false}
                                                loading={!banks}
                                                onChange={onChange}
                                                isSearchable={true}
                                                fetchFunction={handleGetBanks}
                                                labelKeys={['name']}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col justify-between">
                                <div className="flex mb-2">
                                    <Controller
                                        name="type"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner un type de facture.'
                                        }}}
                                        render={({
                                            field: {onChange, value, ref},
                                            fieldState: {error}
                                        }) => (
                                            <SelectInput
                                                label='Type'
                                                placeholder='Type'
                                                inputRef={ref}
                                                value={value}
                                                error={error}
                                                options={types}
                                                loading={!types}
                                                onChange={onChange}
                                                isSearchable={true}
                                                labelKeys={['name']}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="billing_address"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez saisir une adresse.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <TextInput
                                                label={'Adresse de facturation'}
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                                placeholder="Adresse"
                                                type="text"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="due_date"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une date.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <DateInput
                                                label={"Date d'écheance"}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="billable_vat_rate"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Veuillez saisir la tva d\'honoraires'
                                            }
                                        }}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <TextInput
                                                label={'Honoraires TVA [%]'}
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                                placeholder="Honoraires TVA"
                                                type="number"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="expenses_vat_rate"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez saisir la tva des frais'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <TextInput
                                                label={'Frais TVA [%]'}
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                required={true}
                                                disabled={invoiceToBeUpdated?.status === 0 ? false : invoiceToBeUpdated?.status === 200 && !disabled ? true  : disabled}
                                                placeholder="Frais TVA"
                                                type="number"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>)
                        : null
                }
                <div className="px-5">
                    <div className={(invoiceToBeUpdated?.status < 300 || !invoiceToBeUpdated) ? 'py-4 border-t border-borderGrey' : 'py-4'}>
                        <Controller
                            name="notes"
                            control={control}
                            render={({
                                field: {onChange, value}
                            }) => (
                                <>
                                    <div className="font-normal text-sm text-[#646464] mb-2">Veuillez ajouter une note</div>
                                    <TextAreaInput
                                        placeholder={'Tapez votre texte'}
                                        maxLength={100}
                                        onChange={onChange}
                                        value={value}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center mt-6">
                <div className="flex justify-center">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    {
                        (!invoiceToBeUpdated || invoiceToBeUpdated.date && !invoiceToBeUpdated.id) ?
                            <Button
                                type={'primary'}
                                content={'Ajouter la facture'}
                                onClick={handleSubmit(onSubmit)}
                                loading={loading}
                                disabled={!isValid}
                            /> : null
                    }
                    {
                        (invoiceToBeUpdated && invoiceToBeUpdated.id) ?
                            <Button
                                type={'primary'}
                                content={"Enregistrer"}
                                onClick={handleSubmit(onSubmit)}
                                loading={loading}
                                disabled={(!deals || !banks) ? true : !isValid}
                            /> : null
                    }
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewInvoice;
