import {ExpenseDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_AGGREGATED_CALENDAR_EXPENSES_BY_DAY';

export async function getAggregatedCalendarExpensesByDay(
    userData: Record<string, string>,
    before: string,
    after: string,
    query?: string
) {
    try {
        return await ExpenseDao.getAggregatedCalendarExpensesByDay(userData, before, after, query);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des frais',
            error: error
        });
    }
}
