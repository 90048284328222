import {ACTION_CODE} from './_actionCode';
import {TrainingObjectiveService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'UPDATE_TRAINING_OBJECTIVE';

export async function updateTrainingObjective(
    userData: Record<string, string>,
    id: number,
    updatedTrainingObjective: Record<string, any>,
) {
    try {

        LoggerService.logInfo('Action - updateTrainingObjective - Start');

        const updateTrainingObjectiveResult = await TrainingObjectiveService.updateTrainingObjective(userData, id, updatedTrainingObjective);

        LoggerService.logInfo('Action - updateTrainingObjective - End - Success');

        return ResultModel.newSuccessResult(updateTrainingObjectiveResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateTrainingObjective - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
