import React from 'react';

import Button from '../../../components/ui/Button';
import plus from '../../../assets/icons/button/plus.png';
import Table from '../../../components/ui/Table';

const ContractorsDataSection = ({
    data,
    tableConfig,
    tableLoading,
    handleButtonClick,
    handleGetAllContractors,
    contractorsPaginationMetadata
}) => {
    return (
        <div className='px-20 pt-12 pb-36'>
            <div className="flex items-center justify-between mb-8">
                <div className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>
                    Sous/Co-traitants
                    <span className="text-xs ml-1 text-[#646464]">
                        ({data.length})
                    </span>
                </div>
                <Button
                    content={"Nouveau Sous/Co-traitant"}
                    icon={plus}
                    onClick={handleButtonClick}
                />
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                loading={tableLoading}
                paginationMetadata={contractorsPaginationMetadata}
                onPageNumberChange={handleGetAllContractors}
            />
        </div>
    )
}

export default ContractorsDataSection;
