import Tooltip from 'antd/lib/tooltip/index.js';
import {isVacation} from './../../../utils/calendarUtility';

const ProductionDayEvents = ({dayData}) => {
    return (
        <>
            <div>
                {dayData?.deals.map((event, index) => {
                    const dotColor = isVacation(event.short_name) ? 'bg-[#646464]' : 'background-gradient';

                    if (index < 2) {
                        return (
                            <div className="flex flex-row items-center mb-1" key={event.id}>
                                <div className={`${dotColor} w-1.5 h-1.5 mr-2 rounded-full`}/>
                                <Tooltip title={event.short_name}>
                                    <p className="text-sm leading-none">
                                        {event.short_name.length > 14 ? event.short_name.slice(0, 14) + '...' : event.short_name}
                                    </p>
                                </Tooltip>
                            </div>
                        );
                    }
                })}
            </div>
            
            <div className="flex items-center justify-between h-5">
                {
                    (dayData?.produced_days) &&
                        <p className="text-left text-gradient w-fit">
                            {dayData?.produced_days.toFixed(2)}j
                        </p>
                }
                {
                    dayData?.count > 2 &&
                        <p className="flex items-center justify-center h-5 min-w-[20px] w-fit text-[9px] py-[1px] px-1 font-bold leading-none text-left text-white rounded-full roboto bg-[#212121]">
                            +{dayData?.count - 2}
                        </p>
                }
            </div>
        </>
    );
};

export default ProductionDayEvents;
