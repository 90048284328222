import SimpleCard from '../../../../../components/ui/SimpleCard/SimpleCard';
import * as Utils from '../../../../../utils';

const BottomComponent = ({workloads}) => {
    if (Object.keys(workloads).length === 0) {
        return null;
    }

    const { projection_m1, projection_m2, projection_m3, projection_q1, projection_s1  } = workloads;

    const {
        nextMonth1Str,
        nextMonth2Str,
        nextQuarterEndStr,
        nextQuarterStartStr,
        nextSemesterEndStr,
        nextSemesterStartStr,
        monthNames,
        currentMonth,
        currentYear
    } = Utils.getRangeTitleDate();

    return (
        <div className='flex flex-wrap justify-between px-6 pb-5 mt-4 gap-y-5'>
            <SimpleCard
                topComponent={<DetailsChargeIndicator projection={projection_m1}/>}
                topTitle={monthNames[currentMonth] + ' ' + currentYear}
                background='white'
                font='orange'
                lineColor='gray'
                width='w-[31%]'
                title={(projection_m1.planned + projection_m1.potential).toFixed(2)}
                subTitle="Total de charge"
            />
            <SimpleCard
                topComponent={<DetailsChargeIndicator projection={projection_m2}/>}
                topTitle={nextMonth1Str}
                background='white'
                font='orange'
                lineColor='gray'
                width='w-[31%]'
                title={projection_m2.planned + projection_m2.potential}
                subTitle="Total de charge"
            />
            <SimpleCard
                topComponent={<DetailsChargeIndicator projection={projection_m3}/>}
                topTitle={nextMonth2Str}
                background='white'
                font='orange'
                lineColor='gray'
                width='w-[31%]'
                title={projection_m3.planned + projection_m3.potential}
                subTitle="Total de charge"
            />
            <SimpleCard
                topComponent={<DetailsChargeIndicator projection={projection_q1}/>}
                topTitle={nextQuarterStartStr + ' - ' +  nextQuarterEndStr}
                background='white'
                font='orange'
                lineColor='gray'
                width='w-[48%]'
                title={projection_q1.planned + projection_q1.potential}
                subTitle="Total de charge"
            />
            <SimpleCard
                topComponent={<DetailsChargeIndicator projection={projection_s1}/>}
                topTitle={nextSemesterStartStr + ' - ' + nextSemesterEndStr}
                background='white'
                font='orange'
                lineColor='gray'
                width='w-[48%]'
                title={projection_s1.planned + projection_s1.potential}
                subTitle="Total de charge"
            />
        </div>
    )

}

export default BottomComponent

export const DetailsChargeIndicator = ({ projection }) => {
    return (
        <div className='flex justify-between px-6 pb-6'>
            <div className='text-[#F0A841] flex flex-col items-center justify-center w-[40%]'>
                <p style={{ fontFamily: 'Din Condensed' }} className='mb-1 text-2xl'>{projection.planned}</p>
                <p className='text-xs text-center'>Jours de production</p>
                <div className='h-[1px] w-full bg-[#F0A841] mt-2'/>
            </div>

            <div className='text-[#DC3832] flex flex-col items-center justify-center w-[40%]'>
                <p style={{ fontFamily: 'Din Condensed' }} className='mb-1 text-2xl'>{projection.potential}</p>
                <p className='text-xs text-center'>Jours prévisionnels</p>
                <div className='h-[1px] w-full bg-[#DC3832] mt-2'/>
            </div>
        </div>
    )
}