import ActionsButton from '../../../../components/ui/ActionsButton';

export const operationsConfig = (
    handleDeleteService,
    setIsPopupOpen,
    setToBeUpdated,
    actionButtonLoading,
    actionButtonDisabled,
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: true,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'type.short_name',
                    width: 'w-[45%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-start">
                                {item.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: 'notes',
                    width: 'w-[45%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.notes ? item.notes : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={() => {
                                        setToBeUpdated(item);
                                        setIsPopupOpen(true);
                                    }}
                                    disabled={actionButtonDisabled}
                                />
                                <div className="w-0 h-0 px-2"/>
                                <ActionsButton
                                    type="delete"
                                    isLoading={actionButtonLoading === item.id}
                                    onClick={() => handleDeleteService(item.id)}
                                    disabled={actionButtonDisabled}
                                    deleteConfirmationQuestion={`Souhaitez-vous supprimer cette opération?`}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
