import {useEffect, useState} from "react";

import Badge from "../../../components/ui/Badge";
import {useFilterQuery, fetchSelectData, handleFilterList} from "../../../utils";
import {UserActions, DealDasActions} from "../../../actions";
import {SelectInput} from "../../../components/ui/Table";

export const staffingLeftTableConfig = (userData, handleFilterStaffingChange) => {
    const [ressources, setRessources] = useState([]);
    const [dealDas, setDealDas] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        das: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, handleFilterStaffingChange);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({
            action: UserActions.getAllSortedUsers,
            search:`&search=${search}`,
            page: page || null,
            setter: setRessources,
            userData
        });
    }

    const handleGetDealDas = async (search, page = 1) => {
        await fetchSelectData({
            action: DealDasActions.getAllDealDas,
            search:`&search=${search}`,
            page: page || null,
            setter: setDealDas,
            userData
        });
    };

    useEffect(() => {
        // ↓ deal das dropdown values
        (async () => {
            await handleGetDealDas('', 1);
        })();

        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Ressource',
                    value: 'ressource',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                <Badge content={[item.ressource]} type="rounded"/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="ressource"
                        options={ressources?.options}
                        hasNextPage={ressources?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        width='w-full'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'DAS',
                    value: 'das',
                    width: 'w-[18%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={item.das} type="rounded" color="linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%)"/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="das"
                        options={dealDas?.options?.sort((a, b) => a.name.localeCompare(b.name))}
                        hasNextPage={dealDas?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetDealDas)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        width='w-full'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Terre d\'acceuil',
                    value: 'subsidiary',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={item.office}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Profil',
                    value: 'profile',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        const profiles = [
                            {
                                id: 1,
                                name: 'Dirigeant',
                                value: 'PA'
                            },
                            {
                                id: 2,
                                name: 'Manager',
                                value: 'MA'
                            },
                            {
                                id: 3,
                                name: 'Off. Man.',
                                value: 'OM'
                            },
                            {
                                id: 4,
                                name: 'Consultant',
                                value: 'CO'
                            }
                        ];

                        let profile = profiles.find(el => el.value === item.ressource.user_class);
                        profile = profile ? profile.name : '-';

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {profile}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'statut',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.seniority}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
