import {ACTION_CODE} from './_actionCode';
import {LocationService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_LOCATION';

export async function deleteLocation(userData: Record<string, any>, locationId: number) {
    try {
        LoggerService.logInfo('Action - deleteLocationById - Start');

        const deleteLocationByIdResult = await LocationService.deleteLocation(userData, locationId);

        LoggerService.logInfo('Action - deleteLocationById - End - Success');

        return ResultModel.newSuccessResult(deleteLocationByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteLocationById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
