import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CodevDashboardPage from '../pages/codevDashboardPage/CodevDashboardPage';

function CodevDashboardRouter() {
    return (
        <Routes>
            <Route path='/*' element={<CodevDashboardPage/>}/>
        </Routes>
    );
}

export default CodevDashboardRouter;
