import {CustomersDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_CUSTOMER';

export async function deleteCustomer(
    userData: Record<string, string>,
    customerId: number
) {
    try {
        const deleteCustomerResult = await CustomersDao.deleteCustomer(userData.userToken, customerId);

        return deleteCustomerResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du client.',
            error: error
        });
    }
}
