import {Link} from 'react-router-dom';
import {FileUnknownFilled, HourglassFilled} from '@ant-design/icons';

import Title from '../../../components/ui/Title';
import SimpleCard from '../../../components/ui/SimpleCard/SimpleCard';

const CommercialIndicator = ({totalDealsAwaitingResponseItems, totalDealsPendingItems,  currentDate}) => {

    const salesAnsweringIcon = <FileUnknownFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const salesWaitingIcon = <HourglassFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;

    return (
        <div className='my-10'>
            <Title title={currentDate} textSize='text-3xl'/>
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <Link style={{width: '49%'}} to='answering-proposals'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={salesAnsweringIcon}
                        title={totalDealsAwaitingResponseItems ?? '-'}
                        subTitle="Propositions en cours de réponse"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '49%'}} to='pending-proposals'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={salesWaitingIcon}
                        title={totalDealsPendingItems ?? '-'}
                        subTitle="Propositions en attente"
                        hoverEffect={true}
                    />
                </Link>
            </div>
        </div>
    )
}

export default CommercialIndicator