import TradeReport from './TradeReport';
import ActivityIndicators from './ActivityIndicators';

const CommercialTab = ({
    salesActivity,
    salesOverview,
    handleDatePickerChange,
    datePicker,
    dasKey
}) => {

    return (
        <div>
            <ActivityIndicators
                data={salesActivity}
                dasKey={dasKey}
            />
            <TradeReport
                data={salesOverview}
                datePickerDate={datePicker}
                handleDatePickerChange={handleDatePickerChange}
                dasKey={dasKey}
            />
        </div>
    )
}

export default CommercialTab;
