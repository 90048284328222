import {ACTION_CODE} from './_actionCode';
import {CustomerService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_CUSTOMERS';

export async function deleteCustomer(
    userData: Record<string, string>,
    customerId: number
) {
    try {
        LoggerService.logInfo('Action - deleteCustomer - Start');

        const deleteCustomerResult = await CustomerService.deleteCustomer(userData, customerId);

        LoggerService.logInfo('Action - deleteCustomer - End - Success');

        return ResultModel.newSuccessResult(deleteCustomerResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteCustomer - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du client.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
