import React, {useEffect, useState} from 'react';
import {DatePicker} from 'antd';
import {ConfigProvider} from 'antd';
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './datepicker.css';

import suffix from '../../../assets/icons/datepicker/suffix.png';
import separator from '../../../assets/icons/datepicker/separator.png';
import suffixBlue from '../../../assets/icons/blueStyle/calendar-blue.png';

/**
 * @author Honsse Gadacha
 *
 * props:
 *
 * defaultValue = object:
 * si type === "basic" {date: new Date(<string date>)}
 * si type === "range" {startDate: new Date(<string date>), endDate: new Date(<string date>)}
 * onChange = function a executé onChange (default return null)
 *
 * type = basic, range (default basic)
 *
 * picker = week, month, quarter, year (default normal)
 *
 * disabled = true/false default false
 *
 * allowClear = true/false (default true) Afficher ou non le bouton d'effacement
 *
 */

const Datepicker = ({
    defaultValue = null,
    onChange = () => {return null;},
    type = 'basic',
    picker = 'date',
    disabled = false,
    allowClear = true,
    blueStyle = false
}) => {
    const {RangePicker} = DatePicker;

    const [value, setValue] = useState(null);

    const handleChange = (e) => {
        setValue(e);

        if (type === 'range') {
            const dateValue = {
                startDate: e ? new Date(e[0].$d) : null,
                endDate: e ? new Date(e[1].$d) : null
            };
            onChange(dateValue);
        }
        else {
            const dateValue = {
                date: e ? new Date(e.$d) : null
            };
            onChange(dateValue);
        }
    };

    useEffect( () => {
        if (defaultValue && type === 'basic') {
            Object.keys(defaultValue).length === 1 &&
            setValue(dayjs(defaultValue.date));
        }
        else if (defaultValue && type === 'range') {
            Object.keys(defaultValue).length === 2 &&
            setValue([dayjs(defaultValue.startDate), dayjs(defaultValue.endDate)]);
        }
    }, [defaultValue, type]);

    return (
        <ConfigProvider locale={locale}>
            {
                type === 'range' ?
                    <RangePicker
                        value={value}
                        onChange={handleChange}
                        picker={picker}
                        format="DD/MM/YYYY"
                        disabled={disabled}
                        allowClear={allowClear}
                        suffixIcon={<img src={blueStyle ? suffixBlue : suffix} alt='icon' className="w-6"/>}
                        separator={<img src={separator} alt='icon' className="w-4"/>}
                        className="main-date-picker"
                    /> :
                    <DatePicker
                        value={value}
                        onChange={handleChange}
                        picker={picker}
                        format="DD/MM/YYYY"
                        disabled={disabled}
                        allowClear={allowClear}
                        suffixIcon={<img src={blueStyle ? suffixBlue : suffix} alt='icon' className="w-6"/>}
                        className="main-date-picker"
                    />
            }
        </ConfigProvider>
    );
};

export default Datepicker;
