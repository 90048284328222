import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Tooltip from 'antd/lib/tooltip';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import plus from '../../../../assets/icons/button/plus.png';
import {ContractorActions} from '../../../../actions';
import {DealActions} from '../../../../actions';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../assets/icons/blueStyle/delete-blue.png';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import {fetchSelectData} from '../../../../utils';
import CheckboxInput from '../../../../components/ui/Inputs/CheckboxInput/CheckboxInput';

const ContractorsPopup = ({
    setView,
    data,
    refreshData,
    blueStyle,
    setSalesPopupOverflow
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [contractorsValues, setContractorsValues] = useState([]);
    const [contractors, setContractors] = useState(null);
    const [saleInputsInvalidity, setSaleInputsInvalidity] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // -- functions that get data for Select Inputs --
    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({action: ContractorActions.getAllContractors, search:`&search=${search}`, page: page, setter: setContractors, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ contractors dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();

    }, []);

    useEffect(() => {
        const findEmpty = contractorsValues.find((contractor) => {
            return !contractor.contractor || !contractor.budget || !contractor.billable_days;
        });

        if (
            findEmpty ||
            (contractorsValues.length === 0 && !data.contractors.length === 0) ||
            contractorsValues.length === 0 && data.contractors.length === 0
        ) {
            setSaleInputsInvalidity(true);
        }
        else {
            setSaleInputsInvalidity(false);
        }
    },[contractorsValues])

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setContractorsValues(data.contractors.map((contractor, index) => {
                return (
                    {
                        id: `${index}`,
                        contractor: contractor.contractor,
                        budget: contractor.budget,
                        billable_days: contractor.billable_days,
                        direct: contractor.direct,
                        is_cocontractor: contractor.is_cocontractor
                    }
                );
            }));
        }
    }, [data])

    useEffect(() => {
        if (contractorsValues.length <= 4) {
            setSalesPopupOverflow('');
        }
        else {
            setSalesPopupOverflow('overflow-y-auto');
        }
    }, [contractorsValues])

    const handleAddSale = () => {
        if (contractorsValues.length < 10) {
            setContractorsValues((prevState) => [...prevState, {
                id: Math.random(),
                contractor: '',
                budget: '',
                billable_days: '',
                direct: false,
                is_cocontractor: false
            }]);
        }
    }

    const handleRemoveSale = (id) => {
        setContractorsValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleSaleValueChange = (e, id, type) => {
        setContractorsValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }

    const onSubmit = async () => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);
        setSaleInputsInvalidity(true);

        const updatedDeal = {
            contractors: contractorsValues.map((contractor) => ({
                contractor_id: contractor.contractor.id,
                budget: contractor.budget,
                billable_days: contractor.billable_days,
                direct: contractor.direct,
                is_cocontractor: contractor.is_cocontractor
            }))
        }
        
        const result = await DealActions.updateDeal(userData, updatedDeal, data.id);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            setView(false);
            notify('Les informations ont été mis à jour');
        }

        setLoading(false);
        setDisabled(false);
        setSaleInputsInvalidity(false);
    }

    return (
        <ContainerCard
            title='Honoraires sous-traitance'
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex items-start justify-start px-4 mb-7">
                <div className="font-normal h-6 text-sm text-[#646464] w-[10rem]">
                Budget fournisseur
                    <span className='ml-1 text-sm font-normal text-red-500'> *</span>
                </div>
                <div>
                    <div className='flex flex-col items-center justify-center'>
                        {
                            contractorsValues.map((contractor) => {
                                return (
                                    <div key={contractor.id} className='flex items-center mb-3.5'>
                                        <div className='mr-10'>
                                            <SelectInput
                                                label=''
                                                placeholder='Sous traitant'
                                                value={contractor.contractor}
                                                options={contractors?.options}
                                                hasNextPage={contractors?.hasNextPage}
                                                loading={!contractors}
                                                onChange={(e) => handleSaleValueChange(e, contractor.id, 'contractor')}
                                                isSearchable={true}
                                                fetchFunction={handleGetContractors}
                                                labelKeys={['name']}
                                                required={true}
                                                disabled={disabled}
                                                blueStyle={blueStyle}
                                                width='w-40'
                                            />
                                        </div>
                                        <div className='flex flex-row items-center justify-start mr-5'>
                                            <TextInput
                                                label=''
                                                onChange={(e) => handleSaleValueChange(e, contractor.id, 'budget')}
                                                value={contractor.budget}
                                                error={null}
                                                required={true}
                                                disabled={disabled}
                                                placeholder="Budget"
                                                type="number"
                                                width='w-24'
                                            />
                                            <p className='ml-2'>€</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-start mr-5'>
                                            <TextInput
                                                label=''
                                                onChange={(e) => handleSaleValueChange(e, contractor.id, 'billable_days')}
                                                value={contractor.billable_days}
                                                error={null}
                                                required={true}
                                                disabled={disabled}
                                                placeholder="Jours"
                                                type="number"
                                                width='w-20'
                                            />
                                            <p className='ml-2'>Jours</p>
                                        </div>

                                        <div className='mr-5'>
                                            <CheckboxInput
                                                label='Paiement direct'
                                                value={contractor.direct}
                                                onChange={(e) => handleSaleValueChange(e, contractor.id, 'direct')}
                                            />
                                        </div>

                                        <div className='mr-5'>
                                            <CheckboxInput
                                                label='Co-traitant'
                                                value={contractor.is_cocontractor}
                                                onChange={(e) => handleSaleValueChange(e, contractor.id, 'is_cocontractor')}
                                            />
                                        </div>
                                        
                                        <button
                                            onClick={() => handleRemoveSale(contractor.id)}
                                            disabled={disabled}
                                            className="flex items-center justify-center p-1 bg-transparent"
                                        >
                                            <Tooltip title='Supprimer'>
                                                <div>
                                                    <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <button
                        onClick={handleAddSale}
                        disabled={!(contractorsValues.length < 10)}
                        className={`
                                p-2 rounded-full ${contractorsValues.length < 10 ? 'hover:opacity-90' : ''}
                                ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}
                                ${!(contractorsValues.length < 10) ? 'opacity-[40%]' : 'opacity-100'}
                            `}
                    >
                        <img src={plus} alt='plus' className='w-6'/>
                    </button>
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Sauvegarder'}
                        onClick={onSubmit}
                        loading={loading}
                        disabled={saleInputsInvalidity || contractorsValues.length < 0}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default ContractorsPopup;
