import {ACTION_CODE} from './_actionCode';
import {ContractorInvoicesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_CONTRACTOR_INVOICE';

export async function updateContractorInvoice(
    userData: Record<string, string>,
    updatedContractorInvoice: Record<string, any>,
    id: string
) {
    try {

        LoggerService.logInfo('Action - updateContractorInvoice - Start');

        const updateContractorInvoiceResult = await ContractorInvoicesService.updateContractorInvoice(userData, updatedContractorInvoice, id);

        LoggerService.logInfo('Action - updateContractorInvoice - End - Success');

        return ResultModel.newSuccessResult(updateContractorInvoiceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateContractorInvoice - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
