import {useState, useEffect} from "react";

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Datepicker from "../../../../components/ui/Datepicker";
import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import TabNavigator from "../../../../components/ui/navigation/TabNavigator";
import ProgressComponent from "../../../../components/ui/ProgressComponent/ProgressComponent";
import TabCard from "../../../../components/ui/TabCard/TabCard";
import InvoiceCards from "./InvoiceCards";
import {dasTabConfig} from "../../configs/productionTab/dasTabConfig";
import {dasTeamTabConfig} from "../../configs/productionTab/dasTeamTabConfig";
import {tjmTabConfig} from "../../configs/productionTab/tjmTabConfig";
import {aggregateTotalLoad} from "../../functions/aggregateLoad";
import {calculateTotalLoadPercentage} from "../../functions/aggregateDasTeam";

const ChargeIndicator = ({
    users,
    productionLoad,
    datePickerDate,
    handleDatePickerChange
}) => {

    const [displayedSection, setDisplayedSection] = useState('Production DAS');
    const [selectedLoad, setSelectedLoad] = useState({
        selected: 'Cabinet',
        load: aggregateTotalLoad(productionLoad)
        
    });

    useEffect(() => {
        if (displayedSection === 'Production DAS') {
            setSelectedLoad({
                selected: "Cabinet",
                load: Math.round(aggregateTotalLoad(productionLoad))
            })
        }

        if (displayedSection === 'Production équipe DAS') {
            setSelectedLoad({
                selected: "Cabinet",
                load: Math.round(calculateTotalLoadPercentage(productionLoad.ressources))
            })
        }
    }, [displayedSection])

    return (
        <div className='mt-14'>
            <LineTitle title="Indicateurs de charges" textSize='text-4xl'/>
            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>
            <div className="mt-6">
                <ContainerCard showHeader={false}>
                    <div className="mt-2">
                        <TabNavigator
                            setDisplayedSection={setDisplayedSection}
                            displayedSection={displayedSection}
                            sections={[
                                {name: "Production DAS"},
                                {name: "Production équipe DAS"}
                            ]}
                        />
                    </div>
                    {displayedSection === 'Production DAS' && (
                        <>
                            <div className="my-7">
                                <ProgressComponent tooltipValue={`Charge du ${selectedLoad.selected}`} percent={selectedLoad.load}/>
                            </div>

                            <TabCard config={dasTabConfig(productionLoad, selectedLoad, setSelectedLoad, users)}/>
                        </>
                    )}
                    {displayedSection === 'Production équipe DAS' && (
                        <>
                            <div className="my-7">
                                <ProgressComponent
                                    tooltipValue={`Charge du ${selectedLoad.selected}`} percent={selectedLoad.load}/>
                            </div>

                            <TabCard config={dasTeamTabConfig(productionLoad, selectedLoad, setSelectedLoad, users)}/>
                        </>
                    )}
                </ContainerCard>
            </div>
            <InvoiceCards
                productionLoad={productionLoad}
            />
            <div className="mt-6">
                <TabCard config={tjmTabConfig(productionLoad)}/>
            </div>
        </div>
    )
}

export default ChargeIndicator;
