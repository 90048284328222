import {DealsDao} from '../../dao';
import {ErrorModel, DealModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_DEALS';

export async function getAllDeals(
    userData: Record<string, string>,
    params: string,
    page: number,
) {
    try {

        const result = await DealsDao.getAllDeals(userData, params, page);

        const resultParsed = result.results.map((deal: any) => DealModel.parseDeal(deal));

        const metadata = {
            count: result.count,
            next: result.next,
            previous: result.previous
        };

        return {
            metadata,
            data: resultParsed
        };
     
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des affaires',
            error: error
        });
    }
}
