import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {DashboardActions} from '../../../actions';
import {firstDayYear, lastDayYear} from '../../../utils';
import SimpleTab from '../../../components/ui/SimpleTab';
import Datepicker from '../../../components/ui/Datepicker';
import LineTitle from '../../../components/ui/LineTitle/LineTitle';
import Button from '../../../components/ui/Button/Button';
import {notify} from '../../../components/ui/Toast/Toast';
import {standardFormat} from '../../../utils';

const StateTab = ({subsidiaries}) => {
    const [displayedSection, setDisplayedSection] = useState(subsidiaries[0].name);
    const [currentSubsidiary, setCurrentSubsidiary] = useState(subsidiaries[0]);
    const [tabSelectedColor, setTabSelectedColor] = useState(subsidiaries[0]?.is_training ? 'background-gradient-blue' : 'background-gradient');
    const [invoicingLoading, setInvoicingLoading] = useState(false);
    const [progressLoading, setProgressLoading] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(false);
    const [datePickerDate, setDatePickerDate] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDatePickerChange = (e) => {
        setDatePickerDate({
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        });
    };

    useEffect(() => {
        const subsidiary = subsidiaries.filter((el) => el.name === displayedSection)[0];

        setCurrentSubsidiary(subsidiary);

        setTabSelectedColor(subsidiary?.is_training ? 'background-gradient-blue' : 'background-gradient');
    }, [displayedSection])

    const handleClickInvoicingButton = async () => {
        setInvoicingLoading(true);
        
        const payload = {
            start_date: standardFormat(datePickerDate.startDate),
            end_date: standardFormat(datePickerDate.endDate),
            subsidiary_id: currentSubsidiary.id
        }
        
        const result = await DashboardActions.AdminActions.exportInvoices(userData, payload);

        if (result.success) {
            notify('Facturier envoyé');
        }
        else {
            notify('Une erreur est survenue');
        }

        setInvoicingLoading(false);
    };

    const handleClickInProgressButton = async () => {
        setProgressLoading(true);

        const payload = {
            start_date: standardFormat(datePickerDate.startDate),
            end_date: standardFormat(datePickerDate.endDate),
            subsidiary_id: currentSubsidiary.id
        }
        
        const result = await DashboardActions.AdminActions.exportOngoing(userData, payload);

        if (result.success) {
            notify('En-cours et constatés d’avance envoyés');
        }
        else {
            notify('Une erreur est survenue');
        }

        setProgressLoading(false);
    };

    const handleClickBalanceButton = async () => {
        setBalanceLoading(true);

        const payload = {
            start_date: standardFormat(datePickerDate.startDate),
            end_date: standardFormat(datePickerDate.endDate),
            subsidiary_id: currentSubsidiary.id
        }
        
        const result = await DashboardActions.AdminActions.exportTraining(userData, payload);

        if (result.success) {
            notify('Bilan pédagogique et financier envoyé');
        }
        else {
            notify('Une erreur est survenue');
        }

        setBalanceLoading(false);
    };

    return (
        <div>
            <div className='my-10'>
                <SimpleTab
                    displayedSection={displayedSection}
                    setDisplayedSection={setDisplayedSection}
                    backgroundColor={tabSelectedColor}
                    sections={subsidiaries}
                />
            </div>

            <div className='mt-12'>
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>

            <div className='mb-20 mt-7'>
                <LineTitle textSize='text-4xl' title='Facturier'/>
                <div className='mt-8 -ml-2'>
                    <Button
                        content="Envoyer le facturier"
                        type={currentSubsidiary.is_training ? 'blue' : 'primary'}
                        onClick={handleClickInvoicingButton}
                        loading={invoicingLoading}
                    />
                </div>
            </div>

            <div className='mb-20 mt-7'>
                <LineTitle textSize='text-4xl' title='En-cours et constatés d’avance'/>
                <div className='mt-8 -ml-2'>
                    <Button
                        content="Envoyer les en-cours et constatés d’avance"
                        type={currentSubsidiary.is_training ? 'blue' : 'primary'}
                        onClick={handleClickInProgressButton}
                        loading={progressLoading}
                    />
                </div>
            </div>

            {
                currentSubsidiary.is_training ?
                    <div className='mb-20 mt-7'>
                        <LineTitle textSize='text-4xl' title='Bilan pédagogique et financier'/>
                        <div className='mt-8 -ml-2'>
                            <Button
                                content="Envoyer le bilan pédagogique et financier"
                                type={displayedSection === 'Terre d’avance' ? 'primary' : 'blue'}
                                onClick={handleClickBalanceButton}
                                loading={balanceLoading}
                            />
                        </div>
                    </div> :
                    null
            }
        </div>
    )
}

export default StateTab;
