import {ACTION_CODE} from './_actionCode';
// import {MissionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService, MissionService} from '../../services';
const FUNCTION_CODE = 'GET_MISSION_BY_ID';

export async function updateMissionById(userData: Record<string, any>, payload:Record<string, any> ) {
    try {
        LoggerService.logInfo('Action - updateMissionById - Start');

        const updateMissionByIdResult = await MissionService.updateMissionById(userData.userToken, payload);

        LoggerService.logInfo('Action - updateMissionById - End - Success');

        return ResultModel.newSuccessResult(updateMissionByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - updateMissionById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
