import {ContractorDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_CONTRACTORS';

export async function getAllContractorInvoices(
    userData: Record<string, string>,
    query: string
) {
    try {
        const contractors = await ContractorDao.getAllContractorInvoices(userData.userToken, query);

        const metadata = {
            count: contractors.count,
            next: contractors.next,
            previous: contractors.previous
        };

        return {
            metadata,
            data: contractors
        };

        return contractors;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
