import DonutsChart from "../../../../../components/ui/DonutsChart/DonutsChart";
import {consultantChartConfig} from "./consultantChartConfig";
import {consultantsTotalPerStatus, aggregateDasData} from "../../../functions/agggregateRessources";

export const consultantTabConfig = (ressources) => {
    const dataCabinet = [
        {
            type: 'Junior:',
            value: consultantsTotalPerStatus(ressources, "junior")
        },
        {
            type: 'Confirmé:',
            value: consultantsTotalPerStatus(ressources, "confirmed")
        },
        {
            type: 'Sénior:',
            value: consultantsTotalPerStatus(ressources, "senior")
        }
    ];

    const tabs = [
        {
            id: 1,
            label: 'Cabinet',
            component: <div className="h-[360px]">
                <DonutsChart data={dataCabinet} config={consultantChartConfig} label=""/>
            </div>

        }]

    for (const key in ressources) {
        const item = {
            id:key,
            label: key,
            component: <div className="h-[360px]">
                <DonutsChart data={aggregateDasData(ressources, key)} config={consultantChartConfig} label=""/>
            </div>

        }
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            showContainerCard: false,
            tabs: tabs

        }
    );
};
