import {ACTION_CODE} from './_actionCode';
import {ContractorCategoryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'CREATE_CONTRACTOR_CATEGORY';

export async function createContractorCategory(userData = {}, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - createContractorCategory - Start');

        const result = await ContractorCategoryService.createContractorCategory(userData, payload);

        LoggerService.logInfo('Action - createContractorCategory - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - createContractorCategory - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création de la catégorie sous/co-traitant',
            error: error
        });
        
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
