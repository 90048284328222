import {ACTION_CODE} from './_actionCode';
import {MissionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_MISSION_BY_ID';

export async function deleteMissionById(userData: Record<string, any>, missionId: number) {
    try {
        LoggerService.logInfo('Action - deleteMissionById - Start');

        const deleteMissionByIdResult = await MissionService.deleteMissionById(userData, missionId);

        LoggerService.logInfo('Action - deleteMissionById - End - Success');

        return ResultModel.newSuccessResult(deleteMissionByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteMissionById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppéssion d\'une mission',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
