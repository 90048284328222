import {ACTION_CODE} from './_actionCode';
import {TrainingStudentTypesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_TRAINING_STUDENTTYPE';

export async function deleteTrainingStudentType(userData: Record<string, string>, id: number) {
    try {
        LoggerService.logInfo('Action - deleteTrainingStudentType - Start');

        const deleteTrainingStudentTypeResult = await TrainingStudentTypesService.deleteTrainingStudentType(userData, id);

        LoggerService.logInfo('Action - deleteTrainingStudentType - End - Success');

        return ResultModel.newSuccessResult(deleteTrainingStudentTypeResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteTrainingStudentType - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
