import SimpleCard from '../../../../../components/ui/SimpleCard/SimpleCard';
import LineTitle from '../../../../../components/ui/LineTitle/LineTitle';
import {ThunderboltFilled} from '@ant-design/icons';
import TopComponent from './TopComponent';
import BottomComponent from './BottomComponent';

const ChargeIndicator = ({ workloads }) => {
    if (Object.keys(workloads).length === 0) {
        return null;
    }

    const thunderboltIcon = <ThunderboltFilled style={{ fontSize: '30px', color: '#e77e3b' }}/>;

    let totalCharges = 0;
    let totalPlannedCharges = 0;
    let totalPotentialCharges = 0;

    const keys = Object.keys(workloads);

    for (let i = 0; i < keys.length; i++) {
        totalCharges += workloads[keys[i]].planned;
        totalCharges += workloads[keys[i]].potential;

        totalPlannedCharges += workloads[keys[i]].planned;
        totalPotentialCharges += workloads[keys[i]].potential;
    }

    return (
        <div>
            <LineTitle title="Indicateurs de charge"/>
            <div className='mt-5'>
                <SimpleCard
                    width='w-full'
                    background='white'
                    font='orange'
                    lineColor='black'
                    iconComponent={thunderboltIcon}
                    title={totalCharges.toFixed(2)}
                    subTitle="Jours"
                    topComponent={<TopComponent totalPlannedCharges={totalPlannedCharges.toFixed(2)}  totalPotentialCharges={totalPotentialCharges.toFixed(2)}/>}
                    bottomComponent={<BottomComponent workloads={workloads}/>}
                />
            </div>
        </div>
    )
}

export default ChargeIndicator