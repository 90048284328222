import {DealsDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_DEAL';

export async function updateDeal(
    userData: Record<string, string>,
    updatedDeal: Record<string, any>,
    dealId: string
) {
    try {
        const result = await DealsDao.updateDeal(userData.userToken, updatedDeal, dealId);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mis à jour de l\'affaire',
            error: error
        });
    }
}
