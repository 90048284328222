import {Pie} from '@ant-design/plots';

const DonutsChart = ({
    data,
    config,
    label,
    count,
    value
}) => {
    const colors = [
        '#2391FF',
        '#FFC328',
        '#A0DC2C',
        '#946DFF',
        '#626681',
        '#EB4185',
        '#36BCCB'
    ];

    const formatLegendLabel = (name, value, index) => ({
        id: `legend-${index}`,
        name: `${name}    ${value} ${label}`,
        marker: {
            symbol: 'circle',
            style: {
                fill: colors[index],
                r: 5
            }
        }
    });

    const legendLabels = data.map(({ type, value }, index) =>
        formatLegendLabel(type, value, index)
    );

    return (
        <Pie {...config(data, colors, legendLabels, count, value)} pieStyle={{
            stroke: '#fff',
            lineWidth: 8
        }}/>
    )
}

export default DonutsChart;