import {ACTION_CODE} from './_actionCode';
import {ContractorCategoryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_CONTRACTORS';

export async function getAllContractorCategories(
    userData: Record<string, string>,
    params = "",
    page = 1,
) {
    try {
        LoggerService.logInfo('Action - getAllContractorCategories - Start');

        const getAllContractorCategoriesResult = await ContractorCategoryService.getAllContractorCategories(userData, params, page);

        LoggerService.logInfo('Action - getAllContractorCategories - End - Success');

        return ResultModel.newSuccessResult(getAllContractorCategoriesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllContractorCategories - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des fournisseurs.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
