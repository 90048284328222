import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BankActions, InvoiceActions } from '../../../../actions';
import Button from '../../../../components/ui/Button/Button';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';
import { frenchFormat, standardFormat } from '../../../../utils';
import { getTypeId } from '../../../invoicesPage/functions';
import { fetchSelectData, generateRandomNumber, getErrors } from './../../../../utils';

const InvoicePopup = ({
    dealData,
    setView,
    refreshData,
    blueStyle,
    cancelDisabled
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();
    const [banks, setBanks] = useState(null);

    const vat = useSelector((state) => state.GlobalsReducer.vat);

    // -- react hook form --
    const defaultValues = {
        status: {name: 'Prévisionnelle'},
        billing_date: dayjs(),
        billable: null,
        expenses:null ,
        bank_name:null ,
        type:null ,
        billing_adress:null ,
        due_date: dayjs().add(1, 'month'),
        billable_vat_rate:vat ,
        expenses_vat_rate:vat ,
        notes: '' ,
        customer_id:null
    };
    
    const {handleSubmit, control, formState: {isValid}, setValue} = useForm({defaultValues: defaultValues});
    const navigate = useNavigate();

    const isAppearingInDealDetails = location.pathname.includes(`deals/${dealData.id}`);

    // -- dropdowns values --

    // ↓ status dropdown values
    const statusDropdownValues = [
        {name: 'Prévisionnelle'},
        {name: 'À vérifier'},
        {name: 'À émettre'},
        {name: 'Émise'},
        {name: 'Partiellement recouvrée'},
        {name: 'Recouvrée'},
        {name: 'Annulée'}
    ];
    // ↓ types dropdown values
    const typesDropdownValues = [
        {name: 'Honoraires'},
        {name: 'Mise à disposition de personnel'},
        {name: 'Frais'}
    ];

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // -- fetch data for select input --
    const handleGetBank = async (search, page = 1) => {
        await fetchSelectData({action: BankActions.getAllBanks, search:`&search=${search}`, page: page, setter: setBanks, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ banks dropdown values
        (async () => {
            await handleGetBank('', 1);
        })();
    }, []);

    // -- handler functions --

    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        // ↓ body to send to the post request (see API documentation)
        const invoice = {
            status: 0,
            deal_id: dealData.id,
            billing_date: standardFormat(data.billing_date),
            billable: data.billable,
            expenses: data.expenses,
            bank_name: data.bank.name,
            type: getTypeId(data.type.name),
            billing_address: data.billing_address,
            due_date: standardFormat(data.due_date),
            billable_vat_rate: (parseInt(data.billable_vat_rate) / 100).toString(),
            expenses_vat_rate: (parseInt(data.expenses_vat_rate) / 100).toString(),
            customer_id: dealData.customer.id,
            subsidiary: dealData.subsidiary,
            notes: data.notes,
            official_id: generateRandomNumber()
        }

        // ↓ post - new invoice
        const result = await InvoiceActions.createInvoice(userData, invoice);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            notify('Votre facture à bien été créé');
            setView(false);

            if (isAppearingInDealDetails) {
                navigate(`/deals/${dealData.id}/invoices`);
                
            }
        }

        setLoading(false);
        setDisabled(false);
    };

    return (
        <ContainerCard
            title='Nouvelle Facture'
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-6">
                <div className='flex flex-col'>
                    <div className="mb-2">
                        <Controller
                            name="status"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un statut.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Statut'
                                    placeholder='Statut'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={statusDropdownValues}
                                    onChange={onChange}
                                    loading={!statusDropdownValues}
                                    labelKeys={['name']}
                                    disabled={true}
                                    required={true}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billing_date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date de facturation'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billable"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le montant d\'honoraire.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Honoraires Montant HT [€]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Honoraires Montant HT"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="expenses"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le frais.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Frais Montant HT [€]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Frais Montant HT"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="bank"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une banque.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Compte bancaire'
                                    placeholder='Compte bancaire'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={banks?.options}
                                    hasNextPage={banks?.hasNextPage}
                                    fetchFunction={handleGetBank}
                                    loading={!banks}
                                    isSearchable={true}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className="mb-2">
                        <Controller
                            name="type"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un type de facture.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type'
                                    placeholder='Type'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={typesDropdownValues}
                                    loading={!typesDropdownValues}
                                    disabled={disabled}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billing_address"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir une adresse.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Adresse de facturation'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Adresse"
                                    type="text"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="due_date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={"Date d'écheance"}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billable_vat_rate"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez saisir la tva d\'honoraires'
                                }
                            }}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Honoraires TVA [%]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Honoraires TVA"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="expenses_vat_rate"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir la tva des frais'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Frais TVA [%]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Frais TVA"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="px-5">
                <div className="py-4 border-t border-borderGrey">
                    <Controller
                        name="notes"
                        control={control}
                        render={({
                            field: {onChange, value}
                        }) => (
                            <>
                                <div className="font-normal text-sm text-[#646464] mb-2">Veuillez ajouter une note</div>
                                <TextAreaInput
                                    placeholder={'Tapez votre texte'}
                                    maxLength={100}
                                    onChange={onChange}
                                    value={value}
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={isAppearingInDealDetails && cancelDisabled ? true : disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Ajouter la facture'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    )
}

export default InvoicePopup;
