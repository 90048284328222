
import DonutsChart from "../../../../../components/ui/DonutsChart/DonutsChart";
import {marginContributionChartConfig} from "./marginContributionChartConfig";
import {aggregateContributionToMarginPerStatus, aggregateContributionToMarginPerStatusTotal} from "../../../functions/aggregateContributionToMargin";

export const marginContributionTabConfig = (by_status) => {

    const tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <DonutsChart label={'%'} data={aggregateContributionToMarginPerStatusTotal(by_status)} config={marginContributionChartConfig}/>
    }]

    for (let key in by_status) {
        tabs.push({
            id: key,
            label: key,
            component: <DonutsChart label={'%'} data={aggregateContributionToMarginPerStatus(by_status, key)} config={marginContributionChartConfig}/>
        })
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            childOverflow: 'overflow-none',
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};