import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import {URL} from '../root';
import axios from 'axios';
const FUNCTION_CODE = 'GET_ALL_GLOBALS';

export async function getAllGlobals(userToken: string) {
    try {
        const response = await axios.get(
            `${URL}/conf`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
