import {ACTION_CODE} from './_actionCode';
import {AttachmentService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_ATTACHMENTTYPESs';

export async function getAllAttachmentTypes(
    userData: Record<string, string>
) {
    try {

        LoggerService.logInfo('Action - getAllAttachmentTypes - Start');

        const getAllAttachmentTypesResult = await AttachmentService.getAllAttachmentTypes(userData);

        LoggerService.logInfo('Action - getAllAttachmentTypes - End - Success');

        return ResultModel.newSuccessResult(getAllAttachmentTypesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllAttachmentTypes - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des types des documents.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
