import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_INVOICE_BY_ID';

export async function getInvoiceById(
    userToken: string,
    id:number
) {
    try {
        const response = await axios.get(
            `${URL}/invoices/${id}/`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error: any) {
        const errorMessage = () => {
            if (error.response.data.detail) {
                return error.response.data.detail;
            }
            else {
                return 'Une erreur est survenue lors de la récuperation d\'une facture';
            }
        };
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: errorMessage(),
            error: error
        });
    }
}
