// import { Progress } from 'antd';
import { useSelector } from 'react-redux';
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import Badge from '../../../../components/ui/Badge';
import ProgressComponent from '../../../../components/ui/ProgressComponent/ProgressComponent';

const OfficeLoadTeamTabChildren = ({data}) => {

    const das = useSelector((state) => state.DasReducer.das);

    return (
        <div className='flex justify-start mr-5'>
            {data.map((el, index) => {
                console.log(el);
                let userInfos = das.filter((d) => d.name === el.das);

                return (<SimpleCard
                    customStyle={`mt-7 ${index % 2 === 0 ? 'mr-5' : ''}`}
                    key={index}
                    width="w-[32%]"
                    background="white"
                    font="orange"
                    showLine={false}
                    titleSize="text-2xl"
                    title={el.das}
                    shadow={false}
                    borderSize='border'
                    iconComponent={<Badge content={userInfos.length > 0 ? [userInfos[0].manager] : "No data"} type='rounded'/>}
                    topComponent={<ProgressComponent textGradient={true} percent={Math.round(el.all_das_load / el.total_load * 100)}/>}
                    bottomComponent={<BottomComponent percent={Math.round(el.das_load / el.total_load * 100)} title={el.das}/>}
                />)
            })}
        </div>
    )
}

export default OfficeLoadTeamTabChildren;

const BottomComponent = () => {
    return (
        <div className='px-5 bg-white rounded-b-lg pb-14'>
            <div className='h-[1px] w-full bg-black my-10'/>

            <div className='flex items-center w-10/12'>
                {/* <p style={{fontFamily: 'Din condensed'}} className='w-20 text-2xl'>{title}</p> */}
                {/* <Progress percent={percent} showInfo={true} strokeColor={'#e98b4e'} trailColor={'#F5F5F580'}/> */}
            </div>
        </div>
    )
}