import {TrainingAttendancesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_TRAINING_ATTENDANCES';

export async function getAllTrainingAttendances(userData: Record<string, string>, params: string) {
    try {

        const result = await TrainingAttendancesDao.getAllTrainingAttendances(userData, params);

        const metadata = {
            count: result.count,
            next: result.next,
            previous: result.previous
        };

        return {
            metadata,
            data: result
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des suivies de stagiaires',
            error: error
        });
    }
}
