import StackedGroupedColumnPlotChart from "../../../../../components/ui/GroupedColumnPlotChart/StackedGroupedColumnPlotChart";
import {aggregateLoadProjectionDataPerDas} from "../../../functions/aggregateLoadProjectionData";
import {aggregateLoadProjectionDataCabinet} from "../../../functions/aggregateLoadProjectionData";

export const loadProjectionsTabConfig = (load) => {

    const tabs = [
        {
            id: 1,
            label: 'Cabinet',
            component: <StackedGroupedColumnPlotChart data={aggregateLoadProjectionDataCabinet(load)}/>

        }
    ]

    for (const key in load) {
        tabs.push  ({
            id: key,
            label: key,
            component: <StackedGroupedColumnPlotChart data={aggregateLoadProjectionDataPerDas(load[key])}/>

        })
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            childOverflow: 'overflow-none',
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};