import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Layout from '../../components/layout';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import DealsSection from './components/customerSectionComponents/DealsSection';
import InvoicesSection from './components/customerSectionComponents/InvoicesSection';
import NotesAndDocuments from './components/customerSectionComponents/NotesAndDocuments';
import {DealActions, InvoiceActions, AttachmentActions, CustomerActions} from '../../actions';
import {notify, Toast} from '../../components/ui/Toast/Toast';
import CustomerDetailsBanner from './components/customersDetailComponents/CustomerDetailsBanner';

function CustomerDetailsPage() {
    // -- STATES --
    // ------------
    const [customerData, setCustomerData] = useState({});
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    // deal states
    const [dealData, setDealData] = useState([]);
    const [dealsPaginationMetadata, setDealsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 10
    });
    const [dealsTableLoading, setDealsTableLoading] = useState(false);

    // invoice states
    const [invoiceData, setInvoiceData] = useState(false);
    const [invoicesPaginationMetadata, setInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 10
    });
    const [invoicesTableLoading, setInvoicesTableLoading] = useState(false);

    // attachment states
    const [attachmentsData, setAttachmentsData] = useState([]);
    // const [attachmentLoading, setAttachmentLoading] = useState(true);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const customerId = useParams().id;

    // fetch customer data
    const handleGetCustomerData = async () => {
        const result = await CustomerActions.getCustomerById(userData, customerId);

        if (result.success) {
            setCustomerData(result.data);
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des données client');
        }
    }

    const refreshCustomerData = async () => {
        await handleGetCustomerData();
    }
    // --------------------------------------

    // fetch deals data
    const handleGetAllDeals = async (page, activeLoading = true) => {
        if (activeLoading) {
            setDealsTableLoading(true);
            setDealData([]);
        }
            
        const result = await DealActions.getAllDeals(
            userData,
            `&customer=${`${customerId}`}&page_size=${dealsPaginationMetadata.pageSize}`,
            page
        );

        if (result.success) {
            setDealData(result.data);
            setDealsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (dealsPaginationMetadata.totalItems !== result.metadata.count) {
                setDealsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des affaires');
        }

        setDealsTableLoading(false);
    }
    // -----------------------------

    // fetch invoices data
    const handleGetAllInvoices = async (page, activeLoading = true) => {
        if (activeLoading) {
            setInvoicesTableLoading(true);
            setInvoiceData([]);
        }

        const result = await InvoiceActions.getAllInvoices(
            userData,
            `&customer=${customerId}&page_size=${invoicesPaginationMetadata.pageSize}`,
            page
        );

        if (result.success) {
            setInvoiceData(result.data);
            setInvoicesPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (invoicesPaginationMetadata.totalItems !== result.metadata.count) {
                setInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures');
        }

        setInvoicesTableLoading(false);
    }
    // --------------------------------

    // fetch attachments data
    const handleGetAllAttachments = async () => {
        // En attente du back: il faut passer le cutomer id et non pas le deal id
        const result = await AttachmentActions.getAllAttachments(userData, { deal: customerId });

        if (result.success) {
            setAttachmentsData(result.data.results);
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des pièces jointes');
        }
    }

    const refreshAttachmentsData = async () => {
        await handleGetAllAttachments();
    }
    // ------------------------------------------

    const handleFetchAllData = async () => {
        setLoading(true);

        await handleGetCustomerData();
        await handleGetAllDeals(1, false);
        await handleGetAllInvoices(1, false);
        await handleGetAllAttachments();

        setLoading(false);
    }

    useEffect(() => {
        handleFetchAllData();
    }, []);

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <div className='flex items-center justify-center w-full'/>
                <CustomerDetailsBanner data={customerData} userData={userData}/>
                <div className='px-24 pt-5 pb-32 mt-32'>
                    <div className='flex flex-row items-center justify-center mb-6'>
                        <DealsSection
                            data={dealData}
                            dealsPaginationMetadata={dealsPaginationMetadata}
                            handleGetAllDeals={handleGetAllDeals}
                            dealsTableLoading={dealsTableLoading}
                        />
                    </div>
                    <div className='flex flex-row items-center justify-center mb-6'>
                        <InvoicesSection
                            invoiceData={invoiceData}
                            invoicesTableLoading={invoicesTableLoading}
                            invoicesPaginationMetadata={invoicesPaginationMetadata}
                            handleGetAllInvoices={handleGetAllInvoices}
                        />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <NotesAndDocuments
                            userData={userData}
                            customerData={customerData}
                            attachmentsData={attachmentsData}
                            refreshAttachmentsData = {refreshAttachmentsData}
                            refreshCustomerData={refreshCustomerData}
                            buttonsDisabled={buttonsDisabled}
                            setButtonsDisabled={setButtonsDisabled}
                        />
                    </div>
                </div>
            </PageLoader>
        </Layout>
    );
}

export default CustomerDetailsPage;
