
export const expenseReportRepartitionTableConfig = ({label, value}) => {
    return {
        dropdown: false,
        dropdownLeftPadding: true,
        greyBackground: false,
        subline: false,
        columnConfig: [
            {
                label: label,
                value: value,
                width: 'w-[60%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item[value]}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Montant',
                value: 'amount',
                width: 'w-[20%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+(parseInt(item.amount).toLocaleString())}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'TVA',
                value: 'vat',
                width: 'w-[10%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+(parseInt(item.vat).toLocaleString())}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            }
        ]
    };
};
