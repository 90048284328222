import {ACTION_CODE} from './_actionCode';
import {BankService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_BANK';

export async function updateBankById(
    userData: Record<string, any>,
    bankId: number,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateBankById - Start');

        const updateBankByIdResult =
            await BankService.updateBankById(userData, bankId, payloadUpdate);

        LoggerService.logInfo('Action - updateBankById - End - Success');

        return ResultModel.newSuccessResult(updateBankByIdResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateBankById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
