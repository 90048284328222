import {ContractDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'UPDATE_CONTRACT';

export async function updateContract(
    userData: Record<string, string>,
    updatedContract: Record<string, string>,
    contractId: number
) {
    try {
        return await ContractDao.updateContract(userData.userToken, updatedContract, contractId);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la modification du contrat.',
            error: error
        });
    }
}
