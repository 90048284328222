import DonutsChart from "../../../components/ui/DonutsChart/DonutsChart";
import {expenseAnalysisLeftChartConfig} from "./expenseAnalysisLeftChartConfig";

export const expenseAnalysisLeftChartTabConfigs = (data) => {

    const getPercent = (sum, value) => {
        if (!sum || !value) {
            return 0;
        }
        else {
            return Math.round((value / sum) * 100);
        }
    }

    const getSumByDas = (das) => {
        return(
            data[das]['details']['Voyage et déplacement'] +
            data[das]['details']['Frais de mission'] +
            data[das]['details']['Achats société'] +
            data[das]['details']['Autres']
        );
    }

    const getSumByValue = (value) => {
        let sum = 0;

        for (const key in data) {
            sum += data[key].details[value];
        }

        return sum.toFixed();
    }

    const getTotalSum = () => {
        let sum = 0;

        for(const key in data) {
            sum += data[key]['details']['Voyage et déplacement'];
            sum += data[key]['details']['Frais de mission'];
            sum += data[key]['details']['Achats société'];
            sum += data[key]['details']['Autres'];
        }

        return sum;
    }

    const getSumData = () => {
        return (
            [
                {
                    type: 'Voyages et déplacements',
                    value: getPercent(getTotalSum(), getSumByValue('Voyage et déplacement'))
                },
                {
                    type: 'Frais de mission',
                    value: getPercent(getTotalSum(), getSumByValue('Frais de mission'))
                },
                {
                    type: 'Achats société',
                    value: getPercent(getTotalSum(), getSumByValue('Achats société'))
                },
                {
                    type: 'Autres',
                    value: getPercent(getTotalSum(), getSumByValue('Autres'))
                }
            ]
        )
    }

    const getData = (das) => {
        return (
            [
                {
                    type: 'Voyages et déplacements',
                    value: getPercent(getSumByDas(das), data[das]['details']['Voyage et déplacement'])
                },
                {
                    type: 'Frais de mission',
                    value: getPercent(getSumByDas(das), data[das]['details']['Frais de mission'])
                },
                {
                    type: 'Achats société',
                    value: getPercent(getSumByDas(das), data[das]['details']['Achats société'])
                },
                {
                    type: 'Autres',
                    value: getPercent(getSumByDas(das), data[das]['details']['Autres'])
                }
            ]
        )
    }

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <DonutsChart label={'%'} data={getSumData()} config={expenseAnalysisLeftChartConfig}/>
    }];

    for(const key in data) {
            
        const item = {
            id: key,
            label: key,
            component: <DonutsChart label={'%'} data={getData(key)} config={expenseAnalysisLeftChartConfig}/>
        }

        tabs.push(item);
    }
    
    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showContainerCard: false,
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
