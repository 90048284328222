import {ActivityTypesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_ACTIVITY_TYPES';

export async function deleteActivityTypes(userData: Record<string, string>, activityTypesId: number | string) {
    try {
        const result = await ActivityTypesDao.deleteActivityTypes(userData, activityTypesId);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du type d\'activité',
            error: error
        });
    }
}
