import {CustomersDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_CUSTOMERS';

export async function getAllCustomers(
    userData: Record<string, string>,
    params: string,
    page: number
) {
    try {
        const customer = await CustomersDao.getAllCustomers(userData.userToken, params, page);

        const metadata = {
            count: customer.count,
            next: customer.next,
            previous: customer.previous
        };

        return {
            metadata,
            data: customer
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des factures',
            error: error
        });
    }
}
