import {ProductionDao} from '../../dao';
import {
    ErrorModel
} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'DELETE_PRODUCTION';

export async function deleteProduction(
    userData: Record<string, string>,
    productionId: string
) {
    try {
        const result = await ProductionDao.deleteProduction(userData.userToken, productionId);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
