import {ExpenseAttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_EXPENSE_ATTACHMENT';

export async function createExpenseAttachment(
    userData: Record<string, string>,
    filePath: string,
    reportId:string
) {

    try {
        const result = await ExpenseAttachmentDao.createExpenseAttachment(userData.userToken, filePath, reportId);

        return result;
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
