import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import { ReferenceActions, FileActions, ContractorActions, DealTagsActions } from './../../../actions'
import { standardFormat, frenchFormat, getErrors } from '../../../utils';
import FileDragNDropInput from './../../../components/ui/Inputs/FileDragNDropInput';
import Popup from '../../../components/ui/Popup';
import ContainerCard from '../../../components/ui/ContainerCard';
import Button from '../../../components/ui/Button/Button';
import DateInput from '../../../components/ui/Inputs/DateInput/DateInput';
import TextInput from '../../../components/ui/Inputs/TextInput';
import CheckboxInput from '../../../components/ui/Inputs/CheckboxInput/CheckboxInput';
import TagInput from '../../../components/ui/Inputs/TagInput';
import {notify} from '../../../components/ui/Toast/Toast';
import Error from '../../../components/ui/Error/Error';
import RichText from "../../../components/ui/Inputs/RichText";

const ReferencePopup = ({view, setView, itemToEdit, userData, refreshData}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [tags, setTags] = useState([]);
    const [tagsError, setTagsError] = useState(false);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [cocontractors, setCocontractors] = useState([]);
    const [cocontractorsError, setCocontractorsError] = useState(false);
    const [cocontractorsSuggestions, setCocontractorsSuggestions] = useState([]);
    const [isCocontractorsAvailable, setIsCocontractorsAvailable] = useState(false);

    const defaultValues = {
        actions: null,
        budget: null,
        contact: null,
        contact_address: null,
        context: null,
        name: null,
        project_management: null,
        realisation_date: null,
        status: 'draft',
        tags: tags ? tags : [],
        title: null,
        file: null
    }

    const {handleSubmit, control, reset, getValues, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (!view) {
            reset(defaultValues);
            setTagsError(false);
            setCocontractorsError(false);
            setIsCocontractorsAvailable(false);
            setErrors(null);
        }
    }, [view])

    useEffect(() => {
        if (itemToEdit) {
            reset({
                actions: itemToEdit.actions,
                budget: itemToEdit.budget,
                contact: itemToEdit.contact,
                contact_address: itemToEdit.contact_address,
                context: itemToEdit.context,
                name: itemToEdit.name,
                project_management: itemToEdit.project_management,
                realisation_date: itemToEdit.realisation_date ? dayjs(itemToEdit.realisation_date) : null,
                status: itemToEdit.status,
                title: itemToEdit.title,
                file: itemToEdit.customer_logo
            });

            setCocontractors(itemToEdit.cocontractors.map((cocontractor) => ({...cocontractor, value: cocontractor.name, label: cocontractor.name})));
            setIsCocontractorsAvailable(itemToEdit.cocontractors.length);

            setTags(itemToEdit.tags.map((tag) => ({value: tag, label: tag})));
        }
    }, [view]);

    const handleSetTagsSuggestions = async () => {
        const allTagsResult = await DealTagsActions.getAllDealTags(userData, '');

        if (allTagsResult.success) {
            setTagSuggestions(allTagsResult.data.results.map((tag) => ({value: tag.name, label: tag.name})));
        }
    }

    useEffect(() => {
        (async () => {
            const allContractorsResult = await ContractorActions.getAllContractors(userData);

            if (allContractorsResult.success) {
                setCocontractorsSuggestions(allContractorsResult.data.map((contractor) => ({...contractor, value: contractor.name, label: contractor.name})));
            }

            await handleSetTagsSuggestions();
        })();
    }, []);

    const onSubmitWithValidation = () => {
        handleSubmit((data) => onSubmit(data, true))();
        setTagsError(tags.length === 0);

        if (isCocontractorsAvailable) {
            setCocontractorsError(cocontractors.length === 0);
        }
    };
    const onSubmitWithoutValidation = () => {
        if (isCocontractorsAvailable && cocontractors.length === 0) {
            setCocontractorsError(true);
        }
        else {
            const data = getValues();
            onSubmit(data, false);
        }
    };

    const onSubmit = async (data, validation) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        if(isValid || !validation) {
            let file = null;

            if (data.file && data.file.uid && data.file.type) {
                const newFile = {
                    "type": data.file.type,
                    "name": data.file.name,
                    "file": data.file
                }

                const result = await FileActions.createFile(userData, newFile);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    file = result.data;
                }
            }

            const payload = {
                actions: data.actions ? data.actions : null,
                budget: data.budget ? data.budget : null,
                contact: data.contact ? data.contact : null,
                contact_address: data.contact_address ? data.contact_address : null,
                context: data.context ? data.context : null,
                name: data.name ? data.name : null,
                project_management: data.project_management ? data.project_management : null,
                title: data.title ? data.title : null,
                status: validation ? 'published' : 'draft',
                realisation_date: data.realisation_date ? standardFormat(data.realisation_date) : null,
                tags: tags.map((tag) => tag.value),
                customer_logo: file ? file.id : itemToEdit.customer_logo,
                customer_logo_id: file ? file.id : itemToEdit.customer_logo_id,
                cocontractors_ids: cocontractors.map(cocontractor => cocontractor.id)
            }

            const result = await ReferenceActions.updateReference(userData ,payload, itemToEdit.id);

            if (!result.success) {
                reset(data);
                setErrors(getErrors(result));
            }
            else {
                await handleSetTagsSuggestions();
                await refreshData();
                notify(`La réference a été ${validation ? 'publier' : 'modifier'} avec succès.`);
                setView(false);
            }

            setDisabled(false);
            setLoading(false);
        }
    }

    const handleUpdateTags = (newValue) => {
        setTags(newValue);
    }

    const handleUpdateCocontractors = (newValue) => {
        setCocontractors(newValue);
    }

    return (
        <Popup
            view={view}
            setView={setView}
            width={'1098px'}
        >
            <ContainerCard
                showHeader={true}
                coloredButtonDisplayed={false}
                title="Modifier la référence"
            >
                <div className='pb-6 mb-6 border-b border-gray-200'>
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom de la référence'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Nom'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nom de la référence"
                                type="text"
                                width='w-full'
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col h-auto">
                    <div className='mb-3'>
                        <Controller
                            name="title"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le titre de la référence'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Titre'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Ajouter un titre"
                                    type="text"
                                    width='w-full'
                                />
                            )}
                        />
                    </div>
                    <div className='flex justify-between mb-3'>
                        <Controller
                            name="project_management"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le maitrise d’ouvrage'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Maitrise d’ouvrage'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Maitrise d’ouvrage"
                                    type="text"
                                    width='w-72'
                                />
                            )}
                        />
                        <Controller
                            name="contact"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom du contact de la référence'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Contact'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Contact"
                                    type="text"
                                    width='w-72'
                                />
                            )}
                        />
                    </div>
                    <div className='flex justify-between mb-3'>
                        <Controller
                            name="realisation_date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date de réalisation.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    width='w-72'
                                />
                            )}
                        />
                        <Controller
                            name="contact_address"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le contact (mail, téléphone ect...)'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Moyen de contact'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="mail, téléphone ect..."
                                    type="text"
                                    width='w-72'
                                />
                            )}
                        />
                    </div>
                    <div className='flex justify-between mb-3'>
                        <div className="flex flex-row items-start justify-between mb-2">
                            <div className='w-36 mt-[9px]'>
                                <CheckboxInput
                                    label='Cotraitants'
                                    required={false}
                                    onChange={() => setIsCocontractorsAvailable(!isCocontractorsAvailable)}
                                    value={isCocontractorsAvailable}
                                />
                            </div>
                            <TagInput
                                onChange={handleUpdateCocontractors}
                                data={cocontractors}
                                suggestions={cocontractorsSuggestions}
                                error={cocontractorsError}
                                label=''
                                required={false}
                                labelStyle='text-sm text-[#646464] mr-0'
                                placeholder='Ajouter un cotraitant'
                                disabled={!isCocontractorsAvailable}
                                errorMessage='Veuillez sélectionner un cotraitant'
                                indicatorMessage={
                                    isCocontractorsAvailable ?
                                        'Sélectionnez une option ou saisissez un nouveau<br/>cotraitant et appuyez sur entrer' : ''
                                }
                            />
                        </div>
                        <Controller
                            name="budget"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le montant du budget'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Budget'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Budget"
                                    type="number"
                                    width='w-72'
                                />
                            )}
                        />
                    </div>
                    <div className='flex justify-between mb-3'>
                        <TagInput
                            onChange={handleUpdateTags}
                            data={tags}
                            suggestions={tagSuggestions}
                            error={tagsError}
                            required={true}
                            indicatorMessage='Sélectionnez une option ou saisissez un nouveau<br/>tag et appuyez sur entrer'
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center mb-5">
                    <Controller
                        name="file"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez charger un logo'
                        }}}
                        render={({
                            field: {onChange}
                        }) => (
                            <FileDragNDropInput
                                onChange={onChange}
                                uploadText='Glissez déposez ou séllectionnez des fichiers “logos des clients”'
                                uploadHint="Ajoutez ici des documents (PDF, JPEG, PDF) lié à la référence"
                                maxCount={1}
                            />
                        )}
                    />
                </div>
                <div>
                    <p className='mb-1 text-sm'>Contexte & Objectifs <span className='text-red-500'>*</span></p>
                    <Controller
                        name="context"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le contexte & objectifs'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <RichText
                                onChange={onChange}
                                value={value}
                                error={error}
                                disabled={disabled}
                                placeholder="Contexte & Objectifs"
                                height='h-96'
                            />
                        )}
                    />
                </div>
                <div className='mt-4'>
                    <p className='mb-1 text-sm'>Actions & Méthodologie <span className='text-red-500'>*</span></p>
                    <Controller
                        name="actions"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir l\'actions & méthodologie'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <RichText
                                onChange={onChange}
                                value={value}
                                error={error}
                                disabled={disabled}
                                placeholder="Actions & Méthodologie"
                                height='h-96'
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col justify-center pb-2 mt-6">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => {
                                reset();
                                setView(false);
                            }}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            onClick={onSubmitWithoutValidation}
                            type={'secondary'}
                            content={'Enregistrer'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content={`Publier la référence`}
                            onClick={onSubmitWithValidation}
                            loading={loading}
                            disabled={!isValid || tags.length === 0 || (isCocontractorsAvailable && cocontractors.length === 0)}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
        </Popup>
    )
}

export default ReferencePopup;
