import {InvoiceDao} from '../../dao';
import {InvoiceModel} from '../../models';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_INVOICE_BY_ID';

export async function updateInvoiceById(
    userData: Record<string, string>,
    invoiceId: number,
    update: Record<string, any>
) {
    try {
        const result = await InvoiceDao.updateInvoiceById(userData.userToken, invoiceId, update);

        const resultParsed = InvoiceModel.parseInvoice(result);

        return resultParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
