import {ACTION_CODE} from './_actionCode';
import {ExpenseService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_AGREGATED_CALENDAR_EXPENSES_BY_DAY';

export async function getAggregatedCalendarExpensesByDay(
    userData: Record<string, string>,
    before: string,
    after: string,
    query = ''
) {
    try {
        LoggerService.logInfo('Action - getAggregatedCalendarExpensesByDay - Start');

        const getAggregatedCalendarExpensesByDayResult = await ExpenseService.getAggregatedCalendarExpensesByDay(userData, before, after, query);

        LoggerService.logInfo('Action - getAggregatedCalendarExpensesByDay - End - Success');

        return ResultModel.newSuccessResult(getAggregatedCalendarExpensesByDayResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAggregatedCalendarExpensesByDay - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des frais.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
