import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

import arrowDownMenu from './../../../assets/icons/navbar/arrow-down.png';
import navBarLogo from './../../../assets/logos/logo.png';
import DashboardsDropdown from './DashboardsDropdown';
import NotificationIcon from './NotificationIcon';
import User from './User';
import { Toast } from '../../ui/Toast/Toast';

const Navbar = () => {
    const userProfileData = useSelector((state) => state.UserReducer.userProfileData);

    let navElements = userProfileData.role === "CO" ?
        [
            {title: 'Affaires', to: 'deals'},
            {title: 'Temps', to: 'productions'},
            {title: 'Frais', to: 'expenses'},
            {title: 'Références', to: 'references'}
        ] :
        [
            {title: 'Affaires', to: 'deals'},
            {title: 'Facturation', to: 'invoices'},
            {title: 'Temps', to: 'productions'},
            {title: 'Frais', to: 'expenses'},
            {title: 'Références', to: 'references'}
        ]

    const societyDropElements = userProfileData.role === "PA" ?
        [
            {title: 'Staffing', to: 'staffing'},
            {title: 'Sous/Co-traitants', to: 'my-organization/contractors'},
            {title: 'Clients', to: 'my-organization/customers'},
            {title: 'Paramètres', to: 'my-organization/parameters'}
        ] :
        [
            {title: 'Sous/Co-traitants', to: 'my-organization/contractors'},
            {title: 'Clients', to: 'my-organization/customers'},
            {title: 'Paramètres', to: 'my-organization/parameters'}
        ]
    ;

    return (
        <>
            <Toast/>
            <nav
                className="fixed z-[51] flex items-center justify-between max-w-[1600px] w-full h-20 p-2 px-10 bg-white"
                style={{boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)'}}
            >
                <Link to="/" className="h-5/6">
                    <img src={navBarLogo} alt="Brand logo" className="h-full"/>
                </Link>
                <ul className="flex items-center justify-between w-[70%]">
                    <DashboardsDropdown role={userProfileData.role}/>
                    {
                        navElements.map((item, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={`/${item.to}`} className={(link) => {
                                        return (
                                            `${link.isActive ? 'text-gradient' : 'text-[#212121] hover:underline hover:underline-offset-4'}`
                                        );
                                    }}>
                                        {item.title}
                                    </NavLink>
                                </li>
                            );
                        })
                    }
                    {userProfileData.role !== "CO" && <ul className="relative cursor-pointer group">
                        <p className="flex flex-row items-center text-[#212121] grou">
                        Ma société
                            <img className="w-3 ml-2" src={arrowDownMenu} alt=""/>
                        </p>
                        <div className={'absolute w-44 top-6 -right-9 bg-white rounded-md shadow-lg'}>
                            <div className="hidden py-1 hover:block group-hover:block">
                                {
                                    societyDropElements.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <NavLink to={`/${item.to}`} className={(link) => {
                                                    return (
                                                        `block py-2 pl-12 pr-2 text-sm
                                                    ${link.isActive ?
                                                            'bg-[#f9e2d7] border-r-2 text-[#E36D38] border-[#E36D38]' :
                                                            'text-[#212121] hover:bg-[#f9e2d7] hover:border-r-2 hover:text-[#E36D38] hover:border-[#E36D38]'
                                                        }`
                                                    );
                                                }}>
                                                    {item.title}
                                                </NavLink>
                                            </li>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </ul>}
                </ul>
                <div className="flex flex-row items-center gap-x-7">
                    <NotificationIcon userData={userProfileData}/>
                    <User
                        userProfileData={userProfileData}
                    />
                </div>
            </nav>
        </>
    );
};

export default Navbar;
