import React from 'react';

const DateSelector = ({defaultValue, setDataState, dataArray, name}) => {
    return (
        <select
            className="border outline-none bg-white border-[#D9D9D9] text-[#262626] text-sm px-2 py-1 rounded-[2px]"
            defaultValue={defaultValue}
            name={name}
            onChange={(e) => setDataState(parseInt(e.target.value))}
        >
            {dataArray.map((item, index) => (
                <option
                    key={item}
                    value={name === 'year' ? item : index}
                >
                    {item}
                </option>
            ))}
        </select>
    );
};
export default DateSelector;
