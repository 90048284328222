import React, {useState} from 'react';
import {Tooltip} from 'antd';

import editIcon from '../../../assets/icons/actionsbutton/edit.png';
import deleteIcon from '../../../assets/icons/actionsbutton/delete.png';
import sendIcon from '../../../assets/icons/actionsbutton/send.svg';
import downloadIcon from '../../../assets/icons/containerCard/download.png';
import downloadIconBlue from '../../../assets/icons/containerCard/download-blue.png';
import Loader from '../../loaders/Loader';
import goIcon from '../../../assets/icons/actionsbutton/go.png';
import pdfIcon from '../../../assets/icons/actionsbutton/pdf.png';
import pdfIconBlue from '../../../assets/icons/actionsbutton/pdf-blue.png';
import payIcon from '../../../assets/icons/actionsbutton/pay.png';
import goIconBlue from '../../../assets/icons/actionsbutton/goBlue.png';
import deleteIconBlue from '../../../assets/icons/blueStyle/delete-blue.png';
import editIconBlue from '../../../assets/icons/blueStyle/edit-blue.png';
import ConfirmationPopup from '../ConfirmationPopup';
import seeMore from '../../../assets/icons/actionsbutton/seeMore.svg';
import contact from '../../../assets/icons/actionsbutton/contact.svg';

const ActionsButton = ({
    type,
    onClick = null,
    isLoading = false,
    disabled = false,
    blueStyle = false,
    confirmationOnDelete = true,
    deleteConfirmationQuestion = '',
    confirmationOnPay = true,
    payConfirmationQuestion = '',
    customTooltip = null,
    minWidth = null,
    style = "flex items-center justify-center p-1 bg-transparent"
}) => {
    const [confirmationQuestion, setConfirmationQuestion] = useState('');
    const [confirmationPopupView, setConfirmationPopupView] = useState(false);

    const handldeClick = () => {
        if (onClick && type === 'delete' && confirmationOnDelete) {
            setConfirmationQuestion(deleteConfirmationQuestion);
            setConfirmationPopupView(true);
        }
        else if (onClick && (type === 'pay' || type === 'send') && confirmationOnPay) {
            setConfirmationQuestion(payConfirmationQuestion);
            setConfirmationPopupView(true);
        }
        else if (onClick) {
            onClick();
        }
        else {
            return null;
        }
    };

    const checkType = (currentType) => {
        switch (currentType) {
            case 'edit':
                return blueStyle ? editIconBlue : editIcon;
            case 'delete':
                return blueStyle ? deleteIconBlue : deleteIcon;
            case 'download':
                return blueStyle ? downloadIconBlue : downloadIcon;
            case 'send' :
                return sendIcon;
            case 'details' :
                return blueStyle ? goIconBlue : goIcon;
            case 'overview' :
                return blueStyle ? pdfIconBlue : pdfIcon;
            case 'seeMore'  :
                return seeMore;
            case 'pay' :
                return payIcon;
            case 'partialPay' :
                return payIcon;
            case 'contacts' :
                return contact
            default:
                return null;
        }
    };
    const tooltip = (currentType) => {
        switch (currentType) {
            case 'edit':
                return "Modifier";
            case 'delete':
                return "Supprimer";
            case 'download':
                return "Télécharger";
            case 'send' :
                return "Émettre";
            case 'details' :
                return "Voir détails";
            case 'seeMore' :
                return "Voir plus";
            case 'overview' :
                return "Aperçu";
            case 'pay' :
                return "Payer";
            case 'contacts' :
                return "Voir le carnet des contacts";
            default:
                return null;
        }
    };

    return (
        <>
            <button
                id='skip'
                onClick={handldeClick}
                disabled={disabled}
                className={`${style} ${minWidth && minWidth}`}>
                {
                    isLoading ?
                        <Loader color={blueStyle ? '#01abab' : '#E36D38'} size={15}/> :
                        <Tooltip title={customTooltip ? customTooltip : tooltip(type)}>
                            <div id='skip'>
                                <img id='skip' src={checkType(type)} alt="logo" className={type === "details" ? "w-6" : "w-[18px]"}/>
                            </div>
                        </Tooltip>
                }
            </button>

            <ConfirmationPopup
                view={confirmationPopupView}
                setView={setConfirmationPopupView}
                title={confirmationQuestion}
                onConfirm={onClick}
                blueStyle={blueStyle}
            />
        </>
    );
};

export default ActionsButton;