import {ACTION_CODE} from './_actionCode';
import {InvoiceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_INVOICE_INVOICE_CONTRACTOR';

export async function deleteInvoiceContractor(
    userData: Record<string, any>,
    invoiceData: Record<string, any>,
    contractorId: number,
) {
    try {
        LoggerService.logInfo('Action - updateInvoiceContractor - Start');

        // find and remove the contractor in the invoice
        const updateArray = invoiceData.contractors.map((contractor: any) => {
            if (contractor.id === contractorId) {
                return
            }

            return {
                "id": contractor.id,
                "contractor_id": contractor.contractor.id,
                "amount": contractor.amount,
                "notes": contractor.notes
            };
        })

        const newPayload = {contractors: updateArray.filter(Boolean)}
        
        const updateInvoiceContractorResult = await InvoiceService.updateInvoiceById(userData, invoiceData.id, newPayload);

        LoggerService.logInfo('Action - updateInvoiceContractor - End - Success');

        return ResultModel.newSuccessResult(updateInvoiceContractorResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateInvoiceContractor - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
