import {HourglassFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import TabCard from '../../../../components/ui/TabCard/TabCard';
import {paymentTimesAnalysisChartTabConfig} from '../../configs/paymentTimesAnalysisChartTabConfig';

const PaymentTermsAnalysis = ({
    data,
    datePickerValue,
    onDatePickerChange
}) => {
    const HourglassIcon = <HourglassFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getValueSum = () => {
        let sum = 0;

        for (const key in data) {
            sum += data[key].value;
        }

        return sum.toFixed();
    }

    return (
        <div className='my-10'>
            <LineTitle title='Analyse des délais de paiement'/>
            <div className='mt-10 mb-6'>
                <Datepicker
                    type='range'
                    defaultValue={datePickerValue}
                    onChange={onDatePickerChange}
                />
            </div>
            <SimpleCard
                width='w-1/2'
                iconComponent={HourglassIcon}
                title={`${getValueSum()} jours`}
                subTitle="Délai moyen d’encaissement des factures"
                background='orange'
                bottomComponent={<BottomComponent data={data}/>}
            />
            <div className='mt-6'>
                <TabCard config={paymentTimesAnalysisChartTabConfig(data)}/>
            </div>
        </div>
    )
}

export default PaymentTermsAnalysis;

const BottomComponent = ({data}) => {
    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            value: data[key].value
        });
    }

    return (
        <div className='flex flex-row items-center rounded-b-md justify-between w-full bg-white h-[140px]'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge content='JDH' color='#f0a841' type='round'/>
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                {el.value}
                            </span>
                        </div>
                    );
                })
            }
        </div>
    )
}
