import {ACTION_CODE} from './_actionCode';
import {DealService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_DEAL_BY_ID';

export async function getDealById(userData: Record<string, any>, dealId: number) {
    try {
        LoggerService.logInfo('Action - getDealById - Start');

        const getDealByIdResult = await DealService.getDealById(userData, dealId);

        LoggerService.logInfo('Action - getDealById - End - Success');

        return ResultModel.newSuccessResult(getDealByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - getDealById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
