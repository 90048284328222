import React from 'react';
import {Column} from '@ant-design/plots';

const ColumnChart = ({
    data,
    config,
    legends,
    max,
    min
}) => {
    const colors = [
        '#dc3832',
        '#f0a841',
        '#e36d38',
        '#00abe9',
        '#626681',
        '#EB4185'
    ];

    const formatLegendLabel = (name, index) => ({
        id: `legend-${index}`,
        name: name,
        marker: {
            symbol: 'circle',
            style: {
                fill: colors[index],
                r: 5
            }
        }
    });

    const legendLabels = legends.map(({ name }, index) =>
        formatLegendLabel(name, index)
    );
    
    return (
        <Column {...config(data, colors, legendLabels, max, min)}/>
    )
}

export default ColumnChart;
