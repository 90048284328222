import {Tooltip} from 'antd';

import {frenchFormat} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import checkIcon from '../../../assets/icons/table/check-rounded.png';
import crossIcon from '../../../assets/icons/table/cross-rounded.png';

export const dealsTableConfig = (handleRedirect, key) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'short_name',
                    width: 'w-[25%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                // {
                //     label: 'Client',
                //     value: 'customer.name',
                //     width: 'w-[20%]',
                //     sort: true,
                //     component: (item) => {
                //         return (
                //             <div className="flex flex-row items-center justify-center text-center">
                //                 {item?.customer.name ?? '-'}
                //             </div>
                //         );
                //     },
                //     sumDisplayed: false,
                //     sumBy: '',
                //     sumLabel: ''
                // },
                {
                    label: 'Resp. Commerciaux',
                    value: 'sales',
                    width: 'w-[5%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={item.sales} type='rounded'/>
                            </div>
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Production',
                    value: 'production_lead.username',
                    width: 'w-[5%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.production_lead ?
                                        <Badge content={[item.production_lead]} type='rounded'/> :
                                        '-'
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Phase',
                    value: 'production_lead',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.production_lead ? "P" : "C"}
                                    type='rounded' color={item.production_lead ? '#F0A841' : '#00ABE9'}
                                    tooltip={item.production_lead ? "Phase de production" : "Phase commerciale"}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'type',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.type === "business" ? "Affaire" : item.type === "project" ? "Projet" : item.type}
                                    tooltip={'Type'}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                // {
                //     label: 'DAS',
                //     value: 'das',
                //     width: 'w-[12%]',
                //     sort: true,
                //     component: (item) => {
                //         return (
                //             <div className="flex flex-row items-center justify-center">
                //                 <Badge
                //                     content={item.das}
                //                     color={item.das === 'Indéfini' ? '#646464' : null}
                //                     blueStyle={item.das === 'Formation'}
                //                 />
                //             </div>
                //         );
                //     },
                //     sumDisplayed: false,
                //     sumBy: '',
                //     sumLabel: ''
                // },
                {
                    label: 'Date de fin',
                    value: 'mission_end_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.mission_end_date ? frenchFormat(item.mission_end_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Action',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {key !== 'surveys' ? (
                                    <ActionsButton
                                        type="details"
                                        isLoading={false}
                                        onClick={() => handleRedirect({type: 'deal', key}, item)}
                                        disabled={false}
                                    />
                                ) : (
                                    <div className='flex items-center justify-between gap-x-5'>
                                        <Tooltip title="Accepter">
                                            <img
                                                onClick={() => handleRedirect({type: 'surveys', key: 'validated'}, item)}
                                                className='w-6 h-6 cursor-pointer'
                                                src={checkIcon}
                                            />
                                        </Tooltip>

                                        <Tooltip title="Refuser">
                                            <img
                                                onClick={() => handleRedirect({type: 'surveys', key: 'refused'}, item)}
                                                className='w-6 h-6 cursor-pointer'
                                                src={crossIcon}
                                            />
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
