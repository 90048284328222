import * as z from 'zod';
import * as expenseReportFunctions from './functions';
import {ExpenseModel} from '../expense';
import { UserModel } from '../user';

export const ExpenseReportSchema = z.object({
    'id': z.number(),
    'status': z.number(),
    'ressource': UserModel.UserSchema,
    'payment_date': z.string().nullable(),
    'amount': z.number().nullable(),
    'start_date': z.string().nullable(),
    'vat': z.number().nullable(),
    'end_date': z.string().nullable(),
    'expenses': z.array(ExpenseModel.ExpenseSchema).optional(),
    "children": z.any().optional(),
    'parentReportStatus': z.number().optional()
});

export const ExpenseReportPartialSchema = ExpenseReportSchema.partial();
export type ExpenseReportPartial = z.infer<typeof ExpenseReportPartialSchema>;
export type ExpenseReport = z.infer<typeof ExpenseReportSchema>;

export function newExpenseReport(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...ExpenseReportSchema.parse({...data}),
        ...expenseReportFunctions
    };
}

export function parseExpenseReport(data: any, partial = false) {
    if (!partial) {
        return {
            ...ExpenseReportSchema.parse(data),
            ...expenseReportFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...ExpenseReportPartialSchema.parse(data),
            ...expenseReportFunctions
        };
    }
}
