import * as z from 'zod';

export const TrainingObjectiveSchema = z.object({
    "id": z.number(),
    "is_active": z.boolean(),
    "name": z.string()
});

export const TrainingObjectivePartialSchema = TrainingObjectiveSchema.partial();
export type TrainingObjectivePartial = z.infer<typeof TrainingObjectivePartialSchema>;
export type TrainingObjective = z.infer<typeof TrainingObjectiveSchema>;

export function newTrainingObjective(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...TrainingObjectiveSchema.parse({...data})
    };
}

export function parseTrainingObjective(data: any, partial = false) {
    if (!partial) {
        return {
            ...TrainingObjectiveSchema.parse(data)
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...TrainingObjectivePartialSchema.parse(data)
        };
    }
}
