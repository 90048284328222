import React from 'react';
import Table from '../../../../components/ui/Table';

const ContactsPopup = ({
    data,
    tablePopupConfig
}) => {

    return (
        <div className="px-6 pt-3 pb-6 rounded-lg" style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}>
            <div className="flex flex-row items-center justify-between">
                <p
                    className="text-4xl font-bold capitalize"
                    style={{fontFamily: 'DIN Condensed'}}
                >
                        Carnet des contacts
                </p>
            </div>
            <div className="mt-8">
                <Table data={data} tableConfig={tablePopupConfig}/>
            </div>
        </div>
    );
};

export default ContactsPopup;
