import React from 'react';

const ViewToggleButton = ({calendarFormatView, setCalendarFormatView}) => {
    return (
        <div className="flex flex-row">
            <div className={`${calendarFormatView === 'month' ? 'background-gradient' : 'pr-0 w-[63.5px] bg-[#d9d9d9]'} w-16  rounded-l-[2px]  p-[1px]`}>
                <button
                    className="w-full h-full text-sm bg-white"
                    onClick={() => setCalendarFormatView('month')}
                >
                    <p className={`${calendarFormatView === 'month' ? 'text-gradient mx-auto' : 'text-[#262626]'}`}>
                        Month
                    </p>
                </button>
            </div>

            <div className={`${calendarFormatView === 'year' ? 'background-gradient' : 'pl-0 w-[63.5px] bg-[#d9d9d9]'} w-16  rounded-r-[2px]  p-[1px]`}>
                <button
                    className="w-full h-full text-sm bg-white"
                    onClick={() => setCalendarFormatView('year')}
                >
                    <p className={`${calendarFormatView === 'year' ? 'text-gradient mx-auto' : 'text-[#262626]'}`}>
                        Year
                    </p>
                </button>
            </div>
        </div>
    );
};
export default ViewToggleButton;
