import {Link} from 'react-router-dom';
import {WalletFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import TradeReportProposalsCards from './TradeReportProposalsCards';
import {aggregateCountTotal, aggregateSalesOverview} from '../../../codevDashboardPage/functions/aggregateSalesOverview';

const TradeReport = ({
    data,
    datePickerDate,
    handleDatePickerChange,
    dasKey
}) => {
    const detailCardsData = data[dasKey] ? aggregateSalesOverview(data, dasKey) : {};

    const WalletIcon = <WalletFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className='mt-14'>
            <LineTitle title="Bilan commercial" textSize='text-4xl'/>
            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>
            <div className='mt-7'>
                <Link to='specifications-studied' className='w-[49%] block'>
                    <SimpleCard
                        width='w-full'
                        title={data[dasKey] ? aggregateCountTotal(data, dasKey) : 0}
                        subTitle={'Cahiers des charges étudiés'}
                        iconComponent={WalletIcon}
                    />
                </Link>
                <div className='mt-6'>
                    <TradeReportProposalsCards
                        doneSeulCount={detailCardsData.DoneSeulCount ?? 0}
                        doneMandataireCount={detailCardsData.DoneMandataireCount ?? 0}
                        doneCotraitanceCount={detailCardsData.DoneCotraitanceCount ?? 0}
                        doneSeulDays={detailCardsData.DoneSeulDays ?? 0}
                        doneMandataireDays={detailCardsData.DoneMandataireDays ?? 0}
                        doneCotraitanceDays={detailCardsData.DoneCotraitanceDays ?? 0}
                        doneSeulValue={detailCardsData.DoneSeulValue ?? 0}
                        doneMandataireValue={detailCardsData.DoneMandataireValue ?? 0}
                        doneCotraitanceValue={detailCardsData.DoneCotraitanceValue ?? 0}
                        wonSeulCount={data[dasKey] ? data[dasKey].won.Seul.count : 0}
                        wonMandataireCount={data[dasKey] ? data[dasKey].won.Mandataire.count : 0}
                        wonCotraitanceCount={data[dasKey] ? data[dasKey].won.Cotraitance.count : 0}
                        wonSeulDays={data[dasKey] ? data[dasKey].won.Seul.days : 0}
                        wonMandataireDays={data[dasKey] ? data[dasKey].won.Mandataire.days : 0}
                        wonCotraitanceDays={data[dasKey] ? data[dasKey].won.Cotraitance.days : 0}
                        wonSeulValue={data[dasKey] ? data[dasKey].won.Seul.value : 0}
                        wonMandataireValue={data[dasKey] ? data[dasKey].won.Mandataire.value : 0}
                        wonCotraitanceValue={data[dasKey] ? data[dasKey].won.Cotraitance.value : 0}
                        lostSeulCount={data[dasKey] ? data[dasKey].lost.Seul.count : 0}
                        lostMandataireCount={data[dasKey] ? data[dasKey].lost.Mandataire.count : 0}
                        lostCotraitanceCount={data[dasKey] ? data[dasKey].lost.Cotraitance.count : 0}
                        lostSeulDays={data[dasKey] ? data[dasKey].lost.Seul.days : 0}
                        lostMandataireDays={data[dasKey] ? data[dasKey].lost.Mandataire.days : 0}
                        lostCotraitanceDays={data[dasKey] ? data[dasKey].lost.Cotraitance.days : 0}
                        lostSeulValue={data[dasKey] ? data[dasKey].lost.Seul.value : 0}
                        lostMandataireValue={data[dasKey] ? data[dasKey].lost.Mandataire.value : 0}
                        lostCotraitanceValue={data[dasKey] ? data[dasKey].lost.Cotraitance.value : 0}
                        successRateSeulCount={detailCardsData.SuccessRateSeulCount ?? 0}
                        successRateMandataireCount={detailCardsData.SuccessRateMandataireCount ?? 0}
                        successRateCotraitanceCount={detailCardsData.SuccessRateCotraitanceCount ?? 0}
                        successRateSeulDays={detailCardsData.SuccessRateSeulDays ?? 0}
                        successRateMandataireDays={detailCardsData.SuccessRateMandataireDays ?? 0}
                        successRateCotraitanceDays={detailCardsData.SuccessRateCotraitanceDays ?? 0}
                        successRateSeulValue={detailCardsData.SuccessRateSeulValue ?? 0}
                        successRateMandataireValue={detailCardsData.SuccessRateMandataireValue ?? 0}
                        successRateCotraitanceValue={detailCardsData.SuccessRateCotraitanceValue ?? 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default TradeReport;
