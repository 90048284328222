import { useState, useEffect } from 'react';

import {DealActions, UserActions} from './../../../actions';
import {handleFilterList, useFilterQuery, frenchFormat, fetchSelectData} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import {Link} from 'react-router-dom';
import {SelectInput, TableDatePicker} from '../../../components/ui/Table';

export const invoicesConfig = (
    handleDeleteInvoice,
    handleSendInvoice,
    setIsInvoicePopupOpen,
    setInvoiceToBeUpdated,
    actionButtonLoading,
    actionButtonDisabled,
    displayedSection,
    filterInvoiceList,
    userData
) => {
    const [deals, setDeals] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        deal: '',
        lead: '',
        billing_before: '',
        billing_after: '',
        payment_before: '',
        payment_after: '',
        due_before: '',
        due_after: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterInvoiceList);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    };

    const handleGetDeals = async (search, page = null) => {
        const result = await DealActions.getAllDeals(userData, `&type=business&display=${userData.userProfileName}`, page);
        const hasNextPage = result.data?.next ? true : false;

        if (page === 1 || !page || search) {
            setDeals({ options: result.data, hasNextPage });
        }
        else {
            setDeals(prevData => ({ options: [...prevData.options, ...result.data.results], hasNextPage }));
        }
    };

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        });

        // ↓ deals dropdown values
        (async () => {
            await handleGetDeals('', 1);
        })
    }, []);

    // const status = [
    //     {label: 'Prévisionnelle', value: 0, color: null},
    //     {label: 'A émettre', value: 50, color: '#7FFFD4'},
    //     {label: 'À valider', value: 100, color: '#f0a841'},
    //     {label: 'Validée', value: 200, color: '#e36d38'},
    //     {label: 'Émise', value: 300, color: '#ced95f'},
    //     {label: 'Partiellement Réouvrée', value: 400, color: '#ced95f'},
    //     {label: 'Payée', value: 500, color: '#ced95f'},
    //     {label: 'Annulée', value: 600, color: '#212121'}
    // ];

    const getInvoiceStatus = (data) => {
        if (data.credit) return {label: 'Avoir', color: '#f9d828', textColor: 'black'};

        switch (data.status) {
            case 0:
                return {label: 'Prévisionnelle', color: null, textColor: 'white'};
            case 50:
                return {label: 'À émettre', color: '#7FFFD4', textColor: 'white'};
            case 100:
                return {label: 'À valider', color: '#f0a841', textColor: 'white'};
            case 200:
                return {label: 'Validée', color: '#e36d38', textColor: 'white'};
            case 300:
                return {label: 'Émise', value: 300, color: '#ced95f', textColor: 'white'}
            case 400:
                return {label: 'Partiellement Réouvrée', color: '#ced95f', textColor: 'white'};
            case 500:
                return {label: 'Payée', color: '#ced95f', textColor: 'white'};
            case 600:
                return {label: 'Annulée', color: '#212121', textColor: 'white'};
            default:
                return {status: 'Indéfini', color: '#6c757d', textColor: 'white'};
        }
    };

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Numéro facture',
                    value: 'official_id',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.official_id ? `N°${item.official_id}` : "N/A"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Affaire',
                    value: 'deal.short_name',
                    width: 'w-[18%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.deal.short_name}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="deal"
                        options={deals.options}
                        hasNextPage={deals?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetDeals)}
                        labelKeys={['short_name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        const statusData = getInvoiceStatus(item);

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={statusData.label} color={statusData.color} textColor={statusData.textColor}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Production',
                    value: 'deal.production_lead',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={[item.deal.production_lead]} type='rounded'/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="lead"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Facturation',
                    value: 'billing_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.billing_date)}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='billing' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Echéance',
                    value: 'due_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.due_date)}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='due' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Paiement',
                    value: 'payment_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.payment_date ? frenchFormat(item.payment_date) : "-"}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='payment' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net HT',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(+item.billable + +item.expenses).toLocaleString() + " €"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net TVA',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        const getVatAmount = (amount, vat) => {
                            if (!amount || !vat) return 0;

                            const percent = amount * vat;

                            return +amount + percent;
                        }

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {(getVatAmount(+item.billable, +item.billable_vat_rate) + getVatAmount(+item.expenses, +item.expenses_vat_rate)).toLocaleString()} €
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">

                                {displayedSection === 'Factures à émettre' ?
                                    <>
                                        <ActionsButton
                                            type="edit"
                                            isLoading={false}
                                            onClick={() => {
                                                setInvoiceToBeUpdated(item);
                                                setIsInvoicePopupOpen(true);
                                            }}
                                            disabled={actionButtonDisabled}
                                        />
                                        <div className="w-0 h-0 px-2"/>
                                        <ActionsButton
                                            type="delete"
                                            isLoading={actionButtonLoading === item.id}
                                            onClick={() => handleDeleteInvoice(item)}
                                            disabled={actionButtonDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette facture ?'
                                        />
                                    </>
                                    :
                                    <ActionsButton
                                        type="send"
                                        customTooltip={"Emettre cette facture"}
                                        isLoading={actionButtonLoading === item.id}
                                        onClick={() => {
                                            // setIsInvoicePopupOpen(true)
                                            // setInvoiceToBeUpdated(item)

                                            handleSendInvoice(item);
                                        }}
                                        disabled={actionButtonDisabled}
                                        payConfirmationQuestion='Souhaitez-vous émettre cette facture ?'
                                    />}

                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Link to={`${item.id}`} id='skip' className='ml-1'>
                                    <ActionsButton
                                        type="details"
                                        isLoading={false}
                                        onClick={() => null}
                                        disabled={actionButtonDisabled}
                                    />
                                </Link>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
