import {frenchFormat, standardFormat} from "../../../utils";

export const exportExpenseReportDataToCsv = (
    expenseReportData,
    setCsvProps,
    setCsvExportLoading,
    datePickerDate
) => {

    setCsvExportLoading(true);

    let csvData = [];
    
    const getExpenseReportStatus = (status) => {
        switch (status) {
            case 0:
                return 'En cours';
            case 1:
                return 'À vérifier';
            case 2:
                return 'À payer';
            case 3:
                return 'Payée';
            default:
                return 'Indéfini';
        }
    };
        
    const getRessourceNames = (ressource) => {
        if (!ressource) {
            return '';
        }
        else {
            const fisrtname = ressource.first_name ?? '';
            const lastname = ressource.last_name ?? '';
            const username = ressource.username ?? '';
        
            return `${username} - ${fisrtname} ${lastname}`
        }
    }
        
    for (const expenseReport of expenseReportData) {
        csvData.push({
            expenseReportId: expenseReport.id,
            expenseReportStatus: getExpenseReportStatus(expenseReport.status),
            expenseReportRessource: getRessourceNames(expenseReport.ressource),
            expenseReportPaymentDate: expenseReport.status === 3 ? frenchFormat(expenseReport.payment_date) : '-',
            expenseReportAmount: expenseReport.amount,
            expenseReportVat: expenseReport.vat,
            expenseReportStartDate: frenchFormat(expenseReport.start_date),
            expenseReportEndDate: frenchFormat(expenseReport.end_date),
            expenseNumber: '',
            expenseDeal: '',
            expenseType: '',
            expenseDate: '',
            expenseRefact: '',
            expenseAmount: '',
            expenseVat: '',
            expenseCo2: '',
            expenseNotes: ''
        });
        
        for (const expense of expenseReport.expenses) {
            csvData.push({
                expenseReportId: '',
                expenseReportStatus: '',
                expenseReportRessource: '',
                expenseReportPaymentDate: '',
                expenseReportAmount: '',
                expenseReportVat: '',
                expenseReportStartDate: '',
                expenseReportEndDate: '',
                expenseNumber: expense.id,
                expenseDeal: expense.deal?.short_name ?? '-',
                expenseType: expense.type?.name ?? '-',
                expenseDate: frenchFormat(expense.date),
                expenseRefact: expense.billable ? 'Oui' : 'Non',
                expenseAmount: +(expense.amount),
                expenseVat: +(expense.vat),
                expenseCo2: +(expense.co2),
                expenseNotes: expense.notes
            });
        }
    }

    const headers = [
        {label: 'ID', key: 'expenseReportId'},
        {label: 'Satut', key: 'expenseReportStatus'},
        {label: 'Ressource', key: 'expenseReportRessource'},
        {label: 'Date de paiement', key: 'expenseReportPaymentDate'},
        {label: 'Montant HT (€)', key: 'expenseReportAmount'},
        {label: 'TVA (%)', key: 'expenseReportVat'},
        {label: 'Date de début', key: 'expenseReportStartDate'},
        {label: 'Date de fin', key: 'expenseReportEndDate'},

        {label: '#', key: 'expenseNumber'},
        {label: 'Affaire', key: 'expenseDeal'},
        {label: 'Type', key: 'expenseType'},
        {label: 'Date', key: 'expenseDate'},
        {label: 'Refact.', key: 'expenseRefact'},
        {label: 'Montant', key: 'expenseAmount'},
        {label: 'TVA', key: 'expenseVat'},
        {label: 'CO2', key: 'expenseCo2'},
        {label: 'Notes', key: 'expenseNotes'}
    ];

    setCsvProps({
        filename: `frais_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}

export const exportExpenseDataToCsv = (
    expenseData,
    setCsvProps,
    setCsvExportLoading
) => {

    setCsvExportLoading(true);

    let csvData = [];

    for (const expense of expenseData) {
        csvData.push({
            expenseNumber: expense.id,
            expenseDeal: expense.deal?.short_name ?? '-',
            expenseType: expense.type?.name ?? '-',
            expenseDate: frenchFormat(expense.date),
            expenseRefact: expense.billable ? 'Oui' : 'Non',
            expenseAmount: +(expense.amount),
            expenseVat: +(expense.vat),
            expenseCo2: +(expense.co2),
            expenseNotes: expense.notes
        });
    }

    const headers = [
        {label: '#', key: 'expenseNumber'},
        {label: 'Affaire', key: 'expenseDeal'},
        {label: 'Type', key: 'expenseType'},
        {label: 'Date', key: 'expenseDate'},
        {label: 'Refact.', key: 'expenseRefact'},
        {label: 'Montant', key: 'expenseAmount'},
        {label: 'TVA', key: 'expenseVat'},
        {label: 'CO2', key: 'expenseCo2'},
        {label: 'Notes', key: 'expenseNotes'}
    ];

    setCsvProps({
        filename: `liste_des_frais_${standardFormat(new Date())}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}
