import { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

import {frenchFormat, fetchSelectData, handleFilterList, useFilterQuery} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import { UserActions, CustomerActions } from '../../../actions';
import { SelectInput, TableDatePicker } from '../../../components/ui/Table';

export const invoicesTableConfigAwaitingPaiment = ({
    handleInvoicePreview,
    handleInvoicePaiement,
    filterInvoicesList,
    tableType,
    userData
}) => {
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        lead: '',
        customer: '',
        invoice_billing_before: '',
        invoice_billing_after: '',
        invoice_payment_before: '',
        invoice_payment_after: '',
        mission_end_date_before: '',
        mission_end_date_after: ''
    });

    const navigate = useNavigate();

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterInvoicesList, tableType);

    // ↓ get data for select input
    const handleGetUsers = async (search, page) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search, page: page || null, setter: setUsers, userData});
    };

    const handleGetCustomers = async (search, page) => {
        await fetchSelectData({action: CustomerActions.getAllCustomers, search, page: page || null, setter: setCustomers, userData});
    };

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        });

        // ↓ customer dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();
    }, []);
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Numéro facture',
                    value: 'id',
                    width: 'w-[5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        const getInvoiceStatus = (data) => {
                            if (data.credit) return {label: 'Avoir', color: '#f9d828', textColor: 'black'};
                    
                            switch (data.status) {
                                case 0:
                                    return {label: 'Prévisionnelle', color: null, textColor: 'white'};
                                case 50:
                                    return {label: 'À émettre', color: '#7FFFD4', textColor: 'white'};
                                case 100:
                                    return {label: 'À valider', color: '#f0a841', textColor: 'white'};
                                case 200:
                                    return {label: 'Validée', color: '#e36d38', textColor: 'white'};
                                case 300:
                                    return {label: 'Émise', value: 300, color: '#ced95f', textColor: 'white'}
                                case 400:
                                    return {label: 'Partiellement Réouvrée', color: '#ced95f', textColor: 'white'};
                                case 500:
                                    return {label: 'Payée', color: '#ced95f', textColor: 'white'};
                                case 600:
                                    return {label: 'Annulée', color: '#212121', textColor: 'white'};
                                default:
                                    return {status: 'Indéfini', color: '#6c757d', textColor: 'white'};
                            }
                        };

                        const statusData = getInvoiceStatus(item);

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={statusData.label} color={statusData.color} textColor={statusData.textColor}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Production',
                    value: 'deal.production_lead',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.deal.production_lead ?
                                        <Badge content={[item.deal.production_lead]} type='rounded'/>
                                        : '-'
                                }
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="lead"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Client',
                    value: null,
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full text-center">
                                {item.customer?.name ?? '-'}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="customer"
                        options={customers?.options?.sort((a, b) => a.name.localeCompare(b.name))}
                        hasNextPage={customers?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetCustomers)}
                        labelKeys={['name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Facturation',
                    value: 'billing_date',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.billing_date ? frenchFormat(item.billing_date) : '-'}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='invoice_billing' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Échéance',
                    value: 'due_date',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.due_date ?
                                        frenchFormat(item.due_date)
                                        : '-'
                                }
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name="invoice_due" onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Paiement',
                    value: 'payment_date',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.payment_date ? frenchFormat(item.payment_date) : "-"}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='invoice_payment' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net HT',
                    value: null,
                    width: 'w-[8%]',
                    sort: false,
                    component: (item) => {

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <p className={`${item.credit && 'text-red-500'}`}>
                                    {`${item.credit ? '-' : ''}${parseInt(+item.billable + +item.expenses).toLocaleString()} €`}
                                </p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net TVA',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <p className={`${item.credit && 'text-red-500'}`}>
                                    {`${item.credit ? '-' : ''}${(+item.billable + +item.billable * (+item.billable_vat_rate) + +item.expenses + +item.expenses * (+item.expenses_vat_rate))
                                        .toLocaleString()} €`}
                                </p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center gap-x-3">
                                <ActionsButton
                                    type="partialPay"
                                    onClick={() => handleInvoicePaiement(item)}
                                />
                            
                                <ActionsButton
                                    type="overview"
                                    onClick={() => handleInvoicePreview(item)}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => navigate(`/invoices/${item.id}`)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
