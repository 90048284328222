import {ACTION_CODE} from './_actionCode';
import {DealOriginsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_DEAL_ORIGIN';

export async function getAllDealOrigins(
    userData: Record<string, string>,
    params = '',
    page = 1,
) {
    try {
        LoggerService.logInfo('Action - getAllDealOrigins - Start');

        const getAllDealOriginsResult = await DealOriginsService.getAllDealOrigins(userData, params, page);

        LoggerService.logInfo('Action - getAllDealOrigins - Success');

        return ResultModel.newSuccessResult(getAllDealOriginsResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllDealOrigins - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des origines des affaires',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
