import DonutsChart from "../../../components/ui/DonutsChart/DonutsChart";
import {grossMarginChartConfig} from "./grossMarginChartConfig";

export const grossMarginTabConfig = (data) => {

    const getSum = (object, key) => {
        let sum = 0;
    
        for (const k in object) {
            sum += object[k][key];
        }
    
        return sum;
    }

    const getTotal = () => {
        return (
            [
                {
                    type: 'Junior',
                    value: getSum(data, 'junior')
                },
                {
                    type: 'Confirmé',
                    value: getSum(data, 'confirmed')
                },
                {
                    type: 'Sénior',
                    value: getSum(data, 'senior')
                }
            ]
        )
    }

    const getData = (status) => {
        return (
            [
                {
                    type: 'Junior',
                    value: status.junior
                },
                {
                    type: 'Confirmé',
                    value: status.confirmed
                },
                {
                    type: 'Sénior',
                    value: status.senior
                }
            ]
        )
    }

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <DonutsChart label={''} data={getTotal()} config={grossMarginChartConfig}/>
    }];

    for(const key in data) {
            
        const item = {
            id: key,
            label: key,
            component: <DonutsChart label={''} data={getData(data[key])} config={grossMarginChartConfig}/>
        }

        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            childOverflow: 'overflow-y-auto',
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
