import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import { ExpenseActions } from '../../../../actions';
import { notify } from '../../../../components/ui/Toast/Toast';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import Popup from '../../../../components/ui/Popup';
import SpendingExpensePopup from '../../popups/dealDetailPopups/SpendingExpensePopup';
//import SpendingIkPopup from '../../popups/dealDetailPopups/SpendingIkPopup';
import { dealSpendingsTableConfig } from '../../configs/sectionConfigs/dealSpendingsTableConfig';

const SpendingsSection = ({
    data,
    dealData,
    refreshSpendingsData,
    buttonsDisabled,
    setButtonsDisabled,
    blueStyle,
    spendingsPaginationMetadata,
    spendingsTableLoading,
    setSpendingsFilterQuery,
    cannotBeModified,
    handleGetAllSpendingsData
}) => {
    const [expenseToBeUpdated, setExpenseToBeUpdated] = useState(null);
    const [deleteSpendingLoading, setDeleteSpendingLoading] = useState(null);
    const [spendingPopupView, setSpendingPopupView] = useState(false);
    const [typeForm, setTypeForm] = useState('Frais');

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // Delete Table Item
    const handleDeleteSpending = async (id) => {
        setButtonsDisabled(true);
        setDeleteSpendingLoading(id);

        const result = await ExpenseActions.deleteDealExpense(userData, id);

        if (result.success) {
            await refreshSpendingsData(true);
            notify('La dépense a été supprimée');
        }
        else {
            notify('Erreur lors de la suppression de la dépense');
        }

        setDeleteSpendingLoading(null);
        setButtonsDisabled(false);
    }

    // Edit Table Item
    const handleEditSpending = (item) => {
        setExpenseToBeUpdated(item);
        setTypeForm(item?.type?.id === 1 ? 'Indemnités kilométriques' : 'Frais');
        setSpendingPopupView(true);
    }
    // Filter functions
    const filterExpenseList = async (query) => {
        if(!dealData.id) return;
        
        setSpendingsFilterQuery(query);
    };

    // Table Config
    const spendingsTableConfig = dealSpendingsTableConfig(
        handleEditSpending,
        handleDeleteSpending,
        deleteSpendingLoading,
        buttonsDisabled,
        blueStyle,
        filterExpenseList,
        userData,
        cannotBeModified
    );

    // Reset item selected when popup is closed
    useEffect(() => {
        if (!spendingPopupView) {
            setExpenseToBeUpdated(null);
            setTypeForm(null);
        }
    }, [spendingPopupView]);

    return (
        <div>
            <div>
                <ContainerCard
                    title='Achats'
                    buttonContent='Nouvel achat'
                    onButtonClick={() => setSpendingPopupView(true)}
                    blueStyle={blueStyle}
                    overflow='overflow-inherit'
                    coloredButtonDisplayed={!cannotBeModified}
                >
                    <Table
                        data={data || []}
                        tableConfig={spendingsTableConfig}
                        loading={spendingsTableLoading}
                        paginationMetadata={spendingsPaginationMetadata}
                        onPageNumberChange={handleGetAllSpendingsData}
                        blueStyle={blueStyle}
                    />
                </ContainerCard>
            </div>

            {/* Spending Popup */}
            <Popup
                view={spendingPopupView}
                setView={setSpendingPopupView}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
            >
                <SpendingExpensePopup
                    userData={userData}
                    dealData={dealData}
                    setView={setSpendingPopupView}
                    expenseToBeUpdated={expenseToBeUpdated}
                    setSwitchForm={setTypeForm}
                    switchForm={typeForm}
                    refreshData={refreshSpendingsData}
                    blueStyle={blueStyle}
                />
            </Popup>
        </div>
    )
}

export default SpendingsSection;
