import React from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import the notify function and Toast Component in the component you want to use it in
// Pass the notify function to onClick and give it a message, it will display a toast with the message
// <button onClick={() => notify("Hello World")}>Notify</button>
// <Toast />

export const notify = (message) => toast(
    message, {
        position: 'top-left'
    }
);

export function Toast() {
    return (
        <div>
            <ToastContainer
                hideProgressBar={true}
                closeButton={false}
                toastClassName={() => {
                    return (
                        `py-2 pl-4 pr-4 max-w-screen-xl bg-gradient-to-r from-toastGradientStart via-toastGradientMiddle to-toastGradientEnd
                         rounded-md flex flex-row items-center my-1 shadow-md`
                    );
                }}
                bodyClassName={() => 'text-[#FFFFFF] text-sm font-normal'}
            />
        </div>
    );
}
