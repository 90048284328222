import {AttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_ATTACHMENT_TYPES';

export async function getAllAttachmentTypes(userData: Record<string, string>, params = "", page = 1) {

    try {
        const attachmentTypes =  await AttachmentDao.getAllAttachmentTypes(userData.userToken, params, page);

        return attachmentTypes
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
