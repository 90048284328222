import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ConsultantDashboardPage from '../pages/consultantDashboardPage/ConsultantDashboardPage';

function ConsultantDashboardRouter() {
    return (
        <Routes>
            <Route path='/*' element={<ConsultantDashboardPage/>}/>
        </Routes>
    );
}

export default ConsultantDashboardRouter;
