import {ACTION_CODE} from './_actionCode';
import {ContractService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_CONTRACTS';

export async function getAllContracts(
    userData: Record<string, string>,
    query = ''
) {
    try {
        LoggerService.logInfo('Action - getAllcontracts - Start');

        const getAllcontractsResult = await ContractService.getAllContracts(userData, query);

        LoggerService.logInfo('Action - getAllcontracts - End - Success');

        return ResultModel.newSuccessResult(getAllcontractsResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllcontracts - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation des contrats.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
