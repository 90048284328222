import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../../../components/ui/ContainerCard';
import TextInput from '../../../../../../components/ui/Inputs/TextInput';
import Button from '../../../../../../components/ui/Button';
import {notify} from '../../../../../../components/ui/Toast/Toast';
import Error from '../../../../../../components/ui/Error/Error';
import {getErrors} from '../../../../../../utils';
import { capitalize } from '../../../../../../utils';
import {SubsidiaryActions} from "../../../../../../actions"
import { fetchSelectData } from '../../../../../../utils/fetchSelectData';

const CreateNewBank = ({
    setView,
    userData,
    sectionName,
    toBeUpdated,
    actionCreate,
    actionUpdate,
    refreshData

}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [subsidiaryCategories, setSubsidiaryCategories] = useState(null)

    const getSubsidiaries = async (search, page = 1) => {
        await fetchSelectData({action: SubsidiaryActions.getAllSubsidiaries, search:`&search=${search}`, page: page, setter: setSubsidiaryCategories, userData, resultPath: 'data'});
    };
    
    const status = [
        {
            id: 1,
            name: "Actif",
            value: true
        },
        {
            id: 2,
            name: "Archivé",
            value: false
        }
    ];

    // -- react hook form --
    const defaultValues = {
        name: '',
        status: status[0],
        bic: '',
        iban: "",
        subsidiary: ""
    };

    // -- fill expense values in form for update --
    useEffect(() => {
        if (toBeUpdated) {
            reset({
                name: toBeUpdated.name,
                bic: toBeUpdated.bic,
                iban: toBeUpdated.iban,
                subsidiary: {name: toBeUpdated.subsidiary},
                status: toBeUpdated.is_active ? status[0] : status[1]
            });
        }
    }, [toBeUpdated]);
    
    const {handleSubmit, control, reset , formState: {isValid}} = useForm({defaultValues: defaultValues});
    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newItem = {
            name: data.name,
            is_active: data.status.value,
            bic: data.bic,
            iban: data.iban,
            subsidiary: data.subsidiary.name
        }

        if (!toBeUpdated) {

            const result = await actionCreate(userData, newItem);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                setView(false);
                notify(`${capitalize(sectionName.name)} à bien été créé${sectionName.gender === "masculin" ? "" : "e"}`);
            }
        }
        else {
            const result = await actionUpdate(userData, toBeUpdated.id, newItem);
            
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                setView(false);
                notify(`${capitalize(sectionName.name)} à bien été mis${sectionName.gender === "masculin" ? "" : "e"} à jour`);
            }
        }
        refreshData()
        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={`${toBeUpdated ? `Modifier ${sectionName.gender === "masculin" ? "un" : "une"}` : `Ajouter un${sectionName.gender === "masculin" ? "" : "e"}`} ${sectionName.name}`}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-6">
                <div className='flex flex-col'>
                    <div className="mb-3">
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Nom'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Nom"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="iban"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='IBAN'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="FR7630001007941234567890185"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="bic"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='BIC'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="SOGEFRPP"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                
                    <div className="mb-3">
                        <Controller
                            name="subsidiary"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir un statut'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Filliale'
                                    placeholder='Filliale'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    fetchFunction={getSubsidiaries}
                                    options={subsidiaryCategories?.options}
                                    loading={!subsidiaryCategories}
                                    hasNextPage={subsidiaryCategories?.hasNextPage}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                />
                            )
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="status"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir un statut'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Statut'
                                    placeholder='Statut'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={status}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                />
                            )
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={`${toBeUpdated ? `Modifier ${sectionName.gender === "masculin" ? "un" : "une"}` : `Ajouter un${sectionName.gender === "masculin" ? "" : "e"}`} ${sectionName.name}`}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewBank;
