import {ACTION_CODE} from './_actionCode';
import {PaymentMethodService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_PAYMENT_METHOD';

export async function deletePaymentMethodById(userData: Record<string, any>, paymentMethodId: number) {
    try {
        LoggerService.logInfo('Action - deletePaymentMethodById - Start');

        const deletePaymentMethodByIdResult = await PaymentMethodService.deletePaymentMethod(userData, paymentMethodId);

        LoggerService.logInfo('Action - deletePaymentMethodById - End - Success');

        return ResultModel.newSuccessResult(deletePaymentMethodByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deletePaymentMethodById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une méthode de paiement',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
