import * as z from 'zod';
import * as vehicleTypeFunctions from './functions';

export const VehicleTypeSchema = z.object({
    id: z.number(),
    is_active: z.boolean(),
    name: z.string(),
    min_distance: z.number().nullable(),
    max_distance: z.number().nullable(),
    formula: z.string()
});

export const VehicleTypePartialSchema = VehicleTypeSchema.partial();
export type VehicleTypePartial = z.infer<typeof VehicleTypePartialSchema>;
export type VehicleType = z.infer<typeof VehicleTypeSchema>;

export function newVehicleType(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...VehicleTypeSchema.parse({...data}),
        ...vehicleTypeFunctions
    };
}

export function parseVehicleType(data: any, partial = false) {
    if (!partial) {
        return {
            ...VehicleTypeSchema.parse(data),
            ...vehicleTypeFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...VehicleTypePartialSchema.parse(data),
            ...vehicleTypeFunctions
        };
    }
}
