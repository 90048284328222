import {ACTION_CODE} from './_actionCode';
import {DealAbandonmentMotivesDao} from '../../dao';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_DEALLOSSMOTIVE';

export async function updateDealAbandonmentMotive(
    userData: Record<string, any>,
    id: number,
    payload: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateDealAbandonmentMotive - Start');

        const updateDealLossMotiveByIdResult = await DealAbandonmentMotivesDao.updateDealAbandonmentMotive(userData.userToken, id, payload);

        LoggerService.logInfo('Action - updateDealAbandonmentMotive - End - Success');

        return ResultModel.newSuccessResult(updateDealLossMotiveByIdResult);
    }
    catch (error) {

        LoggerService.logInfo('Action - updateDealAbandonmentMotive - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: "Une erreur est survenue lors de la mise à jour d'une banque",
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
