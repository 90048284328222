import {ACTION_CODE} from './_actionCode';
import {AttachmentTypeDao} from '../../dao';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_ATTACHMENT_TYPE';

export async function updateAttachmentType(
    userData: Record<string, string>,
    id: number,
    payload: Record<string, unknown>
) {
    try {

        LoggerService.logInfo('Action - updateAttachmentType - Start');

        const updateAttachmentTypeResult = await AttachmentTypeDao.updateAttachmentType(userData.userToken, id, payload);

        LoggerService.logInfo('Action - updateAttachmentType - End - Success');

        return ResultModel.newSuccessResult(updateAttachmentTypeResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateAttachmentType - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des s des documents.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
