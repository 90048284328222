import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'UPDATE_EXPENSE';

export async function updateExpenseReportById(
    userToken: string,
    expenseId: string,
    update: Record<string, unknown>
) {
    try {
        const response = await axios({
            method: 'patch',
            url: `${URL}/expense-reports/${expenseId}/`,
            data: update,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        const errorMessage = () => {
            if (error.response.data.amount) {
                return error.response.data.amount[0];
            }
            else if (error.response.data.deal_id) {
                return error.response.data.deal_id[0];
            }
            else {
                return 'Une erreur de mise à jour d\'une note de frais';
            }
        };
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: errorMessage(),
            error: error
        });
    }
}
