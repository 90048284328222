import {useState} from 'react';

import Button from '../../../components/ui/Button/Button';
import Badge from '../../../components/ui/Badge/Badge';
import ActionsButton from '../../../components/ui/ActionsButton/ActionsButton';
import CreateReferencePopup from './CreateReferencePopup';
import NewReferencePopUp from './NewReferencePopUp';

const AssignatedReferenceBanner = ({reference, userData, refreshData}) => {
    const [editReferencePopUpView, setEditReferencePopUpView] = useState(false);
    const [newReferencePopUpView, setNewReferencePopUpView] = useState(false);
    
    const userRole = userData.role;
    const userId = userData.userId;

    return (
        <>
            <div
                style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
                className='flex justify-between items-center w-full rounded-lg px-6 h-[72px]'
            >
                <div className='flex items-center justify-start gap-x-4'>
                    <p style={{fontFamily: 'DIN Condensed'}} className='text-xl'>
                        {reference.deal.short_name}
                    </p>
                    <Badge type='rounded' content={userData.userProfileName}/>
                    {
                        ((userRole === 'PA' || userRole === 'MA') || userId === reference?.deal?.production_lead?.id) ?
                            <ActionsButton
                                onClick={() => setNewReferencePopUpView(true)}
                                type='edit'
                            />
                            : null
                    }
                </div>
                <Button
                    onClick={() => setEditReferencePopUpView(true)}
                    content='Créer'
                />
            </div>
            <CreateReferencePopup
                view={editReferencePopUpView}
                setView={setEditReferencePopUpView}
                reference={reference}
                userData={userData}
                refreshData={refreshData}
            />
            <NewReferencePopUp
                view={newReferencePopUpView}
                setView={setNewReferencePopUpView}
                userData={userData}
                refreshData={refreshData}
                editReference={true}
                references={[reference]}
            />
        </>
    );
}

export default AssignatedReferenceBanner;
