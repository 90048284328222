import React from 'react';
import {Route, Routes} from 'react-router-dom';
import StaffingPage from '../pages/staffingPage/StaffingPage';

function StaffingRoute() {
    return (
        <Routes>
            <Route path='/' element={<StaffingPage/>}/>
        </Routes>

    );
}

export default StaffingRoute;
