import {CustomersDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_CUSTOMER_BY_ID';

export async function getCustomerById(userData: Record<string, string>, customerId: number) {
    try {

        const customerResult = await CustomersDao.getCustomerById(userData.userToken, customerId);

        return customerResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération du client.',
            error: error
        });
    }
}
