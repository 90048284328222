import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import Layout from '../../components/layout/Layout';
import CreateNewProductionPopup from './components/createProductionPopup/CreateNewProductionPopup/CreateNewProductionPopup';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import {ProductionActions} from '../../actions';
import TimeBalance from './components/TimeBalance';
import Calendar from '../../components/ui/Calendar';
import {standardFormat, firstDayMonth, lastDayMonth, firstDayYear, lastDayYear} from '../../utils';
import {productionsCalendarConfig} from './configs/productionsCalendarConfig';
import {productionsTablePopupConfig} from './configs/productionsTablePopupConfig';
import {productionsTable} from './configs/productionsTable';
import {productionsDrop} from './configs/productionsDrop.js';
import {vacationsTable} from './configs/vacationsTable.js';
import CalendarTablePopup from '../../components/ui/CalendarTablePopup';

function ProductionsPage() {
    /* -- States -- */
    // Calendar states
    const [calendarYear, setCalendarYear] = useState(new Date().getFullYear());
    const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth());
    const [calendarView, setCalendarView] = useState('month');
    const [calendarData, setCalendarData] = useState({});
    const [loadingCalendar, setLoadingCalendar] = useState(false);

    // Table states
    const [datePickerDate, setDatePickerDate] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [productionPaginationMetadata, setProductionPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 5
    });
    const [productionFilter, setProductionFilter] = useState('');
    const [productionsData, setProductionsData] = useState([]);
    const [vacationsData, setVacationsData] = useState({});
    const [loadingTable, setLoadingTable] = useState(false);
    const [actionButtonLoading, setActionButtonLoading] = useState(null);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    // Popups states
    const [calendarTablePopupVisible, setCalendarTablePopupVisible] = useState(false);
    const [calendarTableLoading, setCalendarTableLoading] = useState(false);
    const [calendarTablePopupData, setCalendarTablePopupData] = useState([]);
    const [calendarCellSelected, setCalendarCellSelected] = useState({
        type: 'day',
        date: null
    });
    const [newProductionPopupVisible, setNewProductionPopupVisible] = useState(false);
    const [isModifyActive, setIsModifyActive] = useState(null);

    // ---
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDatePickerChange = (e) => {
        setDatePickerDate({
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        });
    };
    
    /* -- Functions that get Data -- */
    const handleGetCalendarData = async () => {
        setLoadingCalendar(true);
        setCalendarData({});

        if (calendarView === 'month') {
            const calendarBefore = standardFormat(lastDayMonth(new Date(calendarYear, calendarMonth)));
            const calendarAfter = standardFormat(firstDayMonth(new Date(calendarYear, calendarMonth)));
            
            const result = await ProductionActions.getAggregatedCalendarProductionsByDay(
                userData,
                calendarBefore,
                calendarAfter,
                `&ressource=${userData.userProfileName}`
            );

            if (result.success) {
                //console.log(result.data);
                setCalendarData(result.data);
            }
            else {
                notify('Erreur de récuperation des données du calendrier');
            }
        }
        else if (calendarView === 'year') {
            const calendarBefore = standardFormat(lastDayYear(new Date(calendarYear.toString())));
            const calendarAfter = standardFormat(firstDayYear(new Date(calendarYear.toString())));
            
            const result = await ProductionActions.getAggregatedCalendarProductionsByMonth(
                userData,
                calendarBefore,
                calendarAfter,
                `&ressource=${userData.userProfileName}`
            );

            if (result.success) {
                setCalendarData(result.data);
            }
            else {
                notify('Erreur de récuperation des données du calendrier');
            }
        }

        setLoadingCalendar(false);
    }

    const handleGetProductionsTableData = async (page) => {
        setLoadingTable(true);
        const datePickerBefore = standardFormat(new Date(datePickerDate.endDate));
        const datePickerAfter = standardFormat(new Date(datePickerDate.startDate));
        
        const result = await ProductionActions.getProductionsGroupedByDeal(
            userData,
            datePickerBefore,
            datePickerAfter,
            `&ressource=${userData.userProfileName}&${productionFilter}`
        );

        if (result.success) {
            setProductionsData(result.data);
            setProductionPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (productionPaginationMetadata.totalItems !== result.metadata.count) {
                setProductionPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des données du tableau');
        }

        setLoadingTable(false);
    }

    const handleGetVacationsTableData = async () => {
        setLoadingTable(true);
        const datePickerBefore = standardFormat(new Date(datePickerDate.endDate));
        const datePickerAfter = standardFormat(new Date(datePickerDate.startDate));
        
        const result = await ProductionActions.getProductionsGroupedByDeal(
            userData,
            datePickerBefore,
            datePickerAfter,
            `&ressource=${userData.userProfileName}&search=conge`
        );

        if (result.success) {
            setVacationsData(result.data[0]);
        }
        else {
            notify('Erreur de récuperation des données du tableau');
        }

        setLoadingTable(false);
    };

    /* -- Get data -- */
    // Calendar data
    useEffect(() => {
        handleGetCalendarData();
    }, [calendarView, calendarYear, calendarMonth]);

    // Table data
    useEffect(() => {
        handleGetProductionsTableData(1);
        handleGetVacationsTableData(1);
    }, [datePickerDate, productionFilter]);

    useEffect(() => {
        calendarTablePopupVisible &&
        calendarCellDataFormat(calendarCellSelected.type, calendarCellSelected.date);
    }, [calendarData]);

    /* -- Popup Functions -- */
    const handleGetCalendarCellData = async (page, typeSended = null, dateSended = null) => {
        setCalendarTableLoading(true);

        let after;
        let before;

        if(!typeSended || !dateSended) {
            const {type, date} = calendarCellSelected;
            
            after = type === 'day' ? date : standardFormat(firstDayMonth(new Date(date)));
            before = type === 'day' ? date : standardFormat(lastDayMonth(new Date(date)));
        }
        else {
            after = typeSended === 'day' ? dateSended : standardFormat(firstDayMonth(new Date(dateSended)));
            before = typeSended === 'day' ? dateSended : standardFormat(lastDayMonth(new Date(dateSended)));
        }
        
        const result = await ProductionActions.getAllProductions(
            userData,
            before,
            after,
            {},
            false,
            `page=${page}&page_size=${productionPaginationMetadata.pageSize}&ressource=${userData.userProfileName}`
        );

        if (result.success) {
            //sort result.data by date
            const sortedDataByDate = result.data.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });
            setCalendarTablePopupData(sortedDataByDate);
            setProductionPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (productionPaginationMetadata.totalItems !== result.metadata.count) {
                setProductionPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des données du calendrier');
        }

        setCalendarTableLoading(false);
    };

    const calendarCellDataFormat = async (type, date) => {
        setCalendarTablePopupVisible(true);

        await handleGetCalendarCellData(1, type, date);
    }

    const handleCalendarCellClick = ({type, date}) => {
        setCalendarCellSelected({
            type,
            date
        });
        calendarCellDataFormat(type, date);
    };

    const handleButtonTablePopupClick = (date) => {
        setNewProductionPopupVisible(true);
        setIsModifyActive({date});
    };

    const handleTimeBalanceButtonClick = () => {
        setNewProductionPopupVisible(true);
        setIsModifyActive(null);
    };

    /* -- Refresh Functions -- */
    const handleRefreshCellData = async (removal = false) => {
        const checkedPage = (calendarTablePopupData.length === 1 && productionPaginationMetadata.currentPage > 1 && removal) ?
            productionPaginationMetadata.currentPage - 1 :
            productionPaginationMetadata.currentPage;

        await handleGetCalendarCellData(checkedPage);
    };

    const refreshData = async () => {
        // Calendar data refresh
        handleGetCalendarData();

        if (calendarTablePopupVisible) {
            await handleRefreshCellData();
        }

        // Table data refresh
        handleGetProductionsTableData();
        handleGetVacationsTableData();
    };

    /* -- Delete Functions -- */
    const handleDeleteProduction = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);

        const result = await ProductionActions.deleteProduction(userData, item.id);

        if (result.success) {
            if(calendarTablePopupVisible) {
                await handleRefreshCellData(true);
            }
            else {
                await refreshData(true);
            }
            
            notify('Votre temps à bien été supprimé');
        }
        else {
            notify('Votre temps n\'a pas pu être supprimé');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    /* -- Filter Functions -- */
    const filterProductionByDealList = async (query) => {
        setProductionFilter(query);
    }

    /* -- Configs -- */
    // Calendar configs
    const calendarConfig = productionsCalendarConfig();

    // Table configs
    const dealsConfig = productionsTable(
        filterProductionByDealList,
        userData
    );

    const productionsConfig = productionsDrop(
        setNewProductionPopupVisible,
        setIsModifyActive,
        handleDeleteProduction,
        actionButtonLoading,
        actionButtonDisabled,
    );

    const vacationsConfig = vacationsTable(
        setNewProductionPopupVisible,
        setIsModifyActive,
        handleDeleteProduction,
        actionButtonLoading,
        actionButtonDisabled
    );

    // Popup Configs
    const tablePopupConfig = productionsTablePopupConfig(
        setNewProductionPopupVisible,
        setIsModifyActive,
        handleDeleteProduction,
        actionButtonLoading,
        actionButtonDisabled,
        setCalendarTablePopupVisible
    );

    return (
        <Layout>
            <div className="px-20 pt-10 pb-20">
                <Toast/>
                <Calendar
                    title={'Les temps'}
                    data={calendarData}
                    yearNumber={calendarYear}
                    setYearNumber={setCalendarYear}
                    loading={loadingCalendar}
                    calendarConfig={calendarConfig}
                    onCalendarCellClick={handleCalendarCellClick}
                    setCalendarView={setCalendarView}
                    setCalendarMonth={setCalendarMonth}
                />

                <div className="w-full h-0 py-3"/>
                
                <TimeBalance
                    onButtonClick={handleTimeBalanceButtonClick}
                    onDatePickerChange={handleDatePickerChange}
                    datePickerDefaultValue={datePickerDate}
                    productionsData={productionsData}
                    vacationsData={vacationsData}
                    loadingTable={loadingTable}
                    dealsConfig={dealsConfig}
                    productionsConfig={productionsConfig}
                    vacationsConfig={vacationsConfig}
                />

                <CalendarTablePopup
                    calendarTablePopupVisible={calendarTablePopupVisible}
                    setCalendarTablePopupVisible={setCalendarTablePopupVisible}
                    data={calendarTablePopupData}
                    date={calendarCellSelected.date}
                    type={calendarCellSelected.type}
                    tablePopupConfig={tablePopupConfig}
                    onButtonTablePopupClick={handleButtonTablePopupClick}
                    calendarTableLoading={calendarTableLoading}
                    buttonTitle='Nouveau temps'
                    onPageNumberChange={handleGetCalendarCellData}
                    paginationMetadata={productionPaginationMetadata}
                />

                <CreateNewProductionPopup
                    view={newProductionPopupVisible}
                    setView={setNewProductionPopupVisible}
                    isModifyActive={isModifyActive}
                    userData={userData}
                    refreshData={refreshData}
                />
            </div>
        </Layout>
    );
}

export default ProductionsPage;
