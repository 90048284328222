export const generateUserColor = (username) => {

    if (username) {
        let hash = 0;
        for (let i = 0; i < username.length; i++) {
            hash = username.charCodeAt(i) + ((hash << 5) - hash) + username.charCodeAt();
        }

        let color = ((hash >> 8) & 0xff).toString(16) + ((hash >> 4) & 0xff).toString(16) + ((hash >> 2) & 0xff).toString(16);

        return '#' + color.slice(0, 6).padStart(6, '0');
    }
    else {
        return 'linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%)'
    }

}
