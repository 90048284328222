import {Link} from "react-router-dom";
import {BookFilled, CopyFilled, HourglassFilled} from '@ant-design/icons';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import {activityIndicatorsChartConfig} from '../../configs/commercialTab/activityIndicatorsSection/activityIndicatorsChartConfig';
import {getCurrentMonthAndYear} from '../../../../utils';
import {dasCount, dasDays, dasValue, pieChartData} from '../../../codevDashboardPage/functions/aggregateSalesActivity';
import {transformThousandNumbers} from '../../../../utils';

const ActivityIndicators = ({data, dasKey}) => {

    const specificationsToStudyIcon = <BookFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const pendingProposalsIcon = <CopyFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const awaitingProposalsIcon = <HourglassFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    // ↓ only if dasKey don't exist
    const emptyChartData = [
        {
            type: 'Forte:',
            value: 0
        },
        {
            type: 'Moyenne:',
            value: 0
        },
        {
            type: 'Faible:',
            value: 0
        },
        {
            type: 'Inconnue:',
            value: 0
        }
    ];

    return (
        <div className='my-10'>
            <LineTitle title="Indicateurs d'activité" textSize='text-4xl'/>
            <div className='my-10'>
                <p
                    style={{ fontFamily: 'Din condensed' }}
                    className='mt-10 text-2xl capitalize'
                >
                    {getCurrentMonthAndYear()}
                </p>
                <div className='flex flex-row justify-between mt-5'>
                    <Link className='w-[48%]' to='specification-to-study'>
                        <SimpleCard
                            width='100%'
                            iconComponent={specificationsToStudyIcon}
                            title={data.studying?.[dasKey]?.count ?? 0}
                            subTitle="Cahiers des charges à étudier"
                        />
                    </Link>

                    <div className='mx-5'/>
                    <Link className='w-[48%]' to='proposals-being-written'>
                        <SimpleCard
                            width='100%'
                            iconComponent={pendingProposalsIcon}
                            title={data.responding?.[dasKey]?.count ?? 0}
                            subTitle="Propositions en cours de rédaction"
                        />
                    </Link>
                </div>
            </div>
            <ContainerCard showHeader={false}>
                <div className='flex items-center justify-between'>
                    <Link className='w-[48%]' to='proposals-pending'>
                        <div>
                            <SimpleCard
                                width="w-full"
                                background="white"
                                font="orange"
                                lineColor="black"
                                title={data.waiting?.[dasKey] ? dasCount(data.waiting, dasKey) : 0}
                                shadow={false}
                                borderSize='border'
                                subTitle={'Propositions en attente'}
                                iconComponent={awaitingProposalsIcon}
                                topComponent={
                                    <TopComponent
                                        day={data.waiting?.[dasKey] ? dasDays(data.waiting, dasKey) : 0}
                                        euros={data.waiting?.[dasKey] ? transformThousandNumbers(dasValue(data.waiting, dasKey)) : 0}
                                    />
                                }
                            />
                        </div>
                    </Link>
                    <div className='w-[48%] h-[330px]'>
                        <DonutsChart
                            data={data.waiting?.[dasKey] ? pieChartData(data.waiting, dasKey) : emptyChartData}
                            config={activityIndicatorsChartConfig}
                            label=''
                        />
                    </div>
                </div>
            </ContainerCard>
        </div>
    )
}

export default ActivityIndicators;

const TopComponent = ({day, euros}) => {
    return (
        <div className='flex justify-between bg-white border-t border-gray-200 rounded-b-md'>
            <div className='w-1/2 px-8 pt-5 pb-16'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{day}</p>
                <p className='text-gradient'>Jours</p>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/2 px-8 pt-5 pb-16'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{euros}</p>
                <p className='text-gradient'>Euros</p>
            </div>
        </div>
    )
}
