import {ACTION_CODE} from './_actionCode';
import {LocationService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_PRODUCTION_CATEGORIES';

export async function getAllLocation(
    userData: Record<string, any>,
    params = '',
    page = 1,
) {
    try {
        LoggerService.logInfo('Action - getAllLocation - Start');

        const getAllLocationResult =
            await LocationService.getAllLocations(userData, params, page);

        LoggerService.logInfo('Action - getAllLocation - End - Success');

        return ResultModel.newSuccessResult(getAllLocationResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllLocation - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
