import {ACTION_CODE} from './_actionCode';
import {ReferenceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_REFERENCES';

export async function getAllReferences(
    userData: Record<string, string>,
    params: string,
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllReferences - Start');

        const getAllReferencesResult = await ReferenceService.getAllReferences(userData, params, page);

        const {data, metadata} = getAllReferencesResult;

        LoggerService.logInfo('Action - getAllReferences - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllReferences - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des références',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
