import {ACTION_CODE} from './_actionCode';
import {TrainingSpecialitiesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_TRAINING_SPECIALTY';

export async function createTrainingSpeciality(
    userData: Record<string, string>,
    newTrainingSpecialty: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createTrainingSpecialty - Start');

        const createTrainingSpecialtyResult = await TrainingSpecialitiesService.createTrainingSpeciality(userData, newTrainingSpecialty);

        LoggerService.logInfo('Action - createTrainingSpecialty - End - Success');

        return ResultModel.newSuccessResult(createTrainingSpecialtyResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createTrainingSpecialty - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
