/**
 * The Card component is used to display a customizable card with a title, subtitle, and optional icon.
 * It can also display a line separator, top and bottom components, and supports different color schemes
 * for the background, font, line, and border. The component takes a variety of props to control its appearance
 * and behavior.
 *
 * Props:
 * - width: string (default 'w-2/4') - controls the overall width of the card
 * - iconComponent: JSX element - an optional icon component to be displayed on the right side of the title
 * - title: string - the main title of the card
 * - subTitle: string - an optional subtitle to be displayed below the title
 * - shadow: boolean (default true) - controls whether or not the card has a drop shadow effect
 * - borderColor: string (default 'gray') - controls the color of the card's border
 * - background: string (default 'orange') - controls the overall color scheme of the card's background
 * - font: string (default 'white') - controls the color scheme of the card's text
 * - lineColor: string (default 'white') - controls the color scheme of the separator line
 * - showLine: boolean (default true) - controls whether or not the separator line is displayed
 * - topComponent: JSX element - an optional component to be displayed at the top of the card's content area
 * - bottomComponent: JSX element - an optional component to be displayed at the bottom of the card's content area
 */

const SimpleCard = (
    {
        backgroundHeight = 'h-fit',
        width = 'w-2/4',
        height = 'h-full',
        topTitle = null,
        iconComponent = null,
        title = null,
        titleSize = 'text-4xl',
        sideTitle = null,
        subTitle = null,
        shadow = true,
        borderColor = 'gray',
        borderSize = '1px',
        background = 'orange',
        font = 'white',
        lineColor = 'white',
        showLine = true,
        topComponent = null,
        bottomComponent = null,
        hoverEffect = false,
        customStyle = null

    }
) => {
    let backgroundStyle;
    let fontColorStyle;
    let lineColorStyle;
    let borderColorStyle;

    switch (background) {
        case 'orange':
            backgroundStyle = 'background-gradient';
            break;
        case 'blue':
            backgroundStyle = 'background-gradient-blue';
            break;
        case 'white':
            backgroundStyle = 'bg-white';
            break;
        default:
            break;
    }

    switch (font) {
        case 'orange':
            fontColorStyle = 'text-gradient';
            break;
        case 'blue':
            fontColorStyle = 'text-gradient-blue';
            break;
        case 'white':
            fontColorStyle = 'text-white';
            break;
        default:
            break;
    }

    switch (lineColor) {
        case 'orange':
            lineColorStyle = 'background-gradient';
            break;
        case 'blue':
            lineColorStyle = 'background-gradient-blue';
            break;
        case 'white':
            lineColorStyle = 'bg-white';
            break;
        case 'gray':
            lineColorStyle = 'bg-gray-300';
            break;
        case 'black':
            lineColorStyle = 'bg-[#212121]';
            break;
        default:
            break;
    }

    switch (borderColor) {
        case 'orange':
            borderColorStyle = 'background-gradient';
            break;
        case 'blue':
            borderColorStyle = 'background-gradient-blue';
            break;
        case 'gray':
            borderColorStyle = 'bg-gray-200';
            break;
        default:
            break;
    }

    return (
        <div
            className={`
                ${customStyle} ${width} ${borderColorStyle} ${shadow && "card-shadow"} ${backgroundHeight}
                ${borderSize ? borderSize : 'p-0'} ${hoverEffect && 'hover:opacity-90'} transition-all rounded-lg
            `}
        >
            <div className={`${backgroundStyle} ${height} rounded-md`}>

                {topTitle && (
                    <div className="block pt-4 mx-auto w-fit">
                        <p style={{ fontFamily: 'Din Condensed' }} className="text-xl">{topTitle}</p>
                    </div>
                )}

                <div className='px-6 py-4'>
                    <div className="flex flex-row items-center justify-between">
                        <p>
                            <span className={`${fontColorStyle} ${titleSize}`} style={{ fontFamily: 'Din Condensed' }}>{`${title}`}</span>
                            <span className={`${fontColorStyle} text-sm font-normal`}>{sideTitle}</span>
                        </p>
                        {iconComponent && iconComponent}
                    </div>
                    {subTitle && <p className={`mt-2 ${fontColorStyle}`}>{subTitle}</p>}

                    {showLine && <div className={`h-[1px] ${lineColorStyle} my-4`}/>}
                </div>

                {/* Top component */}
                {topComponent && topComponent}

                {/* Bottom component */}
                {bottomComponent && bottomComponent}
            </div>
        </div>
    );
};

export default SimpleCard;
