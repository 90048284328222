import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GEY_PRODUCTION_LOAD';

export async function getProductionLoad(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getProductionLoad - Start');

        const getProductionLoad =
            await DashboardService.CodevService.getProductionLoad(userData, params);

        LoggerService.logInfo('Action - getProductionLoad - End - Success');

        return ResultModel.newSuccessResult(getProductionLoad);
    }
    catch (error) {
        LoggerService.logInfo('Action - getProductionLoad - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
