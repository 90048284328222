import {AttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DOWNLOAD_ATTACHMENT';

export async function downloadAttachment(userData: Record<string, string>, id: string) {
    try {
        const downloadAttachmentResult = await AttachmentDao.downloadAttachment(userData.userToken, id);

        return downloadAttachmentResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des attachments de frais',
            error: error
        });
    }
}
