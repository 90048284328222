import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import SelectInput from '../../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../../components/ui/Inputs/TextInput';
import DropdownInput from '../../../../../components/ui/Inputs/DropdownInput';
import TextAreaInput from '../../../../../components/ui/Inputs/TextAreaInput';
import DateInput from '../../../../../components/ui/Inputs/DateInput/DateInput';
import Button from '../../../../../components/ui/Button/Button';
import {notify} from '../../../../../components/ui/Toast/Toast';
import {ProductionCategoryActions, DealActions, ProductionActions} from '../../../../../actions';
import {frenchFormat, fetchSelectData} from '../../../../../utils';
import Error from '../../../../../components/ui/Error/Error';
import {getErrors, standardFormat} from '../../../../../utils';
import ContainerCard from '../../../../../components/ui/ContainerCard/ContainerCard';

function CreateNewProduction({
    userData,
    switchForm,
    setSwitchForm,
    setView,
    productionToBeModified,
    refreshData
}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [categories, setCategories] = useState(null);
    const [deals, setDeals] = useState(null);

    const newProduction = [{name: 'Production'}, {name: 'Congés'}];

    // Check date value for new expense from calendar popup
    const defaultDate = (productionToBeModified?.date && !productionToBeModified?.id) ? dayjs(productionToBeModified.date) : dayjs();

    const defaultValues = {
        date: defaultDate,
        deal: null,
        category: '',
        location: '',
        produced_days: null,
        billed_days: null,
        mission: null,
        notes: ''
    };

    // -- functions that get data for Select Inputs --
    const handleGetCategories = async (search, page = 1) => {
        await fetchSelectData({action: ProductionCategoryActions.getAllProductionCategories, search:`&search=${search}`, page: page, setter: setCategories, userData});
    };
    const handleGetDeals = async (search, page) => {
        await fetchSelectData({action: DealActions.getAllDeals, search:`&search=${search}`, page: page, setter: setDeals, userData, resultPath: 'data'});
    };

    useEffect(() => {
        (async () => {
            await handleGetCategories('', 1);
        })();
        (async () => {
            await handleGetDeals('&produceable=MDS', 1);
        })();
    }, []);

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (productionToBeModified?.id) {
            // check if deal is a number or an object
            const dealObject = typeof productionToBeModified?.deal === 'number' ?
                deals?.options.find(deal => deal.id === productionToBeModified?.deal) :
                productionToBeModified?.deal;

            reset({
                date: productionToBeModified?.date,
                deal: dealObject,
                category: {name: productionToBeModified?.category},
                location: productionToBeModified?.location,
                produced_days: (+productionToBeModified?.produced_days).toString(),
                billed_days: (+productionToBeModified?.billed_days).toString(),
                notes: productionToBeModified?.notes
            });
        }
    }, [productionToBeModified, deals]);

    const onSubmit = async (data) => {
        setErrors(null);
        setIsDisabled(true);
        setIsLoading(true);

        const production = {
            date: standardFormat(data.date),
            deal_id: data.deal.id,
            category: data.category.name,
            location: data.location,
            produced_days: data.produced_days,
            billed_days: data.billed_days,
            notes: data.notes,
            ressource_id: userData.userId
        };

        if (!productionToBeModified || productionToBeModified.date && Object.keys(productionToBeModified).length === 1) {
            const result = await ProductionActions.createProduction(userData, production);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre temps à bien été créé');
                setView(false);
            }
        }
        else {
            const result = await ProductionActions.updateProduction(userData, productionToBeModified?.id, production);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre temps à bien été modifié');
                setView(false);
            }
        }

        setIsLoading(false);
        setIsDisabled(false);
    };
    
    return (
        <ContainerCard
            title={productionToBeModified?.id ? 'Éditer un temps' : 'Nouveau temps'}
            coloredButtonDisplayed={false}
            overflow=''
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-4">
                <div className='flex flex-col'>
                    <div className="mb-2">
                        <Controller
                            name="type"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DropdownInput
                                    label={'Type de temps'}
                                    onChange={onChange}
                                    setSwitchForm={setSwitchForm}
                                    value={switchForm ? switchForm : value}
                                    error={error}
                                    required={true}
                                    items={newProduction}
                                    disabled={(productionToBeModified && productionToBeModified.id) ? true : isDisabled}
                                    listHeight="70px"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="deal"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une affaire.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Affaire'
                                    placeholder='Affaire'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={deals?.options || []}
                                    hasNextPage={deals?.hasNextPage || false}
                                    fetchFunction={handleGetDeals}
                                    loading={!deals}
                                    onChange={onChange}
                                    labelKeys={['short_name']}
                                    isSearchable={true}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="category"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un type de temps.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type de temps'
                                    placeholder='Type de temps'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={categories?.options.sort((a, b) => a.id - b.id) || []}
                                    hasNextPage={categories?.hasNextPage || false}
                                    onChange={onChange}
                                    loading={!categories}
                                    isSearchable={true}
                                    fetchFunction={handleGetCategories}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-between">
                    <div className="mb-2">
                        <Controller
                            name="location"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer une localisation.'
                                }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label="Emplacement"
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    placeholder="Localisation"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="produced_days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer un nombre de jours produits.'
                                }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Jours produits (j)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    min="0"
                                    placeholder="Jours produits (j)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billed_days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer un nombre de jours facturés.'
                                }
                            }}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Jours facturés (j)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    min="0"
                                    placeholder="Jours facturés (j)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            isDisabled={isDisabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={isDisabled}
                    />

                    <Button
                        type={'primary'}
                        content={productionToBeModified?.id ? 'Modifier le temps' : 'Ajouter le temps'}
                        onClick={handleSubmit(onSubmit)}
                        loading={isLoading}
                        disabled={!categories || !deals || !isValid}
                    />

                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewProduction;
