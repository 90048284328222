import {DealsDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'DELETE_DEAL';

export async function deleteDeal(userData: Record<string, string>, dealId: string) {
    try {
        const result = await DealsDao.deleteDeal(userData.userToken, dealId);

        return result;
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la suppression de l\'affaire',
            error: error
        });
    }
}
