import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Datepicker from "../../../../components/ui/Datepicker";
import Table from "../../../../components/ui/Table/Table";
import GrossMarginProducedChart from "./GrossMarginProducedChart";
import {grossMarginProducedTableData} from "../../configs/resultIndicatorTab/grossMarginProducedSection/grossMarginProducedTableData";
import {grossMarginProducedTableConfig} from "../../configs/resultIndicatorTab/grossMarginProducedSection/grossMarginProducedTableConfig";

const GrossMarginProduced = ({
    data,
    datePicker,
    handleDatePickerChange,
    dasKey
}) => {

    return (
        <div className='mt-10'>
            <LineTitle title="Marge brute produite" textSize='text-4xl'/>
            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePicker}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>
            <div className="mt-6">
                <Table
                    data={grossMarginProducedTableData(data.global, dasKey)}
                    tableConfig={grossMarginProducedTableConfig()}
                />
            </div>
            <div className="mt-5">
                <GrossMarginProducedChart data={data.by_status} dasKey={dasKey}/>
            </div>
        </div>
    )
}

export default GrossMarginProduced;
