import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {createBrowserHistory} from '@remix-run/router';

import Layout from '../../components/layout';
import Calendar from '../../components/ui/Calendar';
import ExpenseBalance from './components/ExpenseBalance';
import CalendarTablePopup from '../../components/ui/CalendarTablePopup';
import {standardFormat, firstDayMonth, lastDayMonth, lastDayYear, firstDayYear} from '../../utils';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import {ExpenseReportActions} from '../../actions';
import {ExpenseActions} from '../../actions';
import {expensesCalendarConfig} from './configs/expensesCalendarConfig';
import {expensesReportsConfig} from './configs/expenseReportsConfig';
import {expensesConfig} from './configs/expensesConfig';
import CreateNewExpensePopup from '../expensesPage/components/CreateExpensePopup/CreateNewExpensePopup';

const Expenses = () => {
    const userData = useSelector((state) => state.UserReducer.userProfileData);
    
    const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth());
    const [calendarView, setCalendarView] = useState('month');
    const [expenseToBeUpdated, setExpenseToBeUpdated] = useState(null);
    const [actionButtonLoading, setActionButtonLoading] = useState(false);

    // Hooks
    const location = useLocation();
    const history = createBrowserHistory();

    /* -- States -- */
    // Calendar states
    const [calendarData, setCalendarData] = useState({});
    const [calendarYear, setCalendarYear] = useState(new Date().getFullYear());
    const [calendarTableLoading, setCalendarTableLoading] = useState(false);
    const [loadingCalendar, setLoadingCalendar] = useState(false);

    // Table states
    const [expenseReportpaginationMetadata, setExpenseReportPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 15
    });
    const [expensesPopupPaginationMetadata, setExpensesPopupPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 5
    });
    const [expensesTableFilterQuery, setExpensesTableFilterQuery] = useState('');
    const [expensesDataTable, setExpensesDataTable] = useState([]);
    const [datePickerDate, setDatePickerDate] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [loadingTable, setLoadingTable] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    // Popups states
    const [calendarTablePopupVisible, setCalendarTablePopupVisible] = useState(false);
    const [calendarTablePopupData, setCalendarTablePopupData] = useState([]);
    const [calendarCellSelected, setCalendarCellSelected] = useState({
        type: 'day',
        date: null
    });
    const [isExpensePopupOpen, setIsExpensePopupOpen] = useState(false);

    // ---
    const handleDatePickerChange = (e) => {
        setDatePickerDate({
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        });
    };

    /* -- Functions that get data -- */
    const handleGetCalendarData = async () => {
        setLoadingCalendar(true);
        setCalendarData({});

        if (calendarView === 'month') {
            const calendarBefore = standardFormat(lastDayMonth(new Date(calendarYear, calendarMonth)));
            const calendarAfter = standardFormat(firstDayMonth(new Date(calendarYear, calendarMonth)));
            
            const result = await ExpenseActions.getAggregatedCalendarExpensesByDay(
                userData,
                calendarBefore,
                calendarAfter,
                `&ressource=${userData.userProfileName}`
            );

            if (result.success) {
                //console.log(result.data);
                setCalendarData(result.data);
            }
            else {
                notify('Erreur de récuperation des données du calendrier');
            }
        }
        else if (calendarView === 'year') {
            const calendarBefore = standardFormat(lastDayYear(new Date(calendarYear.toString())));
            const calendarAfter = standardFormat(firstDayYear(new Date(calendarYear.toString())));
            
            const result = await ExpenseActions.getAggregatedCalendarExpensesByMonth(
                userData,
                calendarBefore,
                calendarAfter,
                `&ressource=${userData.userProfileName}`
            );

            if (result.success) {
                setCalendarData(result.data);
            }
            else {
                notify('Erreur de récuperation des données du calendrier');
            }
        }

        setLoadingCalendar(false);
    }

    const handleGetTableData = async (page, activeLoading = true) => {
        if(activeLoading) {
            setLoadingTable(true);
            setExpensesDataTable([]);
        }
        
        const datePickerBefore = standardFormat(new Date(datePickerDate.endDate));
        const datePickerAfter = standardFormat(new Date(datePickerDate.startDate));
       
        const result = await ExpenseReportActions.getAllExpenseReports(
            userData,
            `&page_size=${expenseReportpaginationMetadata.pageSize}&before=${datePickerBefore}&after=${datePickerAfter}&ressource=${userData.userProfileName}&${expensesTableFilterQuery}`,
            page
        );

        if (result.success) {
            setExpensesDataTable(result.data);
            setExpenseReportPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (expenseReportpaginationMetadata.totalItems !== result.metadata.count) {
                setExpenseReportPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des données du tableau');
        }
        
        setLoadingTable(false);
    }

    const handleGetCalendarCellData = async (page, typeSended = null, dateSended = null) => {
        setCalendarTableLoading(true);
    
        let after;
        let before;
    
        if(!typeSended || !dateSended) {
            const {type, date} = calendarCellSelected;
                
            after = type === 'day' ? date : standardFormat(firstDayMonth(new Date(date)));
            before = type === 'day' ? date : standardFormat(lastDayMonth(new Date(date)));
        }
        else {
            after = typeSended === 'day' ? dateSended : standardFormat(firstDayMonth(new Date(dateSended)));
            before = typeSended === 'day' ? dateSended : standardFormat(lastDayMonth(new Date(dateSended)));
        }
            
        const result = await ExpenseActions.getAllExpenses(
            userData,
            before,
            after,
            {},
            `&page=${page}&page_size=${expensesPopupPaginationMetadata.pageSize}&ressource=${userData.userProfileName}`
        );
    
        if (result.success) {
            //sort result.data by date
            const sortedDataByDate = result.data.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });
            setCalendarTablePopupData(sortedDataByDate);
            setExpensesPopupPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));
    
            if (expensesPopupPaginationMetadata.totalItems !== result.metadata.count) {
                setExpensesPopupPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des données du calendrier');
        }
    
        setCalendarTableLoading(false);
    };

    /* -- UseEffects -- */

    // Calendar data
    useEffect(() => {
        handleGetCalendarData();
    }, [calendarYear, calendarMonth, calendarView]);

    // Table data
    useEffect(() => {
        handleGetTableData(1);
    }, [datePickerDate]);

    // Calendar table popup data
    useEffect(() => {
        calendarTablePopupVisible &&
        calendarCellDataFormat(calendarCellSelected.type, calendarCellSelected.date);
    }, [calendarData]);

    // Filter data
    useEffect(() => {
        handleGetTableData(1);
    }, [expensesTableFilterQuery]);

    // Check navigation message existence
    useEffect(() => {
        if (location.state === 'invalid_id') {
            notify('Note de frais inexistante');
        }
        else if (location.state === 'expense_report_deleted') {
            notify('Note de frais supprimée');
        }
        history.replace({...history.location, state: null});
    }, []);

    /* -- Cell functions -- */
    const calendarCellDataFormat = async (type, date) => {
        setCalendarTablePopupVisible(true);
        
        await handleGetCalendarCellData(1, type, date);
    };

    const handleCalendarCellClick = ({type, date}) => {
        setCalendarCellSelected({ type, date });
        calendarCellDataFormat(type, date);
    };

    // Refresh functions
    const handleRefreshCellData = async (removal = false) => {
        const checkedPage = (calendarTablePopupData.length === 1 && expensesPopupPaginationMetadata.currentPage > 1 && removal) ?
            expensesPopupPaginationMetadata.currentPage - 1 :
            expensesPopupPaginationMetadata.currentPage;

        await handleGetCalendarCellData(checkedPage);
    };

    const refreshData = async (removal = false) => {
        // Calendar
        handleGetCalendarData();

        // Cell Table
        if (calendarTablePopupVisible) {
            await handleRefreshCellData();
        }
        // Table
        const checkedPage = (expensesDataTable.length === 1 && expenseReportpaginationMetadata.currentPage > 1 && removal) ?
            expenseReportpaginationMetadata.currentPage - 1 :
            expenseReportpaginationMetadata.currentPage;

        handleGetTableData(checkedPage, false);
    };

    // Delete functions
    const handleDeleteExpenseReport = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);
        const result = await ExpenseReportActions.deleteExpenseReportById(userData, item.id);

        if (result.success) {
            await refreshData(true);
            notify('Votre note de frais à bien été supprimé');
        }
        else {
            notify('Votre note de frais n\'a pas pu être supprimé');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    const handleDeleteExpense = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);

        const result = await ExpenseActions.deleteExpense(userData, item);

        if (result.success) {
            if (calendarTablePopupVisible) {
                await handleRefreshCellData(true);
            }
            else {
                await refreshData(true);
            }
            notify('Votre frais à bien été supprimé');
        }
        else {
            notify('Votre frais n\'a pas pu être supprimé');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    // Popup functions
    const handleExpenseBalanceButtonClick = () => {
        setIsExpensePopupOpen(true);
        setExpenseToBeUpdated(null);
    };

    const handleExpenseTableButtonClick = (date) => {
        setCalendarTablePopupVisible(false);
        setIsExpensePopupOpen(true);
        setExpenseToBeUpdated({date: date});
    };

    const handleSetStatusToVeridied = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id);

        const payload = { status: 1 };

        const result = await ExpenseReportActions.updateExpenseReportById(userData, item.id, payload);

        if (result.success) {
            await refreshData();
            notify('Votre note de frais à bien été mis à validé');
        }
        else {
            notify('Votre note de frais n\'a pas pu être mis à validé');
        }
        setActionButtonLoading(false);
        setActionButtonDisabled(false);
    };

    // Filter functions
    const filterExpenseReportList = async (query) => {
        setExpensesTableFilterQuery(query);
    };

    /* -- Configs -- */
    // Calendar configs
    const calendarConfig = expensesCalendarConfig();

    // Table and Popup configs
    const expensesReportsReturn = expensesReportsConfig(
        handleDeleteExpenseReport,
        actionButtonLoading,
        actionButtonDisabled,
        handleSetStatusToVeridied,
        filterExpenseReportList,
        userData
    );

    const expensesConfigReturn = expensesConfig(
        handleDeleteExpense,
        setIsExpensePopupOpen,
        setExpenseToBeUpdated,
        actionButtonLoading,
        actionButtonDisabled
    );

    return (
        <Layout>
            <div className="px-20 pt-10 pb-20">
                <Toast/>
                <Calendar
                    title={'Les frais'}
                    data={calendarData}
                    yearNumber={calendarYear}
                    setYearNumber={setCalendarYear}
                    loading={loadingCalendar}
                    calendarConfig={calendarConfig}
                    onCalendarCellClick={handleCalendarCellClick}
                    setCalendarView={setCalendarView}
                    setCalendarMonth={setCalendarMonth}
                />

                <div className="w-full h-0 py-3"/>

                <ExpenseBalance
                    onButtonClick={handleExpenseBalanceButtonClick}
                    datePickerValue={datePickerDate}
                    onDatePickerChange={handleDatePickerChange}
                    tableData={expensesDataTable}
                    tableLoader={loadingTable}
                    expensesReportsConfig={expensesReportsReturn}
                    expensesConfig={expensesConfigReturn}
                    expenseReportpaginationMetadata={expenseReportpaginationMetadata}
                    handleGetTableData={handleGetTableData}
                />

                <CalendarTablePopup
                    calendarTablePopupVisible={calendarTablePopupVisible}
                    setCalendarTablePopupVisible={setCalendarTablePopupVisible}
                    data={calendarTablePopupData}
                    date={calendarCellSelected.date}
                    type={calendarCellSelected.type}
                    tablePopupConfig={expensesConfigReturn}
                    onButtonTablePopupClick={handleExpenseTableButtonClick}
                    buttonTitle='Nouveau frais'
                    calendarTableLoading={calendarTableLoading}
                    paginationMetadata={expensesPopupPaginationMetadata}
                    onPageNumberChange={handleGetCalendarCellData}
                />
                
                <CreateNewExpensePopup
                    view={isExpensePopupOpen}
                    setView={setIsExpensePopupOpen}
                    expenseToBeUpdated={expenseToBeUpdated}
                    userData={userData}
                    refreshData={refreshData}
                />
            </div>
        </Layout>
    );
};

export default Expenses;
