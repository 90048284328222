import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GEY_SALES_OVERVIEW';

export async function getSalesOverview(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getSalesOverview - Start');

        const getSalesOverview =
            await DashboardService.CodevService.getSalesOverview(userData, params);

        LoggerService.logInfo('Action - getSalesOverview - End - Success');

        return ResultModel.newSuccessResult(getSalesOverview);
    }
    catch (error) {
        LoggerService.logInfo('Action - getSalesOverview - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
