import LoadingRateAndTjm from './LoadingRateAndTjm'
import GrossMarginProduced from './GrossMarginProduced'
import ForecastGrossMargin from './ForecastGrossMargin'

const Production = ({
    grossMarginProducedData,
    forecastGrossMarginData,
    dailyRateData,
    grossMarginProducedDatePicker,
    handleGrossMarginProducedDatePickerDateChange,
    forecastGrossMarginDatePicker,
    handleForecastGrossMarginDatePickerDateChange
}) => {
    return (
        <div>
            <GrossMarginProduced
                data={grossMarginProducedData}
                datePickerDate={grossMarginProducedDatePicker}
                handleDatePickerChange={handleGrossMarginProducedDatePickerDateChange}
            />
            <ForecastGrossMargin
                data={forecastGrossMarginData}
                datePickerDate={forecastGrossMarginDatePicker}
                handleDatePickerChange={handleForecastGrossMarginDatePickerDateChange}
            />
            <LoadingRateAndTjm data={dailyRateData}/>
        </div>
    )
}

export default Production;
