import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GET_RESSOURCES';

export async function getRessources(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getRessources - Start');

        const getRessources =
            await DashboardService.CodevService.getRessources(userData, params);

        LoggerService.logInfo('Action - getRessources - End - Success');

        return ResultModel.newSuccessResult(getRessources);
    }
    catch (error) {
        LoggerService.logInfo('Action - getRessources - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
