import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Table from "../../../../components/ui/Table/Table";
import TabCard from "../../../../components/ui/TabCard/TabCard";
import {aggregateContributionToMarginObject} from '../../functions/aggregateContributionToMarginObject';
import {marginContributionTableConfig} from '../../configs/resultIndicatorTab/marginContributionSection/marginContributionTableConfig';
import {marginContributionTabConfig} from '../../configs/resultIndicatorTab/marginContributionSection/marginContributionTabConfig';

const ContributionToMargin = ({results}) => {

    return (
        <div className='mt-14'>
            <LineTitle title="Contribution à la marge" textSize='text-4xl'/>

            <div className="mt-6">
                <Table data={aggregateContributionToMarginObject(results.global)} tableConfig={marginContributionTableConfig()}/>
            </div>

            <div className="mt-5">
                <TabCard config={marginContributionTabConfig(results.by_status)}/>
            </div>
        </div>
    )
}

export default ContributionToMargin;
