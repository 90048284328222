import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'DELETE_EXPENSE';

export async function deleteExpense(
    userToken: string,
    expenseId: string
) {
    try {
        const response = await axios.delete(
            `${URL}/expenses/${expenseId}/`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error: any) {
        const errorMessage = () => {
            if (error.response.data.detail) {
                return error.response.data.detail;
            }
            else {
                return 'Une erreur est survenue lors de la suppression d\'un frais';
            }
        };
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: errorMessage(),
            error: error
        });
    }
}
