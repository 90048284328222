import * as z from 'zod';
import * as customerCategoryFunctions from './functions';

export const CustomerCategorySchema = z.object({
    'id': z.number(),
    'is_active': z.boolean(),
    'name': z.string()
});

export const CustomerCategoryPartialSchema = CustomerCategorySchema.partial();
export type CustomerCategoryPartial = z.infer<typeof CustomerCategoryPartialSchema>;
export type CustomerCategory = z.infer<typeof CustomerCategorySchema>;

export function newCustomerCategory(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...CustomerCategorySchema.parse({...data}),
        ...customerCategoryFunctions
    };
}

export function parseCustomerCategory(data: any, partial = false) {
    if (!partial) {
        return {
            ...CustomerCategorySchema.parse(data),
            ...customerCategoryFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...CustomerCategoryPartialSchema.parse(data),
            ...customerCategoryFunctions
        };
    }
}
