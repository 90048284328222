import {ACTION_CODE} from './_actionCode';
import {DealDasService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_DEALDAS';

export async function createDealDas(userData: Record<string, any>, dealDas: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createDealDas - Start');

        const createDealDasResult = await DealDasService.createDas(userData, dealDas);

        LoggerService.logInfo('Action - createDealDas - End - Success');

        return ResultModel.newSuccessResult(createDealDasResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createDealDas - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une banque.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
