import * as z from 'zod';
import * as dealFunctions from './functions';
import { UserSchema } from '../user/user';
export const DealSchema = z.object({
    id: z.number(),
    sales: z.object({
        id: z.number(),
        username: z.string(),
        first_name: z.string(),
        last_name: z.string()
    }).array(),
    contractors: z.object({
        contractor_id: z.number().optional(),
        budget: z.string().optional(),
        billable_days: z.string().optional(),
        direct: z.boolean().optional()
    }).optional(),
    production_lead_id: z.number().optional(),
    origin: z.string().optional(),
    das: z.string().nullable(),
    loss_motive: z.string().optional(),
    // subsidiary: z.union([z.string(), z.number()]).nullable().default(null),
    subsidiary: z.object({
        id: z.number(),
        name: z.string(),
        is_active: z.boolean(),
        is_training: z.boolean()
    }).nullable().default(null),
    contractor_budget: z.string(),
    billed_days: z.string().nullable().optional(),
    producers: z.array(UserSchema).nullable().optional(),
    ref_source: z.object({}).optional(),
    ref_source_id: z.number().optional(),
    ref_image: z.object({}).optional(),
    ref_image_id: z.number().optional(),
    tags: z.string().array().optional(),
    type: z.enum(['activity', 'project', 'service', 'business', 'purchase_order', 'framework']).optional(),
    work: z.boolean().optional(),
    name: z.string(),
    short_name: z.string(),
    status: z.union([z.number(), z.string()]) .optional(),
    chance: z.enum(['0', '1', '2', '3']).optional(),
    est_remaining_production_days: z.string().optional(),
    production_risk: z.enum(['0', '1', '2', '3']).nullable().default(null),
    reception_date: z.string().nullable().default(null),
    max_response_date: z.string().nullable().default(null),
    response_date: z.string().nullable().default(null),
    mission_start_date: z.string().nullable().default(null),
    mission_end_date: z.string().nullable().default(null),
    check_1_filing: z.enum(['0', '1', '2']).optional(),
    check_2_contract: z.enum(['0', '1', '2']).optional(),
    check_3_amendment: z.enum(['0', '1', '2']).optional(),
    check_4_subcontracting_contract: z.enum(['0', '1', '2']).optional(),
    check_5_factoring_contract: z.enum(['0', '1', '2']).optional(),
    budget: z.string().optional(),
    billable_days: z.string(),
    commission: z.string().optional(),
    field_expense: z.boolean().optional(),
    expense_budget: z.string().optional(),
    procurement_cost: z.string().optional(),
    billing_adress: z.string().optional(),
    notes: z.string().optional(),
    customer: z.object({
        category: z.string(),
        id: z.number(),
        location: z.string().optional(),
        name: z.string(),
        notes: z.string().optional(),
        number_deals: z.number()
    }).optional(),
    production_lead: z.object({
        first_name: z.string(),
        id: z.number(),
        last_name: z.string(),
        username: z.string()
    }).optional().nullable(),
    analysis_lead: z.object({
        first_name: z.string(),
        id: z.number(),
        last_name: z.string(),
        username: z.string()
    }).optional().nullable(),
    satisfaction_survey_status: z.enum(['to_check', 'ignore', 'to_send', 'send']).optional(),
    satisfaction_survey_email: z.string().nullable().default(null).optional()
});

export const DealPartialSchema = DealSchema.partial();
export type DealPartial = z.infer<typeof DealPartialSchema>;
export type Deal = z.infer<typeof DealSchema>;

export function newDeal(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...DealSchema.parse({...data}),
        ...dealFunctions
    };
}

export function parseDeal(data: any, partial = false) {
    if (!partial) {
        return {
            ...DealSchema.parse(data),
            ...dealFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...DealPartialSchema.parse(data),
            ...dealFunctions
        };
    }
}
