import {OfficeDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_MISSIONS';

export async function getAllOffices(userData: Record<string, string>, params: string) {
    try {
        return await OfficeDao.getAllOffices(userData, params);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération des bureaux',
            error: error
        });
    }
}
