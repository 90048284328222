import {frenchFormat} from '../../../utils';
import Badge from '../../../components/ui/Badge';
import ActionsButton from '../../../components/ui/ActionsButton';

export const referenceTableConfig = (handleRedirect, key) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Affaire',
                    value: 'deal.short_name',
                    width: 'w-[20%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.deal.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Referent',
                    value: 'owner.username',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <Badge content={item.owner.username} type='rounded'/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Reference',
                    value: 'name',
                    width: 'w-[25%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center">
                                {item.name ? <p>{item.name}</p> : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        let title = '';

                        switch (item.status) {
                            case 'published': title = 'Publiée';
                                break;
                            case 'draft': title = 'Brouillon';
                                break;
                            case 'archived': title = 'Archivée';
                                break;
                            default: title = 'brouillon';
                        }

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={title}/>
                            </div>
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Réalisation',
                    value: 'realisation_date',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.realisation_date ? frenchFormat(item.realisation_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Département',
                    value: 'department',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <p>{item?.deal?.customer?.location}</p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.budget ? item.budget + ' €' : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Tags',
                    value: null,
                    width: 'w-[15%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row flex-wrap items-center justify-center gap-x-2">
                                {item.tags.length ? item.tags.map((tag, index) => {
                                    return (
                                        <Badge
                                            key={index}
                                            content={tag}
                                            color={tag.color}
                                        />
                                    )
                                }) : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Action',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={() => handleRedirect({type: 'reference', key}, item)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
