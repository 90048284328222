import {ACTION_CODE} from './_actionCode';
import {SeniorityService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_SENIORITY';

export async function deleteSeniority(userData: Record<string, any>, seniorityId: number) {
    try {
        LoggerService.logInfo('Action - deleteSeniority - Start');

        const deleteSeniorityResult = await SeniorityService.deleteSeniority(userData, seniorityId);

        LoggerService.logInfo('Action - deleteSeniority - End - Success');

        return ResultModel.newSuccessResult(deleteSeniorityResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteSeniority - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
