import { useState, useEffect } from 'react';
import {Link} from "react-router-dom";

import ActionsButton from "../../../components/ui/ActionsButton/ActionsButton";
import {getRafOverrun, handleFilterList, useFilterQuery, frenchFormat} from "../../../utils";
import { DealActions } from '../../../actions';
import { SelectInput, TableDatePicker } from '../../../components/ui/Table';

export const currentMissionsTableConfig = (
    tableEdit,
    filterMissionList,
    userData
) => {
    const [deals, setDeals] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        deal: '',
        mission_end_date_before: '',
        mission_start_date_after: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterMissionList);

    const handleGetDeals = async (search, page) => {
        const result = await DealActions.getAllDeals(userData, `&type=business&display=${userData.userProfileName}`, page);
        const hasNextPage = result.data.next ? true : false;

        if (page === 1 || !page || search) {
            setDeals({ options: result.data, hasNextPage });
        }
        else {
            setDeals(prevData => ({ options: [...prevData.options, ...result.data.results], hasNextPage }));
        }
    };
    
    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetDeals('', 1);
        })
    }, []);
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Affaire',
                    value: 'deal.short_name',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.deal.short_name}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="deal"
                        options={deals.options}
                        hasNextPage={deals?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetDeals)}
                        labelKeys={['short_name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'TJM',
                    value: 'daily_rate',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {parseInt(item.daily_rate).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours',
                    value: 'billable_days',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.billable_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jrs. produits facturables',
                    value: 'billed_days',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.billed_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jrs. produits non fact...',
                    tooltipLabel: 'Jours produits non facturables',
                    value: 'produced_days',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.produced_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(item.budget).toLocaleString()}
                            </div>
                        )
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'RAF',
                    value: 'raf',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.raf}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Proj.M',
                    value: 'projection_m1',
                    key: 'projection_m1',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m1');

                        return (
                            <>
                                {
                                    tableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_m1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Proj.M+1',
                    value: 'projection_m2',
                    key: 'projection_m2',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m2');

                        return (
                            <>
                                {
                                    tableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_m2}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Proj.M+2',
                    value: 'projection_m3',
                    key: 'projection_m3',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m3');

                        return (
                            <>
                                {
                                    tableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_m3}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Trimestre',
                    value: 'projection_q1',
                    key: 'projection_q1',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_q1');

                        return (
                            <>
                                {
                                    tableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_q1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Semestre',
                    value: 'projection_s1',
                    key: 'projection_s1',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_s1');

                        return (
                            <>
                                {
                                    tableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_s1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Date de fin',
                    value: null,
                    width: 'w-[6%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.deal.mission_end_date ? frenchFormat(item.deal.mission_end_date) : '-'}
                            </div>
                        )
                    },
                    filterComponent: () => (
                        <TableDatePicker name='mission_end_date' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[4%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Link to={`/deals/${item.deal?.id}/missions/${item.id}`} id='skip' className='ml-1'>
                                    <ActionsButton type="details"/>
                                </Link>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
