import Popup from '../../../components/ui/Popup';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const ExpenseAttachmentPicturePopup = ({
    view,
    setView,
    url
}) => {
  
    const docs = [{uri: url}];

    return (
        <Popup view={view} setView={setView} maxheight='h-[400px]'>
            <DocViewer
                style={{height: '60vh', width: '850px'}}
                documents={docs}
                initialActiveDocument={docs[0]}
                pluginRenderers={DocViewerRenderers}
                config={{ header: {
                    disableHeader: true
                },
                pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2
                }
                }}
            />
        </Popup>
    );
}

export default ExpenseAttachmentPicturePopup;