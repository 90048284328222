import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GET_SATISFACTION_SURVEYS';

export async function getSatisfactionSurveys(userData: Record<string, any>, params: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - getSatisfactionSurveys - Start');

        const getSatisfactionSurveys = await DashboardService.OfficeManagerService.getSatisfactionSurveys(userData, params);

        const {data, metadata} = getSatisfactionSurveys;

        LoggerService.logInfo('Action - getSatisfactionSurveys - End - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getSatisfactionSurveys - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
