import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'EXPORTS_TRAINING';

export async function exportTraining(userData: Record<string, string>, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - exportTraining - Start');

        const exportTrainingResult = await DashboardService.AdminService.exportTraining(userData, payload);

        LoggerService.logInfo('Action - exportTraining - End - Success');

        return ResultModel.newSuccessResult(exportTrainingResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - exportTraining - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
