import {LocationDao} from '../../dao';
import {ErrorModel, LocationModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_LOCATIONS';

export async function getAllLocations(
    userData: Record<string, string>,
    params: string,
    page: number,
) {
    try {
        const locations = await LocationDao.getAllLocations(userData.userToken, params, page);

        const locationParsed = locations.results.map((location: Record<string, any>) => LocationModel.parseLocation(location));

        return locationParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des localisations',
            error: error
        });
    }
}
