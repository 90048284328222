import React, {useEffect} from 'react';
import InfoModule from "./PopupModules/InfoModule";
import RessourcesModule from "./PopupModules/RessourcesModule";
import Error from '../../../../components/ui/Error/Error';

// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const InternalPopup = ({
    control,
    disabled,
    handleGetCustomers,
    handleGetDealDas,
    handleGetRessources,
    productionRessourcesValues,
    setProductionRessourcesValues,
    dealDas,
    ressources,
    errors
}) => {
    // const navigate = useNavigate();

    // Get dropdows data
    useEffect(() => {

        // ↓ customers dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

        // ↓ deal das dropdown values
        (async () => {
            await handleGetDealDas('', 1);
        })();

        // ↓ ressources dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();

    }, []);

    return (
        <>
            <div className="w-full flex flex-col items-center px-4 pt-2 pb-6">
                <InfoModule
                    control={control}
                    disabled={disabled}
                    handleGetDealDas={handleGetDealDas}
                    dealDas={dealDas}
                />
                <RessourcesModule
                    control={control}
                    disabled={disabled}
                    handleGetRessources={handleGetRessources}
                    productionRessourcesValues={productionRessourcesValues}
                    setProductionRessourcesValues={setProductionRessourcesValues}
                    ressources={ressources}
                    errors={errors}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <Error errors={errors}/>
            </div>
        </>
    );
}

export default InternalPopup;
