import ClientsInvoices from './ClientsInvoices';
import Expenses from './Expenses';
import Suppliers from './Suppliers';

const SubsidiaryTabs = ({
    sentInvoicesData,
    draftInvoicesData,
    pendingContractorInvoicesData,
    toPayContractorInvoicesData,
    currentExpenseReportsData,
    toPayExpenseReportsData,
    isTraining
}) => {
    return (
        <div>
            <ClientsInvoices
                color={isTraining ? 'blue' : 'orange'}
                sentInvoicesData={sentInvoicesData}
                draftInvoicesData={draftInvoicesData}
            />
            <Suppliers
                color={isTraining ? 'blue' : 'orange'}
                pendingContractorInvoicesData={pendingContractorInvoicesData}
                toPayContractorInvoicesData={toPayContractorInvoicesData}
            />
            <Expenses
                color={isTraining ? 'blue' : 'orange'}
                currentExpenseReportsData={currentExpenseReportsData}
                toPayExpenseReportsData={toPayExpenseReportsData}
            />
        </div>
    )
}

export default SubsidiaryTabs;
