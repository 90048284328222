import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import TabCard from "../../../../components/ui/TabCard/TabCard";
import {loadingRateTabConfigConfig} from "../../configs/loadingRateTabConfig";
import {tjmTabConfig} from "../../configs/tjmTabConfig";

const LoadingRateAndTjm = ({data}) => {

    return (
        <div className="my-10">
            <LineTitle title="Taux de charge & TJM"/>

            <div className="mt-5">
                <TabCard config={loadingRateTabConfigConfig(data)}/>
            </div>

            <div className="mt-5">
                <TabCard config={tjmTabConfig(data)}/>
            </div>
        </div>
    )
}

export default LoadingRateAndTjm;
