import {ServicesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_SERVICES';

export async function getAllServices(token: string, params: string, page: number) {
    try {
        const result = await ServicesDao.getAllServices(token, params, page);

        const metadata = {
            count: result.count,
            next: result.next,
            previous: result.previous
        };

        return {
            metadata,
            data: result.results
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des services',
            error: error
        });
    }
}
