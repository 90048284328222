import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import {DealActions} from '../../../../actions';
import PopUp from '../../../../components/ui/Popup'
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard'
import Button from '../../../../components/ui/Button/Button';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import {notify} from '../../../../components/ui/Toast/Toast';
import {standardFormat, frenchFormat} from '../../../../utils';

const ResponsePopup = ({
    userData,
    view,
    setView,
    dealId,
    refreshDealData,
    blueStyle = false
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    // -- react hook form --
    const defaultValues = {
        response_date: dayjs()
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (!view) reset(defaultValues);
    }, [view, refreshDealData]);

    const onSubmit = async (formData) => {
        setDisabled(true);
        setLoading(true);

        const payload = {
            response_date: standardFormat(formData.response_date),
            status: 300
        }

        const result = await DealActions.updateDeal(userData, payload, dealId);

        if (!result.success) {
            notify("Une erreur est survenue lors de la mise à jour de l'affaire");
        }
        else {
            reset(defaultValues);
            await refreshDealData();
            notify("L'affaire a été mise à jour");

            setView(false);
        }

        setLoading(false);
        setDisabled(false);
    }

    const handleCancel = () => {
        reset(defaultValues);
        setView(false);
    }

    return (
        <PopUp xOverflow="overflow-visible" yOverflow="overflow-visible"  view={view} setView={setView} width="550px">
            <ContainerCard overflow="overflow-visible" title="Date de la remise de l'offre" coloredButtonDisplayed={false}>
                <div className="mb-4">
                    <Controller
                        name="response_date"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez selectionner une date'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label={"Date de remise de l'offre"}
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                            />
                        )}
                    />
                </div>
                <div className='flex flex-row mx-auto mt-8 w-fit'>
                    <Button
                        onClick={handleCancel}
                        type='white'
                        content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content={"Valider"}
                        loading={loading}
                        disabled={!isValid || disabled}
                        type={blueStyle ? 'blue' : 'primary'}
                    />
                </div>
            </ContainerCard>
        </PopUp>
    )
}

export default ResponsePopup;
