import {IdcardFilled} from '@ant-design/icons';
import Progress from 'antd/lib/progress';

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import Badge from '../../../../components/ui/Badge';
import {dasChartConfig} from '../../configs/productionTab/dasChartConfig';

const OfficeLoadTabChildren = ({officeLoadChildrenData}) => {
    const IdCardIcon = <IdcardFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    const data = [
        {
            type: 'Junior:',
            value: officeLoadChildrenData.cabinetJunior
        },
        {
            type: 'Confirmé:',
            value: officeLoadChildrenData.cabinetConfirmed
        },
        {
            type: 'Sénior:',
            value: officeLoadChildrenData.cabinetSenior
        }
    ];

    const copy = {...officeLoadChildrenData};
    delete copy.cabinetJunior;
    delete copy.cabinetConfirmed;
    delete copy.cabinetSenior;

    return (
        <div className='flex justify-between'>
            <div className='w-[48%] self-center'>
                <SimpleCard
                    width="w-full"
                    background="white"
                    font="orange"
                    lineColor="black"
                    title={Object.keys(copy).length}
                    shadow={false}
                    borderSize='border'
                    subTitle={'Das'}
                    iconComponent={IdCardIcon}
                    topComponent={<TopComponent
                        officeLoadChildrenData={officeLoadChildrenData}
                    />}
                />
            </div>

            <div className='w-[48%] h-auto'>
                <DonutsChart data={data} config={dasChartConfig} label=''/>
            </div>
        </div>
    )
}

export default OfficeLoadTabChildren;

const TopComponent = ({officeLoadChildrenData}) => {

    const copy = {...officeLoadChildrenData};
    delete copy.cabinetJunior;
    delete copy.cabinetConfirmed;
    delete copy.cabinetSenior;

    let dasS = [];

    for (const key in copy) {
        dasS.push({
            das: key,
            value: officeLoadChildrenData[key]
        })
    }

    return (<div className='flex flex-wrap pb-5 bg-white rounded-b-md'>
        {dasS.map((el, index) => {
            return (
                <div key={index} className='flex items-center justify-between w-1/2 px-5 pt-5 gap-x-2'>
                    <Badge style={{ width: '48px' }} content={el.das} type='rounded'/>
                    <Progress percent={el.value}  showInfo={true} strokeColor='#e98b4e'/>
                </div>
            )
        })}
    </div>)
}