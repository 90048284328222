import { getCurrentMonthAndYear } from "../../../utils/getCurrentMonthAndYear";
import { capitalize } from "../../../utils";

export const aggregateLoadProjectionDataPerDas =  (data: any) => {
    
    //make labels dynamic based on current month
    const m1 = capitalize(getCurrentMonthAndYear(0, true));
    const m2 = capitalize(getCurrentMonthAndYear(1, true));
    const m3 = capitalize(getCurrentMonthAndYear(2, true));
    const m4 = getCurrentMonthAndYear(3, true);
    const m6 = getCurrentMonthAndYear(5, true);
    const q1 = `${capitalize(m4)} - ${capitalize(m6)}`;
    const m7 = getCurrentMonthAndYear(6, true);
    const m12 = getCurrentMonthAndYear(11, true);
    const s1 = `${capitalize(m7)} - ${capitalize(m12)}`;
    const labels = [m1, m2, m3, q1, s1];
    
    const dataObject = [
        {
            load_type: 'Charge de production',
            period: m1,
            value: data.m1.load
        },
        {
            load_type: 'Charge de production',
            period: m2,
            value: data.m2.load
        },
        {
            load_type: 'Charge de production',
            period: m3,
            value: data.m3.load
        },
        {
            load_type: 'Charge de production',
            period: q1,
            value: data.q1.load
        },
        {
            load_type: 'Charge de production',
            period: s1,
            value: data.s1.load
        },
        {
            load_type: 'Charge prévisionnelle',
            period: m1,
            value: data.m1.potential_load
        },
        {
            load_type: 'Charge prévisionnelle',
            period: m2,
            value: data.m2.potential_load
        },
        {
            load_type: 'Charge prévisionnelle',
            period: m3,
            value: data.m3.potential_load
        },
        {
            load_type: 'Charge prévisionnelle',
            period: q1,
            value: data.q1.potential_load
        },
        {
            load_type: 'Charge prévisionnelle',
            period: s1,
            value: data.s1.potential_load
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: m1,
            value: data.m1.available
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: m2,
            value: data.m2.available
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: m3,
            value: data.m3.available
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: q1,
            value: data.q1.available
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: s1,
            value: data.s1.available
        }
    ];

    return {labels: labels, chartData: dataObject}
}

export const aggregateLoadProjectionDataCabinet =  (data: any) => {
    
    //make labels dynamic based on current month
    const m1 = capitalize(getCurrentMonthAndYear(0, true));
    const m2 = capitalize(getCurrentMonthAndYear(1, true));
    const m3 = capitalize(getCurrentMonthAndYear(2, true));
    const m4 = getCurrentMonthAndYear(3, true);
    const m6 = getCurrentMonthAndYear(5, true);
    const q1 = `${capitalize(m4)} - ${capitalize(m6)}`;
    const m7 = getCurrentMonthAndYear(6, true);
    const m12 = getCurrentMonthAndYear(11, true);
    const s1 = `${capitalize(m7)} - ${capitalize(m12)}`;
    const labels = [m1, m2, m3, q1, s1];

    const dasS = []

    for (const key in data) {
        dasS.push({das:key,data:data[key]})
    }
    
    const dataObject = [
        {
            load_type: 'Charge de production',
            period: m1,
            value: dasS.reduce((acc,curr) => acc + curr.data.m1.load,0)
        },
        {
            load_type: 'Charge de production',
            period: m2,
            value: dasS.reduce((acc,curr) => acc + curr.data.m2.load,0)
        },
        {
            load_type: 'Charge de production',
            period: m3,
            value: dasS.reduce((acc,curr) => acc + curr.data.m3.load,0)
        },
        {
            load_type: 'Charge de production',
            period: q1,
            value: dasS.reduce((acc,curr) => acc + curr.data.q1.load,0)
        },
        {
            load_type: 'Charge de production',
            period: s1,
            value: dasS.reduce((acc,curr) => acc + curr.data.s1.load,0)
        },
        {
            load_type: 'Charge prévisionnelle',
            period: m1,
            value: dasS.reduce((acc,curr) => acc + curr.data.m1.potential_load,0)
        },
        {
            load_type: 'Charge prévisionnelle',
            period: m2,
            value: dasS.reduce((acc,curr) => acc + curr.data.m2.potential_load,0)
        },
        {
            load_type: 'Charge prévisionnelle',
            period: m3,
            value: dasS.reduce((acc,curr) => acc + curr.data.m3.potential_load,0)
        },
        {
            load_type: 'Charge prévisionnelle',
            period: q1,
            value: dasS.reduce((acc,curr) => acc + curr.data.q1.potential_load,0)
        },
        {
            load_type: 'Charge prévisionnelle',
            period: s1,
            value: dasS.reduce((acc,curr) => acc + curr.data.s1.potential_load,0)
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: m1,
            value: dasS.reduce((acc,curr) => acc + curr.data.m1.available,0)
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: m2,
            value: dasS.reduce((acc,curr) => acc + curr.data.m2.available,0)
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: m3,
            value: dasS.reduce((acc,curr) => acc + curr.data.m3.available,0)
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: q1,
            value: dasS.reduce((acc,curr) => acc + curr.data.q1.available,0)
        },
        {
            load_type: 'Niveau de ressources humaines',
            period: s1,
            value: dasS.reduce((acc,curr) => acc + curr.data.s1.available,0)
        }
    ];

    return {labels: labels, chartData: dataObject}
}