import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import TextInput from '../../../../components/ui/Inputs/TextInput';
import Button from '../../../../components/ui/Button';
import {InvoiceActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const CreateNewInvoiceItem = ({
    userData,
    invoiceData,
    invoiceItemToBeUpdated,
    setInvoiceItemToBeUpdated,
    setView,
    refreshData
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    // -- react hook form --
    const defaultValues = {
        text: "",
        quantity: "",
        unit_price: "",
        vat: ""
    };
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- fill invoice values in form for update --
    useEffect(() => {
        invoiceItemToBeUpdated &&
            reset({
                text: invoiceItemToBeUpdated.text,
                quantity: (+invoiceItemToBeUpdated.quantity).toString(),
                unit_price: (+invoiceItemToBeUpdated.unit_price).toString(),
                vat: (+invoiceItemToBeUpdated.vat).toString()
            });
    }, [invoiceItemToBeUpdated]);

    // -- handler functions --
    const onSubmit = async (data) => {
        setDisabled(true);
        setLoading(true);
        setErrors(null);

        let items = [];

        const item = {
            text: data.text,
            quantity: data.quantity,
            unit_price: data.unit_price,
            vat: data.vat
        }

        if (invoiceItemToBeUpdated) {
            let oldItems = invoiceData.items.filter((item) => item.id !== invoiceItemToBeUpdated.id);
            oldItems.push(item);
            items = oldItems;
        }
        else if (invoiceData.items.length > 0) {
            let oldItems = invoiceData.items;
            oldItems.push(item);
            items = oldItems;
        }
        else {
            items.push(item);
        }

        // ↓ body to send to the post/patch request (see API documentation)
        const newInvoice = {
            deal_id: invoiceData.deal.id,
            customer_id: invoiceData.deal.customer.id,
            bank_name: invoiceData.bank.name,
            subsidiary: invoiceData.subsidiary,
            type: invoiceData.type,
            status: invoiceData.status,
            billing_address: invoiceData.billing_address,
            billing_date: invoiceData.billing_date,
            due_date: invoiceData.due_date,
            billable: invoiceData.billable,
            billable_vat_rate: invoiceData.billable_vat_rate ,
            expenses: invoiceData.expenses,
            expenses_vat_rate: invoiceData.expenses_vat_rate,
            items: items
        }

        const result = await InvoiceActions.updateInvoiceById(userData, invoiceData.id, newInvoice);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            invoiceItemToBeUpdated ?
                notify('La ligne a été modifiée') :
                notify('La nouvelle ligne a été ajoutée');
            await refreshData();

            setInvoiceItemToBeUpdated(null);
            setView(false);
            reset();
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={invoiceItemToBeUpdated ? "Éditer la ligne" : "Nouvelle ligne"}
            coloredButtonDisplayed={false}
        >
            <div className='flex justify-between px-4 mb-6'>
                <div className="flex flex-col gap-2">
                    <Controller
                        name="quantity"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir une quantité.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label={'Quantité'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Quantité"
                                type="number"
                            />
                        )}
                    />
                    <Controller
                        name="unit_price"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le prix unique.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label={'PU HT'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="PU HT"
                                type="number"
                            />
                        )}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <Controller
                        name="vat"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir la TVA.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label={'TVA'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="TVA"
                                type="number"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="px-4">
                <Controller
                    name="text"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir une description.'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <>
                            <div className="font-normal text-sm text-[#646464] mb-2">
                                Description
                                <span className="ml-1 text-sm font-normal text-red-500">*</span>
                            </div>
                            <TextAreaInput
                                placeholder={'Description'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                maxLength={500}
                                isDisabled={disabled}
                            />
                        </>
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pt-4">
                <div className="flex justify-center">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={invoiceItemToBeUpdated ? 'Enregistrer' : 'Ajouter'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewInvoiceItem
