export const grossMarginTableConfig = () => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: '-',
                    value: 'name',
                    width: 'w-[20%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Chiffre d’affaires HT',
                    value: 'revenue',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.revenue.toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Sous-traitance',
                    value: 'contractors',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.contractors.toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Note de frais',
                    value: 'expenses',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.expenses.toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Achats directs',
                    value: 'procurements',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.procurements.toLocaleString()}
                            </div>
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Marge brute',
                    value: 'direct_purchases',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.gross_margin.toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
