import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import InvoicePreviewPopup from './components/InvoicePreviewPopup';
import Table from '../../components/ui/Table';
import {unpaidInvoicesTableConfig} from './configs/unpaidInvoicesTableConfig';

const InvoicesAwaitingPaymentPage = ({
    tableType,
    data,
    filterInvoicesList,
    userData,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [itemSelected, setItemSelected] = useState(null);
    const [showInvoicePreviewPopup, setShowInvoicePreviewPopup] = useState(false);
    
    useEffect(() => {
        if (!showInvoicePreviewPopup) {
            setItemSelected(null);
        }
    }, [showInvoicePreviewPopup]);

    const handleInvoicePreview = (item) => {
        setItemSelected(item);
        setShowInvoicePreviewPopup(true);
    }

    const tableConfig = unpaidInvoicesTableConfig(
        handleInvoicePreview,
        filterInvoicesList,
        userData,
        tableType
    );

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/admin'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures en cours totaux (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>

            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />

            <InvoicePreviewPopup
                view={showInvoicePreviewPopup}
                setView={setShowInvoicePreviewPopup}
                item={itemSelected}
            />
        </>
    )
}

export default InvoicesAwaitingPaymentPage;
