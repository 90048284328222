export const contractors = {

    from(contractorsResult) {

        const getDasS = () => {
            let dasS = {};

            for (const key in contractorsResult) {
                dasS[key] = {
                    "count": contractorsResult[key]?.count ?? 0,
                    "value": contractorsResult[key]?.value ?? 0
                }
            }

            return dasS;
        }

        return getDasS();
    }
}
