import {TrainingAttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_TRAINING_ATTACHMENT';

export async function deleteTrainingAttachment(userData: Record<string, string>, id: string) {
    try {
        return await TrainingAttachmentDao.deleteTrainingAttachment(userData.userToken, id);
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la suppression de la piece jointe de formation',
            error: error
        });
    }
}
