import React, {useState} from 'react';
import {Tooltip} from 'antd';

import Loader from "../../../../components/loaders/Loader";
import deleteIcon from "../../../../assets/icons/actionsbutton/white-delete.png"
import ConfirmationPopup from '../../../../components/ui/ConfirmationPopup';

export const bannerDealsConfig = (
    handleDeleteDeal,
    deleteDealLoading,
    buttonsDisabled,
    isTraining,
    cannotBeModified
) => {
    const [deleteDealConfirmationView, setDeleteDealConfirmationView] = useState(false);

    return {
        blackgroundColor: isTraining ? 'bg-gradient-to-br from-[#00ABE9] via-[#00ABAB] to-[#CED955]' : 'bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]',
        badgeBorderColor: 'transparent',
        titleLabel: '',
        centerInfo: false,
        statusBadge: (status) => {
            switch (status) {
                case 0:
                    return {label: 'Affaire interne', backgroundColor: isTraining === 'Formation' ? '#212121' : '#00abe9'};
                case 1:
                    return {label: 'Autre interne', backgroundColor: isTraining === 'Formation' ? '#212121' : '#00abe9'};
                case 100:
                    return {label: 'Cahier des Charges demandé', backgroundColor: isTraining === 'Formation' ? '#c3d659' : '#212121'};
                case 150:
                    return {label: 'Étude CdC', backgroundColor: '#28456c'};
                case 190:
                    return {label: 'Hors champ', backgroundColor: '#f0a841'};
                case 200:
                    return {label: 'Réponse en cours', backgroundColor: '#f0a841'};
                case 290:
                    return {label: 'Abandonnée', backgroundColor: '#646464'};
                case 300:
                    return {label: 'Offre remise', backgroundColor: '#69314c'};
                case 400:
                    return {label: 'Perdue', backgroundColor: '#DC3832'};
                case 500:
                    return {label: 'Gagnée', backgroundColor: '#27ae60'};
                case 600:
                    return {label: 'Produite', backgroundColor: '#492f64'};
                case 700:
                    return {label: 'Facturée', backgroundColor: '#2b593f'};
                case 900:
                    return {label: 'Close', backgroundColor: '#212121'};
                default:
                    return {label: 'Indéfini', backgroundColor: '#212121'};
            }
        },
        actionComponents: (status) => {
            return (
                <>
                    {
                        status === 500 || status === null || cannotBeModified ?
                            null :
                            <div className="flex items-center justify-center">
                                <Tooltip title={buttonsDisabled ? '' : 'Supprimer affaire'}>
                                    <button
                                        onClick={() => setDeleteDealConfirmationView(true)}
                                        disabled={buttonsDisabled}
                                        className='flex items-center justify-center p-5'
                                    >
                                        {
                                            deleteDealLoading ?
                                                <Loader size={20} color='white'/> :
                                                <img className='w-5' src={deleteIcon} alt=""/>
                                        }
                                    </button>
                                </Tooltip>
                            </div>
                    }
                    <ConfirmationPopup
                        view={deleteDealConfirmationView}
                        setView={setDeleteDealConfirmationView}
                        title='Souhaitez-vous supprimer cette affaire ?'
                        onConfirm={handleDeleteDeal}
                        blueStyle={isTraining}
                    />
                </>
            );
        }
    }
    ;
};
