// import {Dispatch, AnyAction} from '@reduxjs/toolkit';
import {ACTION_CODE} from './_actionCode';
import {ExpenseReportService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'GET_AGGREGATED_EXPENSE_REPORT_BY_ID';

export async function getAggregatedExpenseReportById(
    userData: Record<string, string>,
    id: number
) {
    try {
        LoggerService.logInfo('Action - getAggregatedExpenseReportById - Start');

        const expenseReport = await ExpenseReportService.getExpenseReportById(userData, id);

        /*         if (expenseReport.expenses?.length) {
            expenseReport.ressource = expenseReport.expenses[0].ressource;
            const descriptor = Object.getOwnPropertyDescriptor(expenseReport, 'expenses');
            Object.defineProperty(expenseReport, 'children', {...descriptor} as PropertyDescriptor & ThisType<any>);
            delete expenseReport['expenses'];
        } */

        if (expenseReport.expenses?.length) {
            expenseReport.ressource = expenseReport.expenses[0].ressource;
            expenseReport.children = expenseReport.expenses.map((expense) => {// TODO: Fix eslint error
                const {vat, amount, co2} = expense;

                return {
                    ...expense,
                    vat: parseFloat(vat),
                    amount: parseFloat(amount),
                    co2: parseFloat(co2),
                    parentStatus: expenseReport.status
                };
            });
            delete expenseReport['expenses'];
        }

        LoggerService.logInfo('Action - getAggregatedExpenseReportById - End - Success');

        return ResultModel.newSuccessResult(expenseReport);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAggregatedExpenseReportById - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation d\'une note de frais',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
