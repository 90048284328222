import {UserDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_USER';

export async function getUser(userToken: string, id: number) {
    try {
        const result = await UserDao.getUser(userToken, id);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation des détails d\'un utilisateur.',
            error: error
        });
    }
}
