import {IdcardFilled} from '@ant-design/icons';
import {TagsFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import {transformThousandNumbers} from '../../../../utils';

const ContractorsAnalysis = ({
    data,
    datePickerValue,
    onDatePickerChange
}) => {
    const IdcardIcon = <IdcardFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const TagsIcon = <TagsFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = (key) => {
        let sum = 0;

        for(const k in data) {
            sum += data[k][key];
        }

        return sum;
    }

    return (
        <div className='my-10'>
            <LineTitle title='Analyse de la sous-traitance'/>
            <div className='mt-10 mb-6'>
                <Datepicker
                    type='range'
                    defaultValue={datePickerValue}
                    onChange={onDatePickerChange}
                />
            </div>
            <div className='flex flex-row'>
                <SimpleCard
                    width='w-[50%]'
                    iconComponent={IdcardIcon}
                    title={getSum('count')}
                    subTitle="Sous-traitants mobilisés"
                    background='orange'
                    bottomComponent={<BottomComponent data={data} count={true}/>}
                />
                <div className='mx-5'/>
                <SimpleCard
                    width='w-[50%]'
                    iconComponent={TagsIcon}
                    title={transformThousandNumbers(getSum('value'))}
                    subTitle="Valeur de la sous-traitance"
                    background='orange'
                    bottomComponent={<BottomComponent data={data} count={false}/>}
                />
            </div>
        </div>
    )
}

export default ContractorsAnalysis;

const BottomComponent = ({
    data,
    count
}) => {
    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            value: count ? data[key].count : transformThousandNumbers(data[key].value)
        });
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge content='JDH' color='#f0a841' type='round'/>
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                {el.value}
                            </span>
                        </div>
                    );
                })
            }
        </div>
    )
}
