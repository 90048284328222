import {ACTION_CODE} from './_actionCode';
import {ContractorService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_CONTRACTORS';

export async function getAllContractors(
    userData: Record<string, string>,
    params = "",
    page = 1,
) {
    try {
        LoggerService.logInfo('Action - getAllcontractors - Start');

        const getAllcontractorsResult = await ContractorService.getAllContractors(userData, params, page);

        const {data, metadata} = getAllcontractorsResult;

        LoggerService.logInfo('Action - getAllcontractors - End - Success');

        const result = ResultModel.newSuccessResult(data, metadata)

        return result;
        
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllcontractors - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des fournisseurs.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
