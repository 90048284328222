import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const AdminDasTab = ({
    role,
    userId,
    setDasKey
}) => {
    const dasS = useSelector((state) => state.DasReducer.das);

    const [currentTab, setCurrentTab] = useState(dasS[0].name);

    useEffect(() => {
        if (role === 'PA') {
            setDasKey(dasS[0].name);
        }
        else if (role === 'MA') {

            let userDas = dasS.filter((el) => el.manager?.id === userId);
            userDas = userDas.length > 0 ? userDas[0] : '';
            userDas = userDas?.name ?? '';

            setDasKey(userDas);
        }
    }, [])

    const handleTabClick = (das) => {
        setCurrentTab(das);
        setDasKey(das);
    }

    return (
        <>
            {
                role === 'PA' ?
                    <div
                        className='fixed z-50 flex items-start justify-center w-full overflow-x-auto bg-white'
                        style={{boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)'}}
                    >
                        {
                            dasS.map((el, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => handleTabClick(el.name)}
                                        className='px-6 py-6'
                                    >
                                        <div
                                            className={`pt-2 pb-0.5 px-4 rounded-full text-xl font-bold
                                            ${currentTab === el.name ? 'background-gradient text-white' : 'bg-white text-[#646464]'}`}
                                            style={{fontFamily: 'Din condensed'}}
                                        >
                                            {el.name}
                                        </div>
                                    </button>
                                )
                            })
                        }
                    </div> :
                    null
            }
        </>
    )
}

export default AdminDasTab;
