import ResourceCosts from "./ResourceCosts";
import CommercialAcquisitionCost from "./CommercialAcquisitionCost";
import ProductionCosts from "./ProductionCosts";

const Costs = ({
    data,
    datePicker,
    handleDatePickerChange
}) => {
    
    const getPercent = (value) => {
        if (!value || !data.gross_margin) {
            return 0;
        }
        else {
            return +((value / data.gross_margin) * 100).toFixed(5);
        }
    }

    return (
        <>
            <ResourceCosts
                data={data}
                datePickerValue={datePicker}
                onDatePickerChange={handleDatePickerChange}
                getPercent={getPercent}
            />
            <CommercialAcquisitionCost
                data={data.acquisition}
                getPercent={getPercent}
            />
            <ProductionCosts
                data={data.production}
                getPercent={getPercent}
            />
        </>
    )
}

export default Costs;
