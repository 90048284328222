import { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

import {frenchFormat, fetchSelectData, handleFilterList, useFilterQuery} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import { SelectInput, TableDatePicker } from '../../../components/ui/Table';
import { UserActions } from '../../../actions';

export const payableExpenseReportsTableConfig = (
    handlePayExpenseReports,
    handleDeleteExpenseReport,
    actionButtonLoading,
    actionButtonDisabled,
    filterExpenseReports,
    tableType,
    userData
) => {
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        payment_before: '',
        payment_after: ''
    });

    const navigate = useNavigate();

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterExpenseReports, tableType);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    };

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })
    }, []);
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'ID',
                    value: 'id',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        const expenseReportStatus = () => {
                            switch (item.status) {
                                case 0:
                                    return {status: 'En cours', color: null};
                                case 1:
                                    return {status: 'À vérifier', color: '#f0a841'};
                                case 2:
                                    return {status: 'À payer', color: '#e36d38'};
                                case 3:
                                    return {status: 'Payée', color: '#ced95f'};
                                default:
                                    return {status: 'Indéfini', color: '#212121'};
                            }
                        };

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={expenseReportStatus().status} color={expenseReportStatus().color}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Ressource',
                    value: 'ressource',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={[item.ressource]} type='rounded'/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="ressource"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de paiement',
                    value: null,
                    width: 'w-[12%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.status === 3 ? frenchFormat(item.payment_date) : '-'}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name="payment" onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant HT (€)',
                    value: 'amount',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(item.amount).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'TVA (%)',
                    value: 'vat',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.vat}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de début',
                    value: 'start_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.start_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de fin',
                    value: 'end_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.end_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="pay"
                                    isLoading={actionButtonLoading === `${item.id}upd`}
                                    onClick={() => handlePayExpenseReports(item)}
                                    disabled={actionButtonDisabled}
                                    payConfirmationQuestion='Souhaitez-vous changer le statut de cette note de frais ?'
                                />
                                <div className="w-0 h-0 px-2"/>
                                <ActionsButton
                                    type="delete"
                                    isLoading={actionButtonLoading === `${item.id}del`}
                                    onClick={() => handleDeleteExpenseReport(item)}
                                    disabled={actionButtonDisabled}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer cette note de frais ?'
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => navigate(`/expenses/${item.id}`)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
