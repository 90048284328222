import React from 'react';
import {Route, Routes} from 'react-router-dom';
import InvoicesPage from '../pages/invoicesPage/InvoicesPage';
import InvoiceDetailsPage from '../pages/invoicesPage/InvoiceDetailsPage';

function InvoicesRoute() {
    return (
        <Routes>
            <Route path='/' element={<InvoicesPage/>}/>
            <Route path='/:id' element={<InvoiceDetailsPage/>}/>
        </Routes>

    );
}

export default InvoicesRoute;
