import React from 'react';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Badge from '../../../../components/ui/Badge';

const MissionInformationsCard = ({userData, data, onEditButtonClick, cannotBeModified}) => {

    const isAllowedToEdit = userData?.role === "CO" && data?.deal?.production_lead?.id !== userData?.id ? false : true;

    const missionStatus = () => {
        switch (data.status) {
            case 0:
                return {status: 'Prévue', color: '#f0a841'};
            case 1:
                return {status: 'En cours', color: '#e36d38'};
            case 2:
                return {status: 'Terminée', color: '#ced95f'};
            default:
                return {status: 'Indéfini', color: '#212121'};
        }
    };

    return (
        <ContainerCard
            title='Informations de la mission'
            editButtonDisplayed={isAllowedToEdit && data?.status < 2 && !cannotBeModified}
            onEditButtonClick={onEditButtonClick}
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[290px]'
        >
            <div className='flex flex-col'>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Ressource</div>
                    <div className='text-[#212121] text-base'>
                        <Badge content={data.ressource ? [data.ressource] : []} type='round'/>
                    </div>
                </div>

                <div className='flex items-center justify-start mt-2'>
                    <div className='text-[#646464] text-sm mr-4'>Status</div>
                    <Badge content={missionStatus().status} color={missionStatus().color}/>
                </div>

                <hr className='w-12 my-5 border-[#212121]'/>
                <div className='flex items-center justify-between'>
                    <div className='flex items-start justify-start'>
                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Jours</div>
                        <div className='text-[#212121] text-base'>{`${+data.billable_days}j`}</div>
                    </div>
                    <div className='flex items-start justify-start'>
                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>TJM</div>
                        <div className='text-[#212121] text-base'>{`${+data.daily_rate}€`}</div>
                    </div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Budget</div>
                    <div className='text-[#212121] text-base'>{`${+data?.deal?.budget}€`}</div>
                </div>
            </div>
        </ContainerCard>
    )
}

export default MissionInformationsCard;
