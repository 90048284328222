import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';

import {notify, Toast} from '../../components/ui/Toast/Toast';
import Layout from '../../components/layout/Layout';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import MissionDetailsBanner from './components/missionDetailComponents/MissionDetailsBanner';
import MissionInformationsCard from './components/missionDetailComponents/MissionInformationsCard';
import DealInformationsCard from './components/missionDetailComponents/DealInformationsCard';
import MissionNotesCard from './components/missionDetailComponents/MissionNotesCard';
import Datepicker from '../../components/ui/Datepicker';
import ProductionsTable from './components/missionDetailComponents/ProductionsTable';
import ExpensesTable from './components/missionDetailComponents/ExpensesTable';
import {firstDayYear, lastDayYear, standardFormat} from '../../utils';
import {productionsTableConfig} from './configs/missionDetailConfigs/productionsTableConfig';
import {expensesTableConfig} from './configs/missionDetailConfigs/expensesTableConfig';
import {MissionActions, ProductionActions, ExpenseActions} from '../../actions';
import MissionInformationPopup from './popups/missionPopups/MissionInformationPopup';
import MissionNotesPopUp from './components/missionDetailComponents/MissionNotesPopUp';
import ProductionsPopUp from './components/missionDetailComponents/ProductionsPopUp';
import Popup from '../../components/ui/Popup/Popup';
import CreateNewExpensePopup from './components/missionDetailComponents/CreateExpensePopup/CreateNewExpensePopup';
// import { ErrorBoundary } from "react-error-boundary";

const MissionDetails = () => {
    // states
    const [missionData, setMissionData] = useState({});
    const [productionData, setProductionData] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteProductionLoading, setDeleteProductionLoading] = useState(null);
    const [deleteExpenseLoading, setDeleteExpenseLoading] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [datePicker, setDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [datePickerLoading, setDatePickerLoading] = useState(false);
    const [productionToBeUpdated, setProductionToBeUpdated] = useState(null);
    const [expenseToBeUpdated, setExpenseToBeUpdated] = useState(null);
    const [missionInformationPopupView, setMissionInformationPopupView] = useState(false);
    const [missionNotesPopupView, setMissionNotesPopupView] = useState(false);
    const [productionsPopupView, setProductionsPopupView] = useState(false);
    const [expensesPopupView, setExpensesPopupView] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const { id } = useParams();
    const navigate = useNavigate();

    const cannotBeModified = missionData.deal?.status === 900;

    // Get data
    useEffect(() => {
        (async () => {

            const missionResult = await MissionActions.getMissionById(userData, id);

            const datePickerAfter = standardFormat(new Date(datePicker.startDate));
            const datePickerBefore = standardFormat(new Date(datePicker.endDate));

            if (missionResult.success) {
                setMissionData(missionResult.data);
            }
            else {
                const urlPath = window.location.pathname.split('/');

                navigate(`/deals/${urlPath[2]}/missions`, {state: 'invalid_mission_id'});
            }

            const optionalProps = {
                deal: missionResult.data.deal.id,
                ressource: missionResult.data.ressource.username
            }

            const missionProductionsResult = await ProductionActions.getAllProductions(userData, datePickerBefore, datePickerAfter , optionalProps);

            if (missionProductionsResult.success) {
                setProductionData(missionProductionsResult.data);
            }
            else {
                notify('Erreur de récuperation des temps');

                return
            }

            const expensesResult = await ExpenseActions.getAllExpenses(userData, datePickerBefore, datePickerAfter, optionalProps);

            if (expensesResult.success) {
                setExpensesData(expensesResult.data);
            }
            else {
                notify('Erreur de récuperation des factures');

                return
            }

            setDatePickerLoading(false);
            setLoading(false);
        })();
    }, [datePicker])

    // Functions
    const handleDatePickerChange = (e) => {
        setDatePickerLoading(true);
        setDatePicker({
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        });
    };

    const handleEditProductionClick = (item) => {
        setProductionToBeUpdated(item);
        setProductionsPopupView(true);
    }

    const handleDeleteProductionClick = async (id) => {
        setButtonsDisabled(true);
        setDeleteProductionLoading(id);

        const result = await ProductionActions.deleteProduction(userData, id);

        if (!result.success) {
            notify('Erreur de création du temps');
        }
        else {
            await refreshProductionData();
            notify('Votre temps à bien été supprimé');
        }

        // after delete request
        setDeleteProductionLoading(null);
        setButtonsDisabled(false);
    }

    const handleEditExpenseClick = (item) => {
        setExpensesPopupView(true);
        setExpenseToBeUpdated(item);
    }

    const handleDeleteExpenseClick = async(expense) => {
        setButtonsDisabled(true);
        setDeleteExpenseLoading(expense.id);

        const result = await ExpenseActions.deleteExpense(userData, expense);

        if (!result.success) {
            notify('Erreur de suppression de la dépense');
        }
        else {
            await refreshExpensesData();
            notify('Votre frais à bien été supprimé');
        }

        // after delete request
        setDeleteExpenseLoading(null);
        setButtonsDisabled(false);
    }

    // functions
    const refreshMissionData = async () => {
        const missionResult = await MissionActions.getMissionById(userData, id);

        if (missionResult.success) {
            setMissionData(missionResult.data);
        }
        else {
            notify('Erreur de récuperation des factures');
        }
    }

    const refreshProductionData = async () => {
        const datePickerAfter = standardFormat(new Date(datePicker.startDate));
        const datePickerBefore = standardFormat(new Date(datePicker.endDate));

        const optionalProps = {
            deal: missionData.deal.id,
            ressource: missionData.ressource.username
        }

        const missionProductionsResult = await ProductionActions.getAllProductions(userData, datePickerBefore, datePickerAfter , optionalProps);

        if (missionProductionsResult.success) {
            setProductionData(missionProductionsResult.data);
        }
        else {
            notify('Erreur de récuperation des productions');
        }
    }

    const refreshExpensesData = async () => {
        const datePickerAfter = standardFormat(new Date(datePicker.startDate));
        const datePickerBefore = standardFormat(new Date(datePicker.endDate));

        const optionalProps = {
            deal: missionData.deal.id,
            ressource: missionData.ressource.username
        }

        const expensesResult = await ExpenseActions.getAllExpenses(userData, datePickerBefore, datePickerAfter, optionalProps);

        if (expensesResult.success) {
            setExpensesData(expensesResult.data);
        }
        else {
            notify('Erreur de récuperation des factures');

            return
        }
    }

    const handleNewProductionButtonClick = () => {
        setProductionToBeUpdated(null);
        setProductionsPopupView(true);
    }

    const handleNewExpenseButtonClick = () => {
        setExpenseToBeUpdated(null);
        setExpensesPopupView(true);
    }

    // filter functions

    const filterExpenseList = async (query) => {
        if(!missionData) return;
        
        const datePickerAfter = standardFormat(new Date(datePicker.startDate));
        const datePickerBefore = standardFormat(new Date(datePicker.endDate));
           
        const optionalProps = {
            deal: missionData?.deal?.id,
            ressource: missionData?.ressource?.username
        }

        const expensesResult = await ExpenseActions.getAllExpenses(userData, datePickerBefore, datePickerAfter, optionalProps, `&${query}`);

        if (expensesResult.success) {
            setExpensesData(expensesResult.data);
        }
        else {
            notify('Erreur de récuperation des factures');
        }
    };

    // Congifs
    const productionsConfig = productionsTableConfig(
        handleEditProductionClick,
        handleDeleteProductionClick,
        buttonsDisabled,
        deleteProductionLoading,
        missionData?.status,
        cannotBeModified
    );
    
    const expensesConfig = expensesTableConfig(
        handleEditExpenseClick,
        handleDeleteExpenseClick,
        buttonsDisabled,
        deleteExpenseLoading,
        missionData?.status,
        filterExpenseList,
        userData,
        cannotBeModified
    );

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <MissionDetailsBanner userData={userData} data={missionData} cannotBeModified={cannotBeModified}/>
                <div className='px-20 py-36'>
                    <div className='flex flex-row items-center justify-center mb-6'>
                        <MissionInformationsCard
                            userData={userData}
                            data={missionData}
                            onEditButtonClick={() => setMissionInformationPopupView(true)}
                            cannotBeModified={cannotBeModified}
                        />
                        <DealInformationsCard data={missionData}/>
                        <MissionNotesCard
                            refreshMissionData={refreshMissionData}
                            data={missionData}
                            handleButtonClick={() => setMissionNotesPopupView(true)}
                            userData={userData}
                            cannotBeModified={cannotBeModified}
                        />
                    </div>
                    <Datepicker
                        type="range"
                        onChange={handleDatePickerChange}
                        defaultValue={datePicker}
                    />
                    <ProductionsTable
                        datePickerValue={datePicker}
                        onDatePickerChange={handleDatePickerChange}
                        data={productionData}
                        tableConfig={productionsConfig}
                        handleButtonClick={handleNewProductionButtonClick}
                        loading={datePickerLoading}
                        missionStatus={missionData?.status}
                        cannotBeModified={cannotBeModified}
                    />
                    <ExpensesTable
                        datePickerValue={datePicker}
                        onDatePickerChange={handleDatePickerChange}
                        data={expensesData}
                        tableConfig={expensesConfig}
                        handleButtonClick={handleNewExpenseButtonClick}
                        loading={datePickerLoading}
                        missionStatus={missionData?.status}
                        cannotBeModified={cannotBeModified}
                    />
                </div>
            </PageLoader>
            <Popup
                view={missionInformationPopupView}
                setView={setMissionInformationPopupView}
                width={'1098px'}
                yOverflow=''
                xOverflow=''
            >
                <MissionInformationPopup
                    setView={setMissionInformationPopupView}
                    userData={userData}
                    dealData={null}
                    missionDataForUpdate={missionData}
                    refreshData={refreshMissionData}
                />
            </Popup>
            <Popup
                view={missionNotesPopupView}
                setView={setMissionNotesPopupView}
                width="1098px"
            >
                <MissionNotesPopUp
                    data={missionData}
                    userData={userData}
                    setView={setMissionNotesPopupView}
                    refreshMissionData={refreshMissionData}
                />
            </Popup>
            <Popup
                view={productionsPopupView}
                setView={setProductionsPopupView}
                width="1098px"
                xOverflow=''
                yOverflow=''
            >
                <ProductionsPopUp
                    data={missionData}
                    userData={userData}
                    setView={setProductionsPopupView}
                    refreshProductionData={refreshProductionData}
                    productionToBeUpdated={productionToBeUpdated}
                />
            </Popup>
            <Popup
                view={expensesPopupView}
                setView={setExpensesPopupView}
                width="1098px"
                xOverflow=''
                yOverflow=''
            >
                <CreateNewExpensePopup
                    missionData={missionData}
                    userData={userData}
                    view={expensesPopupView}
                    setView={setExpensesPopupView}
                    refreshExpensesData={refreshExpensesData}
                    expenseToBeUpdated={expenseToBeUpdated}
                    setExpenseToBeUpdated={setExpenseToBeUpdated}
                />
            </Popup>
        </Layout>
    )
}

export default MissionDetails;
