export const aggregateGrossMarginProducedObject = (data: any) => {
    type DataTableItem = {
    id: string;
    name: string;
    turnover: number;
    sub_contracting: number;
    expense_report: number;
    direct_purchases: number;
    gross_margin: number;
    gross_margin_percentage: number;
};
    const dataTable: DataTableItem[] = []

    for (const key in data) {
    
        const element = data[key];
        dataTable.push({
            id: key,
            name: key,
            turnover: element.revenue,
            sub_contracting: element.contractors,
            expense_report: element.expenses,
            direct_purchases: element.procurements,
            gross_margin: element.gross_margin,
            gross_margin_percentage: Math.round(element.revenue === 0 ? 0 : (element.gross_margin / element.revenue * 100))
        })
    }

    const cabinet: DataTableItem =
        {
            id: "cabinet",
            name: "Cabinet (TOTAL)",
            turnover: 0,
            sub_contracting: 0,
            expense_report: 0,
            direct_purchases: 0,
            gross_margin: 0,
            gross_margin_percentage: 0
        }

    // sum every item in the array of objects
    for (const key in data) {
        cabinet.turnover += data[key].revenue;
        cabinet.sub_contracting += data[key].contractors;
        cabinet.expense_report += data[key].expenses;
        cabinet.direct_purchases += data[key].procurements;
        cabinet.gross_margin += data[key].gross_margin;
        
    }

    cabinet.gross_margin_percentage = Math.round(cabinet.turnover === 0 ? 0 : (cabinet.gross_margin / cabinet.turnover * 100));

    dataTable.push(cabinet);

    return dataTable;
}
