import {useEffect} from "react";
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';

import { UserActions } from './../../../actions'
import TextInput from "../../../components/ui/Inputs/TextInputBis";
// import Button from "../../../components/ui/Button";
import {notify} from '../../../components/ui/Toast/Toast';

const EmailForm = () => {
    // const [disabled, setDisabled] = useState(false);
    // const [loading, setLoading] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: ''
    };

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const {control, reset } = useForm({defaultValues: defaultValues});

    useEffect(() => {
        (async () => {
            const response = await UserActions.getUserDetails(null ,userData.userToken);

            if (response.success) {
                const {first_name:  firstName, last_name: lastName, email} = response.data;
                reset({
                    firstName,
                    lastName,
                    email
                })
            }
            else {
                notify('Impossible de récupérer les informations de votre compte.');
            }

        })()
    }, [])

    // const onSubmit = (data) => {
    //     console.log('submit', data);

    //     if (isValid) {
    //         setDisabled(true);
    //         setLoading(true);

    //     }
    // }

    return (
        <div
            style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
            className="pb-6 rounded-lg"
        >
            <div>
                <p style={{fontFamily: 'DIN condensed'}} className="px-6 pt-4 pb-4 text-xl">Modifier mon adresse mail</p>
            </div>

            <div className="h-[1px] bg-gray-200"/>

            <div className="px-6 mt-6">
                <div className="mb-2">
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir votre Nom.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                width="w-[350px]"
                                label={'Nom'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                // disabled={disabled}
                                placeholder="Nom"
                                type="text"
                                readOnly={true} // TODO: remove this line to enable the form
                            />
                        )}
                    />
                </div>
                <div className="mb-2">
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir votre Prénom.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                width="w-[350px]"
                                label={'prénom'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                // disabled={disabled}
                                placeholder="prénom"
                                type="text"
                                readOnly={true} // TODO: remove this line to enable the form
                            />
                        )}
                    />
                </div>
                <div className="mb-2">
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir votre Email.'
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Veuillez saisir une adresse email valide.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                width="w-[350px]"
                                label={'Email'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                // disabled={disabled}
                                placeholder="Email"
                                type="text"
                                readOnly={true} // TODO: remove this line to enable the form
                            />
                        )}
                    />
                </div>

                {/* <div className='flex flex-row mx-auto mt-8 w-fit'>
                    <Button
                        onClick={() => reset()}
                        type='white'
                        content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content="Sauvegarder"
                        loading={loading}
                        disabled={disabled}
                    />
                </div> */}

                {/* {
                    errorMessage &&
                        <div className="flex justify-center mt-5 text-red-500">
                            {`La modification de l'adresse mail a échouée`}
                        </div>
                } */}
            </div>
        </div>
    )
}

export default EmailForm;
