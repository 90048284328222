import {Link} from 'react-router-dom';
// import {useSelector} from 'react-redux';
import {ArrowLeftOutlined} from '@ant-design/icons';

import TabCard from '../../components/ui/TabCard/TabCard';
import {pageTabConfig} from './configs/pageTables/pageTabConfig';

const ProposalsBeingWrittenPage = ({
    das,
    data,
    tableType,
    userData,
    filterDealList,
    handleMissionNavigation,
    setDasKey,
    dasKey,
    handleGetData,
    tableLoading,
    paginationMetadata
}) => {
    const tableConfig = {handleMissionNavigation, filterDealList, tableType, userData, tableLoading, paginationMetadata, handleGetData, dasKey};

    const handleChangeDasKey = (das) => {
        setDasKey(das.label === "Cabinet" ? '' : das.label);
    }

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/codev'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Propositions en cours de rédaction (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>

            <TabCard config={pageTabConfig(data, "deals", das, tableConfig)} onChange={handleChangeDasKey}/>
        </>
    )
}

export default ProposalsBeingWrittenPage;
