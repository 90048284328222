export const teamContribution = {

    from(teamContributionResult) {

        const getDasS = () => {
            let dasS = {};

            for (const key in teamContributionResult) {
                dasS[key] = {
                    "junior": {
                        "gross_margin_contribution": teamContributionResult[key]?.junior?.gross_margin_contribution ?? 0,
                        "sales": teamContributionResult[key]?.junior?.sales ?? 0
                    },
                    "confirmed": {
                        "gross_margin_contribution": teamContributionResult[key]?.confirmed?.gross_margin_contribution ?? 0,
                        "sales": teamContributionResult[key]?.confirmed?.sales ?? 0
                    },
                    "senior": {
                        "gross_margin_contribution": teamContributionResult[key]?.senior?.gross_margin_contribution ?? 0,
                        "sales": teamContributionResult[key]?.senior?.sales ?? 0
                    }
                }
            }

            return dasS;
        }

        return getDasS();
    }
}
