/* eslint-disable max-len */
export const aggregateConsultants = (current: any, key: string) => {
    return current[key].Junior.ressources + current[key].Senior.ressources + current[key].Confirmé.ressources;
}

export const consultantsTotalPerStatus = (current: any, type: string) => {
    let juniorConsultantsTotal = 0;
    let seniorConsultantsTotal = 0;
    let confirmedConsultantsTotal = 0;

    for (const key in current) {
        juniorConsultantsTotal += current[key].Junior.ressources;
        seniorConsultantsTotal += current[key].Senior.ressources;
        confirmedConsultantsTotal += current[key].Confirmé.ressources;
    }

    return type === "junior" ? juniorConsultantsTotal : type === "senior" ? seniorConsultantsTotal : confirmedConsultantsTotal;
}

export const etpTotalPerStatus = (current: any, type: string) => {
    let juniorETPTotal = 0;
    let seniorETPTotal = 0;
    let confirmedETPTotal = 0;

    for (const key in current) {
        juniorETPTotal += current[key].Junior.etp;
        seniorETPTotal += current[key].Senior.etp;
        confirmedETPTotal += current[key].Confirmé.etp;
    }

    return type === "junior" ? juniorETPTotal : type === "senior" ? seniorETPTotal : confirmedETPTotal;
}

export const aggregateETP = (current: any, key: string) => {
    return current[key].Junior.etp + current[key].Senior.etp + current[key].Confirmé.etp;
}

export const aggregateConsultantsTotal = (current: any) => {
    let consultantsTotal = 0;

    for (const key in current) {

        consultantsTotal += current[key].Junior.ressources + current[key].Senior.ressources + current[key].Confirmé.ressources;
    }

    return consultantsTotal

}
export const aggregateEtpTotal = (current: any) => {
    let etpTotal = 0;

    for (const key in current) {
        etpTotal += current[key].Junior.etp + current[key].Senior.etp + current[key].Confirmé.etp;
    }

    return etpTotal

}

export const aggregateDasData = (current: any, key: string) => {
    const dataDas = [
        {
            type: 'Junior:',
            value: current[key].Junior.ressources
        },
        {
            type: 'Confirmé:',
            value: current[key].Confirmé.ressources
        },
        {
            type: 'Sénior:',
            value: current[key].Senior.ressources
        }
    ];

    return dataDas;
}

export const aggregateDasDataETP = (current: any, key: string) => {
    
    const dataDas = [
        {
            type: 'Junior:',
            value: current[key].Junior.etp
        },
        {
            type: 'Confirmé:',
            value: current[key].Confirmé.etp
        },
        {
            type: 'Sénior:',
            value: current[key].Senior.etp
        }
    ];

    return dataDas;
}

