import OfficeLoadTabChildren from "../../components/productionTab/OfficeLoadTabChildren";
import DasLoadTabChildren from "../../components/productionTab/DasLoadTabChildren";
import { aggregateLoadPerDas, aggregateTotalLoad, aggregateOfficeLoadChildrenData } from "../../functions/aggregateLoad";

export const dasTabConfig = (productionLoad, selectedLoad, setSelectedLoad, users) => {

    const officeLoadChildrenData = aggregateOfficeLoadChildrenData(productionLoad);

    let tabs = [
        {
            id: 1,
            label: 'Cabinet',
            optionalAction: () => setSelectedLoad({
                selected: "Cabinet",
                load: Math.round(aggregateTotalLoad(productionLoad))

            }),
            component: <OfficeLoadTabChildren
                officeLoadChildrenData={officeLoadChildrenData}
            />

        }
    ];

    const copy = {...productionLoad.load};
    delete copy.available_load;
    delete copy.billing;

    for (const key in copy) {
        const item =                 {
            id: key,
            label: key,
            optionalAction: () => setSelectedLoad({
                selected: key,
                load: aggregateLoadPerDas(productionLoad, key)

            }),
            component: <DasLoadTabChildren
                selectedLoad={selectedLoad}
                productionLoad={productionLoad}
                das={key}
                users={users}
            />

        }
    
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
    
