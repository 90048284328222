import React, {useState} from 'react';
import {DatePicker} from 'antd';
import {ConfigProvider} from 'antd';
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './datepicker.css';
import dateInputSuffix from './dateInputSuffix.svg';

/**
 * @author Marcin
**/

const DateInput = ({
    onChange,
    value,
    label,
    required,
    error,
    disabled,
    width = '',
    labelWidth = 'w-36'
}) => {
    const [localValue, setLocalValue] = useState(null);

    const handleChange = (e) => {
        if (!e) {
            setLocalValue(null);
        }
        else {
            setLocalValue(e);
            onChange(e);
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between w-full">
                <div className={`flex flex-row ${labelWidth}`}>
                    <label className="font-normal text-sm text-[#646464]">{label}
                        {
                            required &&
                            <span className="ml-1 text-sm font-normal text-red-500">*</span>
                        }
                    </label>
                </div>
                <ConfigProvider locale={locale}>
                    <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        className={`dateInput ${width}`}
                        value={value === 'Invalid Date' || value === null || value === undefined ? null : localValue ? localValue : dayjs(value, 'DD/MM/YYYY')}
                        defaultValue={null}
                        onChange={handleChange}
                        format="DD/MM/YYYY"
                        disabled={disabled}
                        suffixIcon={<img src={dateInputSuffix} alt='icon'/>}
                    />
                </ConfigProvider>
            </div>
            {error &&
            <div className="text-xs italic text-red-500">
                {error.message}
            </div>
            }
        </div>
    );
};

export default DateInput;
