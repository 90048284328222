import * as z from 'zod';
import * as invoiceFunctions from './functions';

export const InvoiceSchema = z.object({
    'id': z.number(),
    'customer': z.object({
        "id": z.number(),
        "location": z.string(),
        "category": z.string(),
        "number_deals": z.number(),
        "name": z.string(),
        "notes": z.string()
    }),
    'contractors': z.any(),
    'deal': z.any(),
    'items': z.any(),
    'bank': z.any(),
    'credit': z.boolean().optional(),
    'payment_method': z.string().nullable(),
    'subsidiary': z.string().optional(),
    'type': z.number(),
    'status': z.number(),
    'billing_address': z.string(),
    'billing_date': z.string(),
    'due_date': z.string().nullable(),
    'payment_date': z.string().nullable(),
    'billable': z.string().optional(),
    'billable_vat_rate': z.string().optional(),
    'expenses': z.string().optional(),
    'expenses_vat_rate': z.string().optional(),
    'partial_payment': z.string().optional(),
    'notes': z.string().optional(),
    'temporary_id': z.number().optional().nullable(),
    'official_id': z.number().optional().nullable(),
    'cancels': z.number().nullable()});

export const InvoicePartialSchema = InvoiceSchema.partial();
export type InvoicePartial = z.infer<typeof InvoicePartialSchema>;
export type Invoice = z.infer<typeof InvoiceSchema>;

export function newInvoice(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...InvoiceSchema.parse({...data}),
        ...invoiceFunctions
    };
}

export function parseInvoice(data: any, partial = false) {
    if (!partial) {
        return {
            ...InvoiceSchema.parse(data),
            ...invoiceFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...InvoicePartialSchema.parse(data),
            ...invoiceFunctions
        };
    }
}
