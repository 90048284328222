import {ACTION_CODE} from './_actionCode';
import {VehicleTypeService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_VEHICLETYPE';

export async function createVehicleType(userData: Record<string, any>, vehicleType: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createVehicleType - Start');

        const createVehicleTypeResult = await VehicleTypeService.createVehicleType(userData, vehicleType);

        LoggerService.logInfo('Action - createVehicleType - End - Success');

        return ResultModel.newSuccessResult(createVehicleTypeResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createVehicleType - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une banque.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
