/* eslint-disable max-len */
import {DashboardFilled, CalendarFilled, CarryOutFilled} from '@ant-design/icons';
import {aggregateBilledRate, aggregateBilledDays, aggregateProducedDays, producedDaysTotal, billedDaysTotal, billedRateTotal} from '../../functions/aggregateLoad';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';

const InvoiceCards = ({productionLoad}) => {
    const DashboardIcon = <DashboardFilled style={{ fontSize: '30px', color:'white' }}/>;
    const CalendarIcon = <CalendarFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const CarryOutIcon = <CarryOutFilled style={{ fontSize: '30px', color: 'white' }}/>;
    
    const cardDataBilled = aggregateBilledDays(productionLoad);
    
    const cardDataBilledTotal = billedDaysTotal(cardDataBilled);
    
    const cardDataProduced = aggregateProducedDays(productionLoad);
    
    const cardDataProducedTotal = producedDaysTotal(cardDataProduced);
    
    const cardDataBilledRate = aggregateBilledRate(productionLoad);

    const cardDataBilledRateTotal = billedRateTotal(cardDataBilledRate);

    return (
        <div className='flex justify-between mt-6'>
            <SimpleCard
                width='w-[32%]'
                title={cardDataBilledRateTotal + " %"}
                subTitle="Taux de facturation"
                iconComponent={DashboardIcon}
                topComponent={<TopComponent data={cardDataBilledRate} suffix={" %"}/>}
            />

            <SimpleCard
                width='w-[32%]'
                title={cardDataBilledTotal}
                subTitle="Jours facturés"
                iconComponent={CalendarIcon}
                topComponent={<TopComponent data={cardDataBilled}/>}
            />

            <SimpleCard
                width='w-[32%]'
                title={cardDataProducedTotal}
                subTitle="Jours facturables"
                iconComponent={CarryOutIcon}
                topComponent={<TopComponent data={cardDataProduced}/>}
            />
        </div>
    )
}

export default InvoiceCards;

const TopComponent = ({data, suffix = ""}) => {
    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            value: data[key].toFixed()
        })
    }

    return (
        <div className='flex justify-between bg-white rounded-b-md'>
            { dasS.map((el, index) => {
                return(                         <div
                    key={index}
                    className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                >
                    <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                        <p className='text-gradient'>{el.das}</p>
                    </div>
                    <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{el.value + suffix}</p>
                </div>)
            })}

            <div className='h-auto w-[1px] bg-gray-200'/>
        </div>
    )
}