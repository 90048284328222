import {ACTION_CODE} from './_actionCode';
import {ProjectsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'GET_ALL_PROJECTS';

export async function getAllProjects(token = "", page = 1, params = "") {
    try {
        LoggerService.logInfo('Action - getAllProjects - Start');

        const result = await ProjectsService.getAllProjects(token, page, params);

        const {data, metadata} = result;

        LoggerService.logInfo('Action - getAllProjects - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllProjects - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des projets',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
