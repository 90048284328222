import {ACTION_CODE} from './_actionCode';
import {UserService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_USER';

export async function createUser(userData: Record<string, any>, user: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createUser - Start');

        const createUserResult = await UserService.createUser(userData, user);

        LoggerService.logInfo('Action - createUser - End - Success');

        return ResultModel.newSuccessResult(createUserResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createUser - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘un user.',
            error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
