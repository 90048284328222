import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'CREATE_INVOICE';

export async function createInvoice(
    userToken: string,
    newInvoice: Record<string, unknown>
) {
    try {
        const response = await axios({
            method: 'post',
            url: `${URL}/invoices/`,
            data: newInvoice,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur de création d\'une facture',
            error: error
        });
    }
}
