import {AttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_ATTACHMENTS';

export async function getAllAttachments(userData: Record<string, string>, optionalProps?: Record<string, unknown>) {

    try {
        const attachments =  await AttachmentDao.getAllAttachments(userData.userToken, optionalProps);

        return attachments
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
