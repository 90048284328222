import {ACTION_CODE} from './_actionCode';
import {ContractorService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_CUSTOMER_CATEGORY_BY_ID';

export async function getContractorById(userData: Record<string, any>, contractorId: number) {
    try {
        LoggerService.logInfo('Action - getContractorById - Start');

        const getContractorByIdResult = await ContractorService.getContractorById(userData, contractorId);

        LoggerService.logInfo('Action - getContractorById - End - Success');

        return ResultModel.newSuccessResult(getContractorByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - getContractorById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération d\'une catégorie client',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
