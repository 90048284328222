import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import DonutsChart from "../../../../components/ui/DonutsChart/DonutsChart";
import {productionByEtpChartConfig} from "../../configs/resultIndicatorTab/productionByEtpSection/productionByEtpChartConfig";
import {aggregateETPmMarginPerRole} from "../../../codevDashboardPage/functions/aggregateETPMargin";

const ProductionByEtpChart = ({data, dasKey}) => {
    const noData = [
        {
            type: 'Junior:',
            value: 0
        },
        {
            type: 'Confirmé:',
            value: 0
        },
        {
            type: 'Sénior:',
            value: 0
        }
    ];

    return (
        <ContainerCard showHeader={false}>
            <DonutsChart
                data={data[dasKey] ? aggregateETPmMarginPerRole(data, dasKey) : noData}
                config={productionByEtpChartConfig}
                label="€"
            />
        </ContainerCard>
    )
};

export default ProductionByEtpChart;
