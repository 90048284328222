import {ReferenceDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_REFERENCE';

export async function updateReference(
    userData: Record<string, string>,
    updatedReference: Record<string, any>,
    id: string
) {

    try {
        const result = await ReferenceDao.updateReference(userData.userToken, updatedReference, id);

        return result;
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mis à jour de la référence.',
            error: error
        });
    }
}
