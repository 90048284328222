import {ExpenseAttachmentDao} from '../../dao';
import {ErrorModel, ExpenseAttachmentModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_EXPENSE_ATTACHMENTS';

export async function getAllExpenseAttachments(userData: Record<string, string>, reportId: string) {
    try {
        const expenses = await ExpenseAttachmentDao.getAllExpenseAttachments(userData.userToken, reportId);

        const expenseParsed = expenses.results.map((expenseAttachment: Record<string, any>) => ExpenseAttachmentModel.parseExpenseAttachment(expenseAttachment));

        return expenseParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des attachments de frais',
            error: error
        });
    }
}
