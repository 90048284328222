import {useState, useEffect} from 'react';

import {notify} from '../../../../components/ui/Toast/Toast';
import Popup from '../../../../components/ui/Popup';
import PersonalInformationForm from './PersonalInformationForm';
import ContractForm from './ContractForm';
import {ContractActions} from '../../../../actions';

const StaffingPopup = ({
    view,
    setView,
    staffingItemToBeUpdated,
    setStaffingItemToBeUpdated,
    setStaffingUserToBeUpdated,
    staffingUserBeUpdated,
    userData}) => {
    const [tabSelected, setTabSelected] = useState(0);
    const [contracts, setContracts] = useState([]);

    const getContracts = async (ressourceId) => {
        const result = await ContractActions.getAllContracts(userData, `ressource=${ressourceId}`);

        if (result.success) {
            setContracts(result.data.results);
        }
        else {
            notify('Impossible de récupérer les contrats pour cette ressource.');
        }
    };

    useEffect(() => {
        if (staffingItemToBeUpdated && staffingUserBeUpdated) {
            getContracts(staffingItemToBeUpdated.ressource.username);
        }
    }, [staffingItemToBeUpdated, staffingUserBeUpdated]);

    const refreshContracts = async (ressourceId) => {
        await getContracts(ressourceId);
    }

    useEffect(() => {
        !view ? setTabSelected(0) : null;
    }, [view]);
   
    return (
        <Popup view={view} setView={setView} maxheight='h-[400px]' width='80%'>
            {
                tabSelected === 0 ?
                    <PersonalInformationForm
                        setTabSelected={setTabSelected}
                        setView={setView}
                        staffingUserToBeUpdated={staffingUserBeUpdated}
                        setStaffingItemToBeUpdated={setStaffingItemToBeUpdated}
                        setStaffingUserToBeUpdated={setStaffingUserToBeUpdated}
                        setContracts={setContracts}
                        userData={userData}
                    />
                    : null
            }
            {
                tabSelected === 1 ?
                    <ContractForm
                        setTabSelected={setTabSelected}
                        setView={setView}
                        contracts={contracts}
                        userData={userData}
                        refreshContracts={refreshContracts}
                        staffingItemToBeUpdated={staffingItemToBeUpdated}
                    />
                    : null
            }
        </Popup>
    );
}

export default StaffingPopup;
