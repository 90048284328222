import {ACTION_CODE} from './_actionCode';
import {InvoiceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_INVOICE_BY_ID';

export async function getInvoiceById(userData: Record<string, any>, invoiceId: number) {
    try {
        LoggerService.logInfo('Action - getInvoiceById - Start');

        const getInvoiceByIdResult = await InvoiceService.getInvoiceById(userData, invoiceId);

        LoggerService.logInfo('Action - getInvoiceById - End - Success');

        return ResultModel.newSuccessResult(getInvoiceByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - getInvoiceById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
