import {useState} from 'react';

import { TrainingAttachmentActions,  FileActions} from './../../../../actions'
import FileDragNDropInput from './../../../../components/ui/Inputs/FileDragNDropInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import checkboxIcon from "../../../../assets/icons/blueStyle/checkbox-input-blue.png";
import checkboxEmptyIcon from "../../../../assets/icons/page/checkbox-empty.png";
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const AdministrativeAttachmentsPopup = ({
    setView,
    userData,
    refreshData,
    trainingId,
    proposalAttachements,
    agreementAttachements,
    otherAdminAttachements
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [agreement, setAgreement] = useState([]);
    const [otherAdmin, setOtherAdmin] = useState([]);
    const [proposal, setProposal] = useState([]);
    const [errors, setErrors] = useState(null);

    const onSubmit = async () => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newAgrement = agreement.map(file => ({file: file, name: file.name, type: 'agreement'}));
        const newOtherAdmin = otherAdmin.map(file => ({file: file, name: file.name, type: 'other_admin'}));
        const newProposal = proposal.map(file => ({file: file, name: file.name, type: 'proposal'}));

        const allFiles = [...newAgrement, ...newOtherAdmin, ...newProposal];

        if (allFiles.length > 0) {
            let filesResult = [];

            for (let file of allFiles) {
                const newFile = {
                    "type": file.file.type,
                    "name": file.name,
                    "file": file.file
                }

                const result = await FileActions.createFile(userData, newFile);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    filesResult.push({...result.data, type: file.type});
                }
            }

            await Promise.all(filesResult.map(async file => {
                const payload = {
                    file_id: file.id,
                    training_id: trainingId,
                    type: file.type
                };

                const result = await TrainingAttachmentActions.createTrainingAttachment(userData, payload);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    await refreshData();
                    notify(`Les documents ont été chargés avec succès.`);
                    setView(false);
                }
            }));

            setDisabled(false);
            setLoading(false);
        }
    }

    const handleChangeProposal = (file) => {
        setProposal(file);
    }

    const handleChangeAgreement = (file) => {
        setAgreement(file);
    }

    const handleChangeOther_admin = (file) => {
        setOtherAdmin(file);
    }

    return (
        <ContainerCard
            coloredButtonDisplayed={false}
            title="Checklist qualité - Administratif"
        >
            <div className="flex flex-col pt-6 mb-5 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(proposalAttachements.length || proposal.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Propositon commerciale</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeProposal}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col pt-6 mt-6 mb-5 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(agreementAttachements.length || agreement.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Convention signée avec le client</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeAgreement}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col pt-6 mt-6 mb-8 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(otherAdminAttachements.length || otherAdmin.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Autres</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeOther_admin}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'blue'}
                        content={`Ajouter`}
                        onClick={onSubmit}
                        loading={loading}
                        disabled={!(proposal.length || agreement.length || otherAdmin.length)}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default AdministrativeAttachmentsPopup;
