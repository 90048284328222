import {ACTION_CODE} from './_actionCode';
import {VehicleTypeService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_VEHICLETYPES';

export async function getAllVehicleTypes(
    userData: Record<string, string>,
    params = "",
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllVehicleTypes - Start');

        const getAllVehicleTypesResult = await VehicleTypeService.getAllVehicleTypes(userData, params, page);

        LoggerService.logInfo('Action - getAllVehicleTypes - End - Success');

        return ResultModel.newSuccessResult(getAllVehicleTypesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllVehicleTypes - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des frais .',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
