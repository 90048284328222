import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'EXPORTS_ONGOING';

export async function exportOngoing(userData: Record<string, string>, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - exportOngoing - Start');

        const exportOngoingResult = await DashboardService.AdminService.exportOngoing(userData, payload);

        LoggerService.logInfo('Action - exportOngoing - End - Success');

        return ResultModel.newSuccessResult(exportOngoingResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - exportOngoing - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
