export const expenseAnalysisRightChartConfig = (data, colors, legendLabels) => {
    return {
        appendPadding: 35,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        color: colors,
        innerRadius: 0.8,
        legend: {
            layout: 'horizontal',
            position: 'bottom',
            itemWidth: 200,
            items: legendLabels,
            maxRow: 2,
            flipPage: false
        },
        label: false,
        interactions: [
            {
                type: 'element-selected'
            },
            {
                type: 'element-active'
            }
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Din Condensed',
                    color: '#030303',
                    fontSize: '28px'
                },
                content: '<div>60 000 €<br><br>Frais ventilés par type</div>'
            }
        }
    };
}
