import {ACTION_CODE} from './_actionCode';
import {AttachmentService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_ATTACHEMENT';

export async function deleteAttachment(userData: Record<string, any>, id: string) {
    try {
        LoggerService.logInfo('Action - deleteAttachment - Start');

        const deleteAttachmentResult = await AttachmentService.deleteAttachment(userData, id);

        LoggerService.logInfo('Action - deleteAttachment - End - Success');

        return ResultModel.newSuccessResult(deleteAttachmentResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteAttachment - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'un frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
