import React, {useRef, useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {UserActions} from '../../../actions';
import {useDispatch} from 'react-redux';

const User = ({userProfileData}) => {
    const [show, setShow] = useState(false);
    const ref = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = () => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            if (ref.current) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const handleDisconnect = async () => {
        const disconnectUser = await UserActions.logOut(dispatch);

        if (disconnectUser.success && !userProfileData) {
            navigate('/login');
        }
    };

    return (
        <div onClick={() => setShow(true)} className="relative flex items-center justify-center w-10 h-10 bg-orange-200 rounded-full cursor-pointer">
            <p className="text-orange-600 uppercase">{userProfileData.userProfileName}</p>
            {show && (
                <div ref={ref} className={'absolute w-44 top-11 -right-5 bg-white rounded-md shadow-lg'}>
                    <div className="py-1">
                        <Link to="/account">
                            <div className="block py-2 pl-10 pr-2 text-sm text-[#212121] hover:bg-[#f9e2d7] hover:border-r-2 hover:text-[#E36D38] hover:border-[#E36D38]">Mon compte</div>
                        </Link>
                        <div
                            onClick={() => {handleDisconnect();}}
                            className="block py-2 pl-10 pr-2 text-sm text-[#212121] hover:bg-[#f9e2d7] hover:border-r-2 hover:text-[#E36D38] hover:border-[#E36D38]"
                        >
                            Se déconnecter
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default User;
