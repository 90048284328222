import {PaymentMethodDao} from '../../dao';
import {ErrorModel, PaymentMethodModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_PAYMENT_METHODS';

export async function getAllPaymentMethods(
    userData: Record<string, string>,
    params: string,
    page: number
) {
    try {
        const paymentMethods = await PaymentMethodDao.getAllPaymentMethods(userData.userToken, params, page);

        const paymentMethodParsed = paymentMethods.results.map((paymentMethod: Record<string, any>) => PaymentMethodModel.parsePaymentMethod(paymentMethod));

        return paymentMethodParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
