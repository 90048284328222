import {ACTION_CODE} from './_actionCode';
import {ActivityTypesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'GET_ALL_ACTIVITY_TYPES';

export async function getAllActivityTypes(
    userData: Record<string, string>,
    params = "",
    page = 1,
) {
    try {
        LoggerService.logInfo('Action - getAllActivityTypes - Start');

        const result = await ActivityTypesService.getAllActivityTypes(userData, params, page);

        const {data, metadata} = result;

        LoggerService.logInfo('Action - getAllActivityTypes - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllActivityTypes - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des activités',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
