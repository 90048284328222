import {ACTION_CODE} from './_actionCode';
import {InvoiceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CANCEL_INVOICE';

export async function cancelInvoice(userData: Record<string, any>, invoicePayload: any, id: number) {
    try {
        LoggerService.logInfo('Action - cancelInvoice - Start');

        const cancelInvoiceResult = await InvoiceService.cancelInvoice(userData, invoicePayload, id);

        LoggerService.logInfo('Action - cancelInvoice - End - Success');

        return ResultModel.newSuccessResult(cancelInvoiceResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - cancelInvoice - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de l\'annulation d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
