import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'UPDATE_PRODUCTORIGIN_BY_ID';

export async function updateTrainingProductOrigin(
    userToken: string,
    id: number,
    update: Record<string, unknown>
) {
    try {
        const response = await axios({
            method: 'patch',
            url: `${URL}/training-product-origins/${id}/`,
            data: update,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur de modification',
            error: error
        });
    }
}
