import React from 'react';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Badge from '../../../../components/ui/Badge';

const DealInformationsCard = ({data}) => {
    return (
        <ContainerCard
            title='Information de l’affaire'
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[290px]'
            margin='mx-9'
        >
            <div className='flex flex-col'>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Nom complet</div>
                    <div className='text-[#212121] text-base'>{data?.deal?.name}</div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Client</div>
                    <div className='text-[#212121] text-base'>{data?.deal?.customer.name}</div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Domaine d’activité</div>
                    <div className='text-[#212121] text-base'>
                        <Badge content={data?.deal ? data.deal.das : []} color='#646464'/>
                    </div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Adresse de facturation</div>
                    <div className='text-[#212121] text-base'>TEMPORAIRE 12 rue John Doe 75000 - Paris</div>
                </div>
            </div>
        </ContainerCard>
    )
}

export default DealInformationsCard
