import {DashboardActions} from "../../../actions";
import {UserActions} from "../../../actions";
import {standardFormat} from "../../../utils";
import {firstDayMonth, lastDayMonth} from "../../../utils";

export const getProductionTabData = async (
    userData,
    notify,
    setDasUsers,
    loadIndicatorsDatePicker,
    setProductionLoad,
    setProductionLoading
) => {

    // ↓ «Indicateurs de charges» data
    const usersResult = await UserActions.getAllSortedUsers(userData, "");

    if (usersResult.success) {
        setDasUsers(usersResult.data.results);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des utilisateurs DAS");
    }

    const productionLoadResult = await DashboardActions.CodevActions.getProductionLoad(
        userData,
        `?after=${standardFormat(loadIndicatorsDatePicker.startDate)}&before=${standardFormat(loadIndicatorsDatePicker.endDate)}`
    );

    if (productionLoadResult.success) {
        setProductionLoad(productionLoadResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des indicateurs de charges");
    }

    setProductionLoading(false);
}

export const handleLoadIndicatorsDatePickerChange = async (
    e,
    userData,
    notify,
    setLoading,
    setLoadIndicatorsDatePicker,
    setDasUsers,
    setProductionLoad
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setLoadIndicatorsDatePicker(dates);

    const usersResult = await UserActions.getAllSortedUsers(userData, "");

    if (usersResult.success) {
        setDasUsers(usersResult.data.results);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des utilisateurs DAS");
    }

    const productionLoadResult = await DashboardActions.CodevActions.getProductionLoad(
        userData,
        `?after=${standardFormat(dates.startDate)}&before=${standardFormat(dates.endDate)}`
    );

    if (productionLoadResult.success) {
        setProductionLoad(productionLoadResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des indicateurs de charges");
    }

    setLoading(false);
};
