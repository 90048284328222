import {useState, useEffect} from 'react';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {frenchFormat, handleFilterList, useFilterQuery, fetchSelectData} from '../../../../utils';
import ActionsButton from '../../../../components/ui/ActionsButton';
import Badge from '../../../../components/ui/Badge';
import {UserActions, ExpenseTypeActions} from '../../../../actions';
import {SelectInput} from './../../../../components/ui/Table';

export const dealExpensesTableConfig = (
    handleEditExpenseClick,
    handleDeleteExpenseClick,
    buttonsDisabled,
    deleteExpenseLoading,
    blueStyle,
    filterInvoiceList,
    userData,
    cannotBeModified
) => {
    const [types, setTypes] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        type: '',
        credit: false
    });

    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterInvoiceList);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    };

    const handleGetExpenseTypes = async (search, page = 1) => {
        await fetchSelectData({action: ExpenseTypeActions.getAllExpenseTypes, search:`&search=${search}`, page: page, setter: setTypes, userData, resultPath: 'data'});
    }

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        });

        // ↓ expense types dropdown values
        (async () => {
            await handleGetExpenseTypes('', 1);
        });
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Ressource',
                    value: 'ressource.username',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        if(!item?.ressource) return;

                        return (
                            <div className="flex flex-row items-center justify-center w-full text-center">
                                <Badge type="round" content={[item.ressource]}/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="ressource"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'type.group',
                    width: 'w-[21%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.type.name}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="type"
                        options={types?.options}
                        hasNextPage={types?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetExpenseTypes)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Refacturable',
                    value: 'billable',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.billable ? 'Oui' : 'Non'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant',
                    value: 'amount',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(parseInt(item.amount).toLocaleString())}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'TVA',
                    value: 'vat',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(item.vat)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: null,
                    width: 'w-[15%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.notes.length > 0 ? item.notes : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.parentStatus === 3 || cannotBeModified ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </>
                                        :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => handleEditExpenseClick(item)}
                                                disabled={buttonsDisabled}
                                                blueStyle={blueStyle}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteExpenseLoading === item.id}
                                                onClick={() => handleDeleteExpenseClick(item)}
                                                disabled={buttonsDisabled}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce frais ?'
                                                blueStyle={blueStyle}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
