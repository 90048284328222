import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { fetchSelectData, frenchFormat, getErrors, standardFormat } from '../../../../../src/utils';
import { ExpenseActions, ExpenseTypeActions } from '../../../../actions';
import Button from '../../../../components/ui/Button/Button';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import CheckboxInput from '../../../../components/ui/Inputs/CheckboxInput/CheckboxInput';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';

function SpendingExpensePopup({
    userData,
    dealData,
    expenseToBeUpdated,
    switchForm,
    setSwitchForm,
    setView,
    refreshData,
    blueStyle
}) {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [types, setTypes] = useState(null);

    const vat = useSelector((state) => state.GlobalsReducer.vat);

    // -- react hook form --
    const defaultValues = {
        date: dayjs(),
        billable: false,
        vat: vat,
        deal: '',
        //ressource: '',
        amount: null,
        co2: null,
        type: null,
        notes: ''
    };
    const {handleSubmit, control, reset, watch, formState: {isValid}, setValue} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        const type = watch('type');
        
        setSwitchForm(type);
    }, [watch('type')]);

    // -- fill expense values in form for update --
    useEffect(() => {
        if (expenseToBeUpdated?.id) {
            reset({
                date: dayjs(expenseToBeUpdated.date),
                billable: expenseToBeUpdated.billable,
                vat: (+expenseToBeUpdated.vat).toString(),
                deal: expenseToBeUpdated.deal,
                ressource: expenseToBeUpdated.ressource,
                amount: (+expenseToBeUpdated.amount).toString(),
                co2: (+expenseToBeUpdated.co2).toString(),
                type: expenseToBeUpdated.type,
                notes: expenseToBeUpdated.notes
            });
        }
        else {
            reset({type: switchForm});
        }
    }, [expenseToBeUpdated]);

    // const ressources = dealData?.producers?.map((item) => ({
    //     ...item, name: item.first_name + ' ' + item.last_name
    // }))

    // -- functions that get data for selects input --
    const handleGetTypes = async (search, page = 1) => {
        await fetchSelectData({action: ExpenseTypeActions.getAllExpenseTypes, search:`&search=${search}`, page: page, setter: setTypes, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ expense types dropdown values
        (async () => {
            await handleGetTypes('', 1);
        })();
    }, []);

    // -- handle submit --
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        // ↓ body to send to the post/patch request (see API documentation)
        const expense = {
            deal_id: dealData.id,
            type: data.type,
            type_name: data.type.name,
            date: standardFormat(data.date),
            billable: data.billable,
            amount: data.amount,
            vat: data.vat,
            notes: data.notes,
            report: null
        }

        if (data.co2) {
            expense.co2 = data.co2;
        }

        // ⓘ if it is a new expense, the «report» key is assigned in «createExpense.ts» file

        // ↓ post - new expense
        if (!expenseToBeUpdated) {

            const result = await ExpenseActions.createDealExpense(userData, expense);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre frais à bien été créé');
                setView(false);
            }

            setLoading(false);
            setDisabled(false);
        }
        // ↓ patch - update kilometric expense
        else {
            const result = await ExpenseActions.updateDealExpense(userData, expenseToBeUpdated.id, expense);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre frais à bien été modifié');
                setView(false);
            }

            setLoading(false);
            setDisabled(false);
        }
    };

    return (
        <ContainerCard
            title={expenseToBeUpdated?.id ? 'Éditer un frais' : 'Nouveau frais'}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-4">
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="type"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un type de frais.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type de frais'
                                    placeholder='Type de frais'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={types ? types.options.filter((item) => item.name !== 'Indemnités kilométriques') : []}
                                    hasNextPage={types?.hasNextPage || false}
                                    onChange={onChange}
                                    isSearchable={true}
                                    fetchFunction={handleGetTypes}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2 w-fit pl-36">
                        <Controller
                            name="billable"
                            control={control}
                            render={({
                                field: {onChange, value}
                            }) => (
                                <CheckboxInput
                                    uniqueName="billable"
                                    label={'Refacturable'}
                                    onChange={onChange}
                                    value={value}
                                    defaultChecked={value}
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    {/* <div className="mb-2">
                        <Controller
                            name="ressource"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir une ressource.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Ressource'
                                    placeholder='Ressource'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={ressources}
                                    onChange={onChange}
                                    isSearchable={true}
                                    loading={!ressources}
                                    labelKeys={['first_name', 'last_name']}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div> */}
                </div>
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="vat"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir la TVA.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'TVA récupérable [€]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="TVA"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="amount"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir un montant.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Montant TTC [€]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Montant TTC"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="co2"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Qté CO2 (g)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={false}
                                    disabled={disabled}
                                    placeholder="Qté CO2 (g)"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            disabled={disabled}
                            color={blueStyle ? 'blue' : 'orange'}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={expenseToBeUpdated?.id ? 'Modifier le frais' : 'Ajouter le frais'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!types || !isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default SpendingExpensePopup;
