import {InvoiceDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_EXPENSE_REPORT';

export async function createInvoice(
    userData: Record<string, string>,
    invoice: Record<string, any>
) {
    try {
        const result = await InvoiceDao.createInvoice(userData.userToken, invoice);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
