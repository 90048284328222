import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {createBrowserHistory} from '@remix-run/router';

import Layout from '../../components/layout';
import CustomersDataSection from './components/customerComponents/CustomersDataSection';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import {customersTableConfig} from './configs/customersConfigs/customersTableConfig';
import {CustomerActions} from '../../actions';
import Popup from '../../components/ui/Popup/Popup';
import {firstDayYear, lastDayYear, standardFormat} from '../../utils';
import CustomerPopup from './popups/customerPopup/CustomerPopup';

function CustomersPage() {
    // states
    const [customersDataTable, setCustomersDataTable] = useState([]);
    const [customerPaginationMetadata, setCustomerPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [customerFilterQuery, setCustomerFilterQuery] = useState('');
    const [loadingTable, setLoadingTable] = useState(false);
    const [updateCustomer, setUpdateCustomer] = useState(null);
    const [datePickerDate, setDatePickerDate] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [customerPopupView, setCustomerPopupView] = useState(false);

    // hooks
    const userData = useSelector((state) => state.UserReducer.userProfileData);
    const navigate = useNavigate();
    const location = useLocation();
    const history = createBrowserHistory();

    // fetch customers data
    const handleGetAllCustomers = async (page, activeLoading = true) => {
        if (activeLoading) {
            setLoadingTable(true);
            setCustomersDataTable([]);
        }

        const datePickerBefore = standardFormat(new Date(datePickerDate.endDate));
        const datePickerAfter = standardFormat(new Date(datePickerDate.startDate));

        const result = await CustomerActions.getAllCustomers(
            userData,
            `page_size=${customerPaginationMetadata.pageSize}&billing_before=${datePickerBefore}&billing_after=${datePickerAfter}&${customerFilterQuery}`,
            page
        );

        if (result.success) {
            setCustomersDataTable(result.data);
            setCustomerPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (customerPaginationMetadata.totalItems !== result.metadata.count) {
                setCustomerPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des clients');
        }

        setLoadingTable(false);
    }

    const refreshCustomerData = async () => {
        await handleGetAllCustomers(customerPaginationMetadata.currentPage, false);
    }

    useEffect(() => {
        handleGetAllCustomers(1);
    }, [customerFilterQuery]);

    const handleDatePickerChange = (e) => {
        setDatePickerDate({
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        });
    };

    // Check navigation message existence
    useEffect(() => {
        if (location.state === 'invalid_id') {
            notify('Client inexistant');
        }
        else if (location.state === 'deal_deleted') {
            notify('Client supprimé');
        }
        history.replace({...history.location, state: null});
    }, []);

    const handleCustomerNavigation = async (id) => {
        navigate(`${id}`)
    }

    const handleUpdateCustomer =  (customer) => {
        setUpdateCustomer(customer);
        setCustomerPopupView(true);
    }

    const filterCustomerList = async (query) => {
        setCustomerFilterQuery(query);
    }

    useEffect(() => {
        !customerPopupView ? setUpdateCustomer(null) : null;
    }, [customerPopupView]);

    // table config
    const tableConfig = customersTableConfig(
        handleCustomerNavigation,
        handleUpdateCustomer,
        userData,
        filterCustomerList
    );

    return (
        <Layout>
            <Toast/>
            <CustomersDataSection
                data={customersDataTable}
                tableConfig={tableConfig}
                tableLoading={loadingTable}
                handleButtonClick={() => setCustomerPopupView(true)}
                onDatePickerChange={handleDatePickerChange}
                datePickerValue={datePickerDate}
                customerPaginationMetadata={customerPaginationMetadata}
                handleGetAllCustomers={handleGetAllCustomers}
            />
            <Popup
                view={customerPopupView}
                setView={setCustomerPopupView}
                width={'1150px'}
                yOverflow=''
                xOverflow=''
            >
                <CustomerPopup
                    userData={userData}
                    updateCustomer={updateCustomer}
                    refreshdata={refreshCustomerData}
                    setView={setCustomerPopupView}
                />
            </Popup>
        </Layout>
    );
}

export default CustomersPage;
