import ContainerCard from '../../../../../components/ui/ContainerCard/ContainerCard';
import ActionsButton from '../../../../../components/ui/ActionsButton/ActionsButton';
import checkboxIcon from '../../../../../assets/icons/blueStyle/checkbox-input-blue.png';
import attachmentIcon from '../../../../../assets/icons/blueStyle/icon-attachment.png';
import checkboxEmptyIcon from '../../../../../assets/icons/page/checkbox-empty.png';

const QualityChecklist = ({setView, handleDelete, data, title, height = 'h-80', onClick, cannotBeModified}) => {
    return (
        <ContainerCard
            title={title}
            editButtonDisplayed={true}
            coloredButtonDisplayed={false}
            blueStyle={true}
            width='w-1/2'
            height={height}
            onEditButtonClick={() => setView(true)}
        >
            <>
                {data?.length && data.map((item, index) => (
                    <div key={`qualitycheck-${index}`} className='flex flex-col mb-6'>
                        <div className='flex items-center justify-start mb-2'>
                            <img src={item.data.length ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                            <div className='text-[#212121]'>{item.title}</div>
                        </div>

                        {item.data.map((item, index) => (
                            <div key={`agreement-${index}`} className='flex items-center justify-between'>
                                <div className="flex items-center justify-start">
                                    <img src={attachmentIcon} alt='check' className='w-3 mr-3'/>
                                    <p
                                        onClick={() => onClick(item.file.file)}
                                        target='_blank'
                                        className='text-sm font-normal text-[#01abab] cursor-pointer hover:underline' rel="noreferrer"
                                    >
                                        {item.file.name}
                                    </p>
                                </div>
                                {
                                    cannotBeModified ?
                                        null
                                        :
                                        <ActionsButton
                                            blueStyle={true}
                                            type='delete'
                                            onClick={() => handleDelete(item.id)}
                                            confirmationOnDelete={true}
                                            deleteConfirmationQuestion={`Êtes-vous sûr de vouloir supprimer la pièce jointe ${item.file.name} ?`}
                                        />
                                }
                            </div>
                        ))}
                    </div>
                ))}
            </>
        </ContainerCard>
    )
}

export default QualityChecklist;
