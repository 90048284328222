import {useState, useEffect, Fragment} from 'react';

import {ReferenceActions} from './../../../../../actions'
import {frenchFormat} from '../../../../../utils';
import ActionsButton from '../../../../../components/ui/ActionsButton';
import {notify} from '../../../../../components/ui/Toast/Toast';
import Button from '../../../../../components/ui/Button/Button';
import noDataIcon from '../../../../../assets/icons/page/no-data.png';
import Badge from '../../../../../components/ui/Badge';
import contactIcon from '../../../../../assets/icons/actionsbutton/contact.svg';

const ReferenceDetails = ({reference, setReferencePopUpView, userData, refreshData, setReference, blueStyle, cannotBeModified}) => {
    const [isPublishable, setIsPublishable] = useState(false);
    const [isViewable, setIsViewable] = useState(false);
    const [userIsOwner, setUserIsOwner] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (reference) {
            if(
                reference.title &&
                reference.actions &&
                reference.status &&
                reference.owner &&
                reference.name &&
                reference.context &&
                reference.budget &&
                reference.project_management &&
                reference.realisation_date &&
                reference.contacts.length &&
                reference.tags &&
                reference.customer_logo
            ) {
                setIsPublishable(true);
            }
            else {
                setIsPublishable(false);
            }

            if (
                reference.title ||
                reference.actions ||
                reference.name ||
                reference.context ||
                reference.budget ||
                reference.project_management ||
                reference.contact ||
                reference.realisation_date ||
                reference.contact_address ||
                reference.tags.length ||
                reference.customer_logo
            ) {
                setIsViewable(true);
            }
            else {
                setIsViewable(false);
            }

            setUserIsOwner(reference.owner.id === userData.userId);
        }
    }, [reference]);

    const handleChangeStatus = async (e) => {
        const payload = {
            status: e.target.value,
            tags: reference.tags
        }
        const result = await ReferenceActions.updateReference(userData ,payload, reference.id);

        if (!result.success) {
            notify('Une erreur est survenue pendant le changement de statut. Veuillez réessayer.')
        }
        else {
            setReference(result.data);
            notify(`Le statut de la réference a été modifier avec succès.`);
        }
    }

    const handleDeleteReference = async () => {
        setDeleteLoading(true);

        const result = await ReferenceActions.deleteReference(userData, reference.id);

        if (!result.success) {
            notify('Une erreur est survenue lors de la suppression de la référence. Veuillez réessayer.')
        }
        else {
            await refreshData(); // refresh the data of the deal
            notify('La référence bien été supprimée');
        }

        setDeleteLoading(false);
    }

    const handleDownloadReference = async () => {
        const result = await ReferenceActions.downloadReferenceById(userData, reference.id);

        if (!result.success) {
            notify('Une erreur est survenue pendant le téléchargement de la référence. Veuillez réessayer.')
        }
        else {
            const url = URL.createObjectURL(result.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = (reference.name || reference.title || 'reference') + '.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            notify(`La référence a été téléchargée avec succès.`);
        }
    }

    return (
        <div
            className='mb-6 rounded-lg'
            style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
        >
            {
                !isViewable ?
                    <div className='flex flex-col items-center justify-center py-6'>
                        <img src={noDataIcon} alt='no data' className='w-24 mb-4'/>
                        {
                            userIsOwner && !cannotBeModified ?
                                <Button
                                    content='Nouvelle référence'
                                    onClick={() => setReferencePopUpView(true)}
                                    type={blueStyle ? 'blue' : 'primary'}
                                />
                                : null
                        }
                    </div>
                    : <div>
                        <div className='flex items-center justify-between px-6 border-b border-gray-200 h-14'>
                            <p style={{ fontFamily: 'DIN Condensed' }} className='text-xl'>{reference.name ?? '-'}</p>
                            <div className='flex gap-x-3'>
                                <div className='p-1 border border-gray-300'>
                                    <select className='w-32 pr-4 outline-none cursor-pointer' onChange={handleChangeStatus}>
                                        {
                                            isPublishable ?
                                                <option
                                                    selected={reference.status === 'published'}
                                                    value='published'
                                                >
                                                    Publié
                                                </option>
                                                : null
                                        }
                                        <option
                                            selected={reference.status === 'draft'}
                                            value='draft'
                                        >
                                            Brouillon
                                        </option>
                                        <option
                                            selected={reference.status === 'archived'}
                                            value='archived'
                                        >
                                            Archivé
                                        </option>
                                    </select>
                                </div>
                                <div className='flex items-center justify-between gap-x-3'>
                                    {
                                        reference?.customer_logo && !cannotBeModified ?
                                            <ActionsButton
                                                type='download'
                                                blueStyle={blueStyle}
                                                onClick={handleDownloadReference}
                                            />
                                            : null
                                    }
                                    {
                                        userIsOwner && !cannotBeModified ?
                                            <>
                                                <ActionsButton
                                                    type='edit'
                                                    onClick={() => setReferencePopUpView(true)}
                                                    blueStyle={blueStyle}
                                                />
                                                <ActionsButton
                                                    type='delete'
                                                    onClick={handleDeleteReference}
                                                    deleteConfirmationQuestion='Êtes-vous sûr de vouloir supprimer cette référence ?'
                                                    blueStyle={blueStyle}
                                                    isLoading={deleteLoading}
                                                />
                                            </>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='px-6 py-4'>
                            <div className='flex items-center justify-between'>
                                <p>
                                    <span className='mr-4 text-sm text-gray-400'>Titre</span>
                                    {reference.title ?? '-'}
                                </p>
                                <p className='w-[15%]'>
                                    <span className='mr-4 text-sm text-gray-400'>Réalisation</span>
                                    {frenchFormat(reference.realisation_date)}
                                </p>
                            </div>
                            <div className='flex items-center justify-between mt-4'>
                                <p>
                                    <span className='mr-4 text-sm text-gray-400'>Maitrise d’ouvrage</span>
                                    {reference.project_management ?? '-'}
                                </p>
                                <p className='w-[15%]'>
                                    <span className='mr-4 text-sm text-gray-400'>Budget</span>
                                    {reference.budget ? reference.budget + ' €' : '-'}
                                </p>
                            </div>
                            <div className='flex items-center mt-4 max-w-[50%]'>
                                <p className='mr-4 text-sm text-gray-400'>Tags</p>
                                {
                                    reference.tags.length ?
                                        <div className='flex flex-wrap gap-x-2'>
                                            {
                                                reference.tags.map((tag, index) => (
                                                    <Fragment key={`tag-${index}`}>
                                                        <Badge content={tag}/>
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                        : '-'
                                }
                            </div>
                            <div className='flex items-center mt-4 max-w-[50%]'>
                                <div className='mr-4 text-sm text-gray-400'>Cotraitants</div>
                                {
                                    reference.cocontractors.length ?
                                        <div className='flex flex-wrap gap-x-2'>
                                            {
                                                reference.cocontractors.map((cocontractor, index) => (
                                                    <Fragment key={`cocontractor-${index}`}>
                                                        <Badge content={cocontractor.name}/>
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                        : '-'
                                }
                            </div>
                            <div className='w-12 my-6 h-[1px] bg-black'/>
                            <div className='flex items-start'>
                                <div className='mr-4 text-sm text-gray-400'>Contact</div>
                                {
                                    reference.contacts?.length > 0 ?
                                        <div>
                                            {
                                                reference.contacts.map((contact, index) => {
                                                    return (
                                                        <div key={index} className={`flex items-center ${index === 0 ? "" : "mt-1"} mb-1`}>
                                                            <img src={contactIcon} alt="contact" className='w-4 mr-1'/>
                                                            <div className='mr-1 text-base'>{contact.name} -</div>
                                                            <div className='mr-1 text-sm'>{contact.email} -</div>
                                                            <div className='mr-1 text-sm'>{contact.phone}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : "-"
                                }
                            </div>
                            <p className='mt-4'>
                                <span className='mr-4 text-sm text-gray-400'>Moyen de contact</span>
                                {reference.contact_address}
                            </p>
                            {
                                reference.customer_logo ?
                                    <img className='w-48 mt-4' src={reference.customer_logo.file} alt=""/>
                                    : <p className='mt-4'>Aucun logo</p>
                            }
                            <div className='w-12 my-6 h-[1px] bg-black'/>
                            <div>
                                <p style={{fontFamily: 'DIN Condensed'}} className='mb-2 text-xl'>Contexte & Objectifs</p>
                                <div className="html-rendered" dangerouslySetInnerHTML={{ __html: reference.context ?? '-' }}/>
                            </div>
                            <div className='mt-10'>
                                <p style={{fontFamily: 'DIN Condensed'}} className='mb-2 text-xl'>Actions & Méthodologie</p>
                                <div className="html-rendered" dangerouslySetInnerHTML={{ __html: reference.actions ?? '-' }}/>
                            </div>
                        </div>

                    </div>
            }
        </div>
    )
}

export default ReferenceDetails;
