export const aggregateContributionToMarginObject = (data: any) => {

    const dataTable = []

    for (const key in data) {
       
        const element = data[key];
        dataTable.push({
            id: key,
            name: key,
            gross_margin: element.gross_margin,
            direct_salaries: element.salary,
            contribution: element.gross_margin - element.salary,
            contribution_per_etp: Math.round(element.etp === 0 ? 0 : ((element.gross_margin - element.salary) / element.etp))
        })
    }

    const cabinet =
        {
            id: dataTable.length + 1,
            name: "Cabinet (TOTAL)",
            gross_margin: 0,
            direct_salaries: 0,
            contribution: 0,
            contribution_per_etp: 0
        }

    // sum every item in the array of objects
    for (const key in data) {
        cabinet.gross_margin += data[key].gross_margin;
        cabinet.direct_salaries += data[key].salary;
        cabinet.contribution += data[key].gross_margin - data[key].salary;
        cabinet.contribution_per_etp += data[key].etp
    }

    cabinet.contribution_per_etp = Math.round(cabinet.gross_margin === 0 ? 0 : (cabinet.contribution / cabinet.contribution_per_etp));

    dataTable.push(cabinet);

    return dataTable;
}
