import React, {useState} from 'react';
import {CSVLink} from 'react-csv';

// import Datepicker from '../../../components/ui/Datepicker';
import Table from '../../../components/ui/Table';
import Loader from '../../../components/loaders/Loader';
import download from '../../../assets/icons/containerCard/download.png';
import {exportInvoicesDataToCsv} from '../functions/exportDataToCsv';

const InvoiceBalance = ({
    // datePickerValue,
    // onDatePickerChange,
    tableData,
    tableLoader,
    invoicesConfig,
    invoicesPaginationMetadata,
    handleGetAllInvoices
}) => {

    // CSV export states
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });
    
    const [csvExportLoading, setCsvExportLoading] = useState(false);

    const handleDownloadButtonClick = (done) => {
        exportInvoicesDataToCsv(
            tableData,
            setCsvProps,
            setCsvExportLoading,
            //datePickerValue
        );
        done(true);
    }

    return (
        <div>
            <div className="flex justify-end w-full py-2 mb-4">
                {/* <Datepicker
                    type="range"
                    onChange={onDatePickerChange}
                    defaultValue={datePickerValue}
                /> */}
                {
                    tableData.length > 0 ?
                        <CSVLink
                            data={csvProps.data}
                            headers={csvProps.headers}
                            filename={csvProps.filename}
                            asyncOnClick={true}
                            target="_blank"
                            separator=";"
                            onClick={(event, done) => handleDownloadButtonClick(done)}
                        >
                            <button className='p-2 mx-2'>
                                {
                                    csvExportLoading ?
                                        <Loader size={20}/> :
                                        <img src={download} alt="download" className='w-5'/>
                                }
                            </button>
                        </CSVLink> : null
                }
            </div>
            <Table
                tableConfig={invoicesConfig}
                data={tableData}
                loading={tableLoader}
                paginationMetadata={invoicesPaginationMetadata}
                onPageNumberChange={handleGetAllInvoices}
            />
        </div>
    );
};

export default InvoiceBalance;
