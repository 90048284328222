export const getErrors = (result) => {
    
    const errors = result?.error?.error?.error?.error?.response?.data;

    if (!errors) return [{title: '', message: 'Une erreur est survenue'}];

    const status = result?.error?.error?.error?.error?.response?.status;

    if (status >= 500) return [{title: '', message: 'Une erreur est survenue'}];

    const errorArrays = [];

    if (typeof errors !== 'object') return [{title: '', message: 'Une erreur est survenue'}];

    for (const key in errors) {
        if (!(Array.isArray(errors[key]))) {
            if (typeof errors[key] === 'string') {
                return [{title: key, message: errors[key]}];
            }
            else {
                return [{title: '', message: 'Une erreur est survenue'}];
            }
        }
        else {
            errors[key].forEach((el) => {
                if (typeof el === 'object') {
                    for (const k in el) {
                        errorArrays.push({title: k, message: el[k]});
                    }
                }
                else {
                    errorArrays.push({title: key, message: el});
                }
            });
        }

    }

    return errorArrays;
}

export const setCustomErrorMessage = (ErrorMessage) => {

    if (typeof ErrorMessage !== 'string') return [{title: '', message: 'Une erreur est survenue'}];

    return [{title: '', message: ErrorMessage}];
}

export const displayErrors = (errorArrays) => {

    const _formatTitle = (title) => {
        if (!title) {
            return '';
        }
        else {
            return `${title.replaceAll('_', ' ').toUpperCase()}: `;
        }
    }

    const errors = errorArrays.map(el => `⚠ ${_formatTitle(el.title)}${el.message}`);

    return errors.join('\n');
}
