import {DashboardActions} from "../../../actions";
import {firstDayMonth, lastDayMonth, standardFormat} from "../../../utils";

export const getProductionData = async (
    userData,
    notify,
    setGrossMarginProducedData,
    setForecastGrossMarginData,
    setDailyRateData,
    grossMarginProducedDatePicker,
    forecastGrossMarginDatePicker
) => {
    const grossMarginProducedDataResult = await DashboardActions.AdminActions.getGrossMargin(
        userData,
        `?forecast=false&before=${standardFormat(grossMarginProducedDatePicker.endDate)}&after=${standardFormat(grossMarginProducedDatePicker.startDate)}`
    );

    if (grossMarginProducedDataResult.success) {
        setGrossMarginProducedData(grossMarginProducedDataResult.data);
    }
    else {
        notify('Erreur de récuperation des marges brutes produites');
    }

    const forecastGrossMarginDataResult = await DashboardActions.AdminActions.getGrossMargin(
        userData,
        `?forecast=true&before=${standardFormat(forecastGrossMarginDatePicker.endDate)}&after=${standardFormat(forecastGrossMarginDatePicker.startDate)}`
    );

    if (forecastGrossMarginDataResult.success) {
        setForecastGrossMarginData(forecastGrossMarginDataResult.data);
    }
    else {
        notify('Erreur de récuperation des marges brutes prévisionnelles');
    }

    const dailyRateDataResult = await DashboardActions.AdminActions.getDailyRate(userData, '');

    if (dailyRateDataResult.success) {
        setDailyRateData(dailyRateDataResult.data);
    }
    else {
        notify('Erreur de récuperation des TJM');
    }
}

export const handleGrossMarginProducedDatePickerDateChange = async (
    e,
    userData,
    setLoading,
    notify,
    setGrossMarginProducedDatePicker,
    setGrossMarginProducedData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setGrossMarginProducedDatePicker(dates);

    const grossMarginProducedDataResult = await DashboardActions.AdminActions.getGrossMargin(
        userData,
        `?forecast=false&before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );

    if (grossMarginProducedDataResult.success) {
        setGrossMarginProducedData(grossMarginProducedDataResult.data);
    }
    else {
        notify('Erreur de récuperation des marges brutes produites');
    }

    setLoading(false);
};

export const handleForecastGrossMarginDatePickerDateChange = async (
    e,
    userData,
    setLoading,
    notify,
    setForecastGrossMarginDatePicker,
    setForecastGrossMarginData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setForecastGrossMarginDatePicker(dates);

    const forecastGrossMarginDataResult = await DashboardActions.AdminActions.getGrossMargin(
        userData,
        `?forecast=true&before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );

    if (forecastGrossMarginDataResult.success) {
        setForecastGrossMarginData(forecastGrossMarginDataResult.data);
    }
    else {
        notify('Erreur de récuperation des marges brutes prévisionnelles');
    }

    setLoading(false);
};
