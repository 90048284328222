import {DashboardDao} from '../../../dao';
import {ErrorModel} from '../../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'EXPORTS_ONGOING';

export async function exportOngoing(userData: Record<string, string>, payload: Record<string, unknown>) {

    try {
        const result = await DashboardDao.AdminDao.exportOngoing(userData.userToken, payload);

        return result;
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de l\'envoi des en-cours',
            error: error
        });
    }
}
