export const aggregateETPMargin = (data: any, type: string) => {
    
    const das1JuniorETPMargin = Math.round(data.das1.senior.gross_margin / data.das1.senior.etp) ? Math.round(data.das1.senior.gross_margin / data.das1.senior.etp) : 0;
    const das1SeniorETPMargin = Math.round(data.das1.senior.gross_margin / data.das1.senior.etp) ? Math.round(data.das1.senior.gross_margin / data.das1.senior.etp) : 0;
    const das1ConfirmedETPMargin = Math.round(data.das1.confirmed.gross_margin / data.das1.confirmed.etp) ? Math.round(data.das1.confirmed.gross_margin / data.das1.confirmed.etp) : 0;

    const das2JuniorETPMargin = Math.round(data.das2.junior.gross_margin / data.das2.junior.etp) ? Math.round(data.das2.junior.gross_margin / data.das2.junior.etp) : 0;
    const das2SeniorETPMargin = Math.round(data.das2.senior.gross_margin / data.das2.senior.etp) ? Math.round(data.das2.senior.gross_margin / data.das2.senior.etp) : 0;
    const das2ConfirmedETPMargin = Math.round(data.das2.confirmed.gross_margin / data.das2.confirmed.etp) ? Math.round(data.das2.confirmed.gross_margin / data.das2.confirmed.etp) : 0;

    const das3JuniorETPMargin = Math.round(data.das3.junior.gross_margin / data.das3.junior.etp) ? Math.round(data.das3.junior.gross_margin / data.das3.junior.etp) : 0;
    const das3SeniorETPMargin = Math.round(data.das3.senior.gross_margin / data.das3.senior.etp) ? Math.round(data.das3.senior.gross_margin / data.das3.senior.etp) : 0;
    const das3ConfirmedETPMargin = Math.round(data.das3.confirmed.gross_margin / data.das3.confirmed.etp) ? Math.round(data.das3.confirmed.gross_margin / data.das3.confirmed.etp) : 0;

    const das1ETPMargin = das1JuniorETPMargin + das1SeniorETPMargin + das1ConfirmedETPMargin;
    const das2ETPMargin = das2JuniorETPMargin + das2SeniorETPMargin + das2ConfirmedETPMargin;
    const das3ETPMargin = das3JuniorETPMargin + das3SeniorETPMargin + das3ConfirmedETPMargin;

    const juniorETPMargin = das1JuniorETPMargin + das2JuniorETPMargin + das3JuniorETPMargin;
    const seniorETPMargin = das1SeniorETPMargin + das2SeniorETPMargin + das3SeniorETPMargin;
    const confirmedETPMargin = das1ConfirmedETPMargin + das2ConfirmedETPMargin + das3ConfirmedETPMargin;

    const cabinetETPMargin = das1ETPMargin + das2ETPMargin + das3ETPMargin;
    
    switch (type) {
        case 'das1_junior_etp_margin':
            return das1JuniorETPMargin;
        case 'das1_senior_etp_margin':
            return das1SeniorETPMargin;
        case 'das1_confirmed_etp_margin':
            return das1ConfirmedETPMargin;
        case 'das2_junior_etp_margin':
            return das2JuniorETPMargin;
        case 'das2_senior_etp_margin':
            return das2SeniorETPMargin;
        case 'das2_confirmed_etp_margin':
            return das2ConfirmedETPMargin;
        case 'das3_junior_etp_margin':
            return das3JuniorETPMargin;
        case 'das3_senior_etp_margin':
            return das3SeniorETPMargin;
        case 'das3_confirmed_etp_margin':
            return das3ConfirmedETPMargin;
        case 'das1_etp_margin':
            return das1ETPMargin;
        case 'das2_etp_margin':
            return das2ETPMargin;
        case 'das3_etp_margin':
            return das3ETPMargin;
        case 'junior_etp_margin':
            return juniorETPMargin;
        case 'senior_etp_margin':
            return seniorETPMargin;
        case 'confirmed_etp_margin':
            return confirmedETPMargin;
        case 'cabinet_etp_margin':
            return cabinetETPMargin;
        default:
            return 0;
    }
}

export const aggregateETPmMarginParDas = (data: any) => {
    
    // eslint-disable-next-line max-len
    return Math.round(data.junior.gross_margin + data.senior.gross_margin + data.confirmed.gross_margin  / (data.junior.etp + data.senior.etp + data.confirmed.etp > 0 ? data.junior.etp + data.senior.etp + data.confirmed.etp : 1 ) );
}

export const aggregateETPmMarginTotal = (data: any) => {
    let totalGrossMargin = 0;
    let totalETP = 0;

    for (const das in data) {
        totalGrossMargin += (data[das].junior.gross_margin + data[das].senior.gross_margin + data[das].confirmed.gross_margin);
        totalETP += (data[das].junior.etp + data[das].senior.etp + data[das].confirmed.etp);
    }

    return Math.round(totalGrossMargin / totalETP);
}

export const aggregateETPmMarginPerRole = (data: any, key: string) => {
    return [
        {
            type: 'Junior:',
            value: Math.round(data[key].junior.gross_margin / data[key].junior.etp) ? Math.round(data[key].junior.gross_margin / data[key].junior.etp) : 0
        },
        {
            type: 'Confirmé:',
            value: Math.round(data[key].senior.gross_margin / data[key].senior.etp) ? Math.round(data[key].senior.gross_margin / data[key].senior.etp) : 0
        },
        {
            type: 'Sénior:',
            value: Math.round(data[key].confirmed.gross_margin / data[key].confirmed.etp) ? Math.round(data[key].confirmed.gross_margin / data[key].confirmed.etp) : 0
        }
    ];
}

export const aggregateETPMarginPerRoleCabinet = (data: any) => {
    const dataCabinet = [
        {
            type: 'Junior:',
            value: 0
        },
        {
            type: 'Confirmé:',
            value:0
        },
        {
            type: 'Sénior:',
            value: 0
        }
    ];

    for (const das in data) {
        dataCabinet[0].value += Math.round(data[das].junior.gross_margin / data[das].junior.etp) ? Math.round(data[das].junior.gross_margin / data[das].junior.etp) : 0;
        dataCabinet[1].value += Math.round(data[das].senior.gross_margin / data[das].senior.etp) ? Math.round(data[das].senior.gross_margin / data[das].senior.etp) : 0;
        dataCabinet[2].value += Math.round(data[das].confirmed.gross_margin / data[das].confirmed.etp) ? Math.round(data[das].confirmed.gross_margin / data[das].confirmed.etp) : 0;
    }

    return dataCabinet

}