import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {Toast, notify} from '../../components/ui/Toast/Toast';
import {InvoiceActions} from '../../actions';
import Layout from '../../components/layout/Layout';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import RoadStep from '../../components/ui/RoadStep';
import roadStepInvoicesConfig from './configs/roadStepInvoicesConfig';
import TopBanner from '../../components/ui/TopBanner';
import {bannerInvoicesConfig} from './configs/bannerInvoicesConfig';
import InformationsCard from './components/InformationsCard';
import PaymentsCard from './components/PaymentsCard';
import BankAccountCard from './components/BankAccountCard';
import ContractorsCard from './components/ContractorsCard';
import BillingAddressCard from './components/BillingAddressCard';
import NotesCard from './components/NotesCard';
import DetailsTable from './components/DetailsTable';
import {detailsTableConfig} from './configs/detailsTableConfig';
import AssignNewInvoiceContractor from './components/AssignContractorPopup';
import {contractorsTableConfig} from './configs/contractorsTableConfig';
import CreateNewInvoicePopup from './components/CreateInvoicePopup/CreateNewInvoicePopup';
import CreateNewInvoiceItemPopup from './components/CreateNewInvoiceItemPopup/CreateNewInvoiceItemPopup';
import ConfirmationPopup from '../../components/ui/ConfirmationPopup/ConfirmationPopup';
import returnIcon from '../../assets/icons/page/orange-left-arrow.png';
import Popup from '../../components/ui/Popup/Popup';
import ContractorInvoicePreviewPopup from './components/ContractorInvoicePreviewPopup';
import InvoicePaiementPopup from './components/InvoicePaiementPopup';

const InvoiceDetailsPage = () => {
    // states
    const [invoiceData, setInvoiceData] = useState({});
    const [loading, setLoading] = useState(true);
    const [deleteInvoiceLoading, setDeleteInvoiceLoading] = useState(false);
    const [downloadInvoiceLoading, setDownloadInvoiceLoading] = useState(false);
    const [deleteItemLoading, setDeleteItemLoading] = useState(false);
    const [deleteContractorLoading, setDeleteContractorLoading] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [isContractorPopupOpen, setIsContractorPopupOpen] = useState(false);
    const [invoiceContractorToBeUpdated, setInvoiceContractorToBeUpdated] = useState(null);
    const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
    const [isInvoiceItemPopupOpen, setIsInvoiceItemPopupOpen] = useState(false);
    const [invoiceItemToBeUpdated, setInvoiceItemToBeUpdated] = useState(null);
    const [confirmationPopupView, setConfirmationPopupView] = useState(false);
    const [cancelPopupView, setCancelPopupView] = useState(false);
    const [newInvoiceStatus, setNewInvoiceStatus] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    const [showInvoicePreviewPopup, setShowInvoicePreviewPopup] = useState(false);
    const [showInvoicePaiementPopup, setShowInvoicePaiementPopup] = useState(false);
    const [paiementStatus, setPaiementStatus] = useState(null);

    // Hooks
    const {id} = useParams();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // Get invoice data
    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await InvoiceActions.getInvoiceById(userData, id);

            if (result.success) {
                setInvoiceData(result.data);
            }
            else {
                navigate('/invoices', {state: 'invalid_id'});
            }
            setLoading(false);
        })();
    }, [id]);

    // Functions
    const refreshData = async () => {
        const result = await InvoiceActions.getInvoiceById(userData, id);

        if (result.success) {
            setInvoiceData(result.data);
        }
        else {
            notify('Échec de la mise à jour des données');
        }
    };

    const handleInvoiceDownload = async () => {
        setButtonsDisabled(true);
        setDownloadInvoiceLoading(true);

        const result = await InvoiceActions.downloadInvoiceById(userData, invoiceData.id);

        if (!result.success) {
            notify('Échec du téléchargement de la facture');
        }
        else {
            const url = URL.createObjectURL(result.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = (`facture ${invoiceData?.deal?.name}`) + '.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            notify(`La facturee a été téléchargée avec succès.`);
        }
        
        setButtonsDisabled(false);
        setDownloadInvoiceLoading(false);
    };

    const handleInvoiceDelete = async () => {
        setButtonsDisabled(true);
        setDeleteInvoiceLoading(true);

        const result = await InvoiceActions.deleteInvoiceById(userData, invoiceData.id);

        if (result.success) {
            navigate('/invoices', {state: 'invoice_deleted'});
        }
        else {
            notify('Votre frais n\'a pas pu être supprimé');
        }
    };

    const handleEditItem = (data) => {
        setInvoiceItemToBeUpdated(data);
        setIsInvoiceItemPopupOpen(true);
    };

    const handleDeleteItem = async (itemId) => {
        setButtonsDisabled(true);
        setDeleteItemLoading(itemId);

        const result = await InvoiceActions.deleteInvoiceItemById(userData, invoiceData.id, itemId, invoiceData);

        if (result.success) {
            await refreshData();
            notify('La ligne a bien été supprimé');
        }
        else {
            notify('Erreur lors de la suppression de la ligne');
        }
        setDeleteItemLoading(null);
        setButtonsDisabled(false);
    };

    const handleDeleteContractor = async (contractorId) => {
        setButtonsDisabled(true);
        setDeleteContractorLoading(contractorId);
        const result = await InvoiceActions.deleteInvoiceContractor(userData, invoiceData, contractorId);

        if (result.success) {
            await refreshData();
            notify('La factures sous/co traitants a bien été supprimé');
        }
        else {
            notify('Erreur lors de la suppression la factures sous/co traitants');
        }

        setDeleteContractorLoading(null);
        setButtonsDisabled(false);
    };

    useEffect(() => {
        !isInvoiceItemPopupOpen && setInvoiceItemToBeUpdated(null);
    }, [isInvoiceItemPopupOpen]);

    const getCurrentStep = () => {
        switch (invoiceData.status) {
            case 0:
                return 0;
            case 50:
                return 1;
            case 100:
                return 2;
            case 200:
                return 3;
            case 300:
                return 4;
            case 400:
                return 5;
            case 500:
                return 6;
            case 600:
                return 7;
            default:
                return 0;
        }
    };

    const getStepStatus = (step) => {
        switch (step) {
            case 1:
                return 50;
            case 2:
                return 100;
            case 3:
                return 200;
            case 4:
                return 300;
            case 5:
                return 400;
            case 6:
                return 500;
            case 7:
                return 600;
            default:
                return 0;
        }
    }

    const handleStepClick = (step) => {
        if (step === 5 || step === 6) {
            if (step === 5) {
                setPaiementStatus('partial');
            }
            else {
                setPaiementStatus('total');
            }
            
            return setShowInvoicePaiementPopup(true);
        }
        else if (step === 7 && (invoiceData.contractors?.length > 0)) {
            setCancelPopupView(true);
        }
        else {
            setConfirmationPopupView(true);
            setNewInvoiceStatus(getStepStatus(step));
        }
    };
        
    // Click to open paiement popup when the current step is partial paiement
    useEffect(() => {
        const steps = document.getElementsByClassName('ant-steps-item-active');
        
        steps[0].addEventListener('click', () => {
            if (getCurrentStep() === 5) {
                setPaiementStatus('partial');

                return setShowInvoicePaiementPopup(true);
            }
        });
    }, [invoiceData]);

    const handleConfirmStatusChange = async () => {
        setLoading(true);

        if (newInvoiceStatus === 600) {
            const payload = {
                deal_id: invoiceData.deal.id,
                customer_id: invoiceData.deal.customer.id,
                bank_name: invoiceData.bank.name,
                type: invoiceData.type,
                billing_address: invoiceData.billing_address,
                billing_date: invoiceData.billing_date
            }

            const result = await InvoiceActions.cancelInvoice(userData, payload, invoiceData.id);

            if (result.success) {
                notify('La facture a bien été annulée');
                await refreshData();
            }
            else {
                notify('Une erreur est survenue lors de l\'annulation de la facture');
            }
        }
        else {
            const result = await InvoiceActions.updateInvoiceById(userData, invoiceData.id, {status: newInvoiceStatus});

            if (result.success) {
                notify('Le statut a été mis à jour');
                await refreshData();
            }
            else {
                notify('Une erreur est survenue lors du changement de statut');
            }
        }

        setNewInvoiceStatus(null);
        setLoading(false);
    };

    // Invoice preview popup
    useEffect(() => {
        if (!showInvoicePreviewPopup) {
            setItemSelected(null);
        }
    }, [showInvoicePreviewPopup]);

    const handleInvoicePreview = (item) => {
        setItemSelected(item);
        setShowInvoicePreviewPopup(true);
    }

    // Configs
    const bannerConfig = bannerInvoicesConfig(
        setIsInvoicePopupOpen,
        handleInvoiceDownload,
        handleInvoiceDelete,
        downloadInvoiceLoading,
        deleteInvoiceLoading,
        buttonsDisabled
    );
    const tableConfig = detailsTableConfig(
        invoiceData.expenses,
        invoiceData.status,
        buttonsDisabled,
        handleEditItem,
        handleDeleteItem,
        deleteItemLoading
    );

    const contractorsConfig = contractorsTableConfig(
        invoiceData.status,
        buttonsDisabled,
        deleteContractorLoading,
        setIsContractorPopupOpen,
        handleDeleteContractor,
        setInvoiceContractorToBeUpdated,
        handleInvoicePreview
    );

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <div className='flex items-center justify-center w-full py-6'>
                    <RoadStep
                        step={getCurrentStep()}
                        config={roadStepInvoicesConfig(invoiceData.status, invoiceData.type, invoiceData.credit)}
                        onChange={handleStepClick}
                    />
                </div>
                <button onClick={() => navigate(-1)} className='pb-8 pl-16 pr-10 mt-[-10px]'>
                    <img className="w-7" src={returnIcon} alt='return'/>
                </button>
                <TopBanner
                    title={invoiceData.deal?.short_name}
                    status={invoiceData.status}
                    config={bannerConfig}
                />
                <div className='px-20 pt-6 pb-36'>
                    <div className='flex flex-row items-start justify-center mb-6'>
                        <InformationsCard
                            data={invoiceData}
                            onEditButtonClick={() => null}
                        />
                        <PaymentsCard data={invoiceData}/>
                        <BankAccountCard data={invoiceData.bank}/>
                    </div>
                    <div className='flex flex-row items-start justify-center mb-6'>

                        <ContractorsCard
                            data={invoiceData}
                            setInvoiceContractorToBeUpdated={setInvoiceContractorToBeUpdated}
                            setIsContractorPopupOpen={setIsContractorPopupOpen}
                            tableConfig={contractorsConfig}
                        />
                        <BillingAddressCard
                            data={invoiceData.billing_address}
                            onEditButtonClick={() => null}
                        />
                        <NotesCard data={invoiceData.notes}/>
                    </div>
                    <DetailsTable
                        data={invoiceData}
                        tableConfig={tableConfig}
                        onNewLineButtonClick={() => setIsInvoiceItemPopupOpen(true)}
                    />
                </div>
            </PageLoader>
            <Popup
                view={isContractorPopupOpen}
                setView={setIsContractorPopupOpen}
                width={'1098px'}
                maxHeight='42em'
                yOverflow=''
                xOverflow=''
            >
                <AssignNewInvoiceContractor
                    refreshData={refreshData}
                    invoiceData={invoiceData}
                    invoiceContractorToBeUpdated={invoiceContractorToBeUpdated}
                    setView={setIsContractorPopupOpen}
                    userData={userData}
                />
            </Popup>
            <ContractorInvoicePreviewPopup
                view={showInvoicePreviewPopup}
                setView={setShowInvoicePreviewPopup}
                item={itemSelected}
                type='invoice'
            />
            <CreateNewInvoicePopup
                refreshData={refreshData}
                userData={userData}
                view={isInvoicePopupOpen}
                setView={setIsInvoicePopupOpen}
                invoiceToBeUpdated={invoiceData}
            />
            <CreateNewInvoiceItemPopup
                userData={userData}
                invoiceData={invoiceData}
                invoiceItemToBeUpdated={invoiceItemToBeUpdated}
                setInvoiceItemToBeUpdated={setInvoiceItemToBeUpdated}
                view={isInvoiceItemPopupOpen}
                setView={setIsInvoiceItemPopupOpen}
                refreshData={refreshData}
            />
            <ConfirmationPopup
                view={confirmationPopupView}
                setView={setConfirmationPopupView}
                title='Confirmer le changement de statut?'
                subtitle='Le statut de la facture sera mis à jour.'
                onConfirm={handleConfirmStatusChange}
            />
            <ConfirmationPopup
                view={cancelPopupView}
                setView={setCancelPopupView}
                title="Impossible d'annuler la facture"
                subtitle='Vous ne pouvez pas annuler cette facture car elle est liée à des facturations de sous/co-traitances encore actives.'
                confirmButtonText="J'ai compris"
                onConfirm={() => null}
                visibleDeclineButton={false}
            />
            <InvoicePaiementPopup
                view={showInvoicePaiementPopup}
                setView={setShowInvoicePaiementPopup}
                item={invoiceData}
                userData={userData}
                refreshInvoicesData={refreshData}
                paiementStatus={paiementStatus}
                setPaiementStatus={setPaiementStatus}
            />
        </Layout>
    );
};

export default InvoiceDetailsPage;
