import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {Empty} from "antd";

import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import {aggregateLoadProjectionDataPerDas} from '../../../codevDashboardPage/functions/aggregateLoadProjectionData';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const chartConfig = {
    plugins: {
        title: {
            display: true,
            align: 'start',
            color: '#212121',
            text: 'Charges de production & Charges prévisionnelles',
            font: {
                size: 22,
                weight: 'bold',
                family: 'Din condensed'
            }
        },
        legend: {
            align: "end"
        }
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false
    },
    scales: {
        x: {
            stacked: true
        },
        y: {
            stacked: true
        }
    }
};

const ResourceNeeds = ({data, dasKey}) => {

    const aggregateData = data.load[dasKey] ? aggregateLoadProjectionDataPerDas(data.load[dasKey]) : null;

    const labels = data.load[dasKey] ? aggregateData.labels : null;

    const productionLoadData = data.load[dasKey] ?
        aggregateData.labels.map((label) => {
            return aggregateData.chartData.find((item) => item.load_type === 'Charge de production' && item.period === label).value
        }) : null

    const previsionalLoadData = data.load[dasKey] ?
        aggregateData.labels.map((label) => {
            return aggregateData.chartData.find((item) => item.load_type === 'Charge prévisionnelle' && item.period === label).value
        }) : null

    const ressourcesLoadData = data.load[dasKey] ?
        aggregateData.labels.map((label) => {
            return aggregateData.chartData.find((item) => item.load_type === 'Niveau de ressources humaines' && item.period === label).value
        }) : null

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Charge de production',
                data: productionLoadData,
                backgroundColor: '#e67a3a',
                stack: 'Stack 0'
            },
            {
                label: 'Charge prévisionnelle',
                data: previsionalLoadData,
                backgroundColor: '#f0a841',
                stack: 'Stack 0'
            },
            {
                label: 'Niveau de ressources humaines',
                data: ressourcesLoadData,
                backgroundColor: '#212121',
                stack: 'Stack 1'
            }
        ]
    };

    return (
        <div className='mt-6'>
            <ContainerCard
                title={'Projection des besoins de ressource'}
                showLineHeader={false}
                coloredButtonDisplayed={false}
                height="min-h-[450px]"
            >
                {
                    data.load[dasKey] ?
                        <Bar
                            className="w-full h-full"
                            options={chartConfig}
                            data={chartData}
                        /> :
                        <div>
                            <div className="mt-[100px]">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                            </div>
                        </div>
                }
            </ContainerCard>
        </div>
    );
}

export default ResourceNeeds;
