import axios from 'axios';

import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import {URL} from '../root';

const FUNCTION_CODE = 'CREATE_CONTRACTOR_CATEGORY';

export async function createContractorCategory(userData: any, payload: Record<string, unknown>) {
    try {
        const response = await axios({
            method: 'post',
            url: `${URL}/contractor-categories/`,
            data: payload,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userData.userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création de la catégorie sous/co-traitant',
            error: error
        });
    }
}
