import {Route, Routes} from 'react-router-dom';
import NotificationPage from '../pages/notificationPage/NotificationPage';

const NotificationsRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<NotificationPage/>}/>
        </Routes>
    )
}

export default NotificationsRoute;