export const billingPay = {

    from(billingPayResult: Record<string, any>) {

        return (
            {
                "sent_invoices": {
                    "m1": {
                        "count": billingPayResult.sent_invoices?.m1?.count ?? 0,
                        "value": billingPayResult.sent_invoices?.m1?.value ?? 0
                    },
                    "m2": {
                        "count": billingPayResult.sent_invoices?.m2?.count ?? 0,
                        "value": billingPayResult.sent_invoices?.m2?.value ?? 0
                    },
                    "m3": {
                        "count": billingPayResult.sent_invoices?.m3?.count ?? 0,
                        "value": billingPayResult.sent_invoices?.m3?.value ?? 0
                    },
                    "q1": {
                        "count": billingPayResult.sent_invoices?.q1?.count ?? 0,
                        "value": billingPayResult.sent_invoices?.q1?.value ?? 0
                    },
                    "s1": {
                        "count": billingPayResult.sent_invoices?.s1?.count ?? 0,
                        "value": billingPayResult.sent_invoices?.s1?.value ?? 0
                    }
                },
                "draft_invoices": {
                    "m1": {
                        "count": billingPayResult.draft_invoices?.m1?.count ?? 0,
                        "value": billingPayResult.draft_invoices?.m1?.value ?? 0
                    },
                    "m2": {
                        "count": billingPayResult.draft_invoices?.m2?.count ?? 0,
                        "value": billingPayResult.draft_invoices?.m2?.value ?? 0
                    },
                    "m3": {
                        "count": billingPayResult.draft_invoices?.m3?.count ?? 0,
                        "value": billingPayResult.draft_invoices?.m3?.value ?? 0
                    },
                    "q1": {
                        "count": billingPayResult.draft_invoices?.q1?.count ?? 0,
                        "value": billingPayResult.draft_invoices?.q1?.value ?? 0
                    },
                    "s1": {
                        "count": billingPayResult.draft_invoices?.s1?.count ?? 0,
                        "value": billingPayResult.draft_invoices?.s1?.value ?? 0
                    },
                    "other": {
                        "count": billingPayResult.draft_invoices?.other?.count ?? 0,
                        "value": billingPayResult.draft_invoices?.other?.value ?? 0
                    }
                },
                "contractor_invoices": {
                    "pending": {
                        "count": billingPayResult.contractor_invoices?.pending?.count ?? 0,
                        "value": billingPayResult.contractor_invoices?.pending?.value ?? 0
                    },
                    "to_pay": {
                        "count": billingPayResult.contractor_invoices?.to_pay?.count ?? 0,
                        "value": billingPayResult.contractor_invoices?.to_pay?.value ?? 0
                    }
                },
                "expenses": {
                    "pending": {
                        "count": billingPayResult.expenses?.pending?.count ?? 0,
                        "value": billingPayResult.expenses?.pending?.value ?? 0
                    },
                    "to_pay": {
                        "count": billingPayResult.expenses?.to_pay?.count ?? 0,
                        "value": billingPayResult.expenses?.to_pay?.value ?? 0
                    }
                }
            }
        )
    }
}
