/* eslint-disable max-len */
export const currentTotal = (data: any) => {
    let total = 0
    for (const key in data) {
        total += data[key].count
    }

    return total
}

export const totalLoad = (load: any) => {
    
    const copy = { ...load }
    
    delete copy.available_load
    delete copy.billing
    
    let totalLoad = 0

    let totalAviableLoad = 0

    for (const key in copy) {
        totalLoad += copy[key].Confirmé + copy[key].Junior + copy[key].Sénior;
        totalAviableLoad += copy[key].available_load
        
    }

    return totalLoad / totalAviableLoad * 100
}