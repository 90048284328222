import {useState} from "react";
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {UserActions} from '../../../actions'
import TextInput from "../../../components/ui/Inputs/TextInputBis";
import Button from "../../../components/ui/Button";
import {notify} from '../../../components/ui/Toast/Toast';

const PasswordForm = () => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const  defaultValues = {
        currentPassword: '',
        password: '',
        confirmPassword: ''
    };

    const {handleSubmit, control, reset, formState: {isValid}, watch} = useForm({defaultValues: defaultValues});

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const onSubmit = async (data) => {

        const bodyPassword = {
            old:  data.currentPassword,
            new: data.password,
            confirm: data.confirmPassword
        }

        const userId = userData.userId;

        if(isValid) {
            setDisabled(true);
            setLoading(true);
            setErrorMessage(null);

            const response = await UserActions.setUserPassword(userData.userToken, userId, bodyPassword);

            if (response.success) {
                setDisabled(false);
                setLoading(false);
                notify('Le mot de passe a été modifié avec succés');
                reset();
            }
            else {
                setDisabled(false);
                setLoading(false);
                const errorData = response.error.error.error.error.response.data;

                for (const key in errorData) {
                    switch (key) {
                        case 'old':
                            setErrorMessage(`Mot de passe actuel: ${errorData[key]}`);
                            break;
                        case 'new':
                            setErrorMessage(`Nouveau mot de passe: ${errorData[key]}`);
                            break;
                        case 'confirm':
                            setErrorMessage(`Confirmation du mot de passe: ${errorData[key]}`);
                            break;
                        default:
                            setErrorMessage('Une erreur est survenue.');
                            break;
                    }
                }
            }
        }
    }

    const validatePasswordComplexity = (value) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);

        if (value.length >= 8 && hasUpperCase && hasLowerCase) {
            return true;
        }
        else {
            return "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule et une lettre minuscule.";
        }
    };

    const passwordMatch = (value) => {
        if (value === watch('password')) {
            return true;
        }
        else {
            return 'Les mots de passe ne correspondent pas.';
        }
    };

    return (
        <div
            style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
            className="pb-6 mt-6 rounded-lg"
        >
            <div>
                <p style={{fontFamily: 'DIN condensed'}} className="px-6 pt-4 pb-4 text-xl">Modifier mon mot de passe</p>
            </div>

            <div className="h-[1px] bg-gray-200"/>

            <div className="px-6 mt-6">
                <div className="mb-2">
                    <Controller
                        name="currentPassword"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir votre mot de passe actuel.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                width="w-[350px]"
                                label={'Mot de passe actuel'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Mot de passe actuel"
                                type="password"
                            />
                        )}
                    />
                </div>
                <div className="mb-2">
                    <Controller
                        name="password"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir votre nouveau mot de passe.'
                        },
                        validate: {
                            passwordComplexity: validatePasswordComplexity
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                width="w-[350px]"
                                label={'Nouveau mot de passe'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nouveau mot de passe"
                                type="password"
                            />
                        )}
                    />
                </div>
                <div className="mb-2">
                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir votre confirmation de mot de passe.'
                        },
                        validate: {
                            passwordMatch: passwordMatch
                        }
                        }}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                width="w-[350px]"
                                label={'Confirmation nouveau mot de passe'}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Confirmation nouveau mot de passe"
                                type="password"
                            />
                        )}
                    />
                </div>

                <div className='flex flex-row mx-auto mt-8 w-fit'>
                    <Button
                        onClick={() => {
                            reset();
                            setErrorMessage(null);
                        }}
                        type='white'
                        content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content="Sauvegarder"
                        loading={loading}
                        disabled={disabled}
                    />
                </div>

                {
                    errorMessage &&
                        <div className="flex justify-center mt-5 text-red-500">
                            {errorMessage}
                        </div>
                }

            </div>
        </div>
    )
}

export default PasswordForm;