import React from 'react';
import {Route, Routes} from 'react-router-dom';

import AdminDashboardPage from '../pages/adminDashboardPage/AdminDashboardPage';

function AdminDashboardRouter() {
    return (
        <Routes>
            <Route path='/*' element={<AdminDashboardPage/>}/>
        </Routes>
    );
}

export default AdminDashboardRouter;
