import React, {Fragment, useState} from 'react';

import {fetchSelectData, handleFilterList, useFilterQuery} from '../../../utils';
import {LocationActions, UserActions, ContractorCategoryActions} from '../../../actions'
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import {SearchInput, SelectInput} from '../../../components/ui/Table';

export const contractorsTableConfig = (
    handleNavigation,
    setContactPopupView,
    filterContractorsList,
    userData,
    setToBeUpdated,
    setContactPopupData,
    setNewPopupView
) => {
    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        search: '',
        das: '',
        production_lead: '',
        customer: ''
    });
    const [contractorCategories, setContractorCategories] = useState([]);

    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterContractorsList);

    const handleGetLocations = async (search, page) => {
        await fetchSelectData({action: LocationActions.getAllLocation, search:`&search=${search}&is_active=true`, page: page || null, setter: setLocations, userData, resultPath: 'data'});
    }

    const handleGetUsers = async (search, page) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search, page: page || null, setter: setUsers, userData});
    }
    const handleGetContractorCategories = async (search, page) => {
        // eslint-disable-next-line max-len
        await fetchSelectData({action: ContractorCategoryActions.getAllContractorCategories, search:`&search=${search}`, page: page, setter: setContractorCategories, userData, resultPath: 'data.results'});
    };

    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'short_name',
                    width: 'w-[14%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.name}
                            </div>
                        );
                    },
                    filterComponent: () => <SearchInput name={'search'} onChange={handleFilter}/>,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Contacts',
                    value: '',
                    width: 'w-[14%]',
                    sort: true,
                    component: (
                        item
                    ) => {
                        return (
                            <div className="w-10/12 px-2">
                                {
                                    item.contacts.length ?
                                        <div className="flex justify-between">
                                            <div className='max-w-[65%]'>
                                                <p>{item.contacts[0].name}</p>
                                                <p className='text-[10px]'>{item.contacts[0].email}</p>
                                                <p className='text-[10px]'>{item.contacts[0].phone}</p>
                                            </div>
                                            {
                                                item.contacts.length > 1 ?
                                                    <div className="flex items-start">
                                                        <ActionsButton
                                                            type="contacts"
                                                            isLoading={false}
                                                            onClick={() => {
                                                                setContactPopupData(item.contacts);
                                                                setContactPopupView(item);
                                                            }}
                                                            disabled={false}
                                                            style="flex items-start justify-center p-1 bg-transparent"
                                                        />
                                                        <p className='text-[12px] py-[2px]'> (+{item.contacts.length - 1})</p>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        : "-"
                                }
                            </div>
                        );
                    },
                    filterComponent: () => <SearchInput name={'search'} onChange={handleFilter}/>,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Contacts internes',
                    value: 'username',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={item.internal_contacts} type='rounded'/>
                            </div>
                        )
                    },
                    filterComponent: () => <SelectInput
                        name="username"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Code géographique',
                    value: 'location',
                    width: 'w-[20%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item?.location ?? '-'}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="name"
                        options={locations?.options?.sort((a, b) => a.name.localeCompare(b.name))}
                        hasNextPage={locations?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetLocations)}
                        labelKeys={['name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Catégories',
                    value: 'categories',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-wrap justify-center">
                                {
                                    item.categories?.length > 0 ?
                                        item.categories.map((category, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <Badge content={category}/>
                                                </Fragment>
                                            );
                                        })
                                        : '-'
                                }
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="category"
                        options={contractorCategories?.options}
                        hasNextPage={contractorCategories?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetContractorCategories)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: 'note',
                    width: 'w-[14%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.notes ? item.notes : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={() => {setToBeUpdated(item); setNewPopupView(true)}}
                                    disabled={false}
                                />
                                <div className="w-0 h-0 px-1"/>
                                <ActionsButton
                                    type="seeMore"
                                    isLoading={false}
                                    onClick={() => {handleNavigation(item.id);}}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
