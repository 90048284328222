import React from 'react';

import Button from '../Button';
import Popup from '../Popup/Popup.js';
import Table from '../Table';
import {frenchLongFormat, frenchMonthFormat} from '../../../utils';

const CalendarTablePopup = ({
    calendarTablePopupVisible,
    setCalendarTablePopupVisible,
    data,
    date,
    type,
    tablePopupConfig,
    tablePopupDropdownConfig = null,
    onButtonTablePopupClick,
    buttonTitle,
    calendarTableLoading,
    onPageNumberChange,
    paginationMetadata
}) => {
    const handleCreateNewTime = () => {
        setCalendarTablePopupVisible(false);
        onButtonTablePopupClick(date);
    };

    return (
        <Popup setView={setCalendarTablePopupVisible} view={calendarTablePopupVisible} width={'90%'}>
            <div className="px-6 pt-3 pb-6 rounded-lg" style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}>
                <div className="flex flex-row items-center justify-between">
                    <p
                        className="text-4xl font-bold capitalize"
                        style={{fontFamily: 'DIN Condensed'}}
                    >
                        {type === 'day' ? frenchLongFormat(date) : frenchMonthFormat(date)}
                    </p>
                    <Button onClick={handleCreateNewTime} content={buttonTitle}/>
                </div>
                <div className="mt-8">
                    <Table
                        data={data}
                        tableConfig={tablePopupConfig}
                        dropdownConfig={tablePopupDropdownConfig}
                        loading={calendarTableLoading}
                        onPageNumberChange={onPageNumberChange}
                        paginationMetadata={paginationMetadata}
                    />
                </div>
            </div>
        </Popup>
    );
};

export default CalendarTablePopup;
