import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import {fetchSelectData} from './../../../../utils';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import Button from '../../../../components/ui/Button';
import {MissionActions} from '../../../../actions';
import {UserActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from './../../../../utils';

const MissionInformationPopup = ({
    setView,
    userData,
    dealData,
    missionDataForUpdate,
    refreshDealData,
    refreshData,
    blueStyle,
    isInternal
}) => {

    //TO DO

    // use isInternal to not ask for daily rate for missions
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [users, setUsers] = useState(null);

    const status = [
        {
            id: 0,
            name: "Prévue"
        },
        {
            id: 1,
            name: "En cours"
        },
        {
            id: 2,
            name: "Terminée"
        }
    ]

    // -- react hook form --
    const defaultValues = {
        "ressource": null,
        "billable_days": "",
        "daily_rate": "",
        "status": status[0]
    };
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- functions that get data for select input --
    const handleGetRessources = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    };

    // Get dropdows data
    useEffect(() => {
        // ↓ responsable de mission dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();
    }, []);

    useEffect(() => {

        if (missionDataForUpdate && users) {
            const missionDataForUpdateCopy = {...missionDataForUpdate};

            if (missionDataForUpdate.ressource) {
                missionDataForUpdateCopy.ressource.name = `${missionDataForUpdate.ressource.first_name} ${missionDataForUpdate.ressource.last_name}`
            }

            reset({
                "ressource": missionDataForUpdateCopy.ressource,
                "billable_days": (+missionDataForUpdateCopy.billable_days).toString(),
                "daily_rate": (+missionDataForUpdateCopy.daily_rate).toString(),
                "status": status.filter((el) => el.id === missionDataForUpdateCopy.status)[0]
            })
        }
    }, [missionDataForUpdate, users])

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newMission = {
            "id": missionDataForUpdate?.id,
            "ressource_id": data.ressource.id,
            "deal_id": dealData ? dealData?.id : missionDataForUpdate?.deal?.id,
            "billable_days": data.billable_days,
            "daily_rate": data.daily_rate,
            "status": data.status.id
        }

        if (missionDataForUpdate) {
            const result = await MissionActions.updateMissionById(userData, newMission);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                // await refreshDealData()
                await refreshData();
                notify('La mission a été mis à jour');
                setView(false);
            }

            setLoading(false);
            setDisabled(false);
        }
        else {
            const result = await MissionActions.createMission(userData, newMission);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                await refreshDealData()
                notify('La mission a été créé');
                setView(false);
            }

            setLoading(false);
            setDisabled(false);
        }
    }

    return (
        <ContainerCard
            title={missionDataForUpdate ? 'Informations de la mission' : 'Nouvelle mission'}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-6">
                <div className='flex flex-col'>
                    <div className="mb-3">
                        <Controller
                            name="ressource"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le responsable de mission'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Ressource'
                                    placeholder='Ressource'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={users?.options}
                                    hasNextPage={users?.hasNextPage}
                                    fetchFunction={handleGetRessources}
                                    onChange={onChange}
                                    loading={!users}
                                    labelKeys={['first_name', 'last_name']}
                                    isSearchable={true}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="status"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le statut'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Statut'
                                    placeholder='Statut'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={status}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className="mb-3">
                        <Controller
                            name="billable_days"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nombre de jours'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Jours'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Jours"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="daily_rate"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le TJM'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='TJM'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="TJM"
                                    type="number"
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={missionDataForUpdate ? 'Modifier la mission' : 'Créer la mission'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    )
}

export default MissionInformationPopup;
