/* eslint-disable max-len */
export const productionLoadDto = {
    from (productionLoadResult) {

        const getRessourcesData = () => {
            let ressources = {};

            if (!productionLoadResult.ressources) {
                return ressources;
            }
            else {
                for (const key in productionLoadResult.ressources) {

                    let load = {};

                    if (productionLoadResult.ressources?.[key]?.load) {
                        for (const k in productionLoadResult.ressources[key].load) {
                            load[k] = productionLoadResult.ressources[key].load?.[k].production ?? 0;
                        }
                    }

                    ressources[key] = {
                        "currentdas": productionLoadResult.ressources?.[key]?.currentdas ?? '',
                        "total_load": productionLoadResult.ressources?.[key]?.total_available_load ?? 0,
                        "load": load,
                        "seniority": productionLoadResult.ressources?.[key]?.seniority ?? 'No Data'

                    }
                }

                return ressources;
            }
        }

        const getLoadData = () => {
            let dasS = {};

            if (!productionLoadResult.loads) {
                return dasS;
            }
            else {
                for (const key in productionLoadResult.loads) {
                    dasS[key] = {
                        "Junior": isNaN(parseInt(productionLoadResult.loads?.[key]?.["Junior"]?.production.toFixed(2))) ? 0 : parseInt(productionLoadResult.loads?.[key]?.["Junior"]?.production.toFixed(2)),
                        "Confirmé": isNaN(parseInt(productionLoadResult.loads?.[key]?.["Confirmé"]?.production.toFixed(2))) ? 0 : parseInt(productionLoadResult.loads?.[key]?.["Confirmé"]?.production.toFixed(2)),
                        "Sénior": isNaN(parseInt(productionLoadResult.loads?.[key]?.["Sénior"]?.production.toFixed(2))) ? 0 : parseInt(productionLoadResult.loads?.[key]?.["Sénior"]?.production.toFixed(2))
                    }
                }

                return dasS;
            }
        }

        const getAvailableLoadData = () => {
            let dasS = {};

            if (!productionLoadResult.available_load) {
                return dasS;
            }
            else {
                for (const key in productionLoadResult.available_load) {
                    dasS[key] = productionLoadResult.available_load?.[key]?.days ?? 0
                }

                return dasS;
            }
        }

        const getBillingData = () => {
            let dasS = {};

            if (!productionLoadResult.billing) {
                return dasS;
            }
            else {
                for (const key in productionLoadResult.billing) {
                    dasS[key] = {
                        "Junior": {
                            "daily_rate": productionLoadResult.billing?.[key]?.["Junior"]?.daily_rate ?? 0,
                            "billed_days": productionLoadResult.billing?.[key]?.["Junior"]?.billed_days ?? 0,
                            "produced_days": productionLoadResult.billing?.[key]?.["Junior"]?.produced_days ?? 0
                        },
                        "Confirmé": {
                            "daily_rate": productionLoadResult.billing?.[key]?.["Confirmé"]?.daily_rate ?? 0,
                            "billed_days": productionLoadResult.billing?.[key]?.["Confirmé"]?.billed_days ?? 0,
                            "produced_days": productionLoadResult.billing?.[key]?.["Confirmé"]?.produced_days ?? 0
                        },
                        "Senior": {
                            "daily_rate": productionLoadResult.billing?.[key]?.["Senior"]?.daily_rate ?? 0,
                            "billed_days": productionLoadResult.billing?.[key]?.["Senior"]?.billed_days ?? 0,
                            "produced_days": productionLoadResult.billing?.[key]?.["Senior"]?.produced_days ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "ressources": getRessourcesData(),
                "load": getLoadData(),
                "available_load": getAvailableLoadData(),
                "billing": getBillingData()
            }
        );
    }
}
