import ContributionToMargin from "./ContributionToMargin";
import GrossMarginProduced from "./GrossMarginProduced";
import ProductionByEtp from "./ProductionByEtp";

const ResultIndicatorsTab = ({
    resultIndicators,
    handleDatePickerChange,
    datePicker,
    dasKey
}) => {
    return (
        <div>
            <GrossMarginProduced
                data={resultIndicators}
                datePicker={datePicker}
                handleDatePickerChange={handleDatePickerChange}
                dasKey={dasKey}
            />
            <ContributionToMargin
                data={resultIndicators}
                dasKey={dasKey}
            />
            <ProductionByEtp
                data={resultIndicators}
                dasKey={dasKey}
            />
        </div>
    )
}

export default ResultIndicatorsTab;
