import {TagFilled} from '@ant-design/icons';

import DonutsChart from "../../../../components/ui/DonutsChart/DonutsChart";
import {tjmChartConfig} from '../../configs/productionTab/tjmChartConfig';
import SimpleCard from "../../../../components/ui/SimpleCard";

const TjmTabChildren = ({chartData, configData, realRate}) => {
    const TagIcon = <TagFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const data = [
        {
            type: 'Junior:',
            value: chartData.juniorValue
        },
        {
            type: 'Confirmé:',
            value: chartData.confirmedValue
        },
        {
            type: 'Sénior:',
            value: chartData.seniorValue
        }
    ];

    return (
        <div className='flex items-center justify-between'>
            <div className='w-[36%] h-[380px]'>
                <DonutsChart config={tjmChartConfig} data={data} label="J"/>
            </div>

            <SimpleCard
                width='w-[30%]'
                title={`${((configData.juniorValue + configData.confirmedValue + configData.seniorValue) / 3).toFixed(0)} €`}
                subTitle="TJM théorique"
                iconComponent={TagIcon}
                topComponent={<TopComponent junior={configData.juniorValue.toFixed()} confirmed={configData.confirmedValue.toFixed()} senior={configData.seniorValue.toFixed()}/>}
            />

            <SimpleCard
                width='w-[30%]'
                title={`${((realRate.juniorValue + realRate.confirmedValue + realRate.seniorValue) / 3).toFixed(0)} €`}
                subTitle="TJM réel"
                iconComponent={TagIcon}
                topComponent={<TopComponent junior={realRate.juniorValue.toFixed()} confirmed={realRate.confirmedValue.toFixed()} senior={realRate.seniorValue.toFixed()}/>}
            />
        </div>
    )
}

export default TjmTabChildren;

const TopComponent = ({junior, confirmed, senior}) => {
    return (
        <div className='flex justify-between bg-white rounded-b-md'>
            <div className='w-1/3 px-8 py-5'>
                <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                    <p className='text-gradient'>Junior</p>
                </div>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{junior} €</p>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/3 px-8 py-5'>
                <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                    <p className='text-gradient'>Confirmé</p>
                </div>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{confirmed} €</p>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/3 px-8 py-5'>
                <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                    <p className='text-gradient'>Sénior</p>
                </div>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{senior} €</p>
            </div>
        </div>
    )
}