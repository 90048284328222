import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {notify} from '../../components/ui/Toast/Toast';

import Table from '../../components/ui/Table';
import {ExpenseActions} from '../../actions';
import {purchasesTableConfig} from './config/purchasesTableConfig';
import Popup from '../../components/ui/Popup';
import PurchasesForm from './components/PurchasesForm';

const PurchasesPage = ({
    userData
}) => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("");
    const [paginationMetadata, setPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [loading, setLoading] = useState([]);
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
    const [popupView, setPopupView] = useState(false);
    const [expenseFormData, setExpenseFormData] = useState(null);

    // fetch data dunction
    const handleGetData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setLoading(true);
            setData([]);
        }

        const result = await ExpenseActions.getAllExpenses(
            userData,
            '',
            '',
            '',
            `&page_size=${paginationMetadata.pageSize}&report=null&page=${page}${filter}`
        );
    
        if (result.success) {
            setData(result.data);
            setPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (paginationMetadata.totalItems !== result.metadata.count) {
                setPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données de la table «Achats»");
        }

        setLoading(false);
    }

    // refresh data function
    const refreshData = async (removal = false) => {
        const checkedPage = (data.length === 1 && paginationMetadata.currentPage > 1 && removal) ?
            paginationMetadata.currentPage - 1 :
            paginationMetadata.currentPage;

        await handleGetData(checkedPage, false);
    }
    
    // delete
    const handleDeleteSpending = async (itemId) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(itemId);

        const result = await ExpenseActions.deleteDealExpense(userData, itemId);

        if (result.success) {
            await refreshData(true);
            notify('La dépense a été supprimée');
        }
        else {
            notify('Erreur lors de la suppression de la dépense');
        }

        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    }
    
    // edition
    const handleEditButtonClick = async (item) => {
        setExpenseFormData(item);
        setPopupView(true);
    }

    // filter setter
    const filterHandler = (query) => {
        setFilter(query ? `&${query}` : '');
    }

    useEffect(() => {
        handleGetData(1);
    }, [filter])
    
    // config
    const tableConfig = purchasesTableConfig(
        handleEditButtonClick,
        handleDeleteSpending,
        actionButtonLoading,
        actionButtonDisabled,
        filterHandler,
        userData
    );

    return (
        <>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Achats (${paginationMetadata.totalItems.toLocaleString()})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={handleGetData}
                loading={loading}
            />
            <Popup
                view={popupView}
                setView={setPopupView}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
            >
                <PurchasesForm
                    userData={userData}
                    expenseData={expenseFormData}
                    refreshData={refreshData}
                    setView={setPopupView}
                />
            </Popup>
        </>
    )
}

export default PurchasesPage;
