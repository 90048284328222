import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_ALL_PAYMENT_METHODS';

export async function getAllPaymentMethods(
    userToken: string,
    params: string,
    page: number
) {
    try {
        const response = await axios.get(
            `${URL}/payment-methods/?page=${page}&page_size=2000&active=true${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur de récuperation de la liste des méthodes de paiement',
            error: error
        });
    }
}
