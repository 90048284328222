export const daysOfWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

export const monthsOfYear = [
    'Jan',
    'Fév',
    'Mar',
    'Avr',
    'Mai',
    'Jun',
    'Jui',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export const rangeYears = (start, end) => {
    const years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }

    return years;
};

export const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

export const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 0);
};

export const getLastDayOfMonthFormated = (year, month) => {
    if (month === 11) {
        return (year + 1) + '-' + '01' + '-01';
    }
    else {
        return year + '-' + (month + 2 < 10 ? '0' : '') + (month + 2) + '-01';
    }
};

export const getFirstDayOfMonthFormated = (year, month) => {
    return year + '-' + (month + 1 < 10 ? '0' : '') + (month + 1) + '-01';
};

export const getFormatedDate = (year, month, day) => {
    return year + '-' + (month + 1 < 10 ? '0' : '') + (month + 1) + '-' + (day < 10 ? '0' : '') + day;
};

export const sortArrayByDate = (array) => {
    return array.sort((a, b) => {
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });
};

export const isVacation = (str) => {
    const regex = /conge?/i;

    return regex.test(str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
}
