import {Outlet, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {SET_DAS} from '../../reducers/DasReducer';
import {DealDasService} from '../../services';
import {useDispatch} from 'react-redux';
import {Toast, notify} from '../../components/ui/Toast/Toast';

const ReducerProvider = () => {
    const userProfileData = useSelector((state) => state.UserReducer.userProfileData);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            try {
        
                const getDealDas = await DealDasService.getAllDealDas({userToken: userProfileData.userToken}, '', 1);
        
                dispatch({type: SET_DAS,
                    payload: {
                        das:    getDealDas.results.sort((a, b) => a.id - b.id)
                    }});
            }
            catch (error) {
                notify('Erreur de récuperation des DAS');
            }
        })()
    }, [location])

    return <Outlet><Toast/></Outlet>
}

export default ReducerProvider;
