import React from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';
import {frenchFormat} from '../../../utils';

const PaymentsCard = ({data}) => {
    const getVatTotal = (amount, vat) => {
        if (!amount || !vat) return 0;

        const percent = amount * vat;

        return +percent.toFixed(2);
    }

    const getVatAmount = (amount, vat) => {
        if (!amount || !vat) return 0;

        const percent = (amount) * vat;

        return +(amount + percent).toFixed(2);
    }

    return (
        <ContainerCard
            title='Paiements'
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[361px]'
            margin='mx-6'
        >
            <div className='flex flex-col'>
                <div className='flex items-start justify-start mb-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Montant total HT</div>
                    <div className='text-[#212121] text-base'>{(+data?.billable + +data?.expenses).toFixed(2)} €</div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Montant TVA</div>
                    <div className='text-[#212121] text-base'>
                        {(getVatTotal(+data.billable, +data.billable_vat_rate) + getVatTotal(+data.expenses, +data.expenses_vat_rate)).toFixed(2)} €
                    </div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Montant total TTC</div>
                    <div className='text-[#212121] text-base'>
                        {(getVatAmount(+data.billable, +data.billable_vat_rate) + getVatAmount(+data.expenses, +data.expenses_vat_rate)).toFixed(2)} €
                    </div>
                </div>
                <hr className='w-12 my-5 border-[#212121]'/>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Paiement saisi</div>
                    <div className='text-[#212121] text-base'>{data.partial_payment} €</div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Date de paiement</div>
                    <div className='text-[#212121] text-base'>{data.payment_date ? frenchFormat(data.payment_date) : '-'}</div>
                </div>
            </div>
        </ContainerCard>
    )
}

export default PaymentsCard
