import React, {useState, useEffect} from 'react';

import Datepicker from '../../../components/ui/Datepicker';
import Table from '../../../components/ui/Table/Table';
import ContainerCard from '../../../components/ui/ContainerCard';
import {exportProductionDataToCsv, exportVacationDataToCsv} from '../functions/exportDataToCsv';

const TimeBalance = ({
    onButtonClick,
    onDatePickerChange,
    datePickerDefaultValue,
    productionsData,
    vacationsData,
    loadingTable,
    dealsConfig,
    productionsConfig,
    vacationsConfig
}) => {
    const [displayedSection, setDisplayedSection] = useState('production');
    const [totalProducedDays, setTotalProducedDays] = useState();
    const [totalBilledDays, setTotalBilledDays] = useState();

    // CSV export states
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });
    
    const [csvExportLoading, setCsvExportLoading] = useState(false);

    const handleExportProductionDataToCsv = () => exportProductionDataToCsv(
        productionsData,
        setCsvProps,
        setCsvExportLoading,
        datePickerDefaultValue
    )

    const handleExportVacationDataToCsv = () => exportVacationDataToCsv(
        vacationsData.productions,
        setCsvProps,
        setCsvExportLoading,
        datePickerDefaultValue
    )

    useEffect(() => {
        const totalProducedDays = productionsData.map((item) => {
            return (
                item.productions.reduce((accumulator, object) => {
                    return accumulator + parseInt(object.produced_days);
                }, 0)
            )
        }).reduce((accumulator, figure) => {
            return accumulator + parseInt(figure);
        }, 0)

        setTotalProducedDays(totalProducedDays)

        const totalBilledDays = productionsData.map((item) => {
            return (
                item.productions.reduce((accumulator, object) => {
                    return accumulator + parseInt(object.billed_days);
                }, 0)
            )
        }).reduce((accumulator, figure) => {
            return accumulator + parseInt(figure);
        }, 0)

        setTotalBilledDays(totalBilledDays)
    }, [productionsData])

    return (
        <ContainerCard
            title='Bilan des temps'
            buttonContent='Nouveau temps'
            onButtonClick={onButtonClick}
            downloadButtonDisplayed={
                (displayedSection === 'production' && productionsData.length > 0 && !loadingTable) ||
                (displayedSection === 'holidays' && (vacationsData?.productions && vacationsData?.productions.length > 0) && !loadingTable)
            }
            onDownloadButtonClick={displayedSection === 'production' ? handleExportProductionDataToCsv : handleExportVacationDataToCsv}
            csvProps={csvProps}
            csvExportLoading={csvExportLoading}
            overflow='inherit'
        >
            <div className="flex flex-row items-center justify-center w-full text-[#646464] text-base font-normal">
                <button
                    onClick={() => setDisplayedSection('production')}
                    className={`mx-14 py-[10px] ${displayedSection === 'production' ? 'text-[#DC3832] border-[#DC3832] border-b-2' : 'border-transparent border-b-2'}`}
                >
                        Production
                </button>
                <button
                    onClick={() => setDisplayedSection('holidays')}
                    className={`mx-14 py-[10px] ${displayedSection === 'holidays' ? 'text-[#DC3832] border-[#DC3832] border-b-2' : 'border-transparent border-b-2'}`}
                >
                        Congés
                </button>
            </div>
            <div className="w-full py-2">
                <Datepicker
                    type="range"
                    onChange={onDatePickerChange}
                    defaultValue={datePickerDefaultValue}
                />
            </div>
            {
                displayedSection === 'production' ?
                    <Table
                        tableConfig={dealsConfig}
                        dropdownConfig={productionsConfig}
                        dropdownChildrenKey='productions'
                        data={productionsData}
                        loading={loadingTable}
                        customData={[totalProducedDays, totalBilledDays]}
                    /> :
                    <Table
                        tableConfig={vacationsConfig}
                        dropdownConfig={null}
                        data={vacationsData?.productions}
                        loading={loadingTable}
                    />
            }
        </ContainerCard>

    );
};

export default TimeBalance;
