import {ACTION_CODE} from './_actionCode';
import {DealAbandonmentMotivesDao} from '../../dao';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_DEAL_ABANDONMENT_MOTIVES';

export async function getAllDealAbandonmentMotives(
    userData: Record<string, string>,
    params = '',
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllDealAbandonmentMotives - Start');

        const result = await DealAbandonmentMotivesDao.getAllDealAbandonmentMotives(userData, params, page);

        LoggerService.logInfo('Action - getAllDealAbandonmentMotives - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {

        LoggerService.logInfo('Action - getAllDealAbandonmentMotives - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: "Une erreur est survenue lors de la récupération de la liste des motifs d'abandon des affaires",
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
