import {ACTION_CODE} from './_actionCode';
import {CustomerService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_CUSTOMERS';

export async function getAllCustomers(
    userData: Record<string, string>,
    params = "",
    page = 1,
) {
    try {
        LoggerService.logInfo('Action - getAllCustomer - Start');

        const getAllCustomerResult = await CustomerService.getAllCustomers(userData, params, page);

        const {data, metadata} = getAllCustomerResult;

        LoggerService.logInfo('Action - getAllCustomer - End - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllCustomer - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des clients.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
