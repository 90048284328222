import {ACTION_CODE} from './_actionCode';
import {ExpenseAttachmentService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_EXPENSE_ATTACHMENT';

export async function createExpenseAttachment(userData: Record<string, any>, filePath: string, reportId: string) {
    try {
        LoggerService.logInfo('Action - createExpenseAttachment - Start');

        const createExpenseAttachmentResult = await ExpenseAttachmentService.createExpenseAttachment(userData, filePath, reportId);

        LoggerService.logInfo('Action - createExpenseAttachment - End - Success');

        return ResultModel.newSuccessResult(createExpenseAttachmentResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createExpenseAttachment - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
