const ExpenseDayEvents = ({dayData}) => {
    return (
        <div className='flex flex-col items-start justify-center h-full'>
            {
                dayData?.deals.map((event, index) => {
                    if (index < 2) {
                        return (
                            <div className="flex flex-row items-center mb-1.5" key={event.id}>
                                {/* <div className={`w-1.5 h-1.5 mr-2 rounded-full ${event.type.id === 1 ? 'bg-[#ced95f]' : 'bg-[#00abe9]' }`}/>
                                <p className="text-sm leading-none">
                                    {event.type.id === 1 ? 'IK' : 'Frais'} {+(event.amount)} €
                                </p> */}
                                <div className='w-1.5 h-1.5 mr-2 rounded-full bg-slate-500'/>
                                <p className='text-sm leading-none'>
                                    {event.short_name.length > 14 ? event.short_name.slice(0, 14) + '...' : event.short_name}
                                </p>
                            </div>
                        );
                    }
                })
            }
            <div className="flex items-center justify-between w-full h-5">
                {
                    (dayData?.amount) &&
                        <p className="text-left text-gradient w-fit">
                            {dayData?.amount} €
                        </p>
                }
                
                {
                    dayData?.count > 2 &&
                        <p className="flex items-center justify-center h-5 min-w-[20px] w-fit text-[9px] py-[1px] px-1 font-bold leading-none text-left text-white rounded-full roboto bg-[#212121]">
                            +{dayData?.count - 2}
                        </p>
                }
            </div>
            {/* {
                expenses.length > 2 &&
                <div className="h-5">
                    <p className="flex items-center justify-center h-5 min-w-[20px] w-fit text-[9px] py-[1px] px-1 font-bold leading-none text-left text-white rounded-full roboto bg-[#212121]">
                        +{expenses.length - 2}
                    </p>
                </div>
            } */}
        </div>

    );
};

export default ExpenseDayEvents;
