import {Link} from 'react-router-dom';
import {SnippetsFilled, SmileFilled, FrownFilled, TrophyFilled} from '@ant-design/icons';

import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import {transformThousandNumbers} from "../../../../utils/numbersConversion"

const TradeBalanceCards = ({
    doneSeulCount,
    doneMandataireCount,
    doneCotraitanceCount,
    doneSeulDays,
    doneMandataireDays,
    doneCotraitanceDays,
    doneSeulValue,
    doneMandataireValue,
    doneCotraitanceValue,
    wonSeulCount,
    wonMandataireCount,
    wonCotraitanceCount,
    wonSeulDays,
    wonMandataireDays,
    wonCotraitanceDays,
    wonSeulValue,
    wonMandataireValue,
    wonCotraitanceValue,
    lostSeulCount,
    lostMandataireCount,
    lostCotraitanceCount,
    lostSeulDays,
    lostMandataireDays,
    lostCotraitanceDays,
    lostSeulValue,
    lostMandataireValue,
    lostCotraitanceValue,
    successRateSeulCount,
    successRateMandataireCount,
    successRateCotraitanceCount,
    successRateSeulDays,
    successRateMandataireDays,
    successRateCotraitanceDays,
    successRateSeulValue,
    successRateMandataireValue,
    successRateCotraitanceValue

}) => {
    const SnippetIcon = <SnippetsFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;
    const SmileIcon = <SmileFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;
    const FrownIcon = <FrownFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;
    const TrophyIcon = <TrophyFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    let successRate = (+((wonSeulCount + wonMandataireCount + wonCotraitanceCount) / (doneSeulCount + doneMandataireCount + doneCotraitanceCount)  * 100).toFixed(1));
    successRate = (isNaN(successRate) || successRate === Infinity) ? 0 : successRate;

    return (
        <div  className='flex flex-wrap justify-between gap-y-5'>
            <ContainerCard showHeader={false} width='w-[49%]'>
                <Link to='proposals-made' className='block w-full'>
                    <SimpleCard
                        width='w-full'
                        background='white'
                        font='orange'
                        showLine={false}
                        shadow={false}
                        borderSize='border'
                        title={doneSeulCount + doneMandataireCount + doneCotraitanceCount}
                        subTitle={'Propositions faites'}
                        iconComponent={SnippetIcon}
                        topComponent={<TopComponent
                            seul={doneSeulCount}
                            mandataire={doneMandataireCount}
                            cotraitance={doneCotraitanceCount}/>}

                        bottomComponent={<BottomComponent
                            daySeul={+doneSeulDays.toFixed(2)}
                            dayMandataire={+doneMandataireDays.toFixed(2)}
                            dayCotraitance={+doneCotraitanceDays.toFixed(2)}
                            eurosSeul={doneSeulValue}
                            eurosMandataire={doneMandataireValue}
                            eurosCotraitance={doneCotraitanceValue}
                            daySum={+(doneSeulDays + doneMandataireDays + doneCotraitanceDays).toFixed(2)}
                            eurosSum={+(doneSeulValue + doneMandataireValue + doneCotraitanceValue).toFixed(2)}
                        />}
                    />
                </Link>
            </ContainerCard>

            <ContainerCard showHeader={false} width='w-[49%]'>
                <Link to='proposals-won' className='block w-full'>
                    <SimpleCard
                        width='w-full'
                        background='white'
                        font='orange'
                        showLine={false}
                        shadow={false}
                        borderSize='border'
                        title={wonSeulCount + wonMandataireCount + wonCotraitanceCount}
                        subTitle={'Propositions gagnées'}
                        iconComponent={SmileIcon}
                        topComponent={<TopComponent
                            seul={wonSeulCount}
                            mandataire={wonMandataireCount}
                            cotraitance={wonCotraitanceCount}
                        />}
                        bottomComponent={<BottomComponent
                            daySeul={+wonSeulDays.toFixed(2)}
                            dayMandataire={+wonMandataireDays.toFixed(2)}
                            dayCotraitance={+wonCotraitanceDays.toFixed(2)}
                            eurosSeul={wonSeulValue}
                            eurosMandataire={wonMandataireValue}
                            eurosCotraitance={wonCotraitanceValue}

                            daySum={+(wonSeulDays + wonMandataireDays + wonCotraitanceDays).toFixed(2)}
                            eurosSum={+(wonSeulValue + wonMandataireValue + wonCotraitanceValue).toFixed(2)}
                        />}
                    />
                </Link>
            </ContainerCard>

            <ContainerCard showHeader={false} width='w-[49%]'>
                <Link to='proposals-lost' className='block w-full'>
                    <SimpleCard
                        width='w-full'
                        background='white'
                        font='orange'
                        showLine={false}
                        shadow={false}
                        borderSize='border'
                        title={lostSeulCount + lostMandataireCount + lostCotraitanceCount}
                        subTitle={'Propositions perdues'}
                        iconComponent={FrownIcon}
                        topComponent={<TopComponent
                            seul={lostSeulCount}
                            mandataire={lostMandataireCount}
                            cotraitance={lostCotraitanceCount}
                        />}
                        bottomComponent={<BottomComponent
                            daySeul={+lostSeulDays.toFixed(2)}
                            dayMandataire={+lostMandataireDays.toFixed(2)}
                            dayCotraitance={+lostCotraitanceDays.toFixed(2)}
                            eurosSeul={lostSeulValue}
                            eurosMandataire={lostMandataireValue}
                            eurosCotraitance={lostCotraitanceValue}

                            daySum={+(lostSeulDays + lostMandataireDays + lostCotraitanceDays).toFixed(2)}
                            eurosSum={+(lostSeulValue + lostMandataireValue + lostCotraitanceValue).toFixed(2)}

                        />}
                    />
                </Link>
            </ContainerCard>

            <ContainerCard showHeader={false} width='w-[49%]'>
                <SimpleCard
                    width='w-full'
                    background='white'
                    font='orange'
                    showLine={false}
                    shadow={false}
                    borderSize='border'
                    title={`${successRate} %`}
                    subTitle={'Taux de réussite'}
                    iconComponent={TrophyIcon}
                    topComponent={<TopComponent
                        seul={`${(successRateSeulCount).toFixed(0)}%`}
                        mandataire={`${(successRateMandataireCount).toFixed(0)}%`}
                        cotraitance={`${(successRateCotraitanceCount).toFixed(0)}%`}
                    />}
                    bottomComponent={<BottomComponent
                        hideMedianValue={true}
                        daySeul={`${successRateSeulDays} %`}
                        dayMandataire={`${successRateMandataireDays} %`}
                        dayCotraitance={`${successRateCotraitanceDays} %`}
                        eurosSeul={`${successRateSeulValue} %`}
                        eurosMandataire={`${successRateMandataireValue} %`}
                        eurosCotraitance={`${successRateCotraitanceValue} %`}

                        daySum={`${+((successRateSeulDays + successRateMandataireDays + successRateCotraitanceDays) / 3).toFixed(1)} %`}
                        eurosSum={`${+((successRateSeulValue + successRateMandataireValue + successRateCotraitanceValue) / 3).toFixed(1)} %`}
                        totalPercentage={true}
                    />}
                />
            </ContainerCard>
        </div>
    )
}

export default TradeBalanceCards;

const TopComponent = ({seul, mandataire, cotraitance}) => {
    return (
        <div className='px-6'>
            <div className='flex justify-start bg-white gap-x-1'>
                <Badge color={'#f0a841'} content={`Seul: ${seul}`}/>
                <Badge color={'#dc3832'} content={`Mandataire: ${mandataire}`}/>
                <Badge color={'#646464'} content={`Cotraitance: ${cotraitance}`}/>
            </div>
            <div className='h-[1px] w-full bg-slate-800 mt-2 mb-8'/>
        </div>
    )
}

const BottomComponent = ({
    hideMedianValue,
    daySeul,
    dayMandataire,
    dayCotraitance,
    eurosSeul,
    eurosMandataire,
    eurosCotraitance,
    daySum,
    eurosSum,
    totalPercentage = false
}) => {
    let averageTotal = ((eurosSeul + eurosMandataire + eurosCotraitance) / (daySeul + dayMandataire + dayCotraitance)).toFixed(0);
    averageTotal = (isNaN(averageTotal) || averageTotal === Infinity) ? 0 : averageTotal;

    let averageSeul = (eurosSeul / daySeul).toFixed(0);
    averageSeul = (isNaN(averageSeul) || averageSeul === Infinity) ? 0 : averageSeul;

    let averageMandataire = (eurosMandataire / dayMandataire).toFixed(0);
    averageMandataire = (isNaN(averageMandataire) || averageMandataire === Infinity) ? 0 : averageMandataire;

    let averageCotraitance = (eurosCotraitance / dayCotraitance).toFixed(0);
    averageCotraitance = (isNaN(averageCotraitance) || averageCotraitance === Infinity) ? 0 : averageCotraitance;

    return (
        <div className='flex justify-between bg-white border-t border-gray-200 rounded-b-md'>
            <div className={`${hideMedianValue ? "w-1/2" : "w-1/3"} px-5 py-5`}>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{daySum}</p>
                <p className='text-gradient'>Jours</p>
                <div className='w-full bg-slate-800 h-[1px] my-2'/>
                <div>
                    <Badge color={'#f0a841'} content={`Seul: ${daySeul}`}/>
                    <Badge color={'#dc3832'} content={`Mandataire: ${dayMandataire}`}/>
                    <Badge color={'#646464'} content={`Cotraitance: ${dayCotraitance}`}/>
                </div>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className={`${hideMedianValue ? "w-1/2" : "w-1/3"} px-5 py-5`}>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{totalPercentage ? eurosSum : transformThousandNumbers(eurosSum)} </p>
                <p className='text-gradient'>Euros</p>
                <div className='w-full bg-slate-800 h-[1px] my-2'/>
                <div>
                    <Badge color={'#f0a841'} content={`Seul: ${totalPercentage ? eurosSeul : transformThousandNumbers(eurosSeul)}`}/>
                    <Badge color={'#dc3832'} content={`Mandataire: ${totalPercentage ? eurosMandataire : transformThousandNumbers(eurosMandataire)}`}/>
                    <Badge color={'#646464'} content={`Cotraitance: ${totalPercentage ? eurosCotraitance : transformThousandNumbers(eurosCotraitance)}`}/>
                </div>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            {
                !hideMedianValue ?
                    <div className='w-1/3 px-5 py-5'>
                        <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>
                            {averageTotal}
                        </p>
                        <p className='text-gradient'>Valeur moyenne</p>
                        <div className='w-full bg-slate-800 h-[1px] my-2'/>
                        <div>
                            <Badge color={'#f0a841'} content={`Seul: ${averageSeul}`}/>
                            <Badge color={'#dc3832'} content={`Mandataire: ${averageMandataire}`}/>
                            <Badge color={'#646464'} content={`Cotraitance: ${averageCotraitance}`}/>
                        </div>
                    </div> : null
            }
        </div>
    )
}
