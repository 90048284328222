import {ACTION_CODE} from './_actionCode';
import {ActivitiesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'GET_ALL_ACTIVITIES';

export async function getAllActivities(token = "", params = "", page = 1) {
    try {
        LoggerService.logInfo('Action - getAllActivities - Start');

        const result = await ActivitiesService.getAllActivities(token, params, page);

        const {data, metadata} = result;

        LoggerService.logInfo('Action - getAllActivities - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllActivities - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des activités',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
