import {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import {DealActions} from '../../actions';
import {notify} from '../../components/ui/Toast/Toast';
import {standardFormat} from '../../utils';
import TabCard from '../../components/ui/TabCard';
import {pageTabConfig} from './configs/pageTables/pageTabConfig';

const ProposalsMadePage = ({
    dasS,
    userData,
    handleMissionNavigation,
    dates
}) => {
    const [data, setData] = useState([]);
    const [paginationMetadata, setPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [tableLoading, setTableLoading] = useState(true);
    const [filter, setFilter] = useState("");
    const [dasKey, setDasKey] = useState(null);

    const isMounted = useRef(false);

    const handleGetData = async (page) => {
        setTableLoading(true);

        const dealStatus = "status=300&status=400&status=500&status=600&status=700&status=900"

        const intervalDates = `response_after=${standardFormat(dates.startDate)}&response_before=${standardFormat(dates.endDate)}`

        const currentDas = dasKey ? `&das=${dasKey}` : "";

        const result = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${paginationMetadata.pageSize}${currentDas}&${dealStatus}&${intervalDates}${filter}`,
            page
        );
    
        if (result.success) {
            setData(result.data);
            setPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (paginationMetadata.totalItems !== result.metadata.count) {
                setPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données de la table «Propositions faites»");
        }

        setTableLoading(false);
    };

    const handleChangeDasKey = (das) => {
        setDasKey(das.label === "Cabinet" ? '' : das.label);
    }
    
    const filterDealList = (query) => {
        setFilter(query ? `&${query}` : '');
    }
    
    useEffect(() => {
        setFilter("");
        handleGetData(1);
    }, [dasKey]);
    
    useEffect(() => {
        if (isMounted.current) {
            handleGetData(1);
        }
        else {
            isMounted.current = true;
        }
    }, [filter]);

    const tableType = "proposalsMade";

    const tableConfig = {handleMissionNavigation, filterDealList, tableType, userData, dasKey, handleGetData, tableLoading, paginationMetadata};

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/codev'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Propositions faites (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <TabCard
                config={pageTabConfig(data, "deals", dasS, tableConfig)}
                onChange={handleChangeDasKey}
            />
        </>
    )
}

export default ProposalsMadePage;
