import {ContractorDao} from '../../dao';
import {ErrorModel, ContractorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_CONTRACTORS';

export async function getAllContractors(
    userData: Record<string, string>,
    params: string,
    page: number,
) {
    try {
        const contractors = await ContractorDao.getAllContractors(userData.userToken, params, page);

        const contractorParsed = contractors.results.map((contractor: Record<string, any>) => ContractorModel.parseContractor(contractor));

        const metadata = {
            count: contractors.count,
            next: contractors.next,
            previous: contractors.previous
        };

        return {
            metadata,
            data: contractorParsed
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
