import React, {useState} from 'react';

import {exportProductionDataToCsv, exportExpenseDataToCsv} from './../../functions/exportDataToCsv';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Datepicker from '../../../../components/ui/Datepicker';
import Table from '../../../../components/ui/Table';
import DealPopupProduction from '../../popups/productionsAndExpensesPopup/CreateProductionPopup/DealPopupProduction';
import CreateNewExpensePopup from '../../../dealsPage/popups/productionsAndExpensesPopup/CreateExpensePopup/CreateNewExpensePopup';
import {firstDayMonth, lastDayMonth, standardFormat} from '../../../../utils';
import {dealProductionsTableConfig} from '../../configs/sectionConfigs/dealProductionsTableConfig';
import {dealExpensesTableConfig} from '../../configs/sectionConfigs/dealExpensesTableConfig';
import { ExpenseActions, ProductionActions } from '../../../../actions';
import {Toast, notify} from '../../../../components/ui/Toast/Toast';
import Popup from '../../../../components/ui/Popup/Popup';

const ProductionsAndExpensesSection = ({
    userData,
    dealData,
    datePicker,
    setDatePicker,
    productionsData,
    setProductionsFilterQuery,
    productionsPaginationMetadata,
    handleGetAllProductions,
    setExpensesFilterQuery,
    handleGetAllExpenses,
    expensesPaginationMetadata,
    expensesData,
    buttonsDisabled,
    setButtonsDisabled,
    blueStyle,
    refreshProductionsData,
    refreshExpensesData,
    datePickerLoading,
    setDatePickerLoading,
    cannotBeModified
}) => {

    const [deleteProductionLoading, setDeleteProductionLoading] = useState(null);
    const [deleteExpenseLoading, setDeleteExpenseLoading] = useState(null);

    const [viewProduction, setViewProduction] = useState(false);
    const [viewExpense, setViewExpense] = useState(false);
    const [productionToBeUpdated, setProductionToBeUpdated] = useState(null);
    const [expenseToBeUpdated, setExpenseToBeUpdated] = useState(null);

    const [csvExportLoading, setCsvExportLoading] = useState(false);
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });

    const [fromDeal, setFromDeal] = useState(false);

    const handleDatePickerChange = (e) => {
        setDatePickerLoading(true);
        setDatePicker({
            startDate: e.startDate ? standardFormat(e.startDate) : firstDayMonth(new Date()),
            endDate: e.endDate ? standardFormat(e.endDate) : lastDayMonth(new Date())
        })
    }

    // Production functions
    const handleEditProductionClick = (item) => {
        setViewProduction(true);
        setProductionToBeUpdated(item);
    }

    const handleDeleteProductionClick = async (id) => {
        setButtonsDisabled(true);
        setDeleteProductionLoading(id);

        const result = await ProductionActions.deleteProduction(userData, id);

        if (!result.success) {
            notify('Erreur lors de la suppression du temps');
        }
        else {
            await refreshProductionsData(true);
            notify('Votre temps à bien été supprimé');
        }

        // after delete request
        setDeleteProductionLoading(null);
        setButtonsDisabled(false);
    }

    // Expense functions
    const handleEditExpenseClick = (item) => {
        setViewExpense(true);
        setExpenseToBeUpdated(item);
    }

    const handleDeleteExpenseClick = async (item) => {
        setButtonsDisabled(true);
        setDeleteExpenseLoading(item.id);

        const result = await ExpenseActions.deleteExpense(userData, item);

        if (!result.success) {
            notify('Erreur lors de la suppression du frais');
        }
        else {
            await refreshExpensesData(true);
            notify('Votre frais à bien été supprimé');
        }

        // after delete request
        setDeleteExpenseLoading(null);
        setButtonsDisabled(false);
    }

    const handleNewProductionButtonClick = () => {
        setProductionToBeUpdated(null);
        setViewProduction(true);
    }

    const filterProductionList = async (query) => {
        setProductionsFilterQuery(query);
    }

    const filterExpenseList = async (query) => {
        setExpensesFilterQuery(query);
    }

    // Configs
    const productionsConfig = dealProductionsTableConfig(
        handleEditProductionClick,
        handleDeleteProductionClick,
        buttonsDisabled,
        deleteProductionLoading,
        blueStyle,
        filterProductionList,
        userData,
        cannotBeModified
    );

    const expensesConfig = dealExpensesTableConfig(
        handleEditExpenseClick,
        handleDeleteExpenseClick,
        buttonsDisabled,
        deleteExpenseLoading,
        blueStyle,
        filterExpenseList,
        userData,
        cannotBeModified
    );

    const handleExportProductionDataToCsv = () => exportProductionDataToCsv(
        productionsData,
        setCsvProps,
        setCsvExportLoading,
        datePicker
    );

    const handleExportExpenseDataToCsv = () => exportExpenseDataToCsv(
        expensesData,
        setCsvProps,
        setCsvExportLoading,
        datePicker
    );

    return (
        <div>
            <Toast/>
            <div className='mb-8 rounded-lg w-fit'>
                <Datepicker
                    type="range"
                    onChange={handleDatePickerChange}
                    defaultValue={datePicker}
                    blueStyle={blueStyle}
                />
            </div>
            
            <ContainerCard
                title='Bilan des temps'
                buttonContent='Nouveau temps'
                onButtonClick={handleNewProductionButtonClick}
                blueStyle={blueStyle}
                downloadButtonDisplayed={(productionsData.length > 0 && !csvExportLoading)}
                onDownloadButtonClick={handleExportProductionDataToCsv}
                csvProps={csvProps}
                csvExportLoading={csvExportLoading}
                overflow='inherit'
                coloredButtonDisplayed={!cannotBeModified}
            >
                <Popup
                    view={viewProduction}
                    setView={setViewProduction}
                    width={'1098px'}
                    xOverflow=''
                    yOverflow=''
                >
                    <DealPopupProduction
                        userData={userData}
                        dealData={dealData}
                        setView={setViewProduction}
                        productionToBeUpdated={productionToBeUpdated}
                        refreshProductionsData={refreshProductionsData}
                        blueStyle={blueStyle}
                    />
                </Popup>
                
                <Table
                    data={productionsData ? productionsData : []}
                    tableConfig={productionsConfig}
                    height='h-[400px]'
                    loading={datePickerLoading}
                    paginationMetadata={productionsPaginationMetadata}
                    onPageNumberChange={handleGetAllProductions}
                />
            </ContainerCard>
            
            <ContainerCard
                title='Bilan des frais'
                buttonContent='Nouveau frais'
                onButtonClick={() => {
                    setViewExpense(true)
                    setFromDeal(dealData)
                }}
                margin='mt-6'
                blueStyle={blueStyle}
                downloadButtonDisplayed={(expensesData.length > 0 && !csvExportLoading)}
                onDownloadButtonClick={handleExportExpenseDataToCsv}
                csvProps={csvProps}
                csvExportLoading={csvExportLoading}
                overflow='inherit'
                coloredButtonDisplayed={!cannotBeModified}
            >
                <div className='mt-2'/>
                <CreateNewExpensePopup
                    dealData={dealData}
                    view={viewExpense}
                    setView={setViewExpense}
                    userData={userData}
                    fromDeal={fromDeal}
                    expenseToBeUpdated={expenseToBeUpdated}
                    setExpenseToBeUpdated={setExpenseToBeUpdated}
                    refreshExpensesData={refreshExpensesData}
                    blueStyle={blueStyle}
                />
                <Table
                    data={expensesData ? expensesData : []}
                    tableConfig={expensesConfig}
                    height='h-[400px]'
                    loading={datePickerLoading}
                    paginationMetadata={expensesPaginationMetadata}
                    onPageNumberChange={handleGetAllExpenses}
                />
            </ContainerCard>
        </div>
    )
}

export default ProductionsAndExpensesSection;
