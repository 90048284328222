import Layout from "../../components/layout/Layout";
import { Toast } from "../../components/ui/Toast/Toast";
import EmailForm from "./components/EmailForm";
import PasswordForm from "./components/PasswordForm";

const UserAccountPage = () => {
    return (
        <Layout>
            <Toast/>
            <div className="px-8 mt-8">
                <p style={{fontFamily: 'DIN condensed'}} className="mt-10 mb-6 text-4xl ">Mon compte</p>

                <EmailForm/>
                <PasswordForm/>
            </div>
        </Layout>
    )
}

export default UserAccountPage;