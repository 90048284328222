import ActionsButton from '../../../../components/ui/ActionsButton';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

export const activityConfig = (
    handleDelete,
    setIsPopupOpen,
    setToBeUpdated,
    actionButtonLoading,
    actionButtonDisabled,
    sectionName
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: true,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'name',
                    width: 'w-[40%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center text-left justify-left">
                                {item.name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Travail',
                    value: 'work',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {
                                    item.work ? "Présence" : "Temps d'absence"
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: 'notes',
                    width: 'w-[40%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-left">
                                {item.notes ? item.notes : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row justify-center items-left">
                                {
                                    item.parentStatus === 3 ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </> :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => {
                                                    setToBeUpdated(item);
                                                    setIsPopupOpen(true);
                                                }}
                                                disabled={actionButtonDisabled}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={actionButtonLoading === item.id}
                                                onClick={() => handleDelete(item.id)}
                                                disabled={actionButtonDisabled}
                                                deleteConfirmationQuestion={`Souhaitez-vous supprimer ${sectionName.gender === "masculin" ? "ce" : "cette"} ${sectionName.name} ?`}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
