import {useState} from 'react';

import {TrainingAttachmentActions,  FileActions} from './../../../../actions'
import FileDragNDropInput from './../../../../components/ui/Inputs/FileDragNDropInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import checkboxIcon from "../../../../assets/icons/blueStyle/checkbox-input-blue.png";
import checkboxEmptyIcon from "../../../../assets/icons/page/checkbox-empty.png";
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const SatisfactionAttachmentsPopup = ({
    userData,
    setView,
    refreshData,
    trainingId,
    evaluationAttachements,
    satisfactionAttachements,
    assessmentAttachements,
    otherSatisfactionAttachements
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [evaluation, setEvaluation] = useState([]);
    const [satisfaction, setSatisfaction] = useState([]);
    const [assessment, setAssessment] = useState([]);
    const [otherSatisfaction, setOtherSatisfaction] = useState([]);
    const [errors, setErrors] = useState(null);

    const onSubmit = async () => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newEvaluation = evaluation.map(file => ({file: file, name: file.name, type: 'evaluation'}));
        const newSatisfaction = satisfaction.map(file => ({file: file, name: file.name, type: 'satisfaction_survey'}));
        const newAssessment = assessment.map(file => ({file: file, name: file.name, type: 'assessment'}));
        const newOtherSatisfaction = otherSatisfaction.map(file => ({file: file, name: file.name, type: 'other_satisfaction'}));

        const allFiles = [...newEvaluation, ...newSatisfaction, ...newAssessment, ...newOtherSatisfaction];

        if (allFiles.length > 0) {
            let filesResult = [];

            for (let file of allFiles) {
                const newFile = {
                    "type": file.file.type,
                    "name": file.name,
                    "file": file.file
                }

                const result = await FileActions.createFile(userData, newFile);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    filesResult.push({...result.data, type: file.type});
                }
            }

            await Promise.all(filesResult.map(async file => {
                const payload = {
                    file_id: file.id,
                    training_id: trainingId,
                    type: file.type
                };

                const result = await TrainingAttachmentActions.createTrainingAttachment(userData, payload);

                if (!result.success) {
                    setErrors(getErrors(result));
                }
                else {
                    await refreshData();
                    notify(`Les documents ont été chargés avec succès.`);
                    setView(false);
                }
            }));

            setDisabled(false);
            setLoading(false);
        }
    }

    const handleChangeEvaluation = (file) => {
        setEvaluation(file);
    }

    const handleChangeSatisfaction = (file) => {
        setSatisfaction(file);
    }

    const handleChangeAssessment = (file) => {
        setAssessment(file);
    }

    const handleChangeOtherSatisfaction = (file) => {
        setOtherSatisfaction(file);
    }

    return (
        <ContainerCard
            coloredButtonDisplayed={false}
            title="Checklist qualité - Satisfaction et bilan"
        >
            <div className="flex flex-col pt-6 mb-5 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(evaluationAttachements.length || evaluation.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Évaluation</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeEvaluation}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col pt-6 mt-6 mb-5 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(satisfactionAttachements.length || satisfaction.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Questionnaire de satisfaction</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeSatisfaction}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col pt-6 mt-6 mb-5 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(assessmentAttachements.length || assessment.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Bilan de la formation</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeAssessment}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col pt-6 mt-6 mb-8 border-t border-gray-200 gap-y-6">
                <div className='flex items-center justify-start mb-2'>
                    <img src={(otherSatisfactionAttachements.length || otherSatisfaction.length) ? checkboxIcon : checkboxEmptyIcon} alt='check' className='w-4 mr-2'/>
                    <div className='text-[#212121]'>Autres</div>
                </div>
                <FileDragNDropInput
                    onChange={handleChangeOtherSatisfaction}
                    maxCount={5}
                    color="#00ABE9"
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'blue'}
                        content={`Ajouter`}
                        onClick={onSubmit}
                        loading={loading}
                        disabled={!(evaluation.length || satisfaction.length || assessment.length || otherSatisfaction.length)}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default SatisfactionAttachmentsPopup;
