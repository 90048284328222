import {ClockCircleFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import {transformThousandNumbers} from '../../../../utils';

const ProductionCosts = ({data, getPercent}) => {
    const ClockCircleIcon = <ClockCircleFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = (type) => {
        let sum = 0;

        for(const key in data) {
            sum += data[key]['junior'][type];
            sum += data[key]['confirmed'][type];
            sum += data[key]['senior'][type];
        }

        return sum.toFixed();
    }

    return (
        <div className='my-10'>
            <LineTitle title='Coûts de production'/>
            <div className='flex flex-row mt-6'>
                <SimpleCard
                    width='w-1/2'
                    height='h-fit'
                    iconComponent={ClockCircleIcon}
                    title={transformThousandNumbers(getSum('cost'))}
                    sideTitle={`(${getPercent(getSum('cost'))}% de la marge brute)`}
                    subTitle="Temps facturables et frais"
                    background='orange'
                    topComponent={<TopComponent data={data} type='cost' getPercent={getPercent}/>}
                    bottomComponent={<BottomComponent data={data} type='cost' getPercent={getPercent}/>}
                />
                <div className='mx-5'/>
                <SimpleCard
                    width='w-1/2'
                    height='h-fit'
                    iconComponent={ClockCircleIcon}
                    title={transformThousandNumbers(getSum('time'))}
                    sideTitle={`(${getPercent(getSum('time'))}% de la marge brute)`}
                    subTitle="Temps de production"
                    background='orange'
                    topComponent={<TopComponent data={data} type='time' getPercent={getPercent}/>}
                    bottomComponent={<BottomComponent data={data} type='time' getPercent={getPercent}/>}
                />
            </div>
        </div>
    )
}

export default ProductionCosts;

const TopComponent = ({data, type, getPercent}) => {

    const getStatusSum = (status) => {
        let sum = 0;

        for(const key in data) {
            sum += data[key][status][type];
        }

        return sum;
    }

    return (
        <div className='flex flex-row items-center justify-between w-full bg-transparent'>
            <div className='w-1/3 pb-3 px-9'>
                <span className='text-sm font-medium text-white'>Junior</span>
                <div className='h-[1px] w-full mt-1 mb-2 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-white' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(getStatusSum('junior'))} `}
                    </span>
                    <span className='text-sm font-normal text-white'>
                        {`(${getPercent(getStatusSum('junior'))}%)`}
                    </span>
                </div>
            </div>
            <div className='w-1/3 pb-3 px-9'>
                <span className='text-sm font-medium text-white'>Confirmé</span>
                <div className='h-[1px] w-full mt-1 mb-2 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-white' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(getStatusSum('confirmed'))} `}
                    </span>
                    <span className='text-sm font-normal text-white'>
                        {`(${getPercent(getStatusSum('confirmed'))}%)`}
                    </span>
                </div>
            </div>
            <div className='w-1/3 pb-3 px-9'>
                <span className='text-sm font-medium text-white'>Sénior</span>
                <div className='h-[1px] w-full mt-1 mb-2 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-white' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(getStatusSum('senior'))} `}
                    </span>
                    <span className='text-sm font-normal text-white'>
                        {`(${getPercent(getStatusSum('senior'))}%)`}
                    </span>
                </div>
            </div>
        </div>
    )
}

const BottomComponent = ({data, type, getPercent}) => {

    const getDasSum = (dasObject) => {
        return dasObject.junior[type] + dasObject.confirmed[type] + dasObject.senior[type];
    }

    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            juniorValue: transformThousandNumbers(data[key].junior[type]),
            juniorPercent: getPercent(data[key].junior[type]),
            confirmedValue: transformThousandNumbers(data[key].confirmed[type]),
            confirmedPercent: getPercent(data[key].confirmed[type]),
            seniorValue: transformThousandNumbers(data[key].senior[type]),
            seniorPercent: getPercent(data[key].senior[type]),
            totalValue: transformThousandNumbers(getDasSum(data[key])),
            totalPercent: getPercent(getDasSum(data[key]))
        })
    }

    return (
        <div className='flex flex-col w-full'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                flex flex-row items-end w-full bg-white h-[116px] px-9 py-4
                                ${((index + 1) === dasS.length) ? '' : 'border-b-[1px] border-[#e2e2e2]'}
                            `}
                        >
                            <div className='w-1/3'>
                                <div className='flex flex-col justify-start'>
                                    <span className='mb-2 text-sm font-medium text-gradient'>{el.das}</span>
                                    <div className='flex items-center justify-center w-full border border-[#f0f0f0]'>
                                        <div className='flex flex-col items-start'>
                                            <span className='text-sm font-medium text-[#212121]'>Junior</span>
                                            <div>
                                                <span className='text-xl font-bold text-[#212121]' style={{fontFamily: 'Din Condensed'}}>
                                                    {`${el.juniorValue} `}
                                                </span>
                                                <span className='text-xs font-normal text-[#212121]'>
                                                    {`(${el.juniorPercent}%)`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'>
                                <div className='flex items-center justify-center w-full border border-[#f0f0f0]'>
                                    <div className='flex flex-col items-start'>
                                        <span className='text-sm font-medium text-[#212121]'>Confirmé</span>
                                        <div>
                                            <span className='text-xl font-bold text-[#212121]' style={{fontFamily: 'Din Condensed'}}>
                                                {`${el.confirmedValue} `}
                                            </span>
                                            <span className='text-xs font-normal text-[#212121]'>
                                                {`(${el.confirmedPercent}%)`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'>
                                <div className='flex flex-col items-center'>
                                    <div>
                                        <span className='text-2xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                            {`${el.totalValue} `}
                                        </span>
                                        <span className='text-xs font-normal text-gradient'>
                                            {`(${el.totalPercent}%)`}
                                        </span>
                                    </div>
                                    <div className='flex items-center justify-center w-full border border-[#f0f0f0]'>
                                        <div className='flex flex-col items-start'>
                                            <span className='text-sm font-medium text-[#212121]'>Sénior</span>
                                            <div>
                                                <span className='text-xl font-bold text-[#212121]' style={{fontFamily: 'Din Condensed'}}>
                                                    {`${el.seniorValue} `}
                                                </span>
                                                <span className='text-xs font-normal text-[#212121]'>
                                                    {`(${el.seniorPercent}%)`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
