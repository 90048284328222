import ActionsButton from '../../../../components/ui/ActionsButton';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import Badge from '../../../../components/ui/Badge/Badge';

export const vehicleTypeConfig = (
    handleDelete,
    setIsPopupOpen,
    setToBeUpdated,
    actionButtonLoading,
    actionButtonDisabled,
    sectionName
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: true,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'type.name',
                    width: 'w-[18.2%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row text-left items-left justify-left">
                                {item.name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Distance Minimale',
                    width: 'w-[18.2%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row justify-center text-left items-left">
                                {item.min_distance}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Distance Maximale',
                    value: 'type.name',
                    width: 'w-[18.2%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row justify-center text-left items-left">
                                {item.max_distance ?? '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Formule',
                    value: 'type.name',
                    width: 'w-[18.2%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row justify-center text-left items-left">
                                {item.formula}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[18.2%]',
                    sort: true,
                    component: (item) => {
                        const invoiceStatus = () => {
                            switch (item.is_active) {
                                case true:
                                    return {status: 'Actif', color: "#CED95F"};
                                case false:
                                    return {status: 'Archivé', color: '#646464'};
                                default:
                                    return {status: 'Indéfini', color: '#212121'};
                            }
                        };

                        return (
                            <div className="flex flex-row justify-center items-left">
                                <Badge content={invoiceStatus().status} color={invoiceStatus().color}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row justify-center items-left">
                                {
                                    item.parentStatus === 3 ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </> :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => {
                                                    setToBeUpdated(item);
                                                    setIsPopupOpen(true);
                                                }}
                                                disabled={actionButtonDisabled}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={actionButtonLoading === item.id}
                                                onClick={() => handleDelete(item.id)}
                                                disabled={actionButtonDisabled}
                                                deleteConfirmationQuestion={`Souhaitez-vous supprimer ${sectionName.gender === "masculin" ? "ce" : "cette"} ${sectionName.name} ?`}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
