export const costs = {

    from(costsResult) {

        const getBusinessData = () => {
            let dasS = {};

            if (!costsResult.business) {
                return dasS;
            }
            else {
                for (const key in costsResult.business) {
                    dasS[key] = {
                        "junior": costsResult.business?.[key]?.junior ?? 0,
                        "confirmed": costsResult.business?.[key]?.confirmed ?? 0,
                        "senior": costsResult.business?.[key]?.senior ?? 0
                    }
                }

                return dasS;
            }
        }

        const getAcquisitionData = () => {
            let dasS = {};

            if (!costsResult.acquisition) {
                return dasS;
            }
            else {
                for (const key in costsResult.acquisition) {
                    dasS[key] = costsResult.acquisition?.[key] ?? 0
                }

                return dasS;
            }
        }

        const getProductionData = () => {
            let dasS = {};

            if (!costsResult.production) {
                return dasS;
            }
            else {
                for (const key in costsResult.production) {
                    dasS[key] = {
                        "junior": {
                            "time": costsResult.production?.[key]?.junior?.time ?? 0,
                            "cost": costsResult.production?.[key]?.junior?.cost ?? 0
                        },
                        "confirmed": {
                            "time": costsResult.production?.[key]?.confirmed?.time ?? 0,
                            "cost": costsResult.production?.[key]?.confirmed?.cost ?? 0
                        },
                        "senior": {
                            "time": costsResult.production?.[key]?.senior?.time ?? 0,
                            "cost": costsResult.production?.[key]?.senior?.cost ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "gross_margin": costsResult.gross_margin ?? 0,
                "support": {
                    "salary": costsResult.support?.salary ?? 0,
                    "fees": costsResult.support?.fees ?? 0,
                    "expenses": costsResult.support?.expenses ?? 0
                },
                "management": {
                    "salary": costsResult.management?.salary ?? 0,
                    "fees": costsResult.management?.fees ?? 0,
                    "expenses": costsResult.management?.expenses ?? 0
                },
                "business": getBusinessData(),
                "acquisition": getAcquisitionData(),
                "production": getProductionData()
            }
        )
    }
}
