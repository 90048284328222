import {ACTION_CODE} from './_actionCode';
import {InvoiceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_INVOICE';

export async function createInvoice(userData: Record<string, any>, invoice: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createInvoice - Start');

        const createInvoiceResult = await InvoiceService.createInvoice(userData, invoice);

        LoggerService.logInfo('Action - createInvoice - End - Success');

        return ResultModel.newSuccessResult(createInvoiceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createInvoice - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une facture.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
