import {ACTION_CODE} from './_actionCode';
import {ExpenseTypeService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_EXPENSETYPE';

export async function updateExpenseType(
    userData: Record<string, any>,
    expenseTypeId: number,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateExpenseTypeById - Start');

        const updateExpenseTypeByIdResult =
            await ExpenseTypeService.updateExpenseType(userData, expenseTypeId, payloadUpdate);

        LoggerService.logInfo('Action - updateExpenseTypeById - End - Success');

        return ResultModel.newSuccessResult(updateExpenseTypeByIdResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateExpenseTypeById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
