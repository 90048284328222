import {ACTION_CODE} from './_actionCode';
import {ContractorService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_CUSTOMERS';

export async function updateContractor(
    userData: Record<string, string>,
    updated: Record<string, string>,
    contractorId: number
) {
    try {
        LoggerService.logInfo('Action - updateContractor - Start');

        const updateContractorResult = await ContractorService.updateContractor(userData, updated, contractorId);

        LoggerService.logInfo('Action - updateContractor - End - Success');

        return ResultModel.newSuccessResult(updateContractorResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateContractor - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la modification du client.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
