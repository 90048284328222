export const grossMarginProducedTableData = (data, dasKey) => {

    if (!data[dasKey]) {
        return [];
    }
    else {
        
        const getGrossMarginPercentage = () => {
            if (!data[dasKey].gross_margin || !data[dasKey].revenue) {
                return 0;
            }
            else {
                return ((data[dasKey].gross_margin / data[dasKey].revenue) * 100).toFixed();
            }
        }

        return [
            {
                id: 1,
                name: dasKey,
                turnover: `${data[dasKey].revenue} €`,
                sub_contracting: `${data[dasKey].contractors} €`,
                expense_report: `${data[dasKey].expenses} €`,
                direct_purchases: `${data[dasKey].procurements} €`,
                gross_margin: `${data[dasKey].gross_margin} €`,
                gross_margin_percentage: `${getGrossMarginPercentage()}%`
            }
        ]
    }
}
