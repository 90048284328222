import {ACTION_CODE} from './_actionCode';
import {SubsidiaryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_SUBSIDIARY';

export async function deleteSubsidiaryById(userData: Record<string, any>, subsidiaryId: number) {
    try {
        LoggerService.logInfo('Action - deleteSubsidiaryById - Start');

        const deleteSubsidiaryByIdResult = await SubsidiaryService.deleteSubsidiary(userData, subsidiaryId);

        LoggerService.logInfo('Action - deleteSubsidiaryById - End - Success');

        return ResultModel.newSuccessResult(deleteSubsidiaryByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteSubsidiaryById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une filiale',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
