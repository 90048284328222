import {TagFilled} from '@ant-design/icons';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard'

const TjmCards = ({data}) => {

    const getTheoreticalDailyRate = (key) => {
        if (!data[key] || !data[key].mission_value || !data[key].billed_days) {
            return 0;
        }
        else {
            return Math.round(data[key].mission_value / data[key].billed_days);
        }
    }

    const getRealDailyRate = (key) => {
        if (!data[key] || !data[key].gross_margin || !data[key].billed_days) {
            return 0;
        }
        else {
            return Math.round(data[key].gross_margin / data[key].billed_days);
        }
    }

    const getNetDailyRate = (key) => {
        if (!data[key] || !data[key].gross_margin || !data[key].produced_days) {
            return 0;
        }
        else {
            return Math.round(data[key].gross_margin / data[key].produced_days);
        }
    }

    const tagIcon = <TagFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className='flex justify-between mt-6 mb-1'>
            <SimpleCard
                width='w-[31%]'
                iconComponent={tagIcon}
                title={`${getTheoreticalDailyRate('junior')} €`}
                background='orange'
                subTitle="TJM théorique Junior"
                topComponent={<TopComponent realDailyRateData={getRealDailyRate('junior')} netDailyRateData={getNetDailyRate('junior')}/>}
            />

            <SimpleCard
                width='w-[31%]'
                iconComponent={tagIcon}
                title={`${getTheoreticalDailyRate('confirmed')} €`}
                background='orange'
                subTitle="TJM théorique Confirmé"
                topComponent={<TopComponent realDailyRateData={getRealDailyRate('confirmed')} netDailyRateData={getNetDailyRate('confirmed')}/>}
            />

            <SimpleCard
                width='w-[31%]'
                iconComponent={tagIcon}
                title={`${getTheoreticalDailyRate('senior')} €`}
                background='orange'
                subTitle="TJM théorique Sénior"
                topComponent={<TopComponent realDailyRateData={getRealDailyRate('senior')} netDailyRateData={getNetDailyRate('senior')}/>}
            />
        </div>
    )
}

export default TjmCards;

export const TopComponent = ({realDailyRateData, netDailyRateData}) => {
    return (
        <div className='flex justify-between bg-white rounded-b-md'>
            <div className='px-10 py-3'>
                <p className='leading-tight text-gradient'>Tjm réel <br/>(marge brute)</p>
                <div className='h-[1px] w-full bg-gray-200 my-2'/>
                <p style={{ fontFamily: 'Din condensed' }} className='text-4xl text-gradient'>{`${realDailyRateData} €`}</p>
            </div>

            <div className='w-[1px] bg-gray-200'/>

            <div className='px-10 py-3'>
                <p className='leading-tight text-gradient'>Tjm réel <br/>(marge brute)</p>
                <div className='h-[1px] w-full bg-gray-200 my-2'/>
                <p style={{ fontFamily: 'Din condensed' }} className='text-4xl text-gradient'>{`${netDailyRateData} €`}</p>
            </div>
        </div>
    )
}