import React, {useState} from "react";
import {EditOutlined} from "@ant-design/icons";
import {Tooltip} from 'antd';

import Loader from "../../../components/loaders/Loader";
import deleteIcon from "../../../assets/icons/actionsbutton/white-delete.png";
import downloadIcon from "../../../assets/icons/actionsbutton/white-download.png";
import ConfirmationPopup from "../../../components/ui/ConfirmationPopup";

export const bannerInvoicesConfig = (
    setIsInvoicePopupOpen,
    handleInvoiceDownload,
    handleInvoiceDelete,
    downloadInvoiceLoading,
    deleteInvoiceLoading,
    buttonsDisabled
) => {
    const [invoiceDeleteConfirmationView, setInvoiceDeleteConfirmationView] = useState(false);

    return {
        blackgroundColor: 'bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]',
        badgeBorderColor: 'transparent',
        titleLabel: '',
        centerInfo: false,
        statusBadge: (status) => {
            switch (status) {
                case 0:
                    return {label: 'Prévisionnelle', backgroundColor: '#ea8f3d'};
                case 100:
                    return {label: 'À valider', backgroundColor: '#212121'};
                case 200:
                    return {label: 'Validée', backgroundColor: '#f0a841'};
                case 300:
                    return {label: 'Émise', backgroundColor: '#00abe9'};
                case 400:
                    return {label: 'Payée partiellement', backgroundColor: '#a1dba1'};
                case 500:
                    return {label: 'Payée', backgroundColor: '#ced95f'};
                case 600:
                    return {label: 'Annulée', backgroundColor: '#dc3832'};
                default:
                    return {label: 'Indéfini', backgroundColor: '#212121'};
            }
        },
        actionComponents: (status) => {
            return (
                <>
                    {
                        <div className="flex items-center justify-center">
                            <Tooltip title={buttonsDisabled ? '' : 'Modifier facture'}>
                                <button
                                    onClick={() => setIsInvoicePopupOpen(true)}
                                    disabled={buttonsDisabled}
                                    className='flex items-center justify-center p-5'
                                >
                                    <EditOutlined style={{ fontSize: 20, color: "white" }}/>
                                </button>
                            </Tooltip>
                            
                            <Tooltip title={buttonsDisabled ? '' : 'Télécharger facture'}>
                                <button
                                    onClick={() => handleInvoiceDownload()}
                                    disabled={buttonsDisabled}
                                    className='flex items-center justify-center p-5'
                                >
                                    {
                                        downloadInvoiceLoading ?
                                            <Loader size={20} color='white'/> :
                                            <img className='w-5' src={downloadIcon} alt=""/>
                                    }
                                </button>
                            </Tooltip>
                            {
                                status < 300 ?
                                    <Tooltip title={buttonsDisabled ? '' : 'Supprimer facture'}>
                                        <button
                                            onClick={() => setInvoiceDeleteConfirmationView(true)}
                                            disabled={buttonsDisabled}
                                            className='flex items-center justify-center p-5'
                                        >
                                            {
                                                deleteInvoiceLoading ?
                                                    <Loader size={20} color='white'/> :
                                                    <img className='w-5' src={deleteIcon} alt=""/>
                                            }
                                        </button>
                                    </Tooltip> : null
                            }
                        </div>
                    }
                    <ConfirmationPopup
                        view={invoiceDeleteConfirmationView}
                        setView={setInvoiceDeleteConfirmationView}
                        title='Souhaitez-vous supprimer cette facture ?'
                        onConfirm={handleInvoiceDelete}
                    />
                </>
            );
        }
    }
    ;
};
