import GroupedColumnPlotChart from "../../../../../components/ui/GroupedColumnPlotChart/GroupedColumnPlotChart";
import {ressourceNumberChartConfig} from "./ressourceNumberChartConfig";
import { dataPerSector, dataCabinet} from "../../../functions/aggregateRessourcesProjection";

export const ressourceNumberTabConfig = (ressources) => {

    const tabs = [
        {
            id: 1,
            label: 'Cabinet',
            component: <GroupedColumnPlotChart data={dataCabinet(ressources)} config={ressourceNumberChartConfig}/>
        }
    ]

    for (const key in ressources.projection) {
        tabs.push({
            id: key,
            label: key,
            component: <GroupedColumnPlotChart data={dataPerSector(ressources, key)} config={ressourceNumberChartConfig}/>

        })
        
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            childOverflow: 'overflow-none',
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );

}