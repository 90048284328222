import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import Tooltip from 'antd/lib/tooltip';

import {fetchSelectData} from './../../../../utils';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import Button from '../../../../components/ui/Button';
import {ContractorActions, LocationActions, ContractorCategoryActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import {UserActions} from '../../../../actions';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from './../../../../utils';
import plus from '../../../../assets/icons/button/plus.png';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import Popup from '../../../../components/ui/Popup';
import LocationPopup from './LocationPopup';
import ContractorCategoryPopup from './ContractorCategoryPopup';

const NewContractorPopup = ({
    setView,
    userData,
    toBeUpdated,
    refreshData
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [locations, setLocations] = useState(null);
    const [contractorCategories, setContractorCategories] = useState([]);
    const [ressources, setRessources] = useState(null);
    const [contactValues, setContactValues] = useState([
        {id: Math.random(), name: "", email: "", phone: ""}
    ]);
    const [saleInputsInvalidity, setSaleInputsInvalidity] = useState(false);

    const [locationPopupView, setLocationPopupView] = useState(false);
    const [contractorCategoryPopupView, setContractorCategoryPopupView] = useState(false);

    // -- react hook form --
    const defaultValues = {
        name: '',
        location:'',
        internal_contacts: '',
        categories: "",
        note: ""
    };
    
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- functions that get data for Select Inputs --
    const handleGetLocations = async (search, page) => {
        await fetchSelectData({
            action: LocationActions.getAllLocation,
            search:`&search=${search}&is_active=true`,
            page: page,
            setter: setLocations,
            userData,
            resultPath: "data"
        });
    };

    const handleGetContractorCategories = async (search, page) => {
        await fetchSelectData({
            action: ContractorCategoryActions.getAllContractorCategories,
            search:`&search=${search}&is_active=true`,
            page: page,
            setter: setContractorCategories,
            userData,
            resultPath: "data.results"
        });
    };

    const handleGetRessources = async (search, page) => {
        await fetchSelectData({
            action: UserActions.getAllSortedUsers,
            search:`&search=${search}`,
            page: page,
            setter: setRessources,
            userData,
            resultPath: "data.results"
        });
    };

    const handleAddContact = () => {
        if (contactValues.length < 10) {
            setContactValues((prevState) => [...prevState, {id: Math.random(), name: "", email: "", phone: ""}]);
        }
    }

    const handleRemoveContact = (id) => {
        setContactValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleContactValueChange = (e, id, type) => {
        setContactValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }
    
    useEffect(() => {
        if (toBeUpdated) {
            if (toBeUpdated.contacts?.length > 0) {
                setContactValues(toBeUpdated.contacts.map((contact) => {
                    return (
                        {
                            id: Math.random(),
                            name: contact.name,
                            email: contact.email,
                            phone: contact.phone
                        }
                    );
                }));
            }
            reset({
                name: toBeUpdated.name,
                location: {name: toBeUpdated.location},
                internal_contacts: toBeUpdated.internal_contacts,
                categories: toBeUpdated.categories.map(el => { return {name: el} }),
                notes: toBeUpdated.notes
            });
        }

    }, [toBeUpdated]);

    const checkSaleInputsValidity = () => {
        let invalid = false;

        for(const contact of contactValues) {
            if (contact.name.length < 1 || contact.email.length < 1 || contact.phone.length < 1) {
                invalid = true;
            }
        }

        setSaleInputsInvalidity(invalid);
    }

    useEffect(() => {
        checkSaleInputsValidity();
    }, [contactValues]);

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const internal_contacts_ids = data.internal_contacts.map((el) => {
            return el.id;
        });

        const categories = data.categories.map((el) => {
            return el.name;
        });

        const contacts = contactValues.map((contact) => {
            return (
                {
                    email: contact.email,
                    name: contact.name,
                    phone: contact.phone
                }
            );
        });

        const newContractor = {
            name: data.name,
            location: data.location.name,
            internal_contacts_ids,
            categories,
            contacts,
            notes: data.notes
        }

        if (toBeUpdated) {
            const result = await ContractorActions.updateContractor(userData, newContractor, toBeUpdated.id);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData()
                setView(false);
                notify('Le sous/co-traitant à bien été mis à jour');
            }
        }
        else {
            const result = await ContractorActions.createContractor(userData, newContractor);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                setView(false);
                await refreshData();
                notify('Le sous/co-traitant à bien été créé');
            }
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <div>
            <ContainerCard
                title='Nouveau Sous/Co-traitant'
                coloredButtonDisplayed={false}
                overflow='overflow-visible'
            >
                <div className="flex flex-col px-4 pt-2 mb-4 pb-4 border-b border-borderGrey">
                    <div className='border-b border-borderGrey flex flex-col justify-between pb-4 mb-4'>
                        <div className="w-full mb-2">
                            <Controller
                                name="name"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir le nom'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <TextInput
                                        label='Nom de la structure'
                                        labelWidth='w-[200px]'
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="Nom de la structure"
                                        type="text"
                                        width='w-[800px]'
                                    />
                                )}
                            />
                        </div>
                        <div className="w-full flex mb-2">
                            <Controller
                                name="categories"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez selectionner au moins une catégorie'
                                }}}
                                render={({
                                    field: {onChange, ref, value},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Domaines'
                                        labelWidth='w-[190px]'
                                        placeholder='Domaines'
                                        inputRef={ref}
                                        error={error}
                                        value={value}
                                        options={contractorCategories?.options}
                                        hasNextPage={contractorCategories?.hasNextPage}
                                        onChange={onChange}
                                        fetchFunction={handleGetContractorCategories}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={disabled}
                                        isSearchable={true}
                                        isMulti={true}
                                        width='w-[685px]'
                                        height="h-auto"
                                    />
                                )
                                }
                            />
                            <div className='flex items-center justify-center ml-3'>
                                <Tooltip title='Ajouter un département'>
                                    <button
                                        onClick={() => setContractorCategoryPopupView(true)}
                                        disabled={disabled}
                                        // className={`hover:opacity-90 p-2 rounded-full ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}`}
                                        className={`hover:opacity-90 p-1 rounded-full background-gradient`}
                                    >
                                        <img src={plus} alt='plus' className='w-3'/>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="w-full mb-2 flex flex-start">
                            <Controller
                                name="location"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir un client'
                                }}}
                                render={({
                                    field: {onChange, ref, value},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Département du siège'
                                        labelWidth='w-[190.5px]'
                                        placeholder='Département du siège'
                                        inputRef={ref}
                                        error={error}
                                        value={value}
                                        options={locations?.options}
                                        hasNextPage={locations?.hasNextPage}
                                        onChange={onChange}
                                        fetchFunction={handleGetLocations}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={disabled}
                                        isSearchable={true}
                                        // width='w-[800px]'
                                    />
                                )}
                            />
                            <div className='flex items-center justify-center ml-3'>
                                <Tooltip title='Ajouter un département'>
                                    <button
                                        onClick={() => setLocationPopupView(true)}
                                        disabled={disabled}
                                        // className={`hover:opacity-90 p-2 rounded-full ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}`}
                                        className={`hover:opacity-90 p-1 rounded-full background-gradient`}
                                    >
                                        <img src={plus} alt='plus' className='w-3'/>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <Controller
                            name="internal_contacts"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez selectionner au moins un contact interne'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Référent(s)'
                                    labelWidth='w-[200px]'
                                    width='w-[755px]'
                                    placeholder='Référents'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={ressources?.options}
                                    hasNextPage={ressources?.hasNextPage}
                                    fetchFunction={handleGetRessources}
                                    onChange={onChange}
                                    labelKeys={['first_name', 'last_name']}
                                    isSearchable={true}
                                    disabled={disabled}
                                    required={true}
                                    isMulti={true}
                                    height="h-auto"
                                />
                            )
                            }
                        />
                    </div>
                </div>
                <div className="px-5">
                    <div>
                        {
                            contactValues.map((contact, index) => {
                                return (
                                    <div key={contact.id} className='mb-3.5 w-full'>
                                        <div>
                                            <TextInput
                                                label='Nom contact partenaire'
                                                labelWidth='w-[235px]'
                                                onChange={(e) => handleContactValueChange(e, contact.id, 'name')}
                                                value={contact.name}
                                                error={null}
                                                required={true}
                                                width='w-full'
                                                disabled={disabled}
                                                placeholder="Nom contact partenaire"
                                                type="text"
                                            />
                                        </div>
                                        <div className="w-full flex justify-between mt-2">
                                            <TextInput
                                                label='Email du contact'
                                                labelWidth='w-[187px]'
                                                onChange={(e) => handleContactValueChange(e, contact.id, 'email')}
                                                value={contact.email}
                                                error={null}
                                                required={true}
                                                disabled={disabled}
                                                placeholder="Email"
                                                type="text"
                                            />
                                            <TextInput
                                                label='Téléphone du contact'
                                                labelWidth='w-[200px]'
                                                onChange={(e) => handleContactValueChange(e, contact.id, 'phone')}
                                                value={contact.phone}
                                                error={null}
                                                required={true}
                                                disabled={disabled}
                                                placeholder="Téléphone"
                                                type="text"
                                            />
                                        </div>
                                        {
                                            index === 0 ?
                                                null
                                                : <button
                                                    onClick={() => handleRemoveContact(contact.id)}
                                                    disabled={disabled}
                                                    className="flex items-center justify-center p-1 bg-transparent"
                                                >
                                                    <Tooltip title='Supprimer'>
                                                        <div>
                                                            <img src={deleteIcon} alt="logo" className="w-[18px]"/>
                                                        </div>
                                                    </Tooltip>
                                                </button>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className='flex items-center mb-4'>
                        <Tooltip title='Ajouter un contact'>
                            <div
                                onClick={handleAddContact}
                                className={`whitespace-nowrap cursor-pointer text-sm text-gradient ${contactValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"}`}
                            >
                                <p className='hover:underline'>Ajouter un contact</p>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            
                <div className="px-5">
                    <div className={'py-4 border-t border-borderGrey'}>
                        <Controller
                            name="notes"
                            control={control}
                            render={({
                                field: {onChange, value}
                            }) => (
                                <>
                                    <div className="font-normal text-sm text-[#646464] mb-2">Veuillez ajouter une note</div>
                                    <TextAreaInput
                                        placeholder={'Tapez votre texte'}
                                        maxLength={500}
                                        height='h-[150px]'
                                        onChange={onChange}
                                        value={value}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-center pb-2 mt-2">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content={toBeUpdated ? "Modifier le sous/co-traitant" : 'Créer le sous/co-traitant'}
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                            disabled={!isValid || saleInputsInvalidity || contactValues.length < 1}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
            <Popup
                view={locationPopupView}
                setView={setLocationPopupView}>
                <LocationPopup
                    setView={setLocationPopupView}
                    userData={userData}
                    refreshData={handleGetLocations}
                />
            </Popup>
            <Popup
                view={contractorCategoryPopupView}
                setView={setContractorCategoryPopupView}>
                <ContractorCategoryPopup
                    setView={setContractorCategoryPopupView}
                    userData={userData}
                    refreshData={handleGetContractorCategories}
                />
            </Popup>
        </div>
    );
}

export default NewContractorPopup;
