import {MissionDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_MISSION';

export async function createMission(
    userData: Record<string, string>,
    newMission: Record<string, any>
) {

    try {
        const result = await MissionDao.createMission(userData.userToken, newMission);

        return result;
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la création de l\'affaire',
            error: error
        });
    }
}
