import {ProductionDao} from '../../dao';
import {
    VacationModel,
    ErrorModel
} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_VACATION';

export async function createVacation(
    userData: Record<string, string>,
    vacation: Record<string, any>
) {
    try {
        const newProduction = VacationModel.newVacation(vacation);

        const result = await ProductionDao.createVacation(userData.userToken, newProduction);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
