import {ACTION_CODE} from './_actionCode';
import {DealOriginsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_DEALORIGIN';

export async function createDealOrigin(userData: Record<string, any>, dealOrigin: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createDealOrigin - Start');

        const createDealOriginResult = await DealOriginsService.createDealOrigin(userData, dealOrigin);

        LoggerService.logInfo('Action - createDealOrigin - End - Success');

        return ResultModel.newSuccessResult(createDealOriginResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createDealOrigin - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une banque.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
