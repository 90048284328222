import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';

import SelectInput from '../../../components/ui/Inputs/SelectInput/SelectInput';
import {DealActions, ReferenceActions} from '../../../actions'
import ConfirmationPopup from '../../../components/ui/ConfirmationPopup/ConfirmationPopup';
import Popup from '../../../components/ui/Popup';
import ContainerCard from '../../../components/ui/ContainerCard';
import Button from '../../../components/ui/Button/Button';
import {notify} from '../../../components/ui/Toast/Toast';
import Error from '../../../components/ui/Error/Error';
import {getErrors, fetchSelectData} from '../../../utils';

const NewReferencePopUp = ({ setView, view, userData, refreshData, references, editReference = false }) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [referentLoading, setReferentLoading] = useState(false)
    const [deals, setDeals] = useState(null);
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [dealProducers, setDealProducers] = useState([]);
    const [selectedProducer, setSelectedProducer] = useState(null);
    const [isReferenceExist, setIsReferenceExist] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [payload, setPayload] = useState(null);
    const [errors, setErrors] = useState(null);

    const dealValue = editReference ? references[0].deal : null;
    const referentValue = editReference ? references[0].owner : null;
    referentValue ? referentValue['name'] = `${referentValue.first_name} ${referentValue.last_name}` : null;

    const {handleSubmit, setValue, control, reset, formState: {isValid}} = useForm({defaultValues: {
        deal: dealValue,
        referent: referentValue
    }});

    // -- functions that get data for Select Inputs --
    const handleGetDeals = async (search, page = 1) => {
        await fetchSelectData({
            action: DealActions.getAllDeals,
            search:`&type=business&display=${userData.userProfileName}&search=${search}`,
            page: page,
            setter: setDeals,
            userData,
            resultPath: 'data'
        });
    };

    useEffect(() => {
        (async () => {
            await handleGetDeals('', 1);
        })();
    }, []);

    useEffect(() => {
        if (!view) {
            reset();
            setSelectedDeal(null);
            setShowConfirmationPopup(false);
            setIsReferenceExist(false);
        }
        else if(editReference) {
            setSelectedDeal(references[0].deal);

            return setDeals([references[0].deal]);
        }
    }, [view]);

    useEffect(() => {
        if (!showConfirmationPopup) {
            setLoading(false);
            setDisabled(false);
        }
    }, [showConfirmationPopup]);

    useEffect(() => {
        (async () => {
            if (selectedDeal) {
                setReferentLoading(true);

                const result = await DealActions.getDealById(userData, selectedDeal.id);

                if (result.success) {
                    setDealProducers(result.data.producers.map((item) => ({
                        ...item, name: item.first_name + ' ' + item.last_name
                    })));
                }

                setReferentLoading(false);
            }
        })();
    }, [selectedDeal]);

    useEffect(() => {
        !view ? setErrors(null) : null
    }, [view]);

    const handleChangeDeal = (selectedDeal, onChange) => {
        onChange(selectedDeal);
        setSelectedDeal(selectedDeal);

        const existingReference = references.find((item) => item.deal.id === selectedDeal.id);

        if(existingReference) {
            setIsReferenceExist(true);
            setValue("referent", null);
        }
        else {
            setIsReferenceExist(false);
        }
    }

    const handleCreateReference = async () => {
        if (payload) {
            const result = await ReferenceActions.createReference(userData, payload);

            if (!result.success) {
                setErrors(getErrors(result));
                setView(true);
                notify(`Une erreur est survenue lors de la création de la référence`);
            }
            else {
                await refreshData();
                notify(`Votre demande de référence a bien été pris en compte. Le référent désigné a reçu une notification afin de créer la référence.`);
                setView(false);
                reset();
            }

            setDisabled(false);
            setLoading(false);
        }
    }

    const onSubmit = async (data) => {
        setErrors(null);
        setLoading(true);
        setDisabled(true);

        const referencePayload = {
            deal_id: data.deal.id,
            owner_id: data.referent.id
        }

        if (isValid) {
            if (editReference) {
                const result = await ReferenceActions.updateReference(userData ,referencePayload, references[0].id);

                if (!result.success) {
                    reset(data);
                    setErrors(getErrors(result));
                }
                else {
                    await refreshData();
                    setView(false);
                    notify(`La réference a été modifié avec succès.`);
                }

                setDisabled(false);
                setLoading(false);
            }
            else {
                setSelectedProducer(data.referent.name);
                setPayload(referencePayload);
                setShowConfirmationPopup(true);
            }
        }
    }

    return (
        <>
            {
                !showConfirmationPopup ? (
                    <Popup
                        view={view}
                        setView={setView}
                        width={'1098px'}
                        xOverflow=''
                        yOverflow=''
                    >
                        <ContainerCard
                            title={`${editReference ? 'Modifier le' : 'Choisir un'} référent qui sera en charge de la création de la référence`}
                            coloredButtonDisplayed={false}
                            overflow=''
                        >
                            <div className='flex flex-col mb-8'>
                                {
                                    (isReferenceExist && !editReference) ?
                                        <p className="mb-3 text-sm italic text-red-500">
                                                Une demande de référence a déjà été créée pour cette affaire.
                                        </p> : null
                                }
                                <div className='flex items-center justify-between'>
                                    <Controller
                                        name="deal"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une affaire'
                                        }}}
                                        render={({
                                            field: {onChange, value, ref},
                                            fieldState: {error}
                                        }) => (
                                            <SelectInput
                                                label='Affaire'
                                                placeholder='Affaire'
                                                inputRef={ref}
                                                value={value}
                                                error={error}
                                                options={deals?.options || []}
                                                hasNextPage={deals?.hasNextPage || false}
                                                fetchFunction={handleGetDeals}
                                                loading={!deals}
                                                onChange={(selectedDeal) => handleChangeDeal(selectedDeal, onChange)}
                                                labelKeys={['short_name']}
                                                isSearchable={true}
                                                required={true}
                                                disabled={editReference || disabled}
                                                width='w-72'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="referent"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner un référent'
                                        }}}
                                        render={({
                                            field: {onChange, value, ref},
                                            fieldState: {error}
                                        }) => (
                                            <SelectInput
                                                label='Référent'
                                                placeholder='Référent'
                                                inputRef={ref}
                                                value={value}
                                                error={error}
                                                options={dealProducers}
                                                loading={referentLoading}
                                                onChange={onChange}
                                                labelKeys={['name']}
                                                isSearchable={true}
                                                required={true}
                                                width='w-72'
                                                disabled={selectedDeal === null || (isReferenceExist && !editReference)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center pb-2 mt-2">
                                <div className="flex justify-center pb-2">
                                    <Button
                                        onClick={() => {
                                            reset();
                                            setView(false);
                                        }}
                                        type={'secondary'}
                                        content={'Annuler'}
                                        disabled={disabled}
                                    />
                                    <Button
                                        type={'primary'}
                                        content={`${editReference ? 'Modifier' : 'Ajouter'} le référent`}
                                        onClick={handleSubmit(onSubmit)}
                                        loading={loading}
                                        disabled={!isValid}
                                    />
                                </div>
                                <Error errors={errors}/>
                            </div>
                        </ContainerCard>
                    </Popup>
                ) : (
                    <ConfirmationPopup
                        view={view}
                        setView={setView}
                        title={`Souhaitez-vous confier la création de la référence à ${selectedProducer || 'ce référent'} ?`}
                        confirmButtonText="Valider"
                        onConfirm={handleCreateReference}
                    />
                )}
        </>
    );
}

export default NewReferencePopUp;
