import {ACTION_CODE} from './_actionCode';
import {TrainingAttendancesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_TRAINING_ATTENDANCE';

export async function deleteTrainingAttendance(userData: Record<string, string>, id: string) {
    try {
        LoggerService.logInfo('Action - deleteTrainingAttendance - Start');

        const deleteTrainingAttendanceResult = await TrainingAttendancesService.deleteTrainingAttendance(userData, id);

        LoggerService.logInfo('Action - deleteTrainingAttendance - End - Success');

        return ResultModel.newSuccessResult(deleteTrainingAttendanceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteTrainingAttendance - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
