import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../../components/ui/Inputs/TextInput';

// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const InfoModule = ({
    control,
    disabled,
    handleGetDealDas,
    dealDas
}) => {

    // const navigate = useNavigate();

    // Get dropdows data
    useEffect(() => {

        // ↓ deal das dropdown values
        (async () => {
            await handleGetDealDas('', 1);
        })();

    }, []);

    return (
        <>
            <div className="w-full flex flex-col items-center px-4 pb-2 mb-6 border-b border-gray-200">
                <div className="flex justify-start text-gradient w-[110%] pb-2 mb-6 border-b border-gray-200">
                    Informations du chantier
                </div>
                {
                    <div className="pb-3">
                        <Controller
                            name="short_name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le libellé complet'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    width='w-[800px]'
                                    label='Libellé complet'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Libellé complet"
                                    type="text"
                                    labelWidth='w-40'
                                />
                            )}
                        />
                    </div>
                }
                {
                    <div className="pb-3">
                        <Controller
                            name="das"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom domaine d’activité'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Domaine d’activité'
                                    width='w-[800px]'
                                    placeholder='Domaine d’activité'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={dealDas?.options}
                                    hasNextPage={dealDas?.hasNextPage}
                                    onChange={onChange}
                                    fetchFunction={handleGetDealDas}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    labelWidth='w-40'
                                    loading={!dealDas}
                                />
                            )}
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default InfoModule;
