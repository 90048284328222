import {ACTION_CODE} from './_actionCode';
import {CustomerService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_CUSTOMER_BY_ID';

export async function getCustomerById(userData: Record<string, any>, customerId: number) {
    try {
        LoggerService.logInfo('Action - getCustomerById - Start');

        const getCustomerByIdResult = await CustomerService.getCustomerById(userData, customerId);

        LoggerService.logInfo('Action - getCustomerById - End - Success');

        return ResultModel.newSuccessResult(getCustomerByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - getCustomerById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération du client.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
