import {TrainingAttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_TRAINING_ATTACHMENTS';

export async function getAllTrainingAttachments(userData: Record<string, string>, params: string) {
    try {
        return await TrainingAttachmentDao.getAllTrainingAttachments(userData, params);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des pièces jointes',
            error: error
        });
    }
}
