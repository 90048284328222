import {ACTION_CODE} from './_actionCode';
import {ExpenseReportService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_EXPENSE_REPORT';

export async function updateExpenseReportById(
    userData: Record<string, any>,
    expenseReportId: string,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateExpenseReportById - Start');

        const updateExpenseReportByIdResult =
            await ExpenseReportService.updateExpenseReportById(userData, expenseReportId, payloadUpdate);

        LoggerService.logInfo('Action - updateExpenseReportById - End - Success');

        return ResultModel.newSuccessResult(updateExpenseReportByIdResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateExpenseReportById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une note de frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
