export const aggregateSalesActivity = (salesActivityWaiting: Record<string, any>, type: string) => {

    const cabinetPieChartDataObject : Record<string, Array<number>> = {
        Forte: [],
        Moyenne: [],
        Faible: [],
        Inconnue: []
    }

    const totalCount = [];
    const totalValue = [];
    const totalDays = [];

    for (const key in salesActivityWaiting) {
        cabinetPieChartDataObject.Inconnue.push(salesActivityWaiting[key]["0"].count)
        cabinetPieChartDataObject.Faible.push(salesActivityWaiting[key]["1"].count)
        cabinetPieChartDataObject.Moyenne.push(salesActivityWaiting[key]["2"].count)
        cabinetPieChartDataObject.Forte.push(salesActivityWaiting[key]["3"].count)

        totalCount.push(salesActivityWaiting[key]["0"].count + salesActivityWaiting[key]["1"].count + salesActivityWaiting[key]["2"].count + salesActivityWaiting[key]["3"].count)

        totalValue.push(salesActivityWaiting[key]["0"].value + salesActivityWaiting[key]["1"].value + salesActivityWaiting[key]["2"].value + salesActivityWaiting[key]["3"].value)

        totalDays.push(salesActivityWaiting[key]["0"].days + salesActivityWaiting[key]["1"].days + salesActivityWaiting[key]["2"].days + salesActivityWaiting[key]["3"].days)
        
    }

    const cabinetPieChartData = [
        {
            type: 'Forte:',
            value: cabinetPieChartDataObject.Forte.reduce((a, b) => a + b, 0)
        },
        {
            type: 'Moyenne:',
            value: cabinetPieChartDataObject.Moyenne.reduce((a, b) => a + b, 0)
        },
        {
            type: 'Faible:',
            value: cabinetPieChartDataObject.Faible.reduce((a, b) => a + b, 0)
        },
        {
            type: 'Inconnue:',
            value: cabinetPieChartDataObject.Inconnue.reduce((a, b) => a + b, 0)
        }
    ]

    switch (type) {
        case "cabinetPieChartData":
            return cabinetPieChartData;
        case "totalCount":
            return totalCount.reduce((a, b) => a + b, 0);
        case "totalValue":
            return totalValue.reduce((a, b) => a + b, 0);
        case "totalDays":
            return totalDays.reduce((a, b) => a + b, 0);
    }
}

export  const pieChartData = (salesActivityWaiting: Record<string, any>, key: string) => [
    {
        type: 'Forte:',
        value: salesActivityWaiting[key]["3"].count
    },
    {
        type: 'Moyenne:',
        value: salesActivityWaiting[key]["2"].count
    },
    {
        type: 'Faible:',
        value: salesActivityWaiting[key]["1"].count
    },
    {
        type: 'Inconnue:',
        value: salesActivityWaiting[key]["0"].count
    }
];

export const dasCount = (salesActivityWaiting: Record<string, any>, key: string) =>
    salesActivityWaiting[key]["0"].count + salesActivityWaiting[key]["1"].count + salesActivityWaiting[key]["2"].count + salesActivityWaiting[key]["3"].count;

export const dasValue = (salesActivityWaiting: Record<string, any>, key: string) =>
    salesActivityWaiting[key]["0"].value + salesActivityWaiting[key]["1"].value + salesActivityWaiting[key]["2"].value + salesActivityWaiting[key]["3"].value;

export const dasDays = (salesActivityWaiting: Record<string, any>, key: string) =>
    salesActivityWaiting[key]["0"].days + salesActivityWaiting[key]["1"].days + salesActivityWaiting[key]["2"].days + salesActivityWaiting[key]["3"].days;

export const totalCount = (salesActivity: Record<string, any>) => {
    let total = 0;
    for (const key in salesActivity) {
        total += salesActivity[key].count;
    }

    return total;
}