import {frenchFormat, standardFormat, addDays} from "../../../utils";

export const exportProductionDataToCsv = (
    productionsData,
    setCsvProps,
    setCsvExportLoading,
    datePickerDate
) => {
    setCsvExportLoading(true);

    let csvData = [];

    for (const deal of productionsData) {
        const unbillableDays = +(deal.billable_days) - +(deal.billed_days);

        deal?.productions.sort((a, b) => a.date.localeCompare(b.date));

        const startDate = deal.productions[0].date;
        const endDate = deal.productions[deal.productions.length - 1].date;

        csvData.push({
            dealName: deal.short_name,
            dealDates: `${frenchFormat(startDate)} - ${frenchFormat(endDate)}`,
            dealLocation: deal?.customer?.location,
            dealProducedDays: deal.billable_days,
            dealBilledDays: deal.billed_days,
            dealUnbilledDays: unbillableDays,
            productionCategory: '',
            productionType: '',
            productionDate: '',
            productionNotes: '',
            productionProducedDays: '',
            productionBilledDays: '',
            productionUnbilledDays: ''
        });

        for (const production of deal.productions) {
            const productionUnbillableDays = +(production.produced_days) - +(production.billed_days);
            csvData.push({
                dealName: '',
                dealDates: '',
                dealLocation: '',
                dealProducedDays: '',
                dealBilledDays: '',
                dealUnbilledDays: '',
                productionCategory: production.category,
                productionType: 'Production',
                productionDate: frenchFormat(production.date),
                productionNotes: production.notes,
                productionProducedDays: production.produced_days,
                productionBilledDays: production.billed_days,
                productionUnbilledDays: productionUnbillableDays
            });
        }
    }

    const headers = [
        {label: 'Affaires', key: 'dealName'},
        {label: 'Date', key: 'dealDates'},
        {label: 'Localisation', key: 'dealLocation'},
        {label: 'Jours produits', key: 'dealProducedDays'},
        {label: 'Jours facturés', key: 'dealBilledDays'},
        {label: 'Jours non facturés', key: 'dealUnbilledDays'},
        {label: 'Catégorie', key: 'productionCategory'},
        {label: 'Temps', key: 'productionType'},
        {label: 'Date', key: 'productionDate'},
        {label: 'Notes', key: 'productionNotes'},
        {label: 'Jours Produits', key: 'productionProducedDays'},
        {label: 'Jours facturés', key: 'productionBilledDays'},
        {label: 'Jours non facturés', key: 'productionUnbilledDays'}
    ];

    setCsvProps({
        filename: `production_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}

export const exportVacationDataToCsv = (
    vacationsData,
    setCsvProps,
    setCsvExportLoading,
    datePickerDate
) => {

    setCsvExportLoading(true);

    let csvData = [];

    for (const vacation of vacationsData) {
        csvData.push({
            startDate: frenchFormat(vacation.date),
            endDate: frenchFormat(addDays(vacation.date, vacation.produced_days)),
            vacationType: 'Congés',
            vacationProducedDays: vacation.produced_days
        });
    }

    const headers = [
        {label: 'Date de début', key: 'startDate'},
        {label: 'Date de fin', key: 'endDate'},
        {label: 'Temps', key: 'vacationType'},
        {label: 'Jours de congés', key: 'vacationProducedDays'}
    ];

    setCsvProps({
        filename: `congés_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}
