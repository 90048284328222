import {useSelector} from 'react-redux';
import {CalculatorFilled} from '@ant-design/icons';
import {aggregateETPmMarginTotal, aggregateETPmMarginParDas} from "../../functions/aggregateETPMargin";

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import Badge from "../../../../components/ui/Badge";
import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import TabCard from '../../../../components/ui/TabCard';
import {etpProductionTabConfig} from '../../configs/resultIndicatorTab/etpProductionSection/etpProductionTabConfig';
import { transformThousandNumbers } from '../../../../utils';

const ProductionByEtp = ({results}) => {
    const CalculatorIcon = <CalculatorFilled style={{ fontSize: '30px', color:'white' }}/>;

    return (
        <div className='mt-14'>
            <LineTitle title="Production par ETP" textSize='text-4xl'/>

            <div className='flex justify-between mt-10'>
                <SimpleCard
                    width='w-[48%]'
                    title={transformThousandNumbers(aggregateETPmMarginTotal(results.by_status))}
                    borderSize='border'
                    subTitle="Marge brute par ETP"
                    iconComponent={CalculatorIcon}
                    topComponent={<div className='my-16'/>}
                    bottomComponent={<BottomComponent
                        data={results.by_status}
                    />}
                />
                <div className='w-[48%]'>
                    <TabCard config={etpProductionTabConfig(results.by_status)}/>
                </div>
            </div>
        </div>
    )
}

export default ProductionByEtp;

const BottomComponent = ({data}) => {

    const allDasS = useSelector((state) => state.DasReducer.das);

    let dasS = [];

    for (const key in data) {
        let currentDas = allDasS.filter((el) => el.name === key);

        dasS.push({
            das: key,
            value: aggregateETPmMarginParDas(data[key]),
            dasObject: currentDas = currentDas.length > 0 ? currentDas[0] : null
        })
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={el.das}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge
                                    content={el.dasObject ? [el.dasObject.manager] : '-'}
                                    color={el.dasObject ? null : '#bdc3c7'}
                                    type='round'
                                />
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${transformThousandNumbers(el.value)} `}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
