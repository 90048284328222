import LoginForm from './components/LoginForm';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';

const LoginPage = () => {
    const userProfileData = useSelector((state) => state.UserReducer.userProfileData);
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if (userProfileData) {
            navigate(location.state.from.pathname);
        }
    }, [userProfileData]);

    return (
        <div className="flex flex-col justify-center min-h-screen bg-gray-100">
            <div className="container py-20 mx-auto w-96">
                <LoginForm/>
            </div>
        </div>
    );
};

export default LoginPage;
