import {ContractDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_CONTRACTS';

export async function getAllContracts(
    userData: Record<string, string>,
    query: string
) {
    try {
        return await ContractDao.getAllContracts(userData.userToken, query);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
