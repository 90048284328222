import * as z from 'zod';
import * as paymentMethodFunctions from './functions';

export const PaymentMethodSchema = z.object({
    'id': z.number(),
    'is_active': z.boolean(),
    'name': z.string()
});

export const PaymentMethodSchemaPartialSchema = PaymentMethodSchema.partial();
export type PaymentMethodPartial = z.infer<typeof PaymentMethodSchemaPartialSchema>;
export type PaymentMethod = z.infer<typeof PaymentMethodSchema>;

export function newPaymentMethod(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...PaymentMethodSchema.parse({...data}),
        ...paymentMethodFunctions
    };
}

export function parsePaymentMethod(data: any, partial = false) {
    if (!partial) {
        return {
            ...PaymentMethodSchema.parse(data),
            ...paymentMethodFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...PaymentMethodSchema.parse(data),
            ...paymentMethodFunctions
        };
    }
}
