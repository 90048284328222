import {ACTION_CODE} from './_actionCode';
import {AttachmentService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DOWNLOAD__ATTACHMENT';

export async function downloadAttachment(userData: Record<string, any>, reportId: string) {
    try {
        LoggerService.logInfo('Action - downloadAttachment - Start');

        const getAllAttachmentResult = await AttachmentService.downloadAttachment(userData, reportId);

        LoggerService.logInfo('Action - downloadAttachment - End - Success');

        return ResultModel.newSuccessResult(getAllAttachmentResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - downloadAttachment - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
