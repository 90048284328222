import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GET_ADMIN_DASHBOARD_PAYMENT_TERMS';

export async function getPaymentTerms(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getPaymentTerms - Start');

        const getPaymentTerms = await DashboardService.AdminService.getPaymentTerms(userData, params);

        LoggerService.logInfo('Action - getPaymentTerms - End - Success');

        return ResultModel.newSuccessResult(getPaymentTerms);
    }
    catch (error) {
        LoggerService.logInfo('Action - getPaymentTerms - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
