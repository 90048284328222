import * as z from 'zod';
import { UserSchema } from '../user/user';

export const DasSchema = z.object({
    "id": z.number(),
    "is_active": z.boolean(),
    "name": z.string(),
    "manager": UserSchema.optional(),
    "manager_id": z.string().optional()
});

export const DasPartialSchema = DasSchema.partial();
export type DasPartial = z.infer<typeof DasPartialSchema>;
export type Das = z.infer<typeof DasSchema>;

export function newDas(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...DasSchema.parse({...data})
    };
}

export function parseDas(data: any, partial = false) {
    if (!partial) {
        return {
            ...DasSchema.parse(data)
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...DasPartialSchema.parse(data)
        };
    }
}
