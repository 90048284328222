import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux/es/hooks/useSelector';

import Layout from '../../components/layout';
import TabNavigator from '../../components/ui/navigation/TabNavigator';
import ClassificationSection from './components/SectionComponents/ClassificationSection/ClassificationSection';
import OperationsSection from './components/SectionComponents/OperationsSection/OperationSection';
import ProjectsSection from './components/SectionComponents/ProjectsSection/ProjectsSection';
import {ProjectsActions, ServicesActions} from '../../actions';
import {getClassificationData} from './functions/getTablesData';
import {notify} from '../../components/ui/Toast/Toast';

const ParametersPage = () => {
    const [displayedTabSection, setDisplayedTabSection] = useState('Projets');

    // Projects
    const [projectsData, setProjectsData] = useState([]);
    const [projectsPaginationMetadata, setProjectsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [projectsTableLoading, setProjectsTableLoading] = useState(false);
    
    /// Operations
    const [operationsData, setOperationsData] = useState([]);
    const [operationsPaginationMetadata, setOperationsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [operationsTableLoading, setOperationsTableLoading] = useState(false);

    const [classificationTableLoading, setClassificationTableLoading] = useState(false);
    
    const [classificationTablesData, setClassificationTablesData] = useState({
        CustomerCategoryData: {},
        LocationData: {},
        DealOriginsData: {},
        DealLossMotivesData: {},
        DealAbandonmentMotivesActions: {},
        AttachmentTypesData: {},
        DealDasData: {},
        SubsidiaryData: {},
        ProductionCategoryData: {},
        ExpenseTypeData: {},
        VehicleTypeData: {},
        BankData: {},
        PaymentMethodData: {},
        DealTagsData: {},
        SeniorityData: {},
        TrainingProductOriginData: {},
        TrainingObjectiveData: {},
        TrainingStudentTypesData: {},
        TrainingSpecialitiesData: {},
        ContractorCategoryData: {},
        ActivityTypesData: {}
    });
    
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const sections = [
        {name: "Projets"},
        {name: "Opérations diverses"},
        {name: "Classification"}
    ]

    // ↓ Fetch projects data
    const fetchAllProjects = async (page, activeLoading = true) => {
        if (activeLoading) {
            setProjectsTableLoading(true);
            setProjectsData([]);
        }

        const projectsResult = await ProjectsActions.getAllProjects(
            userData.userToken,
            page,
            `&page_size=${projectsPaginationMetadata.pageSize}`
        );

        if (projectsResult.success) {
            setProjectsData(projectsResult.data);
            setProjectsPaginationMetadata(prevState => ({ ...prevState, currentPage: page}));

            if (projectsPaginationMetadata.totalItems !== projectsResult.metadata.count) {
                setProjectsPaginationMetadata(prevState => ({...prevState, totalItems: projectsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récupération de la liste des projets');
        }

        setProjectsTableLoading(false);
    }

    const refreshProjectsData = async (removal = false) => {
        const checkedPage = (projectsData.length === 1 && projectsPaginationMetadata.currentPage > 1 && removal) ?
            projectsPaginationMetadata.currentPage - 1 :
            projectsPaginationMetadata.currentPage;

        await fetchAllProjects(checkedPage, false);
    }

    useEffect(() => {
        fetchAllProjects(1);
    }, []);
    // ---------------------

    // ↓ Fetch projects data
    const fetchAllOperations = async (page, activeLoading = true) => {
        if (activeLoading) {
            setOperationsTableLoading(true);
            setOperationsData([]);
        }

        const operationsResult = await ServicesActions.getAllServices(
            userData.userToken,
            `&page_size=${operationsPaginationMetadata.pageSize}`,
            page
        );

        if (operationsResult.success) {
            setOperationsData(operationsResult.data);
            setOperationsPaginationMetadata(prevState => ({ ...prevState, currentPage: page}));

            if (operationsPaginationMetadata.totalItems !== operationsResult.metadata.count) {
                setOperationsPaginationMetadata(prevState => ({...prevState, totalItems: operationsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récupération de la liste des opérations');
        }

        setOperationsTableLoading(false);
    }

    const refreshOperationsData = async (removal = false) => {
        const checkedPage = (operationsData.length === 1 && operationsPaginationMetadata.currentPage > 1 && removal) ?
            operationsPaginationMetadata.currentPage - 1 :
            operationsPaginationMetadata.currentPage;

        await fetchAllOperations(checkedPage, false);
    }

    useEffect(() => {
        fetchAllOperations(1);
    }, []);
    // -----------------------

    // ↓ fetch classification data
    useEffect(() => {
        (async () => {
            setClassificationTableLoading(true);

            const classificationDataResult = await getClassificationData(userData);

            setClassificationTablesData(classificationDataResult);

            setClassificationTableLoading(false);
        })();
    }, []);

    return (
        <Layout>
            <div>
                <div className="px-20 pt-10">
                    <div className="">
                        <TabNavigator
                            sections={sections}
                            displayedSection={displayedTabSection}
                            setDisplayedSection={setDisplayedTabSection}
                            borderBottom={true}
                        />
                    </div>
                </div>
                {
                    displayedTabSection === "Projets" ?
                        <ProjectsSection
                            data={projectsData}
                            loadingTable={projectsTableLoading}
                            fetchAllProjects={fetchAllProjects}
                            refreshProjectsData={refreshProjectsData}
                            projectsPaginationMetadata={projectsPaginationMetadata}
                        />
                        : null
                }
                {
                    displayedTabSection === "Opérations diverses" ?
                        <OperationsSection
                            data={operationsData}
                            loadingTable={operationsTableLoading}
                            fetchAllOperations={fetchAllOperations}
                            refreshOperationsData={refreshOperationsData}
                            operationsPaginationMetadata={operationsPaginationMetadata}
                        />
                        : null
                }
                {
                    displayedTabSection === "Classification" ?
                        <ClassificationSection
                            data={classificationTablesData}
                            setData={setClassificationTablesData}
                            loadingTable={classificationTableLoading}
                        />
                        : null
                }
            </div>
        </Layout>
    )
};

export default ParametersPage;
