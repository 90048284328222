import {ACTION_CODE} from './_actionCode';
import {DealLossMotivesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_DEAL_ORIGIN';

export async function getAllDealLossMotives(
    userData: Record<string, string>,
    params = '',
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllDealLossMotives - Start');

        const getAllDealLossMotivesResult = await DealLossMotivesService.getAllDealLossMotives(userData, params, page);

        LoggerService.logInfo('Action - getAllDealLossMotives - Success');

        return ResultModel.newSuccessResult(getAllDealLossMotivesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllDealLossMotives - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des motifs de refus des affaires',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
