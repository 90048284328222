import DonutsChart from "../../../../../components/ui/DonutsChart/DonutsChart";
import {etpTotalPerStatus, aggregateDasDataETP} from "../../../functions/agggregateRessources";
import {etpChartConfig} from "./etpChartConfig";

export const etpTabConfig = (ressources) => {

    const dataCabinet = [
        {
            type: 'Junior:',
            value: etpTotalPerStatus(ressources.current, "junior")
        },
        {
            type: 'Confirmé:',
            value: etpTotalPerStatus(ressources.current, "confirmed")
        },
        {
            type: 'Sénior:',
            value: etpTotalPerStatus(ressources.current, "senior")
        }
    ];

    const tabs =
                [{
                    id: 1,
                    label: 'Cabinet',
                    component: <div className="h-[360px]">
                        <DonutsChart data={dataCabinet} config={etpChartConfig} label=""/>
                    </div>

                }]

    for (const key in ressources.current) {
        const item = {
            id:key,
            label: key,
            component: <div className="h-[360px]">
                <DonutsChart data={aggregateDasDataETP(ressources.current, key)} config={etpChartConfig} label=""/>
            </div>

        }
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            showContainerCard: false,
            tabs: tabs
        }
    );
};
