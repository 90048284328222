import React, {useRef, useState, useEffect} from 'react';

import Loader from '../../loaders/Loader';

function DropdownInput({
    onChange,
    setSwitchForm,
    value,
    label,
    required,
    items,
    objectKey = 'name',
    disabled,
    error,
    listHeight = '100px',
    customStyle = '',
    color = 'orange'
}) {
    const menu = useRef(null);

    const [open, setOpen] = useState(false);
    const [localValue, setLocalValue] = useState('');
    const showMenu = () => setOpen(!open);
    {
        setSwitchForm &&
            useEffect(() => {
                if (localValue !== '') {
                    setSwitchForm(localValue);
                }
            }, [localValue]);
    }

    const getDisplay = () => {
        if (localValue) {
            return localValue;
        }

        if (value) {
            return value;
        }

        return label;
    };

    const closeOpenMenu = (e) => {
        if (menu.current && open && !menu.current.contains(e.target)) {
            setOpen(false);
        }
    };

    document.addEventListener('mousedown', closeOpenMenu);

    const onItemClick = (option) => {
        setLocalValue(option[objectKey]);
        onChange(option);
    };

    return (
        <div ref={menu} className="flex flex-col">
            <div className="flex flex-row items-center w-full">
                {
                    label.length > 0 &&
                        <div className="flex flex-row w-36">
                            <label className="font-normal text-sm text-[#646464]">
                                {label}
                                {
                                    required &&
                                        <span className="ml-1 text-sm font-normal text-red-500">*</span>
                                }
                            </label>
                        </div>
                }
                <div className="relative flex flex-col">
                    <button
                        onClick={showMenu}
                        disabled={items === null ? true : disabled}
                        type="button"
                        className={`
                            ${customStyle} w-[250px] dropdown-toggle inline-flex justify-between h-[31px] border border-solid
                            border-[#D9D9D9] rounded-sm py-1 px-2
                            ${color === 'orange' ? 'hover:border-[#E36D38] focus:border-[#E36D38]' : 'hover:border-[#19b3cc] focus:border-[#19b3cc]'}
                            leading-tight transition duration-150ease-in-out disabled:bg-[#efefef] text-[#212121]
                        `}
                    >
                        <div className="w-full h-full">
                            {items === null ?
                                <div className="flex flex-row items-center justify-center w-full h-full">
                                    <Loader size={10}/>
                                    <span className="ml-1 text-xs text-[#646464]">Chargement</span>
                                </div> :
                                <p className={`w-[170px] truncate overflow-hidden text-left text-sm ${getDisplay() === label ? 'text-[#21212140]' : ''}`}>
                                    {getDisplay()}
                                </p>
                            }
                        </div>
                        {open ?
                            <svg className="flex self-center ml-2 mr-1" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d={
                                        'M11.8127 8H10.6408C10.5611 8 10.4861 7.96094 10.4393 7.89687L6.0002 ' +
                                        '1.77812L1.56114 7.89687C1.51426 7.96094 1.43926 8 1.35957 8H0.187698C0.0861354' +
                                        ' 8 0.0267604 7.88437 0.0861354 7.80156L5.59551 0.20625C5.79551 -0.0687494 ' +
                                        '6.20489 -0.0687494 6.40332 0.20625L11.9127 7.80156C11.9736 7.88437 11.9143 8 11.8127 8Z'
                                    }
                                    fill="black"
                                />
                            </svg> :
                            <svg className="flex self-center ml-2 mr-1" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d={
                                        'M11.8127 0H10.6408C10.5611 0 10.4861 0.0390626 10.4393 0.103125L6.0002 ' +
                                        '6.22188L1.56114 0.103125C1.51426 0.0390626 1.43926 0 1.35957 ' +
                                        '0H0.187698C0.0861354 0 0.0267604 0.115625 0.0861354 0.198438L5.59551 ' +
                                        '7.79375C5.79551 8.06875 6.20489 8.06875 6.40332 7.79375L11.9127 ' +
                                        '0.198438C11.9736 0.115625 11.9143 0 11.8127 0Z'
                                    }
                                    fill="black"
                                />
                            </svg>
                        }
                    </button>
                    {
                        open &&
                        <div
                            className={`
                                absolute top-[25px] ${label.length > 0 ? 'right-[1px]' : 'right-[41px]'} z-20 mt-2 w-full h-fit
                                overflow-auto origin-top-right rounded-sl bg-white ring-1 ring-[#D9D9D9]
                                focus:outline-none
                            `}
                            style={{maxHeight: listHeight}}
                            tabIndex="-1"
                        >
                            <div className="py-1" role="none">
                                {
                                    items && items.map((item, index) => {
                                        return (
                                            <a
                                                key={index}
                                                onClick={() => {
                                                    onItemClick(item);
                                                    setOpen(false);
                                                }}
                                                className={`
                                                    text-gray-700 block px-3 py-1 text-sm bg-white hover:text-white 
                                                    ${color === 'orange'
                                                ? 'hover:bg-gradient-to-r from-[#DC3832] via-[#E36D38] to-[#F0A841]'
                                                : 'hover:bg-gradient-to-r from-[#00ABE9] via-[#00ABAB] to-[#CED955]'}
                                                `}
                                                tabIndex={index}
                                            >
                                                {item[objectKey]}
                                            </a>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                error &&
                <div className="text-xs italic text-red-500">
                    {error.message}
                </div>
            }
        </div>
    );
}

export default DropdownInput;
