import {ACTION_CODE} from './_actionCode';
import {TrainingStudentTypesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_TRAINING_STUDENT_TYPES';

export async function getAllTrainingStudentTypes(
    userData: Record<string, string>,
    params = "",
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllTrainingStudentTypes - Start');

        const getAllTrainingStudentTypesResult = await TrainingStudentTypesService.getAllTrainingStudentTypes(userData, params, page);

        LoggerService.logInfo('Action - getAllTrainingStudentTypes - Success');

        return ResultModel.newSuccessResult(getAllTrainingStudentTypesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllTrainingStudentTypes - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des Types de stagiaires',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
