import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_USER_TOKEN';

export async function getUserToken(username: string, password: string) {
    try {
        const response = await axios.post(
            `${URL}/auth/token/`,
            {
                username: username,
                password: password
            },
            {
                headers: {
                    Accept: 'application/json'
                }
            },
        );
            
        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
