
const TopComponent = ({ totalPlannedCharges, totalPotentialCharges }) => {
    return (
        <div className='flex items-center justify-start px-6 pb-5'>
            <div className='mr-10 w-fit text-[#F0A841]'>
                <p style={{ fontFamily: 'Din Condensed' }} className='mb-4 text-2xl'>{totalPlannedCharges} jours</p>
                <p className='text-sm'>Total des charges de production</p>
            </div>
            <div className='w-fit text-[#DC3832]'>
                <p style={{ fontFamily: 'Din Condensed' }} className='mb-4 text-2xl'>{totalPotentialCharges} jours</p>
                <p className='text-sm'>Total des charges prévisionnelles</p>
            </div>
        </div>
    )
}

export default TopComponent