import {ACTION_CODE} from './_actionCode';
import {PaymentMethodService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_PAYMENT_METHODS';

export async function getAllPaymentMethods(
    userData: Record<string, string>,
    params = "",
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllPaymentMethods - Start');

        const getAllPaymentMethodResult = await PaymentMethodService.getAllPaymentMethods(userData, params, page);

        LoggerService.logInfo('Action - getAllPaymentMethods - End - Success');

        return ResultModel.newSuccessResult(getAllPaymentMethodResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllPaymentMethods - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des méthodes de paiement.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
