import React from 'react';
import {CSVLink} from 'react-csv';

import Button from '../Button';
import download from '../../../assets/icons/containerCard/download.png';
import blueDownload from '../../../assets/icons/containerCard/download-blue.png';
import edit from '../../../assets/icons/actionsbutton/edit.png';
import editBlue from '../../../assets/icons/blueStyle/edit-blue.png';
import Loader from '../../loaders/Loader';

const ContainerCard = ({
    children,
    title,
    headerBackground = 'bg-[#FFF]',
    showHeader = true,
    contentPadding = 'p-6',
    showLineHeader = true,
    coloredButtonDisplayed = true,
    coloredButtonDisabled = false,
    buttonContent,
    buttonIcon = false,
    onButtonClick = null,
    editButtonDisplayed = false,
    onEditButtonClick = null,
    downloadButtonDisplayed = false,
    onDownloadButtonClick = null,
    csvProps = {filename: 'empty.csv', headers: [], data: []},
    csvExportLoading = false,
    width = 'w-full',
    height = 'h-fit',
    margin = 'm-0',
    editButtonMargin = '',
    overflow = 'overflow-auto',
    blueStyle = false
}) => {

    const handleEditButtonClick = () => {
        if (editButtonDisplayed) {
            if (onEditButtonClick) {
                onEditButtonClick();
            }
            else {
                return null;
            }
        }
    };

    const handleDownloadButtonClick = (done) => {
        if (downloadButtonDisplayed) {
            if (onDownloadButtonClick) {
                onDownloadButtonClick();
                done(true);
            }
            else {
                return null;
            }
        }
    };

    return (
        <div
            className={`relative rounded-lg flex flex-col ${overflow} ${width} ${height} ${margin}`}
            style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
        >
            {showHeader && (
                <div className={`${headerBackground} flex flex-row items-center justify-between w-full px-6 py-[10px] min-h-[62px]`}>
                    <h2 className="text-[#212121] font-bold text-xl" style={{fontFamily: 'DIN Condensed'}}>{title}</h2>
                    <div className="flex flex-row items-center">
                        {
                            editButtonDisplayed &&
                                <button onClick={handleEditButtonClick} className={`p-2 ${editButtonMargin}`}>
                                    <img src={blueStyle ? editBlue : edit} alt="download" className='w-5'/>
                                </button>
                        }
                        {
                            downloadButtonDisplayed && (
                                <CSVLink
                                    data={csvProps.data}
                                    headers={csvProps.headers}
                                    filename={csvProps.filename}
                                    asyncOnClick={true}
                                    target="_blank"
                                    separator=";"
                                    onClick={(event, done) => handleDownloadButtonClick(done)}
                                >
                                    <button className='p-2 mx-2'>
                                        {
                                            csvExportLoading ?
                                                <Loader size={20}/> :
                                                <img src={blueStyle ? blueDownload : download} alt="download" className='w-5'/>
                                        }
                                    </button>
                                </CSVLink>
                            )
                        }
                        {
                            coloredButtonDisplayed &&
                        <Button
                            onClick={!coloredButtonDisabled && onButtonClick}
                            content={buttonContent}
                            icon={buttonIcon}
                            disabled={coloredButtonDisabled}
                            type={blueStyle ? 'blue' : 'primary'}
                        />
                        }
                    </div>
                </div>
            )}
            <div className={`w-full grow ${contentPadding}`} style={{boxShadow: showLineHeader ? 'inset 0 1px 0 #F0F0F0' : null}}>
                {children}
            </div>
        </div>
    );
};

export default ContainerCard;
