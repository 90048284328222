import React from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';
import Table from '../../../components/ui/Table';

const ContractorsCard = ({
    data,
    setInvoiceContractorToBeUpdated,
    setIsContractorPopupOpen,
    tableConfig
}) => {
    const onButtonClick = () => {
        setInvoiceContractorToBeUpdated(null);
        setIsContractorPopupOpen(true)
    }

    return (
        <ContainerCard
            title='Factures sous/co-traitants'
            coloredButtonDisplayed={data.status < 300}
            buttonContent='Nouvelle facture'
            onButtonClick={onButtonClick}
            width='w-1/3'
            height='h-[300px]'
        >
            <Table
                data={data.contractors}
                tableConfig={tableConfig}
            />
        </ContainerCard>
    )
}

export default ContractorsCard;
