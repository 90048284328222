import {frenchFormat, standardFormat} from "../../../utils";

export const exportInvoicesDataToCsv = (
    invoicesData,
    setCsvProps,
    setCsvExportLoading,
    // datePickerDate
) => {

    setCsvExportLoading(true);

    let csvData = [];

    const getInvoiceStatus = (status) => {
        switch (status) {
            case 0:
                return 'Prévisionnelle';
            case 50:
                return 'A émettre';
            case 100:
                return 'À valider';
            case 200:
                return 'Validée';
            case 300:
                return 'Émise';
            case 400:
                return 'Partiellement Réouvrée';
            case 500:
                return 'Payée';
            case 600:
                return 'Annulée';
            default:
                return 'Indéfini';
        }
    };

    const getRessourceNames = (ressource) => {
        if (!ressource) {
            return '';
        }
        else {
            const fisrtname = ressource.first_name ?? '';
            const lastname = ressource.last_name ?? '';
            const username = ressource.username ?? '';
        
            return `${username} - ${fisrtname} ${lastname}`
        }
    }

    const getTotalVat = (data) => {
        return (
            (+data.billable + +data.billable * (+data.billable_vat_rate / 100) + +data.expenses + +data.expenses * (+data.expenses_vat_rate / 100) ).toFixed(2) + " €"
        );
    }

    for (const invoice of invoicesData) {
        csvData.push({
            invoiceId: invoice.id,
            invoiceDeal: invoice.deal?.short_name ?? '',
            invoiceStatus: getInvoiceStatus(invoice.status),
            invoiceProductionLead: getRessourceNames(invoice.deal?.production_lead),
            invoiceBillingDate: frenchFormat(invoice.billing_date),
            invoiceDueDate: frenchFormat(invoice.due_date),
            invoicePaymentDate: invoice.payment_date ? frenchFormat(invoice.payment_date) : "-",
            invoiceTotalExclTax: (+invoice.billable + +invoice.expenses).toFixed(2) + " €",
            invoiceTotalVat: getTotalVat(invoice)
        });
    }

    const headers = [
        {label: 'Numéro facture', key: 'invoiceId'},
        {label: 'Affaire', key: 'invoiceDeal'},
        {label: 'Statut', key: 'invoiceStatus'},
        {label: 'Resp. Production', key: 'invoiceProductionLead'},
        {label: 'Facturation', key: 'invoiceBillingDate'},
        {label: 'Echéance', key: 'invoiceDueDate'},
        {label: 'Paiement', key: 'invoicePaymentDate'},
        {label: 'Total net HT', key: 'invoiceTotalExclTax'},
        {label: 'Total net TVA', key: 'invoiceTotalVat'}
    ];

    setCsvProps({
        //filename: `factures_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        filename: `factures.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}

export const exportDetailsDataToCsv = (
    detailsData,
    setCsvProps,
    setCsvExportLoading
) => {

    setCsvExportLoading(true);

    let csvData = [];

    for (const detail of detailsData) {
        csvData.push({
            detailsDescription: detail.text,
            detailsQuantity: +detail.quantity,
            detailsUnitPrice: +detail.unit_price,
            detailsAmount: detail.amount,
            detailsVat: +detail.vat,
            detailsNetAmount: +detail.netAmount
        });
    }

    const headers = [
        {label: 'Description', key: 'detailsDescription'},
        {label: 'Quantité', key: 'detailsQuantity'},
        {label: 'PU HT', key: 'detailsUnitPrice'},
        {label: 'Montant HT', key: 'detailsAmount'},
        {label: 'TVA (%)', key: 'detailsVat'},
        {label: 'Montant TTC', key: 'detailsNetAmount'}
    ];

    setCsvProps({
        filename: `détails_${standardFormat(new Date())}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}
