import {CustomerCategoryDao} from '../../dao';
import {ErrorModel, CustomerCategoryModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_CUSTOMER_CATEGORIES';

export async function getAllCustomerCategories(
    userData: Record<string, string>,
    params: string,
    page: number,
) {
    try {
        const customerCategories = await CustomerCategoryDao.getAllCustomerCategories(userData.userToken, params, page);

        const customerCategoryParsed = customerCategories.results.map((customerCategory: Record<string, any>) => CustomerCategoryModel.parseCustomerCategory(customerCategory));

        return customerCategoryParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des factures',
            error: error
        });
    }
}
