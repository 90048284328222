import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Datepicker from "../../../../components/ui/Datepicker";
import Table from "../../../../components/ui/Table/Table";
import TabCard from "../../../../components/ui/TabCard/TabCard";
import {aggregateGrossMarginProducedObject} from '../../functions/aggregateGrossMarginProducedObject';
import {marginProducedTableConfig} from '../../configs/resultIndicatorTab/grossMarginProducedSection/marginProducedTableConfig';
import {marginProducedTabConfig} from '../../configs/resultIndicatorTab/grossMarginProducedSection/marginProducedTabConfig';

const GrossMarginProduced = ({
    results,
    datePickerDate,
    handleDatePickerChange
}) => {
    console.log("results MARGE BRUTE", results);

    return (
        <div className='my-10'>
            <LineTitle title="Marge brute produite" textSize='text-4xl'/>

            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>

            <div className="mt-6">
                <Table data={aggregateGrossMarginProducedObject(results.global)} tableConfig={marginProducedTableConfig()}/>
            </div>

            <div className="mt-5">
                <TabCard config={marginProducedTabConfig(results.by_status)}/>
            </div>
        </div>
    )
}

export default GrossMarginProduced