import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { frenchFormat, handleFilterList, useFilterQuery, fetchSelectData } from '../../../utils';
import {DealTagsActions, UserActions, LocationActions, DealActions} from '../../../actions'
import { SearchInput, TableDatePicker, SelectInput } from '../../../components/ui/Table';
import Badge from '../../../components/ui/Badge';
import ActionsButton from '../../../components/ui/ActionsButton';
import CheckboxInput from '../../../components/ui/Inputs/CheckboxInput/CheckboxInput';

export const referencesTableConfig = ({
    handleCheckReference,
    showReferencesCheckboxes,
    referencesChecked,
    userData,
    filterReferenceList
}) => {
    const [tags, setTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [deals, setDeals] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        search: '',
        before: '',
        after: '',
        tags: '',
        owner: '',
        status: '',
        location: '',
        deal: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };
    
    useFilterQuery(filterQuery, filterReferenceList);

    // ↓ get data for select input
    const handleGetTags = async (search, page) => {
        await fetchSelectData({action: DealTagsActions.getAllDealTags, search:`&search=${search}`, page, setter: setTags, userData});
    };

    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page, setter: setUsers, userData});
    };

    const handleGetLocations = async (search, page = 1) => {
        await fetchSelectData({action: LocationActions.getAllLocation, search:`&search=${search}&is_active=true`, page, setter: setLocations, userData, resultPath: 'data'});
    };

    const handleGetDeals = async (search, page = 1) => {
        await fetchSelectData({action: DealActions.getAllDeals, search:`&type=business&display=${userData.userProfileName}&search=${search}`, page, setter: setDeals, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ deal tags dropdown values
        (async () => {
            await handleGetTags('', 1);
        })();

        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })();

        // ↓ locations dropdown values
        (async () => {
            await handleGetLocations('', 1);
        })();

        // ↓ deals dropdown values
        (async () => {
            await handleGetDeals('', 1);
        })
    }, []);
    
    const navigate = useNavigate();

    const status = [
        {
            value: 'draft',
            label: 'Brouillon'
        },
        {
            value: 'archived',
            label: 'Archivé'
        },
        {
            value: 'published',
            label: 'Publié'
        }
    ];

    const checkboxConfig = (
        {
            label: '',
            value: 'id',
            width: 'w-[2%]',
            sort: false,
            component: (item) => {
                const isChecked = referencesChecked.find(reference => reference.id === item.id);
                
                if (item?.customer_logo) return (
                    <CheckboxInput value={isChecked ? true : false} onChange={() => handleCheckReference({
                        id: item.id,
                        title: item.title,
                        name: item.name
                    })}/>
                );
            },
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        }
    );

    const baseConfig = [
        {
            label: 'Affaire',
            value: 'deal.short_name',
            width: 'w-[20%]',
            sort: true,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-start w-full">
                        {item.deal.short_name}
                    </div>
                );
            },
            filterComponent: () => <SelectInput
                name="deal"
                options={deals?.options}
                hasNextPage={deals?.hasNextPage}
                isSearchable={true}
                onChange={handleFilter}
                fetchFunction={(handleGetDeals)}
                labelKeys={['short_name']}
                valueKey={'id'}
                labelWidth='w-40'
            />,
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Référent',
            value: 'owner.username',
            width: 'w-[8%]',
            sort: true,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-center text-center">
                        {
                            item.owner ?
                                <Badge content={[item.owner]} type='rounded'/>
                                : '-'
                        }
                    </div>
                );
            },
            filterComponent: () => <SelectInput
                name="owner"
                options={users?.options}
                hasNextPage={users?.hasNextPage}
                isSearchable={true}
                onChange={handleFilter}
                fetchFunction={(handleGetUsers)}
                labelKeys={['first_name', 'last_name']}
                valueKey={'id'}
                labelWidth='w-40'
            />,
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Référence',
            value: 'name',
            width: 'w-[25%]',
            sort: true,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-center">
                        {item.name ? item.name : '-'}
                    </div>
                );
            },
            filterComponent: () => <SearchInput name={'search'} onChange={handleFilter}/>,
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Statut',
            value: 'status',
            width: 'w-[7%]',
            sort: false,
            component: (item) => {
                let title = '';

                switch (item.status) {
                    case 'published': title = 'Publiée';
                        break;
                    case 'draft': title = 'Brouillon';
                        break;
                    case 'archived': title = 'Archivée';
                        break;
                    default: title = 'brouillon';
                }

                return (
                    <div className="flex flex-row items-center justify-center">
                        <Badge content={title}/>
                    </div>
                )
            },
            filterComponent: () => <SelectInput
                name="status"
                options={status}
                onChange={handleFilter}
                labelKeys={['label']}
                valueKey={'value'}
                labelWidth='w-40'
            />,
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Réalisation',
            value: 'realisation_date',
            width: 'w-[10%]',
            sort: true,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-center">
                        {item.realisation_date ? frenchFormat(item.realisation_date) : "-"}
                    </div>
                );
            },
            filterComponent: () => (
                <TableDatePicker name={null} onChange={handleFilter}/>
            ),
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Département',
            value: 'department',
            width: 'w-[8%]',
            sort: true,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-center">
                        <p>{item.deal?.customer?.location}</p>
                    </div>
                );
            },
            filterComponent: () => <SelectInput
                name="location"
                options={locations?.options}
                hasNextPage={locations?.hasNextPage}
                isSearchable={true}
                onChange={handleFilter}
                fetchFunction={(handleGetLocations)}
                labelKeys={['name']}
                valueKey={'name'}
                labelWidth='w-40'
            />,
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Budget',
            value: 'budget',
            width: 'w-[7%]',
            sort: true,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-center">
                        {item.budget ? item.budget + ' €' : "-"}
                    </div>
                );
            },
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Tags',
            value: null,
            width: 'w-[15%]',
            sort: false,
            component: (item) => {
                return (
                    <div className="flex flex-row flex-wrap items-center justify-center gap-x-2">
                        {item.tags.length ? item.tags.map((tag, index) => {
                            return (
                                <Badge
                                    key={index}
                                    content={tag}
                                    color={tag.color}
                                />
                            )
                        }) : '-'}
                    </div>
                );
            },
            filterComponent: () => <SelectInput
                name="tags"
                options={tags?.options}
                hasNextPage={tags?.hasNextPage}
                isSearchable={true}
                onChange={handleFilter}
                fetchFunction={(handleGetTags)}
                labelKeys={['name']}
                valueKey={'name'}
                labelWidth='w-40'
            />,
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        },
        {
            label: 'Détails',
            value: null,
            width: 'w-[9%]',
            sort: false,
            component: (item) => {
                return (
                    <div className="flex flex-row items-center justify-center">
                        <ActionsButton
                            type="details"
                            isLoading={false}
                            onClick={() => navigate(`/deals/${item.deal.id}/references`)}
                            disabled={false}
                        />
                    </div>
                );
            },
            sumDisplayed: false,
            sumBy: '',
            sumLabel: ''
        }
    ];

    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: showReferencesCheckboxes ? [checkboxConfig, ...baseConfig] : [...baseConfig]
        }
    );
};
