import {ACTION_CODE} from './_actionCode';
import {ContractorCategoryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'UPDATE_CONTRACTOR_CATEGORY';

export async function updateContractorCategory(userData = {}, contractorCategoryId: number | string, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - updateContractorCategory - Start');

        const result = await ContractorCategoryService.updateContractorCategory(userData, contractorCategoryId, payload);

        LoggerService.logInfo('Action - updateContractorCategory - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateContractorCategory - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la mise à jour de la catégorie sous/co-traitant',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
