import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_ALL_DEAL_LOSS_MOTIVES';

export async function getAllDealLossMotives(
    userData: Record<string, any>,
    params: string,
    page: number
) {
    try {

        const response = await axios.get(
            `${URL}/deal-loss-motives/?page=${page}&page_size=2000&active=true${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userData.userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des motifs de perte des affaires',
            error: error
        });
    }
}
