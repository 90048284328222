import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_AGGREGATED_CALENDAR_PRODUCTIONS_BY_MONTH';

export async function getAggregatedCalendarProductionsByMonth(
    userData: Record<string, string>,
    before: string,
    after: string,
    query?: string
) {
    try {
        const response = await axios.get(
            `${URL}/productions/calendar/month/?before=${before}&after=${after}&${query}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userData.userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur de la récuperation d\'une production',
            error: error
        });
    }
}
