import {TrainingAttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'CREATE_TRAINING_ATTACHMENT';

export async function createTrainingAttachment(
    userData: Record<string, string>,
    newTrainingAttachment: Record<string, any>
) {
    try {
        return await TrainingAttachmentDao.createTrainingAttachment(userData.userToken, newTrainingAttachment);
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la création de la piece jointe de formation',
            error: error
        });
    }
}
