import ActionsButton from '../../../../components/ui/ActionsButton';
// import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import Badge from '../../../../components/ui/Badge/Badge';
import { frenchFormat } from '../../../../utils';

export const projectsConfig = (
    handleDeleteProject,
    setIsPopupOpen,
    setToBeUpdated,
    actionButtonLoading,
    actionButtonDisabled,
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: true,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'type.short_name',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-start">
                                {item.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp',
                    width: 'w-[6%]',
                    sort: true,
                    component: (item) => {

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.production_lead ?
                                        <Badge
                                            content={[item.production_lead]}
                                            type="rounded"
                                        />
                                        : '-'
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Chiffrage',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-start">
                                {item.billable_days}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Frais',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-start">
                                {item.expense_budget}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date du début',
                    value: 'mission_start_date',
                    width: 'w-[11%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.mission_start_date ? frenchFormat(item.mission_start_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de fin',
                    value: 'mission_end_date',
                    width: 'w-[11%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.mission_end_date ? frenchFormat(item.mission_end_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: 'notes',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.notes ? item.notes : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[8%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    // item.parentStatus === 3 ?
                                    //     <>
                                    //         <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                    //         <div className="w-0 h-0 px-2.5"/>
                                    //         <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                    //     </> :
                                    <>
                                        <ActionsButton
                                            type="edit"
                                            isLoading={false}
                                            onClick={() => {
                                                setToBeUpdated(item);
                                                setIsPopupOpen(true);
                                            }}
                                            disabled={actionButtonDisabled}
                                        />
                                        <div className="w-0 h-0 px-2"/>
                                        <ActionsButton
                                            type="delete"
                                            isLoading={actionButtonLoading === item.id}
                                            onClick={() => handleDeleteProject(item.id)}
                                            disabled={actionButtonDisabled}
                                            deleteConfirmationQuestion={`Souhaitez-vous supprimer ce projet?`}
                                        />
                                    </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
