/* eslint-disable max-len */
import {capitalize} from "../../../utils";

export const staffingCenterTableConfig = (staffing) => {
    if (!staffing) return {
        dropdown: false,
        dropdownLeftPadding: false,
        greyBackground: false,
        subline: true,
        columnConfig: [
            {
                label: '-',
                value: '',
                width: 'w-[7%]',
                sort: true,
                component: () => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                                -
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: '-',
                value: '',
                width: 'w-[7%]',
                sort: true,
                component: () => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                                -
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: '-',
                value: '',
                width: 'w-[7%]',
                sort: true,
                component: () => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                                -
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: '-',
                value: '',
                width: 'w-[7%]',
                sort: true,
                component: () => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                                -
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: '-',
                value: '',
                width: 'w-[7%]',
                sort: true,
                component: () => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                                -
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: '-',
                value: '',
                width: 'w-[7%]',
                sort: true,
                component: () => {
                    return (
                        <div className="flex flex-row items-center justify-center w-full">
                                -
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            }
        ]
    }
    else {
        const columns = [];

        for (const key in staffing) {
            columns.push({
                label: capitalize(new Date(key).toLocaleString('fr-FR', { month: 'short', year: 'numeric' })),
                value: `staffing.${key}.staffed`,
                width: 'w-1/6',
                sort: true,
                component: (item) => {
                    const noValue =  (item.staffing[key]?.staffed === 0 || item.staffing[key]?.staffed === null || item.staffing[key]?.staffed === undefined || item.staffing[key]?.staffed === "") ? true : false;

                    return (
                        <div className={`flex flex-row items-center justify-center w-full font-extrabold`}>
                            <p className={`${
                                noValue ? "" :
                                    Math.round(item.staffing[key].staffed) > Math.round(item.staffing[key].available) ? "text-red-600" :
                                        Math.round(item.staffing[key].staffed) < Math.round(item.staffing[key].available) ? "text-[#F0A841]" :
                                            Math.round(item.staffing[key].staffed) === Math.round(item.staffing[key].available) ? "text-[#2CA951]" : ""
                            }`}>{noValue ? '-' : item.staffing[key].staffed.toFixed(1)}</p>
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: ''
            });
        }

        return (
            {
                dropdown: false,
                dropdownLeftPadding: false,
                greyBackground: false,
                subline: true,
                columnConfig: columns

            }
        );
    }
}
