import LineTitle from "../../../../components/ui/LineTitle/LineTitle"
import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard"
import DonutsChartsComponent from "./DonutsChartsComponent"
import {myTimesChartsConfig} from '../../configs/myTimesChartsConfig'

const MyTimes = ({ production }) => {

    if (Object.keys(production).length === 0) {
        return null;
    }

    const { global } = production;

    const data = [
        {
            type: 'Production facturée:',
            value: global.billed || 0
        },
        {
            type: 'Projets internes:',
            value: global.internal_project || 0
        },
        {
            type: 'Commercial proactif:',
            value: global.other || 0
        },
        {
            type: 'Congés:',
            value: global.vacations || 0
        },
        {
            type: 'Production non facturé:',
            value: global.non_billed || 0
        }
    ];

    return (
        <div className="my-10">
            <LineTitle title="Mes temps"/>

            <ContainerCard margin='mt-5' coloredButtonDisplayed={false} showHeader={false}>
                <DonutsChartsComponent data={data} label='jours' config={myTimesChartsConfig}/>
            </ContainerCard>
        </div>
    )
}

export default MyTimes