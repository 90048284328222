import {FolderFilled, FolderOpenFilled} from '@ant-design/icons';
import {Link} from 'react-router-dom';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import {transformThousandNumbers} from '../../../../utils';

const ClientsInvoices = ({
    color,
    sentInvoicesData,
    draftInvoicesData
}) => {

    const folderClosedIcon = <FolderFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const folderOpenIcon = <FolderOpenFilled  style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = (data, key) => {
        if (Object.keys(data).length < 1) {
            return 0;
        }
        else {
            let sum = 0;

            for(const k in data) {
                sum += data[k][key];
            }

            return sum;
        }
    }

    return (
        <div className="my-10">
            <LineTitle title="Factures clients"/>
            <Link to='sent-invoices'>
                <div className='mt-5 cursor-pointer'>
                    <SimpleCard
                        width='w-full'
                        title={getSum(sentInvoicesData, 'count')}
                        background={color === 'orange' ? 'orange' : 'blue'}
                        subTitle="Factures en cours totaux"
                        iconComponent={folderClosedIcon}
                        topComponent={<TopComponent color={color} value={getSum(sentInvoicesData, 'value')}/>}
                        bottomComponent={<BottomComponent data={sentInvoicesData} color={color}/> }
                    />
                </div>
            </Link>
            <Link to='draft-invoices'>
                <div className='mt-5 cursor-pointer'>
                    <SimpleCard
                        width='w-full'
                        title={getSum(draftInvoicesData, 'count')}
                        background={color === 'orange' ? 'orange' : 'blue'}
                        subTitle="Factures prévisionnelles totales"
                        iconComponent={folderOpenIcon}
                        topComponent={<TopComponent color={color} value={getSum(draftInvoicesData, 'value')}/>}
                        bottomComponent={<BottomComponent data={draftInvoicesData} color={color}/>}
                    />
                </div>
            </Link>
        </div>
    )
}

export default ClientsInvoices;

export const TopComponent = ({ value, color }) => {
    return (
        <div className='relative z-20 bg-white'>
            <div className='px-6 py-5 bg-white border border-gray-200 rounded-b-md card-bottom-shadow'>
                <p style={{ fontFamily: 'Din Condensed' }} className={`text-4xl ${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>
                    {transformThousandNumbers(value)}
                </p>
                <p className={`${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>Euros</p>
            </div>
        </div>
    )
}

export const Card = ({ title, invoicesQuantity, invoicesTotal, color, width = 'w-[32%]' }) => {
    return (
        <div className={`${width} px-6 py-4 border rounded-lg border-gray-200 card-shadow`}>
            <p style={{ fontFamily: 'Din condensed' }} className='block mx-auto text-xl w-fit'>{title}</p>
            <div className='flex justify-between my-5'>
                <div>
                    <p style={{ fontFamily: 'Din condensed' }} className={`text-4xl ${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>
                        {invoicesQuantity ?? '-'}
                    </p>
                    <p className={`${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>Factures</p>
                </div>
                <div>
                    <p style={{ fontFamily: 'Din condensed' }} className={`text-4xl ${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>
                        {transformThousandNumbers(invoicesTotal)}
                    </p>
                    <p className={`${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>Euros</p>
                </div>
            </div>
            <div className='h-[1px] bg-gray-200 w-full'/>
        </div>
    )
}

export const BottomComponent = ({data, color}) => {
    return (
        <div className='z-0 flex flex-wrap justify-between px-6 py-4 bg-white rounded-b-md gap-y-5'>
            <Card
                color={color}
                title="Mois d’1 mois"
                invoicesQuantity={data.m1?.count}
                invoicesTotal={data.m1?.value}
            />
            <Card
                color={color}
                title="1 à 2 mois"
                invoicesQuantity={data.m2?.count}
                invoicesTotal={data.m2?.value}
            />
            <Card
                color={color}
                title="2 à 3 mois"
                invoicesQuantity={data.m3?.count}
                invoicesTotal={data.m3?.value}
            />
            <Card
                color={color}
                title="3 à 6 mois"
                invoicesQuantity={data.q1?.count}
                invoicesTotal={data.q1?.value}
                width='w-[49%]'
            />
            <Card
                color={color}
                title="6 mois à 1 an"
                invoicesQuantity={data.s1?.count}
                invoicesTotal={data.s1?.value}
                width='w-[49%]'
            />
        </div>
    )
}