import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import FileDragNDropInput from '../../../../components/ui/Inputs/FileDragNDropInput';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import Button from '../../../../components/ui/Button';
import {AttachmentActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const AttachmentPopup = ({
    userData,
    dealData,
    setView,
    refreshData,
    blueStyle
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [attachmentTypes, setAttachmentTypes] = useState([]);

    const [filename, setFilename] = useState('');

    // TO DO : create an new file on submint to be able to change the name;
    // create a local sate that can be changed bu entering a name in the input

    // -- react hook form --
    const defaultValues = {
        "name": "",
        "type": "",
        "file": null,
        "deal": dealData.id
    };
    const {handleSubmit, control, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // Get dropdows data
    useEffect(() => {
        // ↓ type de document dropdown values
        (async () => {
            const result = await AttachmentActions.getAllAttachmentTypes(userData);

            if (result.success) {
                setAttachmentTypes(result.data.results)
            }
            (result.data);
        })();
    }, []);

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const getExtension = data.file.name.split('.').pop();

        let newFile = new File([data.file], `${filename.length ? filename + "." + getExtension : data.file.name  + "." + getExtension}`, {
            uid: data.file.uid,
            type: data.file.type,
            lastModified: data.file.lastModified
        });

        const newAttachment = {
            "type": data.type.name,
            "name": data.name,
            "file": newFile,
            "deal": dealData.id
        }

        const result = await AttachmentActions.createAttachment(userData, newAttachment);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            notify('L\'affaire à bien été créé');
            setView(false);
        }

        setFilename('');
        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title='Nouveau Document'
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-col px-4 pt-2 pb-6">
                <div className="mb-6">
                    <Controller
                        name="file"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <FileDragNDropInput
                                label='Document'
                                onChange={onChange}
                                setFilename={setFilename}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nom complet"
                            />
                        )}
                    />
                </div>
                <div className='flex flex-row justify-between mb-3'>
                    <Controller
                        name="type"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le responsable de mission'
                        }}}
                        render={({
                            field: {onChange, value, ref}
                        }) => (
                            <SelectInput
                                value={value}
                                inputRef={ref}
                                options={attachmentTypes}
                                onChange={onChange}
                                labelKeys={['name']}
                                required={true}
                                label='Type de document'
                                placeholder='Type de document'
                            />
                        )}
                    />
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: {
                            value: false,
                            message: 'Veuillez saisir le nom'
                        }}}
                        render={({
                            field: {onChange},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Nom du document'
                                setFilename={setFilename}
                                onChange={onChange}
                                value={filename}
                                error={error}
                                required={false}
                                disabled={disabled}
                                placeholder="Nom du document"
                                type="text"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Ajouter un document'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default AttachmentPopup;
