import {FileDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'CREATE__FILE';

export async function createFile(
    userData: Record<string, string>,
    payload: Record<string, unknown>
) {

    try {
        return await FileDao.createFile(userData.userToken, payload);
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}