import {ACTION_CODE} from './_actionCode';
import {UserService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_USERS';

export async function getAllSortedUsers(
    userData: Record<string, string>,
    search: string,
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllUsers - Start');

        const getAllUsersResult = await UserService.getAllUsers(userData.userToken, search, page);

        getAllUsersResult.results = getAllUsersResult.results.sort((a: any, b: any) => {
            const nameA = a.username.toUpperCase();
            const nameB = b.username.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        LoggerService.logInfo('Action - getAllUsers - End - Success');

        return ResultModel.newSuccessResult(getAllUsersResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllUsers - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des utilisateurs.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
