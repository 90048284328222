import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import TabCard from '../../../../components/ui/TabCard/TabCard';
import {consultantContributionsTabConfig} from '../../configs/consultantContributionsTabConfig';

const ConsultantContributions = ({
    data,
    datePickerValue,
    onDatePickerChange
}) => {
    return (
        <div className='my-10'>
            <LineTitle title='Contribution des consultants'/>
            <div className='mt-10 mb-6'>
                <Datepicker
                    type='range'
                    defaultValue={datePickerValue}
                    onChange={onDatePickerChange}
                />
            </div>
            <TabCard config={consultantContributionsTabConfig(data)}/>
        </div>
    )
}

export default ConsultantContributions;
