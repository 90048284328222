import Badge from "../../../components/ui/Badge";
import ActionsButton from '../../../components/ui/ActionsButton';
import {frenchFormat} from "../../../utils";

export const closedDealsCustomerTableConfig = (
    handleSendToCustomer,
    actionButtonLoading,
    actionButtonDisabled
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'short_name',
                    width: 'w-[24%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Client',
                    value: 'customer.name',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item?.customer.name ?? '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Commerciaux',
                    value: null,
                    width: 'w-[13%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge type='round' content={item.sales}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Phase',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.production_lead ? "Production" : "Commerciale"}
                                    color={item.production_lead ? '#F0A841' : '#00ABE9'}
                                    tooltip={item.production_lead ? "Phase de production" : "Phase commerciale"}
                                />
                            </div>
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'das',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.das}
                                    color={item.das === 'Indéfini' ? '#646464' : null}
                                    blueStyle={item.das === 'Formation'}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Fin',
                    value: 'mission_end_date',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.mission_end_date ? frenchFormat(item.mission_end_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours',
                    value: 'billable_days',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.billable_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+parseInt(item.budget).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[6%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="send"
                                    isLoading={actionButtonLoading === item.id}
                                    onClick={() => handleSendToCustomer(item.id)}
                                    disabled={actionButtonDisabled}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
