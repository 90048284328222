import {ACTION_CODE} from './_actionCode';
import {TrainingAttendancesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'GET_ALL_TRAINING_ATTENDANCES';

export async function getAllTrainingAttendances(userData: Record<string, string>, params: string) {
    try {
        LoggerService.logInfo('Action - getAllTrainingAttendances - Start');

        const getAllTrainingAttendancesResult = await TrainingAttendancesService.getAllTrainingAttendances(userData, params);

        const {data, metadata} = getAllTrainingAttendancesResult;

        LoggerService.logInfo('Action - getAllTrainingAttendances - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllTrainingAttendances - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des suivis de formation',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
