import {ErrorModel, ResultModel} from '../../models';
import {Dispatch, AnyAction} from '@reduxjs/toolkit';
import {LoggerService, UserService} from '../../services';
import {ACTION_CODE} from './_actionCode';
const FUNCTION_CODE = 'GET_USER_DETAILS';

export async function getUserDetails(dispatch: Dispatch<AnyAction>, userToken: string) {
    try {
        LoggerService.logInfo('Action - getUserDetails - Start');

        const getUserId = await UserService.getUserDetails(userToken);

        return ResultModel.newSuccessResult(getUserId);
    }
    catch (error) {
        LoggerService.logInfo('getUserDetails - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
