import Datepicker from "../../../../components/ui/Datepicker/Datepicker";
import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Table from "../../../../components/ui/Table/Table";
import TabCard from "../../../../components/ui/TabCard/TabCard";
import {grossMarginTableConfig} from "../../configs/grossMarginTableConfig";
import {aggregateGrossMarginObject} from "../../functions/aggregateGrossMarginObject";
import {grossMarginTabConfig} from "../../configs/grossMarginTabConfig";

const ForecastGrossMargin = ({
    data,
    datePickerDate,
    handleDatePickerChange
}) => {
    return (
        <div className="my-10">
            <LineTitle title="Marge brute prévisionnelle"/>

            <div className="mt-8">
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />

                <div className="mt-6">
                    <Table
                        data={aggregateGrossMarginObject(data.global)}
                        tableConfig={grossMarginTableConfig()}
                    />
                </div>

                <div className="mt-5">
                    <TabCard config={grossMarginTabConfig(data.by_status)}/>
                </div>
            </div>

        </div>
    )
}

export default ForecastGrossMargin;
