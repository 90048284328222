import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {NotificationActions} from './../../../actions'
import notificationIcon from './../../../assets/icons/navbar/bell-notification.png';
import { notify } from '../../ui/Toast/Toast';

const NotificationIcon = ({userData}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        (async () => {
            const response = await NotificationActions.getCount(userData);

            if (!response.success) {
                notify('impossible de récupérer le nombre de notification');
            }
            else {
                setCount(response.data.count);
            }
        })();
    }, []);

    return (
        <Link to="/notifications">
            <div className="relative cursor-pointer">
                <img src={notificationIcon} className="h-6" alt=""/>
                {count !== 0 && (
                    <span className="absolute top-0 flex items-center justify-center w-auto h-4 px-2 py-1 -mt-2 -mr-1 text-xs text-white bg-red-500 rounded-full -right-3">{count}</span>
                )}
            </div>
        </Link>
    );
};

export default NotificationIcon;
