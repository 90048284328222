import * as Utility from '../../../utils/index.js';
import DayCell from './DayCell';

const MonthView = ({
    yearNumber,
    monthSelectedNumber,
    handleSetCurrentDay,
    daySelectedNumber,
    data,
    calendarConfig,
    onCalendarCellClick
}) => {
    const {daysOfWeek, getDaysInMonth, getFirstDayOfMonth, getFormatedDate} = Utility;
    const firstDayOfMonth = getFirstDayOfMonth(yearNumber, monthSelectedNumber).getDay();
    const daysInMonth = getDaysInMonth(yearNumber, monthSelectedNumber);
    const DayNumberOfPrevMonth = getDaysInMonth(monthSelectedNumber !== 0 ? yearNumber : yearNumber - 1, monthSelectedNumber === 0 ? 11 : monthSelectedNumber - 1);

    const getDayNumberArray = () => {
        const cellNumber = daysInMonth + firstDayOfMonth <= 28 ? 28 : daysInMonth + firstDayOfMonth <= 35 ? 35 : 42;
        const dayNumberArray = [];

        for (let i = 1; i <= cellNumber; i++) {
            const nextMonthDayNumber = i - daysInMonth - firstDayOfMonth;

            if (i <= firstDayOfMonth) {
                dayNumberArray.push(DayNumberOfPrevMonth - firstDayOfMonth + i);
            }
            else if (i > firstDayOfMonth && i <= daysInMonth + firstDayOfMonth) {
                dayNumberArray.push(i - firstDayOfMonth);
            }
            else {
                dayNumberArray.push(nextMonthDayNumber);
            }
        }

        return dayNumberArray;
    };

    const getDayStyles = (day, index) => {
        let textColor = '';
        let cellStyle = '';
        let isClickable = false;
        let isOpenDays = false;

        // Set the style of the cell
        if ((index % 7 === 5 || index % 7 === 6) && index >= firstDayOfMonth && index < daysInMonth + firstDayOfMonth) {
            cellStyle = 'bg-[#f4f4f4] border-[#f0f0f0]';
            textColor = 'text-[#646464]';
            isOpenDays = calendarConfig.isWeekEndOpennedDay;
            isClickable = calendarConfig.isWeekEndOpennedDay;
        }
        else if (index < day && index !== day - 1 || index > daysInMonth + firstDayOfMonth - 1) {
            cellStyle = 'border-[#f0f0f0]';
            textColor = 'text-[#bfbfbf]';
        }
        else if (day === daySelectedNumber) {
            cellStyle = 'bg-[#f9e2d7] border-[#e36d38]';
            textColor = 'text-transparent text-gradient w-fit self-end';
            isOpenDays = true;
            isClickable = true;
        }
        else {
            cellStyle = 'bg-white border-[#f0f0f0] shadow-none hover:shadow-[0_2px_4px_rgba(0,0,0,0.25)] transition-all';
            textColor = 'text-[#262626]';
            isClickable = true;
            isOpenDays = true;
        }

        return {
            cellStyle,
            textColor,
            isClickable,
            isOpenDays
        };
    };

    return (
        <>
            <div className="grid grid-cols-7" style={{fontFamily: 'DIN Condensed'}}>
                {daysOfWeek.map((day, index) => {
                    return (
                        <p className="mb-1 text-xl text-center text-[#262626]" key={index}>
                            {day}
                        </p>
                    );
                })}
            </div>

            <div className="grid grid-cols-7 gap-x-3">
                {getDayNumberArray().map((day, index) => {
                    const {cellStyle, textColor, isClickable, isOpenDays} = getDayStyles(day, index);
                    const dayDate = getFormatedDate(yearNumber, monthSelectedNumber, day);
                    const dayData = calendarConfig.monthFilterDataFunction(data, dayDate);

                    return (
                        <DayCell
                            key={`day-${index}`}
                            day={day}
                            dayDate={dayDate}
                            index={index}
                            dayData={dayData}
                            isClickable={isClickable}
                            cellStyle={cellStyle}
                            textColor={textColor}
                            handleSetCurrentDay={handleSetCurrentDay}
                            isOpenDays={isOpenDays}
                            calendarConfig={calendarConfig}
                            onCalendarCellClick={onCalendarCellClick}
                        />
                    );
                })}
            </div>
        </>
    );
};
export default MonthView;
