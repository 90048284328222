import React, {useState} from 'react';

import Datepicker from '../../../components/ui/Datepicker';
import Table from '../../../components/ui/Table';
import ContainerCard from '../../../components/ui/ContainerCard';
import {exportExpenseReportDataToCsv} from '../functions/exportDataToCsv';

const ExpenseBalance = ({
    onButtonClick,
    datePickerValue,
    onDatePickerChange,
    tableData,
    tableLoader,
    expensesReportsConfig,
    expensesConfig,
    expenseReportpaginationMetadata,
    handleGetTableData
}) => {
    
    // CSV export states
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });
    
    const [csvExportLoading, setCsvExportLoading] = useState(false);

    return (
        <ContainerCard
            title='Bilan des frais'
            buttonContent='Nouveau frais'
            onButtonClick={onButtonClick}
            downloadButtonDisplayed={tableData.length > 0 && !tableLoader}
            onDownloadButtonClick={() => exportExpenseReportDataToCsv(
                tableData,
                setCsvProps,
                setCsvExportLoading,
                datePickerValue
            )}
            csvProps={csvProps}
            csvExportLoading={csvExportLoading}
            overflow='overflow-inherit'
        >
            <div>{csvExportLoading && 'TRUE'}</div>
            <div className="w-full py-2">
                <Datepicker
                    type="range"
                    onChange={onDatePickerChange}
                    defaultValue={datePickerValue}
                />
            </div>
            <Table
                tableConfig={expensesReportsConfig}
                dropdownConfig={expensesConfig}
                data={tableData}
                loading={tableLoader}
                dropdownChildrenKey='expenses'
                onPageNumberChange={handleGetTableData}
                paginationMetadata={expenseReportpaginationMetadata}
            />
        </ContainerCard>
    );
};

export default ExpenseBalance;
