import {ACTION_CODE} from './_actionCode';
import {ReferenceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_REFERENCE';

export async function updateReference(
    userData: Record<string, string>,
    updatedReference: Record<string, any>,
    id: string
) {
    try {

        LoggerService.logInfo('Action - updateReference - Start');

        const updateReferenceResult = await ReferenceService.updateReference(userData, updatedReference, id);

        LoggerService.logInfo('Action - updateReference - End - Success');

        return ResultModel.newSuccessResult(updateReferenceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateReference - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
