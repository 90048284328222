import {CustomersDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'CREATE_CUSTOMER';

export async function createCustomer(
    userData: Record<string, string>,
    customer: Record<string, string>
) {
    try {
        const createCustomerResult = await CustomersDao.createCustomer(userData.userToken, customer);

        return createCustomerResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création du client.',
            error: error
        });
    }
}
