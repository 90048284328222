import {ACTION_CODE} from './_actionCode';
import {LocationService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_LOCATION';

export async function createLocation(userData: Record<string, any>, location: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createLocation - Start');

        const createLocationResult = await LocationService.createLocation(userData, location);

        LoggerService.logInfo('Action - createLocation - End - Success');

        return ResultModel.newSuccessResult(createLocationResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createLocation - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une banque.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
