export const externalRessourcesDto = {
    from(externalRessourcesResult) {

        const getDasSData = () => {
            let dasS = {};

            for (const key in externalRessourcesResult) {
                dasS[key] = {
                    "Cotraitants": {
                        "count": externalRessourcesResult[key]?.["Cotraitants"]?.count ?? 0,
                        "days": externalRessourcesResult[key]?.["Cotraitants"]?.days ?? 0,
                        "amount": externalRessourcesResult[key]?.["Cotraitants"]?.amount ?? 0,
                        "commission": externalRessourcesResult[key]?.["Cotraitants"]?.commission ?? 0
                    },
                    "Sous-traitants": {
                        "count": externalRessourcesResult[key]?.["Sous-traitants"]?.count ?? 0,
                        "days": externalRessourcesResult[key]?.["Sous-traitants"]?.days ?? 0,
                        "amount": externalRessourcesResult[key]?.["Sous-traitants"]?.amount ?? 0,
                        "commission": externalRessourcesResult[key]?.["Sous-traitants"]?.commission ?? 0
                    }
                }
            }

            return dasS;
        }

        return getDasSData();
    }
}
