import {TrainingSpecialitiesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'DELETE_CUSTOMER_CATEGORY';

export async function deleteTrainingSpeciality(
    userData: Record<string, string>,
    trainingSpecialtyId: number
) {
    try {
        const result = await TrainingSpecialitiesDao.deleteTrainingSpecialty(userData.userToken, trainingSpecialtyId);

        return result;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'un facture',
            error: error
        });
    }
}
