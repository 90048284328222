import {ACTION_CODE} from './_actionCode';
import {ProductionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_VACATION';

export async function createVacation(userData: Record<string, any>, payloadVacation: any) {
    try {
        LoggerService.logInfo('Action - createVacation- Start');

        const createVacationResult = await ProductionService.createVacation(userData, payloadVacation);

        LoggerService.logInfo('Action - createVacation - End - Success');

        return ResultModel.newSuccessResult(createVacationResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createVacation - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
