export const paymentTerms = {

    from(paymentTermsResult) {

        const getDasS = () => {
            let dasS = {};

            for (const key in paymentTermsResult) {
                dasS[key] = {
                    "count": paymentTermsResult[key]?.count ?? 0,
                    "value": paymentTermsResult[key]?.value ?? 0,
                    "details": {
                        "m1": {
                            "invoices": paymentTermsResult[key]?.details?.m1?.invoices ?? 0,
                            "customers": paymentTermsResult[key]?.details?.m1?.customers ?? 0
                        },
                        "m2": {
                            "invoices": paymentTermsResult[key]?.details?.m2?.invoices ?? 0,
                            "customers": paymentTermsResult[key]?.details?.m2?.customers ?? 0
                        },
                        "m3": {
                            "invoices": paymentTermsResult[key]?.details?.m3?.invoices ?? 0,
                            "customers": paymentTermsResult[key]?.details?.m3?.customers ?? 0
                        },
                        "q1": {
                            "invoices": paymentTermsResult[key]?.details?.q1?.invoices ?? 0,
                            "customers": paymentTermsResult[key]?.details?.q1?.customers ?? 0
                        },
                        "s1": {
                            "invoices": paymentTermsResult[key]?.details?.s1?.invoices ?? 0,
                            "customers": paymentTermsResult[key]?.details?.s1?.customers ?? 0
                        }
                    }
                }
            }

            return dasS;
        }

        return getDasS();
    }
}
