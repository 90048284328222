import storage from 'redux-persist/lib/storage';

export const SET_USER_CURRENT_SELECTED_ROLE = 'SET_USER_CURRENT_SELECTED_ROLE';
export const SET_USER_CURRENT_SELECTED_ROLE_LISTENER = 'SET_USER_CURRENT_SELECTED_ROLE_LISTENER';
export const SET_CURRENT_USER_ROLE = 'SET_CURRENT_USER_ROLE';
export const SET_USER_IS_CONNECTED = 'SET_USER_IS_CONNECTED';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_PROFILE_LISTENER = 'SET_USER_PROFILE_LISTENER';

export const persistUserConfig = {
    key: 'user',
    storage
};

/* eslint-disable no-prototype-builtins */
const initialState = {
    userCurrentSelectedRoleState: null,

    userCurrentSelectedRoleListenerState: null,
    userCurrentSelectedRoleListenerData: null,
    userCurrentSelectedRoleListenerError: null,

    userIsConnected: null,
    userToken: null,
    currentUserRole: null,

    userProfileData: null,

    userProfileListenerState: null,
    userProfileListenerData: null,
    userProfileListenerError: null
};

function UserReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_IS_CONNECTED: {
            return Object.assign({}, state, {
                userIsConnected: action.payload.userIsConnected
            });
        }
        case SET_USER_TOKEN: {
            return Object.assign({}, state, {
                userToken: action.payload.userToken
            });
        }
        case SET_USER_CURRENT_SELECTED_ROLE: {
            return Object.assign({}, state, {
                currentUserRole: action.payload.currentUserRole
            });
        }
        case SET_CURRENT_USER_ROLE: {
            return Object.assign({}, state, {
                userCurrentSelectedRoleState: action.payload.userCurrentSelectedRoleState
            });
        }
        case SET_USER_PROFILE: {
            return Object.assign({}, state, {
                userProfileData: action.payload
            });
        }
        case SET_USER_PROFILE_LISTENER: {
            return Object.assign({}, state, {
                userProfileListenerState: action.payload.state,
                ...(action.payload.hasOwnProperty('data') && {userProfileListenerData: action.payload.data}),
                ...(action.payload.hasOwnProperty('error') && {userProfileListenerError: action.payload.error})
            });
        }
        case SET_USER_CURRENT_SELECTED_ROLE_LISTENER: {
            return Object.assign({}, state, {
                userCurrentSelectedRoleListenerState: action.payload.state,
                ...(action.payload.hasOwnProperty('data') && {userCurrentSelectedListenerRoleData: action.payload.data}),
                ...(action.payload.hasOwnProperty('error') && {userCurrentSelectedListenerRoleError: action.payload.error})
            });
        }
        default:
            break;
    }

    return state;
}

export default UserReducer;
