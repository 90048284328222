import TjmCardsCards from "../components/production/TjmCards";

export const tjmTabConfig = (data) => {
    const getSum = (object, key) => {
        let sum = 0;
    
        for (const k in data) {
            sum += data[k][object][key];
        }
    
        return sum;
    }

    const getObject = () => {
        return (
            {
                junior: {
                    produced_days: getSum('junior', 'produced_days'),
                    billed_days: getSum('junior', 'billed_days'),
                    mission_value: getSum('junior', 'mission_value'),
                    gross_margin: getSum('junior', 'gross_margin')
                },
                confirmed: {
                    produced_days: getSum('confirmed', 'produced_days'),
                    billed_days: getSum('confirmed', 'billed_days'),
                    mission_value: getSum('confirmed', 'mission_value'),
                    gross_margin: getSum('confirmed', 'gross_margin')
                },
                senior: {
                    produced_days: getSum('senior', 'produced_days'),
                    billed_days: getSum('senior', 'billed_days'),
                    mission_value: getSum('senior', 'mission_value'),
                    gross_margin: getSum('senior', 'gross_margin')
                }
            }
        )
    }

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <TjmCardsCards data={getObject()}/>
    }];
        
    for(const key in data) {
                    
        const item = {
            id: key,
            label: key,
            component: <TjmCardsCards data={data[key]}/>
        }
        
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
