import {RocketFilled, ScheduleFilled} from '@ant-design/icons';

import {getCurrentMonthAndYear} from '../../../../utils';
import LineTitle from './../../../../components/ui/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import {Link} from "react-router-dom";

const ActivityIndicator = ({productionActivity, dasKey}) => {

    const ScheduleIcon = <ScheduleFilled style={{ fontSize: '30px', color:'white' }}/>;
    const RocketIcon = <RocketFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className='my-10'>
            <LineTitle title="Indicateurs d'activité" textSize='text-4xl'/>
            <p
                style={{ fontFamily: 'Din condensed' }}
                className='mt-10 text-2xl capitalize'
            >
                {getCurrentMonthAndYear()}
            </p>

            <div className='flex items-center mt-5'>
                <Link to='current-deals' className='w-1/2'>
                    <SimpleCard
                        width='w-full'
                        title={productionActivity.deals[dasKey].count}
                        subTitle="Affaires en cours"
                        iconComponent={ScheduleIcon}
                    />
                </Link>

                <div className='mx-5'/>

                <Link to='current-missions' className="w-1/2">
                    <SimpleCard
                        width='w-full'
                        title={productionActivity.missions[dasKey].count}
                        subTitle="Missions en cours"
                        iconComponent={RocketIcon}
                    />
                </Link>
            </div>
        </div>
    );
}

export default ActivityIndicator;
