import {RobotFilled, IdcardFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle";
import SimpleCard from "../../../../components/ui/SimpleCard";
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import ResourcesNumber from './ResourcesNumber';
import ResourceNeeds from './ResourceNeeds';
import {dasTeamConsultantAndEtpChartConfig} from '../../configs/ressourceManagementTab/dasTeamSection/dasTeamConsultantAndEtpChartConfig';
import {getCurrentMonthAndYear} from '../../../../utils';
import {aggregateConsultants, aggregateETP, aggregateDasData, aggregateDasDataETP} from '../../../codevDashboardPage/functions/agggregateRessources';

const DasTeam = ({data, dasKey}) => {

    const IdCardIcon = <IdcardFilled style={{ fontSize: '30px', color:'white' }}/>;
    const RobotIcon = <RobotFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className="mt-10">
            <LineTitle title="Équipe du DAS" textSize='text-4xl'/>
            <p style={{ fontFamily: 'Din condensed' }} className='mt-10 text-2xl capitalize'>{getCurrentMonthAndYear()}</p>
            <div className='flex justify-between mt-5'>
                <SimpleCard
                    width='w-[48%]'
                    title={data.current[dasKey] ? aggregateConsultants(data.current, dasKey) : 0}
                    subTitle="Consultants"
                    iconComponent={IdCardIcon}
                    bottomComponent={<BottomComponent data={data.current} dasKey={dasKey} type='consultant'/>}
                />
                <SimpleCard
                    width='w-[48%]'
                    title={data.current[dasKey] ? aggregateETP(data.current, dasKey) : 0}
                    subTitle="ETP"
                    iconComponent={RobotIcon}
                    bottomComponent={<BottomComponent data={data.current} dasKey={dasKey} type='etp'/>}
                />
            </div>
            <ResourcesNumber data={data} dasKey={dasKey}/>
            <ResourceNeeds data={data} dasKey={dasKey}/>
        </div>
    )
}

export default DasTeam;

const BottomComponent = ({data, dasKey, type}) => {

    const noData = [
        {
            type: 'Junior:',
            value: 0
        },
        {
            type: 'Confirmé:',
            value: 0
        },
        {
            type: 'Sénior:',
            value: 0
        }
    ];

    let chartData = noData;

    if (type === 'consultant') {
        chartData = data[dasKey] ? aggregateDasData(data, dasKey) : noData;
    }
    else {
        chartData = data[dasKey] ? aggregateDasDataETP(data, dasKey) : noData;
    }

    return (
        <div className='bg-white border-t border-gray-200 rounded-b-md'>
            <div className="h-[360px]">
                <DonutsChart data={chartData} config={dasTeamConsultantAndEtpChartConfig} label=""/>
            </div>
        </div>
    )
}
