import {ACTION_CODE} from './_actionCode';
import {TrainingService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_TRAINING';

export async function updateTraining(
    userData: Record<string, string>,
    updatedTraining: Record<string, any>,
    trainingId: string
) {
    try {

        LoggerService.logInfo('Action - updateTraining - Start');

        const updateTrainingResult = await TrainingService.updateTraining(userData, updatedTraining, trainingId);

        LoggerService.logInfo('Action - updateTraining - End - Success');

        return ResultModel.newSuccessResult(updateTrainingResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateTraining - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
