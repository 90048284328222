import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {FireTwoTone, GlobalOutlined} from '@ant-design/icons';

import {firstDayYear, standardFormat, lastDayYear} from '../../utils';
import {MissionActions, DealActions, DashboardActions} from '../../actions';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import Layout from '../../components/layout/Layout';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import TabNavigator from '../../components/ui/navigation/TabNavigator';
import ChargeIndicator from './components/myProductionComponents/chargesIndicator/ChargeIndicator';
import ActivityIndicator from './components/myProductionComponents/ActivityIndicator';
import Commercial from './components/myActivityReport/Commercial';
import Datepicker from '../../components/ui/Datepicker/Datepicker';
import Production from './components/myActivityReport/Production';
import Pilotage from './components/myActivityReport/Pilotage';
import MyTimes from './components/myActivityReport/MyTimes';
import CurrentMissionsPage from './CurrentMissionsPage';
import PilotedDealsPage from './PilotedDealsPage';
import InternalProjectsPage from './InternalProjectsPage';
import SpecificationsStudyPage from './SpecificationsStudyPage';
import ProposalsInProgressPage from './ProposalsInProgressPage';
import ProposalsDeliveredPage from './ProposalsDeliveredPage';

const ConsultantDashboardPage = () => {
    // ↓ Table -> «Missions en cours»
    const [missionsData, setMissionsData] = useState([]);
    const [missionsPaginationMetadata, setMissionsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [missionsFilterQuery, setMissionsFilterQuery] = useState('');
    const [missionsTableLoading, setMissionsTableLoading] = useState(false);

    // ↓ Table -> Affaires pilotées
    const [pilotedDealsData, setPilotedDealsData] = useState([]);
    const [pilotedDealsPaginationMetadata, setPilotedDealsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [pilotedDealsFilterQuery, setPilotedDealsFilterQuery] = useState('');
    const [pilotedDealsTableLoading, setPilotedDealsTableLoading] = useState(false);

    // ↓ Table -> Projets internes
    const [internalProjectsData, setInternalProjectsData] = useState([]);
    const [internalProjectsPaginationMetadata, setInternalProjectsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [internalProjectsFilterQuery, setInternalProjectsFilterQuery] = useState('');
    const [internalProjectsTableLoading, setInternalProjectsTableLoading] = useState(false);
    
    // ↓ Table -> Cahiers des charges à étudier
    const [specificationsStudyData, setSpecificationsStudyData] = useState([]);
    const [specificationsStudyPaginationMetadata, setSpecificationsStudyPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [specificationsStudyFilterQuery, setSpecificationsStudyFilterQuery] = useState('');
    const [specificationsStudyTableLoading, setSpecificationsStudyTableLoading] = useState(false);

    // ↓ Table -> Propositions à rédiger
    const [proposalsInProgressData, setProposalsInProgressData] = useState([]);
    const [proposalsInProgressPaginationMetadata, setProposalsInProgressPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [proposalsInProgressFilterQuery, setProposalsInProgressFilterQuery] = useState('');
    const [proposalsInProgressTableLoading, setProposalsInProgressTableLoading] = useState(false);

    // ↓ Table -> Propositions en attente
    const [proposalsDeliveredData, setProposalsDeliveredData] = useState([]);
    const [proposalsDeliveredPaginationMetadata, setProposalsDeliveredPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [proposalsDeliveredFilterQuery, setProposalsDeliveredFilterQuery] = useState('');
    const [proposalsDeliveredTableLoading, setProposalsDeliveredTableLoading] = useState(false);

    const [workloads, setWorkloads] = useState({});
    const [production, setProduction] = useState({});
    const [activity, setActivity] = useState({});
    const [sales, setSales] = useState({});
    const [displayedSection, setDisplayedSection] = useState('Ma production');
    const [loading, setLoading] = useState(true);
    const [datePickerDate, setDatePickerDate] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // TabNavigator icons
    const productionIcon = <FireTwoTone
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Ma production' ? '#DC3832' : '#646464'}
    />;
    const bilanIcon = <GlobalOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;

    const location = useLocation();
    const navigate = useNavigate();
    
    // Redirection pour éviter le bug de la page blanche
    useEffect(() => {
        if (location.pathname !== '/dashboard/consultant') {
            navigate('/dashboard/consultant');
        }
    }, []);

    // Permet d'éviter la persistance des states filters une fois de retour à la page principale
    useEffect(() => {
        if (location.pathname === '/dashboard/consultant') {
            if (missionsFilterQuery) setMissionsFilterQuery('');

            if(pilotedDealsFilterQuery) setPilotedDealsFilterQuery('');

            if(internalProjectsFilterQuery) setInternalProjectsFilterQuery('');

            if(specificationsStudyFilterQuery) setSpecificationsStudyFilterQuery('');

            if(proposalsInProgressFilterQuery) setProposalsInProgressFilterQuery('');

            if(proposalsDeliveredFilterQuery) setProposalsDeliveredFilterQuery('');
        }
    }, [location.pathname]);

    // --- TAB -> «Ma production» ---
    // ------------------------------
    // ↓ Fetch data -> Missions en cours
    const fetchMissionsData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setMissionsTableLoading(true);
            setMissionsData([]);
        }

        const missionsResult = await MissionActions.getAllMissions(
            userData,
            `&page_size=${missionsPaginationMetadata.pageSize}&status=1&ressource=${userData.userProfileName}${missionsFilterQuery}`,
            page
        );

        if (missionsResult.success) {
            setMissionsData(missionsResult.data);
            setMissionsPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (missionsPaginationMetadata.totalItems !== missionsResult.metadata.count) {
                setMissionsPaginationMetadata(prevState => ({...prevState, totalItems: missionsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des missions');
        }

        setMissionsTableLoading(false);
    }

    const refreshMissionsData = async (removal = false) => {
        const checkedPage = (missionsData.length === 1 && missionsPaginationMetadata.currentPage > 1 && removal) ?
            missionsPaginationMetadata.currentPage - 1 :
            missionsPaginationMetadata.currentPage;

        await fetchMissionsData(checkedPage, false);
    }

    const filterMissionList = async (query) => {
        setMissionsFilterQuery(`&${query}`);
    }

    useEffect(() => {
        fetchMissionsData(1);
    }, [missionsFilterQuery]);
    // ----------------------

    // ---------------------------------
    // ↓ Fetch data -> Affaires pilotées
    const fetchPilotedDealsData = async (page) => {
        setPilotedDealsTableLoading(true);
        setPilotedDealsData([]);

        const pilotedDealsResult = await DealActions.getAllDeals(
            userData,
            `&status=500&page_size=${pilotedDealsPaginationMetadata.pageSize}&production_lead=${userData.userProfileName}&${pilotedDealsFilterQuery}`,
            page
        );

        if (pilotedDealsResult.success) {
            setPilotedDealsData(pilotedDealsResult.data);
            setPilotedDealsPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (pilotedDealsPaginationMetadata.totalItems !== pilotedDealsResult.metadata.count) {
                setPilotedDealsPaginationMetadata(prevState => ({...prevState, totalItems: pilotedDealsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des affaires pilotées');
        }

        setPilotedDealsTableLoading(false);
    }

    useEffect(() => {
        fetchPilotedDealsData(1);
    }, [pilotedDealsFilterQuery]);
    // ---------------------------

    // --------------------------------
    // ↓ Fetch data -> Projets internes
    const fetchInternalProjectsData = async (page) => {
        setInternalProjectsTableLoading(true);
        setInternalProjectsData([]);

        const internalProjectsResult = await DealActions.getAllDeals(
            userData,
            `&type=project&page_size=${internalProjectsPaginationMetadata.pageSize}&display=${userData.userProfileName}${internalProjectsFilterQuery}`,
            page
        );
        
        if (internalProjectsResult.success) {
            setInternalProjectsData(internalProjectsResult.data);
            setInternalProjectsPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (internalProjectsPaginationMetadata.totalItems !== internalProjectsResult.metadata.count) {
                setInternalProjectsPaginationMetadata(prevState => ({...prevState, totalItems: internalProjectsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des projets internes');
        }

        setInternalProjectsTableLoading(false);
    }

    useEffect(() => {
        fetchInternalProjectsData(1);
    }, [internalProjectsFilterQuery]);
    // -------------------------------

    // ---------------------------------------------
    // ↓ Fetch data -> Cahiers des charges à étudier
    const fetchSpecificationsStudyData = async (page) => {
        setSpecificationsStudyTableLoading(true);
        setSpecificationsStudyData([]);

        const specificationsStudyResult = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${specificationsStudyPaginationMetadata.pageSize}&status=100&status=150&display=${userData.userProfileName}${specificationsStudyFilterQuery}`,
            page
        );

        if (specificationsStudyResult.success) {
            setSpecificationsStudyData(specificationsStudyResult.data);
            setSpecificationsStudyPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (specificationsStudyPaginationMetadata.totalItems !== specificationsStudyResult.metadata.count) {
                setSpecificationsStudyPaginationMetadata(prevState => ({...prevState, totalItems: specificationsStudyResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des cahiers des charges à étudier');
        }

        setSpecificationsStudyTableLoading(false);
    }

    useEffect(() => {
        fetchSpecificationsStudyData(1);
    }, [specificationsStudyFilterQuery]);
    // ----------------------------------

    // --------------------------------------
    // ↓ Fetch data -> Propositions à rédiger
    const fetchProposalsInProgressData = async (page) => {
        setProposalsInProgressTableLoading(true);
        setProposalsInProgressData([]);

        const proposalsInProgressResult = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${proposalsInProgressPaginationMetadata.pageSize}&status=200&display=${userData.userProfileName}${proposalsInProgressFilterQuery}`,
            page
        );

        if (proposalsInProgressResult.success) {
            setProposalsInProgressData(proposalsInProgressResult.data);
            setProposalsInProgressPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (proposalsInProgressPaginationMetadata.totalItems !== proposalsInProgressResult.metadata.count) {
                setProposalsInProgressPaginationMetadata(prevState => ({...prevState, totalItems: proposalsInProgressResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des propositions à rédiger');
        }

        setProposalsInProgressTableLoading(false);
    }

    useEffect(() => {
        fetchProposalsInProgressData(1);
    }, [proposalsInProgressFilterQuery]);
    // ----------------------------------

    // ---------------------------------------
    // ↓ Fetch data -> Propositions en attente
    const fetchProposalsDeliveredData = async (page) => {
        setProposalsDeliveredTableLoading(true);
        setProposalsDeliveredData([]);

        const proposalsDeliveredResult = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${proposalsDeliveredPaginationMetadata.pageSize}&status=300&display=${userData.userProfileName}${proposalsDeliveredFilterQuery}`,
            page
        );

        if (proposalsDeliveredResult.success) {
            setProposalsDeliveredData(proposalsDeliveredResult.data);
            setProposalsDeliveredPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (proposalsDeliveredPaginationMetadata.totalItems !== proposalsDeliveredResult.metadata.count) {
                setProposalsDeliveredPaginationMetadata(prevState => ({...prevState, totalItems: proposalsDeliveredResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation de la liste des propositions en attente');
        }

        setProposalsDeliveredTableLoading(false);
    }

    useEffect(() => {
        fetchProposalsDeliveredData(1);
    }, [proposalsDeliveredFilterQuery]);
    // ---------------------------------

    // Fonction filter de toutes les tables deals ci-dessus
    const filterDealList = async (query, type) => {
        if (type === 'piloted-deals') {
            setPilotedDealsFilterQuery(`&${query}`);
        }
        else if (type === 'internal-projects') {
            setInternalProjectsFilterQuery(`&${query}`);
        }
        else if (type === 'specifications-study') {
            setSpecificationsStudyFilterQuery(`&${query}`);
        }
        else if (type === 'proposals-in-progress') {
            setProposalsInProgressFilterQuery(`&${query}`);
        }
        else if (type === 'proposals-delivered') {
            setProposalsDeliveredFilterQuery(`&${query}`);
        }
    }

    const getProduction = async (date) => {
        const datePickerBefore = standardFormat(new Date(date.endDate));
        const datePickerAfter = standardFormat(new Date(date.startDate));

        const productionResult = await DashboardActions
            .ConsultantActions.ProductionActions.getAllProductions(userData, `start_date=${datePickerAfter}&end_date=${datePickerBefore}`);

        if (productionResult.success) {
            setProduction(productionResult.data);
        }
        else {
            notify('Erreur de récuperation des productions');
        }
    };

    const getActivity = async (date) => {
        const datePickerBefore = standardFormat(new Date(date.endDate));
        const datePickerAfter = standardFormat(new Date(date.startDate));

        const activityResult = await DashboardActions
            .ConsultantActions.ActivityActions.getAllActivities(userData, `start_date=${datePickerAfter}&end_date=${datePickerBefore}`);

        if (activityResult.success) {
            setActivity(activityResult.data);
        }
        else {
            notify('Erreur de récuperation des activitées');
        }
    };

    const getSales = async (date) => {
        const datePickerBefore = standardFormat(new Date(date.endDate));
        const datePickerAfter = standardFormat(new Date(date.startDate));

        const salesResult = await DashboardActions
            .ConsultantActions.SalesActions.getSales(userData, `start_date=${datePickerAfter}&end_date=${datePickerBefore}`);

        if (salesResult.success) {
            setSales(salesResult.data);
        }
        else {
            notify('Erreur de récuperation des sales');
        }
    };

    const handleDatePickerChange = async (e) => {
        setLoading(true);

        const dates = {
            startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
            endDate: e.endDate ? e.endDate : lastDayYear(new Date())
        };

        setDatePickerDate(dates);

        await getProduction(dates);
        await getActivity(dates);
        await getSales(dates);
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            const workloadResult = await DashboardActions.ConsultantActions.WorkloadActions.getAllWorkload(userData);

            if (workloadResult.success) {
                setWorkloads(workloadResult.data);
            }
            else {
                notify('Erreur de récuperation des workloads');
            }

            await getProduction(datePickerDate);
            await getActivity(datePickerDate);
            await getSales(datePickerDate);

            setLoading(false);
        })();
    }, []);

    const date = new Date();
    const month = date.toLocaleString('fr-FR', {month: 'long'});
    const year = date.getFullYear();

    const handleMissionNavigation = async (dealId) => {
        const getDeal = await DealActions.getDealById(userData, dealId);
        
        if (getDeal.success) {
            const isSale = getDeal.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

            if (userData.role === 'CO' && getDeal.data.production_lead.id !== userData.userId && !isSale) {
                navigate(`/deals/${getDeal.data.id}/missions/${getDeal.data.missions.find((mission) => mission.ressource.id === userData.userId)?.id}`);

                return
            }
            else {
                navigate(`/deals/${dealId}`);
            }
        }

    }

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <div className="px-20 pt-10 pb-32">
                    <TabNavigator
                        setDisplayedSection={setDisplayedSection}
                        displayedSection={displayedSection}
                        sections={[
                            {name:"Ma production", icon: productionIcon},
                            {name: "Mon bilan d’activité", icon: bilanIcon}
                        ]}
                    />
                    {
                        displayedSection === 'Ma production' && (
                            <Routes>
                                <Route path='/' element={
                                    <>
                                        <p style={{ fontFamily: 'Din condensed' }} className='text-4xl capitalize mt-7'>{month} {year}</p>

                                        <ActivityIndicator
                                            totalMissionItems={activity.missions}
                                            totalpilotedDealItems={activity.lead_deals}
                                            totalInternalProjectsItems={activity.internal_projects}
                                            totalSpecificationsStudyItems={activity.specification_study}
                                            totalProposalsInProgressItems={activity.proposal_in_progress}
                                            totalProposalsDeliveredItems={activity.waiting_response}
                                        />
                                        <ChargeIndicator
                                            workloads={workloads}
                                        />
                                    </>
                                }/>
                                <Route path='current-missions' element={
                                    <CurrentMissionsPage
                                        filterMissionList={filterMissionList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={missionsData}
                                        refreshData={refreshMissionsData}
                                        paginationMetadata={missionsPaginationMetadata}
                                        onPageNumberChange={fetchMissionsData}
                                        tableLoading={missionsTableLoading}
                                    />
                                }/>
                                <Route path='piloted-deals' element={
                                    <PilotedDealsPage
                                        tableType='piloted-deals'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={pilotedDealsData}
                                        userData={userData}
                                        paginationMetadata={pilotedDealsPaginationMetadata}
                                        onPageNumberChange={fetchPilotedDealsData}
                                        tableLoading={pilotedDealsTableLoading}
                                    />
                                }/>
                                <Route path='internal-projects' element={
                                    <InternalProjectsPage
                                        tableType='internal-projects'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={internalProjectsData}
                                        userData={userData}
                                        paginationMetadata={internalProjectsPaginationMetadata}
                                        onPageNumberChange={fetchInternalProjectsData}
                                        tableLoading={internalProjectsTableLoading}
                                    />
                                }/>
                                <Route path='specifications-study' element={
                                    <SpecificationsStudyPage
                                        tableType='specifications-study'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={specificationsStudyData}
                                        userData={userData}
                                        paginationMetadata={specificationsStudyPaginationMetadata}
                                        onPageNumberChange={fetchSpecificationsStudyData}
                                        tableLoading={specificationsStudyTableLoading}
                                    />
                                }/>
                                <Route path='proposals-in-progress' element={
                                    <ProposalsInProgressPage
                                        tableType='proposals-in-progress'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={proposalsInProgressData}
                                        userData={userData}
                                        paginationMetadata={proposalsInProgressPaginationMetadata}
                                        onPageNumberChange={fetchProposalsInProgressData}
                                        tableLoading={proposalsInProgressTableLoading}
                                    />
                                }/>
                                <Route path='proposals-delivered' element={
                                    <ProposalsDeliveredPage
                                        tableType='proposals-delivered'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={proposalsDeliveredData}
                                        userData={userData}
                                        paginationMetadata={proposalsDeliveredPaginationMetadata}
                                        onPageNumberChange={fetchProposalsDeliveredData}
                                        tableLoading={proposalsDeliveredTableLoading}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Mon bilan d’activité' && (
                            <>
                                <div className='my-6'>
                                    <Datepicker
                                        defaultValue={datePickerDate}
                                        onChange={handleDatePickerChange}
                                        type='range'
                                    />
                                </div>
                                <Commercial sales={sales}/>
                                <Production production={production}/>
                                <Pilotage production={production}/>
                                <MyTimes production={production}/>
                            </>
                        )
                    }
                </div>
            </PageLoader>
        </Layout>
    );
};

export default ConsultantDashboardPage;
