import Badge from '../Badge';

const TopBanner = ({title = null, status = null, config}) => {
    const statusBadge = (!status && typeof status !== 'number') ? null : config.statusBadge(status);

    return (
        <div className={`flex flex-row justify-between items-center h-32 ${config.blackgroundColor || 'bg-red-50'} px-16`}>
            {config.leftComponent && config.leftComponent()}

            <div className={`${config.centerInfo ? 'mx-auto' : ''} w-fit`}>
                {
                    title &&
                    <p className='mb-2 text-4xl font-bold text-white' style={{fontFamily: 'DIN Condensed'}}>
                        {config.titleLabel || ''}{title}
                    </p>
                }
                {
                    statusBadge &&
                    <Badge
                        color={statusBadge.backgroundColor}
                        borderColor={config.badgeBorderColor}
                        content={statusBadge.label}
                    />
                }
            </div>
                
            <div>
                {config.actionComponents && config.actionComponents(status)}
            </div>
        </div>
    );
};

export default TopBanner;
