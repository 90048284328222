import React from 'react';
import Loader from '../../loaders/Loader';

/**
 *
 * Props:
 * onClick = l'action onClick
 * type = primary, secondary, dark (default primary)
 * disabled = true || false (default false)
 * icon = une image importé (default false)
 * loading = true || false (default false)
 *
 */

const Button = ({
    onClick,
    type,
    content,
    disabled = false,
    icon = false,
    loading = false,
    height = '',
    width = ''
}) => {
    const checkType = () => {
        switch (type) {
            case 'primary':
                return {
                    background: 'bg-gradient-to-r from-[#DC3832] via-[#E36D38] to-[#F0A841]',
                    color: 'text-white',
                    border: 'border-white'
                };
            case 'secondary':
                return {
                    background: 'bg-white',
                    color: 'text-[#262626]',
                    border: 'border-[#D9D9D9]'
                };
            case 'dark':
                return {
                    background: 'bg-[#212121]',
                    color: 'text-white',
                    border: 'border-[#212121]'
                };
            case 'success':
                return {
                    background: 'bg-[#28a745]',
                    color: 'text-white',
                    border: 'border-[#28a745]'
                };
            case 'neutral':
                return {
                    background: 'bg-[#868e96]',
                    color: 'text-white',
                    border: 'border-[#868e96]'
                };
            case 'blue':
                return {
                    background: 'bg-gradient-to-br from-[#00ABE9] via-[#00ABAB] to-[#CED955]',
                    color: 'text-white',
                    border: 'border-white'
                };
            case 'white':
                return {
                    background: 'bg-white',
                    color: 'text-black',
                    border: 'border-grey-400'
                };
            case 'dark-red':
                return {
                    background: 'bg-[#c0392b]',
                    color: 'text-white',
                    border: 'border-[#c0392b]'
                };
            default:
                return {
                    background: 'bg-gradient-to-r from-[#DC3832] via-[#E36D38] to-[#F0A841]',
                    color: 'text-white',
                    border: 'border-white'
                };
        }
    };

    const style = checkType();

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        else {
            return null;
        }
    };

    return (
        <button
            onClick={handleClick}
            disabled={disabled}
            className={`
                flex flex-row justify-center mr-2 ml-2 items-center px-4 py-2 font-medium rounded-md
                border transition-all min-w-[100px] ${(!disabled && !loading) && 'hover:opacity-90'}
                ${disabled ? 'opacity-[40%]' : 'opacity-100'} ${type === 'dark' ? 'text-sm' : 'text-base'}
                ${loading ? 'pointer-events-none' : ''} ${style.background} ${style.color} ${style.border} ${height} ${width}
            `}
            style={type === 'dark' ? {boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15)'} : {boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'}}
        >
            { loading ?
                <Loader color="white"/> :
                <>
                    {icon ?
                        <img src={icon} alt="icon" className="w-5 mr-[10px]"/> :
                        null
                    }
                    {content}
                </>
            }
        </button>
    );
};

export default Button;
