import {DashboardActions} from "../../../actions";
import {standardFormat} from "../../../utils";
import {firstDayMonth, lastDayMonth, firstDayYear, lastDayYear} from "../../../utils";

export const getCommercialTabData = async (
    userData,
    notify,
    setLoading,
    setSalesActivity,
    commercialBalanceDatePicker,
    setSalesOverview
) => {
    // ↓ «Indicateurs d‘activité» data
    const startDate = standardFormat(firstDayMonth(new Date()));
    const endDate = standardFormat(lastDayMonth(new Date()));

    const salesActivityResult = await DashboardActions.CodevActions.getSalesActivity(userData, `?start_date=${startDate}&end_date=${endDate}`);

    if (salesActivityResult.success) {
        setSalesActivity(salesActivityResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des indicateurs d'activité commercial");
    }

    // ↓ «Bilan commercial» data
    const salesOverviewResult = await DashboardActions.CodevActions.getSalesOverview(
        userData,
        `?after=${standardFormat(commercialBalanceDatePicker.startDate)}&before=${standardFormat(commercialBalanceDatePicker.endDate)}`
    );

    if (salesOverviewResult.success) {
        setSalesOverview(salesOverviewResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des bilan commercial");
    }

    setLoading(false);
}

export const handleCommercialBalanceDatePickerChange = async (
    e,
    userData,
    notify,
    setLoading,
    setCommercialBalanceDatePicker,
    setSalesOverview
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayYear(new Date()),
        endDate: e.endDate ? e.endDate : lastDayYear(new Date())
    };

    setCommercialBalanceDatePicker(dates);

    const salesOverviewResult = await DashboardActions.CodevActions.getSalesOverview(
        userData,
        `?start_date=${standardFormat(dates.startDate)}&end_date=${standardFormat(dates.endDate)}`
    );

    if (salesOverviewResult.success) {
        setSalesOverview(salesOverviewResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des bilan commercial");
    }

    setLoading(false);
};
