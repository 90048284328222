import {ACTION_CODE} from './_actionCode';
import {TrainingProductOriginService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_TRAINING_PRODUCTORIGIN';

export async function deleteTrainingProductOrigin(userData: Record<string, string>, id: number) {
    try {
        LoggerService.logInfo('Action - deleteTrainingProductOrigin - Start');

        const deleteTrainingProductOriginResult = await TrainingProductOriginService.deleteTrainingProductOrigin(userData, id);

        LoggerService.logInfo('Action - deleteTrainingProductOrigin - End - Success');

        return ResultModel.newSuccessResult(deleteTrainingProductOriginResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteTrainingProductOrigin - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
