import {ContractDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_CONTRACT';

export async function deleteContract(
    userData: Record<string, string>,
    contractId: number
) {
    try {
        return await ContractDao.deleteContract(userData.userToken, contractId);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du contrat.',
            error: error
        });
    }
}
