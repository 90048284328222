import ActionsButton from '../../../components/ui/ActionsButton';
import {frenchFormat, isVacation} from '../../../utils';

export const productionsTablePopupConfig = (
    setNewProductionPopupVisible,
    setIsModifyActive,
    handleDeleteProduction,
    actionButtonLoading,
    actionButtonDisabled,
    setCalendarTablePopupVisible
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Affaires',
                    value: '.deal.deal_name',
                    width: 'w-[25%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start">
                                {item.deal.short_name}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Temps',
                    value: 'type',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <div
                                    className={`
                                    text-white rounded-full w-fit px-2 pb-[1px] h-[21px] text-xs flex items-center
                                    ${isVacation(item.deal.short_name) ? 'bg-[#646464]' : 'bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]'}`
                                    }
                                >
                                    {isVacation(item.deal.short_name) ? 'Congés' : 'Production'}
                                </div>
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'type',
                    value: null,
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.category ?? '-'}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Localisation',
                    value: null,
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.location ?? '-'}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours produits',
                    value: 'produced_days',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {`${item.produced_days} j`}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours Facturés',
                    value: 'billed_days',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {`${item.billed_days} j`}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours non facturés',
                    value: 'unbilled_days',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {`${item.produced_days - item.billed_days} j`}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={() => {
                                        setCalendarTablePopupVisible(false);
                                        setNewProductionPopupVisible(true);
                                        setIsModifyActive(item);
                                    }}
                                    disabled={actionButtonDisabled}
                                />
                                <div className="w-0 h-0 px-2"/>
                                <ActionsButton
                                    type="delete"
                                    isLoading={actionButtonLoading === item.id}
                                    onClick={
                                        () => {
                                            handleDeleteProduction(item);
                                        }
                                    }
                                    disabled={actionButtonDisabled}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer ce temps ?'
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
