import {ACTION_CODE} from './_actionCode';
import {ContractsStaffingService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'GET_ALL_CONTRACTS_STAFFING';

export async function getAllContractsStaffing(
    token: '',
    params: '',
    page: 1
) {
    try {
        LoggerService.logInfo('Action - getAllContractsStaffing - Start');

        const result = await ContractsStaffingService.getAllContractsStaffing(token, params, page);

        const {data, metadata} = result;

        LoggerService.logInfo('Action - getAllContractsStaffing - End - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllContractsStaffing - Error');
        
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation des contrats staffing',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
