import {RobotFilled, IdcardFilled} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import * as Utils from "../../../../utils";
import LineTitle from "../../../../components/ui/LineTitle";
import SimpleCard from "../../../../components/ui/SimpleCard";
import Badge from "../../../../components/ui/Badge";
import TabCard from '../../../../components/ui/TabCard/TabCard';
import {consultantTabConfig} from '../../configs/ressourceManagementTab/dasTeam/consultantTabConfig';
import {etpTabConfig} from '../../configs/ressourceManagementTab/dasTeam/etpTabConfig';
import {ressourceNumberTabConfig} from '../../configs/ressourceManagementTab/dasTeam/ressourceNumberTabConfig';
import {loadProjectionsTabConfig} from '../../configs/ressourceManagementTab/dasTeam/loadProjectionsTabConfig';
import { aggregateConsultantsTotal, aggregateConsultants, aggregateETP, aggregateEtpTotal } from '../../functions/agggregateRessources';

const DasTeam = ({ressources}) => {
    const IdCardIcon = <IdcardFilled style={{ fontSize: '30px', color:'white' }}/>;
    const RobotIcon = <RobotFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className="my-10">
            <LineTitle title="Équipe du DAS" textSize='text-4xl'/>
            <p style={{ fontFamily: 'Din condensed' }} className='mt-10 text-2xl capitalize'>{Utils.getCurrentMonthAndYear()}</p>

            <div className='flex justify-between mt-5'>
                <SimpleCard
                    width='w-[48%]'
                    title={aggregateConsultantsTotal(ressources.current, "consultants")}
                    subTitle="Consultants"
                    iconComponent={IdCardIcon}
                    topComponent={<TopComponent data = {ressources.current} type="consultant"/>}
                    bottomComponent={<BottomComponent ressources={ressources.current} type='consultant'/>}
                />

                <SimpleCard
                    width='w-[48%]'
                    title={aggregateEtpTotal(ressources.current)}
                    subTitle="ETP"
                    iconComponent={RobotIcon}
                    topComponent={<TopComponent data = {ressources.current} type="etp"/>}
                    bottomComponent={<BottomComponent ressources={ressources} type='etp'/>}
                />
            </div>

            <div className='mt-6'>
                <TabCard config={ressourceNumberTabConfig(ressources)}/>
            </div>

            <div className='mt-6'>
                <TabCard config={loadProjectionsTabConfig(ressources.load)}/>
            </div>
        </div>
    )
}

export default DasTeam;

const TopComponent = ({data, type}) => {
    
    const allDasS = useSelector((state) => state.DasReducer.das);

    let dasS = [];

    for (const key in data) {
        let currentDas = allDasS.filter((el) => el.name === key);

        dasS.push({
            das: key,
            value: type === "etp" ? aggregateETP(data, key) : aggregateConsultants(data, key),
            dasObject: currentDas = currentDas.length > 0 ? currentDas[0] : null
        })
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge
                                    content={el.dasObject ? [el.dasObject.manager] : '-'}
                                    color={el.dasObject ? null : '#bdc3c7'}
                                    type='round'
                                />
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

const BottomComponent = ({ type, ressources }) => {
    const config = type === 'consultant' ? consultantTabConfig(ressources) : etpTabConfig(ressources);

    return (
        <div className='bg-white border-t border-gray-200 rounded-b-md'>
            <TabCard config={config}/>
        </div>
    )
}