import thunk from 'redux-thunk';
import {configureStore, combineReducers} from '@reduxjs/toolkit';
import UserReducer from './UserReducer';
import DasReducer from './DasReducer';
import GlobalsReducer from './GlobalsReducer';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistUserConfig = {
    key: 'user',
    storage,
    whitelist: ['userProfileData']
};

const persistDasConfig = {
    key: 'das',
    storage,
    whitelist: ['das']
};

const persistGlobalsConfig = {
    key: 'globals',
    storage,
    whitelist: ['vat']
};

const persistedUserReducer = persistReducer(persistUserConfig, UserReducer);
const persistedDasReducer = persistReducer(persistDasConfig, DasReducer);
const persistedGlobalsReducer = persistReducer(persistGlobalsConfig, GlobalsReducer);

const combineAllReducers = combineReducers({UserReducer: persistedUserReducer, DasReducer: persistedDasReducer, GlobalsReducer: persistedGlobalsReducer});

const store = configureStore({
    reducer: combineAllReducers,
    middleware: [thunk]
});
export const persistor = persistStore(store);

export default store;

