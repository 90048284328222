import DonutsChart from "../../../components/ui/DonutsChart/DonutsChart";
import {paymentTimesAnalysisLeftChartConfig} from "./paymentTimesAnalysisLeftChartConfig";
import {paymentTimesAnalysisRightChartConfig} from "./paymentTimesAnalysisRightChartConfig.js";

export const paymentTimesAnalysisChartTabConfig = (data) => {

    const getPercent = (sum, value) => {
        if (!sum || !value) {
            return 0;
        }
        else {
            return Math.round((value / sum) * 100);
        }
    }

    const getSumByDas = (das, type) => {
        return(
            data[das].details.m1[type] +
            data[das].details.m2[type] +
            data[das].details.m3[type] +
            data[das].details.q1[type] +
            data[das].details.s1[type]
        );
    }

    const getDasSum = (key, type) => {
        let sum = 0;

        for (const k in data) {
            sum += data[k].details[key][type];
        }

        return sum.toFixed();
    }

    const getTotalSum = (type) => {
        let sum = 0;

        for (const key in data) {
            sum += data[key].details.m1[type];
            sum += data[key].details.m2[type];
            sum += data[key].details.m3[type];
            sum += data[key].details.q1[type];
            sum += data[key].details.s1[type];
        }

        return sum;
    }

    const getDataSum = (type) => {
        return (
            [
                {
                    type: '1 mois et moins',
                    value: getPercent(getTotalSum(type), getDasSum('m1', type))
                },
                {
                    type: '1 à 2 mois',
                    value: getPercent(getTotalSum(type), getDasSum('m2', type))
                },
                {
                    type: '2 à 3 mois',
                    value: getPercent(getTotalSum(type), getDasSum('m3', type))
                },
                {
                    type: '3 à 6 mois',
                    value: getPercent(getTotalSum(type), getDasSum('q1', type))
                },
                {
                    type: '+ de 6 mois',
                    value: getPercent(getTotalSum(type), getDasSum('s1', type))
                }
            ]
        );
    }
    
    const getData = (das, type) => {
        return (
            [
                {
                    type: '1 mois et moins',
                    value: getPercent(getSumByDas(das, type), data[das]['details']['m1'][type])
                },
                {
                    type: '1 à 2 mois',
                    value: getPercent(getSumByDas(das, type), data[das]['details']['m2'][type])
                },
                {
                    type: '2 à 3 mois',
                    value: getPercent(getSumByDas(das, type), data[das]['details']['m3'][type])
                },
                {
                    type: '3 à 6 mois',
                    value: getPercent(getSumByDas(das, type), data[das]['details']['q1'][type])
                },
                {
                    type: '+ de 6 mois',
                    value: getPercent(getSumByDas(das, type), data[das]['details']['s1'][type])
                }
            ]
        );
    }

    const getCountSum = () => {
        let sum = 0;

        for (const key in data) {
            sum += data[key].count;
        }

        return sum;
    }

    const getValueSum = () => {
        let firstValue = 0;
        let secondValue = getCountSum();

        for(const key in data) {
            firstValue += (data[key].value * data[key].count);
        }

        return (firstValue / secondValue).toFixed();
    }

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <div className="flex flex-row items-center">
            <div className="w-1/2">
                <DonutsChart
                    label={'%'}
                    data={getDataSum('invoices')}
                    config={paymentTimesAnalysisLeftChartConfig}
                    count={getCountSum()}
                    value={getValueSum()}
                />
            </div>
            <div className="w-1/2">
                <DonutsChart
                    label={'%'}
                    data={getDataSum('customers')}
                    config={paymentTimesAnalysisRightChartConfig}
                />
            </div>
        </div>
    }];

    for(const key in data) {
            
        const item = {
            id: key,
            label: key,
            component: <div className="flex flex-row items-center">
                <div className="w-1/2">
                    <DonutsChart
                        label={'%'}
                        data={getData(key, 'invoices')}
                        config={paymentTimesAnalysisLeftChartConfig}
                        count={data[key].count}
                        value={data[key].value}
                    />
                </div>
                <div className="w-1/2">
                    <DonutsChart
                        label={'%'}
                        data={getData(key, 'customers')}
                        config={paymentTimesAnalysisRightChartConfig}
                    />
                </div>
            </div>
        }

        tabs.push(item);
    }
    
    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
