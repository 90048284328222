import {ACTION_CODE} from './_actionCode';
import {SeniorityService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_SENIORITY_CATEGORY';

export async function updateSeniority(
    userData: Record<string, any>,
    seniorityId: number,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateSeniority - Start');

        const updateSeniorityResult =
            await SeniorityService.updateSeniority(userData, seniorityId, payloadUpdate);

        LoggerService.logInfo('Action - updateSeniority - End - Success');

        return ResultModel.newSuccessResult(updateSeniorityResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateSeniority - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
