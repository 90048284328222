import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

import { DealActions } from '../../../../actions';
import PopUp from '../../../../components/ui/Popup'
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard'
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput'
import Button from '../../../../components/ui/Button/Button';
import DropdownInput from '../../../../components/ui/Inputs/DropdownInput';
import { DealLossMotivesActions, MissionActions } from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';

const RefusalMotiff = ({
    userData,
    view,
    setView,
    refreshDealData,
    refreshMissionsData,
    dealData,
    blueStyle = false
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [motifs, setMotifs] = useState([]);

    // -- react hook form --
    const defaultValues = {
        deal_loss_motif: '',
        notes: ''
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        setLoading(true);
        (async () => {

            const result = await DealLossMotivesActions.getAllDealLossMotives(userData);

            if (result.success) {
                setMotifs(result.data.results);
            }
            else {
                notify('Erreur de récuperation des motifs de réfus');
            }
            setLoading(false);
        })();
    }, [])

    useEffect(() => {
        if (!view) {
            reset(defaultValues);
        }
    }, [view, refreshDealData]);

    const onSubmit = async (formData) => {
        setDisabled(true);
        setLoading(true);

        const dataUpdatedWithNotes = {
            loss_motive: formData.deal_loss_motif.name,
            notes: formData.notes,
            status: 900
        }

        const result = await DealActions.updateDeal(userData, dataUpdatedWithNotes, dealData.id);

        if (!result.success) {
            notify(  'Erreur du refus de l\'affaire');
        }
        else {
            const updatedMissions = dealData.missions.map(async mission => {
                const updatedMission = await MissionActions.updateMissionById(userData, {
                    id: mission.id,
                    status: 2
                });

                return updatedMission;
            });
            const resolveUpdatedMissions = await Promise.all(updatedMissions);

            if (resolveUpdatedMissions.some(mission => !mission.success)) {
                notify("Une erreur est survenue lors de la mise à jour des missions");
            }
            await refreshMissionsData();
            await refreshDealData();
            setView(false);
            notify( 'L\'affaire a été réfusée');
            reset(defaultValues);
        }

        setLoading(false);
        setDisabled(false);
    }

    const handleCancel = () => {
        reset(defaultValues);
        setView(false);
    }

    return (
        <PopUp view={view} setView={setView} width="1098px">
            <ContainerCard title='Motif du refus ' coloredButtonDisplayed={false}>
                <div className="mb-4">
                    <Controller
                        name="deal_loss_motif"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir un motif'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DropdownInput
                                color={blueStyle ? 'blue' : 'primary'}
                                label='Motif'
                                items={motifs}
                                onChange={onChange}
                                maxLength={100}
                                value={value}
                                error={error}
                                required={true}
                                placeholder="Séléctionnez un motif"
                                type="text"
                            />
                        )}
                    />
                </div>
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextAreaInput
                            color={blueStyle ? 'blue' : 'primary'}
                            label='notes'
                            onChange={onChange}
                            maxLength={100}
                            value={value}
                            error={error}
                            required={false}
                            placeholder="Tapez votre texte"
                            type="text"
                        />
                    )}
                />
            </ContainerCard>
            <div className='flex flex-row mx-auto mt-8 w-fit'>
                <Button onClick={handleCancel} type='white' content='Annuler'/>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    content={"Valider"}
                    loading={loading}
                    disabled={!isValid || disabled}
                    type={blueStyle ? 'blue' : 'primary'}
                />

            </div>
        </PopUp>
    )
}
export default RefusalMotiff
