import ContributionToMargin from "./ContributionToMargin";
import GrossMarginProduced from "./GrossMarginProduced";
import ProductionByEtp from "./ProductionByEtp";

const ResultIndicatorTab = ({
    results,
    datePickerDate,
    handleDatePickerChange
}) => {
    return (
        <div>
            <GrossMarginProduced
                results={results}
                datePickerDate={datePickerDate}
                handleDatePickerChange={handleDatePickerChange}
            />
            <ContributionToMargin results={results}/>
            <ProductionByEtp results={results}/>
        </div>
    )
}

export default ResultIndicatorTab;