import ClosedDealsCustomer from './ClosedDealsCustomer';

const SatisfactionSurvey = ({
    data,
    datePickerDate,
    setDatePickerDate,
    refreshData,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    return (
        <div>
            <ClosedDealsCustomer
                data={data}
                datePickerDate={datePickerDate}
                setDatePickerDate={setDatePickerDate}
                refreshData={refreshData}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                tableLoading={tableLoading}
            />
        </div>
    )
}

export default SatisfactionSurvey;
