import {Link} from 'react-router-dom';
import {FilePdfFilled, HourglassFilled, FolderOpenFilled, FolderFilled, ScheduleFilled} from '@ant-design/icons';

import Title from '../../../components/ui/Title';
import SimpleCard from '../../../components/ui/SimpleCard/SimpleCard';

const InvoiceIndicator = ({
    totalInvoicesToIssueItems,
    totalInvoicesAwaitingPaymentItems,
    totalEstimatedInvoicesItems,
    totalForecastInvoicesDueItems,
    totalInvoicesToValidateItems,
    currentDate
}) => {

    const invoicesToIssueIcon = <FilePdfFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const invoicesAwaitingPaymentIcon = <HourglassFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const estimatedInvoicesIcon = <FolderOpenFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const forecastInvoicesDueIcon = <FolderFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const invoicesToValidate = <ScheduleFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;

    return (
        <div className='my-10'>
            <Title title={currentDate} textSize='text-3xl'/>
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <Link style={{width: '49%'}} to='invoices-to-issue'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={invoicesToIssueIcon}
                        title={totalInvoicesToIssueItems}
                        subTitle="Factures à émettre"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '49%'}} to='invoices-awaiting-payment'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={invoicesAwaitingPaymentIcon}
                        title={totalInvoicesAwaitingPaymentItems}
                        subTitle="Factures en attente de paiement"
                        hoverEffect={true}
                    />
                </Link>
            </div>
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <Link style={{width: '49%'}} to='estimated-invoices'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={estimatedInvoicesIcon}
                        title={totalEstimatedInvoicesItems}
                        subTitle="Factures prévisionnelles"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '49%'}} to='forecast-invoices-due'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={forecastInvoicesDueIcon}
                        title={totalForecastInvoicesDueItems}
                        subTitle="Factures prévisionnelles échues"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '49%'}} to='invoices-to-validate'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={invoicesToValidate}
                        title={totalInvoicesToValidateItems}
                        subTitle="Factures à valider"
                        hoverEffect={true}
                    />
                </Link>
            </div>
        </div>
    )
}

export default InvoiceIndicator;
