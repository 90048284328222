import * as z from 'zod';

export const LocationSchema = z.object({
    "id": z.number(),
    "is_active": z.boolean(),
    "name": z.string()
});

export const LocationPartialSchema = LocationSchema.partial();
export type LocationPartial = z.infer<typeof LocationPartialSchema>;
export type Location = z.infer<typeof LocationSchema>;

export function newLocation(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...LocationSchema.parse({...data})
    };
}

export function parseLocation(data: any, partial = false) {
    if (!partial) {
        return {
            ...LocationSchema.parse(data)
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...LocationPartialSchema.parse(data)
        };
    }
}
