import React, {useState} from 'react';
import Empty from 'antd/lib/empty';

import ActionsButton from '../../../../components/ui/ActionsButton/ActionsButton';
import { MissionActions } from '../../../../actions';
import ContainerCard from '../../../../components/ui/ContainerCard';
import {notify} from '../../../../components/ui/Toast/Toast';

const MissionNotesCard = ({data, handleButtonClick, refreshMissionData, userData, cannotBeModified}) => {
    const [deleteNoteLoading, setDeleteNoteLoading] = useState(false)

    const handleDeleteNote = async () => {
        setDeleteNoteLoading(true);

        const dataUpdatedWithNotes = {
            ...data,
            notes: ''
        }

        const result = await MissionActions.updateMissionById(userData ,dataUpdatedWithNotes);

        if (!result.success) {
            notify('Erreur lors de la suppression de la note');
        }
        else {
            notify('La note à été supprimée');
            await refreshMissionData();
        }

        setDeleteNoteLoading(false);
    }

    return (
        <ContainerCard
            title='Notes'
            buttonContent={data.notes ? 'Modifier la note' : 'Ajouter une note'}
            onButtonClick={handleButtonClick}
            coloredButtonDisplayed={data?.status < 2 && !cannotBeModified}
            width='w-1/3'
            height='h-[290px]'
        >
            {
                data.notes ?
                    <div>
                        {data.notes}
                    </div> :
                    <div className='flex items-center justify-center w-full h-full'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                    </div>
            }
            {
                data.notes && !cannotBeModified ?
                    <div className="absolute bottom-3 right-3 ">
                        <ActionsButton
                            onClick={handleDeleteNote}
                            type='delete'
                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette note ?'
                            isLoading={deleteNoteLoading}
                        />
                    </div>
                    : null
            }
        </ContainerCard>
    )
}

export default MissionNotesCard
