import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {fetchSelectData} from './../../../../utils';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import {TrainingActions, TrainingSpecialitiesActions, TrainingStudentTypesActions, TrainingObjectiveActions,TrainingProductOriginActions} from './../../../../actions';
import ContainerCard from '../../../../components/ui/ContainerCard';
import CheckboxInput from "../../../../components/ui/Inputs/CheckboxInput/CheckboxInput";
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from './../../../../utils';

const DealTrainingPopup = ({
    userData,
    setView,
    trainingData,
    refreshData
}) => {
    const [trainingSpecialities, setTrainingSpecialities] = useState([]);
    const [trainingStudentTypes, setTrainingStudentTypes] = useState([]);
    const [trainingObjective, setTrainingObjective] = useState([]);
    const [trainingProductOrigin, setTrainingProductOrigin] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    
    const initialData = {
        general_objective: trainingData?.general_objective ?? '',
        is_contractor: trainingData?.is_contractor ?? '',
        product_origin: trainingData?.product_origin ?? '',
        specialty: trainingData?.specialty ?? '',
        student_type: trainingData?.student_type ?? '',
        uses_contractor: trainingData?.uses_contractor ?? ''
    };
    
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: initialData});
    
    // -- functions that get data for Select Inputs --
    const handleGetAllTrainingsSpecialities = async (search, page = 1) => {
        await fetchSelectData({action: TrainingSpecialitiesActions.getAllTrainingSpecialities, search:`&search=${search}`, page: page, setter: setTrainingSpecialities, userData});
    };

    const handleGetAllTrainingsStudentTypes = async (search, page = 1) => {
        await fetchSelectData({action: TrainingStudentTypesActions.getAllTrainingStudentTypes, search:`&search=${search}`, page: page, setter: setTrainingStudentTypes, userData});
    };

    const handleGetAllTrainingsObjective = async (search, page = 1) => {
        await fetchSelectData({action: TrainingObjectiveActions.getAllTrainingObjective, search:`&search=${search}`, page: page, setter: setTrainingObjective, userData});
    };

    const handleGetAllTrainingsProductOrigin = async (search, page = 1) => {
        await fetchSelectData({action: TrainingProductOriginActions.getAllTrainingProductOrigin, search:`&search=${search}`, page: page, setter: setTrainingProductOrigin, userData});
    };

    useEffect(() => {
        (async () => {
            await handleGetAllTrainingsSpecialities('', 1);
        })();

        (async () => {
            await handleGetAllTrainingsStudentTypes('', 1);
        })();

        (async () => {
            await handleGetAllTrainingsObjective('', 1);
        })();

        (async () => {
            await handleGetAllTrainingsProductOrigin('', 1);
        })();
    }, []);
    
    useEffect(() => {
        if (trainingData) {
            reset({
                general_objective: {name: trainingData?.general_objective},
                is_contractor: trainingData?.is_contractor,
                product_origin: {name: trainingData?.product_origin},
                specialty: {name: trainingData?.specialty},
                student_type: {name :trainingData?.student_type},
                uses_contractor: trainingData?.uses_contractor
            });
        }
    }, [trainingData]);
    
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);
        
        const payload = {
            general_objective: data.general_objective.name,
            is_contractor: data.is_contractor,
            product_origin: data.product_origin.name,
            specialty: data.specialty.name,
            student_type: data.student_type.name,
            uses_contractor: data.uses_contractor
        }
        
        if (isValid) {
            const result = await TrainingActions.updateTraining(userData, payload, trainingData?.id);
            
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify(`La signalétique de l'affaire de formation à été modifier avec succès.`);
                setView(false);
            }
        }
        
        setDisabled(false);
        setLoading(false);
    }
    
    return (
        <ContainerCard
            title='Signalétique d’une affaire de formation'
            coloredButtonDisplayed={false}
            overflow=""
        >
            <div className="flex justify-between mb-6">
                <Controller
                    name="product_origin"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Veuillez sélectionner une origine des produits'
                        }
                    }}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label='Origine des produits'
                            placeholder='Origine des produits'
                            inputRef={ref}
                            value={value}
                            error={error}
                            options={trainingProductOrigin.options}
                            hasNextPage={trainingProductOrigin.hasNextPage}
                            fetchFunction={handleGetAllTrainingsProductOrigin}
                            onChange={onChange}
                            isSearchable={true}
                            loading={!trainingProductOrigin}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            blueStyle={true}
                            labelWidth='w-44'
                        />
                    )}
                />
                    
                <Controller
                    name="student_type"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Veuillez sélectionner un types de stagiaires'
                        }
                    }}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label='Types de stagiaires'
                            placeholder='Types de stagiaires'
                            inputRef={ref}
                            value={value}
                            error={error}
                            options={trainingStudentTypes.options}
                            hasNextPage={trainingStudentTypes.hasNextPage}
                            fetchFunction={handleGetAllTrainingsStudentTypes}
                            onChange={onChange}
                            loading={!trainingStudentTypes}
                            isSearchable={true}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            blueStyle={true}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className="flex items-center justify-between mb-6">
                <Controller
                    name="uses_contractor"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <div className="w-[424px]">
                            <CheckboxInput
                                label='Sous-traitance d’un autre organisme'
                                labelWidth='w-44'
                                reverse={true}
                                onChange={onChange}
                                value={value}
                                color='blue'
                            />
                        </div>
                    )}
                />
                <Controller
                    name="is_contractor"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <div className="w-[424px]">
                            <CheckboxInput
                                label='Sous-traitance à un autre organisme'
                                labelWidth='w-44'
                                reverse={true}
                                onChange={onChange}
                                value={value}
                                color='blue'
                            />
                        </div>
                    )}
                />
            </div>
            <div className="flex justify-between mb-10">
                <Controller
                    name="general_objective"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Veuillez sélectionner un objectif général de la formaiton'
                        }
                    }}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label='Objectif général de la formation'
                            placeholder='Objectif général de la formation'
                            inputRef={ref}
                            value={value}
                            error={error}
                            options={trainingObjective.options}
                            hasNextPage={trainingObjective.hasNextPage}
                            fetchFunction={handleGetAllTrainingsObjective}
                            loading={!trainingObjective}
                            onChange={onChange}
                            isSearchable={true}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            blueStyle={true}
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="specialty"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Veuillez sélectionner une spécialité de formation'
                        }
                    }}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label='Spécialité de formation'
                            placeholder='Spécialité de formation'
                            inputRef={ref}
                            value={value}
                            error={error}
                            options={trainingSpecialities.options}
                            hasNextPage={trainingSpecialities.hasNextPage}
                            fetchFunction={handleGetAllTrainingsSpecialities}
                            loading={!trainingSpecialities}
                            onChange={onChange}
                            isSearchable={true}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            blueStyle={true}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'blue'}
                        content='Sauvegarder'
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default DealTrainingPopup;
