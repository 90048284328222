import ContainerCard from '../../../../../components/ui/ContainerCard/ContainerCard';

const TrainingCase = ({training, setShowDealTrainingPopup}) => {
    return (
        <ContainerCard
            title='Signalétique d’une affaire de formation'
            editButtonDisplayed={true}
            coloredButtonDisplayed={false}
            blueStyle={true}
            margin='mb-6'
            onEditButtonClick={() => setShowDealTrainingPopup(true)}
        >
            <div className='flex items-center justify-between mb-3'>
                <div className='flex items-start justify-start w-1/2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Nature de la formation</div>
                    <div className='text-[#212121] text-base'>{training?.product_origin ?? '-'}</div>
                </div>
                <div className='flex items-start justify-end w-1/2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Types de stagiaires</div>
                    <div className='text-[#212121] text-base'>{training?.student_type ?? '-'}</div>
                </div>
            </div>
            <div className='flex items-center justify-between my-3 min-w-1/2'>
                <div className='flex items-start justify-start w-1/2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Sous-traitance d’un autre organisme</div>
                    <div className='text-[#212121] text-base'>{training?.uses_contractor ? 'Oui' : 'Non'}</div>
                </div>
                <div className='flex items-start justify-end w-1/2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Sous-traitance à un autre organisme</div>
                    <div className='text-[#212121] text-base'>{training?.is_contractor ? 'Oui' : 'Non'}</div>
                </div>
            </div>
            <div className='flex items-center justify-between mt-3'>
                <div className='flex items-start justify-start w-1/2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Objectif général de la formation</div>
                    <div className='text-[#212121] text-base'>{training?.general_objective ?? '-'}</div>
                </div>
                <div className='flex items-start justify-end w-1/2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Spécialité de formation</div>
                    <div className='text-[#212121] text-base'>{training?.specialty ?? '-'}</div>
                </div>
            </div>
        </ContainerCard>
    );
};

export default TrainingCase;