import {ProjectsDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'CREATE_PROJECT';

export async function createProject(token: string, payload: Record<string, unknown>) {
    try {
        const result = await ProjectsDao.createProject(token, payload);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création du projet',
            error: error
        });
    }
}
