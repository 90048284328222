import {DeleteOutlined} from '@ant-design/icons';

import ActionsButton from '../../../components/ui/ActionsButton';

export const contractorsTableConfig = (
    invoiceStatus,
    buttonsDisabled,
    deleteContractorLoading,
    setIsContractorPopupOpen,
    handleDeleteContractor,
    setInvoiceContractorToBeUpdated,
    handleInvoicePreview
) => {
    return {
        dropdown: false,
        dropdownLeftPadding: false,
        greyBackground: false,
        subline: false,
        columnConfig: [
            {
                label: 'Sous-traitants',
                value: 'contractor.name',
                width: 'w-[27%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-start">
                            {item.contractor.name}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Montant',
                value: 'amount',
                width: 'w-[27%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+item.amount} €
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Notes',
                value: null,
                width: 'w-[27%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item.notes ? item.notes : '-'}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Actions',
                value: null,
                width: 'w-[25%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {
                                (invoiceStatus < 300) ?
                                    <>
                                        {item.file && (
                                            <ActionsButton
                                                type="overview"
                                                onClick={() => handleInvoicePreview(item)}
                                                minWidth={'min-w-[30px]'}
                                            />
                                        )}

                                        <ActionsButton
                                            type="edit"
                                            isLoading={false}
                                            minWidth={'min-w-[30px]'}
                                            onClick={() => {
                                                setInvoiceContractorToBeUpdated(item),
                                                setIsContractorPopupOpen(true)
                                            }}
                                            disabled={buttonsDisabled}
                                        />
                                        
                                        <ActionsButton
                                            type="delete"
                                            minWidth={'min-w-[30px]'}
                                            isLoading={deleteContractorLoading === item.id}
                                            onClick={() => handleDeleteContractor(item.id)}
                                            disabled={buttonsDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer ce sous-traitant ?'
                                        />
                                    </> :
                                    <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                            }
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            }
        ]
    };
};
