import {ACTION_CODE} from './_actionCode';
import {ContractorService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_CONTRACTOR';

export async function createContractor(
    userData: Record<string, string>,
    newContractor: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createContractor - Start');

        const createContractorResult = await ContractorService.createContractor(userData, newContractor);

        LoggerService.logInfo('Action - createContractor - End - Success');

        return ResultModel.newSuccessResult(createContractorResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createContractor - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
