import axios from 'axios';

import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import {URL} from '../root';

const FUNCTION_CODE = 'DELETE_PROJECT';

export async function deleteProject(token: string, projectId: number | string) {
    try {
        const response = await axios.delete(
            `${URL}/projects/${projectId}/`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${token}`
                }
            },
        );

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du projet',
            error: error
        });
    }
}
