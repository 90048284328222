export const assimilateDuplicates = (data) => {
    let assimilatedData = [];
    for(let i = 0; i < data.length; i++) {
        let checking = false;

        assimilatedData.map((item) => {
            if (item.name === data[i].name) {
                checking = true;
            }
        })

        if (!checking) {
            assimilatedData.push({name: data[i].name});
        }
    }
    assimilatedData = assimilatedData.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    return assimilatedData;
}

export const getFormula = (vehicleTypes, data) => {
    const vehicleTypesFiltered = vehicleTypes.filter((vehicle) => {
        return (
            vehicle.name === data.name &&
            data.distance > vehicle.min_distance &&
            data.distance < (vehicle.max_distance === null ? data.distance + 1 : vehicle.max_distance)
        );
    });

    return vehicleTypesFiltered.length < 1 ? null : vehicleTypesFiltered[0].formula;
}

export const getInputDataFromNotes = (expenseToBeUpdated, vehicleNames) => {
    let expenseVehicle = expenseToBeUpdated.notes.split(' · ')[1];
    expenseVehicle = vehicleNames.filter((vehicle) => vehicle.name === expenseVehicle)[0];
    const expenseDistance = expenseToBeUpdated.notes.split(' · ')[0].split(' ')[0];
    const expenseNotes = expenseToBeUpdated.notes.split(' · ')[2];

    return ({
        expenseVehicle: expenseVehicle,
        expenseDistance: expenseDistance,
        expenseNotes: expenseNotes
    });
}
