const SimpleTab = ({
    sections,
    displayedSection,
    setDisplayedSection,
    backgroundColor = 'background-gradient',
    onClick = null
}) => {
    const handleClick = (sectionName) => {
        setDisplayedSection(sectionName);

        if (onClick) {
            onClick();
        }
    }

    return (
        <div className={`flex flex-row border rounded-sm w-fit`}>
            {sections.map((section, index) => {
                return (
                    <div className={`px-3 py-1 w-fit  ${displayedSection === section.name ? backgroundColor : 'cursor-pointer'}`} key={index}>
                        <div
                            onClick={() => handleClick(section.name)}
                            className={`
                            ${displayedSection === section.name ? 'text-white' : 'text-black'}
                        `}
                        >
                            {section.name}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SimpleTab;
