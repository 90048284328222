import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Tooltip from 'antd/lib/tooltip';

import {ContractorActions} from '../../../actions'
import arrowIcon from '../../../assets/icons/page/WhiteArrowLeft.png';
import deleteIcon from '../../../assets/icons/actionsbutton/white-delete.png';
import Badge from '../../../components/ui/Badge';
import Loader from '../../../components/loaders/Loader';
import {notify} from '../../../components/ui/Toast/Toast';
import ConfirmationPopup from '../../../components/ui/ConfirmationPopup';

const ContractorDetailsBanner = ({data, userData}) => {
    const navigate = useNavigate();

    const [deleteContractorLoading, setDeleteContractorLoading] = useState(false);
    const [deleteContractorConfirmationView, setDeleteContractorConfirmationView] = useState(false);

    const handleDeleteContractor = async () => {
        setDeleteContractorLoading(true);

        const result = await ContractorActions.deleteContractor(userData, data.id);

        if (result.success) {
            navigate(`/my-organization/contractors/`, {state: 'contractor_deleted'});
        }
        else {
            notify('Le sous/co-traitant n\'a pas pu être supprimée');
        }
        setDeleteContractorLoading(false);
    };

    return (
        <>
            <div className='h-28 w-full bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] px-20 py-5 fixed z-50 flex flex-col justify-start items-center shadow-[0_2px_8px_rgba(0,0,0,0.25)]'>
                <div className='flex flex-row items-center justify-between w-full'>
                    <button onClick={() => navigate(-1)} className='p-4'>
                        <img src={arrowIcon} alt='return' className='w-5'/>
                    </button>

                    <div className='flex flex-col justify-items-center'>

                        <div className='flex flex-row items-end'>

                            <div className='text-4xl font-bold text-white' style={{fontFamily: 'DIN Condensed'}}>
                                {data?.name}
                            </div>
                        </div>

                        <div className='text-base text-white' style={{fontFamily: 'DIN Condensed'}}>
                            {data?.location}
                        </div>

                    </div>

                    {<Tooltip title='Supprimer sous/co-traitants'>
                        <button onClick={() => setDeleteContractorConfirmationView(true)} disabled={deleteContractorLoading} className='p-4'>
                            {
                                deleteContractorLoading ?
                                    <Loader size={20} color='#212121'/> :
                                    <img src={deleteIcon} alt='delete' className='w-5'/>
                            }
                        </button>
                    </Tooltip>}
                </div>
                <Badge
                    content={''}
                    color={'#212121'}
                    borderColor='#212121'
                />
            </div>
            <ConfirmationPopup
                view={deleteContractorConfirmationView}
                setView={setDeleteContractorConfirmationView}
                title='Souhaitez-vous supprimer ce sous/co-traitant ?'
                onConfirm={handleDeleteContractor}
            />
        </>
    )
}

export default ContractorDetailsBanner
