import {BankFilled, FundFilled, EuroCircleFilled, CalculatorFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Datepicker from "../../../../components/ui/Datepicker";
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import {transformThousandNumbers} from '../../../../utils';

const ExternalRessource = ({
    data,
    handleDatePickerChange,
    datePicker,
    dasKey
}) => {

    const BankIcon = <BankFilled style={{ fontSize: '30px', color:'white' }}/>;
    const FundIcon = <FundFilled style={{ fontSize: '30px', color:'white' }}/>;
    const EuroCircleIcon = <EuroCircleFilled style={{ fontSize: '30px', color:'white' }}/>;
    const CalculatorIcon = <CalculatorFilled style={{ fontSize: '30px', color:'white' }}/>;

    const coContractorsCount = data[dasKey] ? data[dasKey]["Cotraitants"].count : 0;
    const subcontractorsCount = data[dasKey] ? data[dasKey]["Sous-traitants"].count : 0;

    const coContractorsDays = data[dasKey] ? data[dasKey]["Cotraitants"].days : 0;
    const subcontractorsDays = data[dasKey] ? data[dasKey]["Sous-traitants"].days : 0;

    const coContractorsAmount = data[dasKey] ? data[dasKey]["Cotraitants"].amount : 0;
    const subcontractorsAmount = data[dasKey] ? data[dasKey]["Sous-traitants"].amount : 0;

    const coContractorsCommission = data[dasKey] ? data[dasKey]["Cotraitants"].commission : 0;
    const subcontractorsCommission = data[dasKey] ? data[dasKey]["Sous-traitants"].commission : 0;

    return (
        <div className='mt-14'>
            <LineTitle title="Ressources externes" textSize='text-4xl'/>

            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePicker}
                    onChange={handleDatePickerChange}
                    type='range'
                />

                <div className="flex flex-wrap justify-between mt-6 gap-y-7">
                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={coContractorsCount + subcontractorsCount}
                        borderSize='border'
                        subTitle='Structures mobilisées'
                        iconComponent={BankIcon}
                        topComponent={<TopComponent jointContractor={coContractorsCount} Subcontractors={subcontractorsCount}/>}
                    />

                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={coContractorsDays + subcontractorsDays}
                        borderSize='border'
                        subTitle='Jours de production confiés'
                        iconComponent={FundIcon}
                        topComponent={<TopComponent jointContractor={coContractorsDays} Subcontractors={subcontractorsDays}/>}
                    />

                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={transformThousandNumbers(coContractorsAmount + subcontractorsAmount)}
                        borderSize='border'
                        subTitle='Euros confiés'
                        iconComponent={EuroCircleIcon}
                        topComponent={
                            <TopComponent
                                jointContractor={transformThousandNumbers(coContractorsAmount)}
                                Subcontractors={transformThousandNumbers(subcontractorsAmount)}
                            />
                        }
                    />

                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={transformThousandNumbers(coContractorsCommission + subcontractorsCommission)}
                        borderSize='border'
                        subTitle='Marge retenue'
                        iconComponent={CalculatorIcon}
                        topComponent={
                            <TopComponent
                                jointContractor={transformThousandNumbers(coContractorsCommission)}
                                Subcontractors={transformThousandNumbers(subcontractorsCommission)}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default ExternalRessource;

const TopComponent = ({jointContractor, Subcontractors}) => {
    return (
        <div className="flex justify-around pb-3">
            <div className="flex flex-col justify-start w-32">
                <p className='text-white'>Cotraitants</p>
                <div className="my-2 bg-white w-full h-[1px]"/>
                <p style={{ fontFamily: 'Din condensed' }} className="text-4xl text-white">{jointContractor}</p>
            </div>

            <div className="flex flex-col justify-start w-32">
                <p className='text-white'>Sous-traitants</p>
                <div className="my-2 bg-white w-full h-[1px]"/>
                <p style={{ fontFamily: 'Din condensed' }} className="text-4xl text-white">{Subcontractors}</p>
            </div>
        </div>
    )
}
