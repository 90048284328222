import {useEffect, useState} from 'react';
import * as Utility from '../../../utils/index.js';
import Loader from '../../loaders/Loader.js';
import DateSelector from './DateSelector.js';
import MonthView from './MonthView.js';
import ViewToggleButton from './ViewToggleButton.js';
import YearView from './YearView.js';

const Calendar = ({
    title,
    data,
    yearNumber,
    setYearNumber,
    loading,
    calendarConfig,
    onCalendarCellClick = null,
    setCalendarView = () => null,
    setCalendarMonth = () => null
}) => {
    const {monthsOfYear, rangeYears} = Utility;
    const date = new Date();

    const initialState = {
        dayNumber: date.getDate(),
        monthNumber: date.getMonth(),
        yearNumber: date.getFullYear()
    };

    const [daySelectedNumber, setDaySelectedNumber] = useState(initialState.dayNumber);
    const [monthSelectedNumber, setMonthSelectedNumber] = useState(initialState.monthNumber);
    const [calendarFormatView, setCalendarFormatView] = useState('month');

    const yearsArray = rangeYears(2010, date.getFullYear() + 1);

    // Reset the day selected when the month or the year change
    useEffect(() => {
        if (monthSelectedNumber !== initialState.monthNumber || yearNumber !== initialState.yearNumber || daySelectedNumber !== initialState.dayNumber) {
            setDaySelectedNumber(0);
        }

        setCalendarMonth(monthSelectedNumber);
    }, [monthSelectedNumber, yearNumber]);

    // Set the date formated when the day selected change
    const handleSetCurrentDay = (day) => {
        setDaySelectedNumber(day);
    };

    useEffect(() => {
        if (setCalendarView) {
            setCalendarView(calendarFormatView);
        }
    }, [calendarFormatView]);

    return (
        <div className="relative">
            <div className="flex flex-row items-center justify-between mb-5">
                <p className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>{title}</p>
                <div className="flex flex-row justify-between gap-x-2">
                    {calendarFormatView === 'month' && (
                        <DateSelector name={'month'} defaultValue={monthSelectedNumber} setDataState={setMonthSelectedNumber} dataArray={monthsOfYear}/>
                    )}
                    <DateSelector name={'year'} defaultValue={yearNumber} setDataState={setYearNumber} dataArray={yearsArray}/>
                    <ViewToggleButton setCalendarFormatView={setCalendarFormatView} calendarFormatView={calendarFormatView}/>
                </div>
            </div>
            {
                loading &&
                <div className="absolute z-10 top-[50%] left-[50%] flex flex-col items-center justify-center text-[#646464] font-medium" style={{transform: 'translateY(-50%)'}}>
                    <Loader size={30}/>
                Chargement
                </div>
            }
            <div className={`${loading ? 'blur-sm' : null}`}>
                {
                    calendarFormatView === 'month' ?
                        <MonthView
                            data={data}
                            yearNumber={yearNumber}
                            monthSelectedNumber={monthSelectedNumber}
                            handleSetCurrentDay={handleSetCurrentDay}
                            daySelectedNumber={daySelectedNumber}
                            calendarConfig={calendarConfig}
                            onCalendarCellClick={onCalendarCellClick}
                        /> :
                        <YearView
                            data={data}
                            yearNumber={yearNumber}
                            monthsOfYear={monthsOfYear}
                            monthSelectedNumber={monthSelectedNumber}
                            setMonthSelectedNumber={setMonthSelectedNumber}
                            calendarConfig={calendarConfig}
                            onCalendarCellClick={onCalendarCellClick}
                        />
                }
            </div>
        </div>
    );
};

export default Calendar;
