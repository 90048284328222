import MonthCell from './MonthCell';
import {standardFormat} from '../../../utils';

const YearView = ({
    monthsOfYear,
    yearNumber,
    monthSelectedNumber,
    setMonthSelectedNumber,
    data,
    calendarConfig,
    onCalendarCellClick
}) => {
    const getMonthStyles = (month) => {
        let cellStyle = '';
        let textColor = '';

        if (month === monthSelectedNumber) {
            cellStyle = 'bg-[#f9e2d7] border-[#e36d38]';
            textColor = 'text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] block';
        }
        else {
            cellStyle = 'border-[#f0f0f0] shadow-none hover:shadow-[0_2px_4px_rgba(0,0,0,0.25)] transition-all';
            textColor = 'text-[#262626]';
        }

        return {
            cellStyle,
            textColor
        };
    };

    return (
        <div className="grid grid-cols-3 gap-x-3">
            {monthsOfYear.map((month, index) => {
                const {cellStyle, textColor} = getMonthStyles(index);
                const monthData = calendarConfig.yearFilterDataFunction(data, standardFormat(new Date(yearNumber, index, 1)));

                return (
                    <MonthCell
                        key={month}
                        monthData={monthData}
                        monthTitle={month}
                        currentMonth={index}
                        setMonthSelectedNumber={setMonthSelectedNumber}
                        cellStyle={cellStyle}
                        textColor={textColor}
                        yearNumber={yearNumber}
                        calendarConfig={calendarConfig}
                        onCalendarCellClick={onCalendarCellClick}
                    />
                );
            })}
        </div>
    );
};

export default YearView;
