import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import DateInput from '../../../../../components/ui/Inputs/DateInput/DateInput';
import Button from '../../../../../components/ui/Button/Button';
import {notify} from '../../../../../components/ui/Toast/Toast';
import CheckboxInput from '../../../../../components/ui/Inputs/CheckboxInput/CheckboxInput';
import DropdownInput from '../../../../../components/ui/Inputs/DropdownInput';
import {frenchFormat, standardFormat} from '../../../../../utils';
import {ProductionActions} from '../../../../../actions';
import ContainerCard from '../../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../../components/ui/Error/Error';
import {getErrors} from '../../../../../utils';

function CreateNewVacation({
    userData,
    switchForm,
    setSwitchForm,
    setView,
    vacationToBeModified,
    refreshData
}) {
    const newProduction = [{name: 'Production'}, {name: 'Congés'}];
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const defaultValues = {
        startingDate: (vacationToBeModified?.date && !vacationToBeModified?.id) ? dayjs(vacationToBeModified.date) : dayjs(),
        morningStart: true,
        endingDate: (vacationToBeModified?.date && !vacationToBeModified?.id) ? dayjs(vacationToBeModified.date) : dayjs(),
        afternoonEnd: true
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (vacationToBeModified?.id) {
            reset({
                date: vacationToBeModified.date
            });
        }
    }, [vacationToBeModified]);

    const onSubmit = async (data) => {
        setErrors(null);
        setIsDisabled(true);
        setIsLoading(true);

        if (!vacationToBeModified || vacationToBeModified.date && Object.keys(vacationToBeModified).length === 1) {

            const vacation = {
                start: standardFormat(data.startingDate),
                start_morning: data.morningStart,
                end: standardFormat(data.endingDate),
                end_morning: data.afternoonEnd
            };

            const result = await ProductionActions.createVacation(userData, vacation);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre temps à bien été créé');
                setView(false);
            }
        }
        else {
            const updatedVacation = {
                date: standardFormat(data.date),
                deal_id: vacationToBeModified?.deal_id,
                ressource_id: vacationToBeModified?.ressource.id,
                billed_days: vacationToBeModified?.billed_days.toString()
            };

            const result = await ProductionActions.updateProduction(userData, vacationToBeModified.id, updatedVacation);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre temps à bien été modifié');
                setView(false);
            }
        }
        
        setIsLoading(false);
        setIsDisabled(false);
    };

    return (
        <ContainerCard
            title={vacationToBeModified?.id ? 'Éditer un temps' : 'Nouveau temps'}
            coloredButtonDisplayed={false}
            overflow=''
        >
            {
                (!vacationToBeModified || (vacationToBeModified.date && !vacationToBeModified.id)) ?
                    <div className="flex flex-col justify-start pt-6 pb-5">
                        <div className="flex mb-2">
                            <Controller
                                name="type"
                                control={control}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <DropdownInput
                                        label={'Type de temps'}
                                        onChange={onChange}
                                        setSwitchForm={setSwitchForm}
                                        value={switchForm ? switchForm : value}
                                        error={error}
                                        required={true}
                                        items={newProduction}
                                        disabled={(vacationToBeModified && vacationToBeModified.produced_days) ? true : isDisabled}
                                        listHeight="70px"
                                    />
                                )}
                            />
                        </div>
                        <div className='flex items-center justify-between'>
                            <div>
                                <div className="flex">
                                    <Controller
                                        name="startingDate"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une date de début.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <DateInput
                                                label={'Date de début'}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={isDisabled}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mt-2 w-fit pl-36">
                                    <Controller
                                        name="morningStart"
                                        control={control}
                                        render={({
                                            field: {onChange, value}
                                        }) => (
                                            <CheckboxInput
                                                uniqueName="morningStart"
                                                label={'Matinée inclue'}
                                                required={false}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col items-start h-full'>
                                <div>
                                    <Controller
                                        name="endingDate"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une date de fin.'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <DateInput
                                                label={'Date de fin'}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={isDisabled}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mt-2 w-fit pl-36">
                                    <Controller
                                        name="afternoonEnd"
                                        control={control}
                                        render={({
                                            field: {onChange, value}
                                        }) => (
                                            <CheckboxInput
                                                uniqueName="afternoonEnd"
                                                label={'Après-midi inclue'}
                                                required={false}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="flex flex-col justify-start pt-6 pb-5">
                        <div className='flex items-center justify-between'>
                            <div>
                                <div className="flex mb-2">
                                    <Controller
                                        name="type"
                                        control={control}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <DropdownInput
                                                label={'Type de temps'}
                                                onChange={onChange}
                                                setSwitchForm={setSwitchForm}
                                                value={switchForm ? switchForm : value}
                                                error={error}
                                                required={true}
                                                items={newProduction}
                                                disabled={(vacationToBeModified && vacationToBeModified.produced_days) ? true : isDisabled}
                                                listHeight="70px"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col items-start h-full'>
                                <div>
                                    <Controller
                                        name="date"
                                        control={control}
                                        rules={{required: {
                                            value: true,
                                            message: 'Veuillez sélectionner une date'
                                        }}}
                                        render={({
                                            field: {onChange, value},
                                            fieldState: {error}
                                        }) => (
                                            <DateInput
                                                label={'Date'}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={isDisabled}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className="flex flex-col justify-center mt-6">
                <div className="flex justify-center">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={isDisabled}
                    />
                    <Button
                        type={'primary'}
                        content={vacationToBeModified?.id ? 'Modifier le temps' : 'Ajouter le temps'}
                        onClick={handleSubmit(onSubmit)}
                        loading={isLoading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewVacation;
