import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import DonutsChart from "../../../../components/ui/DonutsChart/DonutsChart";
import {grossMarginProducedChartConfig} from "../../configs/resultIndicatorTab/grossMarginProducedSection/grossMarginProducedChartConfig";
import {aggregateMarginPerStatus} from "../../../codevDashboardPage/functions/aggregateMarginProduced";

const GrossMarginProducedChart = ({data, dasKey}) => {
    const noData = [
        {
            type: 'Junior',
            value: 0
        },
        {
            type: 'Confirmé',
            value: 0
        },
        {
            type: 'Sénior',
            value: 0
        }
    ];

    return (
        <ContainerCard showHeader={false}>
            <DonutsChart
                label={'€'}
                data={data[dasKey] ? aggregateMarginPerStatus(data, dasKey) : noData}
                config={grossMarginProducedChartConfig}
            />
        </ContainerCard>
    )
};

export default GrossMarginProducedChart;
