import { useState, useRef, useEffect } from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';

import searchIcon from './../../../../assets/icons/table/search-icon.png';

import './date-picker.css';

/**
 * TableDatePicker component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.onChange - The function to be called when the date picker value changes.
 * @param {string} props.name - The name of the date picker.
 * @param {boolean} [props.resetWhenValueChanges=false] - Whether to reset the date picker value when the value changes.
 * @returns {JSX.Element} - The rendered component.
 */
export const TableDatePicker = ({onChange, name, resetWhenValueChanges = null}) => {
    const [visible, setVisible] = useState(false);
    const [localValue, setLocalValue] = useState(null);
    const [value, setValue] = useState(null);
    const { RangePicker } = DatePicker;
    const fakeInputRef = useRef(null);
    const pickerPopupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                fakeInputRef.current && !fakeInputRef.current.contains(event.target) &&
                pickerPopupRef.current && !pickerPopupRef.current.contains(event.target) &&
                !event.target.closest('.ant-picker-dropdown')
            ) {
                setVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleChange = (value) => {
        setVisible(false);

        if (!value) {
            setLocalValue(null);
            setValue(null);
            onChange([null, null], name, true);
            
            return;
        }

        setLocalValue(value);
        setValue(value);
        onChange(value, name, true);
    }

    useEffect(() => {
        if (resetWhenValueChanges) {
            setLocalValue(null);
            setValue(null);
        }
    }, [resetWhenValueChanges]);

    return (
        <div className='relative h-6 px-1 table-datepicker'>
            <div className={`${visible && 'background-gradient'} rounded-md h-full w-full cursor-pointer`}>
                <div
                    ref={fakeInputRef}
                    onClick={() => setVisible(true)}
                    className='flex items-center justify-end h-full pr-2 bg-white rounded-[4px]'
                >
                    <div className='flex flex-col self-center items-center w-full text-[8px]'>
                        {localValue ? (
                            <>
                                <p className='leading-[1.1]'>{localValue[0].format('DD/MM/YYYY')}</p>
                                <p className='leading-[1.1]'>{localValue[1].format('DD/MM/YYYY')}</p>
                            </>
                        ) : null}
                    </div>
                    <img className='w-2.5 h-2.5' src={searchIcon} alt="search"/>
                </div>
            </div>
            
            <div
                ref={pickerPopupRef}
                style={{display: visible ? 'block' : 'none'}}
                className='absolute z-[999999] p-[2px] rounded-md range-picker-popup background-gradient'
            >
                <ConfigProvider locale={frFR}>
                    <RangePicker value={value} format="DD/MM/YYYY" onChange={handleChange}/>
                </ConfigProvider>
            </div>
            
        </div>
    );
};