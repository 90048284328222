import React, {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import SelectInput from '../../../../../components/ui/Inputs/SelectInput/SelectInput';
import DateInput from '../../../../../components/ui/Inputs/DateInput/DateInput';
import TextInput from '../../../../../components/ui/Inputs/TextInput';
import Button from '../../../../../components/ui/Button/Button';
import CheckboxInput from '../../../../../components/ui/Inputs/CheckboxInput/CheckboxInput';
import TextAreaInput from '../../../../../components/ui/Inputs/TextAreaInput';
import {frenchFormat, standardFormat, fetchSelectData} from '../../../../../utils';
import {notify} from '../../../../../components/ui/Toast/Toast';
import {VehicleTypeActions, ExpenseActions, ExpenseTypeActions} from '../../../../../actions';
import {assimilateDuplicates, getFormula, getInputDataFromNotes} from '../../../functions/expensesFunctions';
import ContainerCard from '../../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../../components/ui/Error/Error';
import {getErrors, setCustomErrorMessage} from '../../../../../utils';

function CreateNewKilometricExpense({
    userData,
    missionData,
    expenseToBeUpdated,
    setSwitchForm,
    setView,
    refreshData,
    blueStyle
}) {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [types, setTypes] = useState(null);
    const [vehicleTypes, setVehicleTypes] = useState(null);
    const [vehicleNames, setVehicleNames] = useState(null);

    // ↓ kilometric expense type non dynamic -> corresponding to the type returned by the database (see expense-types request)
    const kilometricExpenseType = {
        id: 1,
        is_active: true,
        name: 'Indemnités kilométriques',
        group: 'Voyages et déplacements',
        accounting: '625100'
    }

    // -- react hook form --
    const defaultValues = {
        date: dayjs(),
        billable: false,
        scale: '',
        deal: '',
        ressource: '',
        type: kilometricExpenseType,
        distance: null,
        co2: null,
        notes: ''
    };
    const {handleSubmit, control, reset, watch , formState: {isValid}} = useForm({defaultValues: defaultValues});

    // Listen to the type value and set the switchForm state accordingly to change the form component displayed
    useEffect(() => {
        const type = watch('type');
            
        if (type?.name !== 'Indemnités kilométriques') {
            setSwitchForm(type);
        }
    }, [watch('type')]);

    // -- fill kilometric expense values in form for update --
    useEffect(() => {
        if (expenseToBeUpdated && vehicleNames) {

            const {expenseVehicle, expenseDistance, expenseNotes} = getInputDataFromNotes(expenseToBeUpdated, vehicleNames);

            const ressource = {
                ...expenseToBeUpdated?.ressource,
                name: expenseToBeUpdated?.ressource?.first_name + ' ' + expenseToBeUpdated?.ressource?.last_name
            };

            reset({
                date: dayjs(expenseToBeUpdated.date),
                billable: expenseToBeUpdated.billable,
                scale: expenseVehicle,
                deal: expenseToBeUpdated.deal,
                ressource: ressource,
                distance: (+expenseDistance).toString(),
                co2: (+expenseToBeUpdated.co2).toString(),
                notes: expenseNotes
            });
        }
    }, [expenseToBeUpdated, vehicleTypes])

    // -- functions that get data for Select Inputs --
    const handleGetExpenseTypes = async (search, page = 1) => {
        await fetchSelectData({action: ExpenseTypeActions.getAllExpenseTypes, search:`&search=${search}`, page: page, setter: setTypes, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ vehicle types dropdown values
        (async () => {
            const result = await VehicleTypeActions.getAllVehicleTypes(userData);
            setVehicleTypes(result.data);
            setVehicleNames(assimilateDuplicates(result.data));
        })();

        // ↓ expense types dropdown values
        (async () => {
            await handleGetExpenseTypes('', 1);
        })();
    }, []);

    // -- handle submit --
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const formula = getFormula(vehicleTypes, {name: data.scale.name, distance: +data.distance});

        if (!formula) {
            setErrors(setCustomErrorMessage('La distance est incohérente avec ce type de véhicule'));
            setLoading(false);
            setDisabled(false);

            return;
        }

        // ↓ function that evaluates the formula dynamicaly with the Function constructor
        const scaleResult = new Function('d', 'return ' + formula)(data.distance).toFixed(2);

        // ↓ body to send to the post/patch request (see API documentation)
        const expense = {
            deal_id: missionData.deal.id,
            type: kilometricExpenseType,
            type_name: kilometricExpenseType.name,
            date: standardFormat(data.date),
            billable: data.billable,
            amount: scaleResult,
            vat: 0,
            ressource: userData.userId,
            notes: `${data.distance} km · ${data.scale.name}${data.notes ? ` · ${data.notes}` : ''}`,
            report: expenseToBeUpdated ? expenseToBeUpdated.report : null
        }
        
        if (data.co2) {
            expense.co2 = data.co2;
        }
        
        // ⓘ if it is a new kilometric expense, the «report» key is assigned in «createExpense.ts» file
        
        // ↓ post - new kilometric expense
        if (!expenseToBeUpdated) {

            const result = await ExpenseActions.createExpense(userData, expense);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre frais à bien été créé');
                setView(false);
            }

            setLoading(false);
            setDisabled(false);
        }
        // ↓ patch - update kilometric expense
        else {
            const result = await ExpenseActions.updateExpense(userData, expenseToBeUpdated.id, expense);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('Votre temps à bien été modifié');
                setView(false);
            }

            setLoading(false);
            setDisabled(false);
        }
    };

    return (
        <ContainerCard
            overflow=''
            title={expenseToBeUpdated?.id ? 'Éditer un frais' : 'Nouveau frais'}
            coloredButtonDisplayed={false}
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-4">
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="type"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un type de frais.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type de frais'
                                    placeholder='Type de frais'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={types?.options || []}
                                    hasNextPage={types?.hasNextPage || false}
                                    fetchFunction={handleGetExpenseTypes}
                                    loading={!types}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    isSearchable={true}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2 w-fit pl-36">
                        <Controller
                            name="billable"
                            control={control}
                            render={({
                                field: {onChange, value}
                            }) => (
                                <CheckboxInput
                                    uniqueName="billable"
                                    label={'Refacturable'}
                                    required={false}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="scale"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un barème.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Barème'
                                    placeholder='Barème'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={vehicleNames}
                                    onChange={onChange}
                                    loading={!vehicleNames}
                                    isSearchable={true}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="distance"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez indiquer la distance.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Distance [km]'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Distance [km]"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="co2"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Qté CO2 (g)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={false}
                                    disabled={disabled}
                                    placeholder="Qté CO2 (g)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={expenseToBeUpdated?.id ? 'Modifier le frais' : 'Ajouter le frais'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!vehicleNames || !isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewKilometricExpense;
