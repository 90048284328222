import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import './loader.css';

const Loader = ({size = 25, color = '#E36D38', visible = true}) => {
    const antIcon = <LoadingOutlined style={{fontSize: size, color: color}} spin/>;

    return (
        <>
            {
                visible ?
                    <Spin
                        indicator={antIcon}
                        wrapperClassName="spin-sextant"
                    /> :
                    null
            }
        </>
    );
};

export default Loader;
