/* eslint-disable max-len */
export const aggregateLoadPerDas = (data: any, key: string) => {
    
    const aviableLoadDas = Math.round(((data.load[key].Confirmé + data.load[key].Junior + data.load[key].Sénior ) / data.available_load[key]) * 100);

    return aviableLoadDas;
}

export const userDasLoad = (data: Record<string, any>) => {

    const sumLoad: any = Object.values(data.load).reduce((a: any, b: any) => a + b);
    
    return Math.round(data.load[data.currentdas] / sumLoad * 100);
    
}

export const concernedDasRessourcesCount  = (data: Record<string, any>, das: string ) => {

    return Object.values(data).filter((item) => item.currentdas === das && item.load[das] > 0).length;
}

export const aggregateTotalLoad = (data: any) => {
    
    const copy = {...data};
    delete copy.available_load;
    delete copy.billing;

    let totalLoad = 0;
    let totalAvailableLoad = 0;
    
    for (const key in copy.load) {
        // eslint-disable-next-line max-len
        totalLoad += copy.load[key].Confirmé + copy.load[key].Junior + copy.load[key].Sénior;
        totalAvailableLoad += data.available_load[key];

    }

    const aviableLoad = Math.round((totalLoad / totalAvailableLoad) * 100);

    return aviableLoad;

}

export const aggregateOfficeLoadChildrenData = (data: any) => {
    
    const copy = {...data};
    delete copy.available_load;
    delete copy.billing;

    const officeLoadChildrenData: Record<string, any> = {
        cabinetJunior: 0,
        cabinetSenior: 0,
        cabinetConfirmed: 0
    }

    for (const key in copy.load) {
        officeLoadChildrenData[key] = aggregateLoadPerDas(data, key);
        officeLoadChildrenData.cabinetJunior += copy.load[key].Junior;
        officeLoadChildrenData.cabinetSenior += copy.load[key].Sénior;
        officeLoadChildrenData.cabinetConfirmed += copy.load[key].Confirmé;
    }

    return officeLoadChildrenData;

}

export const aggregateBilledRate = (productionLoad: any) => {
    const billedRate: Record <string, any> = {}
    for (const key in productionLoad.billing) {

        billedRate[key] = isNaN( ((productionLoad.billing[key].Junior.billed_days + productionLoad.billing[key].Senior.billed_days + productionLoad.billing[key].Confirmé.billed_days) / (productionLoad.billing[key].Junior.produced_days + productionLoad.billing[key].Senior.produced_days + productionLoad.billing[key].Confirmé.produced_days) * 100)) ? 0 : ((productionLoad.billing[key].Junior.billed_days + productionLoad.billing[key].Senior.billed_days + productionLoad.billing[key].Confirmé.billed_days) / (productionLoad.billing[key].Junior.produced_days + productionLoad.billing[key].Senior.produced_days + productionLoad.billing[key].Confirmé.produced_days) * 100)
    }

    return billedRate;

}

export const aggregateBilledDays = (productionLoad: any) => {
    const billedDays: Record <string, any> = {}
    for (const key in productionLoad.billing) {

        billedDays[key] = productionLoad.billing[key].Junior.billed_days + productionLoad.billing[key].Senior.billed_days + productionLoad.billing[key].Confirmé.billed_days
    }

    return billedDays;

}

export const aggregateProducedDays = (productionLoad: any) => {
    const producedDays: Record <string, any> = {}
    for (const key in productionLoad.billing) {

        producedDays[key] = productionLoad.billing[key].Junior.produced_days + productionLoad.billing[key].Senior.produced_days + productionLoad.billing[key].Confirmé.produced_days
    }

    return producedDays;
}

export const producedDaysTotal = (cardDataProduced: any) => {

    let total = 0;

    for (const key in cardDataProduced) {
        total += cardDataProduced[key];
    }

    return total.toFixed();

}

export const billedDaysTotal = (cardDataBilled: any) => {
    
    let total = 0;
    
    for (const key in cardDataBilled) {
        total += cardDataBilled[key];
    }
    
    return total.toFixed();
    
}

export const billedRateTotal = (cardDataBilledRate: any) => {
        
    let total = 0;
        
    for (const key in cardDataBilledRate) {
        total += cardDataBilledRate[key];
    }
        
    return (total / Object.keys(cardDataBilledRate).length).toFixed();
        
}
