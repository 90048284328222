import {ACTION_CODE} from './_actionCode';
import {ExpenseAttachmentService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_EXPENSE_ATTACHMENT';

export async function deleteExpenseAttachment(userData: Record<string, any>, id: string) {
    try {
        LoggerService.logInfo('Action - deleteExpenseAttachment - Start');

        const deleteExpenseAttachmentResult = await ExpenseAttachmentService.deleteExpenseAttachment(userData, id);

        LoggerService.logInfo('Action - deleteExpenseAttachment - End - Success');

        return ResultModel.newSuccessResult(deleteExpenseAttachmentResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteExpenseAttachment - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'un frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
