import {RocketFilled, ScheduleFilled} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import * as Utils from '../../../../utils';
import LineTitle from '../../../../components/ui/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import {currentTotal} from "../../functions/aggregateProductionsActivity"

const ActivityIndicator = ({productionActivity}) => {
    const ScheduleIcon = <ScheduleFilled style={{ fontSize: '30px', color:'white' }}/>;
    const RocketIcon = <RocketFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className='my-10'>
            <LineTitle title="Indicateurs d'activité" textSize='text-4xl'/>
            <p style={{ fontFamily: 'Din condensed' }} className='mt-10 text-2xl capitalize'>{Utils.getCurrentMonthAndYear()}</p>

            <div className='flex justify-between mt-5'>
                <Link to='current-deals' className='w-[48%]'>
                    <SimpleCard
                        width='w-full'
                        title={currentTotal(productionActivity.deals)}
                        subTitle="Affaires en cours"
                        iconComponent={ScheduleIcon}
                        topComponent={<TopComponent data={productionActivity.deals}/>}
                    />
                </Link>

                <Link to='current-missions' className='w-[48%]'>
                    <SimpleCard
                        width='w-full'
                        title={currentTotal(productionActivity.missions)}
                        subTitle="Missions en cours"
                        iconComponent={RocketIcon}
                        topComponent={<TopComponent data={productionActivity.missions}/>}
                    />
                </Link>
            </div>
        </div>
    )
}

export default ActivityIndicator;

const TopComponent = ({data}) => {

    const allDasS = useSelector((state) => state.DasReducer.das);

    let dasS = [];

    for (const key in data) {
        let currentDas = allDasS.filter((el) => el.name === key);

        dasS.push({
            das: key,
            value: data[key].count,
            dasObject: currentDas = currentDas.length > 0 ? currentDas[0] : null
        })
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge
                                    content={el.dasObject ? [el.dasObject.manager] : '-'}
                                    color={el.dasObject ? null : '#bdc3c7'}
                                    type='round'
                                />
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}