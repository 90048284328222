import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import {fetchSelectData} from './../../../../utils';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import Button from '../../../../components/ui/Button';
import {
    CustomerActions,
    DealDasActions,
    SubsidiaryActions,
    DealActions,
    DealOriginsActions
} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from './../../../../utils';

const DealInformationsPopup = ({
    setView,
    data,
    refreshData,
    blueStyle
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [subsidiaryCategories, setSubsidiaryCategories] = useState(null);
    const [dealDas, setDealDas] = useState(null);
    const [dealOrigins, setDealOrigins] = useState(null);

    const dealTypes = [
        {
            id: 1,
            name: "Accord Cadre",
            value: "framework"
        },
        {
            id: 2,
            name: "Chantiers internes",
            value: "project"
        },
        {
            id: 3,
            name: "Bond de Commande",
            value: "purchase_order"
        },
        {
            id: 4,
            name: "Marché",
            value: "business"
        }
    ];

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // -- react hook form --
    const defaultValues = {
        customer: '',
        short_name: '',
        name: '',
        subsidiary: '',
        das: '',
        type: '',
        origin: ''
    };
    const {handleSubmit, control, reset, watch, formState: {isValid}, setValue} = useForm({defaultValues: defaultValues});

    const watchCustomer = watch('customer');
    
    // -- functions that get data for Select Inputs --
    const handleGetCustomers = async (search, page = 1) => {
        await fetchSelectData({action: CustomerActions.getAllCustomers, search:`&search=${search}`, page: page || null, setter: setCustomers, userData});
    };

    const handleGetSubsidiaries = async (search, page = 1) => {
        await fetchSelectData({action: SubsidiaryActions.getAllSubsidiaries, search:`&search=${search}`, page: page || null, setter: setSubsidiaryCategories, userData, resultPath: 'data'});
    };

    const handleGetDealDas = async (search, page = 1) => {
        await fetchSelectData({action: DealDasActions.getAllDealDas, search:`&search=${search}`, page: page || null, setter: setDealDas, userData});
    };

    const handleGetDealOrigins = async (search, page = 1) => {
        const filterOrFind = (origin) => origin.is_active;
    
        await fetchSelectData({
            action: DealOriginsActions.getAllDealOrigins,
            search:`&search=${search}`,
            page: page || null,
            setter: setDealOrigins,
            userData,
            filterOrFind
        });
    };

    // Get dropdows data
    useEffect(() => {
        // ↓ customer categories dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

        // ↓ deal das dropdown values
        (async () => {
            await handleGetDealDas('', 1);
        })();

        // ↓ deal subsidiary dropdown values
        (async () => {
            await handleGetSubsidiaries('', 1);
        })();

        // ↓ deal origins dropdown values
        (async () => {
            await handleGetDealOrigins('', 1);
        })();
    }, []);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            reset({
                name: data.name,
                short_name: data.short_name,
                customer: data.customer,
                billing_address: data.billing_address,
                type: dealTypes.find((type) => type.value === data.type)[0] ?? '',
                das: {name: data.das},
                indice: data.indice,
                subsidiary: {name: data.subsidiary},
                origin: {name: data.origin}
            })
        }
    }, [data]);

    useEffect(() => {
        if (watchCustomer.name === "Terre d'avance" || watchCustomer.name === "Terre d'avance formation") {
            setValue('type', dealTypes[1]);
            setValue('status', 0)
        }
        else {
            setValue('type', dealTypes[3])
            setValue('status', 100)
        }
        
    }, [watchCustomer])

    // Submit form
    const onSubmit = async (formData) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const sales = data.sales.map((sale) => {
            return {
                ressource: sale.ressource,
                ressource_id: sale.ressource.id,
                percent: sale.percent

            }
        })

        const updatedDeal = {
            sales: sales,
            customer_id: formData.customer.id,
            origin: formData.origin.name,
            das: formData.das.name,
            subsidiary: formData.subsidiary.name,
            name: formData.name,
            short_name: formData.short_name,
            type: formData.type.value,
            status: formData.status
        }

        const result = await DealActions.updateDeal(userData, updatedDeal, data.id);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            notify('Les informations ont été mis à jour');
            setView(false);
            reset();
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={data.status === 100 ? data.name : 'Informations de l’affaire'}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className='pb-3'>
                <Controller
                    name="customer"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir un client'
                    }}}
                    render={({
                        field: {onChange, ref, value},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            width='w-[830px]'
                            label='Client'
                            placeholder='Client'
                            inputRef={ref}
                            error={error}
                            value={value}
                            options={customers?.options}
                            hasNextPage={customers?.hasNextPage}
                            onChange={onChange}
                            fetchFunction={handleGetCustomers}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            isSearchable={true}
                            loading={!customers}
                            labelWidth='w-40'
                            blueStyle={blueStyle}
                        />
                    )}
                />
            </div>
            <div className='pb-3'>
                <Controller
                    name="short_name"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le libellé de l\'affaire'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInput
                            width='w-[830px]'
                            label="Libellé de l'affaire"
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="[Client]Affaire"
                            type="text"
                            labelWidth='w-40'
                            color={blueStyle ? 'blue' : 'orange'}
                        />
                    )}
                />
            </div>
            <div className='pb-3'>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le nomn abrégé'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInput
                            width='w-[830px]'
                            label="Nom abrégé"
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="[Client]Affaire"
                            type="text"
                            labelWidth='w-40'
                            color={blueStyle ? 'blue' : 'orange'}
                        />
                    )}
                />
            </div>
            <div className='bg-gray-200 h-[1px] w-full'/>
            <div className='w-full pb-3'>
                <div className='flex justify-between pt-4 pb-2'>
                    <Controller
                        name="subsidiary"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir la chance de réussite'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label='Filiale'
                                placeholder='Filiale'
                                inputRef={ref}
                                error={error}
                                value={value}
                                onChange={onChange}
                                options={subsidiaryCategories?.options}
                                hasNextPage={subsidiaryCategories?.hasNextPage}
                                fetchFunction={handleGetSubsidiaries}
                                loading={!subsidiaryCategories}
                                isSearchable={true}
                                labelKeys={['name']}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                                blueStyle={blueStyle}
                            />
                        )}
                    />
                    <Controller
                        name="das"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom domaine d’activité'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label='DAS'
                                placeholder='Domaine d’activité'
                                inputRef={ref}
                                value={value}
                                error={error}
                                options={dealDas?.options}
                                hasNextPage={dealDas?.hasNextPage}
                                onChange={onChange}
                                fetchFunction={handleGetDealDas}
                                labelKeys={['name']}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                                loading={!dealDas}
                                blueStyle={blueStyle}
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between pb-4'>
                    <Controller
                        name="type"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir un statut'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label="Type d'affaire"
                                placeholder="Type d'affaire"
                                inputRef={ref}
                                error={error}
                                value={value}
                                options={dealTypes}
                                isSearchable={true}
                                onChange={onChange}
                                labelKeys={['name']}
                                required={true}
                                disabled={true}
                                labelWidth='w-40'
                                blueStyle={blueStyle}
                            />
                        )}
                    />
                    <Controller
                        name="origin"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir l\'origine'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label='Origine'
                                placeholder='Origine'
                                inputRef={ref}
                                error={error}
                                value={value}
                                options={dealOrigins?.options}
                                hasNextPage={dealOrigins?.hasNextPage}
                                onChange={onChange}
                                fetchFunction={handleGetDealOrigins}
                                labelKeys={['name']}
                                required={true}
                                disabled={disabled}
                                isSearchable={true}
                                labelWidth='w-40'
                                loading={!dealOrigins}
                                blueStyle={blueStyle}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Sauvegarder'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default DealInformationsPopup;
