import React from 'react';
import {Tooltip} from 'antd';
import {generateUserColor} from '../../../utils';

/**
 *  type = normal or round (string) - default -> normal
 *  color = {color code} (string) - default -> gradient
 *  textColor = {color code} (string) - default -> white
 *  content = {displayed text} (string)
 *  Possible props for content: Array of contents
 */

const Badge = ({
    type = 'normal',
    color = null,
    borderColor = 'white',
    textColor = 'white',
    content,
    topBadge = false,
    topBadgeContent = '',
    tooltip = null
    // blueStyle = false
}) => {
    const isArray = Array.isArray(content);

    return (
        content ? <div className="flex flex-row items-center justify-start">
            {
                isArray ?
                    content.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    (index === 2 && content.length > 3) ?
                                        <Tooltip
                                            key={index}
                                            title={() => {
                                                return (
                                                    <>
                                                        {
                                                            item?.ressource ?
                                                                content.slice(2).map((el) => {
                                                                    return (
                                                                        <div key={el.ressource.username} className='flex flex-col items-center justify-center'>
                                                                            <div>{`${el.ressource.first_name} ${el.ressource.last_name}`}</div>
                                                                        </div>
                                                                    )
                                                                }) :
                                                                content.slice(2).map((el) => {
                                                                    return (
                                                                        <div key={el.username} className='flex flex-col items-center justify-center'>
                                                                            <div>{`${el.first_name} ${el.last_name}`}</div>
                                                                        </div>
                                                                    )
                                                                })
                                                        }
                                                    </>
                                                )
                                            }}
                                        >
                                            <div
                                                className={`
                                                    text-[14px] color-[#DC3832] font-normal border-[1px] max-w-fit flex cursor-default
                                                    items-center justify-center relative ${topBadge ? 'ml-[-6px]' : 'ml-[-8px]'}
                                                    ${type === 'normal' ? 'rounded-xl w-fit px-2 py-0.5' : 'rounded-full min-w-[32px] h-8'}
                                                `}
                                                style={{
                                                    background: "#FFD8BF",
                                                    color: "#DC3832",
                                                    borderColor: borderColor,
                                                    fontFamily: type === 'normal' ? 'Roboto' : 'DIN-Regular'
                                                }}
                                            >
                                                +{content.length - 2}
                                            </div>
                                        </Tooltip>
                                        : index < 3 ?
                                            <Tooltip
                                                title={`
                                                    ${item?.ressource ? item.ressource?.first_name : item?.first_name}
                                                    ${item?.ressource ? item.ressource?.last_name : item?.last_name}
                                                `}
                                            >
                                                <div
                                                    className={`
                                                    text-xs font-normal border-[1px] max-w-fit flex items-center justify-center relative
                                                    ${index !== 0 ? topBadge ? 'ml-[-6px]' : 'ml-[-8px]' : ''} cursor-default
                                                    ${type === 'normal' ? 'rounded-xl w-fit px-2 py-0.5' : 'rounded-full min-w-[32px] h-8'}
                                                `}
                                                    style={{
                                                        background: generateUserColor(item?.ressource ? item?.ressource?.username : item?.username),
                                                        color: textColor,
                                                        borderColor: borderColor,
                                                        fontFamily: type === 'normal' ? 'Roboto' : 'DIN-Regular'
                                                    }}
                                                >
                                                    {
                                                        topBadge &&
                                                            <div className='absolute top-[-10px] right-[-6px] rounded-full px-1 py-[1px] text-xs bg-[#212121] cursor-default'>
                                                                {item?.percent}%
                                                            </div>
                                                    }
                                                    {item?.ressource ? item?.ressource?.username : item?.username}
                                                </div>
                                            </Tooltip>
                                            : null
                                }
                            </React.Fragment>
                        )
                    }) :
                    <Tooltip title={tooltip}>
                        <div
                            className={`
                                text-xs font-normal border-[1px] max-w-fit flex items-center justify-center relative cursor-default text-center
                                ${type === 'normal' ? 'rounded-xl w-fit px-2 py-0.5' : content.length > 3 ? 'rounded-full min-w-[40px] h-10' : 'rounded-full min-w-[32px] h-8'}
                            `}
                            style={{
                                background: color ? color : generateUserColor(content),
                                color: textColor,
                                borderColor: borderColor,
                                fontFamily: type === 'normal' ? 'Roboto' : 'DIN-Regular'
                            }}
                        >
                            {
                                topBadge &&
                                    <div className='absolute top-[-10px] right-[-6px] rounded-full px-1 py-[1px] text-xs bg-[#212121] cursor-default'>
                                        {topBadgeContent}
                                    </div>
                            }
                            {content}
                        </div>
                    </Tooltip>
            }
        </div> : ''
    );
};

export default Badge;
