import Table from "../../../../components/ui/Table/Table";
import {pageTableDealsConfig} from "./pageTableDealsConfig";
import {pageTableMissionsConfig} from "./pageTableMissionsConfig";
import {addMissionKeys} from "../../../../utils";
import Error from "../../../../components/ui/Error";

export const pageTabConfig = (data, type, das, tableConfig) => {

    // TABS
    let tabs = [
        {
            id: 1,
            label: 'Cabinet',
            component: <div className="mt-8">
                <Table
                    data={type === "deals" ? data : addMissionKeys(data)}
                    tableConfig={type === "deals" ? pageTableDealsConfig(tableConfig) : pageTableMissionsConfig(tableConfig)}
                    onSaveFunction={type === "missions" ? tableConfig.onSaveFunction : null}
                    showSubmitButtons={type === "missions" ? tableConfig.missionsTableEdit : null}
                    setShowSubmitButtons={type === "missions" ? tableConfig.setMissionsTableEdit : null}
                    submitButtonLoading={type === "missions" ? tableConfig.submitButtonLoading : null}
                    setSubmitButtonLoading={type === "missions" ? tableConfig.setSubmitButtonLoading : undefined}
                    loading={tableConfig.tableLoading}
                    onPageNumberChange={tableConfig.handleGetData}
                    paginationMetadata={tableConfig.paginationMetadata}
                />
                {(type === "missions") && (
                    <Error errors={tableConfig?.errors}/>
                )}
            </div>
        }
    ];

    das.forEach((obj) => {
        const dataTable = type === "deals" ? data : addMissionKeys(data);

        const objTableConfig = type === "deals" ? pageTableDealsConfig(tableConfig) : pageTableMissionsConfig(tableConfig);

        const item = {
            id: obj.id + 1 ,
            label: obj.name,
            component: (
                <div className="mt-8">
                    <Table
                        data={dataTable}
                        tableConfig={objTableConfig}
                        onSaveFunction={type === "missions" ? tableConfig.onSaveFunction : null}
                        showSubmitButtons={type === "missions" ? tableConfig.missionsTableEdit : null}
                        setShowSubmitButtons={type === "missions" ? tableConfig.setMissionsTableEdit : null}
                        submitButtonLoading={type === "missions" ? tableConfig.submitButtonLoading : null}
                        setSubmitButtonLoading={type === "missions" ? tableConfig.setSubmitButtonLoading : undefined}
                        loading={tableConfig.tableLoading}
                        onPageNumberChange={tableConfig.handleGetData}
                        paginationMetadata={tableConfig.paginationMetadata}
                    />
                    {(type === "missions") && (
                        <Error errors={tableConfig?.errors}/>
                    )}
                </div>
            )
        }

        tabs.push(item);
    })

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: type === "missions",
            tapSelectorPosition: 'self-start',
            onEditButtonClick: type === "missions" ? (param = true) => tableConfig.setMissionsTableEdit(param) : null,
            tabs: tabs,
            overflow: 'overflow-inherit'
        }
    );

};
