export const addMissionKeys = (missionsData) => {
    return missionsData.map((el) => ({
        ...el,
        raf: +((el.billable_days - el.billed_days).toFixed(3)),
        budget: +((+el.billable_days * +el.daily_rate).toFixed(3))
    }));
}

export const getRafOverrun = (mission, key) => {

    const projections = {
        projection_m1: +mission.projection_m1,
        projection_m2: +mission.projection_m2,
        projection_m3: +mission.projection_m3,
        projection_q1: +mission.projection_q1,
        projection_s1: +mission.projection_s1
    }

    //Get total up until current projection
    let total = 0;

    for (const k in projections) {
        total += projections[k];

        if (k === key) break;
    }

    if (mission.raf - total >= 0 || projections[key] === 0) return null;

    if (mission.raf - total < 0 && mission.raf - total + projections[key] > 0) return "yellow";

    return "red";
}
