import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes, useNavigate, useLocation} from "react-router-dom";
import {FireOutlined, LikeOutlined, PieChartOutlined, TeamOutlined} from '@ant-design/icons';

import Layout from '../../components/layout/Layout';
import CommercialTab from './components/commercialTab/CommercialTab';
import ProductionTab from './components/productionTab/ProductionTab';
import RessourceManagementsTab from './components/ressourceManagementsTab/RessourceManagementsTab';
import ResultIndicatorsTab from './components/resultIndicatorTab/ResultIndicatorsTab';
import {firstDayYear, lastDayYear} from '../../utils';
import {notify, Toast} from '../../components/ui/Toast/Toast';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import TabNavigator from '../../components/ui/navigation/TabNavigator';
import AdminDasTab from './components/AdminDasTab';
import {getCommercialTabData, handleCommercialBalanceDatePickerChange} from './functions/commercialTabData';
import {getProductionTabData, handleLoadIndicatorsDatePickerChange} from './functions/productionTabData';
import {
    getResourceManagementTabData,
    handleExternalRessourcesDatePickerChange
} from './functions/resourceManagementTabData';
import {getResultIndicatorsData, handleResultIndicatorsDatePickerChange} from './functions/resultIndicatorsTabData';
import SpecificationToStudyPage from "./SpecificationToStudyPage";
import ProposalsBeingWrittenPage from "./ProposalsBeingWrittenPage";
import ProposalsPendingPage from "./ProposalsPendingPage";
import ProposalsMadePage from './ProposalsMadePage';
import ProposalsWonPage from './ProposalsWonPage';
import ProposalsLost from './ProposalsLost';
import SpecificationsStudiedPage from './SpecificationsStudiedPage';
import CurrentDeals from "./CurrentDeals";
import CurrentMissions from "./CurrentMissions";
import { DealActions, MissionActions, DashboardActions } from '../../actions';

const DasDashboardPage = () => {

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // ↓ States
    const [displayedSection, setDisplayedSection] = useState('Commercial');
    const [dasKey, setDasKey] = useState('DAS 1');
    const [loading, setLoading] = useState(true);
    const [productionLoading, setProductionLoading] = useState(true);

    // ↓ table data states
    const [specificationsToStudy, setSpecificationsToStudy] = useState([]);
    const [proposalsBeingWritten, setProposalsBeingWritten] = useState([]);
    const [proposalsPending, setProposalsPending] = useState([]);
    const [currentDeals, setCurrentDeals] = useState([]);
    const [missions, setMissions] = useState([]);

    // Filters States
    const [specificationsToStudyFilter, setSpecificationsToStudyFilter] = useState('');
    const [proposalsBeingWrittenFilter, setProposalsBeingWrittenFilter] = useState('');
    const [proposalsPendingFilter, setProposalsPendingFilter] = useState('');
    const [currentDealsFilter, setCurrentDealsFilter] = useState('');
    const [missionsFilter, setMissionsFilter] = useState('');

    // Pagination States
    const [specificationsToStudyPaginationMetadata, setSpecificationsToStudyPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    const [proposalsBeingWrittenPaginationMetadata, setProposalsBeingWrittenPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    const [proposalsPendingPaginationMetadata, setProposalsPendingPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    const [currentDealsPaginationMetadata, setCurrentDealsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    const [missionsPaginationMetadata, setMissionsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    // Table Loading States
    const [specificationsToStudyTableLoading, setSpecificationsToStudyTableLoading] = useState(false);
    const [proposalsBeingWrittenTableLoading, setProposalsBeingWrittenTableLoading] = useState(false);
    const [proposalsPendingTableLoading, setProposalsPendingTableLoading] = useState(false);
    const [currentDealsTableLoading, setCurrentDealsTableLoading] = useState(false);
    const [missionsTableLoading, setMissionTableLoading] = useState(false);

    // ↓ «Commercial» TAB STATES
    const [salesActivity, setSalesActivity] = useState({});
    const [salesOverview, setSalesOverview] = useState({});
    const [commercialBalanceDatePicker, setCommercialBalanceDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ «Production» TAB STATES
    const [productionActivity, setProductionActivity] = useState({});
    const [dasUsers, setDasUsers] = useState({});
    const [productionLoad, setProductionLoad] = useState({});
    const [loadIndicatorsDatePicker, setLoadIndicatorsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ «Gestion des ressources» TAB STATES
    const [ressources, setRessources] = useState({});
    const [externalRessources, setExternalRessources] = useState({});
    const [externalRessourcesDatePicker, setExternalRessourcesDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ «Indicateurs de résultat» TAB STATES
    const [resultIndicators, setResultIndicators] = useState([]);
    const [resultIndicatorsDatePicker, setResultIndicatorsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ Tabs navigation
    const commercialIcon = <PieChartOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Ma production' ? '#DC3832' : '#646464'}
    />;
    const productionIcon = <FireOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    const ressourcesIcon = <TeamOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    const resultIcon = <LikeOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    
    const sections = [
        {name:"Commercial", icon: commercialIcon},
        {name: "Production", icon: productionIcon},
        {name: "Gestion des ressources", icon: ressourcesIcon},
        {name: "Indicateurs de résultat", icon: resultIcon}
    ];
    
    const location = useLocation();
    const navigate = useNavigate();
    
    // Redirection pour éviter le bug de la page blanche
    useEffect(() => {
        if (location.pathname !== '/dashboard/das') {
            navigate('/dashboard/das');
        }
    }, []);

    // Permet d'éviter la persistance des states filters une fois de retour à la page principale
    useEffect(() => {
        if (location.pathname === '/dashboard/das') {
            if (specificationsToStudyFilter) setSpecificationsToStudyFilter('');

            if(proposalsBeingWrittenFilter) setProposalsBeingWrittenFilter('');

            if(proposalsPendingFilter) setProposalsPendingFilter('');

            if(currentDealsFilter) setCurrentDealsFilter('');

            if(missionsFilter) setMissionsFilter('');
        }
    }, [location.pathname]);

    useEffect(() => {
        (async () => {
            // ↓ «Indicateurs d‘activité» data
            const productionActivityResult = await DashboardActions.CodevActions.getProductionActivity(userData, "");

            if (productionActivityResult.success) {
                setProductionActivity(productionActivityResult.data);
            }
            else {
                notify("Une erreur est survenue lors de la récuperation des indicateurs d'activité de production");
            }
        })();
    }, [])

    const handleGetSpecificationsToStudyData = async (page) => {
        setSpecificationsToStudyTableLoading(true);

        if(!dasKey) return;

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${specificationsToStudyPaginationMetadata.pageSize}&display=${userData.userProfileName}&das=${dasKey}&status=150&${specificationsToStudyFilter}`,
            page
        );
    
        if (result.success) {
            setSpecificationsToStudy(result.data);
            setSpecificationsToStudyPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (specificationsToStudyPaginationMetadata.totalItems !== result.metadata.count) {
                setSpecificationsToStudyPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setSpecificationsToStudyTableLoading(false);
    };

    const handleGetProposalsBeingWrittenData = async (page) => {
        setProposalsBeingWrittenTableLoading(true);
       
        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${proposalsBeingWrittenPaginationMetadata.pageSize}&display=${userData.userProfileName}&status=100&status=200&das=${dasKey}&${proposalsBeingWrittenFilter}`,
            page
        );
    
        if (result.success) {
            setProposalsBeingWritten(result.data);
            setProposalsBeingWrittenPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (proposalsBeingWrittenPaginationMetadata.totalItems !== result.metadata.count) {
                setProposalsBeingWrittenPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setProposalsBeingWrittenTableLoading(false);
    };

    const handleGetProposalsPendingData = async (page) => {
        setProposalsPendingTableLoading(true);

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${proposalsPendingPaginationMetadata.pageSize}&status=300&das=${dasKey}&${proposalsPendingFilter}`,
            page
        );
    
        if (result.success) {
            setProposalsPending(result.data);
            setProposalsPendingPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (proposalsPendingPaginationMetadata.totalItems !== result.metadata.count) {
                setProposalsPendingPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setProposalsPendingTableLoading(false);
    };

    const handleGetCurrentDealsData = async (page) => {
        setCurrentDealsTableLoading(true);

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${currentDealsPaginationMetadata.pageSize}&status=500&das=${dasKey}&${currentDealsFilter}`,
            page
        );
    
        if (result.success) {
            setCurrentDeals(result.data);
            setCurrentDealsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (currentDealsPaginationMetadata.totalItems !== result.metadata.count) {
                setCurrentDealsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setCurrentDealsTableLoading(false);
    };

    const handleFetchMissionsData = async (page) => {
        setMissionTableLoading(true);

        const result = await MissionActions.getAllMissions(
            userData,
            `&page_size=${missionsPaginationMetadata.pageSize}&status=1&deal_status=500&type=business&das=${dasKey}&${missionsFilter}`,
            page
        );

        if (result.success) {
            setMissions(result.data);
            setMissionsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (missionsPaginationMetadata.totalItems !== result.metadata.count) {
                setMissionsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setMissionTableLoading(false);
    };

    // useEffets that fetch data for tables
    useEffect(() => {
        handleGetSpecificationsToStudyData(1);
    }, [specificationsToStudyFilter]);

    useEffect(() => {
        handleGetProposalsBeingWrittenData(1);
    }, [proposalsBeingWrittenFilter]);

    useEffect(() => {
        handleGetProposalsPendingData(1);
    }, [proposalsPendingFilter]);

    useEffect(() => {
        handleGetCurrentDealsData(1);
    }, [currentDealsFilter]);
   
    useEffect(() => {
        handleFetchMissionsData(1);
    }, [missionsFilter]);

    useEffect(() => {
        // reset filters when dasKey changes
        setSpecificationsToStudyFilter('');
        setProposalsBeingWrittenFilter('');
        setProposalsPendingFilter('');
        setCurrentDealsFilter('');
        setMissionsFilter('');

        // reset pagination when dasKey changes
        handleGetSpecificationsToStudyData(1);
        handleGetProposalsBeingWrittenData(1);
        handleGetProposalsPendingData(1);
        handleGetCurrentDealsData(1);
        handleFetchMissionsData(1);
    }, [dasKey]);

    // ↓ «Commercial» tab get data
    useEffect(() => {
        getCommercialTabData(
            userData,
            notify,
            setLoading,
            setSalesActivity,
            commercialBalanceDatePicker,
            setSalesOverview
        );
    }, [])

    // ↓ «Production» tab get data
    useEffect(() => {
        getProductionTabData(
            userData,
            notify,
            setDasUsers,
            loadIndicatorsDatePicker,
            setProductionLoad,
            setProductionLoading
        );
    }, [])

    // ↓ «Gestion des ressources» tab get data
    useEffect(() => {
        getResourceManagementTabData(
            userData,
            notify,
            setRessources,
            externalRessourcesDatePicker,
            setExternalRessources
        );
    }, [])

    // ↓ «Indicateurs de résultat» tab get data
    useEffect(() => {
        getResultIndicatorsData(
            userData,
            notify,
            resultIndicatorsDatePicker,
            setResultIndicators
        );
    }, []);
    
    const handleRefreshMissionsData = async (removal = false) => {
        const checkedPage = (missions.length === 1 && missionsPaginationMetadata.currentPage > 1 && removal) ?
            missionsPaginationMetadata.currentPage - 1 :
            missionsPaginationMetadata.currentPage;
        
        await handleFetchMissionsData(checkedPage);
    };

    // ↓ Ce useEffect permet de réafficher la table des missions au retour de la page de détails mission
    useEffect(() => {
        if (location.pathname === '/dashboard/das/current-missions') {
            setDisplayedSection('Production');
        }
    }, []);

    useEffect(() => {
        handleGetProposalsPendingData(1);
    }, [commercialBalanceDatePicker]);

    const filterDealList = async (query, type) => {
        if (type === 'specificationsToStudy') {
            setSpecificationsToStudyFilter(query);
        }
        else if (type === 'proposalsBeingWritten') {
            setProposalsBeingWrittenFilter(query);
        }
        else if (type === 'proposalsPending') {
            setProposalsPendingFilter(query);
        }
        else if (type === 'currentDeals') {
            setCurrentDealsFilter(query);
        }
    };

    const filterMissionList = async (query) => {
        setMissionsFilter(query);
    };

    const handleMissionNavigation = async (dealId) => {
        const getDeal = await DealActions.getDealById(userData, dealId);
        
        if (getDeal.success) {

            const isSale = getDeal.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

            if (userData.role === 'CO' && getDeal.data.production_lead.id !== userData.userId && !isSale) {
                
                navigate(`/deals/${getDeal.data.id}/missions/${getDeal.data.missions.find((mission) => mission.ressource.id === userData.userId)?.id}`);

                return
            }

            else {
                navigate(`/deals/${dealId}`);
            }
        }
    };

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={!productionLoading ? loading : true}>
                <AdminDasTab
                    role={userData.role}
                    userId={userData.userId}
                    setDasKey={setDasKey}
                />
                <div className={`px-20 pb-32 ${userData.role === 'PA' ? 'pt-28' : 'pt-10'}`}>
                    <TabNavigator
                        setDisplayedSection={setDisplayedSection}
                        displayedSection={displayedSection}
                        sections={sections}
                        onClick={() => navigate('/dashboard/das')}
                    />
                    {
                        displayedSection === 'Commercial' && (
                            <Routes>
                                <Route path='/' element={
                                    <CommercialTab
                                        salesActivity={salesActivity}
                                        salesOverview={salesOverview}
                                        handleDatePickerChange={(e) => handleCommercialBalanceDatePickerChange(
                                            e,
                                            userData,
                                            notify,
                                            setLoading,
                                            setCommercialBalanceDatePicker,
                                            setSalesOverview
                                        )}
                                        datePicker={commercialBalanceDatePicker}
                                        dasKey={dasKey}
                                    />}
                                />
                                <Route path='specification-to-study' element={
                                    <SpecificationToStudyPage
                                        userData={userData}
                                        tableType='specificationsToStudy'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={specificationsToStudy}
                                        specificationsToStudyPaginationMetadata={specificationsToStudyPaginationMetadata}
                                        handleGetSpecificationsToStudyData={handleGetSpecificationsToStudyData}
                                        tableLoading={specificationsToStudyTableLoading}
                                        dasKey={dasKey}
                                    />
                                }/>
                                <Route path='proposals-being-written' element={
                                    <ProposalsBeingWrittenPage
                                        userData={userData}
                                        tableType='proposalsBeingWritten'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={proposalsBeingWritten}
                                        proposalsBeingWrittenPaginationMetadata={proposalsBeingWrittenPaginationMetadata}
                                        handleGetProposalsBeingWrittenData={handleGetProposalsBeingWrittenData}
                                        tableLoading={proposalsBeingWrittenTableLoading}
                                        dasKey={dasKey}
                                    />
                                }/>
                                <Route path='proposals-pending' element={
                                    <ProposalsPendingPage
                                        userData={userData}
                                        tableType='proposalsPending'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        data={proposalsPending}
                                        proposalsPendingPaginationMetadata={proposalsPendingPaginationMetadata}
                                        productionActivity={productionActivity}
                                        handleGetProposalsPendingData={handleGetProposalsPendingData}
                                        tableLoading={proposalsPendingTableLoading}
                                        dasKey={dasKey}
                                    />
                                }/>
                                <Route path='specifications-studied' element={
                                    <SpecificationsStudiedPage
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dasKey={dasKey}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                                <Route path='proposals-made' element={
                                    <ProposalsMadePage
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dasKey={dasKey}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                                <Route path='proposals-won' element={
                                    <ProposalsWonPage
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dasKey={dasKey}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                                <Route path='proposals-lost' element={
                                    <ProposalsLost
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dasKey={dasKey}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Production' && (
                            <Routes>
                                <Route path='/' element={
                                    <ProductionTab
                                        productionActivity={productionActivity}
                                        dasUsers={dasUsers}
                                        productionLoad={productionLoad}
                                        handleDatePickerChange={(e) => handleLoadIndicatorsDatePickerChange(
                                            e,
                                            userData,
                                            notify,
                                            setLoading,
                                            setLoadIndicatorsDatePicker,
                                            setDasUsers,
                                            setProductionLoad
                                        )}
                                        datePicker={loadIndicatorsDatePicker}
                                        dasKey={dasKey}
                                    />}
                                />

                                <Route path='current-deals' element={
                                    <CurrentDeals
                                        data={currentDeals}
                                        userData={userData}
                                        tableType='currentDeals'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        currentDealsPaginationMetadata={currentDealsPaginationMetadata}
                                        handleGetCurrentDealsData={handleGetCurrentDealsData}
                                        tableLoading={currentDealsTableLoading}
                                        dasKey={dasKey}
                                    />
                                }/>
                                <Route path='current-missions' element={
                                    <CurrentMissions
                                        filterMissionList={filterMissionList}
                                        das={dasKey}
                                        data={missions}
                                        userData={userData}
                                        refreshMissionData={handleRefreshMissionsData}
                                        missionsPaginationMetadata={missionsPaginationMetadata}
                                        handleFetchMissionsData={handleFetchMissionsData}
                                        tableLoading={missionsTableLoading}
                                        dasKey={dasKey}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Gestion des ressources' && (
                            <RessourceManagementsTab
                                ressources={ressources}
                                externalRessources={externalRessources}
                                handleDatePickerChange={(e) => handleExternalRessourcesDatePickerChange(
                                    e,
                                    userData,
                                    notify,
                                    setLoading,
                                    setExternalRessourcesDatePicker,
                                    setExternalRessources
                                )}
                                datePicker={externalRessourcesDatePicker}
                                dasKey={dasKey}
                            />
                        )
                    }
                    {
                        displayedSection === 'Indicateurs de résultat' && (
                            <ResultIndicatorsTab
                                resultIndicators={resultIndicators}
                                handleDatePickerChange={(e) => handleResultIndicatorsDatePickerChange(
                                    e,
                                    userData,
                                    notify,
                                    setLoading,
                                    setResultIndicatorsDatePicker,
                                    setResultIndicators
                                )}
                                datePicker={resultIndicatorsDatePicker}
                                dasKey={dasKey}
                            />
                        )
                    }
                </div>
            </PageLoader>
        </Layout>
    );
};

export default DasDashboardPage;
