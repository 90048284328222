// export const fetchSelectData = async ({action, search, page, setter, userData, filterOrFind}) => {
//     const result = await action(userData, search, page || null);
//     const hasNextPage = result.data.next ? true : false;
  
//     if (page === 1 || !page || search) {
//         let options = result.data.results;

//         if (filterOrFind) {
//             options = options.filter(filterOrFind); // Apply filter or find function to the options
//         }
//         setter({options, hasNextPage});
//     }
//     else {
//         let options = result.data.results;

//         if (filterOrFind) {
//             options = options.filter(filterOrFind); // Apply filter or find function to the options
//         }
//         setter(prevData => ({options: [...prevData.options, ...options], hasNextPage}));
//     }
// };

import { getProperty } from 'dot-prop';

export const fetchSelectData = async ({ action, search, page, setter, userData, filterOrFind, resultPath = 'data.results' }) => {
    
    const result = await action(userData, search, page);
    
    const hasNextPage = result.data?.next ? true : false;

    let options = getProperty(result, resultPath, []);

    if (filterOrFind) {
        options = options.filter(filterOrFind); // Apply filter or find function to the options
    }

    if (page === 1 || !page || search) {
        setter({ options, hasNextPage });
    }
    else {
        setter(prevData => ({ options: [...prevData.options, ...options], hasNextPage }));
    }
};
  