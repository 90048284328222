import {ErrorModel, ResultModel} from '../../models';
import {Dispatch, AnyAction} from '@reduxjs/toolkit';
import {LoggerService, UserService, DealDasService} from '../../services';
import {SET_USER_PROFILE} from '../../reducers/UserReducer';
import {SET_DAS} from '../../reducers/DasReducer';
import {SET_GLOBALS} from '../../reducers/GlobalsReducer';
import {ACTION_CODE} from './_actionCode';
const FUNCTION_CODE = 'LOG_IN';
import { GlobalsDao } from '../../dao';

export async function logIn(dispatch: Dispatch<AnyAction>, payload: Record<string, string>) {
    try {
        LoggerService.logInfo('Action - logIn - Start');
        const getUserToken = await UserService.getUserToken(payload);

        const getUserId = await UserService.getUserDetails(getUserToken.token);

        const getDealDas = await DealDasService.getAllDealDas({userToken: getUserToken.token}, '', 1);

        // const findUsersDas = getDealDas.results.filter((userDas: any) => userDas.manager.id === getUserId.id);

        const globals = await GlobalsDao.getAllGlobals(getUserToken.token);

        dispatch({type: SET_USER_PROFILE,
            payload: {
                userProfileName: payload.username,
                userToken: getUserToken.token,
                userId: getUserId.id,
                role: getUserId.user_class,
                name : getUserId.first_name + ' ' + getUserId.last_name
            }});

        dispatch({type: SET_DAS,
            payload: {
                das:    getDealDas.results.sort((a: any, b: any) => a.id - b.id)
            }});

        dispatch({type: SET_GLOBALS,
            payload: {
                vat: globals.default_vat_rate
            }});

        LoggerService.logInfo('Action - logIn - End - Success');

        return ResultModel.newSuccessResult(getUserToken);
    }
    catch (error) {
        
        LoggerService.logInfo('authenticateUser - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}

