import React from 'react';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import Popup from '../Popup';

const ConfirmationPopup = ({
    view,
    setView,
    title = '',
    subtitle = '',
    visibleDeclineButton = true,
    onDecline = () => setView(false),
    onConfirm = null,
    declineButtonText = 'Annuler',
    confirmButtonText = 'Confirmer',
    blueStyle = false
}) => {
    const handleConfirmAction = () => {
        if (onConfirm) {
            setView(false);
            onConfirm();
        }
    }

    return (
        <Popup view={view === 0 ? true : view} setView={setView}>
            <div id='skip' className='flex items-start text-xl font-bold' style={{fontFamily: 'DIN Condensed'}}>
                <ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14', marginRight: '16px'}}/>
                {title}
            </div>
            <div id='skip' className='flex items-start'>
                <div style={{width: '22px', marginRight: '16px'}}/>
                <div className='text-[14px] opacity-[0.8] mt-3'>{subtitle}</div>
            </div>
            <div id='skip' className='flex flex-row items-center justify-center mt-6'>
                {
                    visibleDeclineButton ?
                        <button
                            id='skip'
                            onClick={onDecline}
                            className='mx-1 h-9 w-44 rounded-md border transition-all bg-white text-[#262626] border-[#D9D9D9] hover:opacity-90'
                            style={{boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'}}
                        >
                            {declineButtonText}
                        </button>
                        : null
                }
                <button
                    id='skip'
                    onClick={handleConfirmAction}
                    className={`
                        mx-1 h-9 w-44 rounded-md border transition-all text-white border-white hover:opacity-90
                        ${blueStyle ? 'bg-gradient-to-br from-[#00ABE9] via-[#00ABAB] to-[#CED955]' : 'bg-gradient-to-r from-[#DC3832] via-[#E36D38] to-[#F0A841]'}
                    `}
                    style={{boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'}}
                >
                    {confirmButtonText}
                </button>
            </div>
        </Popup>
    )
}

export default ConfirmationPopup
