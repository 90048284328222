import {ScheduleFilled, FundFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle/LineTitle"
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard"

const Pilotage = ({ production }) => {

    if (Object.keys(production).length === 0) {
        return null;
    }
    
    const { lead } = production;

    const ScheduleIcon = <ScheduleFilled style={{ fontSize: '30px', color:'#e77e3b' }}/>;
    const FundIcon = <FundFilled style={{ fontSize: '30px', color: '#e77e3b' }}/>;

    return (
        <div className="my-10">
            <LineTitle title="Pilotage"/>

            <div className='flex justify-between mt-5'>
                <SimpleCard
                    width='w-[48%]'
                    title={lead.deals}
                    showLine={true}
                    lineColor='black'
                    background="white"
                    font="orange"
                    subTitle="Affaires pilotées"
                    iconComponent={ScheduleIcon}
                />

                <SimpleCard
                    width='w-[48%]'
                    title={lead.produced ? lead.produced.toFixed(2) : 0}
                    background="white"
                    font="orange"
                    lineColor='black'
                    subTitle="Jours de production pilotés"
                    iconComponent={FundIcon}
                />
            </div>
        </div>
    )
}

export default Pilotage