import axios from 'axios';

import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import {URL} from '../root';

const FUNCTION_CODE = 'GET_ALL_ACTIVITIES';

export async function getAllActivities(token: string, params: string, page: number) {
    try {
        const response = await axios.get(
            `${URL}/activities/?page=${page}${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${token}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des activités',
            error: error
        });
    }
}
