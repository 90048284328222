import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

import {MissionActions} from '../../../../actions';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard'
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput'
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const MisionNotesPopUp = ({data, userData, setView, refreshMissionData}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    // -- react hook form --
    const defaultValues = {
        notes: data.notes || ''
    };

    const {handleSubmit, control, formState: {isValid}} = useForm({defaultValues: defaultValues});

    const onSubmit = async (formData) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const dataUpdatedWithNotes = {
            ...data,
            notes: formData.notes
        }

        const result = await MissionActions.updateMissionById(userData, dataUpdatedWithNotes);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshMissionData();
            notify(data.notes ? 'La note à été modifiée' : 'La note à été créée');
            setView(false);
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard title='Nouvelle note' coloredButtonDisplayed={false}>
            <div className="flex justify-center px-4 pt-2 pb-6">
                <div className="w-full">
                    <Controller
                        name="notes"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir une note'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextAreaInput
                                label='notes'
                                onChange={onChange}
                                maxLength={100}
                                value={value}
                                error={error}
                                required={false}
                                placeholder="Tapez votre texte"
                                type="text"
                                height='h-24'
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type='white' content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content={data.notes ? 'Modifier la note' : 'Créer la note'}
                        loading={loading}
                        disabled={!isValid || disabled}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    )
}
export default MisionNotesPopUp;
