import {TrainingAttendancesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_TRAINING_ATTENDANCES';

export async function createTrainingAttendance(
    userData: Record<string, string>,
    newTrainingAttendances: Record<string, any>
) {

    try {
        return await TrainingAttendancesDao.createTrainingAttendance(userData.userToken, newTrainingAttendances);
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la création de suivi de stagiaire',
            error: error
        });
    }
}
