import {VehicleTypeDao} from '../../dao';
import {ErrorModel, VehicleTypeModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_VEHICLE_TYPES';

export async function getAllVehicleTypes(
    userData: Record<string, string>,
    params: string,
    page: number
) {

    try {
        const vehicleTypes =  await VehicleTypeDao.getAllVehicleTypes(userData.userToken, params, page);

        const vehicleTypesParsed = vehicleTypes.results.map((vehicleType: Record<string, any>) => VehicleTypeModel.parseVehicleType(vehicleType));

        return vehicleTypesParsed
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
