export const ressourcesDto = {
    from(ressourcesResult) {

        const getCurrentData = () => {
            let dasS = {};

            if (!ressourcesResult.current) {
                return dasS;
            }
            else {
                for (const key in ressourcesResult.current) {
                    dasS[key] = {
                        "Junior": {
                            "ressources": ressourcesResult.current?.[key]?.["Junior"]?.ressources ?? 0,
                            "etp": ressourcesResult.current?.[key]?.["Junior"]?.etp ?? 0
                        },
                        "Confirmé": {
                            "ressources": ressourcesResult.current?.[key]?.["Confirmé"]?.ressources ?? 0,
                            "etp": ressourcesResult.current?.[key]?.["Confirmé"]?.etp ?? 0
                        },
                        "Senior": {
                            "ressources": ressourcesResult.current?.[key]?.["Senior"]?.ressources ?? 0,
                            "etp": ressourcesResult.current?.[key]?.["Senior"]?.etp ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        const getProjectionData = () => {
            let dasS = {};

            if (!ressourcesResult.projections) {
                return dasS;
            }
            else {
                for (const key in ressourcesResult.projections) {
                    dasS[key] = {
                        "m1": {
                            "ressources": ressourcesResult.projections?.[key]?.m1?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m1?.etp ?? 0
                        },
                        "m2": {
                            "ressources": ressourcesResult.projections?.[key]?.m2?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m2?.etp ?? 0
                        },
                        "m3": {
                            "ressources": ressourcesResult.projections?.[key]?.m3?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m3?.etp ?? 0
                        },
                        "m4": {
                            "ressources": ressourcesResult.projections?.[key]?.m4?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m4?.etp ?? 0
                        },
                        "m5": {
                            "ressources": ressourcesResult.projections?.[key]?.m5?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m5?.etp ?? 0
                        },
                        "m6": {
                            "ressources": ressourcesResult.projections?.[key]?.m6?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m6?.etp ?? 0
                        },
                        "m7": {
                            "ressources": ressourcesResult.projections?.[key]?.m7?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m7?.etp ?? 0
                        },
                        "m8": {
                            "ressources": ressourcesResult.projections?.[key]?.m8?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m8?.etp ?? 0
                        },
                        "m9": {
                            "ressources": ressourcesResult.projections?.[key]?.m9?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m9?.etp ?? 0
                        },
                        "m10": {
                            "ressources": ressourcesResult.projections?.[key]?.m10?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m10?.etp ?? 0
                        },
                        "m11": {
                            "ressources": ressourcesResult.projections?.[key]?.m11?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m11?.etp ?? 0
                        },
                        "m12": {
                            "ressources": ressourcesResult.projections?.[key]?.m12?.ressource ?? 0,
                            "etp": ressourcesResult.projections?.[key]?.m12?.etp ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        const getLoadData = () => {
            let dasS = {};

            if (!ressourcesResult.load) {
                return dasS;
            }
            else {
                for (const key in ressourcesResult.load) {
                    dasS[key] = {
                        "m1": {
                            "available": ressourcesResult.load?.[key]?.m1?.available ?? 0,
                            "load": ressourcesResult.load?.[key]?.m1?.load ?? 0,
                            "potential_load": ressourcesResult.load?.[key]?.m1?.potential_load ?? 0
                        },
                        "m2": {
                            "available": ressourcesResult.load?.[key]?.m2?.available ?? 0,
                            "load": ressourcesResult.load?.[key]?.m2?.load ?? 0,
                            "potential_load": ressourcesResult.load?.[key]?.m2?.potential_load ?? 0
                        },
                        "m3": {
                            "available": ressourcesResult.load?.[key]?.m3?.available ?? 0,
                            "load": ressourcesResult.load?.[key]?.m3?.load ?? 0,
                            "potential_load": ressourcesResult.load?.[key]?.m3?.potential_load ?? 0
                        },
                        "q1": {
                            "available": ressourcesResult.load?.[key]?.q1?.available ?? 0,
                            "load": ressourcesResult.load?.[key]?.q1?.load ?? 0,
                            "potential_load": ressourcesResult.load?.[key]?.q1?.potential_load ?? 0
                        },
                        "s1": {
                            "available": ressourcesResult.load?.[key]?.s1?.available ?? 0,
                            "load": ressourcesResult.load?.[key]?.s1?.load ?? 0,
                            "potential_load": ressourcesResult.load?.[key]?.s1?.potential_load ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "current": getCurrentData(),
                "projection": getProjectionData(),
                "load": getLoadData(),
                "support": {
                    "management": ressourcesResult.support?.management ?? 0,
                    "support": ressourcesResult.support?.support ?? 0
                }
            }
        );
    }
}
