import {ACTION_CODE} from './_actionCode';
import {ExpenseService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_EXPENSE';

export async function updateExpense(
    userData: Record<string, any>,
    expenseId: string,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateExpense - Start');

        const updateExpenseResult =
            await ExpenseService.updateExpense(userData, expenseId, payloadUpdate);

        LoggerService.logInfo('Action - updateExpense - End - Success');

        return ResultModel.newSuccessResult(updateExpenseResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateExpense - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'un frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
