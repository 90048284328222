import TradeBalanceCards from "../../../components/commercialTab/TradeBalanceCards";
import {aggregateSalesOverview, aggregateSalesOverviewTotal} from "../../../functions/aggregateSalesOverview";

export const tradeBalanceTabConfig = (salesOverview) => {

    const total = aggregateSalesOverviewTotal(salesOverview);

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <TradeBalanceCards
            doneSeulCount={total.DoneSeulCount}
            doneMandataireCount={total.DoneMandataireCount}
            doneCotraitanceCount={total.DoneCotraitanceCount}

            doneSeulDays={total.DoneSeulDays}
            doneMandataireDays={total.DoneMandataireDays}
            doneCotraitanceDays={total.DoneCotraitanceDays}

            doneSeulValue={total.DoneSeulValue}
            doneMandataireValue={total.DoneMandataireValue}
            doneCotraitanceValue={total.DoneCotraitanceValue}

            wonSeulCount={total.WonSeulCount}
            wonMandataireCount={total.WonMandataireCount}
            wonCotraitanceCount={total.WonCotraitanceCount}
            
            wonSeulDays={total.WonSeulDays}
            wonMandataireDays={total.WonMandataireDays}
            wonCotraitanceDays={total.WonCotraitanceDays}

            wonSeulValue={total.WonSeulValue}
            wonMandataireValue={total.WonMandataireValue}
            wonCotraitanceValue={total.WonCotraitanceValue}

            lostSeulCount={total.LostSeulCount}
            lostMandataireCount={total.LostMandataireCount}
            lostCotraitanceCount={total.LostCotraitanceCount}

            lostSeulDays={total.LostSeulDays}
            lostMandataireDays={total.LostMandataireDays}
            lostCotraitanceDays={total.LostCotraitanceDays}

            lostSeulValue={total.LostSeulValue}
            lostMandataireValue={total.LostMandataireValue}
            lostCotraitanceValue={total.LostCotraitanceValue}

            successRateSeulCount={total.SuccessRateSeulCount}
            successRateMandataireCount={total.SuccessRateMandataireCount}
            successRateCotraitanceCount={total.SuccessRateCotraitanceCount}

            successRateSeulDays={total.SuccessRateSeulDays}
            successRateMandataireDays={total.SuccessRateMandataireDays}
            successRateCotraitanceDays={total.SuccessRateCotraitanceDays}

            successRateSeulValue={total.SuccessRateSeulValue}
            successRateMandataireValue={total.SuccessRateMandataireValue}
            successRateCotraitanceValue={total.SuccessRateCotraitanceValue}
        />
    }];
    
    for (const key in salesOverview) {
        const result = aggregateSalesOverview(salesOverview, key);
        
        const item = {
            id: key,
            label: key,
            component:  <TradeBalanceCards
                doneSeulCount={result.DoneSeulCount}
                doneMandataireCount={result.DoneMandataireCount}
                doneCotraitanceCount={result.DoneCotraitanceCount}

                doneSeulDays={result.DoneSeulDays}
                doneMandataireDays={result.DoneMandataireDays}
                doneCotraitanceDays={result.DoneCotraitanceDays}

                doneSeulValue={result.DoneSeulValue}
                doneMandataireValue={result.DoneMandataireValue}
                doneCotraitanceValue={result.DoneCotraitanceValue}

                wonSeulCount={salesOverview[key].won.Seul.count}
                wonMandataireCount={salesOverview[key].won.Mandataire.count}
                wonCotraitanceCount={salesOverview[key].won.Cotraitance.count}

                wonSeulDays={salesOverview[key].won.Seul.days}
                wonMandataireDays={salesOverview[key].won.Mandataire.days}
                wonCotraitanceDays={salesOverview[key].won.Cotraitance.days}

                wonSeulValue={salesOverview[key].won.Seul.value}
                wonMandataireValue={salesOverview[key].won.Mandataire.value}
                wonCotraitanceValue={salesOverview[key].won.Cotraitance.value}

                lostSeulCount={salesOverview[key].lost.Seul.count}
                lostMandataireCount={salesOverview[key].lost.Mandataire.count}
                lostCotraitanceCount={salesOverview[key].lost.Cotraitance.count}

                lostSeulDays={salesOverview[key].lost.Seul.days}
                lostMandataireDays={salesOverview[key].lost.Mandataire.days}
                lostCotraitanceDays={salesOverview[key].lost.Cotraitance.days}

                lostSeulValue={salesOverview[key].lost.Seul.value}
                lostMandataireValue={salesOverview[key].lost.Mandataire.value}
                lostCotraitanceValue={salesOverview[key].lost.Cotraitance.value}

                successRateSeulCount={result.SuccessRateSeulCount}
                successRateMandataireCount={result.SuccessRateMandataireCount}
                successRateCotraitanceCount={result.SuccessRateCotraitanceCount}

                successRateSeulDays={result.SuccessRateSeulDays}
                successRateMandataireDays={result.SuccessRateMandataireDays}
                successRateCotraitanceDays={result.SuccessRateCotraitanceDays}

                successRateSeulValue={result.SuccessRateSeulValue}
                successRateMandataireValue={result.SuccessRateMandataireValue}
                successRateCotraitanceValue={result.SuccessRateCotraitanceValue}

            />

        }
    
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
