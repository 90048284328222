import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ProductionsPage from '../pages/productionsPage/ProductionsPage';

function ProductionsRoute() {
    return (
        <Routes>
            <Route path='/' element={<ProductionsPage/>}/>
        </Routes>
    );
}

export default ProductionsRoute;
