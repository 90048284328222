import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GET_ALL_ADMIN_DASHBOARD_DAILY_RATE';

export async function getDailyRate(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getDailyRate - Start');

        const getDailyRate = await DashboardService.AdminService.getDailyRate(userData, params);

        LoggerService.logInfo('Action - getDailyRate - End - Success');

        return ResultModel.newSuccessResult(getDailyRate);
    }
    catch (error) {
        LoggerService.logInfo('Action - getDailyRate - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
