export const getRangeTitleDate = () => {

    const monthNames = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    const currentDate = new Date();

    const currentMonth = currentDate.getMonth();

    const currentYear = currentDate.getFullYear();

    const nextMonth1 = currentMonth + 1;
    const nextMonth1Year = nextMonth1 < 12 ? currentYear : currentYear + 1;
    const nextMonth1Str = `${monthNames[nextMonth1 % 12]} ${nextMonth1Year}`;

    const nextMonth2 = currentMonth + 2;
    const nextMonth2Year = nextMonth2 < 12 ? currentYear : currentYear + 1;
    const nextMonth2Str = `${monthNames[nextMonth2 % 12]} ${nextMonth2Year}`;

    let nextQuarterStart, nextQuarterEnd, nextQuarterEndYear, nextQuarterStartYear, nextQuarterStartStr, nextQuarterEndStr;

    if (currentMonth === 0) {
        nextQuarterStart = 3;
        nextQuarterEnd = 5;
    }
    else if (currentMonth === 1) {
        nextQuarterStart = 4;
        nextQuarterEnd = 6;
    }
    else if (currentMonth === 2) {
        nextQuarterStart = 5;
        nextQuarterEnd = 7;
    }
    else if (currentMonth === 3) {
        nextQuarterStart = 6;
        nextQuarterEnd = 8;
    }
    else if (currentMonth === 4) {
        nextQuarterStart = 7;
        nextQuarterEnd = 9;
    }
    else if (currentMonth === 5) {
        nextQuarterStart = 8;
        nextQuarterEnd = 10;
    }
    else if (currentMonth === 6) {
        nextQuarterStart = 9;
        nextQuarterEnd = 11;
    }
    else if (currentMonth === 7) {
        nextQuarterStart = 10;
        nextQuarterEnd = 0;
    }
    else if (currentMonth === 8) {
        nextQuarterStart = 11;
        nextQuarterEnd = 1;
    }
    else if (currentMonth === 9) {
        nextQuarterStart = 0;
        nextQuarterEnd = 2;
    }
    else if (currentMonth === 10) {
        nextQuarterStart = 1;
        nextQuarterEnd = 3;
    }
    else if (currentMonth === 11) {
        nextQuarterStart = 2;
        nextQuarterEnd = 4;
    }

    nextQuarterStartYear = currentMonth < 9 ? currentYear : currentYear + 1;
    nextQuarterEndYear = currentMonth > 6 ? currentYear + 1 : currentYear;

    nextQuarterStartStr = `${monthNames[nextQuarterStart]} ${nextQuarterStartYear === nextQuarterEndYear ? '' : nextQuarterStartYear}`;
    nextQuarterEndStr = `${monthNames[nextQuarterEnd]} ${nextQuarterEndYear}`;

    const nextSemesterStart = currentMonth + 6;
    const nextSemesterEnd = nextSemesterStart + 5;
    const nextSemesterEndYear = nextSemesterEnd < 11 ? currentYear : currentYear + 1;
    const nextSemesterStartStr = `${monthNames[nextSemesterStart % 12]} ${currentMonth >= 6 ? '' : currentMonth === 0 ? '' : currentYear}`;
    const nextSemesterEndStr = `${monthNames[nextSemesterEnd % 12]} ${currentMonth === 0 ? currentYear : nextSemesterEndYear}`;

    return {
        nextMonth1Str,
        nextMonth2Str,
        nextQuarterStartStr,
        nextQuarterEndStr,
        nextSemesterStartStr,
        nextSemesterEndStr,
        monthNames,
        currentMonth,
        currentYear
    }
}
