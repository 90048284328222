const roadStepInvoicesConfig = (currentStatus, invoiceType, credit) => {
    const items = [
        {
            title: 'Prévisionnelle',
            disabled: (currentStatus === 0 || currentStatus === 50) && !credit ? false : true
        },
        {
            title: 'À émettre',
            disabled: (currentStatus === 0 || currentStatus === 50 || currentStatus === 100)  && !credit ? false : true
        },
        {
            title: 'À valider',
            disabled: (currentStatus === 50 || currentStatus === 100 || currentStatus === 200) && !credit ? false : true
        },
        {
            title: 'Validée',
            disabled: (currentStatus === 100 || currentStatus === 200) && !credit ? false : true
        },
        {
            title: credit ? 'Avoir' : 'Émise',
            disabled: (currentStatus === 200 || currentStatus === 300) && !credit ? false : true
        },
        {
            title: 'Partiellement recouvrée',
            disabled: (currentStatus === 300 || currentStatus === 400) && !credit ? false : true
        },
        {
            title: 'Payée',
            disabled: (currentStatus === 300 || currentStatus === 400 || currentStatus === 500) && !credit ? false : true
        },
        {
            title: 'Annulée',
            disabled: currentStatus !== 600 && !credit ? false : true
        }
    ];

    let filteredItems;
    
    if (currentStatus < 300 || invoiceType !== 1) {
        filteredItems = items.filter((item) => item.title !== 'Annulée');
    }
    else {
        filteredItems = items;
    }

    return {
        color: 'orange',
        width: 'w-[85%]',
        items: filteredItems
    }
};

export default roadStepInvoicesConfig;
