import { FileAddFilled, HourglassFilled, RocketFilled, ScheduleFilled, ClockCircleFilled, FolderOpenFilled } from "@ant-design/icons";

import { dealsTableConfig } from "./dealsTableConfig";
import { missionsTableConfig } from "./missionTableConfig";
import { closedMissionsToValidateTableConfig } from './closedMissionToValidateTableConfig';
import { referenceTableConfig } from "./referenceTableConfig";
import { invoicesConfig } from './invoiceTableConfig'

export const notificationsCardsConfig = () => {
    const FileAddIcon = <FileAddFilled style={{ color: '#fff', fontSize: '30px'}}/>;
    const HourglassIcon = <HourglassFilled style={{ color: '#fff', fontSize: '30px'}}/>;
    const RocketIcon = <RocketFilled style={{ color: '#fff', fontSize: '30px'}}/>;
    const ScheduleIcon = <ScheduleFilled style={{ color: '#fff', fontSize: '30px'}}/>;
    const ClockCircleIcon = <ClockCircleFilled style={{ color: '#fff', fontSize: '30px'}}/>;
    const FolderOpenIcon = <FolderOpenFilled style={{ color: '#fff', fontSize: '30px'}}/>;

    return [
        {
            title: 'Référence à rédiger',
            pluralTitle: 'Références à rédiger',
            key: 'references_to_write',
            icon: FileAddIcon,
            tableConfig: (handleRedirect) => referenceTableConfig(handleRedirect, 'references_to_write')
        },
        {
            title: 'Affaire censées être terminées',
            pluralTitle: 'Affaires censées être terminées',
            key: 'deals_to_finish',
            icon: HourglassIcon,
            tableConfig: (handleRedirect) => dealsTableConfig(handleRedirect, 'deals_to_finish')
        },
        {
            title: 'Affaire s’achevant dans le mois',
            pluralTitle: 'Affaires s’achevant dans le mois',
            key: 'deal_to_finish_soon',
            icon: ScheduleIcon,
            tableConfig: (handleRedirect) => dealsTableConfig(handleRedirect, 'deal_to_finish_soon')
        },
        {
            title: 'Mission censées être terminées',
            pluralTitle: 'Mission censées être terminées',
            key: 'mission_to_finish',
            icon: RocketIcon,
            tableConfig: (handleRedirect) => closedMissionsToValidateTableConfig(handleRedirect, 'mission_to_finish')
        },
        {
            title: 'Mission s’achevant dans le mois',
            pluralTitle: 'Missions s’achevant dans le mois',
            key: 'missions_to_finish_soon',
            icon: RocketIcon,
            tableConfig: (handleRedirect) => missionsTableConfig(handleRedirect, 'missions_to_finish_soon')
        },
        {
            title: 'Facture à valider',
            pluralTitle: 'Factures à valider',
            key: 'invoices_to_validate',
            icon: ClockCircleIcon,
            tableConfig: (handleRedirect) => invoicesConfig(handleRedirect, 'invoices_to_validate')
        },
        {
            title: 'Facture à émettre dans le mois',
            pluralTitle: 'Factures à émettre dans le mois',
            key: 'invoices_to_send_soon',
            icon: FolderOpenIcon,
            tableConfig: (handleRedirect) => invoicesConfig(handleRedirect, 'invoices_to_send_soon')
        },
        {
            title: 'Facture en attente de règlement',
            pluralTitle: 'Factures en attente de règlement',
            key: 'invoices_to_pay',
            icon: HourglassIcon,
            tableConfig: (handleRedirect) => invoicesConfig(handleRedirect, 'invoices_to_pay')
        },
        {
            title: 'Facture en retard de règlement',
            pluralTitle: 'Factures en retard de règlement',
            key: 'invoices_late_payment',
            icon: HourglassIcon,
            tableConfig: (handleRedirect) => invoicesConfig(handleRedirect, 'invoices_late_payment')
        },
        {
            title: 'Facture censées être émises',
            pluralTitle: 'Factures censées être émises',
            key: 'invoices_to_send',
            icon: HourglassIcon,
            tableConfig: (handleRedirect) => invoicesConfig(handleRedirect, 'invoices_to_send')
        },
        {
            title: 'Enquete de satisfaction',
            pluralTitle: 'Enquetes de satisfaction',
            key: 'surveys',
            icon: HourglassIcon,
            tableConfig: (handleRedirect) => dealsTableConfig(handleRedirect, 'surveys')
        }
    ]
}