const roadStepDealsConfig = (isInternal, isTraining, dealData) => {
    
    const cannotBeModified = dealData.status === 900;
    const won = dealData.status >= 500 && !dealData.loss_motive;
    
    const lost = dealData.status >= 400 && dealData.loss_motive;

    return (
        {
            color: isTraining ? 'blue' : 'orange',
            width: isInternal ? 'w-[40%]' : 'w-[85%]',
            items: isInternal ?
                [
                    { title: 'En cours', disabled: cannotBeModified },
                    { title: 'Terminée', disabled: cannotBeModified }
                ]
                :
                [
                    { title: 'CdC demandé', disabled: cannotBeModified },
                    { title: 'Étude CdC', disabled: cannotBeModified },
                    { title: dealData.status <= 150 ? "Résultat d'analyse" : "Réponse en cours", disabled: cannotBeModified },
                    { title: 'Offre remise', disabled: cannotBeModified },
                    { title: won ? 'Gagnée' : lost ? 'Perdue' : 'Résultat', disabled: cannotBeModified },
                    { title: 'Produite', disabled: cannotBeModified },
                    { title: 'Facturée', disabled: cannotBeModified },
                    { title: 'Close', disabled: cannotBeModified }
                ]
        }
    );
}

export default roadStepDealsConfig;
