import {NavLink} from 'react-router-dom';

import arrowDownMenu from './../../../assets/icons/navbar/arrow-down.png';

const DashboardsDropdown = ({role}) => {
    const dashboardDropElements = role === 'PA' ?
        [
            {title: 'Dirigeant', to: 'admin'},
            {title: 'Consultant', to: 'consultant'},
            {title: 'DAS', to: 'das'},
            {title: 'Office Manager', to: 'office-manager'},
            {title: 'CODEV', to: 'codev'}
        ] :
        role === 'MA' ?
            [
                {title: 'Consultant', to: 'consultant'},
                {title: 'DAS', to: 'das'},
                {title: 'CODEV', to: 'codev'}
            ] :
            role === 'OM' ?
                [
                    {title: 'Consultant', to: 'consultant'},
                    {title: 'Office Manager', to: 'office-manager'}
                ] :
                []

    return (
        <>
            {
                role === 'CO' ?
                    <li>
                        <NavLink
                            to='/dashboard/consultant'
                            className={(link) => {
                                return (
                                    `${link.isActive ? 'text-gradient' : 'text-[#212121] hover:underline hover:underline-offset-4'}`
                                );
                            }}>
                            Tableau de bord
                        </NavLink>
                    </li> :
                    <ul className="relative cursor-pointer group">
                        <p className="flex flex-row items-center mr-4 text-[#212121]">
                        Tableau de bord
                            <img className="w-3 ml-2" src={arrowDownMenu} alt=""/>
                        </p>
                        <div className={'absolute w-44 top-6 -right-5 bg-white rounded-md shadow-lg z-[51]'}>
                            <div className="hidden py-1 hover:block group-hover:block">
                                {
                                    dashboardDropElements.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <NavLink to={`/dashboard/${item.to}`} className={(link) => {
                                                    return (
                                                        `block py-2 pl-2 pr-2 text-sm
                                                        ${link.isActive ?
                                                            'bg-[#f9e2d7] border-r-2 text-[#E36D38] border-[#E36D38]' :
                                                            'text-[#212121] hover:bg-[#f9e2d7] hover:border-r-2 hover:text-[#E36D38] hover:border-[#E36D38]'
                                                        }`
                                                    );
                                                }}>
                                                    {item.title}
                                                </NavLink>
                                            </li>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </ul>
            }
        </>
    )
}

export default DashboardsDropdown;
