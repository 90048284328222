import {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PieChartOutlined, FireOutlined, TeamOutlined, LikeOutlined} from '@ant-design/icons';

import Layout from '../../components/layout/Layout';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import TabNavigator from '../../components/ui/navigation/TabNavigator';
import InvoiceAndPayment from './components/invoiceAndPayment/InvoiceAndPayment';
import Production from './components/production/Production';
import ManagementParameters from './components/managementParameters/ManagementParameters';
import InvoicesAwaitingPaymentPage from './InvoicesAwaitingPaymentPage';
import ForecastedInvoicesPage from './ForecastedInvoicesPage';
import PendingInvoicesPage from './PendingInvoicesPage';
import PayableInvoicesPage from './PayableInvoicesPage';
import CurrentExpenseReportsPage from './CurrentExpenseReportsPage';
import PayableExpenseReportsPage from './PayableExpenseReportsPage';
import StateTab from './components/StateTab';
import {
    getProductionData,
    handleGrossMarginProducedDatePickerDateChange,
    handleForecastGrossMarginDatePickerDateChange
} from './functions/getProductionData';
import {
    getManagementParametersData,
    handleMissionsProfitabilityDatePickerChange,
    handleConsultantContributionsDatePickerChange,
    handleCostsDatePickerChange,
    handleContractorsDatePickerChange,
    handleExpensesDatePickerChange,
    handlePaymentTermsDatePickerChange
} from './functions/getManagementParametersData';
import {firstDayYear, lastDayYear, standardFormat} from '../../utils';
import {
    SubsidiaryActions,
    InvoiceActions,
    ContractorInvoiceActions,
    ExpenseReportActions,
    DashboardActions
} from '../../actions';

const AdminDashboardPage = () => {
    // --- States -> «Factures et règlements» ---
    // ------------------------------------------
    // Main page
    // ---------
    const [sentInvoicesData, setSentInvoicesData] = useState({});
    const [draftInvoicesData, setDraftInvoicesData] = useState({});
    const [pendingContractorInvoicesData, setPendingContractorInvoicesData] = useState({});
    const [toPayContractorInvoicesData, setToPayContractorInvoicesData] = useState({});
    const [currentExpenseReportsData, setCurrentExpenseReportsData] = useState({});
    const [toPayExpenseReportsData, setPayableExpenseReportsData] = useState({});
    // Table pages
    //------------
    // Table -> «Factures en attente de paiement»
    const [invoicesAwaitingPayment, setInvoicesAwaitingPayment] = useState([]);
    const [invoicesAwaitingPaymentPaginationMetadata, setInvoicesAwaitingPaymentPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [invoicesAwaitingPaymentFilterQuery, setInvoicesAwaitingPaymentFilterQuery] = useState('');
    const [invoicesAwaitingPaymentTableLoading, setInvoicesAwaitingPaymentTableLoading] = useState(false);

    // Table -> «Factures prévisionnelles»
    const [forecastInvoices, setForecastInvoices] = useState([]);
    const [forecastInvoicesPaginationMetadata, setForecastInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [forecastInvoicesFilterQuery, setForecastInvoicesFilterQuery] = useState('');
    const [forecastInvoicesTableLoading, setForecastInvoicesTableLoading] = useState(false);

    // Table -> «Factures fournisseurs en attente»
    const [pendingContractorInvoices, setPendingContractorInvoices] = useState([]);
    const [pendingContractorInvoicesPaginationMetadata, setPendingContractorInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [pendingContractorInvoicesFilterQuery, setPendingContractorInvoicesFilterQuery] = useState('');
    const [pendingContractorInvoicesTableLoading, setPendingContractorInvoicesTableLoading] = useState(false);

    // Table -> «Factures fournisseurs à payer»
    const [toPayContractorInvoices, setToPayContractorInvoices] = useState([]);
    const [toPayContractorInvoicesPaginationMetadata, setToPayContractorInvoicesPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [toPayContractorInvoicesFilterQuery, setToPayContractorInvoicesFilterQuery] = useState('');
    const [toPayContractorInvoicesTableLoading, setToPayContractorInvoicesTableLoading] = useState(false);

    // Table -> «Notes de frais en cours»
    const [currentExpenseReports, setCurrentExpenseReports] = useState([]);
    const [currentExpenseReportsPaginationMetadata, setCurrentExpenseReportsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [currentExpenseReportsFilterQuery, setCurrentExpenseReportsFilterQuery] = useState('');
    const [currentExpenseReportsTableLoading, setCurrentExpenseReportsTableLoading] = useState(false);

    // Table -> «Notes de frais à payer»
    const [toPayExpenseReports, setToPayExpenseReports] = useState([]);
    const [toPayExpenseReportsPaginationMetadata, setToPayExpenseReportsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [toPayExpenseReportsFilterQuery, setToPayExpenseReportsFilterQuery] = useState('');
    const [toPayExpenseReportsTableLoading, setToPayExpenseReportsTableLoading] = useState(false);

    //---
    const [subsidiariesDisplayedSection, setSubsidiariesDisplayedSection] = useState(null);
    const [currentSubsidiary, setCurrentSubsidiary] = useState(null);

    // «Production» STATES
    const [grossMarginProducedData, setGrossMarginProducedData] = useState({});
    const [forecastGrossMarginData, setForecastGrossMarginData] = useState({});
    const [dailyRateData, setDailyRateData] = useState({});
    const [grossMarginProducedDatePicker, setGrossMarginProducedDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [forecastGrossMarginDatePicker, setForecastGrossMarginDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // «Paramètres de gestion» STATES
    const [missionsProfitabilityData, setMissionsProfitabilityData] = useState({});
    const [missionsProfitabilityDatePicker, setMissionsProfitabilityDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [consultantContributionsData, setConsultantContributionsData] = useState({});
    const [consultantContributionsDatePicker, setConsultantContributionsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [costsData, setCostsData] = useState({});
    const [costsDatePicker, setCostsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [contractorsData, setContractorsData] = useState({});
    const [contractorsDatePicker, setContractorsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [expensesData, setExpensesData] = useState({});
    const [expensesDatePicker, setExpensesDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });
    const [paymentTermsData, setPaymentTermsData] = useState({});
    const [paymentTermsDatePicker, setPaymentTermsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // OTHER STATES
    const [loading, setLoading] = useState(true);
    const [displayedSection, setDisplayedSection] = useState('Factures et règlements');
    const [subsidiaries, setSubsidiaries] = useState([]);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // TAB NAVIGATOR ICONS
    const invoiceIcon = <PieChartOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Ma production' ? '#DC3832' : '#646464'}
    />;
    const fireIcon = <FireOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;
    const teamIcon = <TeamOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Ma production' ? '#DC3832' : '#646464'}
    />;
    const likeIcon = <LikeOutlined
        style={{ fontSize: '13px' }}
        twoToneColor={displayedSection === 'Mon bilan d’activité' ? '#DC3832' : '#646464'}
    />;

    const sections = [
        {name:"Factures et règlements", icon: invoiceIcon},
        {name: "Production", icon: fireIcon},
        {name:"Paramètres de gestion", icon: teamIcon},
        {name: "Etats", icon: likeIcon}
    ];

    const location = useLocation();
    const navigate = useNavigate();
    
    // Redirection pour éviter le bug de la page blanche
    useEffect(() => {
        if (location.pathname !== '/dashboard/admin') {
            navigate('/dashboard/admin');
        }
    }, []);

    // Permet d'éviter la persistance des states filters une fois de retour à la page principale
    useEffect(() => {
        if (location.pathname === '/dashboard/admin') {
            if (invoicesAwaitingPaymentFilterQuery) setInvoicesAwaitingPaymentFilterQuery('');

            if(forecastInvoicesFilterQuery) setForecastInvoicesFilterQuery('');

            if(pendingContractorInvoicesFilterQuery) setPendingContractorInvoicesFilterQuery('');

            if(toPayContractorInvoicesFilterQuery) setToPayContractorInvoicesFilterQuery('');

            if(currentExpenseReportsFilterQuery) setCurrentExpenseReportsFilterQuery('');

            if(toPayExpenseReportsFilterQuery) setToPayExpenseReportsFilterQuery('');
        }
    }, [location.pathname]);

    // get subsidiaries (for «Factures et règlements» & «Etats» tabs)
    useEffect(() => {
        (async () => {
            const result = await SubsidiaryActions.getAllSubsidiaries(userData);

            if (result.success) {
                setSubsidiaries(result.data.reverse());
                setSubsidiariesDisplayedSection(result.data[0].name);
                setCurrentSubsidiary(result.data[0]);
            }
            else {
                notify('Erreur de récuperation des filiales');
            }
        })();
    }, []);

    const fetchCardData = async (activeLoading = true) => {
        if (activeLoading) setLoading(true);

        const billingPayResult = await DashboardActions.AdminActions.getBillingPay(userData, `?subsidiary_id=${currentSubsidiary?.id}`);

        if (billingPayResult.success) {
            setSentInvoicesData(billingPayResult.data?.sent_invoices ?? {});
            setDraftInvoicesData(billingPayResult.data?.draft_invoices ?? {});
            setPendingContractorInvoicesData(billingPayResult.data?.contractor_invoices.pending ?? {});
            setToPayContractorInvoicesData(billingPayResult.data?.contractor_invoices.to_pay ?? {});
            setCurrentExpenseReportsData(billingPayResult.data?.expenses.pending ?? {});
            setPayableExpenseReportsData(billingPayResult.data?.expenses.to_pay ?? {});
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des données des factures clients');
        }

        setLoading(false);
    }

    useEffect( () => {
        if (currentSubsidiary) {
            fetchCardData();
        }
    }, [currentSubsidiary]);

    // --- TAB -> «Factures et règlements» ---
    // ---------------------------------------
    // ↓ Fetch data -> Factures en attente de paiement
    const fetchInvoicesAwaitingPaymentData = async (page) => {
        setInvoicesAwaitingPaymentTableLoading(true);
        setInvoicesAwaitingPayment([]);
        
        const todayMinusOneYear = standardFormat(new Date(new Date().setFullYear(new Date().getFullYear() - 1)))

        const sentInvoicesAwaitingPaymentResult = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${invoicesAwaitingPaymentPaginationMetadata.pageSize}
            &${standardFormat(new Date())}&credit=false&billing_after=${todayMinusOneYear}&status=300&status=400&subsidiary=${currentSubsidiary?.id}${invoicesAwaitingPaymentFilterQuery}`,
            page
        );

        if (sentInvoicesAwaitingPaymentResult.success) {
            setInvoicesAwaitingPayment(sentInvoicesAwaitingPaymentResult.data);
            setInvoicesAwaitingPaymentPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (invoicesAwaitingPaymentPaginationMetadata.totalItems !== sentInvoicesAwaitingPaymentResult.metadata.count) {
                setInvoicesAwaitingPaymentPaginationMetadata(prevState => ({...prevState, totalItems: sentInvoicesAwaitingPaymentResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures en attente de paiement');
        }

        setInvoicesAwaitingPaymentTableLoading(false);
    }

    useEffect(() => {
        if (currentSubsidiary) {
            fetchInvoicesAwaitingPaymentData(1);
        }
    }, [currentSubsidiary, invoicesAwaitingPaymentFilterQuery]);
    // ---------------------------------------------------------

    // ----------------------------------------
    // ↓ Fetch data -> Factures prévisionnelles
    const fetchForecastInvoicesData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setForecastInvoicesTableLoading(true);
            setForecastInvoices([]);
        }

        const forecastInvoicesResult = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${forecastInvoicesPaginationMetadata.pageSize}&credit=false&status=0&status=100&status=200&subsidiary=${currentSubsidiary?.id}${forecastInvoicesFilterQuery}`,
            page
        );

        if (forecastInvoicesResult.success) {
            setForecastInvoices(forecastInvoicesResult.data);
            setForecastInvoicesPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (forecastInvoicesPaginationMetadata.totalItems !== forecastInvoicesResult.metadata.count) {
                setForecastInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: forecastInvoicesResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures prévisionnelles');
        }

        setForecastInvoicesTableLoading(false);
    }

    useEffect(() => {
        if (currentSubsidiary) {
            fetchForecastInvoicesData(1);
        }
    }, [currentSubsidiary, forecastInvoicesFilterQuery]);
    // -------------------------------------------------

    // Fonction filter des 2 tables invoices ci-dessus
    const filterInvoices = async (query, status) => {
        if (status === 'sent') {
            setInvoicesAwaitingPaymentFilterQuery(`&${query}`);
        }
        else {
            setForecastInvoicesFilterQuery(`&${query}`);
        }
    }

    // ------------------------------------------------
    // ↓ Fetch data -> Factures fournisseurs en attente
    const fetchPendingContractorInvoicesData = async (page) => {
        setPendingContractorInvoicesTableLoading(true);
        setPendingContractorInvoices([]);

        const pendingContractorInvoicesResult = await ContractorInvoiceActions.getContractorInvoices(
            userData,
            // eslint-disable-next-line max-len
            `?page=${page}&page_size=${pendingContractorInvoicesPaginationMetadata.pageSize}&subsidiary=${currentSubsidiary?.id}&paid=false&invoice_paid=false${pendingContractorInvoicesFilterQuery}`
        );
    
        if (pendingContractorInvoicesResult.success) {
            setPendingContractorInvoices(pendingContractorInvoicesResult.data);
            setPendingContractorInvoicesPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (pendingContractorInvoicesPaginationMetadata.totalItems !== pendingContractorInvoicesResult.metadata.count) {
                setPendingContractorInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: pendingContractorInvoicesResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures fournisseurs en attente');
        }

        setPendingContractorInvoicesTableLoading(false);
    }

    useEffect(() => {
        if (currentSubsidiary) {
            fetchPendingContractorInvoicesData(1);
        }
    }, [currentSubsidiary, pendingContractorInvoicesFilterQuery]);
    // -----------------------------------------------------------

    // ---------------------------------------------
    // ↓ Fetch data -> Factures fournisseurs à payer
    const fetchToPayContractorInvoicesData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setToPayContractorInvoicesTableLoading(true);
            setToPayContractorInvoices([]);
        }

        const toPayContractorInvoicesResult = await ContractorInvoiceActions.getContractorInvoices(
            userData,
            `?page=${page}&page_size=${toPayContractorInvoicesPaginationMetadata.pageSize}&subsidiary=${currentSubsidiary?.id}&paid=false&invoice_paid=true${toPayContractorInvoicesFilterQuery}`
        );

        if (toPayContractorInvoicesResult.success) {
            setToPayContractorInvoices(toPayContractorInvoicesResult.data);
            setToPayContractorInvoicesPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (toPayContractorInvoicesPaginationMetadata.totalItems !== toPayContractorInvoicesResult.metadata.count) {
                setToPayContractorInvoicesPaginationMetadata(prevState => ({...prevState, totalItems: toPayContractorInvoicesResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des factures fournisseurs à payer');
        }

        setToPayContractorInvoicesTableLoading(false);
    }

    const refreshToPayContractorInvoicesData = async (removal = false) => {
        const checkedPage = (toPayContractorInvoices.length === 1 && toPayContractorInvoicesPaginationMetadata.currentPage > 1 && removal) ?
            toPayContractorInvoicesPaginationMetadata.currentPage - 1 :
            toPayContractorInvoicesPaginationMetadata.currentPage;

        await fetchToPayContractorInvoicesData(checkedPage, false);
        // ↓ Ici les await ont été volontairement omis pour ne pas rallonger davantage le temps du refresh de la data
        fetchCardData(false);
        fetchForecastInvoicesData(1, false);
    }

    useEffect(() => {
        if (currentSubsidiary) {
            fetchToPayContractorInvoicesData(1);
        }
    }, [currentSubsidiary, toPayContractorInvoicesFilterQuery]);
    // ---------------------------------------------------------

    // Fonction filter des 2 tables contractos ci-dessus
    const filterContractorInvoices = async (query, status) => {
        if (status === 'pending') {
            setPendingContractorInvoicesFilterQuery(`&${query}`);
        }
        else if(status === 'toPay') {
            setToPayContractorInvoicesFilterQuery(`&${query}`);
        }
    }

    // ---------------------------------------
    // ↓ Fetch data -> Notes de frais en cours
    const fetchCurrentExpenseReportsData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setCurrentExpenseReportsTableLoading(true);
            setCurrentExpenseReports([]);
        }

        const currentExpenseReportsResult = await ExpenseReportActions.getAllExpenseReports(
            userData,
            `&page_size=${currentExpenseReportsPaginationMetadata.pageSize}&status=0&status=1&subsidiary=${currentSubsidiary?.id}${currentExpenseReportsFilterQuery}`,
            page
        );

        if (currentExpenseReportsResult.success) {
            const filterOutNoDate = currentExpenseReportsResult.data.filter((expenseReport) => expenseReport.start_date && expenseReport.end_date)

            setCurrentExpenseReports(filterOutNoDate);

            setCurrentExpenseReportsPaginationMetadata(prevState => ({...prevState, currentPage: page, filteredOutCount: filterOutNoDate.length}));

            if (currentExpenseReportsPaginationMetadata.totalItems !== currentExpenseReportsResult.metadata.count) {
                setCurrentExpenseReportsPaginationMetadata(prevState => ({...prevState, totalItems: currentExpenseReportsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des notes de frais en cours');
        }

        setCurrentExpenseReportsTableLoading(false);
    }

    const refreshCurrentExpenseReportsData = async (removal = false) => {
        const checkedPage = (currentExpenseReports.length === 1 && currentExpenseReportsPaginationMetadata.currentPage > 1 && removal) ?
            currentExpenseReportsPaginationMetadata.currentPage - 1 :
            currentExpenseReportsPaginationMetadata.currentPage;

        await fetchCurrentExpenseReportsData(checkedPage, false);
        // ↓ Ici les await ont été volontairement omis pour ne pas rallonger davantage le temps du refresh de la data
        fetchCardData(false);
    }

    useEffect(() => {
        fetchCurrentExpenseReportsData(1);
    }, [currentSubsidiary, currentExpenseReportsFilterQuery])
    // ------------------------------------------------------

    // ---------------------------------------
    // ↓ Fetch data -> Notes de frais à payer
    const fetchToPayExpenseReportsData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setToPayExpenseReportsTableLoading(true);
            setToPayExpenseReports([]);
        }

        const toPayExpenseReportsResult = await ExpenseReportActions.getAllExpenseReports(
            userData,
            `&page_size=${toPayExpenseReportsPaginationMetadata.pageSize}&status=2&subsidiary=${currentSubsidiary?.id}${toPayExpenseReportsFilterQuery}`,
            page
        );

        if (toPayExpenseReportsResult.success) {
            setToPayExpenseReports(toPayExpenseReportsResult.data);

            setToPayExpenseReportsPaginationMetadata(prevState => ({...prevState, currentPage: page}));

            if (toPayExpenseReportsPaginationMetadata.totalItems !== toPayExpenseReportsResult.metadata.count) {
                setToPayExpenseReportsPaginationMetadata(prevState => ({...prevState, totalItems: toPayExpenseReportsResult.metadata.count}));
            }
        }
        else {
            notify('Une erreur est survenue lors de la récuperation des notes de frais à payer');
        }

        setToPayExpenseReportsTableLoading(false);
    }

    const refreshToPayExpenseReportsData = async (removal = false) => {
        const checkedPage = (toPayExpenseReports.length === 1 && toPayExpenseReportsPaginationMetadata.currentPage > 1 && removal) ?
            toPayExpenseReportsPaginationMetadata.currentPage - 1 :
            toPayExpenseReportsPaginationMetadata.currentPage;

        await fetchToPayExpenseReportsData(checkedPage, false);
        // ↓ Ici les await ont été volontairement omis pour ne pas rallonger davantage le temps du refresh de la data
        fetchCardData(false);
    }

    useEffect(() => {
        fetchToPayExpenseReportsData(1);
    }, [currentSubsidiary, toPayExpenseReportsFilterQuery])
    // ----------------------------------------------------

    // Fonction filter des 2 tables expense reports ci-dessus
    const filterExpenseReports = async (query, status) => {
        if (status === 'current') {
            setCurrentExpenseReportsFilterQuery(`&${query}`)
        }
        else {
            setToPayExpenseReportsFilterQuery(`&${query}`)
        }
    }

    // «Production» TAB DATA
    useEffect(() => {
        getProductionData(
            userData,
            notify,
            setGrossMarginProducedData,
            setForecastGrossMarginData,
            setDailyRateData,
            grossMarginProducedDatePicker,
            forecastGrossMarginDatePicker
        );
    }, [])

    // «Paramètres de gestion» TAB DATA
    useEffect(() => {
        getManagementParametersData(
            userData,
            notify,
            setMissionsProfitabilityData,
            missionsProfitabilityDatePicker,
            setConsultantContributionsData,
            consultantContributionsDatePicker,
            setCostsData,
            costsDatePicker,
            setContractorsData,
            contractorsDatePicker,
            setExpensesData,
            expensesDatePicker,
            setPaymentTermsData,
            paymentTermsDatePicker
        );
    }, []);

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={loading}>
                <div className="px-20 pt-10 pb-32">
                    <TabNavigator
                        setDisplayedSection={setDisplayedSection}
                        displayedSection={displayedSection}
                        sections={sections}
                    />
                    {
                        displayedSection === 'Factures et règlements' && (
                            <Routes>
                                <Route path='/' element={
                                    <InvoiceAndPayment
                                        sentInvoicesData={sentInvoicesData}
                                        draftInvoicesData={draftInvoicesData}
                                        pendingContractorInvoicesData={pendingContractorInvoicesData}
                                        toPayContractorInvoicesData={toPayContractorInvoicesData}
                                        currentExpenseReportsData={currentExpenseReportsData}
                                        toPayExpenseReportsData={toPayExpenseReportsData}
                                        displayedSection={subsidiariesDisplayedSection}
                                        setDisplayedSection={setSubsidiariesDisplayedSection}
                                        subsidiaries={subsidiaries}
                                        currentSubsidiary={currentSubsidiary}
                                        setCurrentSubsidiary={setCurrentSubsidiary}
                                    />
                                }/>
                                <Route path='sent-invoices' element={
                                    <InvoicesAwaitingPaymentPage
                                        tableType='sent'
                                        data={invoicesAwaitingPayment}
                                        filterInvoicesList={filterInvoices}
                                        userData={userData}
                                        paginationMetadata={invoicesAwaitingPaymentPaginationMetadata}
                                        onPageNumberChange={fetchInvoicesAwaitingPaymentData}
                                        tableLoading={invoicesAwaitingPaymentTableLoading}
                                    />
                                }/>
                                <Route path='draft-invoices' element={
                                    <ForecastedInvoicesPage
                                        tableType='draft'
                                        data={forecastInvoices}
                                        filterForecastedInvoicesList={filterInvoices}
                                        userData={userData}
                                        paginationMetadata={forecastInvoicesPaginationMetadata}
                                        onPageNumberChange={fetchForecastInvoicesData}
                                        tableLoading={forecastInvoicesTableLoading}
                                    />
                                }/>
                                <Route path='pending-contractor-invoices' element={
                                    <PendingInvoicesPage
                                        tableType='pending'
                                        data={pendingContractorInvoices}
                                        filterContractorInvoices={filterContractorInvoices}
                                        userData={userData}
                                        paginationMetadata={pendingContractorInvoicesPaginationMetadata}
                                        onPageNumberChange={fetchPendingContractorInvoicesData}
                                        tableLoading={pendingContractorInvoicesTableLoading}
                                    />
                                }/>
                                <Route path='payable-contractor-invoices' element={
                                    <PayableInvoicesPage
                                        tableType='toPay'
                                        data={toPayContractorInvoices}
                                        filterContractorInvoices={filterContractorInvoices}
                                        refreshData={refreshToPayContractorInvoicesData}
                                        paginationMetadata={toPayContractorInvoicesPaginationMetadata}
                                        onPageNumberChange={fetchToPayContractorInvoicesData}
                                        tableLoading={toPayContractorInvoicesTableLoading}
                                    />
                                }/>
                                <Route path='current-expense-reports' element={
                                    <CurrentExpenseReportsPage
                                        filterExpenseReports={filterExpenseReports}
                                        tableType='current'
                                        data={currentExpenseReports}
                                        refreshData={refreshCurrentExpenseReportsData}
                                        paginationMetadata={currentExpenseReportsPaginationMetadata}
                                        onPageNumberChange={fetchCurrentExpenseReportsData}
                                        tableLoading={currentExpenseReportsTableLoading}
                                    />
                                }/>
                                <Route path='payable-expense-reports' element={
                                    <PayableExpenseReportsPage
                                        filterExpenseReports={filterExpenseReports}
                                        tableType='toPay'
                                        data={toPayExpenseReports}
                                        refreshData={refreshToPayExpenseReportsData}
                                        paginationMetadata={toPayExpenseReportsPaginationMetadata}
                                        onPageNumberChange={fetchToPayExpenseReportsData}
                                        tableLoading={toPayExpenseReportsTableLoading}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Production' && (
                            <Production
                                grossMarginProducedData={grossMarginProducedData}
                                forecastGrossMarginData={forecastGrossMarginData}
                                dailyRateData={dailyRateData}
                                grossMarginProducedDatePicker={grossMarginProducedDatePicker}
                                handleGrossMarginProducedDatePickerDateChange={(e) => handleGrossMarginProducedDatePickerDateChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setGrossMarginProducedDatePicker,
                                    setGrossMarginProducedData
                                )}
                                forecastGrossMarginDatePicker={forecastGrossMarginDatePicker}
                                handleForecastGrossMarginDatePickerDateChange={(e) => handleForecastGrossMarginDatePickerDateChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setForecastGrossMarginDatePicker,
                                    setForecastGrossMarginData
                                )}
                            />
                        )
                    }
                    {
                        displayedSection === 'Paramètres de gestion' && (
                            <ManagementParameters
                                missionsProfitabilityData={missionsProfitabilityData}
                                missionsProfitabilityDatePicker={missionsProfitabilityDatePicker}
                                handleMissionsProfitabilityDatePickerChange={(e) => handleMissionsProfitabilityDatePickerChange (
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setMissionsProfitabilityDatePicker,
                                    setMissionsProfitabilityData
                                )}
                                consultantContributionsData={consultantContributionsData}
                                consultantContributionsDatePicker={consultantContributionsDatePicker}
                                handleConsultantContributionsDatePickerChange={(e) => handleConsultantContributionsDatePickerChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setConsultantContributionsDatePicker,
                                    setConsultantContributionsData
                                )}
                                costsData={costsData}
                                costsDatePicker={costsDatePicker}
                                handleCostsDatePickerChange={(e) => handleCostsDatePickerChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setCostsDatePicker,
                                    setCostsData
                                )}
                                contractorsData={contractorsData}
                                contractorsDatePicker={contractorsDatePicker}
                                handleContractorsDatePickerChange={(e) => handleContractorsDatePickerChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setContractorsDatePicker,
                                    setContractorsData
                                )}
                                expensesData={expensesData}
                                expensesDatePicker={expensesDatePicker}
                                handleExpensesDatePickerChange={(e) => handleExpensesDatePickerChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setExpensesDatePicker,
                                    setExpensesData
                                )}
                                paymentTermsData={paymentTermsData}
                                paymentTermsDatePicker={paymentTermsDatePicker}
                                handlePaymentTermsDatePickerChange={(e) => handlePaymentTermsDatePickerChange(
                                    e,
                                    userData,
                                    setLoading,
                                    notify,
                                    setPaymentTermsDatePicker,
                                    setPaymentTermsData
                                )}
                            />
                        )
                    }
                    {
                        displayedSection === 'Etats' && (
                            <StateTab subsidiaries={subsidiaries}/>
                        )
                    }
                </div>
            </PageLoader>
        </Layout>
    )
}

export default AdminDashboardPage;
