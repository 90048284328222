import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import AdminDashboardRouter from './AdminDashboardRouter';
import ConsultantDashboardRouter from './ConsultantDashboardRouter';
import DasDashboardRouter from './DasDashboardRouter';
import OfficeManagerDashboardRouter from './OfficeManagerDashboardRouter';
import CodevDashboardRouter from './CodevDashboardRouter';
import DealsRoute from './DealsRoute';
import StaffingRoute from './StaffingRoute';
import ExpensesRoute from './ExpensesRoute';
import InvoicesRoute from './InvoicesRoute';
import ReferencesRoute from './ReferencesRoute';
import ProductionsRoute from './ProductionsRoute';
import MyOrganizationRouter from './MyOrganizationRouter';
import LoginPage from '../pages/loginPage/LoginPage';
import NotificationsRoute from './NotificationsRoute';
import UserAccount from './UserAccount';
import UserIsAuthenticated from './guards/UserIsAuthenticated';
import ProtectedDashboard from './guards/ProtectedDashboard';
import ReducerProvider from './guards/ReducerProvider';
import {getUserDefaultDashboard} from '../utils';
import {ErrorBoundary} from 'react-error-boundary';
import {useNavigate} from 'react-router-dom'; //

function AppRouter() {
    const userProfileData = useSelector((state) => state.UserReducer.userProfileData);

    const userRole = userProfileData?.role ?? null;

    const defaultDashboard = getUserDefaultDashboard(userRole);

    const ErrorFallback = () => {
        const navigate = useNavigate(); // Initialize useHistory hook

        return (
            <div className='w-full min-h-screen justify-center items-center text-center'>
                <div className="pt-40">
                    <p className='text-red-500 font-bold'>Oups! Une erreur est survenue</p>
                    <button
                        onClick={() => {
                            navigate('/'); // Navigate to homepage
                            location.reload();
                        }}
                        className='mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
                    >
                        Retour à l&apos;accueil
                    </button>
                </div>
            </div>
        );
    };

    return (
        <Router>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
            >
                <Routes>
                    <Route path={'/login'} element={<LoginPage/>}/>
                    <Route element={<UserIsAuthenticated/>}>
                        <Route
                            element={<ReducerProvider/>}>
                            <Route element={
                                <ProtectedDashboard
                                    authorized={userRole === 'PA'}
                                    defaultDashboard={defaultDashboard}
                                />
                            }>
                                <Route path='/dashboard/admin/*' element={<AdminDashboardRouter/>}/>
                            </Route>
                            <Route element={
                                <ProtectedDashboard
                                    authorized={userRole === 'PA' || userRole === 'MA'}
                                    defaultDashboard={defaultDashboard}
                                />
                            }>
                                <Route path='/dashboard/das/*' element={<DasDashboardRouter/>}/>
                            </Route>
                            <Route element={
                                <ProtectedDashboard
                                    authorized={userRole === 'PA' || userRole === 'MA'}
                                    defaultDashboard={defaultDashboard}
                                />
                            }>
                                <Route path='/dashboard/codev/*' element={<CodevDashboardRouter/>}/>
                            </Route>
                            <Route element={
                                <ProtectedDashboard
                                    authorized={userRole === 'PA' || userRole === 'OM'}
                                    defaultDashboard={defaultDashboard}
                                />
                            }>
                                <Route path='/dashboard/office-manager/*' element={<OfficeManagerDashboardRouter/>}/>
                            </Route>
                            <Route path='/dashboard/consultant/*' element={<ConsultantDashboardRouter/>}/>
                            <Route path='/deals/*' element={<DealsRoute/>}/>

                            <Route element={
                                <ProtectedDashboard
                                    authorized={userRole === 'PA' || userRole === 'OM'}
                                    defaultDashboard={defaultDashboard}
                                />
                            }>
                                <Route path='/staffing/*' element={<StaffingRoute/>}/>
                            </Route>

                            <Route path='/invoices/*' element={<InvoicesRoute/>}/>
                            <Route path='/productions/*' element={<ProductionsRoute/>}/>
                            <Route path='/expenses/*' element={<ExpensesRoute/>}/>
                            <Route path='/references/*' element={<ReferencesRoute/>}/>
                            <Route path='/notifications/*' element={<NotificationsRoute/>}/>
                            <Route path='/account/*' element={<UserAccount/>}/>
                            <Route path="/my-organization/*" element={<MyOrganizationRouter/>}/>
                            <Route
                                path="*"
                                element={<Navigate to={`/dashboard/${defaultDashboard}`} replace/>}
                            />
                        </Route>
                    </Route>
                    <Route
                        path="*"
                        element={<Navigate to='/login' replace/>}
                    />
                </Routes>
            </ErrorBoundary>
        </Router>
    );
}

export default AppRouter;
