import { Progress, Tooltip } from "antd";

import './progress-bar.css';

const ProgressComponent = ({
    style = 'orange',
    percent,
    tooltipValue,
    textGradient
}) => {
    return (
        <div className='px-6'>
            <Tooltip title={tooltipValue}>
                <>
                    <Progress
                        className={`dashboard-${style} ${textGradient && 'text-gradient'}`}
                        strokeColor={'#fff'}
                        trailColor={'#F5F5F580'}
                        showInfo={true}
                        percent={percent}
                    />
                </>
            </Tooltip>
        </div>
    )
}

export default ProgressComponent;