import {EuroCircleFilled} from '@ant-design/icons';
import {CalculatorFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import TabCard from '../../../../components/ui/TabCard/TabCard';
import {missionsProfitabilityChartTabConfig} from '../../configs/missionsProfitabilityChartTabConfig';
import {transformThousandNumbers} from '../../../../utils';

const getPercent = (grossMargin, revenue) => {
    if (!grossMargin || !revenue) {
        return 0;
    }
    else {
        return +((grossMargin / revenue) * 100).toFixed(1);
    }
}

const MissionsProfitability = ({
    data,
    datePickerValue,
    onDatePickerChange
}) => {
    const euroIcon = <EuroCircleFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const calculatorIcon = <CalculatorFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = (key) => {
        let sum = 0;

        for (const k in data) {
            sum += data[k][key];
        }

        return Math.round(sum);
    }

    return (
        <div className='my-10'>
            <LineTitle title='Rentabilité des missions'/>
            <div className='mt-10 mb-6'>
                <Datepicker
                    type='range'
                    defaultValue={datePickerValue}
                    onChange={onDatePickerChange}
                />
            </div>
            <div className='flex flex-row'>
                <SimpleCard
                    width='w-[50%]'
                    height='h-fit'
                    iconComponent={euroIcon}
                    title={transformThousandNumbers(getSum('gross_margin'))}
                    sideTitle={`(${getPercent(getSum('gross_margin'), getSum('revenue'))}%)`}
                    subTitle="Marge brute"
                    background='orange'
                    bottomComponent={<BottomComponent data={data} gross={true}/>}
                />
                <div className='mx-5'/>
                <SimpleCard
                    width='w-[50%]'
                    height='h-fit'
                    iconComponent={calculatorIcon}
                    title={transformThousandNumbers((getSum('gross_margin') - getSum('costs')))}
                    sideTitle={`(${getPercent(getSum('gross_margin'), getSum('revenue'))}%)`}
                    subTitle="Contribution à la marge"
                    background='orange'
                    bottomComponent={<BottomComponent data={data} gross={false}/>}
                />
            </div>
            <div className='mt-6'>
                <TabCard config={missionsProfitabilityChartTabConfig(data)}/>
            </div>
        </div>
    )
}

export default MissionsProfitability;

const BottomComponent = ({data, gross}) => {
    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            value: transformThousandNumbers(gross ? data[key].gross_margin : (data[key].gross_margin - data[key].costs)),
            percent: getPercent(data[key].gross_margin, data[key].revenue)
        })
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge content='JDH' color='#f0a841' type='round'/>
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                                <span className='text-sm font-normal text-gradient'>
                                    {`(${el.percent}%)`}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
