import * as z from 'zod';
import * as productionFunctions from './functions';

export const ProductionSchema = z.object({
    category: z.string(),
    ressource_id: z.number(),
    deal_id: z.number(),
    date: z.string().optional(),
    produced_days: z.string(),
    billed_days: z.string(),
    location: z.string().optional().nullable(),
    mission: z.number().optional().nullable(),
    notes: z.string().optional()
});

export const ProductionPartialSchema = ProductionSchema.partial();
export type ProductionPartial = z.infer<typeof ProductionPartialSchema>;
export type Production = z.infer<typeof ProductionSchema>;

export function newProduction(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...ProductionSchema.parse({...data}),
        ...productionFunctions
    };
}

export function parseProduction(data: any, partial = false) {
    if (!partial) {
        return {
            ...ProductionSchema.parse(data),
            ...productionFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...ProductionPartialSchema.parse(data),
            ...productionFunctions
        };
    }
}
