import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GET_ADMIN_DASHBOARD_PROFITABILITY';

export async function getProfitability(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getProfitability - Start');

        const getProfitability = await DashboardService.AdminService.getProfitability(userData, params);

        LoggerService.logInfo('Action - getProfitability - End - Success');

        return ResultModel.newSuccessResult(getProfitability);
    }
    catch (error) {
        LoggerService.logInfo('Action - getProfitability - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
