import {ExpenseReportDao} from '../../dao';
import {ErrorModel, ExpenseReportModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_EXPENSE_REPORTS';

export async function getAllExpenseReports(
    userData: Record<string, string>,
    params: string,
    page: number,
) {
    try {
        const expenseReports = await ExpenseReportDao.getAllExpenseReports(userData.userToken, params, page);

        const expenseReportParsed = expenseReports.results.map((expenseReport: Record<string, any>) => ExpenseReportModel.parseExpenseReport(expenseReport));

        const metadata = {
            count: expenseReports.count,
            next: expenseReports.next,
            previous: expenseReports.previous
        };

        return {
            metadata,
            data: expenseReportParsed
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
