import {ACTION_CODE} from './_actionCode';
import {InvoiceService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_INVOICE';

export async function deleteInvoiceById(userData: Record<string, any>, invoiceId: number) {
    try {
        LoggerService.logInfo('Action - deleteInvoiceById - Start');

        const deleteInvoiceByIdResult = await InvoiceService.deleteInvoice(userData, invoiceId);

        LoggerService.logInfo('Action - deleteInvoiceById - End - Success');

        return ResultModel.newSuccessResult(deleteInvoiceByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteInvoiceById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
