/* eslint-disable max-len */
import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import {fetchSelectData} from './../../../../../utils';
import SelectInput from '../../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../../components/ui/Inputs/TextInput';
import TextAreaInput from '../../../../../components/ui/Inputs/TextAreaInput';
import DateInput from '../../../../../components/ui/Inputs/DateInput/DateInput';
import Button from '../../../../../components/ui/Button/Button';
import {notify} from '../../../../../components/ui/Toast/Toast';
import {LocationActions, ProductionCategoryActions, ProductionActions} from '../../../../../actions';
import {frenchFormat} from '../../../../../utils';
import ContainerCard from '../../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../../components/ui/Error/Error';
import {getErrors, standardFormat} from './../../../../../utils';

function DealPopupProduction({
    userData,
    dealData,
    setView,
    productionToBeUpdated,
    refreshProductionsData,
    blueStyle
}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const [categories, setCategories] = useState(null);
    const [locations, setLocations] = useState(null);
    
    useEffect(() => {
        if (productionToBeUpdated) {

            const ressource = {
                ...productionToBeUpdated.ressource,
                name: productionToBeUpdated.ressource?.first_name + ' ' + productionToBeUpdated.ressource?.last_name
            };

            reset({
                date: productionToBeUpdated?.date,
                location: productionToBeUpdated?.location,
                produced_days: (+productionToBeUpdated?.produced_days).toString(),
                category: {name: productionToBeUpdated?.category},
                ressource: ressource,
                billed_days: (+productionToBeUpdated?.billed_days).toString(),
                notes: productionToBeUpdated?.notes
            });
        }
    }, [productionToBeUpdated]);

    const defaultValues = {
        date: dayjs(),
        location: null,
        produced_days: null,
        category: '',
        ressource: '',
        billed_days: null,
        notes: ''
    };
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    const handleGetCategories = async (search, page = 1) => {
        await fetchSelectData({action: ProductionCategoryActions.getAllProductionCategories, search:`&search=${search}`, page: page, setter: setCategories, userData});
    };

    const handleGetLocations = async (search, page = 1) => {
        await fetchSelectData({action: LocationActions.getAllLocation, search:`&display=${userData.userProfileName}&search=${search}&is_active=true`, page: page, setter: setLocations, userData, resultPath: 'data'});
    };

    useEffect(() => {
        (async () => {
            await handleGetCategories('', 1);
        })();
        (async () => {
            await handleGetLocations('', 1);
        })();
    }, []);

    const ressources = dealData?.producers?.map((item) => ({
        ...item, name: item.first_name + ' ' + item.last_name
    }))

    const onSubmit = async (data) => {
        setErrors(null);
        setIsDisabled(true);
        setIsLoading(true);

        const production = {
            date: standardFormat(data.date),
            location: data?.location.name,
            deal_id: dealData.id,
            produced_days: data.produced_days,
            category: data.category.name,
            ressource_id: data.ressource.id,
            billed_days: data.billed_days,
            notes: data.notes
        };

        if (!productionToBeUpdated) {
            const result = await ProductionActions.createProduction(userData, production);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshProductionsData()
                notify('Votre temps à bien été créé');
                setView(false);
            }
        }
        else {
            const result = await ProductionActions.updateProduction(userData, productionToBeUpdated?.id, production);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshProductionsData()
                notify('Votre temps à bien été modifié');
                setView(false);
            }
        }

        setIsLoading(false);
        setIsDisabled(false);
    };

    return (
        <ContainerCard
            title={productionToBeUpdated ? 'Éditer un temps' : 'Nouveau temps'}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-4">
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="location"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer une localisation.'
                                }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Emplacement'
                                    placeholder='Emplacement'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={locations?.options || []}
                                    hasNextPage={locations?.hasNextPage || false}
                                    fetchFunction={handleGetLocations}
                                    loading={!locations}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    isSearchable={true}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="category"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un type de temps.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type de temps'
                                    placeholder='Type de temps'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={categories?.options.sort((a, b) => a.id - b.id)}
                                    hasNextPage={categories?.hasNextPage}
                                    onChange={onChange}
                                    loading={!categories}
                                    fetchFunction={handleGetCategories}
                                    labelKeys={['name']}
                                    isSearchable={true}
                                    required={true}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="ressource"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une ressource.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Ressource'
                                    placeholder='Ressource'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    isSearchable={true}
                                    options={ressources}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="produced_days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer un nombre de jours produits.'
                                }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Jours produits (j)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    min="0"
                                    placeholder="Jours produits (j)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billed_days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer un nombre de jours facturés.'
                                }
                            }}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Jours facturés (j)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    min="0"
                                    placeholder="Jours facturés (j)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            isDisabled={isDisabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={isDisabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={productionToBeUpdated ? 'Modifier le temps' : 'Ajouter le temps'}
                        onClick={handleSubmit(onSubmit)}
                        loading={isLoading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default DealPopupProduction;
