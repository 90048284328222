import {CustomersDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'UPDATE_CUSTOMER';

export async function updateCustomer(
    userData: Record<string, string>,
    updatedCustomer: Record<string, string>,
    customerId: number
) {
    try {
        const updateCustomerResult = await CustomersDao.updateCustomer(userData.userToken, updatedCustomer, customerId);

        return updateCustomerResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la modification du client.',
            error: error
        });
    }
}
