import {useState, useEffect} from 'react';
import Popup from '../../../components/ui/Popup';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import {InvoiceActions} from '../../../actions';
import {notify} from '../../../components/ui/Toast/Toast';

const PreviewFilePopup = ({
    view,
    setView,
    userData,
    item
}) => {
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        (async () => {
            if (!item || !view) return;
           
            const result = await InvoiceActions.downloadInvoiceById(userData, item.id);

            if (result.success) {
                const url = URL.createObjectURL(result.data);
                setFileUrl(url);
            }
            else {
                notify('Erreur lors de la récupération de la facture');
            }
        })();
    }, [item, view]);
  
    const docs = [{uri: fileUrl}];

    return (
        <Popup view={view} setView={setView} maxheight='h-[400px]'>
            <DocViewer
                style={{height: '60vh', width: '850px'}}
                documents={docs}
                initialActiveDocument={docs[0]}
                pluginRenderers={DocViewerRenderers}
                config={{ header: {
                    disableHeader: true
                },
                pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2
                }
                }}
            />
        </Popup>
    );
}

export default PreviewFilePopup;
