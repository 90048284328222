import {ACTION_CODE} from './_actionCode';
import {ProductionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_PRODUCTION';

export async function deleteProduction(userData: Record<string, any>, productionId: string) {
    try {
        LoggerService.logInfo('Action - deleteProduction - Start');

        const deleteProductionResult = await ProductionService.deleteProduction(userData, productionId);

        LoggerService.logInfo('Action - deleteProduction - End - Success');

        return ResultModel.newSuccessResult(deleteProductionResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteProduction - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
