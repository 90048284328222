import React from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';

const BillingAddressCard = ({data}) => {
    return (
        <ContainerCard
            title='Adresse de facturation'
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[300px]'
            margin='mx-6'
        >
            <div className='flex items-start justify-start'>
                <div className='text-[#646464] text-sm mt-[3px] mr-4'>Adresse</div>
                <div className='text-[#212121] text-base'>{data ? data : '-'}</div>
            </div>
        </ContainerCard>
    )
}

export default BillingAddressCard
