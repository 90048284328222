import React from 'react';
import {Route, Routes} from 'react-router-dom';
import DealsPage from '../pages/dealsPage/DealsPage';
import DealDetailsPage from '../pages/dealsPage/DealDetailsPage';
import MissionDetails from '../pages/dealsPage/MissionDetails';

function DealsRoute() {
    return (
        <Routes>
            <Route path='/' element={<DealsPage/>}/>
            <Route path='/:id/*' element={<DealDetailsPage/>}/>
            <Route path='/:id/missions/:id' element={<MissionDetails/>}/>
        </Routes>

    );
}

export default DealsRoute;
