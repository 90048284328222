import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'CREATE_TRAINING_ATTENDANCE';

export async function createTrainingAttendance(
    userToken: string,
    newTrainingAttendance: Record<string, unknown>
) {
    try {
        const response = await axios({
            method: 'post',
            url: `${URL}/training-attendances/`,
            data: newTrainingAttendance,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la création de la liste des suivis de stagiaires',
            error: error
        });
    }
}
