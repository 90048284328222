import {ACTION_CODE} from './_actionCode';
import {CustomerCategoryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_CUSTOMER_CATEGORY';

export async function updateCustomerCategoryById(
    userData: Record<string, any>,
    customerCategoryId: number,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateCustomerCategoryById - Start');

        const updateCustomerCategoryByIdResult =
            await CustomerCategoryService.updateCustomerCategoryById(userData, customerCategoryId, payloadUpdate);

        LoggerService.logInfo('Action - updateCustomerCategoryById - End - Success');

        return ResultModel.newSuccessResult(updateCustomerCategoryByIdResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateCustomerCategoryById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une catégorie client',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
