import {useEffect, useState} from 'react';

import {frenchFormat, fetchSelectData, handleFilterList, useFilterQuery} from '../../../../utils/';
import {DealDasActions, UserActions} from './../../../../actions'
import ActionsButton from '../../../../components/ui/ActionsButton';
import Badge from '../../../../components/ui/Badge';
import {SearchInput, SelectInput, TableDatePicker} from './../../../../components/ui/Table';

export const dealsTableConfig = (handleMissionNavigation, filterdealList, userData) => {
    const [dealDas, setDealDas] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        search: '',
        das: '',
        production_lead: '',
        produced_by: '',
        phase: '',
        mission_end_date_before: '',
        mission_end_date_after: ''
    });

    const phases = {
        'hasNextPage': false,
        'options': [
            {'id':1, 'isActive': true, 'label': 'Commercial', 'name': 'sale', 'value': '100&status=150&status=190&status=200&status=300&status=400'},
            {'id':2, 'isActive': true, 'label': 'Production', 'name': 'production', 'value': '500'},
            {'id':3, 'isActive': true, 'label': 'Facturation', 'name': 'billing', 'value': '600&status=700'}
            // {'id':4, 'isActive': true, 'label': 'Close', 'name': 'close', 'value': '900'}
        ]};

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterdealList);

    // ↓ get data for select input
    const handleGetDealDas = async (search, page = 1) => {
        await fetchSelectData({action: DealDasActions.getAllDealDas, search:`&search=${search}`, page: page || null, setter: setDealDas, userData});
    };

    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page || null, setter: setUsers, userData});
    }

    useEffect(() => {
        // ↓ deal das dropdown values
        (async () => {
            await handleGetDealDas('', 1);
        })();

        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'short_name',
                    width: 'w-[14%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.short_name}
                            </div>
                        );
                    },
                    filterComponent: () => <SearchInput name={'search'} onChange={handleFilter}/>,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Commerciaux',
                    value: 'sales',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.sales?.length > 0 ?
                                        <Badge content={item.sales} type='rounded'/>
                                        : '-'
                                }
                            </div>
                        )
                    },
                    filterComponent: () => <SelectInput
                        name="sales_lead"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Production',
                    value: 'production_lead.username',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.production_lead ?
                                        <Badge content={[item.production_lead]} type='rounded'/> :
                                        '-'
                                }
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="production_lead"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Producteurs',
                    value: 'producers',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item?.producers?.length > 0 ?
                                        <Badge content={item.producers} type='rounded'/>
                                        : '-'
                                }
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="produced_by"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Phase',
                    value: 'production_stage',
                    width: 'w-[11%]',
                    sort: false,
                    component: (item) => {
                        switch (item.status) {
                            case 100:
                            case 150:
                            case 190:
                            case 200:
                            case 290:
                            case 300:
                            case 400:
                                return (
                                    <div className="flex flex-row items-center justify-center">
                                        <Badge
                                            content={'C'}
                                            type='rounded' color={'#00ABE9'}
                                            tooltip={'Commercial'}
                                        />
                                    </div>
                                );
                            case 500:
                                return (
                                    <div className="flex flex-row items-center justify-center">
                                        <Badge
                                            content={'P'}
                                            type='rounded' color={'#F0A841'}
                                            tooltip={'Production'}
                                        />
                                    </div>
                                );
                            case 600:
                            case 700:
                                return (
                                    <div className="flex flex-row items-center justify-center">
                                        <Badge
                                            content={'F'}
                                            type='rounded' color={'#E36D38'}
                                            tooltip={'Facturation'}
                                        />
                                    </div>
                                );
                            case 900:
                                return (
                                    <div className="flex flex-row items-center justify-center">
                                        <Badge
                                            content={'CL'}
                                            type='rounded' color={'#DC3832'}
                                            tooltip={'Close'}
                                        />
                                    </div>
                                );
                        }
                    },
                    filterComponent: () => <SelectInput
                        name="status"
                        options={phases?.options}
                        hasNextPage={phases?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        labelKeys={['label']}
                        valueKey={'value'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'DAS',
                    value: 'das',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.das}
                                    color={item.das === 'Indéfini' ? '#646464' : null}
                                    blueStyle={item.das === 'Formation'}
                                />
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="das"
                        options={dealDas?.options?.sort((a, b) => a.name.localeCompare(b.name))}
                        hasNextPage={dealDas?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetDealDas)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours',
                    value: 'billable_days',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {Math.round(item.billable_days)} J
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'billable_days',
                    sumLabel: ' J'
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(item.budget).toLocaleString()} €
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'budget',
                    sumLabel: ' €'
                },
                {
                    label: 'Fin',
                    value: 'mission_end_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.mission_end_date ? frenchFormat(item.mission_end_date) : "-"}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name={'mission_end_date'} onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Avancement',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {isNaN((item.billed_days / item.billable_days) * 100) || (item.billed_days / item.billable_days) * 100 === Infinity
                                    ? '-' : Math.round((item.billed_days / item.billable_days) * 100) + ' %'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => handleMissionNavigation(item.id)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
