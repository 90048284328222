import {Steps} from 'antd';
import './style.css';

// Exemple de config
/* const config = {
    color: 'blue',
    width: 'w-7/12',
    items: [
        {
            title: 'En cours'
        },
        {
            title: 'À vérifier'
        },
        {
            title: 'À payer'
        },
        {
            title: 'Payée'
        }
    ]
}; */

/**
 * @typedef {Object} RoadStepConfig
 * @property {string} color
 * @property {string} width // tailwind width
 * @property {Array} items
 * @property {string} items.title
 * @property {string} items.description
 * **/

const RoadStep = ({config, step, onChange = null, declined}) => {
    return (
        <div className={`${config.width ?? config.width}`}>
            <Steps
                current={step}
                status={(config.items.length - 1) === step ? 'finish' : declined ? 'error' : 'progress'}
                items={config.items}
                className={`roadstep-${config.color ?? 'orange'}`}
                labelPlacement="vertical"
                onChange={onChange}
            />
        </div>
    );
};

export default RoadStep;
