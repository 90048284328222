export const dailyRate = {

    from(dailyRateResult) {

        const getDasS = () => {
            let dasS = {};

            for (const key in dailyRateResult) {
                dasS[key] = {
                    "junior": {
                        "produced_days": dailyRateResult[key]?.junior?.produced_days ?? 0,
                        "billed_days": dailyRateResult[key]?.junior?.billed_days ?? 0,
                        "mission_value": dailyRateResult[key]?.junior?.mission_value ?? 0,
                        "gross_margin": dailyRateResult[key]?.junior?.gross_margin ?? 0
                    },
                    "confirmed": {
                        "produced_days": dailyRateResult[key]?.confirmed?.produced_days ?? 0,
                        "billed_days": dailyRateResult[key]?.confirmed?.billed_days ?? 0,
                        "mission_value": dailyRateResult[key]?.confirmed?.mission_value ?? 0,
                        "gross_margin": dailyRateResult[key]?.confirmed?.gross_margin ?? 0
                    },
                    "senior": {
                        "produced_days": dailyRateResult[key]?.senior?.produced_days ?? 0,
                        "billed_days": dailyRateResult[key]?.senior?.billed_days ?? 0,
                        "mission_value": dailyRateResult[key]?.senior?.mission_value ?? 0,
                        "gross_margin": dailyRateResult[key]?.senior?.gross_margin ?? 0
                    }
                }
            }

            return dasS;
        }

        return getDasS();
    }
}
