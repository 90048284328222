import {IdcardFilled} from '@ant-design/icons';
import {EuroCircleFilled} from '@ant-design/icons';
import {CreditCardFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import TabCard from '../../../../components/ui/TabCard/TabCard';
import {expenseAnalysisLeftChartTabConfigs} from '../../configs/expenseAnalysisLeftChartTabConfigs';
import {expenseAnalysisRightChartTabConfigs} from '../../configs/expenseAnalysisRightChartTabConfigs';
import {transformThousandNumbers} from '../../../../utils';

const ExpenseAnalysis = ({
    data,
    datePickerValue,
    onDatePickerChange
}) => {
    const IdcardIcon = <IdcardFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const EuroCircleIcon = <EuroCircleFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const CreditCardIcon = <CreditCardFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = (value) => {
        let sum = 0;

        for(const key in data.by_das) {
            sum += data.by_das[key][value];
        }

        return sum;
    }

    const getPercent = (value) => {
        if (!value || !data.gross_margin) {
            return 0;
        }
        else {
            return +((value / data.gross_margin) * 100).toFixed(5);
        }
    }

    return (
        <div className='my-10'>
            <LineTitle title='Analyse des frais'/>
            <div className='mt-10 mb-6'>
                <Datepicker
                    type='range'
                    defaultValue={datePickerValue}
                    onChange={onDatePickerChange}
                />
            </div>
            <div className='flex flex-row'>
                <SimpleCard
                    width='w-full'
                    iconComponent={IdcardIcon}
                    title={transformThousandNumbers(getSum('expenses'))}
                    sideTitle={`(${getPercent(getSum('expenses'))}% du CA)`}
                    subTitle="Frais des consultants"
                    background='orange'
                    bottomComponent={
                        <BottomComponent
                            data={data.by_das}
                            value='expenses'
                            getPercent={getPercent}
                        />
                    }
                />
                <div className='mx-5'/>
                <SimpleCard
                    width='w-full'
                    iconComponent={EuroCircleIcon}
                    title={transformThousandNumbers(getSum('procurements'))}
                    sideTitle={`(${getPercent(getSum('procurements'))}% du CA)`}
                    subTitle="Achats directs"
                    background='orange'
                    bottomComponent={
                        <BottomComponent
                            data={data.by_das}
                            value='procurements'
                            getPercent={getPercent}
                        />
                    }
                />
                <div className='mx-5'/>
                <SimpleCard
                    width='w-full'
                    iconComponent={CreditCardIcon}
                    title={transformThousandNumbers(getSum('billable_expenses'))}
                    sideTitle={`(${getPercent(getSum('billable_expenses'))}% du CA)`}
                    subTitle="Frais refacturables"
                    background='orange'
                    bottomComponent={
                        <BottomComponent
                            data={data.by_das}
                            value='billable_expenses'
                            getPercent={getPercent}
                        />
                    }
                />
            </div>
            <div
                className='flex flex-row items-center mt-6 rounded-lg'
                style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
            >
                <div className='w-1/2'>
                    <TabCard config={expenseAnalysisLeftChartTabConfigs(data.by_das)}/>
                </div>
                <div className='w-1/2'>
                    <TabCard config={expenseAnalysisRightChartTabConfigs(data.by_office)}/>
                </div>
            </div>
        </div>
    )
}

export default ExpenseAnalysis;

const BottomComponent = ({
    data,
    value,
    getPercent
}) => {
    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            value: transformThousandNumbers(data[key][value]),
            percent: getPercent(data[key][value])
        });
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <span className='text-sm font-medium text-gradient'>{el.das}</span>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                                <span className='text-sm font-normal text-gradient'>
                                    {`(${el.percent}%)`}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
