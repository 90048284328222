import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';

import {frenchFormat, fetchSelectData, handleFilterList, useFilterQuery} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import { UserActions } from '../../../actions';
import { SelectInput, TableDatePicker } from '../../../components/ui/Table';

export const expensesTableConfig = (
    handleDeleteExpenseReport,
    actionButtonLoading,
    handlePayExpenseReport,
    filterExpenseReportList,
    tableType,
    userData
) => {
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        after: '',
        before: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterExpenseReportList, tableType);

    // ↓ get data for select input
    const handleGetUsers = async (search, page) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search, page: page || null, setter: setUsers, userData});
    };

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        });
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'ID',
                    value: 'id',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        const expenseReportStatus = () => {
                            switch (item.status) {
                                case 0:
                                    return {status: 'En cours', color: null};
                                case 1:
                                    return {status: 'À vérifier', color: '#f0a841'};
                                case 2:
                                    return {status: 'À payer', color: '#e36d38'};
                                case 3:
                                    return {status: 'Payée', color: '#ced95f'};
                                default:
                                    return {status: 'Indéfini', color: '#212121'};
                            }
                        };

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={expenseReportStatus().status} color={expenseReportStatus().color}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Ressource',
                    value: 'ressource',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={[item.ressource]} type='rounded'/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="ressource"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de paiement',
                    value: 'payment_date',
                    width: 'w-[14%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.status === 3 ? frenchFormat(item.payment_date) : '-'}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name={null} onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant HT (€)',
                    value: 'amount',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(item.amount).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: ''
                },
                {
                    label: 'TVA (%)',
                    value: 'vat',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(item.vat).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de début',
                    value: 'start_date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.start_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de fin',
                    value: 'end_date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.end_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[6%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.status === 2 &&
                                    <div className  = "flex">
                                        <ActionsButton
                                            type="pay"
                                            isLoading={actionButtonLoading === `${item.id}upd`}
                                            onClick={() => handlePayExpenseReport(item)}
                                            disabled={false}
                                            payConfirmationQuestion='Souhaitez-vous payer cette note de frais ?'
                                        />
                                        <ActionsButton
                                            type="delete"
                                            isLoading={actionButtonLoading === `${item.id}del`}
                                            onClick={() => handleDeleteExpenseReport(item)}
                                            disabled={false}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette note de frais ?'

                                        />
                                    </div>
                                }
                                {
                                    item.status === 1 &&
                                        <ActionsButton
                                            type="delete"
                                            isLoading={actionButtonLoading === `${item.id}del`}
                                            onClick={() => handleDeleteExpenseReport(item)}
                                            disabled={false}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette note de frais ?'
                                        />
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => navigate(`/expenses/${item.id}`)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
