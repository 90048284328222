import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import {fetchSelectData} from '../../../../utils';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import Button from '../../../../components/ui/Button';
import {CustomerActions, CustomerCategoryActions, LocationActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import LocationPopup from './LocationPopup';

import plus from '../../../../assets/icons/button/plus.png';
import Tooltip from 'antd/lib/tooltip';
import Popup from '../../../../components/ui/Popup';
const CustomerPopup = ({
    userData,
    updateCustomer,
    refreshdata,
    setView,
    setValue
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [customerLocations, setCustomerLocations] = useState(null);
    const [customerCategories, setCustomerCategories] = useState(null);

    const [locationPopupView, setLocationPopupView] = useState(false);

    // -- react hook form --
    const defaultValues = {
        name: updateCustomer ? updateCustomer.name : '',
        location: updateCustomer ? {name: updateCustomer.location} : '',
        category: updateCustomer ? {name: updateCustomer.category} : '',
        notes: updateCustomer ? updateCustomer.notes : ''
    };

    const {handleSubmit, control, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- functions that get data for Select Inputs --
    const handleGetLocations = async (search, page) => {
        await fetchSelectData({
            action: LocationActions.getAllLocation,
            search:`&search=${search}&is_active=true`,
            page: page || null,
            setter: setCustomerLocations,
            userData,
            resultPath: 'data'
        });
    };

    const handleGetCustomerCategories = async (search, page) => {
        await fetchSelectData({
            action: CustomerCategoryActions.getAllCustomerCategories,
            search,
            page: page || null,
            setter: setCustomerCategories,
            userData,
            resultPath:
            'data'
        });
    };

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const customerPayload = {
            name: data.name,
            location: data.location.name,
            category: data.category.name,
            notes: data.notes
        }

        if (updateCustomer) {
            const result = await CustomerActions.updateCustomer(userData, customerPayload, updateCustomer.id);

            if (result.success) {
                await refreshdata();
                setView(false);
                notify('Le client a bien été mis à jour');
            }
            else {
                setErrors(getErrors(result));
            }
        }
        else {
            const result = await CustomerActions.createCustomer(userData, customerPayload);
    
            if (result.success) {
                await refreshdata();
                setValue && setValue('customer', result.data)
                setView(false);
                notify('Le client à bien été créé');
            }
            else {
                setErrors(getErrors(result));
            }
        }
        
        setLoading(false);
        setDisabled(false);
    }

    return (
        <>
            <ContainerCard
                title={updateCustomer ? 'Éditer client' : 'Nouveau client'}
                coloredButtonDisplayed={false}
                overflow='overflow-visible'
            >
                <div className="w-full px-4 pb-2 mb-6 border-b border-gray-200">
                    <div className='flex flex-col justify-center'>
                        <div className="mb-3 ">
                            <Controller
                                name="name"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir un nom'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <TextInput
                                        label='Nom du client'
                                        width='w-[800px]'
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="Nom du client"
                                        type="text"
                                        labelWidth='w-48'
                                    />
                                )}
                            />
                        </div>
                        <div className="mb-3 relative">
                            <Controller
                                name="location"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir un secteur géographique'
                                }}}
                                render={({
                                    field: {onChange, ref, value},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        width='w-[800px]'
                                        label='Département du siège'
                                        placeholder='Département du siège'
                                        inputRef={ref}
                                        error={error}
                                        value={value}
                                        fetchFunction={handleGetLocations}
                                        options={customerLocations?.options}
                                        onChange={onChange}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={disabled}
                                        labelWidth='w-48'
                                    />
                                )
                                }
                            />
                        </div>
                        <div className='flex items-center justify-center pb-3 absolute right-[7px] top-[130px]'>
                            <Tooltip title='Ajouter un département'>
                                <button
                                    onClick={() => setLocationPopupView(true)}
                                    disabled={disabled}
                                    // className={`hover:opacity-90 p-2 rounded-full ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}`}
                                    className={`hover:opacity-90 p-2 rounded-full background-gradient`}
                                >
                                    <img src={plus} alt='plus' className='w-3'/>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="">
                        <Controller
                            name="category"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir une catégorie'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    width='w-[800px]'
                                    label='Type de client'
                                    placeholder='Type de client'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    fetchFunction={handleGetCustomerCategories}
                                    options={customerCategories?.options}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="py-4 mx-4 border-t border-borderGrey">
                    <Controller
                        name="notes"
                        control={control}
                        render={({
                            field: {onChange, value}
                        }) => (
                            <TextAreaInput
                                height='h-40'
                                placeholder={'Tapez votre texte'}
                                maxLength={500}
                                onChange={onChange}
                                value={value}
                                isDisabled={disabled}
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col justify-center pb-2 mt-2">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content={updateCustomer ? 'Modifier le client' : 'Ajouter le client'}
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
            <Popup
                view={locationPopupView}
                setView={setLocationPopupView}>
                <LocationPopup
                    setView={setLocationPopupView}
                    userData={userData}
                    refreshData={handleGetLocations}
                />
            </Popup>
            
        </>
    );
}

export default CustomerPopup;
