import {ErrorModel} from '../../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../../root';
const FUNCTION_CODE = 'EXPORTS_ONGOING';

export async function exportOngoing(userToken: string, payload: Record<string, unknown>) {
    try {
        const response = await axios({
            method: 'post',
            url: `${URL}/dashboards/partner/exports/ongoing/`,
            data: payload,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
