import { useState } from 'react'

import {Toast, notify} from '../../../../components/ui/Toast/Toast';
import LineTitle from '../../../../components/ui/LineTitle';
import Table from '../../../../components/ui/Table';
import Datepicker from '../../../../components/ui/Datepicker';
import {useSelector} from 'react-redux';

import {firstDayMonth, lastDayMonth} from '../../../../utils';
import {closedDealsCustomerTableConfig} from '../../config/closedDealsCustomerTableConfig';

import { DealActions } from '../../../../actions';

const ClosedDealsCustomer = ({
    data,
    datePickerDate,
    setDatePickerDate,
    refreshData,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {

    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDatePickerChange = (e) => {
        setDatePickerDate({
            startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
            endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
        });
    };

    const handleSendToCustomer = async (id) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(id);

        const updateDeal = await DealActions.updateDeal(userData, {satisfaction_survey_status: "sent"}, id);

        if (updateDeal.success) {
            await refreshData();
            notify("L'enquête de satisfaction a été envoyée au client avec succès");
        }
        else {
            notify("Une erreur est survenue lors de l'envoi de l'enquête de satisfaction au client");
        }

        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    }

    const tableConfig = closedDealsCustomerTableConfig(handleSendToCustomer, actionButtonLoading, actionButtonDisabled);

    return (
        <div>
            <Toast/>
            <div className='my-10'>
                <LineTitle title='Affaires closes validées à envoyer aux clients'/>
                <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                    <div className="w-full py-2 mb-4">
                        <Datepicker
                            type="range"
                            onChange={handleDatePickerChange}
                            defaultValue={datePickerDate}
                        />
                    </div>
                    <Table
                        data={data}
                        tableConfig={tableConfig}
                        paginationMetadata={paginationMetadata}
                        onPageNumberChange={onPageNumberChange}
                        loading={tableLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default ClosedDealsCustomer;
