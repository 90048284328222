import {useState, useEffect} from 'react';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {frenchFormat, handleFilterList, useFilterQuery, fetchSelectData} from '../../../../utils';
import ActionsButton from '../../../../components/ui/ActionsButton';
import {ExpenseTypeActions} from '../../../../actions';
import {SelectInput} from './../../../../components/ui/Table';

export const expensesTableConfig = (
    handleEditExpenseClick,
    handleDeleteExpenseClick,
    buttonsDisabled,
    deleteExpenseLoading,
    missionStatus,
    filterExpenseList,
    userData,
    cannotBeModified
) => {
    const [types, setTypes] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        type: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterExpenseList);

    // ↓ get data for select input
    const handleGetExpenseTypes = async (search, page = 1) => {
        await fetchSelectData({action: ExpenseTypeActions.getAllExpenseTypes, search:`&search=${search}`, page: page, setter: setTypes, userData, resultPath: 'data'});
    }

    useEffect(() => {
        // ↓ expense types dropdown values
        (async () => {
            await handleGetExpenseTypes('', 1);
        });
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Type',
                    value: 'type.group',
                    width: 'w-[25%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.type.group}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="category"
                        options={types?.options}
                        hasNextPage={types?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetExpenseTypes)}
                        labelKeys={['name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Refacturable',
                    value: 'billable',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.billable ? 'Oui' : 'Non'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant',
                    value: 'amount',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        console.log("ITEM", item);

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(parseInt(item.amount).toLocaleString())}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'TVA',
                    value: 'vat',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(item.vat)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: null,
                    width: 'w-[16%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.notes.length > 0 ? item.notes : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    missionStatus === 2 || cannotBeModified ?
                                        <div className="flex flex-row items-center justify-center">
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </div> :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => handleEditExpenseClick(item)}
                                                disabled={buttonsDisabled}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteExpenseLoading === item.id}
                                                onClick={() => handleDeleteExpenseClick(item)}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce frais ?'
                                                disabled={buttonsDisabled}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
