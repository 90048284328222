import {ACTION_CODE} from './_actionCode';
import {ActivityTypesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'UPDATE_ACTIVITY_TYPES';

export async function updateActivityTypes(userData: Record<string, string>, activityTypeId: number | string, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - updateActivityTypes - Start');

        const result = await ActivityTypesService.updateActivityTypes(userData, activityTypeId, payload);

        LoggerService.logInfo('Action - updateActivityTypes - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateActivityTypes - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la mise à jour de l\'activité',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
