/* eslint-disable max-len */
import TjmTabChildren from "../../components/productionTab/TjmTabChildren";
import {realRateValue, realRateValueCabinet, aggregateCabinetData, aggregateCabinetChartData} from "../../functions/aggregateTjm";

export const tjmTabConfig = (productionLoad) => {

    const cabinetData = aggregateCabinetData(productionLoad)

    const cabinetChartData = aggregateCabinetChartData(productionLoad)

    const dasCountLength = Object.keys(productionLoad.load).length;

    // TABS
    let tabs = [                {
        id: 1,
        label: 'Cabinet',
        component: <TjmTabChildren
            chartData={
                {
                    juniorValue: cabinetChartData.cabinetJuniorValue,
                    seniorValue: cabinetChartData.cabinetSeniorValue,
                    confirmedValue: cabinetChartData.cabinetConfirmedValue,
                    dasCount: dasCountLength
                }
            }
            configData={
                {
                    juniorValue: cabinetData.cabinetJuniorValue,
                    seniorValue: cabinetData.cabinetSeniorValue,
                    confirmedValue: cabinetData.cabinetConfirmedValue,
                    dasCount: dasCountLength
                }
            }

            realRate={
                {
                    juniorValue: realRateValueCabinet(productionLoad, "Junior"),
                    seniorValue: realRateValueCabinet(productionLoad, "Senior"),
                    confirmedValue: realRateValueCabinet(productionLoad, "Confirmé"),
                    dasCount: dasCountLength
                }
            }
            dasCount={dasCountLength}

        />

    }];

    for (const key in productionLoad.load) {
        const item = {
            id: key,
            label: key,
            component: <TjmTabChildren
                chartData={{
                    juniorValue: parseInt(productionLoad.billing[key].Junior.billed_days.toFixed(2)),
                    seniorValue: parseInt(productionLoad.billing[key].Senior.billed_days.toFixed(2)),
                    confirmedValue: parseInt(productionLoad.billing[key].Confirmé.billed_days.toFixed(2))
                }}
                configData={{
                    juniorValue: productionLoad.billing[key].Junior.daily_rate,
                    seniorValue: productionLoad.billing[key].Senior.daily_rate,
                    confirmedValue: productionLoad.billing[key].Confirmé.daily_rate
                }}
                realRate={
                    {juniorValue: realRateValue(productionLoad, key, "Junior"),
                        seniorValue: realRateValue(productionLoad, key, "Senior"),
                        confirmedValue: realRateValue(productionLoad, key, "Confirmé")
                    }}

            />

        }
    
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
