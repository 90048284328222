import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import Table from '../../components/ui/Table';
import {dealsTableConfig} from './config/dealsTableConfig';

const PendingProposalsPage = ({
    data,
    tableType,
    handleMissionNavigation,
    userData,
    filterDealList,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {

    const tableConfig = dealsTableConfig(tableType, handleMissionNavigation, userData, filterDealList);

    return (
        <>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Propositions en cours de réponse (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default PendingProposalsPage;
