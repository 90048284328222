import {ContractorDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_CONTRACTORS';

export async function deleteContractorInvoice(
    userData: Record<string, string>,
    id: number
) {
    try {
        return await ContractorDao.deleteContractorInvoice(userData.userToken, id);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
