export const productionActivityDto = {
    from(productionActivityResult) {

        const getDealsData = () => {
            let dasS = {};

            if (!productionActivityResult.deals) {
                return dasS;
            }
            else {
                for (const key in productionActivityResult.deals) {
                    dasS[key] = {
                        "count": productionActivityResult.deals?.[key]?.count ?? 0
                    }
                }

                return dasS;
            }
        }

        const getMissionsData = () => {
            let dasS = {};

            if (!productionActivityResult.missions) {
                return dasS;
            }
            else {
                for (const key in productionActivityResult.missions) {
                    dasS[key] = {
                        "count": productionActivityResult.missions?.[key]?.count ?? 0
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "deals": getDealsData(),
                "missions": getMissionsData()
            }
        );
    }

}
