import {ACTION_CODE} from './_actionCode';
import {TrainingObjectiveService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_TRAINING_OBJECTIVE';

export async function deleteTrainingObjective(userData: Record<string, string>, id: number) {
    try {
        LoggerService.logInfo('Action - deleteTrainingObjective - Start');

        const deleteTrainingObjectiveResult = await TrainingObjectiveService.deleteTrainingObjective(userData, id);

        LoggerService.logInfo('Action - deleteTrainingObjective - End - Success');

        return ResultModel.newSuccessResult(deleteTrainingObjectiveResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteTrainingObjective - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
