import {useEffect, useState} from 'react';

import {fetchSelectData, handleFilterList, useFilterQuery} from '../../../../utils';
import {CustomerCategoryActions, LocationActions} from '../../../../actions'
import ActionsButton from '../../../../components/ui/ActionsButton';
import {SearchInput, SelectInput} from '../../../../components/ui/Table';

export const customersTableConfig = (handleCustomerNavigation, handleUpdateCustomer, userData, filterCustomerList) => {
    const [categories, setCategories] = useState([]);
    const [locations, setLocations] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        search: '',
        location: '',
        category: ''
    });

    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterCustomerList);

    // ↓ get data for select input
    const handleGetCategories = async (search, page = 1) => {
        await fetchSelectData({action: CustomerCategoryActions.getAllCustomerCategories, search, page: page || null, setter: setCategories, userData, resultPath: 'data'});
    };

    const handleGetLocations = async (search, page = 1) => {
        await fetchSelectData({action: LocationActions.getAllLocation, search:`&search=${search}&is_active=true`, page: page || null, setter: setLocations, userData, resultPath: 'data'});
    }

    useEffect(() => {
        // ↓ categories dropdown values
        (async () => {
            await handleGetCategories('', 1);
        })();
        
        // ↓ location dropdown values
        (async () => {
            await handleGetLocations('', 1);
        })
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Id',
                    value: 'id',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item?.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Client',
                    value: 'name',
                    width: 'w-[20%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item?.name}
                            </div>
                        );
                    },
                    filterComponent: () => <SearchInput name={'search'} onChange={handleFilter}/>,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Catégorie',
                    value: 'category',
                    width: 'w-[15%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item?.category}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="category"
                        options={categories?.options}
                        hasNextPage={categories?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetCategories)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Secteur géographique',
                    value: 'location',
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item?.location}
                            </div>
                        )
                    },
                    filterComponent: () => <SelectInput
                        name="location"
                        options={locations?.options}
                        hasNextPage={locations?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetLocations)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Affaires',
                    value: 'deals',
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item?.number_deals ? item.number_deals : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Factures',
                    value: 'invoices',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item?.number_invoices ? item.number_invoices : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: 'notes',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.notes ? item.notes : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center gap-1">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={() => handleUpdateCustomer(item)}
                                    disabled={false}
                                />
                                <div className="w-0 h-0 px-1"/>
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => handleCustomerNavigation(item?.id)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
