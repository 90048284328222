import {ReferenceDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_REFERENCES';

export async function getAllReferences(userData: Record<string, string>, params: string, page: number) {
    try {

        const result = await ReferenceDao.getAllReferences(userData, params, page);

        const metadata = {
            count: result.count,
            next: result.next,
            previous: result.previous
        };

        return {
            metadata,
            data: result
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des références',
            error: error
        });
    }
}
