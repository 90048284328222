import React from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';

const BankAccountCard = ({data}) => {
    return (
        <ContainerCard
            title='Compte bancaire'
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[361px]'
        >
            <div className='flex flex-col'>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Filiale</div>
                    <div className='text-[#212121] text-base'>{data?.subsidiary}</div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Nom</div>
                    <div className='text-[#212121] text-base'>{data?.name}</div>
                </div>
                <hr className='w-12 my-5 border-[#212121]'/>
                <div className='flex items-start justify-start'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>IBAN</div>
                    <div className='text-[#212121] text-base'>{data?.iban}</div>
                </div>
                <div className='flex items-start justify-start my-2'>
                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>BIC</div>
                    <div className='text-[#212121] text-base'>{data?.bic}</div>
                </div>
            </div>
        </ContainerCard>
    )
}

export default BankAccountCard
