import {useEffect, useState} from 'react';
import {useParams, Link, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {ExpenseReportActions, ExpenseActions} from '../../actions';
import {notify, Toast} from '../../components/ui/Toast/Toast';
import {getAggregatedExpenseReportById} from '../../actions/expenseReportActions';
import {ExpenseAttachmentActions} from '../../actions';
import Layout from '../../components/layout';
import returnIcon from '../../assets/icons/page/orange-left-arrow.png';
import RoadStep from '../../components/ui/RoadStep';
import roadStepConfig from './configs/roadStepConfig';
import TopBanner from '../../components/ui/TopBanner';
import {topBannerConfig} from './configs/topBannerConfig';
import {expenseListConfig} from './configs/expenseListConfig';
import {expenseReportTabCardConfig} from './configs/expenseReportTabCardConfig';
import ExpenseInfoCard from './components/ExpenseInfoCard';
import ExpenseListCard from './components/ExpenseListCard';
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import TabCard from '../../components/ui/TabCard';
import AttachmentsCard from './components/AttachmentsCard';
import CreateNewExpensePopup from './components/CreateExpensePopup/CreateNewExpensePopup';
import CreateNewExpenseAttachmentPopup from './components/CreateNewExpenseAttachmentPopup';
import InvoicePaymentDatePopup from './components/InvoicePaymentDatePopup';
import ExpenseAttachmentPicturePopup from './components/ExpenseAttachmentPicturePopup';
import Popup from '../../components/ui/Popup/Popup';

const ExpensesReportPageDetails = () => {
    const [expenseReportData, setExpenseReportData] = useState([]);
    const [attachmentsData, setAttachmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteExpenseReportLoading, setDeleteExpenseReportLoading] = useState(false);
    const [deleteExpenseLoading, setDeleteExpenseLoading] = useState(false);
    const [deleteAttachmentLoading, setDeleteAttachmentLoading] = useState(false);
    const [downloadAttachmentLoading, setDownloadAttachmentLoading] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [isExpensePopupOpen, setIsExpensePopupOpen] = useState(false);
    const [isExpenseAttachmentPopupOpen, setIsExpenseAttachmentPopupOpen] = useState(false);
    const [expenseToBeUpdated, setExpenseToBeUpdated] = useState(null);
    const [picturePopupVisible, setPicturePopupVisible] = useState(false);
    const [imageClickedUrl, setImageClickedUrl] = useState('');

    const {id} = useParams();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // Expense data
    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await getAggregatedExpenseReportById(userData, id);

            if (result.success) {
                setExpenseReportData(result.data);
            }
            else {
                navigate('/expenses', {state: 'invalid_id'});
            }
            setLoading(false);
        })();
    }, [id]);

    // Attachment expenses data
    useEffect(() => {
        (async () => {
            const result = await ExpenseAttachmentActions.getAllExpenseAttachments(userData, id);

            if (result.success) {
                setAttachmentsData(result.data);
            }
            else {
                notify('Échec de la récuperation de la donnée des justificatifs');
            }
        })();
    }, [id])

    // functions
    const refreshData = async () => {
        const resultExpense = await getAggregatedExpenseReportById(userData, id);

        if (resultExpense.success) {
            setExpenseReportData(resultExpense.data);
        }
        else {
            notify('Échec de la mise à jour des données');
        }

        const resultAttachments = await ExpenseAttachmentActions.getAllExpenseAttachments(userData, id);

        if (resultAttachments.success) {
            setAttachmentsData(resultAttachments.data);
        }
        else {
            notify('Échec de la mise à jour des justificatifs');
        }
    };

    const onDeleteExpenseReport = async () => {
        setButtonsDisabled(true);
        setDeleteExpenseReportLoading(true);

        const result = await ExpenseReportActions.deleteExpenseReportById(userData, expenseReportData.id);

        if (result.success) {
            navigate('/expenses', {state: 'expense_report_deleted'});
        }
        else {
            notify('Votre note de frais n\'a pas pu être supprimé');
        }
        setDeleteExpenseReportLoading(false);
        setButtonsDisabled(false);
    };

    const onDeleteExpense = async (item) => {
        setButtonsDisabled(true);
        setDeleteExpenseLoading(item.id);

        const result = await ExpenseActions.deleteExpense(userData, item);

        if (result.success && result.data.expense_report) {
            await refreshData();
            notify('Votre frais a bien été supprimé');
        }
        else if (result.success && !result.data.expense_report) {
            navigate('/expenses', {state: 'expense_report_deleted'});
        }
        else {
            notify('Votre frais n\'a pas pu être supprimé');
        }

        setDeleteExpenseLoading(null);
        setButtonsDisabled(false);
    };

    const handleNewExpenseButtonClick = () => {
        setIsExpensePopupOpen(true);
        setExpenseToBeUpdated(null);
    };

    const handleNewAttachmentButtonClick = () => {
        setIsExpenseAttachmentPopupOpen(true);
    }

    const handleDownloadButtonAttachmentClick = async (id, name) => {

        setDownloadAttachmentLoading(id);

        const expenseAttachmentDownload = await ExpenseAttachmentActions.downloadExpenseAttachment(userData, id);

        if (!expenseAttachmentDownload.success) {
            notify('Votre justificatif n\'a pas pu être téléchargé');
            setDownloadAttachmentLoading(null);

            return;
        }

        const blob = new Blob([expenseAttachmentDownload.data]);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        setDownloadAttachmentLoading(null);

    }

    const handleDeleteButtonAttachmentClick = async (id) => {
        setDeleteAttachmentLoading(id);

        const deleteResult = await ExpenseAttachmentActions.deleteExpenseAttachment(userData, id);

        if (deleteResult.success) {
            notify('Votre justificatif à bien été supprimé');
            await refreshData();
        }
        else {
            notify('Votre justificatif n\'a pas pu être supprimé');
        }

        setDeleteAttachmentLoading(null);

    }

    const handleAttachmentItemClick = (url) => {
        setPicturePopupVisible(true)
        setImageClickedUrl(url)
    }

    // Configs
    const bannerConfig = topBannerConfig(onDeleteExpenseReport, deleteExpenseReportLoading, buttonsDisabled);

    const expenseListTableConfig = expenseListConfig(
        onDeleteExpense,
        deleteExpenseLoading,
        setIsExpensePopupOpen,
        setExpenseToBeUpdated,
        buttonsDisabled
    );
    
    const tabCardConfig = expenseReportTabCardConfig(expenseReportData.children);

    return (
        <Layout>
            <PageLoader loading={loading}>
                <div className="relative">
                    <Toast/>
                    <div className="relative flex items-center justify-center h-32 py-10">
                        <Link to='/expenses'>
                            <img
                                className="absolute w-7 top-8 left-20"
                                src={returnIcon}
                                alt='return'
                            />
                        </Link>
                        <RoadStep step={expenseReportData.status} config={roadStepConfig}/>
                    </div>
                    <TopBanner
                        title={`${expenseReportData?.ressource?.first_name + " " + expenseReportData?.ressource?.last_name}`}
                        status={expenseReportData.status}
                        rightComponentContent={{start_date: expenseReportData.start_date, end_date: expenseReportData.end_date}}
                        config={bannerConfig}
                    />
                </div>
                <ExpenseInfoCard
                    id={expenseReportData.id}
                    startDate={expenseReportData.start_date}
                    endDate={expenseReportData.end_date}
                    ressource={expenseReportData.ressource}
                />
                <ExpenseListCard
                    data={expenseReportData.children}
                    tableConfig={expenseListTableConfig}
                    onButtonClick={handleNewExpenseButtonClick}
                    newExpenseButtonDisabled={expenseReportData.status === 3}
                />
                <div className='flex items-center justify-center mb-10 px-14'>
                    <TabCard config={tabCardConfig}/>
                    <div className='mx-5'/>
                    <AttachmentsCard
                        onButtonClick={() => setIsExpenseAttachmentPopupOpen(true)}
                        data={attachmentsData}
                        onNewAttachmentButtonClick={handleNewAttachmentButtonClick}
                        onAttachmentItemClick={handleAttachmentItemClick}
                        onDownloadButtonClick={handleDownloadButtonAttachmentClick}
                        onDeleteButtonClick={handleDeleteButtonAttachmentClick}
                        newAttachmentButtonDisabled={expenseReportData.status === 3}
                        downloadAttachmentLoading={downloadAttachmentLoading}
                        deleteAttachmentLoading={deleteAttachmentLoading}
                        buttonsDisabled={buttonsDisabled}
                    />
                </div>
            </PageLoader>
            <CreateNewExpensePopup
                view={isExpensePopupOpen}
                setView={setIsExpensePopupOpen}
                expenseToBeUpdated={expenseToBeUpdated}
                userData={userData}
                refreshData={refreshData}
            />
            <Popup
                view={isExpenseAttachmentPopupOpen}
                setView={setIsExpenseAttachmentPopupOpen}
                width={'680px'}
            >
                <CreateNewExpenseAttachmentPopup
                    setView={setIsExpenseAttachmentPopupOpen}
                    userData={userData}
                    reportId={expenseReportData.id}
                    refreshData={refreshData}
                />
            </Popup>
            <InvoicePaymentDatePopup
                view={false}
                setView={null}
                onSubmit={() => {return null}}
            />
            <ExpenseAttachmentPicturePopup
                view={picturePopupVisible}
                setView={setPicturePopupVisible}
                url={imageClickedUrl}
            />
        </Layout>
    );
};

export default ExpensesReportPageDetails;
