import ColumnChart from "../../../components/ui/ColumnChart/ColumnChart";
import {missionsProfitabilityChartConfig} from "./missionsProfitabilityChartConfig";

export const missionsProfitabilityChartTabConfig = (data) => {

    const legends = [
        {
            name: 'Affaires rentables\ncontribution supérieure à la moyenne'
        },
        {
            name: 'Affaires rentables\ncontribution inférieure à la moyenne'
        },
        {
            name: 'Affaires non-rentables'
        }
    ]

    const getObjectData = (das) => {
        return (
            [
                {
                    type: !das.deals.above_average.count ? 'Aucune affaire' : `${das.deals.above_average.count} affaires\n${das.deals.above_average.value}`,
                    values: !das.deals.above_average.value ? null : [das.deals.above_average.value, 0]
                },
                {
                    type: !das.deals.below_average.count ? 'Aucune affaire' : `${das.deals.below_average.count} affaires\n${das.deals.below_average.value}`,
                    values: !das.deals.below_average.value ? null : [das.deals.below_average.value, 0]
                },
                {
                    type: !das.deals.loss.count ? 'Aucune affaire' : `${das.deals.loss.count} affaires\n${das.deals.loss.value}`,
                    values: !das.deals.loss.value ? null : [das.deals.loss.value, 0]
                }
            ]
        );
    }

    const getSum = (key1, key2) => {
        let sum = 0;

        for (const k in data) {
            sum += data[k]['deals'][key1][key2];
        }

        return sum;
    }

    const getSumObject = () => {
        return (
            {
                deals : {
                    "above_average": {
                        "count": getSum('above_average', 'count'),
                        "value": getSum('above_average', 'value')
                    },
                    "below_average": {
                        "count": getSum('below_average', 'count'),
                        "value": getSum('below_average', 'value')
                    },
                    "loss": {
                        "count": getSum('loss', 'count'),
                        "value": getSum('loss', 'value')
                    }
                }
            }
        );
    }

    function getMaxNumber(numbers) {
        let maxNumber = numbers[0];
      
        for (let i = 1; i < numbers.length; i++) {
            if (numbers[i] > maxNumber) {
                maxNumber = numbers[i];
            }
        }
      
        return maxNumber;
    }

    function getMinNumber(numbers) {
        let minNumber = numbers[0];
      
        for (let i = 1; i < numbers.length; i++) {
            if (numbers[i] < minNumber) {
                minNumber = numbers[i];
            }
        }
      
        return minNumber;
    }

    const getNumberArrays = (das) => {
        return [
            das.deals.above_average.value,
            das.deals.below_average.value,
            das.deals.loss.value
        ];
    }

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <ColumnChart
            data={getObjectData(getSumObject())}
            config={missionsProfitabilityChartConfig}
            legends={legends}
            max={getMaxNumber(getNumberArrays(getSumObject()))}
            min={getMinNumber(getNumberArrays(getSumObject()))}
        />
    }];
    
    for (const key in data) {
        const item = {
            id: key,
            label: key,
            component: <ColumnChart
                data={getObjectData(data[key])}
                config={missionsProfitabilityChartConfig}
                legends={legends}
                max={getMaxNumber(getNumberArrays(data[key]))}
                min={getMinNumber(getNumberArrays(data[key]))}
            />
        }
    
        tabs.push(item);
    }
    
    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
