import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'UPDATE_CONTRACTOR_INVOICE';

export async function updateContractorInvoice(
    userToken: string,
    updatedContractorInvoice: Record<string, unknown>,
    id: string
) {
    try {
        const response = await axios({
            method: 'patch',
            url: `${URL}/contractor-invoices/${id}/`,
            data: updatedContractorInvoice,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mis à jour du fournisseur',
            error: error
        });
    }
}
