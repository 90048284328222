import React from 'react';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import {customerDealsTableConfig} from '../../configs/sectionConfigs/customerDealsTableConfig';

const DealsSection = ({
    data,
    dealsPaginationMetadata,
    handleGetAllDeals,
    dealsTableLoading
}) => {
    const dealActionsTableConfig = customerDealsTableConfig();

    return (
        <>
            <ContainerCard
                title='Affaires'
                coloredButtonDisplayed={false}
            >
                <Table
                    data={data}
                    tableConfig={dealActionsTableConfig}
                    paginationMetadata={dealsPaginationMetadata}
                    onPageNumberChange={handleGetAllDeals}
                    loading={dealsTableLoading}
                />
            </ContainerCard>
        </>
    )
}

export default DealsSection;
