import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'DOWNLOAD_EXPENSE_ATTACHMENT';

export async function downloadExpenseAttachment(
    userToken: string,
    id: string
) {
    try {
        const response = await axios.get(
            // eslint-disable-next-line max-len
            `${URL}/expense-attachments/${id}/download/`,
            {
                headers: {
                    Authorization: `Token ${userToken}`
                },
                responseType: 'arraybuffer'
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de le téléchargement de la pièce jointe',
            error: error
        });
    }
}
