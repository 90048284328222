import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GET_CONTRACTORS';

export async function getContractors(userData: Record<string, any>, params: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - getContractors - Start');

        const getContractors =
            await DashboardService.OfficeManagerService.getContractors(userData, params);

        LoggerService.logInfo('Action - getContractors - End - Success');

        return ResultModel.newSuccessResult(getContractors);
    }
    catch (error) {
        LoggerService.logInfo('Action - getContractors - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
