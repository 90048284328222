import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import Layout from '../../components/layout';
import ContractorsDataSection from './components/ContractorsSection';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import {contractorsTableConfig} from './configs/contractorsTableConfig';
import {ContractorActions} from '../../actions';
import Popup from '../../components/ui/Popup/Popup';
import NewContractorPopup from './components/popups/NewContractorPopup';
import ContactsPopup from './components/popups/ContactsPopup';
import contactsTableConfig from './configs/contactsTableConfig';

function ContractorsPage() {
    // States

    // Pagination
    const [contractorsPaginationMetadata, setContractorsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    // Filter
    const [contractorsFilterQuery, setContractorsFilterQuery] = useState('');

    const [contractorsData, setContractorsData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [newPopupView, setNewPopupView] = useState(false);
    const [contactPopupView, setContactPopupView] = useState(false);
    const [toBeupdated, setToBeUpdated] = useState(null);
    const [contactPopupData, setContactPopupData] = useState([]);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const navigate = useNavigate();

    // Get data
    const handleGetAllContractors = async (page, activeLoading = true) => {
        if (activeLoading) {
            setLoadingTable(true);
            setContractorsData([]);
        }
        
        const result = await ContractorActions.getAllContractors(
            userData,
            `${contractorsFilterQuery}&page_size=${contractorsPaginationMetadata.pageSize}`,
            page
        );

        if (result.success) {
            setContractorsData(result.data);

            setContractorsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (contractorsPaginationMetadata.totalItems !== result.metadata.count) {
                setContractorsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des affaires');
        }

        setLoadingTable(false);
    }

    useEffect(() => {
        handleGetAllContractors(1);
    }, [])

    useEffect(() => {
        handleGetAllContractors(1);
    }, [contractorsFilterQuery])

    const handleNavigation = async (contractorId) => {
        navigate(`${contractorId}`);
    }

    const filterContractorsList = async (query) => {
        setContractorsFilterQuery(query);
    }

    const refreshContractorData = async () => {
        handleGetAllContractors(1, false);
    }

    // Configs
    const tableConfig = contractorsTableConfig(
        handleNavigation,
        setContactPopupView,
        filterContractorsList,
        userData,
        setToBeUpdated,
        setContactPopupData,
        setNewPopupView
    );

    const contactsTable = contactsTableConfig(
        handleNavigation,
        setContactPopupView,
        filterContractorsList,
        userData,
        setToBeUpdated,
        setNewPopupView
    );

    return (
        <Layout>
            <Toast/>
            <ContractorsDataSection
                data={contractorsData}
                tableConfig={tableConfig}
                tableLoading={loadingTable}
                contractorsPaginationMetadata={contractorsPaginationMetadata}
                handleGetAllContractors={handleGetAllContractors}
                handleButtonClick={() => {setNewPopupView(true); setToBeUpdated(null)}}
            />
            <Popup
                view={newPopupView}
                setView={setNewPopupView}
                width={'1098px'}
                xOverflow=''
                yOverflow='overflow-y-auto'
                maxHeight='45rem'
            >
                <NewContractorPopup
                    setView={setNewPopupView}
                    userData={userData}
                    toBeUpdated={toBeupdated}
                    refreshData={refreshContractorData}
                />
            </Popup>
            <Popup
                view={contactPopupView}
                setView={setContactPopupView}
                width={'1150px'}
                yOverflow=''
                xOverflow=''>
                <ContactsPopup
                    data={contactPopupData}
                    tablePopupConfig={contactsTable}
                />
            </Popup>
        </Layout>
    );
}

export default ContractorsPage;
