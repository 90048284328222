import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextAreaInput from '../../../../../components/ui/Inputs/TextAreaInput';
import ContainerCard from '../../../../../components/ui/ContainerCard';
import TextInput from '../../../../../components/ui/Inputs/TextInput';
import Button from '../../../../../components/ui/Button';
import {notify} from '../../../../../components/ui/Toast/Toast';
import Error from '../../../../../components/ui/Error/Error';
import {getErrors} from '../../../../../utils';
import {UserActions, ProjectsActions} from "../../../../../actions"
import { fetchSelectData } from '../../../../../utils/fetchSelectData';
import DateInput from '../../../../../components/ui/Inputs/DateInput/DateInput';
import { frenchFormat, standardFormat } from '../../../../../utils';

const CreateNewProject = ({
    userData,
    toBeUpdated,
    refreshData,
    setView
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [ressources, setRessources] = useState(null)

    const getRessources = async (search, page = 1) => {
        await fetchSelectData({
            action: UserActions.getAllSortedUsers,
            search:`&search=${search}`,
            page: page,
            setter: setRessources,
            userData,
            resultPath: 'data.results'
        });
    };
    
    const status = [
        {
            id: 1,
            name: "Prévu",
            value: 150
        },
        {
            id: 2,
            name: "En cours",
            value: 500
        },
        {
            id: 3,
            name: "Terminé",
            value: 900
        }
    ];

    // -- react hook form --
    const defaultValues = {
        name: '',
        short_name: '',
        status: status[0],
        production_lead: "",
        mission_start_date: null,
        mission_end_date: null,
        billable_days: '',
        expense_budget: '',
        notes: ''
    };

    // -- fill expense values in form for update --
    useEffect(() => {
        if (toBeUpdated) {
            reset({
                name: toBeUpdated.name,
                short_name: toBeUpdated.short_name,
                status: status.find(el => el.value === toBeUpdated.status),
                production_lead: toBeUpdated.production_lead,
                mission_start_date: toBeUpdated.mission_start_date ? new Date(toBeUpdated.mission_start_date) : null,
                mission_end_date:  toBeUpdated.mission_end_date ? new Date(toBeUpdated.mission_end_date) : null,
                billable_days: toBeUpdated.billable_days,
                expense_budget: toBeUpdated.expense_budget,
                notes: toBeUpdated.notes
            });
        }
    }, [toBeUpdated]);

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const payload = {
            name: data.name,
            short_name: data.short_name,
            notes: data.notes,
            production_lead_id: data.production_lead.id,
            status: data.status.value,
            mission_start_date: data.mission_start_date ? standardFormat(data.mission_start_date) : null,
            mission_end_date: data.mission_end_date ? standardFormat(data.mission_end_date) : null,
            billable_days: data.billable_days.length > 0 ? data.billable_days : '0',
            expense_budget: data.expense_budget.length > 0 ? data.expense_budget : '0'
        }

        if (!toBeUpdated) {
            const result = await ProjectsActions.createProject(userData.userToken, payload);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                setView(false);
                notify("Le projet a bien été créé");
            }
        }
        else {
            const result = await ProjectsActions.updateProject(userData.userToken, toBeUpdated.id, payload);
            
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                setView(false);
                notify("Le projet a bien été mis à jour");
            }
        }
        
        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={"Ajouter un projet"}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-1">
                <div className='flex flex-col'>
                    <div className="mb-3">
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Nom complet'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Nom complet"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="short_name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Nom abrégé'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Nom abrégé"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="status"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir un statut'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Statut'
                                    placeholder='Statut'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={status}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                />
                            )
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="production_lead"
                            control={control}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Responsable'
                                    placeholder='Responsable'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    fetchFunction={getRessources}
                                    options={ressources?.options}
                                    loading={!ressources}
                                    hasNextPage={ressources?.hasNextPage}
                                    isSearchable={true}
                                    onChange={onChange}
                                    labelKeys={['first_name', 'last_name']}
                                    required={false}
                                    disabled={disabled}
                                />
                            )
                            }
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className="mb-3">
                        <Controller
                            name="mission_start_date"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date du début'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={false}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="mission_end_date"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date de fin'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={false}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="billable_days"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Chiffrage [J]'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={false}
                                    disabled={disabled}
                                    placeholder="Jours"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="expense_budget"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Frais [€]'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={false}
                                    disabled={disabled}
                                    placeholder="Euros"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            isDisabled={disabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={`${toBeUpdated ? "Modifier le projet" : "Ajouter un Projet"}`}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewProject;
