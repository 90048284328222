import {useState} from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';
import Table from '../../../components/ui/Table';
import {exportExpenseDataToCsv} from '../functions/exportDataToCsv';

const ExpenseListCard = ({tableConfig, data = [], onButtonClick, newExpenseButtonDisabled}) => {

    // CSV export states
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });
    
    const [csvExportLoading, setCsvExportLoading] = useState(false);

    return (
        <div className='my-6 px-14'>
            <ContainerCard
                onButtonClick={onButtonClick}
                title="Liste des frais"
                coloredButtonDisplayed={true}
                buttonContent="Nouveau frais"
                coloredButtonDisabled={newExpenseButtonDisabled}
                downloadButtonDisplayed={data.length > 0}
                onDownloadButtonClick={() => exportExpenseDataToCsv(
                    data,
                    setCsvProps,
                    setCsvExportLoading
                )}
                csvProps={csvProps}
                csvExportLoading={csvExportLoading}
            >
                <Table tableConfig={tableConfig} data={data}/>
            </ContainerCard>
        </div>
    );
};

export default ExpenseListCard;
