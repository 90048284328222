export const ressourceNumberChartConfig = (data, colors) => {
    return {
        data,
        isGroup: true,
        xField: 'date',
        yField: 'ressource',
        seriesField: 'name',
        color: colors,
        appendPadding: [40, 0, 0, 0],
        columnWidthRatio: 0.5,
        columnStyle: {
            width: 10
        },
        xAxis: {
            label: {
                style: {
                    fill: '#333',
                    fontSize: 16,
                    fontWeight: 'bold',
                    fontFamily: 'Din condensed'
                }
            }
        },
        annotations: [
            {
                type: 'text',
                position: ['min', 'max'],
                offsetY: -55,
                offsetX: 5,
                content: 'Effectif',
                style: {
                    fill: '#333',
                    fontSize: 22,
                    fontWeight: 'bold',
                    textAlign: 'right',
                    fontFamily: 'Din condensed'
                }
            },
            {
                type: 'text',
                position: ['min', 'max'],
                offsetY: -30,
                offsetX: 5,
                content: '',
                style: {
                    fill: '#333',
                    fontSize: 22,
                    fontWeight: 'bold',
                    textAlign: 'right',
                    fontFamily: 'Din condensed'
                }
            }
        ],

        // marginRatio: 0.1,
        legend: {
            position: 'top-right',
            layout: 'horizontal',
            offsetY: -5,
            marker: {
                symbol: 'circle'
            }
        },
        label: null
    }
}