import {ACTION_CODE} from './_actionCode';
import {TrainingAttachementService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_TRAINING_ATTACHMENTS';

export async function getAllTrainingAttachments(userData: Record<string, string>, params: string) {
    try {
        LoggerService.logInfo('Action - getAllTrainingAttachments - Start');

        const getAllTrainingAttachmentsResult = await TrainingAttachementService.getAllTrainingAttachments(userData, params);

        LoggerService.logInfo('Action - getAllTrainingAttachments - Success');

        return ResultModel.newSuccessResult(getAllTrainingAttachmentsResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllTrainingAttachments - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des affaires',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
