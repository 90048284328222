import {TrainingDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'UPDATE_TRAINING';

export async function updateTraining(
    userData: Record<string, string>,
    updatedTraining: Record<string, any>,
    trainingId: string
) {

    try {
        return await TrainingDao.updateTraining(userData.userToken, updatedTraining, trainingId);
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mis à jour de la fromation',
            error: error
        });
    }
}
