import React, {useEffect, useState} from 'react';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import {dealMissionsTableConfig} from '../../configs/sectionConfigs/dealMissionsTableConfig';
import {notify} from '../../../../components/ui/Toast/Toast';
import {MissionActions} from '../../../../actions';
import MissionInformationPopup from '../../popups/missionPopups/MissionInformationPopup';
import {addMissionKeys} from '../../../../utils';
import Popup from '../../../../components/ui/Popup/Popup';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const MissionsSection = ({
    userData,
    missionsData,
    refreshMissionsData,
    refreshDealData,
    dealData,
    blueStyle,
    setMissionFilterQuery,
    missionTableLoading,
    cannotBeModified,
    handleGetAllMissionsData,
    missionPaginationMetadata,
    isInternal
}) => {
    const [missionsTableEdit, setMissionsTableEdit] = useState(false);
    const [missionInformationPopupView, setMissionInformationPopupView] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const onSaveFunction = async (dataArray) => {
        setErrors(null);
        
        const updateResult = dataArray.map(async (data) => {

            const missionToUpdate = missionsData.find((mission) => mission.id === data.id);

            const dataKeys = Object.keys(data);

            const isSame = dataKeys.every((key) => data[key] === missionToUpdate[key]);

            if (isSame) {
                return;
            }

            const payload = {...missionToUpdate, ...data}

            return await MissionActions.updateMissionById(userData, payload );
        })

        const resolve = await Promise.all(updateResult);

        const results = resolve.filter(Boolean)

        if (results.every((result) => result.success)) {
            await refreshMissionsData();
            setMissionsTableEdit(false);
            setSubmitButtonLoading(false);
            notify('Missions mises à jour');
        }
        else {
            const errorResults = results.filter((result) => !result.success);

            const allErrors = errorResults.map((el) => {
                const err = getErrors(el);

                return err[0];
            })

            const assimilatedErrors = allErrors.filter((el, index) => {
                return index === allErrors.findIndex(other => el.title === other.title && el.message === other.message);
            });

            setSubmitButtonLoading(false);

            setErrors(assimilatedErrors);
        }
    }

    useEffect(() => {
        if (!missionsTableEdit) {
            setErrors(null);
        }
    }, [missionsTableEdit]);

    const filterMissionList = async (query) => {
        if (!dealData.id) return;

        setMissionFilterQuery(query);
    }

    const missionsTableConfig = dealMissionsTableConfig(missionsTableEdit, blueStyle, filterMissionList, userData);

    return (
        <div>
            <ContainerCard
                title={`Missions`}
                buttonContent={`Nouvelle mission`}
                coloredButtonDisplayed={!missionsTableEdit && !cannotBeModified}
                editButtonDisplayed={!missionsTableEdit && missionsData.length > 0 && !cannotBeModified}
                editButtonMargin='mr-6'
                onEditButtonClick={() => setMissionsTableEdit(true)}
                onButtonClick={() => setMissionInformationPopupView(true)}
                blueStyle={blueStyle}
                overflow='inherit'
            >
                <Table
                    data={addMissionKeys(missionsData)}
                    tableConfig={missionsTableConfig}
                    onSaveFunction={onSaveFunction}
                    setShowSubmitButtons={setMissionsTableEdit}
                    showSubmitButtons={missionsTableEdit}
                    submitButtonLoading={submitButtonLoading}
                    setSubmitButtonLoading={setSubmitButtonLoading}
                    blueStyle={blueStyle}
                    loading={missionTableLoading}
                    onPageNumberChange={handleGetAllMissionsData}
                    paginationMetadata={missionPaginationMetadata}
                />
                <Error errors={errors}/>
            </ContainerCard>
            <Popup
                view={missionInformationPopupView}
                setView={setMissionInformationPopupView}
                width={'1098px'}
                yOverflow=''
                xOverflow=''
            >
                <MissionInformationPopup
                    isInternal={isInternal}
                    setView={setMissionInformationPopupView}
                    userData={userData}
                    dealData={dealData}
                    refreshDealData={refreshDealData}
                    refreshData={refreshMissionsData}
                    blueStyle={blueStyle}
                />
            </Popup>
        </div>
    )
}

export default MissionsSection;
