import {DealTagsDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_DEALS_TAGS';

export async function getAllDealTags(userData: Record<string, string>, params: string, page: number) {
    try {
        return await DealTagsDao.getAllDealTags(userData, params, page);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des tags',
            error: error
        });
    }
}
