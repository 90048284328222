import {ACTION_CODE} from './_actionCode';
import {MissionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_MISSION_BY_ID';

export async function getMissionById(userData: Record<string, any>, missionId: number) {
    try {
        LoggerService.logInfo('Action - getMissionById - Start');

        const getMissionByIdResult = await MissionService.getMissionById(userData, missionId);

        LoggerService.logInfo('Action - getMissionById - End - Success');

        return ResultModel.newSuccessResult(getMissionByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - getMissionById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération d\'une facture',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
