import React, {useState} from 'react';
import {Tooltip} from 'antd';

import deleteButton from '../../../assets/icons/actionsbutton/white-delete.png';
import Loader from '../../../components/loaders/Loader';
import ConfirmationPopup from '../../../components/ui/ConfirmationPopup';

export const topBannerConfig = (onDeleteExpenseReport, deleteExpenseReportLoading, buttonsDisabled) => {
    const [deleteConfirmationView, setDeleteConfirmationView] = useState(false);

    return {
        blackgroundColor: 'bg-[#F0A841]',
        badgeBorderColor: '#F0A841',
        titleLabel: '',
        centerInfo: false,
        statusBadge: (status) => {
            switch (status) {
                case 0:
                    return {label: 'En cours', backgroundColor: null};
                case 1:
                    return {label: 'À vérifier', backgroundColor: '#f0a841'};
                case 2:
                    return {label: 'À payer', backgroundColor: '#e36d38'};
                case 3:
                    return {label: 'Payée', backgroundColor: '#ced95f'};
                default:
                    return {label: 'Indéfini', backgroundColor: '#212121'};
            }
        },
        actionComponents: (status) => {
            return (
                <>
                    {
                        status === 3 ?
                            null :
                            <Tooltip title={'Supprimer note de frais'}>
                                <button
                                    onClick={() => setDeleteConfirmationView(true)}
                                    disabled={buttonsDisabled}
                                    className='flex items-center justify-center p-8'
                                >
                                    {
                                        deleteExpenseReportLoading ?
                                            <Loader size={20} color='white'/> :
                                            <img className='w-5' src={deleteButton} alt=""/>
                                    }
                                </button>
                            </Tooltip>
                    }
                    <ConfirmationPopup
                        view={deleteConfirmationView}
                        setView={setDeleteConfirmationView}
                        title='Souhaitez-vous supprimer cette note de frais ?'
                        onConfirm={onDeleteExpenseReport}
                    />
                </>
            );
        }
    }
    ;
};
