import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import Table from '../../../../../../components/ui/Table/Table'
import {subsidiaryTableConfig} from '../../../../configs/classificationConfigs/subsidiaryTableConfig'
import Button  from '../../../../../../components/ui/Button/Button'
import CreateNewSubsidiary from '../ClassificationSectionPopups/CreateNewSubsidiary'
import Popup from '../../../../../../components/ui/Popup/Popup'
import {notify} from '../../../../../../components/ui/Toast/Toast'
import { capitalize } from '../../../../../../utils'

function SubsidiariesSubsection({
    data,
    setData,
    loadingTable,
    sectionName,
    dataName,
    actionCreate,
    actionUpdate,
    actionDelete,
    actionRefreshData
}) {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [toBeUpdated, setToBeUpdated] = useState(null);
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const refreshData = async () => {
        const resultForTable = await actionRefreshData(userData);

        if (resultForTable.success) {
            
            setData(prevState => ({
                ...prevState,
                [dataName]: resultForTable.data.results ? resultForTable.data.results : resultForTable.data
            }));
        }
        else {
            notify('Erreur de récuperation des données du tableau');
        }
    };

    const handleDelete = async (id) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(id);
        const result = await actionDelete(userData, id)

        if (result.success) {
            await refreshData();
            notify(`${capitalize(sectionName.name)} à bien été supprimé${sectionName.gender === "masculin" ? "" : "e"}`);
        }
        else {
            notify(`${capitalize(sectionName.name)} n'a pas pu être supprimé${sectionName.gender === "masculin" ? "" : "e"}`);
        }
        setActionButtonDisabled(false);
        setActionButtonLoading(null);
    }

    const customerCategoryInit =  subsidiaryTableConfig(
        handleDelete,
        setIsPopupOpen,
        setToBeUpdated,
        actionButtonLoading,
        actionButtonDisabled,
        sectionName
    )

    return (
        <div className="w-full px-4 pt-4 pb-28">
            <div className="flex justify-end py-4">
                <Button
                    content={`Ajouter un${sectionName.gender === "masculin" ? "" : "e"} ${sectionName.name}`}
                    onClick={() => {setToBeUpdated(null); setIsPopupOpen(true)}}
                />
            </div>
            <Table
                tableConfig={customerCategoryInit}
                data={data}
                loading={loadingTable}
            />

            <Popup
                view={isPopupOpen}
                setView={setIsPopupOpen}
                width={'1150px'}
                yOverflow=''
                xOverflow=''
            >
                <CreateNewSubsidiary
                    setView={setIsPopupOpen}
                    userData={userData}
                    sectionName={sectionName}
                    toBeUpdated={toBeUpdated}
                    actionCreate={actionCreate}
                    actionUpdate={actionUpdate}
                    refreshData={refreshData}
                />
            </Popup>
        </div>
    )
}

export default SubsidiariesSubsection