import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ContractorActions, ContractorInvoiceActions, FileActions, InvoiceActions } from '../../../../actions';
import Button from '../../../../components/ui/Button/Button';
import Error from '../../../../components/ui/Error/Error';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';
import FileDragNDropInput from './../../../../components/ui/Inputs/FileDragNDropInput';
import { fetchSelectData, getErrors } from './../../../../utils';

function SpendingContractorPopup({
    userData,
    invoiceContractorToBeUpdated,
    setView,
    refreshData,
    blueStyle,
    dealData
}) {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [contractors, setContractors] = useState([]);
    const [invoices, setInvoices] = useState([]);

    const vat = useSelector((state) => state.GlobalsReducer.vat);

    // -- react hook form --
    const defaultValues = {
        contractor: null,
        amount: null,
        notes: "",
        gross_amount: null,
        recoverable_vat: vat,
        file: null
    };
    const {handleSubmit, control, reset, formState: {isValid}, setValue} = useForm({defaultValues});

    // -- fill invoiceContractor values in form for update --
    useEffect(() => {

        if (invoiceContractorToBeUpdated && invoiceContractorToBeUpdated.id) {
            reset({
                contractor: invoiceContractorToBeUpdated.contractor,
                invoice: invoiceContractorToBeUpdated.invoice,
                amount: (+invoiceContractorToBeUpdated.amount).toString(),
                gross_amount: (+invoiceContractorToBeUpdated.gross_amount).toString(),
                recoverable_vat: (+invoiceContractorToBeUpdated.recoverable_vat).toString(),
                notes: invoiceContractorToBeUpdated.notes,
                file: invoiceContractorToBeUpdated.file
            });
        }
    }, [invoiceContractorToBeUpdated]);

    // -- functions that get data for Select Inputs --
    const handleGetContractors = async (search, page = 1) => {
        // eslint-disable-next-line max-len
        await fetchSelectData({action: ContractorActions.getAllContractors, search:`&search=${search}&deal=${dealData.id}`, page, setter: setContractors, userData, resultPath: 'data'});
    };

    const handleGetInvoices = async (search, page = 1) => {
        // eslint-disable-next-line max-len
        await fetchSelectData({action: InvoiceActions.getAllInvoices, search:`&search=${search}&deal=${dealData.id}&page_size=2000`, page, setter: setInvoices, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ contractors dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();
        (async () => {
            await handleGetInvoices('', 1);
        })();

    }, []);

    // -- handle submit --
    const onSubmit = async (data) => {
        setDisabled(true);
        setLoading(true);
        setErrors(null);
        let file = null;

        if (data.file && data.file.uid && data.file.type) {
            const newFile = {
                "type": data.file.type,
                "name": data.file.name,
                "file": data.file
            }

            const result = await FileActions.createFile(userData, newFile);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                file = result.data;
            }
        }

        // ↓ body to send to the post/patch request (see API documentation)
        const contractor = {
            contractor_id: data.contractor?.id,
            invoice_id: data.invoice.id,
            amount: data.amount,
            gross_amount: data.gross_amount,
            recoverable_vat: data.recoverable_vat,
            notes: data.notes ? data.notes : ""
        };

        if (file) {
            contractor.file_id = file.id;
        }

        // ↓ post - new invoiceContractor
        if (!invoiceContractorToBeUpdated) {
            const result = await ContractorInvoiceActions.createContractorInvoice(userData, contractor);
            // const result = await InvoiceActions.pushContractorIntoInvoice(userData, invoiceData, contractor);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('La facture sous/co-traitant à bien été crée');

                setView(false);
                reset();
            }
        }
        // ↓ patch - update invoiceContractor
        else {

            const result = await ContractorInvoiceActions.updateContractorInvoice(userData, contractor, invoiceContractorToBeUpdated.id);
            
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('La facture sous/co-traitant à bien été modifié');

                setView(false);
                reset();
            }
        }
        
        setLoading(false);
        setDisabled(false);
    };

    return (
        <div>
            <div className="pb-4 shadow-custom-outer">
                <div className="py-4 pl-4">
                    <h3 className="text-xl font-bold title">
                        {(invoiceContractorToBeUpdated && invoiceContractorToBeUpdated.id) ? 'Éditer la facture sous/co-traitant' : 'Nouvelle facture sous/co-traitant'}
                    </h3>
                </div>
                <div className="flex flex-row justify-between py-6 pl-4 pr-4 shadow-custom-inner">
                    <div>
                        <div className="flex mb-2">
                            <Controller
                                name="invoice"
                                control={control}
                                render={({
                                    field: {onChange, value, ref},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Facture'
                                        placeholder="Facture Terre d'Avance"
                                        inputRef={ref}
                                        value={value}
                                        error={error}
                                        options={invoices?.options}
                                        hasNextPage={invoices?.hasNextPage}
                                        loading={!invoices}
                                        onChange={onChange}
                                        isSearchable={true}
                                        fetchFunction={handleGetInvoices}
                                        labelKeys={["deal.name", "billing_date"]}
                                        separator=' - '
                                        required={true}
                                        disabled={(invoiceContractorToBeUpdated && invoiceContractorToBeUpdated.id) ? true : disabled}
                                        blueStyle={blueStyle}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex mb-2">
                            <Controller
                                name="contractor"
                                control={control}
                                render={({
                                    field: {onChange, value, ref},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Fournisseur'
                                        placeholder='Fournisseur'
                                        inputRef={ref}
                                        value={value}
                                        error={error}
                                        options={contractors?.options}
                                        hasNextPage={contractors?.hasNextPage}
                                        loading={!contractors}
                                        onChange={onChange}
                                        isSearchable={true}
                                        fetchFunction={handleGetContractors}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={(invoiceContractorToBeUpdated && invoiceContractorToBeUpdated.id) ? true : disabled}
                                        blueStyle={blueStyle}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex mb-2">
                            <Controller
                                name="amount"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir la TVA.'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <TextInput
                                        label={'Montant TTC (€)'}
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="Montant TTC"
                                        type="number"
                                        color={blueStyle ? 'blue' : 'orange'}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col place-content-end">
                        <div className="flex mb-2">
                            <Controller
                                name="recoverable_vat"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir la TVA.'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <TextInput
                                        label={'TVA reccupérable'}
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="TVA reccupérable"
                                        type="number"
                                        color={blueStyle ? 'blue' : 'orange'}
                                    />
                                )}
                            />
                        </div>
                        
                        <div className="flex mb-2">
                            <Controller
                                name="gross_amount"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir la TVA.'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <TextInput
                                        label={'Montant HT (€)'}
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="Montant HT"
                                        type="number"
                                        color={blueStyle ? 'blue' : 'orange'}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="px-4 mb-5">
                    <Controller
                        name="file"
                        control={control}
                        // rules={{required: {
                        //     value: true,
                        //     message: 'Veuillez charger un logo'
                        // }}}
                        render={({
                            field: {onChange}
                        }) => (
                            <FileDragNDropInput
                                onChange={onChange}
                                uploadText='Glissez déposez ou séllectionnez un fichiers'
                                uploadHint="Ajoutez ici des documents (PDF, JPEG, PDF)"
                                maxCount={1}
                                color={blueStyle ? '#19b3dc' : '#E36D38'}
                            />
                        )}
                    />
                </div>

                <div className="px-4">
                    <div className="py-4 border-t border-borderGrey">
                        <Controller
                            name="notes"
                            control={control}
                            render={({
                                field: {onChange, value}
                            }) => (
                                <TextAreaInput
                                    placeholder={'Tapez votre texte'}
                                    maxLength={100}
                                    onChange={onChange}
                                    value={value}
                                    disabled={disabled}
                                    color={blueStyle ? 'blue' : 'orange'}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center mt-6 mb-4">
                    <div className="flex justify-center">
                        <Button
                            onClick={() => {
                                reset();
                                setView(false);
                            }}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        {
                            (!invoiceContractorToBeUpdated) ?
                                <Button
                                    type={blueStyle ? 'blue' : 'primary'}
                                    content={'Ajouter la facture sous/co-traitant'}
                                    onClick={handleSubmit(onSubmit)}
                                    loading={loading}
                                    disabled={!isValid}
                                /> : null
                        }
                        {
                            (invoiceContractorToBeUpdated && invoiceContractorToBeUpdated.id) ?
                                <Button
                                    type={blueStyle ? 'blue' : 'primary'}
                                    content={'Modifier la facture sous/co-traitant'}
                                    onClick={handleSubmit(onSubmit)}
                                    loading={loading}
                                    disabled={(!contractors) ? true : !isValid}
                                /> : null
                        }
                    </div>
                    <Error errors={errors}/>
                </div>
            </div>
        </div>
    );
}

export default SpendingContractorPopup;
