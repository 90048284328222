import {ACTION_CODE} from './_actionCode';
import {CustomerService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_CUSTOMERS';

export async function createCustomer(
    userData: Record<string, string>,
    customer: Record<string, string>
) {
    try {
        LoggerService.logInfo('Action - createCustomer - Start');

        const createCustomerResult = await CustomerService.createCustomer(userData, customer);

        LoggerService.logInfo('Action - createCustomer - End - Success');

        return ResultModel.newSuccessResult(createCustomerResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createCustomer - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création du client.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
