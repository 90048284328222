import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Tooltip from 'antd/lib/tooltip';

import {MissionActions} from '../../../../actions'
import arrowIcon from '../../../../assets/icons/page/orange-left-arrow.png';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import Badge from '../../../../components/ui/Badge';
import Loader from '../../../../components/loaders/Loader';
import {notify} from '../../../../components/ui/Toast/Toast';
import ConfirmationPopup from '../../../../components/ui/ConfirmationPopup';

const MissionDetailsBanner = ({data, userData, cannotBeModified}) => {
    const navigate = useNavigate();

    const isAllowedToDelete = userData?.role === "CO" && data?.deal?.production_lead?.id !== userData?.id ? false : true;

    const [deleteMissionLoading, setDeleteMissionLoading] = useState(false);
    const [deleteMissionConfirmationView, setDeleteMissionConfirmationView] = useState(false);

    const missionStatus = () => {
        switch (data.status) {
            case 0:
                return {status: 'Prévue', color: '#f0a841'};
            case 1:
                return {status: 'En cours', color: '#e36d38'};
            case 2:
                return {status: 'Terminée', color: '#ced95f'};
            default:
                return {status: 'Indéfini', color: '#212121'};
        }
    };

    const handleDeleteMission = async () => {
        setDeleteMissionLoading(true);

        const result = await MissionActions.deleteMissionById(userData, data.id);

        if (result.success) {
            navigate(`/deals/${data.deal.id}/missions`, {state: 'mission_deleted'});
        }
        else {
            notify('La mission n\'a pas pu être supprimée');
        }
        setDeleteMissionLoading(false);
    };

    return (
        <>
            <div className='h-28 w-full bg-[#212121] px-20 py-5 fixed z-50 flex flex-col justify-start items-center shadow-[0_2px_8px_rgba(0,0,0,0.25)]'>
                <div className='flex flex-row items-center justify-between w-full'>
                    <button onClick={() => navigate(-1)} className='p-4'>
                        <img src={arrowIcon} alt='return' className='w-5'/>
                    </button>
                    <div className='text-4xl font-bold text-gradient' style={{fontFamily: 'DIN Condensed'}}>
                        {data?.ressource?.first_name ? `${data?.ressource?.first_name} ${data?.ressource?.last_name} - ${data?.deal?.short_name}` : ''}
                    </div>
                    {
                        isAllowedToDelete && !cannotBeModified ?
                            <Tooltip title='Supprimer mission'>
                                <button onClick={() => setDeleteMissionConfirmationView(true)} disabled={deleteMissionLoading} className='p-4'>
                                    {
                                        deleteMissionLoading ?
                                            <Loader size={20} color='#E36D38'/> :
                                            <img src={deleteIcon} alt='delete' className='w-5'/>
                                    }
                                </button>
                            </Tooltip>
                            :
                            <div className='w-5'/>
                    }
                </div>
                <Badge
                    content={data.status ? missionStatus().status : ''}
                    color={data.status ? missionStatus().color : '#212121'}
                    borderColor='#212121'
                />
            </div>
            <ConfirmationPopup
                view={deleteMissionConfirmationView}
                setView={setDeleteMissionConfirmationView}
                title='Souhaitez-vous supprimer cette mission ?'
                onConfirm={handleDeleteMission}
            />
        </>
    )
}

export default MissionDetailsBanner
