// import { file } from 'jszip';
import {frenchFormat, standardFormat} from "../../../utils";

export const exportProductionDataToCsv = (
    productionsData,
    setCsvProps,
    setCsvExportLoading,
    datePickerDate
) => {

    setCsvExportLoading(true);

    const csvData = [];

    for (const production of productionsData) {
        csvData.push({
            ressource: production.ressource.first_name + ' ' + production.ressource.last_name,
            type: production.category,
            date: frenchFormat(production.date),
            location: production.location,
            producedDays: production.produced_days,
            billedDays: production.billed_days,
            unbilledDays: (parseFloat(production.billed_days) - parseFloat(production.produced_days))
        });
    }

    const headers = [
        {label: 'Ressource', key: 'ressource'},
        {label: 'Type', key: 'type'},
        {label: 'Date', key: 'date'},
        {label: 'Localisation', key: 'location'},
        {label: 'Jours Produits', key: 'producedDays'},
        {label: 'Jours facturés', key: 'billedDays'},
        {label: 'Jours non facturés', key: 'unbilledDays'}
    ];

    setCsvProps({
        filename: `production_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}

export const exportExpenseDataToCsv = (
    expensesData,
    setCsvProps,
    setCsvExportLoading,
    datePickerDate
) => {

    setCsvExportLoading(true);

    const csvData = [];

    for (const expense of expensesData) {
        csvData.push({
            ressource: expense.ressource.first_name + ' ' + expense.ressource.last_name,
            date: frenchFormat(expense.date),
            type: expense.type.group,
            billable: expense.billable ? 'Oui' : 'Non',
            amount: expense.amount,
            vat: expense.vat,
            notes: expense.notes
        });
    }

    const headers = [
        {label: 'Ressource', key: 'ressource'},
        {label: 'Type', key: 'type'},
        {label: 'Date', key: 'date'},
        {label: 'Refacturation', key: 'billable'},
        {label: 'Montant', key: 'amount'},
        {label: 'Tva', key: 'vat'},
        {label: 'Notes', key: 'notes'}
    ];

    setCsvProps({
        filename: `frais_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}

export const exportTrainingDataToCsv = (
    trainingData,
    setCsvProps,
    setCsvExportLoading,
    datePickerDate
) => {

    setCsvExportLoading(true);

    const csvData = [];

    for (const training of trainingData) {
        csvData.push({
            date: frenchFormat(training.date),
            duration: training.duration,
            students: training.students,
            file: training.file.file
        });
    }

    const headers = [
        {label: 'Date de formation', key: 'date'},
        {label: 'Durée', key: 'duration'},
        {label: 'Nombre de stagiaires', key: 'students'},
        {label: 'Feuille d\'émargement', key: 'file'}
    ];

    setCsvProps({
        filename: `suivi_de_stagiaires_${standardFormat(datePickerDate.startDate)}_${standardFormat(datePickerDate.endDate)}.csv`,
        headers: headers,
        data: csvData
    });

    setCsvExportLoading(false);
}