import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'CREATE_CUSTOMERS';

export async function createCustomer(
    userToken: string,
    customer: Record<string, string>
) {
    try {
        const response = await axios({
            method: 'post',
            url: `${URL}/customers/`,
            data: customer,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur de création du client.',
            error: error
        });
    }
}
