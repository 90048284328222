import {useSelector} from 'react-redux';
import {CalculatorFilled} from '@ant-design/icons';

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import Badge from "../../../../components/ui/Badge";
import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import ProductionByEtpChart from './ProductionByEtpChart';
import {transformThousandNumbers} from '../../../../utils';
import {aggregateETPmMarginParDas} from '../../../codevDashboardPage/functions/aggregateETPMargin';

const ProductionByEtp = ({data, dasKey}) => {
    const CalculatorIcon = <CalculatorFilled style={{ fontSize: '30px', color:'white' }}/>;

    return (
        <div className='mt-14'>
            <LineTitle title="Production par ETP" textSize='text-4xl'/>

            <div className='flex justify-between mt-10'>
                <SimpleCard
                    width='w-[48%]'
                    title={data.by_status[dasKey] ? transformThousandNumbers(aggregateETPmMarginParDas(data.by_status[dasKey])) : 0}
                    subTitle="Marge brute par ETP"
                    iconComponent={CalculatorIcon}
                    bottomComponent={data.by_status[dasKey] ? <BottomComponent data={data.by_status} dasKey={dasKey}/> : null}
                />
                <div className='w-[48%]'>
                    <ProductionByEtpChart data={data.by_status} dasKey={dasKey}/>
                </div>
            </div>
        </div>
    )
}

export default ProductionByEtp;

const BottomComponent = ({dasKey}) => {

    const dasS = useSelector((state) => state.DasReducer.das);
    let currentDas = dasS.filter((el) => el.name === dasKey);
    currentDas = currentDas.length > 0 ? currentDas[0] : null;
    
    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            <div className='min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]'>
                <div className='flex flex-row items-center justify-between'>
                    <div className='text-sm font-medium text-gradient'>
                        {dasKey}
                    </div>
                    <Badge
                        content={currentDas ? [currentDas.manager] : '-'}
                        color={currentDas ? null : '#bdc3c7'}
                        type='round'
                    />
                </div>
            </div>
        </div>
    )
}
