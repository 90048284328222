import {BankDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'DELETE_BANK';

export async function deleteBank(
    userData: Record<string, string>,
    bankId: number
) {
    try {
        const result = await BankDao.deleteBank(userData.userToken, bankId);

        return result;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
