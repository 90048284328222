import { useEffect, useState } from 'react';
import {CreditCardFilled, CalendarFilled, TagFilled} from '@ant-design/icons';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import ProgressComponent from '../../../../components/ui/ProgressComponent/ProgressComponent';
import DonutsChartsComponent from './DonutsChartsComponent';
import { productionChartsConfig } from '../../configs/productionChartsConfig'
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';

const Production = ({ production }) => {

    if (Object.keys(production).length === 0) {
        return null;
    }

    const [chartsData, setChartsData] = useState([]);

    const { billed, global } = production;

    let progressValue = (global.billed / billed.production_objective) * 100;
    const tooltipValue = +billed.production_objective - +global.billed + ' €';

    progressValue = Math.trunc(progressValue);

    useEffect(() => {
        if (Object.keys(billed.das).length === 0) {
            setChartsData([{
                type: 'Aucune donnée:',
                value: 0
            }])
        }
        else {
            setChartsData([]);
            for (const [key, value] of Object.entries(billed.das)) {
                if (key !== 'daily_rate' && key !== 'production_objective') {
                    setChartsData(prevState => [...prevState, {
                        type: key + ':',
                        value: value
                    }])
                }
            }
        }
    }, [production]);

    const CreditCardIcon = <CreditCardFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const CalendarIcon = <CalendarFilled style={{ fontSize: '30px', color:'white' }}/>;
    const TagIcon = <TagFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className="my-10">
            <LineTitle title="Production"/>

            <div className='flex justify-between mt-5'>
                <SimpleCard
                    width='w-[31%]'
                    title={billed.production_objective + ' €'}
                    showLine={false}
                    subTitle="Objectif contractuel de CA"
                    iconComponent={CreditCardIcon}
                    topComponent={<ProgressComponent style='white' tooltipValue={tooltipValue} percent={progressValue}/>}
                    backgroundHeight='h-auto'
                />

                <SimpleCard
                    width='w-[31%]'
                    title={global.billed || 0}
                    subTitle="Jours facturés"
                    iconComponent={CalendarIcon}
                />

                <SimpleCard
                    width='w-[31%]'
                    title={ billed.daily_rate + ' €'}
                    subTitle="TJM"
                    iconComponent={TagIcon}
                />
            </div>

            <ContainerCard margin='mt-5' coloredButtonDisplayed={false} showHeader={false}>
                <DonutsChartsComponent data={chartsData} label='jours' config={productionChartsConfig}/>
            </ContainerCard>
        </div>
    )
}

export default Production