import {ACTION_CODE} from './_actionCode';
import {SubsidiaryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_SUBSIDIARIES';

export async function getAllSubsidiaries(
    userData: Record<string, string>,
    params = '',
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllSubsidiaries - Start');

        const getAllSubsidiariesResult = await SubsidiaryService.getAllSubsidiaries(userData, params, page);

        LoggerService.logInfo('Action - getAllSubsidiaries - End - Success');

        return ResultModel.newSuccessResult(getAllSubsidiariesResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllSubsidiaries - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des filiale.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
