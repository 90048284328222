import {UserDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_USERS';

export async function getAllUsers(
    userToken: string,
    search: string,
    page: number,
) {
    try {
        const result = await UserDao.getAllUsers(userToken, search, page);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des utilisateurs.',
            error: error
        });
    }
}
