import {ContractorDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'UPDATE_CONTRACTOR';

export async function updateContractor(
    userData: Record<string, string>,
    updatedContractor: Record<string, string>,
    contractorId: number
) {
    try {
        const updateContractorResult = await ContractorDao.updateContractor(userData.userToken, updatedContractor, contractorId);

        return updateContractorResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la modification du client.',
            error: error
        });
    }
}
