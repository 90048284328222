export const aggregateContributionToMarginPerStatus = (by_status: any, key: string) => {

    let dasSalary = 0;

    let dasGrossMargin = 0;

    for (const status in by_status[key]) {
        dasSalary += by_status[key][status].salary;
        dasGrossMargin += by_status[key][status].gross_margin;
    }

    const das = [
        {
            type: 'Junior',
            value: Math.round(((by_status[key].junior.gross_margin - by_status[key].junior.salary) / (dasGrossMargin - dasSalary)) * 100)
        },
        {
            type: 'Confirmé',
            value: Math.round(((by_status[key].confirmed.gross_margin - by_status[key].confirmed.salary) / (dasGrossMargin - dasSalary)) * 100)
        },
        {
            type: 'Sénior',
            value: Math.round(((by_status[key].senior.gross_margin - by_status[key].senior.salary) / ( dasGrossMargin - dasSalary)) * 100)
        }
    ];

    return das;
    
}

export const aggregateContributionToMarginPerStatusTotal = (by_status: any) => {

    let marginJuniorTotal = 0;
    let marginConfirmedTotal = 0;
    let marginSeniorTotal = 0;

    let salaryJuniorTotal = 0;
    let salaryConfirmedTotal = 0;
    let salarySeniorTotal = 0;

    for (const key in by_status) {
        marginJuniorTotal += by_status[key].junior.gross_margin;
        marginConfirmedTotal += by_status[key].confirmed.gross_margin;
        marginSeniorTotal += by_status[key].senior.gross_margin;

        salaryJuniorTotal += by_status[key].junior.salary;
        salaryConfirmedTotal += by_status[key].confirmed.salary;
        salarySeniorTotal += by_status[key].senior.salary;

    }

    const marginCabinet = marginJuniorTotal + marginConfirmedTotal + marginSeniorTotal;
    const salaryCabinet = salaryJuniorTotal + salaryConfirmedTotal + salarySeniorTotal;
    
    return [
        {
            type: 'Junior',
            value: Math.round(((marginJuniorTotal - salaryJuniorTotal) / (marginCabinet - salaryCabinet)) * 100)
        },
        {
            type: 'Confirmé',
            value: Math.round(((marginConfirmedTotal - salaryConfirmedTotal) / (marginCabinet - salaryCabinet)) * 100)
        },
        {
            type: 'Sénior',
            value: Math.round(((marginSeniorTotal - salarySeniorTotal) / (marginCabinet - salaryCabinet)) * 100)
        }
    ]

}