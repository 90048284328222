export const contributionToMarginData = (data, dasKey) => {

    if (!data[dasKey]) {
        return [];
    }
    else {

        const directSalaries = data[dasKey].gross_margin - data[dasKey].salary;
        
        const getContributionPerEtpPercentage = () => {
            if (!directSalaries || !data[dasKey].etp) {
                return 0;
            }
            else {
                return (directSalaries / data[dasKey].etp).toFixed();
            }
        }

        return [
            {
                id: 1,
                name: dasKey,
                gross_margin: `${data[dasKey].gross_margin} €`,
                direct_salaries: `${data[dasKey].salary} €`,
                contribution: `${directSalaries} €`,
                contribution_per_etp: `${getContributionPerEtpPercentage()} €`
            }
        ]
    }
}
