import {useEffect, useState} from 'react';
import {CheckSquareOutlined, DeleteOutlined} from "@ant-design/icons";
import {Link} from 'react-router-dom';
import {Tooltip} from 'antd';

import {frenchFormat, fetchSelectData, handleFilterList, useFilterQuery} from '../../../../src/utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import goIcon from '../../../assets/icons/actionsbutton/go.png';
import {UserActions} from '../../../actions';
import {SelectInput} from '../../../components/ui/Table';

export const expensesReportsConfig = (
    handleDeleteExpenseReport,
    actionButtonLoading,
    actionButtonDisabled,
    handleSetStatusToVeridied,
    filterExpenseReportList,
    userData
) => {
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        status: ''
    });
    
    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterExpenseReportList);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    };

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })
    }, []);

    // create array of object of status
    const statusArray = [
        {status: 'En cours', color: null, id: 0},
        {status: 'À vérifier', color: '#f0a841', id: 1},
        {status: 'À payer', color: '#e36d38', id: 2},
        {status: 'Payée', color: '#ced95f', id: 3}
    ]
    
    return (
        {
            dropdown: true,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'ID',
                    value: 'id',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        let expenseReportStatus = statusArray.find((status) => status.id === item.status);

                        if (!expenseReportStatus) {
                            expenseReportStatus = {status: 'Indefini', color: '#212121'};
                        }

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={expenseReportStatus.status} color={expenseReportStatus.color}/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="status"
                        options={statusArray}
                        onChange={handleFilter}
                        labelKeys={['status']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Ressource',
                    value: 'resource.username',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={[item.ressource]} type='rounded'/>
                            </div>
                        );
                    },
                    filterComponent: () => {
                        if(userData.role === 'CO') return;
                        
                        return (
                            <SelectInput
                                name="ressource"
                                options={users?.options}
                                hasNextPage={users?.hasNextPage}
                                isSearchable={true}
                                onChange={handleFilter}
                                fetchFunction={(handleGetUsers)}
                                labelKeys={['first_name', 'last_name']}
                                valueKey={'username'}
                                labelWidth='w-40'
                            />
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de paiement',
                    value: null,
                    width: 'w-[14%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.status === 3 ? frenchFormat(item.payment_date) : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant HT (€)',
                    value: 'amount',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {parseInt(item.amount).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: ''
                },
                {
                    label: 'TVA [€]',
                    value: 'vat',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.vat}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de début',
                    value: 'start_date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.start_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de fin',
                    value: 'end_date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.end_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[6%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center gap-x-1">
                                {item.status === 0 && (
                                    <Tooltip title='A vérifier'>
                                        <CheckSquareOutlined onClick={() => handleSetStatusToVeridied(item)} style={{ color: '#e26737', fontSize: '20px'}}/>
                                    </Tooltip>
                                )}
                                
                                {
                                    item.status === 3 ?
                                        <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/> :
                                        <ActionsButton
                                            type="delete"
                                            isLoading={actionButtonLoading === item.id}
                                            onClick={() => handleDeleteExpenseReport(item)}
                                            disabled={actionButtonDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette note de frais ?'
                                        />
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[5%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Tooltip title={actionButtonDisabled ? '' : 'Voir détails'}>
                                    <Link to={`${item.id}`} id='skip' className='ml-1'>
                                        <img src={goIcon} alt='go' id='skip' className='w-6'/>
                                    </Link>
                                </Tooltip>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
