import * as z from 'zod';
import {ExpenseTypeModel} from '../expenseType';
import { UserModel } from '../user';
import * as expenseFunctions from './functions';

export const ExpenseSchema = z.object({
    'id': z.number(),
    'deal': z.object({
        'id': z.number(),
        'short_name': z.string()
    }),
    'type': ExpenseTypeModel.ExpenseTypeSchema,
    'ressource': UserModel.UserSchema.optional(),
    'date': z.string(),
    'billable': z.boolean(),
    'amount': z.string(),
    'vat': z.string(),
    'co2': z.string(),
    'notes': z.string(),
    'report': z.number().nullable(),
    'status': z.number().optional(),
    'report_status': z.number().optional()

});

export const ExpensePartialSchema = ExpenseSchema.partial();
export type ExpensePartial = z.infer<typeof ExpensePartialSchema>;
export type Expense = z.infer<typeof ExpenseSchema>;

export function newExpense(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...ExpenseSchema.parse({...data}),
        ...expenseFunctions
    };
}

export function parseExpense(data: any, partial = false) {
    if (!partial) {
        return {
            ...ExpenseSchema.parse(data),
            ...expenseFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...ExpensePartialSchema.parse(data),
            ...expenseFunctions
        };
    }
}
