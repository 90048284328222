import {ContractorDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE_CONTRACTOR_';

export async function createContractor(
    userData: Record<string, string>,
    newContractor: Record<string, any>
) {

    try {
        return await ContractorDao.createContractor(userData.userToken, newContractor);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la création du fournisseur',
            error: error
        });
    }
}
