import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
import FormData from 'form-data';
const FUNCTION_CODE = 'CREATE_EXPENSE_ATTACHMENT';

export async function createExpenseAttachment(
    userToken: string,
    filePath: string,
    reportId:string
) {
    try {

        const data = new FormData();
        data.append('file', filePath);
        data.append('report', reportId);

        const response = await axios({
            method: 'post',
            url: `${URL}/expense-attachments/`,
            data: data,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: "Une erreur de la création d'une pièce jointe",
            error: error
        });
    }
}
