import ProductionDayEvents from '../components/ProductionDayEvents';
import ProductionMonthEvents from '../components/ProductionMonthEvents';

export const productionsCalendarConfig = () => {
    return (
        {
            isWeekEndOpennedDay: false,
            monthFilterDataFunction: (data, date) => {
                return data[date];
            },
            yearFilterDataFunction: (data, date) => {
                const currentDate = new Date(date);
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();

                return data[`${currentYear}-${currentMonth >= 9 ? currentMonth + 1 : `0${currentMonth + 1}`}`];
            },
            dayCellEventsComponent: (dayData, dayFormated) => {
                return (
                    <ProductionDayEvents dayData={dayData} dayFormated={dayFormated}/>
                );
            },
            monthCellEventsComponent: (monthData) => {
                return (
                    <ProductionMonthEvents monthData={monthData}/>
                );
            }
        }
    );
};
