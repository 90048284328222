import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Popup from '../../../components/ui/Popup';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import {InvoiceActions} from '../../../actions';
import { notify } from '../../../components/ui/Toast/Toast';

const InvoicePreviewPopup = ({
    view,
    setView,
    item
}) => {
    const [fileUrl, setFileUrl] = useState(null);
    
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    useEffect(() => {
        if (!item || !view) return;

        (async () => {
            const result = await InvoiceActions.downloadInvoiceById(userData, item.id);

            if (result.success) {
                const url = URL.createObjectURL(result.data);
                setFileUrl(url);
            }
            else {
                notify('Erreur de récupération du de la facture');
            }
        })();
    }, [item, view]);
  
    const docs = [{uri: fileUrl}];

    return (
        <Popup view={view} setView={setView} maxheight='h-[400px]'>
            <DocViewer
                style={{height: '60vh', width: '850px'}}
                documents={docs}
                initialActiveDocument={docs[0]}
                pluginRenderers={DocViewerRenderers}
                config={{ header: {disableHeader: true},
                    pdfZoom: {
                        defaultZoom: 1.1,
                        zoomJump: 0.2
                    }
                }}
            />
        </Popup>
    );
}

export default InvoicePreviewPopup;