import {ACTION_CODE} from './_actionCode';
import {TrainingObjectiveService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_TRAINING_OBJECTIVE';

export async function getAllTrainingObjective(
    userData: Record<string, string>,
    params = "",
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllTrainingObjective - Start');

        const getAllTrainingObjectiveResult = await TrainingObjectiveService.getAllTrainingObjective(userData, params, page);

        LoggerService.logInfo('Action - getAllTrainingObjective - Success');

        return ResultModel.newSuccessResult(getAllTrainingObjectiveResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllTrainingObjective - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des objectifs général de la formation',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
