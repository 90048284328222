import {UserDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_USER';

export async function updateUser(
    userData: Record<string, string>,
    id: number,
    payload: Record<string, any>
) {
    try {
        await UserDao.updateUser(userData.userToken, id, payload);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error
        });
    }
}
