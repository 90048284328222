import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import {addMissionKeys} from "../../utils";
import Error from "../../components/ui/Error";
import {pageTableMissionsConfig} from "./configs/pageTables/pageTableMissionsConfig";
import Table from "../../components/ui/Table";
import {notify} from "../../components/ui/Toast/Toast";
import {getErrors} from "../../utils";
import {MissionActions} from './../../actions';
import edit from "../../assets/icons/actionsbutton/edit.png";

const CurrentMissions = ({
    data: missionsData,
    das,
    refreshMissionData,
    userData,
    filterMissionList,
    missionsPaginationMetadata,
    handleFetchMissionsData,
    tableLoading,
    dasKey
}) => {
    const [missionsTableEdit, setMissionsTableEdit] = useState(false);
    const [errors, setErrors] = useState(null);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    useEffect(() => {
        if(missionsTableEdit) {
            setMissionsTableEdit(false);
        }
    }, [das]);

    const onSaveFunction = async (dataArray) => {
        setErrors(null);
        let statusChanged = false;

        const updateResult = dataArray.map(async (data) => {
            if (parseInt(data.status) !== 1) statusChanged = true;

            const missionToUpdate = missionsData.find((mission) => mission.id === data.id);

            const dataKeys = Object.keys(data);

            const isSame = dataKeys.every((key) => data[key] === missionToUpdate[key]);

            if (isSame) {
                return;
            }

            const payload = {...missionToUpdate, ...data}

            return await MissionActions.updateMissionById(userData, payload );
        })

        const resolve = await Promise.all(updateResult);

        const results = resolve.filter(Boolean)

        if (results.every((result) => result.success)) {
            await refreshMissionData(statusChanged);
            setMissionsTableEdit(false);
            setSubmitButtonLoading(false);
            notify('Missions mises à jour');
        }
        else {
            const errorResults = results.filter((result) => !result.success);

            const allErrors = errorResults.map((el) => {
                const err = getErrors(el);

                return err[0];
            })

            const assimilatedErrors = allErrors.filter((el, index) => {
                return index === allErrors.findIndex(other => el.title === other.title && el.message === other.message);
            });

            setSubmitButtonLoading(false);
            setErrors(assimilatedErrors);
        }
    }

    const tableConfig = pageTableMissionsConfig(missionsTableEdit, filterMissionList, userData, dasKey);

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/das'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Missions en cours (${missionsPaginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>

            <div className='flex justify-end min-h-[36px]'>
                {
                    !missionsTableEdit &&
                    <button onClick={() => setMissionsTableEdit(true)} className='p-2'>
                        <img src={edit} alt="download" className='w-5'/>
                    </button>
                }
            </div>

            <Table
                data={addMissionKeys(missionsData)}
                tableConfig={tableConfig}
                onSaveFunction={onSaveFunction}
                showSubmitButtons={missionsTableEdit}
                setShowSubmitButtons={setMissionsTableEdit}
                submitButtonLoading={submitButtonLoading}
                setSubmitButtonLoading={setSubmitButtonLoading}
                onPageNumberChange={handleFetchMissionsData}
                paginationMetadata={missionsPaginationMetadata}
                loading={tableLoading}
            />
            <Error errors={errors}/>
        </>
    )
}

export default CurrentMissions;
