import {ACTION_CODE} from './_actionCode';
import {ContractorInvoicesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_CONTRACTOR_INVOICES';

export async function getContractorInvoices(
    userData: Record<string, string>,
    params: string
) {
    try {
        LoggerService.logInfo('Action - getContractorInvoices - Start');

        const getAllContractorInvoicesResult = await ContractorInvoicesService.getContractorInvoices(userData, params);

        const {data, metadata} = getAllContractorInvoicesResult;

        LoggerService.logInfo('Action - getContractorInvoices - End - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getContractorInvoices - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des fournisseurs.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
