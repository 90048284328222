import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import {ExpenseReportActions} from '../../actions';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import Table from '../../components/ui/Table';
import {expensesTableConfig} from './config/expensesTableConfig';

const ExpensesToPayPage = ({
    userData,
    expensesToPay,
    actionButtonLoading,
    setActionButtonDisabled,
    setActionButtonLoading,
    refreshData,
    tableType,
    filterExpenseReportList,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {

    const handleDeleteExpenseReport = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${item.id}del`);

        const result = await ExpenseReportActions.deleteExpenseReportById(userData, item.id);

        if (result.success) {
            await refreshData(true);
            notify('Votre note de frais à bien été supprimé');
        }
        else {
            notify('Votre note de frais n\'a pas pu être supprimé');
        }

        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    const handlePayExpenseReport = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${item.id}upd`);

        const result = await ExpenseReportActions.updateExpenseReportById(userData, item.id, {status: 3});

        if (result.success) {
            await refreshData(true);
            notify('Votre note de frais à bien été payé');
        }
        else {
            notify('Votre note de frais n\'a pas pu être payé');
        }

        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    const tableConfig = expensesTableConfig(
        handleDeleteExpenseReport,
        actionButtonLoading,
        handlePayExpenseReport,
        filterExpenseReportList,
        tableType,
        userData
    );

    return (
        <>
            <Toast/>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Notes de frais à payer (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={expensesToPay}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default ExpensesToPayPage;
