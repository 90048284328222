
import { getCurrentMonthAndYear } from '../../../utils/getCurrentMonthAndYear';

export const dataPerSector = (ressources: Record<string, any>, sector: string) => [
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(),
        ressource: ressources.projection[sector].m1.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(1),
        ressource: ressources.projection[sector].m2.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(2),
        ressource: ressources.projection[sector].m3.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(3),
        ressource: ressources.projection[sector].m4.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(4),
        ressource: ressources.projection[sector].m5.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(5),
        ressource: ressources.projection[sector].m6.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(6),
        ressource: ressources.projection[sector].m7.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(7),
        ressource: ressources.projection[sector].m8.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(8),
        ressource: ressources.projection[sector].m9.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(9),
        ressource: ressources.projection[sector].m10.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(10),
        ressource: ressources.projection[sector].m11.etp
    },
    {
        name: 'ETP',
        date: getCurrentMonthAndYear(11),
        ressource: ressources.projection[sector].m12.etp
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(),
        ressource: ressources.projection[sector].m1.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(1),
        ressource: ressources.projection[sector].m2.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(2),
        ressource: ressources.projection[sector].m3.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(3),
        ressource: ressources.projection[sector].m4.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(4),
        ressource: ressources.projection[sector].m5.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(5),
        ressource: ressources.projection[sector].m6.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(6),
        ressource: ressources.projection[sector].m7.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(7),
        ressource: ressources.projection[sector].m8.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(8),
        ressource: ressources.projection[sector].m9.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(9),
        ressource: ressources.projection[sector].m10.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(10),
        ressource: ressources.projection[sector].m11.ressources
    },
    {
        name: 'Consultants',
        date: getCurrentMonthAndYear(11),
        ressource: ressources.projection[sector].m12.ressources
    }
];

export const dataCabinet = (ressources: Record<string, any>) => {
    
    let data = null;
    for (const sector in ressources.projection) {
        if (data === null) {
            data = dataPerSector(ressources, sector);
        }
        else {
            data.forEach((item: Record<string, any>, index: number) => {
                item.ressource += dataPerSector(ressources, sector)[index].ressource;
            })
        }
        
    }

    return data;
}