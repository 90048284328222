import MissionsProfitability from './MissionsProfitability';
import ConsultantContributions from './ConsultantContributions';
import Costs from './Costs';
import ContractorsAnalysis from './ContractorsAnalysis';
import ExpenseAnalysis from './ExpenseAnalysis';
import PaymentTermsAnalysis from './PaymentTermsAnalysis';

const ManagementParameters = ({
    missionsProfitabilityData,
    missionsProfitabilityDatePicker,
    handleMissionsProfitabilityDatePickerChange,
    consultantContributionsData,
    consultantContributionsDatePicker,
    handleConsultantContributionsDatePickerChange,
    costsData,
    costsDatePicker,
    handleCostsDatePickerChange,
    contractorsData,
    contractorsDatePicker,
    handleContractorsDatePickerChange,
    expensesData,
    expensesDatePicker,
    handleExpensesDatePickerChange,
    paymentTermsData,
    paymentTermsDatePicker,
    handlePaymentTermsDatePickerChange
}) => {

    return (
        <>
            <MissionsProfitability
                data={missionsProfitabilityData}
                datePickerValue={missionsProfitabilityDatePicker}
                onDatePickerChange={handleMissionsProfitabilityDatePickerChange}
            />
            <ConsultantContributions
                data={consultantContributionsData}
                datePickerValue={consultantContributionsDatePicker}
                onDatePickerChange={handleConsultantContributionsDatePickerChange}
            />
            <Costs
                data={costsData}
                datePicker={costsDatePicker}
                handleDatePickerChange={handleCostsDatePickerChange}
            />
            <ContractorsAnalysis
                data={contractorsData}
                datePickerValue={contractorsDatePicker}
                onDatePickerChange={handleContractorsDatePickerChange}
            />
            <ExpenseAnalysis
                data={expensesData}
                datePickerValue={expensesDatePicker}
                onDatePickerChange={handleExpensesDatePickerChange}
            />
            <PaymentTermsAnalysis
                data={paymentTermsData}
                datePickerValue={paymentTermsDatePicker}
                onDatePickerChange={handlePaymentTermsDatePickerChange}
            />
        </>
    )
}

export default ManagementParameters;
