import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {notify} from '../../components/ui/Toast/Toast';

import Table from '../../components/ui/Table';
import {InvoiceActions} from '../../actions';
import {invoicesTableConfig} from './config/invoicesTableConfig';

const InvoicesToValidate = ({
    userData
}) => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("");
    const [paginationMetadata, setPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [loading, setLoading] = useState([]);

    // fetch data dunction
    const handleGetData = async (page, activeLoading = true) => {
        if (activeLoading) {
            setLoading(true);
            setData([]);
        }

        const result = await InvoiceActions.getAllInvoices(
            userData,
            `&page_size=${paginationMetadata.pageSize}&status=100${filter}`,
            page
        );
    
        if (result.success) {
            setData(result.data);
            setPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (paginationMetadata.totalItems !== result.metadata.count) {
                setPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données de la table «Factures à valider»");
        }

        setLoading(false);
    }

    // filter setter
    const filterHandler = (query) => {
        setFilter(query ? `&${query}` : '');
    }

    useEffect(() => {
        handleGetData(1);
    }, [filter])
    
    // config
    const tableConfig = invoicesTableConfig(filterHandler, userData, "");

    return (
        <>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures à valider (${paginationMetadata.totalItems.toLocaleString()})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={handleGetData}
                loading={loading}
            />
        </>
    )
}

export default InvoicesToValidate;
