import {ACTION_CODE} from './_actionCode';
import {CustomerService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_CUSTOMERS';

export async function updateCustomer(
    userData: Record<string, string>,
    updatedCustomer: Record<string, string>,
    customerId: number
) {
    try {
        LoggerService.logInfo('Action - updateCustomer - Start');

        const updateCustomerResult = await CustomerService.updateCustomer(userData, updatedCustomer, customerId);

        LoggerService.logInfo('Action - updateCustomer - End - Success');

        return ResultModel.newSuccessResult(updateCustomerResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateCustomer - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la modification du client.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
