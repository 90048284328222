import {DashboardDao} from '../../../../dao';
import {ErrorModel} from '../../../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_DASHBOARD_CONSULTANT_WORKLOAD';

export async function getAllWorkload(userData: Record<string, string>) {
    try {
        const result = await DashboardDao.ConsultantDao.WorkloadDao.getAllWorkload(userData.userToken);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
