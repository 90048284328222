export const missionsProfitabilityChartConfig = (data, colors, legendLabels, max, min) => {
    return {
        appendPadding: 35,
        data,
        xField: 'type',
        yField: 'values',
        isRange: true,
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'adjust-color'
                }
            ]
        },
        legend: {
            layout: 'horizontal',
            position: 'top-right',
            itemWidth: 0,
            maxItemWidth: 0,
            maxWidth: 0,
            items: legendLabels,
            flipPage: false
        },
        seriesField : 'type',
        color: colors,
        xAxis: {
            animate: false,
            min: 0
        },
        yAxis: {
            animate: false,
            max: max,
            min: min
        },
        maxColumnWidth: 125
    };
}
