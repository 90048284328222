export const aggregateMarginPerStatus = (by_status: any, key: string) => {
 
    const das = [
        {
            type: 'Junior',
            value: parseInt(by_status[key].junior.gross_margin.toFixed(2))
        },
        {
            type: 'Confirmé',
            value: parseInt(by_status[key].confirmed.gross_margin.toFixed(2))
        },
        {
            type: 'Sénior',
            value: parseInt(by_status[key].senior.gross_margin.toFixed(2))
        }
        // {
        //     type: 'Stagiaire',
        //     value: parseInt(by_status[key].intern.gross_margin.toFixed(2))
        // }
    ];

    return das;
    
}

export const aggregateMarginPerStatusTotal = (by_status: any) => {

    console.log('by status', by_status);

    let juniorTotal = 0;
    let confirmedTotal = 0;
    let seniorTotal = 0;
    // let internTotal = 0;

    for (const key in by_status) {
        juniorTotal += by_status[key].junior.gross_margin;
        confirmedTotal += by_status[key].confirmed.gross_margin;
        seniorTotal += by_status[key].senior.gross_margin;
        // internTotal += by_status[key].intern.gross_margin;
    }
    
    return [
        {
            type: 'Junior',
            value: parseInt(juniorTotal.toFixed(2))
        },
        {
            type: 'Confirmé',
            value: parseInt(confirmedTotal.toFixed(2))
        },
        {
            type: 'Sénior',
            value: parseInt(seniorTotal.toFixed(2))
        }
        // {
        //     type: 'Stagiaire',
        //     value: parseInt(internTotal.toFixed(2))
        // }
    ]

}