import React from 'react';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import Button from '../../../../components/ui/Button';
import plus from '../../../../assets/icons/button/plus.png';
import Table from '../../../../components/ui/Table';

const CustomersDataSection = ({
    data,
    tableConfig,
    tableLoading,
    handleButtonClick,
    onDatePickerChange,
    datePickerValue,
    customerPaginationMetadata,
    handleGetAllCustomers
}) => {

    return (
        <div className='px-20 pt-12 pb-36'>
            <div className="flex items-center justify-between mb-8">
                <div className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>
                    Clients
                    <span className="text-xs ml-1 text-[#646464]">
                        ({data?.count})
                    </span>

                </div>
                
                <Button
                    content={"Nouveau client"}
                    icon={plus}
                    onClick={handleButtonClick}
                />
            </div>

            <div className='my-10'>
                <Datepicker
                    type="range"
                    onChange={onDatePickerChange}
                    defaultValue={datePickerValue}
                />
            </div>

            <Table
                data={data?.results || []}
                tableConfig={tableConfig}
                loading={tableLoading}
                paginationMetadata={customerPaginationMetadata}
                onPageNumberChange={handleGetAllCustomers}
            />
        </div>
    )
}

export default CustomersDataSection
