import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';

import Popup from '../../../components/ui/Popup';
import ContainerCard from '../../../components/ui/ContainerCard';
import TextInput from '../../../components/ui/Inputs/TextInput';
import Button from '../../../components/ui/Button';
import {DealActions} from '../../../actions';
import {notify} from '../../../components/ui/Toast/Toast';
import Error from '../../../components/ui/Error/Error';
import {getErrors} from '../../../utils';

const ValidatedSurveyPopup = ({
    view,
    setView,
    userData,
    itemToEdit,
    refreshData
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: {email: ''}});

    useEffect(() => {
        if (!view) {
            setErrors(null);
            reset();
        }
    }, [view]);

    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const payload = {
            satisfaction_survey_email: data.email,
            satisfaction_survey_status: "to_send"
        }

        if (isValid) {
            const result = await DealActions.updateDeal(userData, payload, itemToEdit.id);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify('L\'enquete de satisfaction a été validé');
                setView(false);
                reset();
            }

            setLoading(false);
            setDisabled(false);
        }
    }

    return (
        <Popup view={view} setView={setView} width={'1098px'}>
            <ContainerCard
                title="Destinataire"
                coloredButtonDisplayed={false}
            >
                <div className="flex items-center justify-between mb-12">
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir l\' adresse mail'
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Veuillez saisir une adresse mail valide'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Adresse mail'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Adresse mail du destinataire"
                                type="email"
                                width='w-96'
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col justify-center pb-2 mt-6">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => {
                                reset();
                                setView(false);
                            }}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content="Valider"
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
        </Popup>
    );
}

export default ValidatedSurveyPopup;
