import {useState, useEffect} from 'react';

import {frenchFormat, handleFilterList, useFilterQuery, fetchSelectData} from '../../../utils';
import {DealActions, LocationActions} from '../../../actions';
import {SelectInput} from '../../../components/ui/Table';

export const productionsTable = (filterInvoiceList, userData) => {
    const [deals, setDeals] = useState([]);
    const [locations, setLocations] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        deal: '',
        location: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterInvoiceList);

    // ↓ get data for select input
    const handleGetDeals = async (search, page = null) => {
        const result = await DealActions.getAllDeals(userData, `&type=business&display=${userData.userProfileName}`, page);
        const hasNextPage = result.data.next ? true : false;

        if (page === 1 || !page || search) {
            setDeals({ options: result.data, hasNextPage });
        }
        else {
            setDeals(prevData => ({ options: [...prevData.options, ...result.data.results], hasNextPage }));
        }
    };

    const handleGetLocations = async (search, page = 1) => {
        await fetchSelectData({action: LocationActions.getAllLocation, search:`&search=${search}&is_active=true`, page, setter: setLocations, userData, resultPath: 'data'});
    };

    useEffect(() => {
        // ↓ deals dropdown values
        (async () => {
            await handleGetDeals('', 1);
        });

        // ↓ location dropdown values
        (async () => {
            await handleGetLocations('', 1);
        })
    }, []);

    return (
        {
            dropdown: true,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Affaires',
                    value: 'short_name',
                    width: 'w-[40%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start">
                                {item.short_name}
                            </div>);
                    },
                    filterComponent: () => <SelectInput
                        name="deal"
                        options={deals.options}
                        hasNextPage={deals?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetDeals)}
                        labelKeys={['short_name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: true,
                    sumBy: 'item',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: null,
                    width: 'w-[15%]',
                    sort: false,
                    component: (item) => {
                        item.productions.sort((a, b) => a.date.localeCompare(b.date));

                        const startDate = item.productions[0].date;
                        const endDate = item.productions[item.productions.length - 1].date;

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {`${frenchFormat(startDate)} - ${frenchFormat(endDate)}`}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Localisation',
                    value: 'customer.location',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.customer?.location}
                            </div>);
                    },
                    filterComponent: () => {
                        const sortedLocations = locations?.options?.sort((a, b) => b.name.localeCompare(a.name));

                        return (
                            <SelectInput
                                name="location"
                                options={sortedLocations || []}
                                hasNextPage={locations?.hasNextPage}
                                isSearchable={true}
                                onChange={handleFilter}
                                fetchFunction={(handleGetLocations)}
                                labelKeys={['name']}
                                valueKey={'name'}
                                labelWidth='w-40'
                            />
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours produits',
                    value: 'produced_days',
                    width: 'w-[30%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.productions.reduce((acc, curr) => acc + +curr.produced_days, 0)}
                            </div>);
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jours facturés',
                    value: 'billed_days',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.productions.reduce((acc, curr) => acc + +curr.billed_days, 0)}
                            </div>);
                    },
                    sumDisplayed: true,
                    sumLabel: 'jours'
                },
                {
                    label: 'Jours non facturés',
                    value: 'unbilled_days',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+((item.produced_days - item.billed_days).toFixed(2))}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Actions',
                    value: '',
                    width: 'w-[9%]',
                    sort: false,
                    component: () => {return null;},
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
