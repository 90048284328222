import {ACTION_CODE} from './_actionCode';
import {DealTagsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_DEALTAG';

export async function deleteDealTag(userData: Record<string, any>, dealTagId: number) {
    try {
        LoggerService.logInfo('Action - deleteDealTagById - Start');

        const deleteDealTagByIdResult = await DealTagsService.deleteDealTag(userData, dealTagId);

        LoggerService.logInfo('Action - deleteDealTagById - End - Success');

        return ResultModel.newSuccessResult(deleteDealTagByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteDealTagById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
