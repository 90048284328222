import {EuroCircleFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import {transformThousandNumbers} from '../../../../utils';

const CommercialAcquisitionCost = ({data, getPercent}) => {
    const euroIcon = <EuroCircleFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = () => {
        let sum = 0;

        for (const key in data) {
            sum += data[key];
        }

        return sum.toFixed();
    }

    return (
        <div className='my-10'>
            <LineTitle title='Coût commercial d’acquisition'/>
            <div className='mt-6'>
                <SimpleCard
                    width='w-[50%]'
                    iconComponent={euroIcon}
                    title={transformThousandNumbers(getSum())}
                    sideTitle={`(${getPercent(getSum())}%)`}
                    subTitle="Coût commercial d’acquisition"
                    background='orange'
                    bottomComponent={<BottomComponent data={data} getPercent={getPercent}/>}
                />
            </div>
        </div>
    )
}

export default CommercialAcquisitionCost;

const BottomComponent = ({
    data,
    getPercent
}) => {
    let dasS = [];

    for (const key in data) {
        dasS.push({
            das: key,
            value: transformThousandNumbers(data[key]),
            percent: getPercent(data[key])
        });
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge content='JDH' color='#f0a841' type='round'/>
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                                <span className='text-sm font-normal text-gradient'>
                                    {`(${el.percent}%)`}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
