import {useState, useRef, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {notify} from '../../../../components/ui/Toast/Toast';
// import TextInput from '../../../../components/ui/Inputs/TextInput';
import TextInputBis from '../../../../components/ui/Inputs/TextInputBis';
import ContainerCard from '../../../../components/ui/ContainerCard';
import SelectInput from '../../../../components/ui/Inputs/SelectInput';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import Button from '../../../../components/ui/Button';
import ConfirmationPopup from '../../../../components/ui/ConfirmationPopup';
import Error from '../../../../components/ui/Error/Error';
import {OfficeActions, SeniorityActions, ContractActions, DealDasActions} from '../../../../actions';
import {fetchSelectData, frenchFormat, getErrors, standardFormat} from '../../../../utils';

const ContractForm = ({
    setView,
    setTabSelected,
    contracts,
    userData,
    refreshContracts,
    staffingItemToBeUpdated
}) => {
    const [errors, setErrors] = useState();
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [isNewContract, setIsNewContract] = useState(false);
    const [ressource, setRessource] = useState(null);
    const [confirmationPopupView, setConfirmationPopupView] = useState(false);
    const [validity, setValidity] = useState(false);

    const formRef = useRef();

    useEffect(() => {
        if (contracts.length) {
            handleContractSelected(contracts[0].id);
        }
        else {
            setIsNewContract(true);
        }
    }, [contracts]);

    useEffect(() => {
        if (staffingItemToBeUpdated) {
            setRessource(staffingItemToBeUpdated.ressource);
        }
    }, [staffingItemToBeUpdated]);

    useEffect(() => {
        if (!isNewContract && contracts.length) {
            handleContractSelected(contracts[0].id);
        }
    }, [isNewContract]);

    const handleContractSelected = (id) => {
        if (id && isNewContract) {
            setSelectedContract(null);

            return;
        }
        
        const contract = contracts.find((item) => item.id === id);
        setSelectedContract(contract);
    };

    const handleSetNewContract = () => {
        setIsNewContract(true);
        setSelectedContract(null);
    };

    const handleDeleteContract = async () => {
        if (isNewContract) {
            setIsNewContract(false);

            return;
        }
        setLoading(true);
        
        const result = await ContractActions.deleteContract(userData, selectedContract.id);

        if (result.success) {
            notify(`Le contrat a été supprimé avec succès.`);
            await refreshContracts(ressource.id);
        }
        else {
            setErrors(getErrors(result));
        }

        setLoading(false);
    };
    
    return (
        <>
            <ContainerCard
                title={ressource && `${ressource.first_name} ${ressource.last_name}`}
                headerBackground='bg-[#F4F6F8]'
                coloredButtonDisplayed={false}
                overflow='overflow-visible'
                contentPadding='pb-10'
            >
                <div className='bg-[#F4F6F8] h-10 flex justify-start gap-6 px-6'>
                    <div className='cursor-pointer'>
                        <p
                            className='h-full bg-[#F4F6F8] font-semibold text-[#637381]'
                            onClick={() => setTabSelected(0)}
                        >
                            Informations personnelles
                        </p>
                    </div>
                    <div className='background-gradient pb-[2px]'>
                        <p
                            className='h-full bg-[#F4F6F8] font-bold'
                            onClick={() => setTabSelected(0)}
                        >
                            Contrats
                        </p>
                    </div>
                </div>
                <div className='flex flex-row justify-between px-6 py-4 my-5'>
                    <div className='flex justify-start w-9/12 my-3 overflow-auto gap-x-7'>
                        {
                            isNewContract ?
                                <div className={`background-gradient block h-8 min-w-[105px] pb-[2px] cursor-pointer`}>
                                    <p className='h-full bg-white font-bold text-[14px]'>Nouveau contrat</p>
                                </div>
                                : null
                        }
                        {
                            contracts.map((form) => {
                                return (
                                    <div
                                        key={form.id}
                                        className={`${!isNewContract && selectedContract?.id === form.id ? 'background-gradient' : 'bg-white'} block h-8 min-w-[150px] pb-[2px] cursor-pointer`}
                                    >
                                        <p
                                            onClick={() => handleContractSelected(form.id)}
                                            className={`h-full bg-white font-bold text-[14px] ${selectedContract?.id === form.id ? 'text-black' : 'text-[#637381]'}`}>
                                            {frenchFormat(form.start_date)} - {form.end_date ? frenchFormat(form.end_date) : '?'}
                                        </p>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        contracts.length > 0 && !isNewContract ?
                            <Button
                                onClick={handleSetNewContract}
                                height={'h-10'}
                                type={'primary'}
                                content={'Nouveau contrat'}
                                disabled={disabled}
                            />
                            : null
                    }
                </div>
                <Form
                    disabled={disabled}
                    setDisabled={setDisabled}
                    setLoading={setLoading}
                    setView={setView}
                    formRef={formRef}
                    setErrors={setErrors}
                    selectedContract={selectedContract}
                    isNewContract={isNewContract}
                    userData={userData}
                    refreshContracts={refreshContracts}
                    ressource={ressource}
                    setIsNewContract={setIsNewContract}
                    setValidity={setValidity}
                />
                {
                    ((isNewContract || selectedContract) && contracts.length) ?
                        <>
                            <div className='mt-10 ml-4'>
                                <Button
                                    onClick={isNewContract ? handleDeleteContract : () => setConfirmationPopupView(true)}
                                    height={'h-10'}
                                    type={'primary'}
                                    content={'Supprimer le contrat'}
                                    disabled={disabled}
                                />
                            </div>
                            <ConfirmationPopup
                                view={confirmationPopupView}
                                setView={setConfirmationPopupView}
                                title='Supprimer le contrat'
                                subtitle='Souhaitez-vous supprimer ce contrat ?'
                                onConfirm={handleDeleteContract}
                            />
                        </>
                        : null
                }
            </ContainerCard>
            <div className="flex flex-col justify-center pb-2 mt-6">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={'Sauvegarder'}
                        onClick={() => {formRef.current.click()}}
                        loading={loading}
                        disabled={disabled || !validity}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </>
    )
}

export default ContractForm;

const Form = ({
    disabled,
    formRef,
    setDisabled,
    setView,
    setLoading,
    setErrors,
    isNewContract,
    selectedContract,
    userData,
    refreshContracts,
    ressource,
    setIsNewContract,
    setValidity
}) => {
    const [offices, setOffices] = useState(null);
    const [seniorities, setSeniorities] = useState(null);
    const [dass, setDass] = useState(null);
    
    const defaultValues = {
        ressource: '',
        seniority: '',
        office: '',
        start_date: '',
        end_date: '',
        presence: '',
        sales_objective: '',
        production_objective: '',
        billing_objective: '',
        yearly_salary: '',
        gross_yearly_salary: '',
        production_share: '',
        support_share: '',
        management_share: '',
        das: ''
    };

    const {handleSubmit: formSubmit ,control, reset, formState: {isValid}} = useForm({defaultValues});

    useEffect(() => {
        if (isNewContract && ressource) {

            ressource.name = `${ressource.first_name} ${ressource.last_name}`;
            
            reset({
                ...defaultValues,
                ressource: ressource
            });
        }
        else {
            if(selectedContract && dass && offices && seniorities && ressource) {

                ressource.name = `${ressource.first_name} ${ressource.last_name}`;

                reset({
                    ressource: ressource,
                    seniority: seniorities.options.find(el => el.name === selectedContract.seniority),
                    office: offices.options.find(el => el.name === selectedContract.office),
                    start_date: selectedContract.start_date,
                    end_date: selectedContract.end_date,
                    sales_objective: selectedContract.sales_objective ,
                    production_objective: selectedContract.production_objective ,
                    billing_objective: selectedContract.billing_objective ,
                    yearly_salary: selectedContract.yearly_salary,
                    gross_yearly_salary: selectedContract.gross_yearly_salary,
                    production_share: parseInt(selectedContract.production_share * 100),
                    support_share: parseInt(selectedContract.support_share * 100),
                    management_share: parseInt(selectedContract.management_share * 100),
                    presence: parseInt(selectedContract.presence * 100),
                    das: dass.options.find(el => el.id === selectedContract.das)
                });
            }
        }
    }, [isNewContract, selectedContract, dass, offices, seniorities, ressource]);

    const onSubmit = async (data) => {
        setLoading(true);
        setDisabled(true);
        setErrors(null);

        const payload = {
            ressource_id: data.ressource.id,
            seniority: data.seniority.name,
            office: data.office.name,
            start_date: standardFormat(data.start_date),
            end_date: standardFormat(data.end_date),
            sales_objective: data.sales_objective,
            production_objective: data.production_objective,
            billing_objective: data.billing_objective,
            yearly_salary: data.yearly_salary,
            gross_yearly_salary: data.gross_yearly_salary,
            production_share: data.production_share,
            support_share: data.support_share,
            management_share: data.management_share,
            presence: (data.presence / 100).toString(),
            das: data.das.id
        }

        console.log('payload', payload);

        if(isNewContract) {
            const result = await ContractActions.createContract(userData, payload);
            
            if (result.success) {
                await refreshContracts(ressource.id);
                setIsNewContract(false);
                notify('Le contrat a été crée avec succès');
            }
            else {
                setErrors(getErrors(result));
            }
        }
        else {
            const result = await ContractActions.updateContract(userData, payload, selectedContract.id);
            
            if (result.success) {
                await refreshContracts(ressource.id);
                setView(false);
                notify('Le contrat a été modifié avec succès');
            }
            else {
                setErrors(getErrors(result));
            }
        }

        setLoading(false);
        setDisabled(false);
    };

    // -- functions that get data for Select Inputs --
    const handleGetOffices = async (search, page) => {
        await fetchSelectData({action: OfficeActions.getAllOffices, search:`&search=${search}`, page, setter: setOffices, userData});
    };

    const handleGetSeniorities = async (search, page) => {
        await fetchSelectData({action: SeniorityActions.getAllSeniority, search:`&search=${search}`, page, setter: setSeniorities, userData});
    }

    const handleGetDass = async (search, page) => {
        await fetchSelectData({action: DealDasActions.getAllDealDas, search:`&search=${search}`, page, setter: setDass, userData});
    }

    useEffect(() => {
        (async () => {
            await handleGetOffices('', 1);
        });

        (async () => {
            await handleGetSeniorities('', 1);
        });

        (async () => {
            await handleGetDass('', 1);
        });
    }, []);

    useEffect(() => {
        setValidity(isValid);
    }, [isValid]);
    
    return (
        <div className='px-6'>
            <p className='my-5 text-2xl font-bold text-gradient'>Informations</p>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="ressource"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir l\'id'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Ressource'
                            onChange={onChange}
                            value={value.name || ''}
                            error={error}
                            required={true}
                            disabled={true}
                            placeholder="Id"
                            type="text"
                            readOnly={true}
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="seniority"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez sélectionner la seniorité'
                    }}}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label="Séniorité"
                            placeholder="Séniorité"
                            inputRef={ref}
                            error={error}
                            value={seniorities?.options.find((item) => item.name === value) || value}
                            options={seniorities?.options}
                            hasNextPage={seniorities?.hasNextPage}
                            onChange={onChange}
                            fetchFunction={handleGetSeniorities}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            loading={!seniorities}
                            isSearchable={true}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="das"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez sélectionner la terre d\'accueil'
                    }}}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label="DAS"
                            placeholder="DAS"
                            inputRef={ref}
                            error={error}
                            value={dass?.options.find((item) => item.name === value) || value}
                            options={dass?.options}
                            hasNextPage={dass?.hasNextPage}
                            onChange={onChange}
                            fetchFunction={handleGetDass}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            loading={!dass}
                            isSearchable={true}
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="office"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez sélectionner le bureau'
                    }}}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label="Terre d\'accueil"
                            placeholder="Terre d'accueil"
                            inputRef={ref}
                            error={error}
                            value={offices?.options.find((item) => item.name === value) || value}
                            options={offices?.options}
                            hasNextPage={offices?.hasNextPage}
                            onChange={onChange}
                            fetchFunction={handleGetOffices}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            loading={!offices}
                            isSearchable={true}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="gross_yearly_salary"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir salaire brut chargé'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Salaire brut chargé (€)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Salaire brut chargé (€)"
                            type="number"
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="yearly_salary"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le salaire brut annuel'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Salaire brut annuel (€)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Salaire brut annuel (€)"
                            type="number"
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="start_date"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir la date de début'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <DateInput
                            label={'Commence le'}
                            onChange={onChange}
                            value={frenchFormat(value)}
                            error={error}
                            required={true}
                            disabled={disabled}
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="end_date"
                    control={control}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <DateInput
                            label={'Termine le'}
                            onChange={onChange}
                            value={frenchFormat(value)}
                            error={error}
                            required={false}
                            disabled={disabled}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <p className='my-5 text-2xl font-bold text-gradient'>Taux</p>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="production_share"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le taux de production'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Taux Production (%)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Taux Production (%)"
                            type="number"
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="management_share"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir taux de managment'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Taux Management (%)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Taux Management(%)"
                            type="number"
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="presence"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le taux de présence'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Taux Présence (%)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Taux Présence (%)"
                            min={0}
                            max={100}
                            type="number"
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="support_share"
                    control={control}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Taux Fonction support (%)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={false}
                            disabled={disabled}
                            placeholder="taux Fonction support (%)"
                            min={0}
                            max={100}
                            type="number"
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <p className='my-5 text-2xl font-bold text-gradient'>Objectifs</p>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="sales_objective"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir l\'objectif commercial'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Objectif Commercial (€)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Objectif Commercial (€)"
                            type="number"
                            min={0}
                            max={100}
                            labelWidth='w-44'
                        />
                    )}
                />
                <Controller
                    name="production_objective"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir l\'objectif production'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Objectif Production (J)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Objectif Production (J)"
                            type="number"
                            min={0}
                            max={100}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <div className='flex items-center justify-between py-1 gap-28'>
                <Controller
                    name="billing_objective"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir l\'objectif facturation'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInputBis
                            label='Objectif Facturation (%)'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Objectif Facturation (%)"
                            type="number"
                            min={0}
                            max={100}
                            labelWidth='w-44'
                        />
                    )}
                />
            </div>
            <input ref={formRef} onClick={formSubmit(onSubmit)} className='hidden'/>
        </div>
    )
};
