import {useState, useEffect} from 'react';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import ActionsButton from '../../../../components/ui/ActionsButton';
import {SelectInput, SearchInput} from './../../../../components/ui/Table';
import Badge from '../../../../components/ui/Badge';
import {handleFilterList, useFilterQuery, fetchSelectData, frenchFormat} from './../../../../utils'
import { UserActions, ProductionCategoryActions } from './../../../../actions';

export const dealProductionsTableConfig = (
    handleEditProductionClick,
    handleDeleteProductionClick,
    buttonsDisabled,
    deleteProductionLoading,
    blueStyle,
    filterInvoiceList,
    userData,
    cannotBeModified
) => {
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        category: '',
        location: ''
    });

    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterInvoiceList);

    // ↓ get data for select input
    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    }

    const handleGetProductionCategories = async (search, page = 1) => {
        await fetchSelectData({action: ProductionCategoryActions.getAllProductionCategories, search:`&search=${search}`, page: page, setter: setCategories, userData});
    }

    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        });

        // ↓ Production categories dropdown values
        (async () => {
            await handleGetProductionCategories('', 1);
        });
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Ressource',
                    value: 'ressource.username',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                <Badge type="round" content={[item.ressource]}/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="ressource"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'category',
                    width: 'w-[21%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start">
                                {item.category}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="category"
                        options={categories?.options}
                        hasNextPage={categories?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetProductionCategories)}
                        labelKeys={['name']}
                        valueKey={'id'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Localisation',
                    value: 'location',
                    width: 'w-[13%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item?.location?.length > 0 ? item.location : '-'}
                            </div>
                        );
                    },
                    filterComponent: () => <SearchInput name='location' onChange={handleFilter}/>,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours Produits',
                    value: 'produced_days',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.produced_days}
                            </div>);
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jours facturés',
                    value: 'billed_days',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+item.billed_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Jours non facturés',
                    value: 'unbilled_days',
                    width: 'w-[12%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+((+item.produced_days - +item.billed_days).toFixed(3))}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    cannotBeModified ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </>
                                        :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => handleEditProductionClick(item)}
                                                disabled={buttonsDisabled}
                                                blueStyle={blueStyle}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteProductionLoading === item.id}
                                                onClick={() => handleDeleteProductionClick(item.id)}
                                                disabled={buttonsDisabled}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce temps ?'
                                                blueStyle={blueStyle}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
