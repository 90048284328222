import { useState, useEffect } from "react";
import {Link} from "react-router-dom";

import Badge from "../../../../components/ui/Badge";
import ActionsButton from "../../../../components/ui/ActionsButton";
import {getRafOverrun, fetchSelectData, handleFilterList, useFilterQuery} from "../../../../utils";
import { UserActions } from '../../../../actions';
import { SelectInput } from '../../../../components/ui/Table';

export const pageTableMissionsConfig = ({
    missionsTableEdit,
    filterMissionList,
    userData
}) => {
    const [users, setUsers] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: ''
    });
    
    const handleFilter = (value, name) => {
        handleFilterList(value, name, filterQuery, setFilterQuery);
    };

    useFilterQuery(filterQuery, filterMissionList);

    const handleGetUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setUsers, userData});
    }
    
    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetUsers('', 1);
        })
    }, []);
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Ressource',
                    value: 'ressource',
                    width: 'w-[4%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full text-center">
                                <Badge content={[item.ressource]} type='round'/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="ressource"
                        options={users?.options}
                        hasNextPage={users?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetUsers)}
                        labelKeys={['first_name', 'last_name']}
                        valueKey={'username'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: true,
                    sumBy: 'item',
                    sumLabel: ''
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {parseInt(item.budget).toLocaleString()}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Jours',
                    value: 'billable_days',
                    width: 'w-[7.5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {+item.billable_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'TJM',
                    value: 'daily_rate',
                    width: 'w-[7.5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {+item.daily_rate}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    key: 'status',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const missionStatus = () => {
                            switch (item.status) {
                                case 0:
                                    return {status: 'Prévue', color: '#f0a841'};
                                case 1:
                                    return {status: 'En cours', color: '#e36d38'};
                                case 2:
                                    return {status: 'Terminée', color: '#ced95f'};
                                default:
                                    return {status: 'Indéfini', color: '#212121'};
                            }
                        };

                        const status = [
                            {
                                id: 0,
                                name: "Prévue"
                            },
                            {
                                id: 1,
                                name: "En cours"
                            },
                            {
                                id: 2,
                                name: "Terminée"
                            }
                        ]

                        return (
                            <>
                                {missionsTableEdit ? (
                                    <div className=''>
                                        <select defaultValue={value} onChange={onChange}>
                                            {status.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex flex-row items-center justify-center text-center">
                                        <Badge content={missionStatus().status} color={missionStatus().color}/>
                                    </div>)}
                            </>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'RAF',
                    value: 'raf',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.raf}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Proj. M',
                    value: 'projection_m1',
                    key: 'projection_m1',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m1');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? `text-${overrun}-500` : ''}`}>
                                            {+item.projection_m1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Proj. M+1',
                    value: 'projection_m2',
                    key: 'projection_m2',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m2');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? `text-${overrun}-500` : ''}`}>
                                            {+item.projection_m2}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Proj. M+2',
                    value: 'projection_m3',
                    key: 'projection_m3',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m3');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? `text-${overrun}-500` : ''}`}>
                                            {+item.projection_m3}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Trimestre',
                    value: 'projection_q1',
                    key: 'projection_q1',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_q1');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? `text-${overrun}-500` : ''}`}>
                                            {+item.projection_q1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Semestre',
                    value: 'projection_s1',
                    key: 'projection_s1',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_s1');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_s1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[5%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Link to={`${item.id}`} id='skip' className='ml-1'>
                                    <ActionsButton
                                        type="details"
                                        isLoading={false}
                                        onClick={() => null}
                                        disabled={false}
                                    />
                                </Link>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
