import {AttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'CREATE__ATTACHMENT';

export async function createAttachment(
    userData: Record<string, string>,
    payload: Record<string, unknown>
) {

    try {
        const result = await AttachmentDao.creatAttachment(userData.userToken, payload);

        return result;
    }

    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
