import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import {ProductionCategoryActions, ProductionActions} from '../../../../actions';
import {notify} from '../../../../components/ui/Toast/Toast';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput.js';
import {frenchFormat, standardFormat, fetchSelectData} from '../../../../utils';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput';
import Button from '../../../../components/ui/Button/Button';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const ProductionsPopUp = ({
    setView,
    data,
    userData,
    productionToBeUpdated,
    refreshProductionData
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState(null);
    const [errors, setErrors] = useState(null);

    const defaultDate = (productionToBeUpdated && productionToBeUpdated.date && !productionToBeUpdated.id) ? dayjs(productionToBeUpdated.date) : dayjs();

    // -- react hook form --
    const defaultValues = {
        date: defaultDate,
        location: null,
        produced_days: null,
        category: '',
        billed_days: null,
        notes: ''
    };
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (productionToBeUpdated) {
            reset({
                date: dayjs(productionToBeUpdated.date),
                location: productionToBeUpdated.location,
                produced_days: (+productionToBeUpdated.produced_days).toString(),
                category: {name: productionToBeUpdated.category},
                billed_days: (+productionToBeUpdated.billed_days).toString(),
                notes: productionToBeUpdated.notes
            });
        }
    }, [productionToBeUpdated]);

    const handleGetCategories = async (search, page = 1) => {
        await fetchSelectData({action: ProductionCategoryActions.getAllProductionCategories, search:`&search=${search}`, page: page, setter: setCategories, userData});
    };

    useEffect(() => {
        (async () => {
            await handleGetCategories('', 1);
        })();
    }, []);

    const onSubmit = async (formData) => {
        setErrors(null);
        setIsDisabled(true);
        setIsLoading(true);

        const productionData = {
            date: standardFormat(formData.date),
            location: formData.location,
            produced_days: formData.produced_days.toString(),
            category: formData.category.name,
            billed_days: formData.billed_days.toString(),
            deal_id: data.deal.id,
            ressource_id: data.ressource.id,
            notes: formData.notes,
            mission: data.id
        }

        if (!productionToBeUpdated || productionToBeUpdated.date && Object.keys(productionToBeUpdated).length === 1) {
            const result = await ProductionActions.createProduction(userData, productionData);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshProductionData();
                notify('Votre temps à bien été créé');
                setView(false);
            }
        }
        else {
            const result = await ProductionActions.updateProduction(userData, productionToBeUpdated.id, productionData);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshProductionData();
                notify('Votre temps à bien été modifié');
                setView(false);
            }
        }

        setIsLoading(false);
        setIsDisabled(false);
    }

    return (
        <ContainerCard
            title={productionToBeUpdated ? 'Modifier le temps' : 'Nouveau temps'}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-4">
                <div className='flex flex-col'>
                    <div className="mb-2">
                        <Controller
                            name="date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner une date.'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Date'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="location"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Emplacement'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={false}
                                    disabled={isDisabled}
                                    placeholder="Localisation"
                                    type="string"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="category"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner un type de temps.'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type de temps'
                                    placeholder='Type de temps'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={categories?.options.sort((a, b) => a.id - b.id)}
                                    hasNextPage={categories?.hasNextPage}
                                    onChange={onChange}
                                    loading={!categories}
                                    fetchFunction={handleGetCategories}
                                    labelKeys={['name']}
                                    isSearchable={true}
                                    required={true}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col justify-between'>
                    <div className="mb-2">
                        <Controller
                            name="produced_days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer un nombre de jours produits.'
                                }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Jours produits (j)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    min="0"
                                    placeholder="Jours produits (j)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="billed_days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez indiquer un nombre de jours facturés.'
                                }
                            }}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label={'Jours facturés (j)'}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={isDisabled}
                                    min="0"
                                    placeholder="Jours facturés (j)"
                                    type="number"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            disabled={isDisabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type='white'
                        content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content={productionToBeUpdated ? 'Modifier le temps' : 'Créer le temps'}
                        loading={isLoading}
                        disabled={!isValid || isDisabled}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    )
}

export default ProductionsPopUp;
