import {UserDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_USER_DETAILS';

export async function getUserDetails(userToken: string) {
    try {
        const result = await UserDao.getUserDetails(userToken);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation des détails d\'un utilisateur.',
            error: error
        });
    }
}
