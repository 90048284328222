import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'CREATE_PRODUCTION';

export async function createProduction(
    userToken: string,
    newProduction: Record<string, unknown>
) {
    try {
        const response = await axios({
            method: 'post',
            url: `${URL}/productions/`,
            data: newProduction,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        const errorMessage = () => {
            if (error.response.data.billed_days) {
                return error.response.data.billed_days[0];
            }
            else if (error.response.data.ressource) {
                return error.response.data.ressource[0];
            }
            else {
                return 'Une erreur de la création d\'une production';
            }
        };

        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: errorMessage(),
            error: error
        });
    }
}
