import {ACTION_CODE} from './_actionCode';
import {TrainingProductOriginService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_TRAINING_PRODUCTORIGIN';

export async function createTrainingProductOrigin(
    userData: Record<string, string>,
    newTrainingProductOrigin: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createTrainingProductOrigin - Start');

        const createTrainingProductOriginResult = await TrainingProductOriginService.createTrainingProductOrigin(userData, newTrainingProductOrigin);

        LoggerService.logInfo('Action - createTrainingProductOrigin - End - Success');

        return ResultModel.newSuccessResult(createTrainingProductOriginResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createTrainingProductOrigin - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
