import ExpenseDayEvents from '../components/ExpenseDayEvents';
import ExpenseMonthEvents from '../components/ExpenseMonthEvents';

export const expensesCalendarConfig = () => {
    return (
        {
            isWeekEndOpennedDay: false,
            monthFilterDataFunction: (data, date) => {
                return data[date];
            },
            yearFilterDataFunction: (data, date) => {
                const currentDate = new Date(date);
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();

                return data[`${currentYear}-${currentMonth >= 9 ? currentMonth + 1 : `0${currentMonth + 1}`}`];
            },
            dayCellEventsComponent: (dayData, dayFormated) => {
                return (
                    <ExpenseDayEvents dayData={dayData} dayFormated={dayFormated}/>
                );
            },
            monthCellEventsComponent: (monthData) => {
                return (
                    <ExpenseMonthEvents monthData={monthData}/>
                );
            }
        }
    );
};
