import {ExpenseReportDao} from '../../dao';
import {ExpenseReportModel} from '../../models';
import {
    ErrorModel
} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_EXPENSE_REPORT';

export async function updateExpenseReportById(
    userData: Record<string, string>,
    expenseId: string,
    update: Record<string, any>
) {
    try {
        const result = await ExpenseReportDao.updateExpenseReportById(userData.userToken, expenseId, update);

        const resultParsed = ExpenseReportModel.parseExpenseReport(result);

        return resultParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'une note de frais',
            error: error
        });
    }
}
