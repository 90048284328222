import {ActivityTypesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'CREATE_ACTIVITY_TYPES';

export async function createActivityTypes(userData: Record<string, string>, payload: Record<string, unknown>) {
    try {
        const result = await ActivityTypesDao.createActivityTypes(userData, payload);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création du type d\'activité',
            error: error
        });
    }
}
