import {BookFilled, CopyFilled} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getCurrentMonthAndYear} from '../../../../utils';
import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import TabCard from "../../../../components/ui/TabCard";
import {pendingProposalsTabConfig} from '../../configs/commercialTab/activityIndicatorsSection/pendingProposalsTabConfig';
import {totalCount} from '../../functions/aggregateSalesActivity';

const ActivityIndicators = ({salesActivity}) => {
    const BookIcon = <BookFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const CopyIcon = <CopyFilled style={{ fontSize: '30px', color:'white' }}/>;

    return (
        <div className='my-10'>
            <LineTitle title="Indicateurs d'activité" textSize='text-4xl'/>
            <p style={{ fontFamily: 'Din condensed' }} className='text-2xl capitalize mt-7'>{getCurrentMonthAndYear()}</p>

            <div className='flex justify-between mt-6'>
                <Link className='w-[48%]' to='specification-to-study'>
                    <SimpleCard
                        title={totalCount(salesActivity.studying)}
                        subTitle="Cahiers des charges à étudier"
                        iconComponent={BookIcon}
                        width='w-full'
                        topComponent={<TopComponent
                            data={salesActivity.studying}
                        />}
                    />
                </Link>

                <Link className='w-[48%]' to='proposals-being-written'>
                    <SimpleCard
                        title={totalCount(salesActivity.responding)}
                        subTitle="Propositions en cours de rédaction"
                        iconComponent={CopyIcon}
                        width='w-full'
                        topComponent={<TopComponent
                            data={salesActivity.responding}
                        />}
                    />
                </Link>
            </div>

            <div className="mt-6">
                <TabCard
                    config={pendingProposalsTabConfig(salesActivity.waiting)}
                />
            </div>
        </div>
    )
}

export default ActivityIndicators;

const TopComponent = ({data}) => {

    const allDasS = useSelector((state) => state.DasReducer.das);

    let dasS = [];

    for (const key in data) {
        let currentDas = allDasS.filter((el) => el.name === key);

        dasS.push({
            das: key,
            value: data[key].count,
            dasObject: currentDas = currentDas.length > 0 ? currentDas[0] : null
        })
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={el.das}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge
                                    content={el.dasObject ? [el.dasObject.manager] : '-'}
                                    color={el.dasObject ? null : '#bdc3c7'}
                                    type='round'
                                />
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
