import {ACTION_CODE} from './_actionCode';
import {ExpenseReportService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_EXPENSEREPORTBYID';

export async function deleteExpenseReportById(userData: Record<string, any>, expenseReportId: string) {
    try {
        LoggerService.logInfo('Action - deleteExpenseReportById - Start');

        const deleteExpenseReportByIdResult = await ExpenseReportService.deleteExpenseReportById(userData, expenseReportId);

        LoggerService.logInfo('Action - deleteExpenseReportById - End - Success');

        return ResultModel.newSuccessResult(deleteExpenseReportByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteExpenseReportById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une note de frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
