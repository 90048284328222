export const salesOverviewDto = {
    from(salesOverviewResult) {

        const getDasS = () => {
            let dasS = {};

            for (const key in salesOverviewResult) {
                dasS[key] = {
                    "studied": {
                        "Seul": {
                            "count": salesOverviewResult[key]?.studied?.Seul?.count ?? 0,
                            "days": salesOverviewResult[key]?.studied?.Seul?.days ?? 0,
                            "value": salesOverviewResult[key]?.studied?.Seul?.value ?? 0
                        },
                        "Cotraitance": {
                            "count": salesOverviewResult[key]?.studied?.Cotraitance?.count ?? 0,
                            "days": salesOverviewResult[key]?.studied?.Cotraitance?.days ?? 0,
                            "value": salesOverviewResult[key]?.studied?.Cotraitance?.value ?? 0
                        },
                        "Mandataire": {
                            "count": salesOverviewResult[key]?.studied?.Mandataire?.count ?? 0,
                            "days": salesOverviewResult[key]?.studied?.Mandataire?.days ?? 0,
                            "value": salesOverviewResult[key]?.studied?.Mandataire?.value ?? 0
                        }
                    },
                    "delivered": {
                        "Seul": {
                            "count": salesOverviewResult[key]?.delivered?.Seul?.count ?? 0,
                            "days": salesOverviewResult[key]?.delivered?.Seul?.days ?? 0,
                            "value": salesOverviewResult[key]?.delivered?.Seul?.value ?? 0
                        },
                        "Cotraitance": {
                            "count": salesOverviewResult[key]?.delivered?.Cotraitance?.count ?? 0,
                            "days": salesOverviewResult[key]?.delivered?.Cotraitance?.days ?? 0,
                            "value": salesOverviewResult[key]?.delivered?.Cotraitance?.value ?? 0
                        },
                        "Mandataire": {
                            "count": salesOverviewResult[key]?.delivered?.Mandataire?.count ?? 0,
                            "days": salesOverviewResult[key]?.delivered?.Mandataire?.days ?? 0,
                            "value": salesOverviewResult[key]?.delivered?.Mandataire?.value ?? 0
                        }
                    },
                    "lost": {
                        "Seul": {
                            "count": salesOverviewResult[key]?.lost?.Seul?.count ?? 0,
                            "days": salesOverviewResult[key]?.lost?.Seul?.days ?? 0,
                            "value": salesOverviewResult[key]?.lost?.Seul?.value ?? 0
                        },
                        "Cotraitance": {
                            "count": salesOverviewResult[key]?.lost?.Cotraitance?.count ?? 0,
                            "days": salesOverviewResult[key]?.lost?.Cotraitance?.days ?? 0,
                            "value": salesOverviewResult[key]?.lost?.Cotraitance?.value ?? 0
                        },
                        "Mandataire": {
                            "count": salesOverviewResult[key]?.lost?.Mandataire?.count ?? 0,
                            "days": salesOverviewResult[key]?.lost?.Mandataire?.days ?? 0,
                            "value": salesOverviewResult[key]?.lost?.Mandataire?.value ?? 0
                        }
                    },
                    "won": {
                        "Seul": {
                            "count": salesOverviewResult[key]?.won?.Seul?.count ?? 0,
                            "days": salesOverviewResult[key]?.won?.Seul?.days ?? 0,
                            "value": salesOverviewResult[key]?.won?.Seul?.value ?? 0
                        },
                        "Cotraitance": {
                            "count": salesOverviewResult[key]?.won?.Cotraitance?.count ?? 0,
                            "days": salesOverviewResult[key]?.won?.Cotraitance?.days ?? 0,
                            "value": salesOverviewResult[key]?.won?.Cotraitance?.value ?? 0
                        },
                        "Mandataire": {
                            "count": salesOverviewResult[key]?.won?.Mandataire?.count ?? 0,
                            "days": salesOverviewResult[key]?.won?.Mandataire?.days ?? 0,
                            "value": salesOverviewResult[key]?.won?.Mandataire?.value ?? 0
                        }
                    }
                }
            }

            return dasS;
        }

        return getDasS();
    }
}
