import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../../../components/ui/Inputs/SelectInput/SelectInput';
import Tooltip from 'antd/lib/tooltip';
import plus from '../../../../../../assets/icons/button/plus.png';
import deleteIcon from '../../../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../../../assets/icons/blueStyle/delete-blue.png';

// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const ResponseGroupModule = ({
    control,
    disabled,
    watch,
    partnerValues,
    setPartnerValues,
    setNewPartnerView,
    handleGetContractors,
    contractors,
    ressources,
    handleGetRessources,
    blueStyle = false
}) => {

    const [responseGroupLabel, setResponseGroupLabel] = useState('Terre d\'avance');

    const watchAllFields = watch();
    const taRoleValue = watch('response_groupement');
    const subsidiaryValue = watch('subsidiary');

    const taRoles = [
        { id: 1, name: 'Seul' },
        { id: 2, name: 'Mandataire' },
        { id: 3, name: 'Cotraitant' }
    ];

    const [partnerRoles, setPartnerRoles ] = useState(
        [
            { id: 1, name: 'Cotraitant' },
            { id: 2, name: 'Mandataire' }
        ]
    );

    useEffect(() => {

        // ↓ contractor dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();

    }, []);

    // --------------------------------------------
    // ↓ Rerender form to update response grouping label on change of subsidiary ↓
    // --------------------------------------------
    useEffect(() => {
        if (!subsidiaryValue) return;
        setResponseGroupLabel(subsidiaryValue?.name ===  "Terre d'Avance Formation" ? "Terre d'Avance Formation" : "Terre d'Avance");
    }, [subsidiaryValue])

    useEffect(() => {
        if (!taRoleValue) return;
        
        if (taRoleValue.name === "Mandataire") {
            const setPartnerValuesToMandataire = partnerValues.map((partner) => {
                return {
                    ...partner,
                    role: {id: 1, name: 'Cotraitant'}
                }
            });
            setPartnerRoles((setPartnerRoles) => setPartnerRoles.filter((role) => role.name === "Cotraitant"));
            setPartnerValues((setPartnerValuesToMandataire))
        }

        if (taRoleValue.name === "Cotraitant") {

            setPartnerRoles([
                { id: 1, name: 'Cotraitant' },
                { id: 2, name: 'Mandataire' }
            ]);

        }
            
    }, [taRoleValue])

    const handleAddPartner = () => {
        if (partnerValues.length < 10) {
            setPartnerValues((prevState) => [...prevState, {id: Math.random(), role: '', ressource: null}]);
        }
    }

    const handleRemovePartner = (id) => {
        setPartnerValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handlePartnerValueChange = (e, id, type) => {
        setPartnerValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }

    const filterOutAlreadySelected = (options, selected) => {
        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.name === obj2?.ressource?.name
            )
        );

        return filteredArray;
    };

    // const navigate = useNavigate();

    // Get dropdows data
    // useEffect(() => {

    //     // ↓ deal das dropdown values
    //     (async () => {
    //         await handleGetDealDas('', 1);
    //     })();

    // }, []);

    return (
        <>
                
            <div className="flex justify-start pl-2 text-gradient w-[110%] pb-2 pt-4 mb-6 border-b border-gray-200">
                        Groupement de réponse
            </div>
                    
            <div className="pb-3 border-b border-gray-200 w-full">
                <div className='w-full flex justify-start'>
                    <Controller
                        name="response_groupement"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le rôle'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label={responseGroupLabel}
                                placeholder='Rôle'
                                inputRef={ref}
                                width='w-[500px]'
                                error={error}
                                value={value}
                                options={taRoles}
                                onChange={onChange}
                                labelKeys={['name']}
                                disabled={disabled}
                                labelWidth="w-[245px]"
                            />
                        )}
                    />
                </div>
                {taRoleValue?.name && taRoleValue.name !== "Seul" ?  <div className='w-full mt-2'>
                    {
                        partnerValues.map((partner, index) => {
                            return (
                                <div
                                    className='w-full flex'
                                    key={partner.id}
                                >
                                    <div className='w-full flex mb-1.5'>
                                        <div className='flex justify-start'>
                                            {
                                                index === 0 ?
                                                    <div className='flex flex-col justify-center mr-[14.5px]'>
                                                        <Tooltip title='Créer un partenaire'>
                                                            <button
                                                                onClick={() => setNewPartnerView(true)}
                                                                disabled={!(partnerValues.length < 10)}
                                                                className={`
                                                                                    p-1 rounded-full ${partnerValues.length < 10 ? 'hover:opacity-90' : ''}
                                                                                    ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}
                                                                                    ${!(partnerValues.length < 10) ? 'opacity-[40%]' : 'opacity-100'}
                                                                                `}
                                                            >
                                                                <img src={plus} alt='plus' className='w-6'/>
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                    : <div className="w-6 ml-[17px]"></div>
                                            }
                                            <SelectInput
                                                placeholder='Partenaire'
                                                value={partner.ressource}
                                                options={filterOutAlreadySelected(contractors?.options, partnerValues)}
                                                onChange={(e) => handlePartnerValueChange(e, partner.id, 'ressource')}
                                                labelKeys={['name']}
                                                disabled={disabled}
                                                fetchFunction={handleGetContractors}
                                                hasNextPage={contractors?.hasNextPage}
                                                width = 'w-[200px]'
                                                isSearchable={true}
                                                loading={!ressources}
                                                labelWidth='w-40'
                                            />
                                            <div className='w-full ml-3'>
                                                <SelectInput
                                                    placeholder='Rôle'
                                                    // inputRef={ref}
                                                    width='w-[500px]'
                                                    // error={error}
                                                    value={partner.role}
                                                    options={partnerRoles}
                                                    onChange={(e) => handlePartnerValueChange(e, partner.id, 'role')}
                                                    labelKeys={partnerRoles.length ? ["name"] : null}
                                                    required={true}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className='flex flex-col justify-center'>
                                                {
                                                    index === 0 ?
                                                        <Tooltip title='Assigner un partenaire existant'>
                                                            <div
                                                                onClick={handleAddPartner}
                                                                className={`whitespace-nowrap cursor-pointer text-sm text-gradient ${partnerValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"} ml-1`}
                                                            >
                                                                <p className='hover:underline'>Ajouter un partenaire</p>
                                                            </div>
                                                        </Tooltip>
                                                        : <button
                                                            onClick={() => handleRemovePartner(partner.id)}
                                                            disabled={disabled}
                                                            className=" bg-transparent"
                                                        >
                                                            <Tooltip title='Supprimer'>
                                                                <div>
                                                                    <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-6"/>
                                                                </div>
                                                            </Tooltip>
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div> : null}
            </div>
        </>
    );
}

export default ResponseGroupModule;
