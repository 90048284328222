import {ProductionDao} from '../../dao';
import {
    ProductionModel,
    ErrorModel
} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_PRODUCTION';

export async function updateProduction(
    userData: Record<string, string>,
    productionId: string,
    update: Record<string, any>
) {
    try {
        const parsedUpdate = ProductionModel.parseProduction(update, true);

        const result = await ProductionDao.updateProduction(userData.userToken, productionId, parsedUpdate);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
