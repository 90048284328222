import {ACTION_CODE} from './_actionCode';
import {ActivityTypesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'DELETE_ACTIVITY_TYPES';

export async function deleteActivityTypes(userData: Record<string, string>, activityTypeId: number | string) {
    try {
        LoggerService.logInfo('Action - deleteActivityTypes - Start');

        const result = await ActivityTypesService.deleteActivityTypes(userData, activityTypeId);

        LoggerService.logInfo('Action - deleteActivityTypes - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteActivityTypes - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression de l\'activité',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
