import React from 'react';

import Popup from '../../../../components/ui/Popup';
import CreateNewInvoiceItem from './CreateNewInvoiceItem';

const CreateNewInvoiceItemPopup = ({
    userData,
    view,
    setView,
    invoiceData,
    invoiceItemToBeUpdated,
    setInvoiceItemToBeUpdated,
    refreshData
}) => {
    return (
        <Popup
            view={view}
            setView={setView}
            width={'1098px'}
        >
            <CreateNewInvoiceItem
                userData={userData}
                invoiceData={invoiceData}
                invoiceItemToBeUpdated={invoiceItemToBeUpdated}
                setInvoiceItemToBeUpdated={setInvoiceItemToBeUpdated}
                setView={setView}
                refreshData={refreshData}
            />
        </Popup>
    )
}

export default CreateNewInvoiceItemPopup
