import {ACTION_CODE} from './_actionCode';
import {CustomerCategoryService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_CUSTOMER_CATEGORY';

export async function deleteCustomerCategoryById(userData: Record<string, any>, customerCategoryId: number) {
    try {
        LoggerService.logInfo('Action - deleteCustomerCategoryById - Start');

        const deleteCustomerCategoryByIdResult = await CustomerCategoryService.deleteCustomerCategory(userData, customerCategoryId);

        LoggerService.logInfo('Action - deleteCustomerCategoryById - End - Success');

        return ResultModel.newSuccessResult(deleteCustomerCategoryByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteCustomerCategoryById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une catégorie client',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
