import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import {DealActions} from '../../actions';
import {pageTableDealsConfig} from "./configs/pageTables/pageTableDealsConfig";
import Table from "../../components/ui/Table";
import {notify} from '../../components/ui/Toast/Toast';
import {standardFormat} from '../../utils';

const ProposalsMadePage = ({
    userData,
    handleMissionNavigation,
    dasKey,
    dates
}) => {
    const [data, setData] = useState([]);
    const [paginationMetadata, setPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState("");

    const handleGetData = async (page) => {
        if(!dasKey) return;
        
        setLoading(true);
        
        const dealStatus = "status=300&status=400&status=500&status=600&status=700&status=900"

        const intervalDates = `response_after=${standardFormat(dates.startDate)}&response_before=${standardFormat(dates.endDate)}`

        const result = await DealActions.getAllDeals(
            userData,
            `&type=business&page_size=${paginationMetadata.pageSize}&das=${dasKey}&${dealStatus}&${intervalDates}${filter}`,
            page
        );
    
        if (result.success) {
            setData(result.data);
            setPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (paginationMetadata.totalItems !== result.metadata.count) {
                setPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données de la table «Propositions faites»");
        }

        setLoading(false);
    };

    const filterHandler = (query) => {
        setFilter(query ? `&${query}` : '');
    }

    useEffect(() => {
        handleGetData(1);
    }, [filter, dasKey]);

    const tableConfig = pageTableDealsConfig(
        filterHandler,
        handleMissionNavigation,
        "proposalsMade",
        userData,
        dasKey
    );

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/das'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Propositions faites (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                onPageNumberChange={handleGetData}
                paginationMetadata={paginationMetadata}
                loading={loading}
            />
        </>
    )
}

export default ProposalsMadePage;
