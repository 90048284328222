
export const aggregateCountPerDas = (salesOverview: Record<string, any>, key: string) => {

    return salesOverview[key].delivered.Cotraitance.count +
        salesOverview[key].delivered.Mandataire.count +
        salesOverview[key].delivered.Seul.count +
        salesOverview[key].lost.Cotraitance.count +
        salesOverview[key].lost.Mandataire.count +
        salesOverview[key].lost.Seul.count +
        salesOverview[key].studied.Cotraitance.count +
        salesOverview[key].studied.Mandataire.count +
        salesOverview[key].studied.Seul.count +
        salesOverview[key].won.Cotraitance.count +
        salesOverview[key].won.Mandataire.count +
        salesOverview[key].won.Seul.count;
}

export const aggregateCountTotal = (salesOverview: Record<string, any>, das?: string) => {
    let total = 0;

    if (das) {
        total = total + aggregateCountPerDas(salesOverview, das);
    }
    else {
        for (const key in salesOverview) {
            total = total + aggregateCountPerDas(salesOverview, key);
        }
    }

    return total;
}

const calculateSuccessData = (value1: any, value2: any) => {
    if (value1 === 0 || value2 === 0) {
        return 0;
    }
    else {
        return +((value1 / value2 * 100).toFixed(1));
    }
}

export const aggregateSalesOverview = (salesOverview: any, key: string) => {
    const DoneSeulCount = salesOverview[key].delivered.Seul.count + salesOverview[key].lost.Seul.count + salesOverview[key].won.Seul.count;

    const DoneMandataireCount = salesOverview[key].delivered.Mandataire.count + salesOverview[key].lost.Mandataire.count + salesOverview[key].won.Mandataire.count;

    const DoneCotraitanceCount = salesOverview[key].delivered.Cotraitance.count + salesOverview[key].lost.Cotraitance.count + salesOverview[key].won.Cotraitance.count;

    const DoneSeulDays = salesOverview[key].delivered.Seul.days + salesOverview[key].lost.Seul.days + salesOverview[key].won.Seul.days;

    const DoneMandataireDays = salesOverview[key].delivered.Mandataire.days + salesOverview[key].lost.Mandataire.days + salesOverview[key].won.Mandataire.days;

    const DoneCotraitanceDays = salesOverview[key].delivered.Cotraitance.days + salesOverview[key].lost.Cotraitance.days + salesOverview[key].won.Cotraitance.days;

    const DoneSeulValue = salesOverview[key].delivered.Seul.value + salesOverview[key].lost.Seul.value + salesOverview[key].won.Seul.value;

    const DoneMandataireValue = salesOverview[key].delivered.Mandataire.value + salesOverview[key].lost.Mandataire.value + salesOverview[key].won.Mandataire.value;

    const DoneCotraitanceValue = salesOverview[key].delivered.Cotraitance.value + salesOverview[key].lost.Cotraitance.value + salesOverview[key].won.Cotraitance.value;

    const SuccessRateSeulCount = calculateSuccessData(salesOverview[key].won.Seul.count, DoneSeulCount);

    const SuccessRateMandataireCount = calculateSuccessData(salesOverview[key].won.Mandataire.count, DoneMandataireCount);

    const SuccessRateCotraitanceCount = calculateSuccessData(salesOverview[key].won.Cotraitance.count, DoneCotraitanceCount);

    const SuccessRateSeulDays = calculateSuccessData(salesOverview[key].won.Seul.days, DoneSeulDays);

    const SuccessRateMandataireDays = calculateSuccessData(salesOverview[key].won.Mandataire.days, DoneMandataireDays);

    const SuccessRateCotraitanceDays = calculateSuccessData(salesOverview[key].won.Cotraitance.days, DoneCotraitanceDays);

    const SuccessRateSeulValue = calculateSuccessData(salesOverview[key].won.Seul.value, DoneSeulValue);

    const SuccessRateMandataireValue = calculateSuccessData(salesOverview[key].won.Mandataire.value, DoneMandataireValue);

    const SuccessRateCotraitanceValue = calculateSuccessData(salesOverview[key].won.Cotraitance.value, DoneCotraitanceValue);

    return {
        DoneSeulCount: DoneSeulCount,
        DoneMandataireCount: DoneMandataireCount,
        DoneCotraitanceCount: DoneCotraitanceCount,
        DoneSeulDays: DoneSeulDays,
        DoneMandataireDays: DoneMandataireDays,
        DoneCotraitanceDays: DoneCotraitanceDays,
        DoneSeulValue: DoneSeulValue,
        DoneMandataireValue: DoneMandataireValue,
        DoneCotraitanceValue: DoneCotraitanceValue,
        SuccessRateSeulCount: SuccessRateSeulCount,
        SuccessRateMandataireCount: SuccessRateMandataireCount,
        SuccessRateCotraitanceCount: SuccessRateCotraitanceCount,
        SuccessRateSeulDays: SuccessRateSeulDays,
        SuccessRateMandataireDays: SuccessRateMandataireDays,
        SuccessRateCotraitanceDays: SuccessRateCotraitanceDays,
        SuccessRateSeulValue: SuccessRateSeulValue,
        SuccessRateMandataireValue: SuccessRateMandataireValue,
        SuccessRateCotraitanceValue: SuccessRateCotraitanceValue
    }
}

export const aggregateSalesOverviewTotal = (salesOverview: any) => {
    const total = {
        DoneSeulCount: 0,
        DoneMandataireCount: 0,
        DoneCotraitanceCount: 0,

        DoneSeulDays: 0,
        DoneMandataireDays: 0,
        DoneCotraitanceDays: 0,

        DoneSeulValue: 0,
        DoneMandataireValue: 0,
        DoneCotraitanceValue: 0,

        WonSeulCount: 0,
        WonMandataireCount: 0,
        WonCotraitanceCount: 0,

        WonSeulDays: 0,
        WonMandataireDays: 0,
        WonCotraitanceDays: 0,

        WonSeulValue: 0,
        WonMandataireValue: 0,
        WonCotraitanceValue: 0,

        LostSeulCount: 0,
        LostMandataireCount: 0,
        LostCotraitanceCount: 0,

        LostSeulDays: 0,
        LostMandataireDays: 0,
        LostCotraitanceDays: 0,

        LostSeulValue: 0,
        LostMandataireValue: 0,
        LostCotraitanceValue: 0,

        SuccessRateSeulCount: 0,
        SuccessRateMandataireCount: 0,
        SuccessRateCotraitanceCount: 0,

        SuccessRateSeulDays: 0,
        SuccessRateMandataireDays: 0,
        SuccessRateCotraitanceDays: 0,

        SuccessRateSeulValue: 0,
        SuccessRateMandataireValue: 0,
        SuccessRateCotraitanceValue: 0
        
    }
    for (const key in salesOverview) {
        total.DoneSeulCount = total.DoneSeulCount + salesOverview[key].delivered.Seul.count + salesOverview[key].lost.Seul.count + salesOverview[key].won.Seul.count;
        total.DoneMandataireCount = total.DoneMandataireCount + salesOverview[key].delivered.Mandataire.count + salesOverview[key].lost.Mandataire.count + salesOverview[key].won.Mandataire.count;
        total.DoneCotraitanceCount = total.DoneCotraitanceCount + salesOverview[key].delivered.Cotraitance.count + salesOverview[key].lost.Cotraitance.count + salesOverview[key].won.Cotraitance.count;

        total.DoneSeulDays = total.DoneSeulDays + salesOverview[key].delivered.Seul.days + salesOverview[key].lost.Seul.days + salesOverview[key].won.Seul.days;
        total.DoneMandataireDays = total.DoneMandataireDays + salesOverview[key].delivered.Mandataire.days + salesOverview[key].lost.Mandataire.days + salesOverview[key].won.Mandataire.days;
        total.DoneCotraitanceDays = total.DoneCotraitanceDays + salesOverview[key].delivered.Cotraitance.days + salesOverview[key].lost.Cotraitance.days + salesOverview[key].won.Cotraitance.days;

        total.DoneSeulValue = total.DoneSeulValue + salesOverview[key].delivered.Seul.value + salesOverview[key].lost.Seul.value + salesOverview[key].won.Seul.value;
        total.DoneMandataireValue = total.DoneMandataireValue + salesOverview[key].delivered.Mandataire.value + salesOverview[key].lost.Mandataire.value + salesOverview[key].won.Mandataire.value;
        total.DoneCotraitanceValue = total.DoneCotraitanceValue + salesOverview[key].delivered.Cotraitance.value + salesOverview[key].lost.Cotraitance.value + salesOverview[key].won.Cotraitance.value;

        total.WonSeulCount = total.WonSeulCount + salesOverview[key].won.Seul.count;
        total.WonMandataireCount = total.WonMandataireCount + salesOverview[key].won.Mandataire.count;
        total.WonCotraitanceCount = total.WonCotraitanceCount + salesOverview[key].won.Cotraitance.count;

        total.WonSeulDays = total.WonSeulDays + salesOverview[key].won.Seul.days;
        total.WonMandataireDays = total.WonMandataireDays + salesOverview[key].won.Mandataire.days;
        total.WonCotraitanceDays = total.WonCotraitanceDays + salesOverview[key].won.Cotraitance.days;

        total.WonSeulValue = total.WonSeulValue + salesOverview[key].won.Seul.value;
        total.WonMandataireValue = total.WonMandataireValue + salesOverview[key].won.Mandataire.value;
        total.WonCotraitanceValue = total.WonCotraitanceValue + salesOverview[key].won.Cotraitance.value;

        total.LostSeulCount = total.LostSeulCount + salesOverview[key].lost.Seul.count;
        total.LostMandataireCount = total.LostMandataireCount + salesOverview[key].lost.Mandataire.count;
        total.LostCotraitanceCount = total.LostCotraitanceCount + salesOverview[key].lost.Cotraitance.count;

        total.LostSeulDays = total.LostSeulDays + salesOverview[key].lost.Seul.days;
        total.LostMandataireDays = total.LostMandataireDays + salesOverview[key].lost.Mandataire.days;
        total.LostCotraitanceDays = total.LostCotraitanceDays + salesOverview[key].lost.Cotraitance.days;

        total.LostSeulValue = total.LostSeulValue + salesOverview[key].lost.Seul.value;
        total.LostMandataireValue = total.LostMandataireValue + salesOverview[key].lost.Mandataire.value;
        total.LostCotraitanceValue = total.LostCotraitanceValue + salesOverview[key].lost.Cotraitance.value;

    }

    total.SuccessRateSeulCount = calculateSuccessData(total.WonSeulCount, total.DoneSeulCount);
    total.SuccessRateMandataireCount = calculateSuccessData(total.WonMandataireCount, total.DoneMandataireCount);
    total.SuccessRateCotraitanceCount = calculateSuccessData(total.WonCotraitanceCount, total.DoneCotraitanceCount);

    total.SuccessRateSeulDays = calculateSuccessData(total.WonSeulDays, total.DoneSeulDays);
    total.SuccessRateMandataireDays = calculateSuccessData(total.WonMandataireDays, total.DoneMandataireDays);
    total.SuccessRateCotraitanceDays = calculateSuccessData(total.WonCotraitanceDays, total.DoneCotraitanceDays);

    total.SuccessRateSeulValue = calculateSuccessData(total.WonSeulValue, total.DoneSeulValue);
    total.SuccessRateMandataireValue = calculateSuccessData(total.WonMandataireValue, total.DoneMandataireValue);
    total.SuccessRateCotraitanceValue = calculateSuccessData(total.WonCotraitanceValue, total.DoneCotraitanceValue);

    return total;
}
