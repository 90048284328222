export const standardFormat = (date) => {
    // eslint-disable-next-line max-len
    return `${new Date(date).toLocaleDateString('fr-FR', {year: 'numeric'})}-${new Date(date).toLocaleDateString('fr-FR', {month: '2-digit'})}-${new Date(date).toLocaleDateString('fr-FR', {day: '2-digit'})}`;
};

export const frenchFormat = (date) => {
    if (date) {

        return new Date(date).toLocaleString('fr-FR', {day: '2-digit', month: '2-digit', year: 'numeric'});
    }
    else {

        return null;
    }
};

export const frenchLongFormat = (date) => {
    return new Date(date).toLocaleString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'});
};

export const frenchMonthFormat = (date) => {
    return new Date(date).toLocaleString('fr-FR', {month: 'long', year: 'numeric'});
};

export const firstDayMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const lastDayMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const firstDayYear = (date) => {
    return new Date(date.getFullYear(), 0, 1);
};

export const lastDayYear = (date) => {
    return new Date(date.getFullYear(), 11, 31);
};

export const addDays = (date, days) => {
    const formattedDate = new Date(date);
    const futureDate = formattedDate.setDate(formattedDate.getDate() + days);

    return new Date(futureDate);
};

export const isValidDate = (dateString) => {
    if (typeof dateString !== 'string') return false;
    const regex = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");

    if(!dateString.match(regex)) return false;
    
    const date = new Date(dateString);

    return date.toISOString().slice(0, 10) === dateString;
}

// ↓ Obtenir le nombre de jour ouvrés dans l'année en cours
export const getWorkingDaysInYear = (year) => {
    const joursFeries = getJoursFeries(year);
    let workingDays = 0;

    for (let month = 0; month < 12; month++) {
        for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
            let currentDate = new Date(year, month, day);
            let dayOfWeek = currentDate.getDay();
            let isWeekend = (dayOfWeek === 0 || dayOfWeek === 6);
            let isHoliday = joursFeries.some(d => d.getTime() === currentDate.getTime());

            if (!isWeekend && !isHoliday) {
                workingDays++;
            }
        }
    }

    return workingDays;
}

function getJoursFeries(year) {
    let fixedHolidays = [
        new Date(year, 0, 1),  // 1er janvier
        new Date(year, 4, 1),  // Fête du Travail
        new Date(year, 4, 8),  // Victoire 1945
        new Date(year, 6, 14), // Fête Nationale
        new Date(year, 7, 15), // Assomption
        new Date(year, 10, 1), // Toussaint
        new Date(year, 10, 11), // Armistice
        new Date(year, 11, 25) // Noël
    ];

    let easterDate = getEasterDate(year);
    let easterMonday = new Date(easterDate.getFullYear(), easterDate.getMonth(), easterDate.getDate() + 1);
    let ascensionDay = new Date(easterDate.getFullYear(), easterDate.getMonth(), easterDate.getDate() + 39);
    let pentecostMonday = new Date(easterDate.getFullYear(), easterDate.getMonth(), easterDate.getDate() + 50);

    return fixedHolidays.concat([easterMonday, ascensionDay, pentecostMonday]);
}

function getEasterDate(year) {
    let f = Math.floor,
        // Golden Number - 1
        G = year % 19,
        C = f(year / 100),
        // related to Epact
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        // number of days from 21 March to the Paschal full moon
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        // weekday for the Paschal full moon
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        // number of days from 21 March to the Sunday on or before the Paschal full moon
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4);

    return new Date(year, month - 1, day);
}
// ----------------------------------------
