import {ACTION_CODE} from './_actionCode';
import {BankService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_BANK';

export async function deleteBankById(userData: Record<string, any>, bankId: number) {
    try {
        LoggerService.logInfo('Action - deleteBankById - Start');

        const deleteBankByIdResult = await BankService.deleteBank(userData, bankId);

        LoggerService.logInfo('Action - deleteBankById - End - Success');

        return ResultModel.newSuccessResult(deleteBankByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteBankById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
