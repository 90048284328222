import {useLayoutEffect, useState} from 'react';
import CreateNewExpense from './CreateNewExpense';
import CreateNewKilometricExpense from './CreateNewKilometricExpense';
import './CreateNewExpensePopup';
import Popup from '../../../../../components/ui/Popup';

function CreateNewExpensePopup({
    dealData,
    view,
    setView,
    fromDeal,
    expenseToBeUpdated,
    userData,
    refreshExpensesData,
    setExpenseToBeUpdated,
    blueStyle
}) {
    const [switchForm, setSwitchForm] = useState('Frais');

    // Check if the expense to be updated is a kilometric expense and set the switchForm state accordingly
    // reset the switchForm state when the popup is closed
    // Use of useLayoutEffect to preserve blinking effect with the kilometric expense form
    useLayoutEffect(() => {
        if (expenseToBeUpdated?.type?.id === 1) {
            setSwitchForm(expenseToBeUpdated?.type);
        }

        if (!view) {
            setSwitchForm(null);
            setExpenseToBeUpdated(null);
        }
    }, [expenseToBeUpdated, view]);

    return (
        <Popup
            view={view}
            setView={setView}
            width={'1098px'}
            xOverflow=''
            yOverflow=''
        >
            {
                switchForm?.name === 'Indemnités kilométriques' ?
                    <CreateNewKilometricExpense
                        dealData={dealData}
                        fromDeal={fromDeal}
                        setView={setView}
                        userData={userData}
                        switchForm={switchForm}
                        setSwitchForm={setSwitchForm}
                        expenseToBeUpdated={expenseToBeUpdated}
                        refreshData={refreshExpensesData}
                        blueStyle={blueStyle}
                    /> :
                    <CreateNewExpense
                        dealData={dealData}
                        fromDeal={fromDeal}
                        setView={setView}
                        userData={userData}
                        switchForm={switchForm}
                        setSwitchForm={setSwitchForm}
                        expenseToBeUpdated={expenseToBeUpdated}
                        refreshData={refreshExpensesData}
                        blueStyle={blueStyle}
                    />
            }
        </Popup>
    );
}

export default CreateNewExpensePopup;
