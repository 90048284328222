import {useState} from "react";

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Datepicker from "../../../../components/ui/Datepicker";
import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import TabNavigator from "../../../../components/ui/navigation/TabNavigator";
import DasLoadTabChildren from "./DasLoadTabChildren";
import DasLoadTeamTabChildren from "./DasLoadTeamTabChildren";
import InvoiceCards from "./InvoiceCards";
import TjmTabChildren from "./TjmTabChildren";

const ChargeIndicator = ({
    data,
    dasUsers,
    handleDatePickerChange,
    datePicker,
    dasKey
}) => {
    const [displayedSection, setDisplayedSection] = useState('Production DAS');

    const sections = [
        {name: "Production DAS"},
        {name: "Production équipe DAS"}
    ];

    return (
        <div className='mt-14'>
            <LineTitle title="Indicateurs de charges" textSize='text-4xl'/>
            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePicker}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>
            <div className="mt-6">
                <ContainerCard showHeader={false} width="min-h-[505px]">
                    <div className="mt-2">
                        <TabNavigator
                            setDisplayedSection={setDisplayedSection}
                            displayedSection={displayedSection}
                            sections={sections}
                        />
                    </div>
                    {
                        displayedSection === 'Production DAS' && (
                            <DasLoadTabChildren data={data} dasKey={dasKey} dasUsers={dasUsers}/>
                        )
                    }
                    {
                        displayedSection === 'Production équipe DAS' && (
                            <DasLoadTeamTabChildren data={data} dasKey={dasKey} dasUsers={dasUsers}/>
                        )
                    }
                </ContainerCard>
                <InvoiceCards data={data} dasKey={dasKey}/>
                <div className="mt-6">
                    <ContainerCard
                        showHeader={false}
                        coloredButtonDisplayed={false}
                    >
                        <TjmTabChildren data={data} dasKey={dasKey}/>
                    </ContainerCard>
                </div>
            </div>
        </div>
    )
}

export default ChargeIndicator;
