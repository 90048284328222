import {ACTION_CODE} from './_actionCode';
import {MissionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_MISSIONS';

export async function getAllMissions(
    userData: Record<string, string>,
    params: string,
    page = 1
) {
    try {
        
        LoggerService.logInfo('Action - getAllMissions - Start');

        const getAllMissionsResult = await MissionService.getAllMissions(userData, params, page);

        const {data, metadata} = getAllMissionsResult;

        LoggerService.logInfo('Action - getAllMissions - Success');

        return ResultModel.newSuccessResult(data, metadata);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllMissions - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des missions de l\'affaire',
            error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
