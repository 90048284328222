import ConsultantContributionsCards from "../components/managementParameters/ConsultantContributionsCards";

export const consultantContributionsTabConfig = (data) => {

    const getSum = (key1, key2) => {
        let sum = 0;

        for (const k in data) {
            sum += data[k][key1][key2];
        }

        return sum;
    }

    const getSumObjectData = () => {
        return {
            "junior": {
                "gross_margin_contribution": getSum('junior', 'gross_margin_contribution'),
                "sales": getSum('junior', 'sales')
            },
            "confirmed": {
                "gross_margin_contribution": getSum('confirmed', 'gross_margin_contribution'),
                "sales": getSum('confirmed', 'sales')
            },
            "senior": {
                "gross_margin_contribution": getSum('senior', 'gross_margin_contribution'),
                "sales": getSum('senior', 'sales')
            }
        }
    }

    // TABS
    let tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <ConsultantContributionsCards data={getSumObjectData()}/>
    }];

    for (const key in data) {
        const item = {
            id: key,
            label: key,
            component: <ConsultantContributionsCards data={data[key]}/>
        }
        
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
