import React from 'react';
import Empty from 'antd/lib/empty';

import ContainerCard from '../../../components/ui/ContainerCard';

const NotesCard = ({data}) => {
    return (
        <ContainerCard
            title='Notes'
            coloredButtonDisplayed={false}
            width='w-1/3'
            height='h-[300px]'
        >
            {
                data?.length > 0 ?
                    <div className='text-[#212121] text-base'>{data}</div> :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            }
        </ContainerCard>
    )
}

export default NotesCard
