import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_ALL_CONTRACTS_STAFFING';

export async function getAllContractsStaffing(
    token: string,
    params: string,
    page: number
) {
    try {
        const response = await axios.get(
            `${URL}/contracts/staffing/?page=${page}${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${token}`
                }
            }
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation des contrats staffing',
            error: error
        });
    }
}
