import React from 'react';
import arrow_down from "../../../assets/icons/dropdown/arrow_down_white.svg"
import arrow_up from "../../../assets/icons/dropdown/arrow_up_white.svg"
const SideBar = ({
    displayedMenuSection,
    setDisplayedMenuSection,
    displayedSection,
    setDisplayedSection
}) => {

    return (
        <div className="sticky top-0 flex flex-col pt-[92px] h-screen overflow-visible text-white w-80">
            <div className='bg-[#212121] h-full' style={{boxShadow: '4px 0px 8px 0px rgba(0, 0, 0, 0.25)'}}>
                <ul className="pt-4 pb-4 overflow-auto">
                    {/* CLIENTS */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Clients");
                            setDisplayedSection("Types")
                        }}
                        className={`
                            hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                            ${displayedMenuSection === "Clients" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                        `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Clients</span>
                            <img className="w-2" src={displayedMenuSection === "Clients" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Clients" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                        mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                        ${displayedSection === "Types" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                    `}
                                    onClick={() => setDisplayedSection("Types")}
                                >
                                    Types
                                </li>
                                <li
                                    className={`
                                        hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                        ${displayedSection === "Secteurs géographiques" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                    `}
                                    onClick={() => setDisplayedSection("Secteurs géographiques")}
                                >
                                    Secteurs géographiques
                                </li>
                            </ul>
                            : null
                    }

                    {/* AFFAIRES */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Affaires");
                            setDisplayedSection("Origines")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Affaires" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                    `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Affaires</span>
                            <img className="w-2" src={displayedMenuSection === "Affaires" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Affaires" ?
                            <ul
                                to="/"
                                className="px-10"
                            >
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Origines" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Origines")}
                                >
                                Origines
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Motifs de perte" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Motifs de perte")}
                                >
                                Motifs de perte
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Motifs d'abandon" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Motifs d'abandon")}
                                >
                                    {"Motifs d'abandon"}
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Types des documents" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Types des documents")}
                                >
                                Types des documents
                                </li>
                            </ul>
                            : null
                    }

                    {/* DAS */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("DAS");
                            setDisplayedSection("DAS")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "DAS" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                    `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">DAS</span>
                            <img className="w-2" src={displayedMenuSection === "DAS" ? arrow_up : arrow_down}/>
                        </div>
                    </li>

                    {/* FILIALE */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Filliale");
                            setDisplayedSection("Filliale")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Filliale" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                     `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Filiale</span>
                            <img className="w-2" src={displayedMenuSection === "Filliale" ? arrow_up : arrow_down}/>
                        </div>
                    </li>

                    {/* PRODUCTION */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Production");
                            setDisplayedSection("Types de production")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Production" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                     `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Production</span>
                            <img className="w-2" src={displayedMenuSection === "Production" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Production" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Types de production" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Types de production")}
                                >
                                Types de production
                                </li>
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Types d'activité" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Types d'activité")}
                                >
                                Types d&apos;activité
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Activités" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Activités")}
                                >
                                Activités
                                </li>
                            </ul>
                            : null
                    }

                    {/* FRAIS */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Frais");
                            setDisplayedSection("Types")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Frais" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                     `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Frais</span>
                            <img className="w-2" src={displayedMenuSection === "Frais" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Frais" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Types" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Types")}
                                >
                                Types
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Barèmes" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Barèmes")}
                                >
                                Barèmes
                                </li>
                            </ul>
                            : null
                    }

                    {/* FACTURES */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Factures");
                            setDisplayedSection("Banques")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Factures" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                     `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Factures</span>
                            <img className="w-2" src={displayedMenuSection === "Factures" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Factures" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Banques" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Banques")}
                                >
                                Banques
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Modes encaissement" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Modes encaissement")}
                                >
                                Modes encaissement
                                </li>
                            </ul>
                            : null
                    }

                    {/* SÉNIORITÉ */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Séniorité");
                            setDisplayedSection("Séniorité")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Séniorité" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                    `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Séniorité</span>
                            <img className="w-2" src={displayedMenuSection === "Séniorité" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                
                    {/* RÉFÉRENCES */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Références");
                            setDisplayedSection("Tags")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Références" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                    `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Références</span>
                            <img className="w-2" src={displayedMenuSection === "Références" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Références" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Tags" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Tags")}
                                >
                                Tags
                                </li>
                            </ul>
                            : null
                    }

                    {/* SOUS/CO-TRAITANTS */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Sous/Co-traitants");
                            setDisplayedSection("Catégories")
                        }}
                        className={`
                            hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                            ${displayedMenuSection === "Sous/Co-traitants" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                        `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Sous/Co-traitants</span>
                            <img className="w-2" src={displayedMenuSection === "Sous/Co-traitants" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Sous/Co-traitants" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                        hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                        ${displayedSection === "Catégories" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                    `}
                                    onClick={() => setDisplayedSection("Catégories")}
                                >
                                    Catégories
                                </li>
                            </ul>
                            : null
                    }

                    {/* FORMATIONS */}
                    <li
                        onClick={() => {
                            setDisplayedMenuSection("Formations");
                            setDisplayedSection("Nature de la formation")
                        }}
                        className={`
                        hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] transition-opacity hover:cursor-pointer px-5 py-3 space-x-3
                        ${displayedMenuSection === "Formations" ? "bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                    `}
                    >
                        <div className="flex transition-transform">
                            <span className="mr-2 text-m">Formations</span>
                            <img className="w-2" src={displayedMenuSection === "Formations" ? arrow_up : arrow_down}/>
                        </div>
                    </li>
                    {
                        displayedMenuSection === "Formations" ?
                            <ul to="/" className="px-10">
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Nature de la formation" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Nature de la formation")}
                                >
                                Nature de la formation
                                </li>
                                <li
                                // eslint-disable-next-line max-len
                                    className={`mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5 ${displayedSection === "Objectifs générales de formation" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}`}
                                    onClick={() => setDisplayedSection("Objectifs générales de formation")}
                                >
                                Objectifs générales de formation
                                </li>
                                <li
                                    className={`
                                    mb hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Type de stagiaires" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Type de stagiaires")}
                                >
                                Type de stagiaires
                                </li>
                                <li
                                    className={`
                                    hover:text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] hover:cursor-pointer leading-5 py-1.5
                                    ${displayedSection === "Spécialité de formation" ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]" : null}
                                `}
                                    onClick={() => setDisplayedSection("Spécialité de formation")}
                                >
                                Spécialité de formation
                                </li>
                            </ul>
                            : null
                    }
                </ul>
            </div>
        </div>
    );
};

export default SideBar;
