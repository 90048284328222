import * as z from 'zod';
import * as userFunctions from './functions';

export const UserSchema = z.object({
    id: z.number(),
    username: z.string(),
    email: z.string().optional(),
    first_name: z.string(),
    last_name: z.string(),
    user_class: z.string(),
    last_login: z.string().nullable().optional()
});

export const UserPartialSchema = UserSchema.partial();
export type UserPartial = z.infer<typeof UserPartialSchema>;
export type User = z.infer<typeof UserSchema>;

export function newUser(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...UserSchema.parse({...data}),
        ...userFunctions
    };
}

export function parseUser(data: any, partial = false) {
    if (!partial) {
        return {
            ...UserSchema.parse(data),
            ...userFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...UserPartialSchema.parse(data),
            ...userFunctions
        };
    }
}
