import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Table from '../../../../../components/ui/Table/Table';
import Button  from '../../../../../components/ui/Button/Button';
import Popup from '../../../../../components/ui/Popup/Popup';
import CreateNewProject from './CreateNewOperation';
import {ServicesActions} from '../../../../../actions';
import {operationsConfig} from '../../../configs/operationsConfigs/operationsConfig';
import {notify} from '../../../../../components/ui/Toast/Toast';

function OperationSection({
    data,
    loadingTable,
    fetchAllOperations,
    refreshOperationsData,
    operationsPaginationMetadata
}) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [toBeUpdated, setToBeUpdated] = useState(null);
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDeleteService = async (id) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(id);

        const result = await ServicesActions.deleteService(userData.userToken, id);

        if (result.success) {
            await refreshOperationsData(true);
            notify(`L'opération a bien été supprimée`);
        }
        else {
            notify(`L'opération n'a pas pu être supprimée`);
        }

        setActionButtonDisabled(false);
        setActionButtonLoading(null);
    };

    const tableConfig =  operationsConfig(
        handleDeleteService,
        setIsPopupOpen,
        setToBeUpdated,
        actionButtonLoading,
        actionButtonDisabled,
    );

    return (
        <div className="w-full px-20 pt-4">
            <div className="flex justify-end py-4">
                <Button
                    content={`Ajouter un Projet`}
                    onClick={() => {
                        setToBeUpdated(null);
                        setIsPopupOpen(true)
                    }}
                />
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                loading={loadingTable}
                paginationMetadata={operationsPaginationMetadata}
                onPageNumberChange={fetchAllOperations}
            />
            <Popup
                view={isPopupOpen}
                setView={setIsPopupOpen}
                width={'1150px'}
                yOverflow=''
                xOverflow=''
            >
                <CreateNewProject
                    userData={userData}
                    toBeUpdated={toBeUpdated}
                    refreshData={refreshOperationsData}
                    setView={setIsPopupOpen}
                />
            </Popup>
        </div>
    )
}

export default OperationSection;
