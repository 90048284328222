import * as z from 'zod';
import * as expenseAttachmentFunctions from './functions';

export const ExpenseAttachmentSchema = z.object({
    id: z.number(),
    name: z.string(),
    size: z.number(),
    report: z.number(),
    url: z.string()
});

export const ExpenseAttachmentPartialSchema = ExpenseAttachmentSchema.partial();
export type ExpenseAttachmentPartial = z.infer<typeof ExpenseAttachmentPartialSchema>;
export type ExpenseAttachment = z.infer<typeof ExpenseAttachmentSchema>;

export function newExpenseAttachment(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...ExpenseAttachmentSchema.parse({...data}),
        ...expenseAttachmentFunctions
    };
}

export function parseExpenseAttachment(data: any, partial = false) {
    if (!partial) {
        return {
            ...ExpenseAttachmentSchema.parse(data),
            ...expenseAttachmentFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...ExpenseAttachmentPartialSchema.parse(data),
            ...expenseAttachmentFunctions
        };
    }
}
