export const aggregateBudgetObject = (data) => {

    const getGrossMargin = (value1, value2) => {
        const parsingValue1 = parseInt(value1);
        const parsingValue2 = parseInt(value2);

        if (!parsingValue1 || !parsingValue2) {
            return 0;
        }
        else {
            return (parsingValue1 - parsingValue2).toFixed(2);
        }
    }

    return [
        {
            id: 1,
            name: "Honoraires cabinet",
            facturable: parseInt(data.budget),
            jours_h: parseInt(data.billable_days),
            // eslint-disable-next-line max-len
            eur_jour: isNaN((parseInt(data.budget) / parseInt(data.billable_days))) || (parseInt(data.budget) / parseInt(data.billable_days)) === Infinity ? 0 : (parseInt(data.budget) / parseInt(data.billable_days)).toFixed(2),
            frais_forfaitaires: null,
            marge_brute: getGrossMargin(data.budget, data.expense_budget)
        },
        {
            id: 2,
            name: "Honoraires sous-traitance",
            facturable: parseInt(data.contractors.reduce((acc, curr) => acc + parseInt(curr.budget), 0)).toFixed(0),
            jours_h: parseInt(data.contractors.reduce((acc, curr) => acc + parseInt(curr.billable_days), 0)).toFixed(0),
            // eslint-disable-next-line max-len
            eur_jour: isNaN((parseInt(data?.contractors?.reduce((acc, curr) => acc + parseInt(curr.budget), 0)) / parseInt(data?.contractors?.reduce((acc, curr) => acc + parseInt(curr.billable_days), 0)))) || (parseInt(data?.contractors?.reduce((acc, curr) => acc + parseInt(curr.budget), 0)) / parseInt(data?.contractors?.reduce((acc, curr) => acc + parseInt(curr.billable_days), 0))) === Infinity ? 0 : (parseInt(data?.contractors?.reduce((acc, curr) => acc + parseInt(curr.budget), 0)) / parseInt(data?.contractors?.reduce((acc, curr) => acc + parseInt(curr.billable_days), 0))).toFixed(2),
            frais_forfaitaires: parseInt(data.expense_budget).toFixed(2),
            marge_brute: 0,
            count: data.contractors.length
        },
        {
            id: 3,
            name: "Commission",
            facturable: parseInt(data.commission),
            jours_h: 0.0000001,
            eur_jour: null,
            frais_forfaitaires: null,
            marge_brute: getGrossMargin(getGrossMargin(data.budget, data.expense_budget), 0)
        }
    ]
}

// La valeur «0.0000001» de la clé «jours_h» ligne 27 permet d'afficher «-» comme le client le souhaitait (voir fichier de config ligne 72),
// si la valeur était «null», toute les valeurs de la 3ème de la table ne serait pas retourné par react hook form