import {Link} from 'react-router-dom';
// import {useSelector} from 'react-redux';
import {ArrowLeftOutlined} from '@ant-design/icons';

import {pageTableDealsConfig} from "./configs/pageTables/pageTableDealsConfig";
import Table from "../../components/ui/Table";

const CurrentDeals = ({
    data,
    userData,
    tableType,
    filterDealList,
    handleMissionNavigation,
    currentDealsPaginationMetadata,
    handleGetCurrentDealsData,
    tableLoading,
    dasKey
}) => {
    const tableConfig = pageTableDealsConfig(filterDealList, handleMissionNavigation, tableType, userData, dasKey);

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/das'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Affaires en cours (${currentDealsPaginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>

            <Table
                data={data}
                tableConfig={tableConfig}
                onPageNumberChange={handleGetCurrentDealsData}
                paginationMetadata={currentDealsPaginationMetadata}
                loading={tableLoading}
            />
        </>
    )
}

export default CurrentDeals;
