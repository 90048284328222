import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import TextInput from '../../../components/ui/Inputs/TextInput';
import Button from '../../../components/ui/Button';
import DateInput from '../../../components/ui/Inputs/DateInput/DateInput';
import Error from '../../../components/ui/Error';
import SelectInput from '../../../components/ui/Inputs/SelectInput';
import Popup from '../../../components/ui/Popup';
import ContainerCard from '../../../components/ui/ContainerCard';
import { notify } from '../../../components/ui/Toast/Toast';
import { frenchFormat, standardFormat, getErrors, fetchSelectData } from '../../../utils';
import { InvoiceActions, PaymentMethodActions } from './../../../actions'

const InvoicePaiementPopup = ({view, setView, item, userData, refreshData}) => {
    const [typeForm, setTypeForm] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [amountValue, setAmountValue] = useState(null);

    // Paiement type array
    const formTypeArray = [
        {
            id: 0,
            name: 'Recouvrée',
            type: 'total',
            status: 500
        },
        {
            id: 1,
            name: 'En partie recouvrée',
            type: 'partial',
            status: 400
        }
    ]

    // -- react hook form --
    const defaultValues = {
        date: dayjs(),
        type: formTypeArray[0],
        amount: null,
        payment_method: null
    };

    const {handleSubmit, control, watch , reset, formState: {isValid}} = useForm({defaultValues});

    useEffect(() => {
        const type = watch('type');

        setTypeForm(type);

        if (item) {
            setAmountValue((item.partial_payment && item.partial_payment !== '0.00') ? item.partial_payment : '');
        }
    }, [watch('type')]);

    useEffect(() => {
        if (!item) {
            return;
        }

        setAmountValue((item.partial_payment && item.partial_payment !== '0.00') ? item.partial_payment : '');

        reset({
            date: item.payment_date ? item.payment_date : dayjs(),
            type: (item.partial_payment && item.partial_payment !== '0.00') ? formTypeArray[1] : formTypeArray[0],
            amount: (item.partial_payment && item.partial_payment !== '0.00') ? item.partial_payment : '',
            payment_method: item.payment_method ? {name: item.payment_method} : null
        });

    }, [item]);

    useEffect(() => {
        if (!view) {
            reset(defaultValues);
            setErrors(null);
        }
    }, [view]);

    // -- functions that get data for Select Inputs --
    const handleGetPaymentMethods = async (search, page = 1) => {
        await fetchSelectData({action: PaymentMethodActions.getAllPaymentMethods, search:`&search=${search}`, page: page, setter: setPaymentMethods, userData, resultPath: 'data'});
    };

    useEffect(() => {
        if (!userData) {
            return;
        }
        // ↓ payment methods dropdown values
        (async () => {
            await handleGetPaymentMethods('', 1);
        })();
    }, []);

    const onSubmit = async (data) => {
        setDisabled(true);
        setLoading(true);
        setErrors(null);

        const invoice = {
            id: item.id,
            status: typeForm.status,
            payment_date: standardFormat(data.date),
            payment_method: data.payment_method.name
        }

        if (typeForm.type === 'partial') {
            invoice.partial_payment = data.amount;
        }
        else {
            invoice.partial_payment = '0';
        }

        const result = await InvoiceActions.updateInvoiceById(userData, item.id, invoice);
    
        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            const removal = invoice.status === 400 ? false : true;
            await refreshData(removal);
            notify('Votre facture à bien été modifiée');
            setView(false);
            reset();
        }
            
        setLoading(false);
        setDisabled(false);
    }

    return (
        <Popup
            view={view}
            setView={setView}
            width='1098px'
            xOverflow=''
            yOverflow=''
        >
            <ContainerCard
                overflow=''
                title={'Recouvremment'}
                coloredButtonDisplayed={false}
            >
                <div className="flex flex-row justify-between px-4 pt-2 pb-4">
                    <div className='flex flex-col'>
                        <div className="mb-2">
                            <Controller
                                name="type"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez sélectionner un type de recouvrement.'
                                }}}
                                render={({
                                    field: {onChange, value, ref},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Status'
                                        placeholder='Status'
                                        inputRef={ref}
                                        value={value}
                                        error={error}
                                        options={formTypeArray || []}
                                        onChange={onChange}
                                        labelKeys={['name']}
                                        isSearchable={false}
                                        required={false}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </div>

                        <div className="mb-2">
                            <Controller
                                name="date"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une date.'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <DateInput
                                        label={'Date'}
                                        onChange={onChange}
                                        value={frenchFormat(value)}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col justify-between'>
                        <div className="flex mb-2">
                            <Controller
                                name="payment_method"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez sélectionner un mode de paiement.'
                                }}}
                                render={({
                                    field: {onChange, value, ref},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Mode de paiement'
                                        placeholder='Mode de paiement'
                                        inputRef={ref}
                                        value={value}
                                        error={error}
                                        options={paymentMethods?.options || []}
                                        hasNextPage={paymentMethods?.hasNextPage || false}
                                        loading={!paymentMethods}
                                        onChange={onChange}
                                        isSearchable={true}
                                        fetchFunction={handleGetPaymentMethods}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </div>
                        
                        {typeForm?.type === 'partial' && (
                            <div className="mb-2">
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{required: true}}
                                    shouldUnregister={true}
                                    render={({
                                        field: {onChange},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            label="Total des recouvrements (€)"
                                            onChange={(value) => {
                                                setAmountValue(value);
                                                onChange(value);
                                            }}
                                            value={amountValue}
                                            error={error}
                                            required={typeForm.type === formTypeArray[1]}
                                            disabled={disabled}
                                            placeholder="Total des recouvrements"
                                            type="string"
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col self-end justify-center pb-2 mt-12">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content={'Metre à jour'}
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
        </Popup>
    )
}

export default InvoicePaiementPopup;