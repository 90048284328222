import {useState} from 'react';
import {Link} from 'react-router-dom';
import {HourglassFilled, CreditCardFilled, ShoppingCartOutlined} from '@ant-design/icons';

import Title from '../../../components/ui/Title';
import SimpleCard from '../../../components/ui/SimpleCard/SimpleCard';
import Button from '../../../components/ui/Button';
import Popup from '../../../components/ui/Popup';
import SupplierInvoiceForm from './SupplierInvoiceForm';
import PurchasesForm from './PurchasesForm';

const ContractorIndicator = ({
    userData,
    totalContractorInvoicesAwaitingItems,
    totalContractorInvoicesToPayItems,
    totalPurchaseItems,
    currentDate,
    refreshContractorInvoicesData,
    refreshPurchasesData
}) => {

    const [supplierInvoicePopupView, setSupplierInvoicePopupView] = useState(false);
    const [purchasePopupView, setPurchasePopupView] = useState(false);
    
    const contractorInvoicesAwaitingIcon = <HourglassFilled
        style={{ fontSize: '30px', color: '#ffff' }}
        twoToneColor="#ffff"
    />;

    const contractorInvoicesToPayIcon = <CreditCardFilled
        style={{ fontSize: '30px', color: '#ffff' }}
        twoToneColor="#ffff"
    />;

    const purchasesIcon = <ShoppingCartOutlined
        style={{ fontSize: '37px', color: '#ffff' }}
        twoToneColor="#ffff"
    />;

    return (
        <div className='my-10'>
            <div className='flex items-center justify-between'>
                <Title title={currentDate} textSize='text-3xl'/>
                <div className='flex items-center'>
                    <Button
                        content="Nouvelle facture fournisseur"
                        onClick={() => setSupplierInvoicePopupView(true)}
                    />
                    <Button
                        content="Nouvel achat"
                        onClick={() => setPurchasePopupView(true)}
                    />
                </div>
            </div>
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <Link style={{width: '49%'}} to='contractor-invoices-awaiting'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={contractorInvoicesAwaitingIcon}
                        title={totalContractorInvoicesAwaitingItems}
                        subTitle="Factures fournisseurs en attente"
                        hoverEffect={true}
                    />
                </Link>
                
                <Link style={{width: '49%'}} to='contractor-invoices-to-pay'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={contractorInvoicesToPayIcon}
                        title={totalContractorInvoicesToPayItems}
                        subTitle="Factures fournisseurs à payer"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '49%'}} to='purchases'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={purchasesIcon}
                        title={totalPurchaseItems.toLocaleString()}
                        subTitle="Achats"
                        hoverEffect={true}
                    />
                </Link>
            </div>
            <Popup
                view={supplierInvoicePopupView}
                setView={setSupplierInvoicePopupView}
                width={'1098px'}
                xOverflow=''
            >
                <SupplierInvoiceForm
                    setView={setSupplierInvoicePopupView}
                    invoiceContractorToBeUpdated={null}
                    refreshData={refreshContractorInvoicesData}
                />
            </Popup>
            <Popup
                view={purchasePopupView}
                setView={setPurchasePopupView}
                width={'1098px'}
                xOverflow=''
            >
                <PurchasesForm
                    userData={userData}
                    expenseData={null}
                    refreshData={refreshPurchasesData}
                    setView={setPurchasePopupView}
                />
            </Popup>
        </div>
    )
}

export default ContractorIndicator;
