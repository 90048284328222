import {ACTION_CODE} from './_actionCode';
import {ProductionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_AGGREGATED_CALENDAR_PRODUCTIONS_BY_DAY';

export async function getAggregatedCalendarProductionsByDay(
    userData: Record<string, string>,
    before: string,
    after: string,
    query = ''
) {
    try {
        LoggerService.logInfo('Action - getAggregatedCalendarProductionsByDay - Start');

        const getAggregatedCalendarProductionsByDayResult = await ProductionService.getAggregatedCalendarProductionsByDay(userData, before, after, query);

        const {data, metadata} = getAggregatedCalendarProductionsByDayResult;
        
        LoggerService.logInfo('Action - getAggregatedCalendarProductionsByDay - End - Success');

        return ResultModel.newSuccessResult(data, metadata);

    }
    catch (error) {
        LoggerService.logInfo('Action - getAggregatedCalendarProductionsByDay - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}