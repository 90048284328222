import ActionsButton from '../../../components/ui/ActionsButton/ActionsButton';
import {frenchFormat} from '../../../utils';

export const productionsDrop = (
    setNewProductionPopupVisible,
    setIsModifyActive,
    handleDeleteProduction,
    actionButtonLoading,
    actionButtonDisabled
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: true,
            subline: false,
            columnConfig: [
                {
                    label: 'Catégorie',
                    value: 'category',
                    width: 'w-[22%]',
                    sort: true,
                    component: (item) => {
                        return (<div className="flex flex-row items-center justify-start">
                            {item.category || '-'}
                        </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (<div className="flex flex-row items-center justify-center">
                            {frenchFormat(item.date)}
                        </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: 'notes',
                    width: 'w-[18%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.notes.length > 0 ? item.notes : '-'}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours Produits',
                    value: 'produced_days',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+((item.produced_days))}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours facturés',
                    value: 'billed_days',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+((item.billed_days))}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours non facturés',
                    value: 'unbilled_days',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        const unbilledDays = +(item.produced_days - item.billed_days);

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {unbilledDays}
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: 'drop_action',
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={
                                        () => {
                                            setNewProductionPopupVisible(true);
                                            setIsModifyActive(item);
                                        }
                                    }
                                    disabled={actionButtonDisabled}
                                />
                                <div className="w-0 h-0 px-2"/>
                                <ActionsButton
                                    type="delete"
                                    isLoading={actionButtonLoading === item.id}
                                    onClick={
                                        () => {
                                            handleDeleteProduction(item);
                                        }
                                    }
                                    disabled={actionButtonDisabled}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer ce temps ?'
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
