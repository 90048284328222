import {ExpenseDao} from '../../dao';
import {ExpenseModel} from '../../models';
import {
    ErrorModel
} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_EXPENSE';

export async function updateExpense(
    userData: Record<string, string>,
    expenseId: string,
    update: Record<string, any>
) {
    try {
        const result = await ExpenseDao.updateExpense(userData.userToken, expenseId, update);

        const resultParsed = ExpenseModel.parseExpense(result);

        return resultParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'un frais',
            error: error
        });
    }
}
