import ActivityIndicator from "./ActivityIndicator";
import ChargeIndicator from "./ChargeIndicator";

const ProductionTab = ({
    productionActivity,
    users,
    productionLoad,
    datePickerDate,
    handleDatePickerChange
}) => {
    return (
        <div className='mt-6'>
            <ActivityIndicator
                productionActivity={productionActivity}
            />
            <ChargeIndicator
                users={users}
                productionLoad={productionLoad}
                datePickerDate={datePickerDate}
                handleDatePickerChange={handleDatePickerChange}
            />
        </div>
    )
}

export default ProductionTab;
