export const dasTeamRessources = (ressources: Record<string, any>, key: string) => {
    
    const ressourceArray: {currentdas: string, username: string, load: Record<string, any>, total_load: number, seniority: string}[] = []
    for (const ressourceKey in ressources) {
        const ressource = ressources[ressourceKey]
        
        if (ressource.currentdas === key) {
            ressourceArray.push({username: ressourceKey, ...ressource})
        }
    }

    return ressourceArray
}

export const cabinetTeamRessources = (ressources: Record<string, any>) => {
    const aggregateByCrrentDas: { das: string; all_das_load:number, das_load: number, total_load: number; }[] = [];
    for (const ressourceKey in ressources) {
        const das = ressources[ressourceKey].currentdas;
        const total_load = ressources[ressourceKey].total_load;
        let all_das_load = 0
        const das_load = ressources[ressourceKey].load[das]

        for (const das in ressources[ressourceKey].load) {
            all_das_load += ressources[ressourceKey].load[das]
        }

        if (aggregateByCrrentDas.find((item) => item.das === das)) {
            const index = aggregateByCrrentDas.findIndex((item) => item.das === das);
            aggregateByCrrentDas[index].total_load += total_load;
            aggregateByCrrentDas[index].all_das_load += all_das_load;

            continue;
        }
        aggregateByCrrentDas.push({
            das: das,
            all_das_load: total_load,
            das_load: das_load,
            total_load: total_load
        });
    }

    return aggregateByCrrentDas;
};

export  const calculateMainPercentage = (ressource: Record<string, any>) => {
    let lotalDasLoad = 0;
    for (const das in ressource.load) {
        lotalDasLoad += ressource.load[das]
    }
    const percentage = (lotalDasLoad / ressource.total_load) * 100

    return Math.ceil(percentage)
}

export const calculateDasPercentage = (ressource: Record<string, any>, das: string) => {
    const percentage = (ressource.load[das] / ressource.total_load) * 100

    return Math.ceil(percentage)
}

export const calculateDasPercentageByDasLoad = (ressources: Record<string, any>, das: string) => {
    let total_load_current = 0;
    let load_current = 0;

    for (const ressourceKey in ressources) {
        if (ressources[ressourceKey].currentdas === das) {
            const ressource = ressources[ressourceKey];
            total_load_current += ressource.total_load;
            for (const dasKey in ressource.load) {
                load_current += ressource.load[dasKey];
            }
        }
    }
    
    const percentage = (load_current / total_load_current) * 100

    return Math.ceil(percentage)
}

export const calculateTotalLoadPercentage = (ressources: Record<string, any>) => {
    let total_load = 0;
    let load = 0;

    for (const ressourceKey in ressources) {
        const ressource = ressources[ressourceKey];
        total_load += ressource.total_load;
        for (const dasKey in ressource.load) {
            load += ressource.load[dasKey];
        }
    }
    
    const percentage = (load / total_load) * 100

    return Math.ceil(percentage)
}

