import './checkbox.css';

function CheckboxInput({
    uniqueName,
    label,
    required,
    value,
    onChange,
    error,
    reverse = false,
    labelWidth = '',
    color = 'orange'
}) {
    return (
        <div className="flex flex-col w-fit">
            <div className={`flex ${reverse ? 'flex-row-reverse' : 'flex-row'} items-center w-fit`}>
                <input
                    onChange={(e) => {onChange(e.target.checked);}}
                    checked={value}
                    className={`mr-2 checkbox-component ${color === 'orange' ? 'checkbox-orange' : 'checkbox-blue'}`} type="checkbox" name={uniqueName} id={uniqueName}
                />
                <label htmlFor={uniqueName} className={`flex relative flex-row font-normal ${labelWidth} text-sm text-[#646464]`}>
                    {label}
                    {required && <p className="absolute top-0 ml-1 text-sm font-normal text-red-500 -right-2">*</p>}
                </label>
            </div>
            {
                error &&
                    <div className="text-xs italic text-red-500">
                        {error.message}
                    </div>
            }
        </div>
    );
}

export default CheckboxInput;
