import {DashboardActions} from "../../../actions";
import {firstDayMonth, lastDayMonth, standardFormat} from "../../../utils";

export const getManagementParametersData = async (
    userData,
    notify,
    setMissionsProfitabilityData,
    missionsProfitabilityDatePicker,
    setConsultantContributionsData,
    consultantContributionsDatePicker,
    setCostsData,
    costsDatePicker,
    setContractorsData,
    contractorsDatePicker,
    setExpensesData,
    expensesDatePicker,
    setPaymentTermsData,
    paymentTermsDatePicker
) => {
    // ↓ Rentabilité des missions
    const managementProfitabilityDataResult = await DashboardActions.AdminActions.getProfitability(
        userData,
        `?before=${standardFormat(missionsProfitabilityDatePicker.endDate)}&after=${standardFormat(missionsProfitabilityDatePicker.startDate)}`
    );
    
    if (managementProfitabilityDataResult.success) {
        setMissionsProfitabilityData(managementProfitabilityDataResult.data);
    }
    else {
        notify('Erreur de récuperation des rentabilités des missions');
    }

    // ↓ Contribution des consultants
    const consultantContributionsDataResult = await DashboardActions.AdminActions.getTeamContribution(
        userData,
        `?before=${standardFormat(consultantContributionsDatePicker.endDate)}&after=${standardFormat(consultantContributionsDatePicker.startDate)}`
    );

    if (consultantContributionsDataResult.success) {
        setConsultantContributionsData(consultantContributionsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des contribution des consultants');
    }

    // ↓ Coûts des moyens + Coût commercial d’acquisition + Coûts de production
    const costsDataResult = await DashboardActions.AdminActions.getCosts(
        userData,
        `?before=${standardFormat(costsDatePicker.endDate)}&after=${standardFormat(costsDatePicker.startDate)}`
    );

    if (costsDataResult.success) {
        setCostsData(costsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des coûts des moyens');
    }

    // ↓ Analyse de la sous-traitance
    const contractorsDataResult = await DashboardActions.AdminActions.getContractors(
        userData,
        `?before=${standardFormat(contractorsDatePicker.endDate)}&after=${standardFormat(contractorsDatePicker.startDate)}`
    );
    
    if (contractorsDataResult.success) {
        setContractorsData(contractorsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des analyses de la sous-traitance');
    }

    // ↓ Analyse des frais
    const expensesDataResult = await DashboardActions.AdminActions.getExpenses(
        userData,
        `?before=${standardFormat(expensesDatePicker.endDate)}&after=${standardFormat(expensesDatePicker.startDate)}`
    );
        
    if (expensesDataResult.success) {
        setExpensesData(expensesDataResult.data);
    }
    else {
        notify('Erreur de récuperation des analyses de la sous-traitance');
    }

    // ↓ Analyse des délais de paiement
    const paymentTermsDataResult = await DashboardActions.AdminActions.getPaymentTerms(
        userData,
        `?before=${standardFormat(paymentTermsDatePicker.endDate)}&after=${standardFormat(paymentTermsDatePicker.startDate)}`
    );
            
    if (paymentTermsDataResult.success) {
        setPaymentTermsData(paymentTermsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des analyses de la sous-traitance');
    }
}

export const handleMissionsProfitabilityDatePickerChange = async (
    e,
    userData,
    setLoading,
    notify,
    setMissionsProfitabilityDatePicker,
    setMissionsProfitabilityData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setMissionsProfitabilityDatePicker(dates);

    const managementProfitabilityDataResult = await DashboardActions.AdminActions.getProfitability(
        userData,
        `?before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );
    
    if (managementProfitabilityDataResult.success) {
        setMissionsProfitabilityData(managementProfitabilityDataResult.data);
    }
    else {
        notify('Erreur de récuperation des rentabilités des missions');
    }

    setLoading(false);
}

export const handleConsultantContributionsDatePickerChange = async (
    e,
    userData,
    setLoading,
    notify,
    setConsultantContributionsDatePicker,
    setConsultantContributionsData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setConsultantContributionsDatePicker(dates);

    const consultantContributionsDataResult = await DashboardActions.AdminActions.getTeamContribution(
        userData,
        `?before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );

    if (consultantContributionsDataResult.success) {
        setConsultantContributionsData(consultantContributionsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des contribution des consultants');
    }

    setLoading(false);
}

export const handleCostsDatePickerChange = async (
    e,
    userData,
    setLoading,
    notify,
    setCostsDatePicker,
    setCostsData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setCostsDatePicker(dates);

    const costsDataResult = await DashboardActions.AdminActions.getCosts(
        userData,
        `?before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );

    if (costsDataResult.success) {
        setCostsData(costsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des coûts des moyens');
    }

    setLoading(false);
}

export const handleContractorsDatePickerChange = async (
    e,
    userData,
    setLoading,
    notify,
    setContractorsDatePicker,
    setContractorsData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setContractorsDatePicker(dates);

    const contractorsDataResult = await DashboardActions.AdminActions.getContractors(
        userData,
        `?before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );
    
    if (contractorsDataResult.success) {
        setContractorsData(contractorsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des analyses de la sous-traitance');
    }

    setLoading(false);
}

export const handleExpensesDatePickerChange = async (
    e,
    userData,
    setLoading,
    notify,
    setExpensesDatePicker,
    setExpensesData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setExpensesDatePicker(dates);

    const expensesDataResult = await DashboardActions.AdminActions.getExpenses(
        userData,
        `?before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );
        
    if (expensesDataResult.success) {
        setExpensesData(expensesDataResult.data);
    }
    else {
        notify('Erreur de récuperation des analyses de la sous-traitance');
    }

    setLoading(false);
}

export const handlePaymentTermsDatePickerChange = async (
    e,
    userData,
    setLoading,
    notify,
    setPaymentTermsDatePicker,
    setPaymentTermsData
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setPaymentTermsDatePicker(dates);

    const paymentTermsDataResult = await DashboardActions.AdminActions.getPaymentTerms(
        userData,
        `?before=${standardFormat(dates.endDate)}&after=${standardFormat(dates.startDate)}`
    );
            
    if (paymentTermsDataResult.success) {
        setPaymentTermsData(paymentTermsDataResult.data);
    }
    else {
        notify('Erreur de récuperation des analyses de la sous-traitance');
    }

    setLoading(false);
}
