import ActivityIndicators from "./ActivityIndicators";
import TradeBalance from "./TradeBalance";

const CommercialTab = ({
    salesActivity,
    salesOverview,
    datePickerDate,
    handleDatePickerChange
}) => {

    return (

        <div className="mt-6">
            <ActivityIndicators salesActivity={salesActivity}/>
            <TradeBalance
                salesOverview={salesOverview}
                datePickerDate={datePickerDate}
                handleDatePickerChange={handleDatePickerChange}
            />
        </div>
    )
}

export default CommercialTab;
