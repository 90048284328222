import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Layout from '../../components/layout';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import {contractorDealsTableConfig} from './configs/contractorDealsTableConfig';
import {ContractorActions, DealActions} from '../../actions';
import Table from '../../components/ui/Table/Table';
import ContractorDetailsBanner from "./components/ContractorDetailsBanner"

function ContractorsDealsPage() {
    // States
    const [dealsPaginationMetadata, setDealsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [dealsFilterQuery, setDealsFilterQuery] = useState('');

    const [dealsDataTable, setDealsDataTable] = useState([]);
    const [contractorData, setContractorData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);
    
    const {id} = useParams();

    const navigate = useNavigate();

    const handleGetDealsData = async (page) => {
        setLoadingTable(true);
        setDealsDataTable([]);
    
        const result = await DealActions.getAllDeals(userData,
            `&type=business&page_size=${dealsPaginationMetadata.pageSize}&display=${userData.userProfileName}&contractor=${id}`, page);

        if (result.success) {
            setDealsDataTable(result.data);
            setDealsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (dealsPaginationMetadata.totalItems !== result.metadata.count) {
                setDealsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify('Erreur de récuperation des affaires');
        }

        setLoadingTable(false);
    };

    // Get data
    useEffect(() => {
        (async () => {

            const result = await ContractorActions.getContractorById(userData, id);

            if (result.success) {
                setContractorData(result.data);
            }
            else {
                notify('Erreur de récuperation des données');
            }

        })();
    }, [])

    const handleMissionNavigation = async (dealId) => {
        const getDeal = await DealActions.getDealById(userData, dealId);
        
        if (getDeal.success) {

            const isSale = getDeal.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

            if (userData.role === 'CO' && getDeal.data.production_lead.id !== userData.userId && !isSale) {
                
                navigate(`/deals/${getDeal.data.id}/missions/${getDeal.data.missions.find((mission) => mission.ressource.id === userData.userId)?.id}`);

                return
            }
            else {
                navigate(`/deals/${dealId}`);
            }
        }

    }

    // filter deals
    useEffect(() => {
        handleGetDealsData(1);
    }, [dealsFilterQuery]);

    const filterDealList = async (query) => {
        setDealsFilterQuery(query);
    };

    // Configs
    const tableConfig = contractorDealsTableConfig(handleMissionNavigation, filterDealList, userData, id);

    return (
        <Layout>
            <Toast/>
            <div className= "mb-4">
                <ContractorDetailsBanner
                    userData={userData}
                    data={contractorData}
                />
            </div>
            <div className='px-20 pt-12 pb-36'>
                <div className="flex items-center justify-between mb-8">
                    <div className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>
                    Les affaires du sous/co-traitant
                        <span className="text-xs ml-1 text-[#646464]">
                        ({dealsDataTable.length})
                        </span>
                    </div>
                </div>
                <Table
                    data={dealsDataTable}
                    tableConfig={tableConfig}
                    loading={loadingTable}
                    paginationMetadata={dealsPaginationMetadata}
                    onPageNumberChange={handleGetDealsData}
                />
            </div>
        </Layout>
    );
}

export default ContractorsDealsPage;
