import React, {useEffect, useState} from 'react';
import Button from '../../../../components/ui/Button/Button';
import {Controller} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import Tooltip from 'antd/lib/tooltip';
import plus from '../../../../assets/icons/button/plus.png';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../assets/icons/blueStyle/delete-blue.png';
import {fetchSelectData} from '../../../../utils';
import {ContractorActions, DealActions} from '../../../../actions';
import NewContractorPopup from '../../../contractorsPage/components/popups/NewContractorPopup';
import Popup from '../../../../components/ui/Popup/Popup';
import {useSelector} from 'react-redux';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import { notify } from '../../../../components/ui/Toast/Toast';
import { getErrors } from '../../../../utils';
import Error from '../../../../components/ui/Error/Error';

// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const ResponseGroupPopup = ({
    partnerValues,
    setPartnerValues,
    setView,
    setNewPartnerView,
    newPartnerView,
    data,
    refreshData,
    blueStyle = false
}) => {

    const [responseGroupLabel, setResponseGroupLabel] = useState('Terre d\'avance');
    const [contractors, setContractors] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    // const [taValue, setTaValue] = useState(null);

    const defaultValues = {
        response_groupement: {id: 3, name: 'Seul'},
        subsidiary: null
    }
    
    const {handleSubmit, control, watch, reset, setValue, formState: {isValid}} = useForm({defaultValues: defaultValues});

    const taRoleValue = watch('response_groupement');
    
    const subsidiaryValue = watch('subsidiary');

    const taRoles = [
        { id: 1, name: 'Cotraitant' },
        { id: 2, name: 'Mandataire' },
        { id: 3, name: 'Seul' }
    ];

    useEffect(() => {
        if (data.contractors.length === 0) {
            setValue("response_groupement", taRoles[2]);
        }

        if (data.contractors.find((partner) => partner.is_cocontractor === false)) {
            setValue("response_groupement", taRoles[0]);
        }

        if (data.contractors.length && data.contractors.every((partner) => partner.is_cocontractor === true)) {
            setValue("response_groupement", taRoles[1]);
        }
    }, [data])

    const [partnerRoles, setPartnerRoles ] = useState(
        [
            { id: 1, name: 'Cotraitant' },
            { id: 2, name: 'Mandataire' }
        ]
    );

    useEffect(() => {

        // ↓ contractor dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();

    }, []);

    // --------------------------------------------
    // ↓ Rerender form to update response grouping label on change of subsidiary ↓
    // --------------------------------------------
    useEffect(() => {
        if (!subsidiaryValue) return;
        setResponseGroupLabel(subsidiaryValue?.name ===  "Terre d'Avance Formation" ? "Terre d'Avance Formation" : "Terre d'Avance");
    }, [subsidiaryValue])

    useEffect(() => {
        if (!taRoleValue || !partnerValues.length) return;

        if (taRoleValue.name === "Mandataire") {
            const setPartnerValuesToCocontractor = partnerValues.map((partner) => {
                return {
                    ...partner,
                    role: {id: 1, name: 'Cotraitant'}
                }
            });
            setPartnerRoles((setPartnerRoles) => setPartnerRoles.filter((role) => role.name === "Cotraitant"));
            setPartnerValues((setPartnerValuesToCocontractor))
        }

        if (taRoleValue.name === "Cotraitant") {

            setPartnerRoles([
                { id: 1, name: 'Cotraitant' },
                { id: 2, name: 'Mandataire' }
            ]);

        }

        if (!partnerValues.length && taRoleValue.name === "Mandataire" ||
            !partnerValues.length && taRoleValue.name === "Cotraitant"
        ) {
            setPartnerValues({
                id: Math.random(),
                ressource: null,
                role: {id: 1, name: 'Cotraitant'}});

        }
            
    }, [taRoleValue])

    useEffect(() => {
        if (partnerValues.find((partner) => partner.role.name === "Mandataire")) {
            setPartnerRoles((setPartnerRoles) => setPartnerRoles.filter((role) => role.name === "Cotraitant"));
        }

        if (partnerValues.every((partner) => partner.role.name === "Cotraitant" && taRoleValue.name !== "Mandataire")) {
            setPartnerRoles([
                { id: 1, name: 'Cotraitant' },
                { id: 2, name: 'Mandataire' }])
        }

    }, [partnerValues])

    // -- dropdowns values --
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({
            action: ContractorActions.getAllContractors,
            search:`&search=${search}`,
            page: page || null,
            setter: setContractors,
            userData,
            resultPath: 'data'
        });
    }

    const handleAddPartner = () => {
        if (partnerValues.length < 10) {
            setPartnerValues((prevState) => [...prevState, {id: Math.random(), role: '', ressource: null}]);
        }
    }

    const handleRemovePartner = (id) => {
        setPartnerValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handlePartnerValueChange = (e, id, type) => {

        const isRessource = type === 'ressource';

        if (isRessource) {
            const dealContractorObject = {
                billable_days: "0.00",
                budget: "0.00",
                budget_vat: "0.00",
                contractor: {
                    id: e.id,
                    name: e.name,
                    location: e.location,
                    categories: e.categories
                },
                direct: false,
                is_cocontractor: true
            }

            setPartnerValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: dealContractorObject}  : el));

            return;
        }

        setPartnerValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }

    const filterOutAlreadySelected = (options, selected) => {
        if (!options) return;
        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.name === obj2?.ressource?.contractor?.name
            )
        );

        return filteredArray;
    };

    const onSubmit = async () => {
        if (partnerValues.every((partner) => partner.role.name === "Cotraitant") && taRoleValue.name === "Cotraitant") {
            
            setErrors([{title: "Mandataire manquant", message:"Vous devez avoir au moins un mandataire"}]);

            return;
        }
        setErrors(null);
        setDisabled(true);
        setLoading(true);
        
        // setSaleInputsInvalidity(true);
        
        const updatedDeal = {
            contractors: partnerValues.map((contractor) => {
                if (!contractor.ressource) return;

                return {
                    contractor_id: contractor.ressource.contractor.id,
                    is_cocontractor: contractor.role.name === 'Cotraitant' ? true : false
                }
            })
        }
        
        const result = await DealActions.updateDeal(userData, taRoleValue.name === "Seul" ? {contractors: []} : updatedDeal, data.id);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            setView(false);
            notify('Les informations ont été mis à jour');
        }

        setLoading(false);
        setDisabled(false);
        // setSaleInputsInvalidity(false);
    }

    // const navigate = useNavigate();

    // Get dropdows data
    // useEffect(() => {

    //     // ↓ deal das dropdown values
    //     (async () => {
    //         await handleGetDealDas('', 1);
    //     })();

    // }, []);

    return (
        <div>
            <ContainerCard
                title={data.name}
                coloredButtonDisplayed={false}
                overflow='overflow-visible'
            >
            
                <div className={`flex justify-start pl-2 ${blueStyle ? "text-gradient-blue" : "text-gradient"} w-full pb-2 pt-4 mb-6 border-b border-gray-200`}>
                            Groupement de réponse
                </div>
            
                <div className="pb-3 border-b border-gray-200 w-full">
                    <div className='w-full flex justify-start'>
                        <Controller
                            name="response_groupement"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le rôle'
                            }}}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label={responseGroupLabel}
                                    blueStyle={blueStyle}
                                    placeholder='Rôle'
                                    inputRef={ref}
                                    width='w-[500px]'
                                    error={error}
                                    value={value}
                                    options={taRoles}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    disabled={disabled}
                                    labelWidth="w-[245px]"
                                />
                            )}
                        />
                    </div>

                    {taRoleValue.name === "Seul" ? null :  <div className='w-full mt-2'>
                        {
                            partnerValues.map((partner, index) => {
                                return (
                                    <div
                                        className='w-full flex'
                                        key={partner.id}
                                    >
                                        <div className='w-full flex mb-1.5'>
                                            <div className='flex justify-start'>
                                                {
                                                    index === 0 ?
                                                        <div className='flex flex-col justify-center mr-[14.5px]'>
                                                            <Tooltip title='Créer un partenaire'>
                                                                <button
                                                                    onClick={() => setNewPartnerView(true)}
                                                                    disabled={!(partnerValues.length < 10)}
                                                                    className={`p-1 rounded-full ${partnerValues.length < 10 ? 'hover:opacity-90' : ''}
                                                                    ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}
                                                                    ${!(partnerValues.length < 10) ? 'opacity-[40%]' : 'opacity-100'}`}
                                                                >
                                                                    <img src={plus} alt='plus' className='w-6'/>
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                        : <div className="w-6 ml-[17px]"></div>
                                                }
                                                <SelectInput
                                                    placeholder='Partenaire'
                                                    value={partner?.ressource?.contractor ? partner?.ressource?.contractor : partner.ressource}
                                                    options={filterOutAlreadySelected(contractors?.options, partnerValues)}
                                                    onChange={(e) => handlePartnerValueChange(e, partner.id, 'ressource')}
                                                    labelKeys={['name']}
                                                    disabled={disabled}
                                                    fetchFunction={handleGetContractors}
                                                    hasNextPage={contractors?.hasNextPage}
                                                    width = 'w-[200px]'
                                                    isSearchable={true}
                                                    loading={!contractors}
                                                    labelWidth='w-40'
                                                    blueStyle={blueStyle}
                                                />
                                                <div className='w-full ml-3'>
                                                    <SelectInput
                                                        placeholder='Rôle'
                                                        // inputRef={ref}
                                                        width='w-[500px]'
                                                        // error={error}
                                                        value={partner.role}
                                                        options={partnerRoles}
                                                        onChange={(e) => handlePartnerValueChange(e, partner.id, 'role')}
                                                        labelKeys={["name"]}
                                                        required={true}
                                                        disabled={disabled}
                                                        blueStyle={blueStyle}
                                                    />
                                                </div>
                                                <div className='flex flex-col justify-center'>
                                                    {
                                                        index === 0  ?
                                                            <Tooltip title='Assigner un partenaire existant'>
                                                                <div
                                                                    onClick={handleAddPartner}
                                                                    className={`whitespace-nowrap cursor-pointer text-sm ${blueStyle ? "text-gradient-blue" : "text-gradient"} ${partnerValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"} ml-1`}
                                                                >
                                                                    <p className='hover:underline'>Ajouter un partenaire</p>
                                                                </div>
                                                            </Tooltip> :
                                                            <button
                                                                onClick={() => handleRemovePartner(partner.id)}
                                                                disabled={disabled}
                                                                className=" bg-transparent"
                                                            >
                                                                <Tooltip title='Supprimer'>
                                                                    <div>
                                                                        <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-6"/>
                                                                    </div>
                                                                </Tooltip>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>}
                </div>
                <div className="flex flex-col justify-center pb-2 mt-2">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={blueStyle ? 'blue' : 'primary'}
                            content={'Sauvegarder'}
                            onClick={onSubmit}
                            loading={loading}
                            disabled={
                                taRoleValue?.name !== "Seul" &&
                                partnerValues.length &&
                                partnerValues.some((partner) => !partner.ressource)}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
            <Popup
                view={newPartnerView}
                setView={setNewPartnerView}
                width={'1098px'}
                xOverflow=''
                yOverflow='overflow-y-auto'
                maxHeight='45rem'
            >
                <NewContractorPopup
                    setView={setNewPartnerView}
                    userData={userData}
                    toBeUpdated={null}
                    refreshData={handleGetContractors}
                />
            </Popup>
        </div>
    );
}

export default ResponseGroupPopup;
