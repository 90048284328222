import {ACTION_CODE} from './_actionCode';
import {ProjectsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'CREATE_PROJECT';

export async function createProject(token = '', payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - createProject - Start');

        const result = await ProjectsService.createProject(token, payload);

        LoggerService.logInfo('Action - createProject - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - createProject - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création du projet',
            error: error
        });
        
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
