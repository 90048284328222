import {ACTION_CODE} from './_actionCode';
import {ActivityTypesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'CREATE_ACTIVITY_TYPES';

export async function createActivityTypes(userData: Record<string, string>, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - createActivityTypes - Start');

        const result = await ActivityTypesService.createActivityTypes(userData, payload);

        LoggerService.logInfo('Action - createActivityTypes - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - createActivityTypes - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création du type d\'activité',
            error: error
        });
        
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
