import {useEffect, useState} from 'react';

import ContainerCard from '../ContainerCard';

/**
@typedef {Object} TableConfig
@property {string} width - width of the table
@property {string} height - height of the table
@property {string} title - title of the table
@property {string} childOverflow - overflow-y-scroll of the table
@property {string} tapSelectorPosition - self-start of the table
@property {Array.<Tab>} tabs - array of tabs
@typedef {Object} Tab
@property {number} id - Id of the tab
@property {string} label - label of the tab
@property {React.Element} component - react component of the tab
*/

const TabCard = ({
    config,
    onChange = null
}) => {
    const [activeTab, setActiveTab] = useState(config.tabs[0].id);

    useEffect(() => {
        if (onChange) {
            const clickedTab = config.tabs.find(tab => tab.id === activeTab);
            onChange(clickedTab);
        }
    }, [activeTab]);

    const handleTabClick = (id) => {
        if(config && typeof config.onEditButtonClick === 'function') {
            config.onEditButtonClick(false);
        }

        setActiveTab(id);

        const clickedTab = config.tabs.find(tab => tab.id === id);

        clickedTab?.optionalAction && clickedTab.optionalAction();

    };

    // This code is used to add rounded corners left or right to the selected tab button
    let selectedButtonBorderRadius = '';

    if (config.tabs.length === 1) {
        selectedButtonBorderRadius = 'rounded-sm';
    }
    else if (config.tabs.length > 1) {
        if (activeTab === config.tabs[0].id) {
            selectedButtonBorderRadius = 'rounded-l-sm';
        }
        else if (activeTab === config.tabs[config.tabs.length - 1].id) {
            selectedButtonBorderRadius = 'rounded-r-sm';
        }
        else {
            selectedButtonBorderRadius = 'rounded-none';
        }
    }

    return (
        <>
            {config.showContainerCard === true || config.showContainerCard === undefined ? (
                <ContainerCard
                    title={config.title || ''}
                    coloredButtonDisplayed={false}
                    width={config.width || 'w-1/2'}
                    height={config.height || 'h-[30em]'}
                    showHeader={config.showCardHeader}
                    editButtonDisplayed={true}
                    onEditButtonClick={config.onEditButtonClick || null}
                    overflow={config.overflow || 'overflow-auto'}
                >
                    <TabComponent
                        config={config}
                        activeTab={activeTab}
                        handleTabClick={handleTabClick}
                        selectedButtonBorderRadius={selectedButtonBorderRadius}
                    />
                </ContainerCard>
            ) :
                (
                    <div className='px-5 py-3'>
                        <TabComponent
                            config={config}
                            activeTab={activeTab}
                            handleTabClick={handleTabClick}
                            selectedButtonBorderRadius={selectedButtonBorderRadius}
                        />
                    </div>
                )
            }
        </>
    );
};

export default TabCard;

const TabComponent = ({ config, activeTab, handleTabClick, selectedButtonBorderRadius }) => (
    <>
        <div className={`flex flex-row mb-3 ${config.tapSelectorPosition}  border rounded-sm w-fit`}>
            {config.tabs.map((tab) => (
                <div
                    key={tab.id}
                    onClick={() => handleTabClick(tab.id)}
                    className={`${activeTab === tab.id ? 'background-gradient ' + selectedButtonBorderRadius : 'cursor-pointer'} w-fit px-3 py-1`}
                >
                    <p className={`${activeTab === tab.id ? 'text-white' : 'text-[#262626]'}`}>
                        {tab.label}
                    </p>
                </div>
            ))}
        </div>
        <div className={`${config.childOverflow || ''} h-5/6`}>
            {config.tabs.map((tab) => (
                <div
                    key={tab.id}
                    className={`${activeTab === tab.id ? 'block' : 'hidden'}`}
                >
                    {activeTab === tab.id && tab.component}
                </div>
            ))}
        </div>
    </>
)
