import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {WalletFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import TabCard from '../../../../components/ui/TabCard/TabCard';
import {tradeBalanceTabConfig} from '../../configs/commercialTab/tradeBalanceSection/tradeBalanceTabConfig';
import {aggregateCountPerDas, aggregateCountTotal} from '../../functions/aggregateSalesOverview';

const TradeBalance = ({
    salesOverview,
    datePickerDate,
    handleDatePickerChange
}) => {

    const WalletIcon = <WalletFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className='mt-14'>
            <LineTitle title="Bilan commercial" textSize='text-4xl'/>

            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />
            </div>

            <div className='mt-7'>
                <Link to='specifications-studied' className='w-[49%] block'>
                    <SimpleCard
                        width='w-full'
                        title={aggregateCountTotal(salesOverview)}
                        subTitle={'Cahiers des charges  étudiés'}
                        iconComponent={WalletIcon}
                        topComponent={<TopComponent data={salesOverview}/>}
                    />
                </Link>
                <div className='mt-6'>
                    <TabCard config={tradeBalanceTabConfig(salesOverview)}/>
                </div>
            </div>
        </div>
    )
}

export default TradeBalance;

const TopComponent = ({data}) => {

    const allDasS = useSelector((state) => state.DasReducer.das);

    let dasS = [];

    for (const key in data) {
        let currentDas = allDasS.filter((el) => el.name === key);

        dasS.push({
            das: key,
            value: aggregateCountPerDas(data, key),
            dasObject: currentDas = currentDas.length > 0 ? currentDas[0] : null
        })
    }

    return (
        <div className='flex flex-wrap items-center justify-between w-full bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`
                                min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                                ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                            `}
                        >
                            <div className='flex flex-row items-center justify-between'>
                                <div className='text-sm font-medium text-gradient'>{el.das}</div>
                                <Badge
                                    content={el.dasObject ? [el.dasObject.manager] : '-'}
                                    color={el.dasObject ? null : '#bdc3c7'}
                                    type='round'
                                />
                            </div>
                            <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                            <div>
                                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                    {`${el.value} `}
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
