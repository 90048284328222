import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'CANCEL_INVOICE';

export async function cancelInvoice(
    userToken: string,
    invoicePayload: any,
    id:number
) {

    try {
        const response = await axios({
            method: 'post',
            data: invoicePayload,
            url: `${URL}/invoices/${id}/cancel/`,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        const errorMessage = () => {
            if (error.response.data.detail) {
                return error.response.data.detail;
            }
            else {
                return 'Une erreur est survenue lors de l\'annulation d\'une facture';
            }
        };
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: errorMessage(),
            error: error
        });
    }
}
