import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../../services';
import {ErrorModel, ResultModel} from '../../../../models';
import {LoggerService} from '../../../../services';
const FUNCTION_CODE = 'GET_ALL_DASHBOARD_CONSULTANT_ACTIVITY';

export async function getAllActivities(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getAllDashboardConsultantActivities - Start');

        const getAllDashboardConsultantActivities =
            await DashboardService.ConsultantService.ActivityService.getAllActivities(userData, params);

        LoggerService.logInfo('Action - getAllDashboardConsultantActivities - End - Success');

        return ResultModel.newSuccessResult(getAllDashboardConsultantActivities);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllDashboardConsultantActivities - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
