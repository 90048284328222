import React, {useState} from 'react';
import FileUploadInput from '../../../components/ui/Inputs/FileUploadInput';
import {Controller} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import Button from '../../../components/ui/Button';
import {ExpenseAttachmentActions} from '../../../actions';
import {notify} from '../../../components/ui/Toast/Toast';
import Error from '../../../components/ui/Error/Error';
import {getErrors} from '../../../utils';
import ContainerCard from '../../../components/ui/ContainerCard/ContainerCard';

function CreateNewExpenseAttachmentPopup({
    refreshData,
    userData,
    reportId,
    setView
}) {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    // -- react hook form --
    const defaultValues = {
        fileName: '',
        file: ''
    };
    const {
        handleSubmit, reset, formState: {isValid},
        control} = useForm({defaultValues: defaultValues});

    // -- handle submit --
    const onSubmit = async (data) => {
        setDisabled(true);
        setLoading(true);
        setErrors(null);

        const uploadExpenseAttachment = await ExpenseAttachmentActions.createExpenseAttachment(userData, data.file, reportId);

        if (!uploadExpenseAttachment.success) {
            setErrors(getErrors(uploadExpenseAttachment));
        }
        else {
            await refreshData();
            notify('Votre justificatif à bien été ajoutée');

            setView(false);
            reset();
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title='Ajouter un justificatif'
            coloredButtonDisplayed={false}
        >
            <div className="flex flex-col">
                <div className='flex justify-center w-full pb-5'>
                    <Controller
                        name="file"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez sélectionner un fichier.'
                        }}}
                        render={({
                            field: {onChange, value}
                        }) => (
                            <FileUploadInput
                                multiple
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
                <div className="flex justify-center pt-5">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={'Ajouter un justificatif'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewExpenseAttachmentPopup;
