import {useNavigate} from 'react-router-dom';

import Badge from "../../../../components/ui/Badge";
import {frenchFormat} from "../../../../utils";
import ActionsButton from "../../../../components/ui/ActionsButton";

export const customerDealsTableConfig = () => {
    const navigate = useNavigate();

    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Nom',
                    value: 'short_name',
                    width: 'w-[18%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Commerciaux',
                    value: 'sales',
                    width: 'w-[14%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={item.sales} type='rounded'/>
                            </div>
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Production',
                    value: 'production_lead.username',
                    width: 'w-[14%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.production_lead ?
                                        <Badge content={[item.production_lead]} type='rounded'/> :
                                        '-'
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Phase',
                    value: 'production_lead',
                    width: 'w-[14%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.production_lead ? "P" : "C"}
                                    type='rounded' color={item.production_lead ? '#F0A841' : '#00ABE9'}
                                    tooltip={item.production_lead ? "Phase de production" : "Phase commerciale"}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'type',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={item.type}
                                    color={'#646464'}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge
                                    content={`${(+(+item.budget).toFixed(2)).toLocaleString()} €`}
                                    color={'#646464'}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Fin',
                    value: 'mission_end_date',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.mission_end_date ? frenchFormat(item.mission_end_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => navigate(`/deals/${item.id}`)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
