import {MissionDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_MISSIONS';

export async function getAllMissions(
    userData: Record<string, string>,
    params: string,
    page: number
) {
    try {

        const result = await MissionDao.getAllMissions(userData, params, page);

        const metadata = {
            count: result.count,
            next: result.next,
            previous: result.previous
        };

        return {
            metadata,
            data: result.results
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des affaires',
            error: error
        });
    }
}
