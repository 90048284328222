import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_ALL_DEALS';

export async function getAllDeals(
    userData: Record<string, any>,
    params: string,
    page: number,
): Promise<any> {
    
    try {
        const response = await axios.get(
            `${URL}/deals/?page=${page}${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userData.userToken}`
                }
            },
        );

        return response.data;

    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des affaires',
            error: error
        });
    }
}
