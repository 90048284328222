import {ACTION_CODE} from './_actionCode';
import {DealLossMotivesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_DEALLOSSMOTIVE';

export async function deleteDealLossMotive(userData: Record<string, any>, dealLossMotiveId: number) {
    try {
        LoggerService.logInfo('Action - deleteDealLossMotiveById - Start');

        const deleteDealLossMotiveByIdResult = await DealLossMotivesService.deleteDealLossMotive(userData, dealLossMotiveId);

        LoggerService.logInfo('Action - deleteDealLossMotiveById - End - Success');

        return ResultModel.newSuccessResult(deleteDealLossMotiveByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteDealLossMotiveById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
