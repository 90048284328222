import {frenchFormat} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

export const expensesConfig = (
    handleDeleteExpense,
    setIsExpensePopupOpen,
    setExpenseToBeUpdated,
    actionButtonLoading,
    actionButtonDisabled
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: true,
            subline: false,
            columnConfig: [
                {
                    label: '#',
                    value: 'id',
                    width: 'w-[5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Affaire',
                    value: 'deal.short_name',
                    width: 'w-[18%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.deal.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'type.name',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.type.name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Refact.',
                    value: 'billable',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.billable ? 'Oui' : 'Non'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant',
                    value: 'amount',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(parseInt(item.amount).toLocaleString())}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'TVA',
                    value: 'vat',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(item.vat)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'CO2',
                    value: 'co2',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(item.co2)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: null,
                    width: 'w-[16%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.notes.length > 0 ? item.notes : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.parentStatus === 3 ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </> :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => {
                                                    setExpenseToBeUpdated(item);
                                                    setIsExpensePopupOpen(true);
                                                }}
                                                disabled={actionButtonDisabled}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={actionButtonLoading === item.id}
                                                onClick={() => handleDeleteExpense(item)}
                                                disabled={actionButtonDisabled}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce frais ?'
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
