import DasTeam from './DasTeam'
import ExternalRessource from './ExternalRessource'

const RessourceManagementsTab = ({
    ressources,
    externalRessources,
    handleDatePickerChange,
    datePicker,
    dasKey
}) => {
    return (
        <div>
            <DasTeam
                data={ressources}
                dasKey={dasKey}
            />
            <ExternalRessource
                data={externalRessources}
                handleDatePickerChange={handleDatePickerChange}
                datePicker={datePicker}
                dasKey={dasKey}
            />
        </div>
    )
}

export default RessourceManagementsTab;
