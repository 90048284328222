import {VehicleTypeDao} from '../../dao';
import {VehicleTypeModel} from '../../models';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_CUSTOMER_CATEGORY_BY_ID';

export async function updateVehicleType(
    userData: Record<string, string>,
    vehicleTypeId: number,
    update: Record<string, any>
) {
    try {
        const result = await VehicleTypeDao.updateVehicleType(userData.userToken, vehicleTypeId, update);

        const resultParsed = VehicleTypeModel.parseVehicleType(result);

        return resultParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
