import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './rich-text.css';

const RichText = ({ onChange, value, height, placeholder, error, disabled }) => {

    const handleChange = (content, delta, source, editor) => {
        onChange(editor.getHTML());
    };

    return (
        <div>
            <ReactQuill
                className={`${height}`}
                theme="snow"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                readOnly={disabled}
            />
            {error &&
                <div className="text-xs italic text-red-500">
                    {error.message}
                </div>
            }
        </div>
    );
};

export default RichText;
