import {useEffect, useState} from 'react';

import {TrainingActions, TrainingAttachmentActions, TrainingAttendancesActions} from '../../../../../actions';
import ContainerCard from '../../../../../components/ui/ContainerCard';
import Datepicker from '../../../../../components/ui/Datepicker';
import Table from '../../../../../components/ui/Table';
import {notify} from '../../../../../components/ui/Toast/Toast';
import {firstDayMonth, lastDayMonth, standardFormat} from '../../../../../utils';
import {traininAttendancesTableConfig} from '../../../configs/sectionConfigs/trainingAttendancesTableConfig';
import AdministrativeAttachmentsPopup from "../../../popups/trainingPopups/AdministrativeAttachmentsPopup";
import ConceptionAttachmentsPopup from "../../../popups/trainingPopups/ConceptionAttachementsPopup";
import DealTrainingPopup from '../../../popups/trainingPopups/DealTrainingPopup';
import SatisfactionAttachmentsPopup from "../../../popups/trainingPopups/SatisfactionAttachementsPopup";
import TrainingAttendancePopup from "../../../popups/trainingPopups/TrainingAttendancePopup";
import QualityChecklist from './QualityChecklist';
import TrainingCase from './TrainingCase';
import PageLoader from '../../../../../components/ui/PageLoader/PageLoader';
import FilePreviewPopup from '../../../popups/trainingPopups/FilePreviewPopup';
import {exportTrainingDataToCsv} from '../../../functions/exportDataToCsv';
import Popup from '../../../../../components/ui/Popup/Popup';

const CoursesSection = ({buttonsDisabled, setButtonsDisabled, userData, trainingId, cannotBeModified}) => {
    const [loading, setLoading] = useState(false);
    const [training, setTraining] = useState(null);
    const [trainingAttendances, setTrainingAttendances] = useState([]);
    const [trainingAttendanceLoading, setTrainingAttendanceLoading] = useState(false)
    const [trainingAttendanceToEdit, setTrainingAttendanceToEdit] = useState(null);
    const [deleteDateLoading, setDeleteDateLoading] = useState(null);
    const [datePicker, setDatePicker] = useState({
        startDate: firstDayMonth(new Date()),
        endDate: lastDayMonth(new Date())
    });

    // Pagination states
    const [trainingAttendancePaginationMetadata, setTrainingAttendancePaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 5
    });

    // Training Popups states
    const [showDealTrainingPopup, setShowDealTrainingPopup] = useState(false);
    const [showTrainingAttendancePopup, setShowTrainingAttendancePopup] = useState(false);
    const [showAdministrativePopup, setShowAdministrativePopup] = useState(false);
    const [showConceptionPopup, setShowConceptionPopup] = useState(false);
    const [showSatisfactionPopup, setShowSatisfactionPopup] = useState(false);
    
    // Training Attachements states
    const [proposalAttachements, setProposalAttachements] = useState([]);
    const [agreementAttachements, setAgreementAttachements] = useState([]);
    const [otherAdminAttachements, setOtherAdminAttachements] = useState([]);
    const [teachingAidAttachements, setTeachingAidAttachements] = useState([]);
    const [teachingResourcesAttachements, setTeachingResourcesAttachements] = useState([]);
    const [evaluationAttachements, setEvaluationAttachements] = useState([]);
    const [satisfactionAttachements, setSatisfactionAttachements] = useState([]);
    const [assessmentAttachements, setAssessmentAttachements] = useState([]);
    const [otherSatisfactionAttachements, setOtherSatisfactionAttachements] = useState([]);
    const [selectedFileUrl, setSelectedFileUrl] = useState('');
    const [showPreviewFilePopup, setShowPreviewFilePopup] = useState(false);

    // Training Dawnload Csv states
    const [csvExportLoading, setCsvExportLoading] = useState(false);
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });
    
    useEffect(() => {
        if(!showTrainingAttendancePopup) setTrainingAttendanceToEdit(null);
    }, [showTrainingAttendancePopup])

    useEffect(() => {
        if (!showPreviewFilePopup) setSelectedFileUrl('');
    }, [showPreviewFilePopup])

    useEffect(() => {
        if (!trainingId) return;
        (async () => {
            setLoading(true);
            await refreshTrainingData();
            await refreshTrainingAttachmentsData();
            setLoading(false);
        })();
    }, [trainingId]);
    
    useEffect(() => {
        if (!trainingId) return;
        handleGetTrainingAttendancesData(1);
    }, [trainingId, datePicker]);

    const handleDatePickerChange = (e) => {
        setDatePicker({
            startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
            endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
        })
    };

    const handleRefreshTrainingAttendancesData = async (removal) => {
        const checkedPage = (trainingAttendances.length === 1 && trainingAttendancePaginationMetadata.currentPage > 1 && removal) ?
            trainingAttendancePaginationMetadata.currentPage - 1 :
            trainingAttendancePaginationMetadata.currentPage;
            
        await handleGetTrainingAttendancesData(checkedPage, false);
    }

    const handleEditTrainingAttendance = (item) => {
        setTrainingAttendanceToEdit(item);
        setShowTrainingAttendancePopup(true);
    }
    
    const handleDeleteTrainingAttendance = async (id) => {
        setButtonsDisabled(true);
        setDeleteDateLoading(id);
        
        const result = await TrainingAttendancesActions.deleteTrainingAttendance(userData, id);
        
        if (!result.success) {
            notify('Erreur lors de la suppression de la formation');
        }
        else {
            notify('Formation supprimée avec succès');
            await handleRefreshTrainingAttendancesData(true);
        }
        
        setDeleteDateLoading(null);
        setButtonsDisabled(false);
        
    };

    const refreshTrainingData = async () => {
        const result = await TrainingActions.getTrainingById(userData, trainingId);

        if (!result.success) {
            notify('Erreur lors du chargement des données de formation');
        }
        else {
            setTraining(result.data);
        }
    }

    const handleDeleteTrainingAttachment = async (id) => {
        const result = await TrainingAttachmentActions.deleteTrainingAttachment(userData, id);

        if (!result.success) {
            notify('Erreur lors de la suppression du document');
        }
        else {
            notify('Le document a été supprimée avec succès');
            await refreshTrainingAttachmentsData();
        }
    }

    const attachmentTypes = {
        proposal: setProposalAttachements,
        agreement: setAgreementAttachements,
        other_admin: setOtherAdminAttachements,
        teaching_aid: setTeachingAidAttachements,
        teaching_resource: setTeachingResourcesAttachements,
        evaluation: setEvaluationAttachements,
        satisfaction_survey: setSatisfactionAttachements,
        assessment: setAssessmentAttachements,
        other_satisfaction: setOtherSatisfactionAttachements
    };
    
    const refreshTrainingAttachmentsData = async () => {
        const result = await TrainingAttachmentActions.getAllTrainingAttachments(userData, `&training_id=${trainingId}`);
    
        if (!result.success) {
            notify('Erreur lors du chargement des documents de formation');

            return;
        }
      
        let newAttachments = {};
    
        result.data.results.forEach(item => {
            if (!newAttachments[item.type]) {
                newAttachments[item.type] = [];
            }
            newAttachments[item.type].push(item);
        });
        
        for (let type in attachmentTypes) {
            if (newAttachments[type]) {
                attachmentTypes[type](newAttachments[type]);
            }
            else {
                attachmentTypes[type]([]);
            }
        }
    }

    const handleNewDateButtonClick = () => {
        setTrainingAttendanceToEdit(null);
        setShowTrainingAttendancePopup(true);
    }
    
    const handleGetTrainingAttendancesData = async (page, activeLoading = true) => {
        if(activeLoading) {
            setTrainingAttendanceLoading(true);
            setTrainingAttendances([]);
        }
        
        const result = await TrainingAttendancesActions.getAllTrainingAttendances(
            userData,
            `&before=${standardFormat(datePicker.endDate)}
            &after=${standardFormat(datePicker.startDate)}
            &training_id=${trainingId}
            &page_size=${trainingAttendancePaginationMetadata.pageSize}&page=${page}`);
        
        if (!result.success) {
            notify('Erreur lors du chargement des données de formation');
        }
        else {
            setTrainingAttendances(result.data.results);
            setTrainingAttendancePaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (trainingAttendancePaginationMetadata.totalItems !== result.metadata.count) {
                setTrainingAttendancePaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }

        setTrainingAttendanceLoading(false);
    }

    const handleClickFile = (url) => {
        setSelectedFileUrl(url);
        setShowPreviewFilePopup(true);
    }

    const trainingAttendancesConfig = traininAttendancesTableConfig(
        handleEditTrainingAttendance,
        handleDeleteTrainingAttendance,
        buttonsDisabled,
        deleteDateLoading,
        handleClickFile,
        cannotBeModified
    );

    const handleExportTrainingAttendancesDataToCsv = () => exportTrainingDataToCsv(
        trainingAttendances,
        setCsvProps,
        setCsvExportLoading,
        datePicker
    );

    return (
        <>
            <div className='flex justify-center'>
                <PageLoader loading={loading}/>
            </div>
            <div>
                <TrainingCase training={training} setShowDealTrainingPopup={setShowDealTrainingPopup}/>
                <div className='flex justify-between gap-x-8'>
                    <QualityChecklist
                        setView={setShowAdministrativePopup}
                        handleDelete={handleDeleteTrainingAttachment}
                        title='Checklist qualité - Administratif'
                        onClick={handleClickFile}
                        data={[
                            {title: 'Proposition commerciale', data: proposalAttachements},
                            {title: 'Convention signée avec le client', data: agreementAttachements},
                            {title: 'Autres', data: otherAdminAttachements}
                        ]}
                        cannotBeModified={cannotBeModified}
                    />
                    <QualityChecklist
                        setView={setShowConceptionPopup}
                        handleDelete={handleDeleteTrainingAttachment}
                        title='Checklist qualité - Conception'
                        onClick={handleClickFile}
                        data={[
                            {title: 'Supports pédagogiques', data: teachingAidAttachements},
                            {title: 'Ressources pédagogiques', data: teachingResourcesAttachements}
                        ]}
                        cannotBeModified={cannotBeModified}
                    />
                </div>
                <ContainerCard
                    title='Checklist qualité - Suivi des stagiaires'
                    buttonContent='Nouvelle date'
                    onButtonClick={handleNewDateButtonClick}
                    blueStyle={true}
                    margin='mt-6'
                    downloadButtonDisplayed={(trainingAttendances.length > 0 && !csvExportLoading)}
                    onDownloadButtonClick={handleExportTrainingAttendancesDataToCsv}
                    csvProps={csvProps}
                    csvExportLoading={csvExportLoading}
                    coloredButtonDisplayed={cannotBeModified}
                >
                    <Datepicker
                        type="range"
                        onChange={handleDatePickerChange}
                        defaultValue={datePicker}
                        blueStyle={true}
                    />
                    <div className='mt-6'/>
                    <Table
                        data={trainingAttendances}
                        tableConfig={trainingAttendancesConfig}
                        paginationMetadata={trainingAttendancePaginationMetadata}
                        onPageNumberChange={handleGetTrainingAttendancesData}
                        loading={trainingAttendanceLoading}
                        blueStyle={true}
                    />
                </ContainerCard>
                <div className='pr-6 mt-6'>
                    <QualityChecklist
                        setView={setShowSatisfactionPopup}
                        handleDelete={handleDeleteTrainingAttachment}
                        title='Checklist qualité - Satisfaction et bilan'
                        onClick={handleClickFile}
                        height='h-fit'
                        data={[
                            {title: 'Évaluation', data: evaluationAttachements},
                            {title: 'Questionnaire de satisfaction', data: satisfactionAttachements},
                            {title: 'Bilan de la formation', data: assessmentAttachements},
                            {title: 'Autres', data: otherSatisfactionAttachements}
                        ]}
                        cannotBeModified={cannotBeModified}
                    />
                </div>
            </div>
            <Popup
                view={showDealTrainingPopup}
                setView={setShowDealTrainingPopup}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
            >
                <DealTrainingPopup
                    userData={userData}
                    view={showDealTrainingPopup}
                    setView={setShowDealTrainingPopup}
                    trainingData={training}
                    refreshData={refreshTrainingData}
                />
            </Popup>
            <Popup
                view={showTrainingAttendancePopup}
                setView={setShowTrainingAttendancePopup}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
                maxHeight='45rem'
            >
                <TrainingAttendancePopup
                    userData={userData}
                    setView={setShowTrainingAttendancePopup}
                    trainingId={training?.id}
                    trainingAttendanceToEdit={trainingAttendanceToEdit}
                    refreshData={handleRefreshTrainingAttendancesData}
                />
            </Popup>
            <Popup
                view={showAdministrativePopup}
                setView={setShowAdministrativePopup}
                width={'1098px'}
            >
                <AdministrativeAttachmentsPopup
                    setView={setShowAdministrativePopup}
                    userData={userData}
                    trainingId={training?.id}
                    refreshData={refreshTrainingAttachmentsData}
                    proposalAttachements={proposalAttachements}
                    agreementAttachements={agreementAttachements}
                    otherAdminAttachements={otherAdminAttachements}
                />
            </Popup>
            <Popup
                view={showConceptionPopup}
                setView={setShowConceptionPopup}
                width={'1098px'}
            >
                <ConceptionAttachmentsPopup
                    userData={userData}
                    setView={setShowConceptionPopup}
                    trainingId={training?.id}
                    refreshData={refreshTrainingAttachmentsData}
                    teachingAidAttachements={teachingAidAttachements}
                    teachingResourcesAttachements={teachingResourcesAttachements}
                />
            </Popup>
            <Popup
                view={showSatisfactionPopup}
                setView={setShowSatisfactionPopup}
                width={'1098px'}
            >
                <SatisfactionAttachmentsPopup
                    userData={userData}
                    setView={setShowSatisfactionPopup}
                    trainingId={training?.id}
                    refreshData={refreshTrainingAttachmentsData}
                    evaluationAttachements={evaluationAttachements}
                    satisfactionAttachements={satisfactionAttachements}
                    otherSatisfactionAttachements={otherSatisfactionAttachements}
                    assessmentAttachements={assessmentAttachements}
                />
            </Popup>
            <Popup
                view={showPreviewFilePopup}
                setView={setShowPreviewFilePopup}
                maxheight='h-[400px]'
            >
                <FilePreviewPopup
                    view={showPreviewFilePopup}
                    setView={setShowPreviewFilePopup}
                    url={selectedFileUrl}
                />
            </Popup>
        </>
    )
}

export default CoursesSection;
