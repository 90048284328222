import {DashboardActions} from "../../../actions";
import {standardFormat, firstDayMonth, lastDayMonth} from "../../../utils";

export const getResultIndicatorsData = async (
    userData,
    notify,
    resultIndicatorsDatePicker,
    setResultIndicators
) => {
    const resultIndicatorsResult = await DashboardActions.CodevActions.getResults(
        userData,
        `?after=${standardFormat(resultIndicatorsDatePicker.startDate)}&before=${standardFormat(resultIndicatorsDatePicker.endDate)}`
    );

    if (resultIndicatorsResult.success) {
        setResultIndicators(resultIndicatorsResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des indicateurs de résultat");
    }
}

export const handleResultIndicatorsDatePickerChange = async (
    e,
    userData,
    notify,
    setLoading,
    setResultIndicatorsDatePicker,
    setResultIndicators
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setResultIndicatorsDatePicker(dates);

    const resultIndicatorsResult = await DashboardActions.CodevActions.getResults(
        userData,
        `?after=${standardFormat(dates.startDate)}&before=${standardFormat(dates.endDate)}`
    );

    if (resultIndicatorsResult.success) {
        setResultIndicators(resultIndicatorsResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des indicateurs de résultat");
    }

    setLoading(false);
};
