import Table from '../../../components/ui/Table';
import {expenseReportRepartitionTableConfig} from './expenseReportRepartitionTableConfig.js';

export const expenseReportTabCardConfig = (data) => {
    const dealTableConfig = expenseReportRepartitionTableConfig({label: 'Affaire', value: 'deal'});
    const imputationTableConfig = expenseReportRepartitionTableConfig({label: 'Imputation comptable', value: 'name'});
    const natureTableConfig = expenseReportRepartitionTableConfig({label: 'Nature', value: 'group'});

    const dealGroup = [];
    const imputationGroup = [];
    const natureGroup = [];

    // this code reagregates the data by deal and by imputation group
    if (data && data.length > 0) {
        data.forEach((item) => {
            const existingDeal = dealGroup.find((uniqueDeal) => uniqueDeal.id === item.deal.id);
            const existingImputation = imputationGroup.find((uniqueImputation) => uniqueImputation.name === item.type.accounting);
            const existingNature = natureGroup.find((uniqueImputation) => uniqueImputation.group === item.type.group);

            if (!existingDeal) {
                dealGroup.push({
                    id: item.deal.id,
                    deal: item.deal.short_name,
                    vat: item.vat,
                    amount: item.amount
                });
            }
            else {
                existingDeal.amount += item.amount;
                existingDeal.vat += item.vat;
            }

            if (!existingImputation) {
                imputationGroup.push({
                    id: item.type.id,
                    group: item.type.accounting,
                    vat: item.vat,
                    amount: item.amount,
                    name: item.type.accounting
                });
            }
            else {
                existingImputation.amount += item.amount;
                existingImputation.vat += item.vat;
            }

            if (!existingNature) {
                natureGroup.push({
                    id: item.type.id,
                    group: item.type.group,
                    vat: item.vat,
                    amount: item.amount,
                    name: item.type.group
                });
            }
            else {
                existingNature.amount += item.amount;
                existingNature.vat += item.vat;
            }
        });
    }

    return (
        {
            width: 'w-1/2',
            height: 'h-[30em]',
            title: 'Répartitions',
            childOverflow: 'overflow-y-auto',
            tapSelectorPosition: 'self-start',
            tabs: [
                {
                    id: 1,
                    label: 'Par Affaire',
                    component: <Table data={dealGroup} tableConfig={dealTableConfig}/>

                },
                {
                    id: 2,
                    label: 'Par imputation comptable',
                    component: <Table data={imputationGroup} tableConfig={imputationTableConfig}/>

                },
                {
                    id: 3,
                    label: 'Par nature',
                    component: <Table data={natureGroup} tableConfig={natureTableConfig}/>

                }
            ]
        }
    );
};
