import {Link} from 'react-router-dom';
import {SnippetsFilled, SmileFilled, FrownFilled, TrophyFilled} from '@ant-design/icons';

import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import Badge from '../../../../components/ui/Badge/Badge';
import {transformThousandNumbers} from '../../../../utils';

const TradeReportProposalsCards = ({
    doneSeulCount,
    doneMandataireCount,
    doneCotraitanceCount,
    doneSeulDays,
    doneMandataireDays,
    doneCotraitanceDays,
    doneSeulValue,
    doneMandataireValue,
    doneCotraitanceValue,
    wonSeulCount,
    wonMandataireCount,
    wonCotraitanceCount,
    wonSeulDays,
    wonMandataireDays,
    wonCotraitanceDays,
    wonSeulValue,
    wonMandataireValue,
    wonCotraitanceValue,
    lostSeulCount,
    lostMandataireCount,
    lostCotraitanceCount,
    lostSeulDays,
    lostMandataireDays,
    lostCotraitanceDays,
    lostSeulValue,
    lostMandataireValue,
    lostCotraitanceValue,
    successRateSeulCount,
    successRateMandataireCount,
    successRateCotraitanceCount,
    successRateSeulDays,
    successRateMandataireDays,
    successRateCotraitanceDays,
    successRateSeulValue,
    successRateMandataireValue,
    successRateCotraitanceValue
}) => {
    const SnippetIcon = <SnippetsFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;
    const SmileIcon = <SmileFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;
    const FrownIcon = <FrownFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;
    const TrophyIcon = <TrophyFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    let successRate = (+((wonSeulCount + wonMandataireCount + wonCotraitanceCount) / (doneSeulCount + doneMandataireCount + doneCotraitanceCount)  * 100).toFixed(1));
    successRate = (isNaN(successRate) || successRate === Infinity) ? 0 : successRate;

    return (
        <div  className='flex flex-wrap justify-between gap-y-5'>
            <ContainerCard showHeader={false} width='w-[49%]'>
                <Link to='proposals-made'>
                    <SimpleCard
                        width='w-full'
                        background='white'
                        font='orange'
                        showLine={false}
                        shadow={false}
                        borderSize='border'
                        title={doneSeulCount + doneMandataireCount + doneCotraitanceCount}
                        subTitle={'Propositions faites'}
                        iconComponent={SnippetIcon}
                        topComponent={<TopComponent
                            seul={doneSeulCount}
                            mandataire={doneMandataireCount}
                            cotraitance={doneCotraitanceCount}
                        />}
                        bottomComponent={<BottomComponent
                            daySeul={doneSeulDays}
                            dayMandataire={doneMandataireDays}
                            dayCotraitance={doneCotraitanceDays}
                            eurosSeul={doneSeulValue}
                            eurosMandataire={doneMandataireValue}
                            eurosCotraitance={doneCotraitanceValue}
                            daySum={+(doneSeulDays + doneMandataireDays + doneCotraitanceDays).toFixed(2)}
                            eurosSum={+(doneSeulValue + doneMandataireValue + doneCotraitanceValue).toFixed(2)}
                        />}
                    />
                </Link>
            </ContainerCard>

            <ContainerCard showHeader={false} width='w-[49%]'>
                <Link to='proposals-won'>
                    <SimpleCard
                        width='w-full'
                        background='white'
                        font='orange'
                        showLine={false}
                        shadow={false}
                        borderSize='border'
                        title={wonSeulCount + wonMandataireCount + wonCotraitanceCount}
                        subTitle={'Propositions gagnées'}
                        iconComponent={SmileIcon}
                        topComponent={<TopComponent
                            seul={wonSeulCount}
                            mandataire={wonMandataireCount}
                            cotraitance={wonCotraitanceCount}
                        />}
                        bottomComponent={<BottomComponent
                            daySeul={wonSeulDays}
                            dayMandataire={wonMandataireDays}
                            dayCotraitance={wonCotraitanceDays}
                            eurosSeul={wonSeulValue}
                            eurosMandataire={wonMandataireValue}
                            eurosCotraitance={wonCotraitanceValue}

                            daySum={+(wonSeulDays + wonMandataireDays + wonCotraitanceDays).toFixed(2)}
                            eurosSum={+(wonSeulValue + wonMandataireValue + wonCotraitanceValue).toFixed(2)}
                        />}
                    />
                </Link>
            </ContainerCard>

            <ContainerCard showHeader={false} width='w-[49%]'>
                <Link to='proposals-lost'>
                    <SimpleCard
                        width='w-full'
                        background='white'
                        font='orange'
                        showLine={false}
                        shadow={false}
                        borderSize='border'
                        title={lostSeulCount + lostMandataireCount + lostCotraitanceCount}
                        subTitle={'Propositions perdues'}
                        iconComponent={FrownIcon}
                        topComponent={<TopComponent
                            seul={lostSeulCount}
                            mandataire={lostMandataireCount}
                            cotraitance={lostCotraitanceCount}
                        />}
                        bottomComponent={<BottomComponent
                            daySeul={lostSeulDays}
                            dayMandataire={lostMandataireDays}
                            dayCotraitance={lostCotraitanceDays}
                            eurosSeul={lostSeulValue}
                            eurosMandataire={lostMandataireValue}
                            eurosCotraitance={lostCotraitanceValue}
                            daySum={+(lostSeulDays + lostMandataireDays + lostCotraitanceDays).toFixed(2)}
                            eurosSum={+(lostSeulValue + lostMandataireValue + lostCotraitanceValue).toFixed(2)}
                        />}
                    />
                </Link>
            </ContainerCard>

            <ContainerCard showHeader={false} width='w-[49%]'>
                <SimpleCard
                    width='w-full'
                    background='white'
                    font='orange'
                    showLine={false}
                    shadow={false}
                    borderSize='border'
                    title={`${successRate} %`}
                    subTitle={'Taux de réussite'}
                    iconComponent={TrophyIcon}
                    topComponent={<TopComponent
                        seul={`${(successRateSeulCount).toFixed(0)} %`}
                        mandataire={`${(successRateMandataireCount).toFixed(0)} %`}
                        cotraitance={`${(successRateCotraitanceCount).toFixed(0)} %`}
                    />}
                    bottomComponent={<BottomComponent
                        hideAverage={true}
                        daySeul={successRateSeulDays}
                        dayMandataire={successRateMandataireDays}
                        dayCotraitance={successRateCotraitanceDays}
                        eurosSeul={successRateSeulValue}
                        eurosMandataire={successRateMandataireValue}
                        eurosCotraitance={successRateCotraitanceValue}
                        daySum={+(successRateSeulDays + successRateMandataireDays + successRateCotraitanceDays).toFixed(2)}
                        eurosSum={+(successRateSeulValue + successRateMandataireValue + successRateCotraitanceValue) / 3}
                    />}
                />
            </ContainerCard>
        </div>
    )
}

export default TradeReportProposalsCards;

const TopComponent = ({seul, mandataire, cotraitance}) => {
    return (
        <div className='px-6'>
            <div className='flex justify-start bg-white gap-x-1'>
                <Badge color={'#f0a841'} content={`Seul: ${seul}`}/>
                <Badge color={'#dc3832'} content={`Mandataire: ${mandataire}`}/>
                <Badge color={'#646464'} content={`Cotraitance: ${cotraitance}`}/>
            </div>
            <div className='h-[1px] w-full bg-slate-800 mt-2 mb-8'/>
        </div>
    )
}

const BottomComponent = ({hideAverage, daySeul, dayMandataire, dayCotraitance, eurosSeul, eurosMandataire, eurosCotraitance, daySum, eurosSum}) => {
    let averageTotal = ((eurosSeul + eurosMandataire + eurosCotraitance) / (daySeul + dayMandataire + dayCotraitance)).toFixed(0);
    averageTotal = (isNaN(averageTotal) || averageTotal === Infinity) ? 0 : averageTotal;

    let averageSeul = (eurosSeul / daySeul).toFixed(0);
    averageSeul = (isNaN(averageSeul) || averageSeul === Infinity) ? 0 : averageSeul;

    let averageMandataire = (eurosMandataire / dayMandataire).toFixed(0);
    averageMandataire = (isNaN(averageMandataire) || averageMandataire === Infinity) ? 0 : averageMandataire;

    let averageCotraitance = (eurosCotraitance / dayCotraitance).toFixed(0);
    averageCotraitance = (isNaN(averageCotraitance) || averageCotraitance === Infinity) ? 0 : averageCotraitance;

    return (
        <div className='flex justify-between bg-white border-t border-gray-200 rounded-b-md'>
            <div className='w-1/3 px-5 py-5'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{daySum}</p>
                <p className='text-gradient'>Jours</p>
                <div className='w-full bg-slate-800 h-[1px] my-2'/>
                <div>
                    <Badge color={'#f0a841'} content={`Seul: ${daySeul}`}/>
                    <Badge color={'#dc3832'} content={`Mandataire: ${dayMandataire}`}/>
                    <Badge color={'#646464'} content={`Cotraitance: ${dayCotraitance}`}/>
                </div>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/3 px-5 py-5'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{transformThousandNumbers(eurosSum)} </p>
                <p className='text-gradient'>Euros</p>
                <div className='w-full bg-slate-800 h-[1px] my-2'/>
                <div>
                    <Badge color={'#f0a841'} content={`Seul: ${transformThousandNumbers(eurosSeul)}`}/>
                    <Badge color={'#dc3832'} content={`Mandataire: ${transformThousandNumbers(eurosMandataire)}`}/>
                    <Badge color={'#646464'} content={`Cotraitance: ${transformThousandNumbers(eurosCotraitance)}`}/>
                </div>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            {!hideAverage && <div className='w-1/3 px-5 py-5'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>
                    {averageTotal}
                </p>
                <p className='text-gradient'>Valeur moyenne</p>
                <div className='w-full bg-slate-800 h-[1px] my-2'/>
                <div>
                    <Badge color={'#f0a841'} content={`Seul: ${averageSeul}`}/>
                    <Badge color={'#dc3832'} content={`Mandataire: ${averageMandataire}`}/>
                    <Badge color={'#646464'} content={`Cotraitance: ${averageCotraitance}`}/>
                </div>
            </div>}
        </div>
    )
}
