import {NotificationDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_DETAILS_NOTIFICATION';

export async function getDetails(userData: Record<string, string>) {
    try {
        const result = await NotificationDao.getDetails(userData);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération du details des notifications',
            error: error
        });
    }
}
