import * as z from 'zod';
import * as contractorFunctions from './functions';

export const ContractorSchema = z.object({
    'id': z.number(),
    "location": z.string(),
    "name": z.string(),
    "contacts": z.array(z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string()
    }).optional()),
    "notes": z.string(),
    "internal_contacts": z.array(z.object({
        "id": z.number(),
        "username": z.string(),
        "first_name": z.string(),
        "last_name": z.string(),
        "user_class": z.string()
    }).optional()),
    "categories": z.array(z.string().optional())
});

export const ContractorSchemaPartialSchema = ContractorSchema.partial();
export type ContractorPartial = z.infer<typeof ContractorSchemaPartialSchema>;
export type Contractor = z.infer<typeof ContractorSchema>;

export function newContractor(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...ContractorSchema.parse({...data}),
        ...contractorFunctions
    };
}

export function parseContractor(data: any, partial = false) {
    if (!partial) {
        return {
            ...ContractorSchema.parse(data),
            ...contractorFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...ContractorSchema.parse(data),
            ...contractorFunctions
        };
    }
}
