import { useState } from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {notify} from '../../components/ui/Toast/Toast';

import Table from '../../components/ui/Table';
import {contractorsTableConfig} from './config/contractorsTableConfig';
import {InvoiceActions, ContractorInvoiceActions} from '../../actions';

const ContractorInvoicesToPayPage = ({
    data,
    refreshData,
    userData,
    tableType,
    filterContractorInvoiceList,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
    
    const handleDeleteInvoice = async (contractorInvoice) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${contractorInvoice.id}del`);

        const attachmentInvoice = await InvoiceActions.getInvoiceById(userData, contractorInvoice.invoice.id);

        const deleteInvoiceContractorResult = await InvoiceActions.deleteInvoiceContractor(userData, attachmentInvoice.data, contractorInvoice.id);

        if (deleteInvoiceContractorResult.success) {
            await refreshData(true);
            notify("La facture a été supprimée avec succès");
        }
        
        setActionButtonDisabled(false);
        setActionButtonLoading(null);
    }
    
    const handlePayInvoice = async (contractorInvoice) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${contractorInvoice.id}upd`);

        const upInvoiceContractorResult = await ContractorInvoiceActions.updateContractorInvoice(userData, {paid: true}, contractorInvoice.id);

        if (upInvoiceContractorResult.success) {
            await refreshData(true);
            notify("La facture a été payée avec succès");
        }

        setActionButtonDisabled(false);
        setActionButtonLoading(null);
    }
    
    const displayedSection = "Factures fournisseurs à payer";
    
    const tableConfig = contractorsTableConfig({
        displayedSection,
        handleDeleteInvoice,
        handlePayInvoice,
        actionButtonLoading,
        actionButtonDisabled,
        userData,
        tableType,
        filterContractorInvoiceList
    });

    return (
        <>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures fournisseurs à payer (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default ContractorInvoicesToPayPage;
