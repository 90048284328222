import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'DAO_EXAMPLE';

export async function deleteAttachmentType(userToken: string, id: string) {
    try {

        const response = await axios.delete(
            `${URL}/attachment-types/${id}/`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
