import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ReferencesPage from '../pages/referencePage/ReferencesPage';

function ReferencesRoute() {
    return (
        <Routes>
            <Route path='/' element={<ReferencesPage/>}>

            </Route>
        </Routes>

    );
}

export default ReferencesRoute;
