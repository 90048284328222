import { useState, useEffect } from 'react';
import {useForm, Controller} from 'react-hook-form';

import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import { ReferenceActions } from './../../../../actions';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const AssignAReferentPopup = ({
    userData,
    dealData,
    setView,
    refreshData,
    reference,
    blueStyle
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [producers, setProducers] = useState([]);
    const [referentId, setReferentId] = useState(null);
    const [errors, setErrors] = useState(null);

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: {ressource: null}});

    useEffect(() => {
        if (dealData.producers) {
            const producers = dealData.producers.map((item) => ({
                ...item, name: item.first_name + ' ' + item.last_name
            }))

            if (reference && reference.owner) {
                const selectedProducer = producers.find((item) => item.id === reference.owner.id);
                reset({ressource: selectedProducer});
            }
            else {
                setReferentId(null);
                reset({ressource: null});
            }

            setProducers(producers);
        }

        if (reference) {
            setReferentId(reference.id);
        }
    }, [dealData, reference]);

    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const payload = {
            deal_id: dealData.id,
            owner_id: data.ressource.id
        }

        if (isValid) {
            let result;

            if (referentId) {
                result = await ReferenceActions.updateReference(userData, payload, referentId);
            }
            else {
                result = await ReferenceActions.createReference(userData, payload);
            }

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify(`Le réferent a été ${reference ? 'modifier' : 'sélectionné'} avec succès.`);
                setView(false);
            }
        }

        setDisabled(false);
        setLoading(false);
    }

    return (
        <ContainerCard
            title={`${reference ? 'Modifier' : 'Choisir'} un référent`}
            coloredButtonDisplayed={false}
        >
            <div className="flex flex-col justify-between w-1/2 h-40 mb-2">
                <Controller
                    name="ressource"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez sélectionner un référent'
                    }}}
                    render={({
                        field: {onChange, value, ref},
                        fieldState: {error}
                    }) => (
                        <SelectInput
                            label='Référent'
                            placeholder='Référent'
                            inputRef={ref}
                            value={value}
                            error={error}
                            options={producers}
                            onChange={onChange}
                            loading={!producers}
                            isSearchable={true}
                            labelKeys={['name']}
                            required={true}
                            disabled={disabled}
                            blueStyle={blueStyle}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={`${reference ? 'Modifier' : 'Ajouter'} le référent`}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default AssignAReferentPopup;