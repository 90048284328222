import {useEffect, useState} from "react";
import {useSelector} from "react-redux"
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {FireOutlined, LikeOutlined, PieChartOutlined, TeamOutlined} from '@ant-design/icons';

import Layout from "../../components/layout/Layout";
import PageLoader from '../../components/ui/PageLoader/PageLoader';
import {notify, Toast} from '../../components/ui/Toast/Toast';
import TabNavigator from "../../components/ui/navigation/TabNavigator";
import CommercialTab from "./components/commercialTab/CommercialTab";
import ProductionTab from "./components/productionTab/ProductionTab";
import RessourceManagementTab from "./components/ressourceManagementTab/RessourceManagementTab";
import ResultIndicatorTab from "./components/resultIndicatorTab/ResultIndicatorTab";
import SpecificationToStudyPage from "./SpecificationToStudyPage";
import ProposalsBeingWrittenPage from "./ProposalsBeingWrittenPage";
import ProposalsPendingPage from "./ProposalsPendingPage";
import CurrentDeals from "./CurrentDeals";
import CurrentMissions from "./CurrentMissions";
import {firstDayYear, lastDayYear} from '../../utils';
import {getCommercialTabData, handleCommercialBalanceDatePickerChange} from "./functions/commercialTabData";
import {getProductionTabData, handleLoadIndicatorsDatePickerChange} from "./functions/productionTabData";
import {getResourceManagementTabData, handleExternalRessourcesDatePickerChange} from "./functions/resourceManagementTabData";
import {getResultIndicatorsData, handleResultIndicatorsDatePickerChange} from "./functions/resultIndicatorsTabData";
import {DealActions, MissionActions} from '../../actions';
import SpecificationsStudiedPage from "./SpecificationsStudiedPage";
import ProposalsMadePage from "./ProposalsMadePage";
import ProposalsWonPage from "./ProposalsWonPage";
import ProposalsLost from "./ProposalsLost";

const CodevDashboardPage = () => {
    const userData = useSelector((state) => state.UserReducer.userProfileData);
    const das = useSelector((state) => state.DasReducer.das);
    const navigate = useNavigate();
    const location = useLocation();

    // ↓ states
    const [displayedSection, setDisplayedSection] = useState('Commercial');
    const [loading, setLoading] = useState(true);
    const [productionLoading, setProductionLoading] = useState(true);

    // ↓ table data states
    const [specificationsToStudy, setSpecificationsToStudy] = useState([]);
    const [proposalsBeingWritten, setProposalsBeingWritten] = useState([]);
    const [proposalsPending, setProposalsPending] = useState([]);
    const [currentDeals, setCurrentDeals] = useState([]);
    const [missions, setMissions] = useState([]);

    // Filters States
    const [specificationsToStudyFilter, setSpecificationsToStudyFilter] = useState('');
    const [proposalsBeingWrittenFilter, setProposalsBeingWrittenFilter] = useState('');
    const [proposalsPendingFilter, setProposalsPendingFilter] = useState('');
    const [currentDealsFilter, setCurrentDealsFilter] = useState('');
    const [missionsFilter, setMissionsFilter] = useState('');

    // Pagination States
    const [specificationsToStudyPaginationMetadata, setSpecificationsToStudyPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    
    const [proposalsBeingWrittenPaginationMetadata, setProposalsBeingWrittenPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    
    const [proposalsPendingPaginationMetadata, setProposalsPendingPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    
    const [currentDealsPaginationMetadata, setCurrentDealsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    
    const [missionsPaginationMetadata, setMissionsPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });

    // Table Loading States
    const [specificationsToStudyTableLoading, setSpecificationsToStudyTableLoading] = useState(false);
    const [proposalsBeingWrittenTableLoading, setProposalsBeingWrittenTableLoading] = useState(false);
    const [proposalsPendingTableLoading, setProposalsPendingTableLoading] = useState(false);
    const [currentDealsTableLoading, setCurrentDealsTableLoading] = useState(false);
    const [missionsTableLoading, setMissionTableLoading] = useState(false);

    // dasKey per Table
    const [specificationsToStudyDasKey, setSpecificationsToStudyDasKey] = useState('');
    const [proposalsBeingWrittenDasKey, setProposalsBeingWrittenDasKey] = useState('');
    const [proposalsPendingDasKey, setProposalsPendingDasKey] = useState('');
    const [currentDealsDasKey, setCurrentDealsDasKey] = useState('');
    const [missionsDasKey, setMissionsDasKey] = useState('');

    // ↓ «Commercial» TAB STATES
    const [salesActivity, setSalesActivity] = useState({});
    const [salesOverview, setSalesOverview] = useState({});
    const [commercialBalanceDatePicker, setCommercialBalanceDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ «Production» TAB STATES
    const [productionActivity, setProductionActivity] = useState({});
    const [dasUsers, setDasUsers] = useState({});
    const [productionLoad, setProductionLoad] = useState({});
    const [loadIndicatorsDatePicker, setLoadIndicatorsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ «Gestion des ressources» TAB STATES
    const [ressources, setRessources] = useState({});
    const [externalRessources, setExternalRessources] = useState({});
    const [externalRessourcesDatePicker, setExternalRessourcesDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ «Indicateurs de résultat» TAB STATES
    const [resultIndicators, setResultIndicators] = useState([]);
    const [resultIndicatorsDatePicker, setResultIndicatorsDatePicker] = useState({
        startDate: firstDayYear(new Date()),
        endDate: lastDayYear(new Date())
    });

    // ↓ Tabs navigation
    const productionIcon = <FireOutlined
        style={{ fontSize: '13px', color: displayedSection === 'Production' ? '#DC3832' : '#646464' }}
    />;
    const pieChartIcon = <PieChartOutlined
        style={{ fontSize: '13px', color: displayedSection === 'Commercial' ? '#DC3832' : '#646464' }}
    />;
    const teamIcon = <TeamOutlined
        style={{ fontSize: '13px', color: displayedSection === 'Gestion des ressources' ? '#DC3832' : '#646464' }}
    />;
    const likeOutIcon = <LikeOutlined
        style={{ fontSize: '13px', color: displayedSection === 'Indicateurs de résultat' ? '#DC3832' : '#646464' }}
    />;

    const sections = [
        {name: "Commercial", icon: pieChartIcon},
        {name: "Production", icon: productionIcon},
        {name: "Gestion des ressources", icon: teamIcon},
        {name: "Indicateurs de résultat", icon: likeOutIcon}
    ];

    // Redirection pour éviter le bug de la page blanche
    useEffect(() => {
        if (location.pathname !== '/dashboard/codev') {
            navigate('/dashboard/codev');
        }
    }, []);

    // Permet d'éviter la persistance des states filters une fois de retour à la page principale
    useEffect(() => {
        if (location.pathname === '/dashboard/codev') {
            if (specificationsToStudyFilter) setSpecificationsToStudyFilter('');
    
            if(proposalsBeingWrittenFilter) setProposalsBeingWrittenFilter('');
    
            if(proposalsPendingFilter) setProposalsPendingFilter('');
    
            if(currentDealsFilter) setCurrentDealsFilter('');
    
            if(missionsFilter) setMissionsFilter('');
        }
    }, [location.pathname]);

    const handleGetSpecificationsToStudyData = async (page) => {
        setSpecificationsToStudyTableLoading(true);

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${specificationsToStudyPaginationMetadata.pageSize}${specificationsToStudyDasKey && `&das=${specificationsToStudyDasKey}`}&status=150&${specificationsToStudyFilter}`,
            page
        );
    
        if (result.success) {
            setSpecificationsToStudy(result.data);
            setSpecificationsToStudyPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (specificationsToStudyPaginationMetadata.totalItems !== result.metadata.count) {
                setSpecificationsToStudyPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setSpecificationsToStudyTableLoading(false);
    };

    const handleGetProposalsBeingWrittenData = async (page) => {
        setProposalsBeingWrittenTableLoading(true);

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${proposalsBeingWrittenPaginationMetadata.pageSize}&status=200${proposalsBeingWrittenDasKey && `&das=${proposalsBeingWrittenDasKey}`}&${proposalsBeingWrittenFilter}`,
            page
        );
    
        if (result.success) {
            setProposalsBeingWritten(result.data);
            setProposalsBeingWrittenPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (proposalsBeingWrittenPaginationMetadata.totalItems !== result.metadata.count) {
                setProposalsBeingWrittenPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setProposalsBeingWrittenTableLoading(false);
    };

    const handleGetProposalsPendingData = async (page) => {
        setProposalsPendingTableLoading(true);

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${proposalsPendingPaginationMetadata.pageSize}&status=300${proposalsPendingDasKey && `&das=${proposalsPendingDasKey}`}&${proposalsPendingFilter}`,
            page
        );
    
        if (result.success) {
            setProposalsPending(result.data);
            setProposalsPendingPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (proposalsPendingPaginationMetadata.totalItems !== result.metadata.count) {
                setProposalsPendingPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setProposalsPendingTableLoading(false);
    };

    const handleGetCurrentDealsData = async (page) => {
        setCurrentDealsTableLoading(true);

        const statusExceptClosest = 'status=500';

        const result = await DealActions.getAllDeals(
            userData,
            // eslint-disable-next-line max-len
            `&type=business&page_size=${currentDealsPaginationMetadata.pageSize}&${statusExceptClosest}${currentDealsDasKey && `&das=${currentDealsDasKey}`}&${currentDealsFilter}`,
            page
        );
            
        if (result.success) {
            setCurrentDeals(result.data);
            setCurrentDealsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (currentDealsPaginationMetadata.totalItems !== result.metadata.count) {
                setCurrentDealsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setCurrentDealsTableLoading(false);
    };

    const handleFetchMissionsData = async (page) => {
        setMissionTableLoading(true);

        // eslint-disable-next-line max-len
        const result = await MissionActions.getAllMissions(
            userData,
            `&page_size=${missionsPaginationMetadata.pageSize}&status=1&type=business&deal_status=500&${missionsDasKey && `&das=${missionsDasKey}`}&${missionsFilter}`,
            page
        );

        if (result.success) {
            setMissions(result.data);
            setMissionsPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (missionsPaginationMetadata.totalItems !== result.metadata.count) {
                setMissionsPaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }
        else {
            notify("Une erreur est survenue lors de la récuperation des données des tables");
        }

        setMissionTableLoading(false);
    };

    // useEffets that fetch data for tables
    useEffect(() => {
        handleGetSpecificationsToStudyData(1);
    }, [specificationsToStudyFilter]);
    
    useEffect(() => {
        handleGetProposalsBeingWrittenData(1);
    }, [proposalsBeingWrittenFilter]);
    
    useEffect(() => {
        handleGetProposalsPendingData(1);
    }, [proposalsPendingFilter]);
    
    useEffect(() => {
        handleGetCurrentDealsData(1);
    }, [currentDealsFilter]);
       
    useEffect(() => {
        handleFetchMissionsData(1);
    }, [missionsFilter]);

    // useEffets that fetch data for tables
    useEffect(() => {
        if (specificationsToStudyFilter === '') {
            handleGetSpecificationsToStudyData(1);
        }
        else {
            setSpecificationsToStudyFilter('');
        }
    }, [specificationsToStudyDasKey]);

    useEffect(() => {
        if (proposalsBeingWrittenFilter === '') {
            handleGetProposalsBeingWrittenData(1);
        }
        else {
            setProposalsBeingWrittenFilter('');
        }
    }, [proposalsBeingWrittenDasKey]);

    useEffect(() => {
        if (proposalsPendingFilter === '') {
            handleGetProposalsPendingData(1);
        }
        else {
            setProposalsPendingFilter('');
        }
    }, [proposalsPendingDasKey]);

    useEffect(() => {
        if (currentDealsFilter === '') {
            handleGetCurrentDealsData(1);
        }
        else {
            setCurrentDealsFilter('');
        }
    }, [currentDealsDasKey]);

    useEffect(() => {
        if (missionsFilter === '') {
            handleFetchMissionsData(1);
        }
        else {
            setMissionsFilter('');
        }
    }, [missionsDasKey]);

    // ↓ «Commercial» tab get data
    useEffect(() => {
        getCommercialTabData(
            userData,
            notify,
            setLoading,
            setSalesActivity,
            commercialBalanceDatePicker,
            setSalesOverview
        );
    }, [])

    // ↓ «Production» tab get data
    useEffect(() => {
        getProductionTabData(
            userData,
            notify,
            setProductionActivity,
            setDasUsers,
            loadIndicatorsDatePicker,
            setProductionLoad,
            setProductionLoading
        );
    }, [])

    // ↓ «Gestion des ressources» tab get data
    useEffect(() => {
        getResourceManagementTabData(
            userData,
            notify,
            setRessources,
            externalRessourcesDatePicker,
            setExternalRessources
        );
    }, [])

    // ↓ «Indicateurs de résultat» tab get data
    useEffect(() => {
        getResultIndicatorsData(
            userData,
            notify,
            resultIndicatorsDatePicker,
            setResultIndicators
        );
    }, [])
    
    const handleRefreshMissionsData = async (removal = false) => {
        const checkedPage = (missions.length === 1 && missionsPaginationMetadata.currentPage > 1 && removal) ?
            missionsPaginationMetadata.currentPage - 1 :
            missionsPaginationMetadata.currentPage;
        
        await handleFetchMissionsData(checkedPage);
    };

    // ↓ Ce useEffect permet de réafficher la table des missions au retour de la page de détails mission
    useEffect(() => {
        if (location.pathname === '/dashboard/codev/current-missions') {
            setDisplayedSection('Production');
        }
    }, []);

    const filterDealList = async (query, type) => {
        if (type === 'specificationsToStudy') {
            setSpecificationsToStudyFilter(query);
        }
        else if (type === 'proposalsBeingWritten') {
            setProposalsBeingWrittenFilter(query);
        }
        else if (type === 'proposalsPending') {
            setProposalsPendingFilter(query);
        }
        else if (type === 'currentDeals') {
            setCurrentDealsFilter(query);
        }
    };
   
    const filterMissionList = async (query) => {
        setMissionsFilter(query);
    };

    const handleMissionNavigation = async (dealId) => {
        const getDeal = await DealActions.getDealById(userData, dealId);
        
        if (getDeal.success) {

            const isSale = getDeal.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

            if (userData.role === 'CO' && getDeal.data.production_lead.id !== userData.userId && !isSale) {
                
                navigate(`/deals/${getDeal.data.id}/missions/${getDeal.data.missions.find((mission) => mission.ressource.id === userData.userId)?.id}`);

                return
            }
            else {
                navigate(`/deals/${dealId}`);
            }
        }

    };

    return (
        <Layout>
            <Toast/>
            <PageLoader loading={!productionLoading ? loading : true}>
                <div className="px-20 pt-10 pb-32">
                    <TabNavigator
                        setDisplayedSection={setDisplayedSection}
                        displayedSection={displayedSection}
                        sections={sections}
                        onClick={() => navigate('/dashboard/codev')}
                    />
                    {
                        displayedSection === 'Commercial' && (
                            <Routes>
                                <Route path='/' element={
                                    <CommercialTab
                                        salesActivity={salesActivity}
                                        salesOverview={salesOverview}
                                        datePickerDate={commercialBalanceDatePicker}
                                        handleDatePickerChange={(e) => handleCommercialBalanceDatePickerChange(
                                            e,
                                            userData,
                                            notify,
                                            setLoading,
                                            setCommercialBalanceDatePicker,
                                            setSalesOverview
                                        )}
                                    />
                                }/>
                                <Route path='specification-to-study' element={
                                    <SpecificationToStudyPage
                                        das={das}
                                        data={specificationsToStudy}
                                        userData={userData}
                                        tableType='specificationsToStudy'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        tableLoading={specificationsToStudyTableLoading}
                                        dasKey={specificationsToStudyDasKey}
                                        setDasKey={setSpecificationsToStudyDasKey}
                                        paginationMetadata={specificationsToStudyPaginationMetadata}
                                        handleGetData={handleGetSpecificationsToStudyData}
                                    />
                                }/>
                                <Route path='proposals-being-written' element={
                                    <ProposalsBeingWrittenPage
                                        das={das}
                                        data={proposalsBeingWritten}
                                        userData={userData}
                                        tableType='proposalsBeingWritten'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        tableLoading={proposalsBeingWrittenTableLoading}
                                        setDasKey={setProposalsBeingWrittenDasKey}
                                        dasKey={proposalsBeingWrittenDasKey}
                                        paginationMetadata={proposalsBeingWrittenPaginationMetadata}
                                        handleGetData={handleGetProposalsBeingWrittenData}
                                    />
                                }/>
                                <Route path='proposals-pending' element={
                                    <ProposalsPendingPage
                                        das={das}
                                        data={proposalsPending}
                                        userData={userData}
                                        tableType='proposalsPending'
                                        filterDealList={filterDealList}
                                        handleMissionNavigation={handleMissionNavigation}
                                        tableLoading={proposalsPendingTableLoading}
                                        setDasKey={setProposalsPendingDasKey}
                                        dasKey={proposalsPendingDasKey}
                                        paginationMetadata={proposalsPendingPaginationMetadata}
                                        handleGetData={handleGetProposalsPendingData}
                                    />
                                }/>
                                <Route path='specifications-studied' element={
                                    <SpecificationsStudiedPage
                                        dasS={das}
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                                <Route path='proposals-made' element={
                                    <ProposalsMadePage
                                        dasS={das}
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                                <Route path='proposals-won' element={
                                    <ProposalsWonPage
                                        dasS={das}
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                                <Route path='proposals-lost' element={
                                    <ProposalsLost
                                        dasS={das}
                                        userData={userData}
                                        handleMissionNavigation={handleMissionNavigation}
                                        dates={commercialBalanceDatePicker}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Production' && (
                            <Routes>
                                <Route path='/' element={
                                    <ProductionTab
                                        productionActivity={productionActivity}
                                        users={dasUsers}
                                        productionLoad={productionLoad}
                                        datePickerDate={loadIndicatorsDatePicker}
                                        handleDatePickerChange={(e) => handleLoadIndicatorsDatePickerChange(
                                            e,
                                            userData,
                                            notify,
                                            setLoading,
                                            setLoadIndicatorsDatePicker,
                                            setDasUsers,
                                            setProductionLoad
                                        )}
                                    />
                                }/>

                                <Route path='current-deals' element={
                                    <CurrentDeals
                                        das={das}
                                        data={currentDeals}
                                        userData={userData}
                                        tableType='currentDeals'
                                        filterDealList={filterDealList}
                                        tableLoading={currentDealsTableLoading}
                                        setDasKey={setCurrentDealsDasKey}
                                        dasKey={currentDealsDasKey}
                                        paginationMetadata={currentDealsPaginationMetadata}
                                        handleGetData={handleGetCurrentDealsData}
                                    />
                                }/>
                                
                                <Route path='current-missions' element={
                                    <CurrentMissions
                                        filterMissionList={filterMissionList}
                                        das={das}
                                        data={missions}
                                        userData={userData}
                                        refreshMissionData={handleRefreshMissionsData}
                                        tableLoading={missionsTableLoading}
                                        paginationMetadata={missionsPaginationMetadata}
                                        handleGetData={handleFetchMissionsData}
                                        setDasKey={setMissionsDasKey}
                                        dasKey={missionsDasKey}
                                    />
                                }/>
                            </Routes>
                        )
                    }
                    {
                        displayedSection === 'Gestion des ressources' && (
                            <RessourceManagementTab
                                ressources={ressources}
                                externalRessources={externalRessources}
                                datePickerDate={externalRessourcesDatePicker}
                                handleDatePickerChange={(e) => handleExternalRessourcesDatePickerChange(
                                    e,
                                    userData,
                                    notify,
                                    setLoading,
                                    setExternalRessourcesDatePicker,
                                    setExternalRessources
                                )}
                            />
                        )
                    }
                    {
                        displayedSection === 'Indicateurs de résultat' && (
                            <ResultIndicatorTab
                                results={resultIndicators}
                                datePickerDate={resultIndicatorsDatePicker}
                                handleDatePickerChange={(e) => handleResultIndicatorsDatePickerChange(
                                    e,
                                    userData,
                                    notify,
                                    setLoading,
                                    setResultIndicatorsDatePicker,
                                    setResultIndicators
                                )}
                            />
                        )
                    }
                </div>
            </PageLoader>
        </Layout>
    )
}

export default CodevDashboardPage;
