import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import TabCard from '../../components/ui/TabCard/TabCard';
import {pageTabConfig} from './configs/pageTables/pageTabConfig';
import {useState} from "react";
import {notify} from "../../components/ui/Toast/Toast";
import {getErrors} from "../../utils";
import {MissionActions} from '../../actions';

const CurrentMissions = ({
    data: missionsData,
    das,
    userData,
    refreshMissionData,
    filterMissionList,
    tableLoading,
    paginationMetadata,
    handleGetData,
    setDasKey,
    dasKey
}) => {
    const [missionsTableEdit, setMissionsTableEdit] = useState(false);
    const [errors, setErrors] = useState(null);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
    //const [activeTabLength, setActiveTabLength] = useState(null);

    const onSaveFunction = async (dataArray) => {
        setErrors(null);
        let statusChanged = false;

        const updateResult = dataArray.map(async (data) => {
            if (parseInt(data.status) !== 1) statusChanged = true;

            const missionToUpdate = missionsData.find((mission) => mission.id === data.id);

            const dataKeys = Object.keys(data);

            const isSame = dataKeys.every((key) => data[key] === missionToUpdate[key]);

            if (isSame) {
                return;
            }

            const payload = {...missionToUpdate, ...data}

            return await MissionActions.updateMissionById(userData, payload );
        })

        const resolve = await Promise.all(updateResult);

        const results = resolve.filter(Boolean)

        if (results.every((result) => result.success)) {
            await refreshMissionData(statusChanged);
            setMissionsTableEdit(false);
            setSubmitButtonLoading(false);
            notify('Missions mises à jour');
        }
        else {
            const errorResults = results.filter((result) => !result.success);

            const allErrors = errorResults.map((el) => {
                const err = getErrors(el);

                return err[0];
            })

            const assimilatedErrors = allErrors.filter((el, index) => {
                return index === allErrors.findIndex(other => el.title === other.title && el.message === other.message);
            });

            setSubmitButtonLoading(false);

            setErrors(assimilatedErrors);
        }
    };

    // const onChangeTab = async (tab) => {
    //     await refreshMissionData();
    //     const missionsLength = missionsData.filter((mission) => mission.deal.das === tab.label).length;
    //     setActiveTabLength(tab.label === 'Cabinet' ? missionsData.length : missionsLength);
    // };

    const handleChangeDasKey = (das) => {
        setDasKey(das.label === "Cabinet" ? '' : das.label);
    };

    const tableConfig = {
        filterMissionList,
        userData,
        dasKey,
        handleGetData,
        tableLoading,
        paginationMetadata,
        missionsTableEdit,
        errors,
        submitButtonLoading,
        onSaveFunction,
        setMissionsTableEdit,
        setSubmitButtonLoading
    };

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/codev'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Missions en cours (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>

            <TabCard onChange={handleChangeDasKey} config={pageTabConfig(missionsData, "missions", das, tableConfig)}/>
        </>
    )
}

export default CurrentMissions;
