import {EuroCircleFilled} from '@ant-design/icons';
import {CalculatorFilled} from '@ant-design/icons';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import {transformThousandNumbers} from '../../../../utils';

const ConsultantContributionsCards = ({data}) => {
    
    const euroIcon = <EuroCircleFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const calculatorIcon = <CalculatorFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSum = (key) => {
        let sum = 0;

        for (const k in data) {
            sum += data[k][key];
        }

        return Math.round(sum);
    }

    return (
        <div className='flex flex-row mt-4'>
            <SimpleCard
                width='w-[50%]'
                height='h-fit'
                iconComponent={calculatorIcon}
                title={transformThousandNumbers(getSum('gross_margin_contribution'))}
                subTitle="Contribution totale à la marge des consultants"
                background='orange'
                bottomComponent={<BottomComponent
                    juniorData={data.junior.gross_margin_contribution}
                    confirmedData={data.confirmed.gross_margin_contribution}
                    seniorData={data.senior.gross_margin_contribution}
                />}
            />
            <div className='mx-5'/>
            <SimpleCard
                width='w-[50%]'
                height='h-fit'
                iconComponent={euroIcon}
                title={transformThousandNumbers(getSum('sales'))}
                subTitle="Chiffre d’affaires commercialisé"
                background='orange'
                bottomComponent={<BottomComponent
                    juniorData={data.junior.sales}
                    confirmedData={data.confirmed.sales}
                    seniorData={data.senior.sales}
                />}
            />
        </div>
    )
}

export default ConsultantContributionsCards;

const BottomComponent = ({juniorData, confirmedData, seniorData}) => {
    return (
        <div className='flex flex-row items-center justify-between w-full bg-white rounded-b-md'>
            <div className='w-1/3 py-6 px-9 border-t-[1px] border-r-[1px] border-[#d3d3d3]'>
                <span className='text-sm font-medium text-gradient'>Junior</span>
                <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                    {transformThousandNumbers(juniorData)}
                </span>
            </div>
            <div className='w-1/3 py-6 px-9 border-t-[1px] border-r-[1px] border-[#d3d3d3]'>
                <span className='text-sm font-medium text-gradient'>Confirmé</span>
                <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                    {transformThousandNumbers(confirmedData)}
                </span>
            </div>
            <div className='w-1/3 py-6 px-9 border-t-[1px] border-[#d3d3d3]'>
                <span className='text-sm font-medium text-gradient'>Sénior</span>
                <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                    {transformThousandNumbers(seniorData)}
                </span>
            </div>
        </div>
    )
}
