import {useState, useEffect} from 'react';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {frenchFormat, handleFilterList, useFilterQuery, fetchSelectData} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import {ExpenseTypeActions} from '../../../actions';
import {SelectInput, TableDatePicker} from '../../../components/ui/Table';

export const purchasesTableConfig = (
    handleEditButtonClick,
    handleDeleteSpending,
    deleteSpendingLoading,
    buttonsDisabled,
    filterExpenseList,
    userData
) => {
    const [types, setTypes] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        ressource: '',
        type: '',
        before: '',
        after: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterExpenseList);

    // ↓ get data for select input
    const handleGetExpenseTypes = async (search, page = 1) => {
        await fetchSelectData({action: ExpenseTypeActions.getAllExpenseTypes, search:`&search=${search}`, page: page, setter: setTypes, userData, resultPath: 'data'});
    }

    useEffect(() => {
        // ↓ expense types dropdown values
        (async () => {
            await handleGetExpenseTypes('', 1);
        });
    }, []);
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: '#',
                    value: 'id',
                    width: 'w-[5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full h-full text-center">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Type',
                    value: 'type.name',
                    width: 'w-[20%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.type.name}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="type"
                        options={types?.options}
                        hasNextPage={types?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetExpenseTypes)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date',
                    value: 'date',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.date)}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name={null} onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Refact.',
                    value: 'billable',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.billable ? 'Oui' : 'Non'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Montant',
                    value: 'amount',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(parseInt(item.amount).toLocaleString())}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'TVA',
                    value: 'vat',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(item.vat)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'CO2',
                    value: 'co2',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {+(item.co2)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Notes',
                    value: null,
                    width: 'w-[17%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.notes.length > 0 ? item.notes : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {
                                    item.parentStatus === 3 ?
                                        <>
                                            <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                            <div className="w-0 h-0 px-2.5"/>
                                            <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        </> :
                                        <>
                                            <ActionsButton
                                                type="edit"
                                                isLoading={false}
                                                onClick={() => handleEditButtonClick(item)}
                                                disabled={buttonsDisabled}
                                                blueStyle={false}
                                            />
                                            <div className="w-0 h-0 px-2"/>
                                            <ActionsButton
                                                type="delete"
                                                isLoading={deleteSpendingLoading === item.id}
                                                onClick={() => handleDeleteSpending(item.id)}
                                                disabled={buttonsDisabled}
                                                deleteConfirmationQuestion='Souhaitez-vous supprimer ce frais ?'
                                                blueStyle={false}
                                            />
                                        </>
                                }
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
