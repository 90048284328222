import {ExclamationCircleOutlined} from '@ant-design/icons';

import Popup from '../../../../components/ui/Popup';
import Button from '../../../../components/ui/Button';

const GoNoGoPopup = ({
    view,
    setView,
    setAbandonedPopupVisible,
    setResponsePopupVisible,
    blueStyle = false
}) => {

    const handleNoGo = () => {
        setView(false);
        setAbandonedPopupVisible(true);
    }

    const handleGo = async () => {
        setView(false);
        setResponsePopupVisible(true);
    }

    return (
        <Popup view={view} setView={setView}>
            <div className='flex items-start text-xl font-bold' style={{fontFamily: 'DIN Condensed'}}>
                <ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14', marginRight: '16px'}}/>
                {"Direction de l'affaire"}
            </div>
            <div className='flex items-start'>
                <div style={{width: '22px', marginRight: '16px'}}/>
                <div className='text-[14px] opacity-[0.8] mt-3'>{"Sélectionnez la direction que prend l'affaire."}</div>
            </div>
            <div className='flex flex-row items-center justify-center mt-6'>
                <Button
                    content="No Go"
                    type="secondary"
                    onClick={handleNoGo}
                    width='w-44'
                    height='h-9'
                />
                <Button
                    content="Go"
                    type={blueStyle ? 'blue' : 'primary'}
                    onClick={handleGo}
                    width='w-44'
                    height='h-9'
                />
            </div>
        </Popup>
    )
}

export default GoNoGoPopup;
