import React, {useEffect, useRef} from 'react';
import close from '../../../assets/icons/popup/close.svg';
/**
 * props:
 * setView = permet de changer la valeur de view (true/false)
 * view = true || false (default false) => permet d'afficher la popup
 * hideOnClick = true || false (default false) => permet de fermer la popup en cliquant à l'extérieur
 * height/width = ex 100px, 50%, etc... (default fit-content)
 */

const Popup = ({
    setView,
    view,
    hideOnClick = true,
    height = 'fit-content',
    width,
    maxHeight = '40rem',
    xOverflow = 'overflow-x-hidden',
    yOverflow = 'overflow-y-auto',
    children
}) => {
    const ref = useRef(null);

    useEffect( () => {
        const handleClickOutsidePopup = (e) => {
            if (ref.current && !ref.current.contains(e.target) && hideOnClick) {
                let isChild = false;
                for (let node = e.target; node !== null; node = node.parentNode) {
                    if (node === ref) {
                        isChild = true;
                        break;
                    }
                }

                if (!isChild) {
                    setView(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutsidePopup);

        return () => document.removeEventListener('mousedown', handleClickOutsidePopup);
    }, [ref]);

    useEffect(() => {
        if (view) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [view]);

    return (
        <>
            { view ?
                (<div id='skip' className="p-12 z-[60] fixed inset-0 w-screen h-screen flex justify-center items-center bg-[#212121]/60 cursor-default">
                    <div
                        id='skip'
                        ref={ref}
                        className={`relative max-w-full px-8 pt-16 pb-8 bg-white rounded-sm cursor-default mt-20 ${xOverflow} ${yOverflow}`}
                        style={{
                            boxShadow: '0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
                            height: height,
                            width: `${width === undefined || !width ? 'fit-content' : `${width}`}`,
                            maxHeight: maxHeight
                        }}
                    >
                        <button
                            id='skip'
                            onClick={() => setView(false)}
                            className="absolute flex items-center justify-center p-3 cursor-pointer top-2 right-5"
                        >
                            <img id='skip' src={close} alt="logo" className="w-4"/>
                        </button>
                        {children}
                    </div>
                </div>) : null
            }
        </>
    );
};

export default Popup;
