import {ACTION_CODE} from './_actionCode';
import {ContractService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_CONTRACT';

export async function createContract(
    userData: Record<string, string>,
    newContract: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createContract - Start');

        const createContractResult = await ContractService.createContract(userData, newContract);

        LoggerService.logInfo('Action - createContract - End - Success');

        return ResultModel.newSuccessResult(createContractResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createContract - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
