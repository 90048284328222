import {TagFilled} from '@ant-design/icons';

import DonutsChart from "../../../../components/ui/DonutsChart/DonutsChart";
import {tjmChartConfig} from '../../configs/productionTab/chargeIndicatorSection/tjmChartConfig';
import SimpleCard from "../../../../components/ui/SimpleCard";

const TjmTabChildren = ({data, dasKey}) => {
    
    const TagIcon = <TagFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const chartData = [
        {
            type: 'Junior:',
            value: data.billing[dasKey] ? data.billing[dasKey]["Junior"].billed_days : 0
        },
        {
            type: 'Confirmé:',
            value: data.billing[dasKey] ? data.billing[dasKey]["Confirmé"].billed_days : 0
        },
        {
            type: 'Sénior:',
            value: data.billing[dasKey] ? data.billing[dasKey]["Senior"].billed_days : 0
        }
    ];

    const getTotalBilledDays = (key) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            let billedDays = 0;

            for (const k in data.billing[key]) {
                billedDays += data.billing[key][k].billed_days;
            }

            return billedDays.toFixed();
        }
    }

    const getAverageDailyRate = (key) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            let dailyRates = 0;

            for (const k in data.billing[key]) {
                dailyRates += data.billing[key][k].daily_rate;
            }

            return (dailyRates / 3).toFixed();
        }
    }

    const getRealDailyRate = (key, status) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            const firstValue = data.billing[key][status].daily_rate * data.billing[key][status].billed_days;
            const secondValue = data.billing[key][status].produced_days;

            if (!firstValue || !secondValue) {
                return 0;
            }
            else {
                return (firstValue / secondValue).toFixed();
            }
        }
    }

    const getAverageRealDailyRate = (key) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            let dailyRates = 0;

            dailyRates += parseInt(getRealDailyRate(key, "Junior"));
            dailyRates += parseInt(getRealDailyRate(key, "Confirmé"));
            dailyRates += parseInt(getRealDailyRate(key, "Senior"));

            return (dailyRates / 3).toFixed();
        }
    }

    return (
        <div className='flex items-center justify-between'>
            <div className='w-[36%] h-[380px]'>
                <DonutsChart config={tjmChartConfig} data={chartData} count={getTotalBilledDays(dasKey)} label="j"/>
            </div>
            <SimpleCard
                width='w-[30%]'
                title={`${getAverageDailyRate(dasKey)} €`}
                subTitle="TJM théorique"
                iconComponent={TagIcon}
                topComponent={
                    <TopComponent
                        juniorValue={data.billing[dasKey] ? data.billing[dasKey]["Junior"].daily_rate.toFixed() : 0}
                        confirmedValue={data.billing[dasKey] ? data.billing[dasKey]["Confirmé"].daily_rate.toFixed() : 0}
                        seniorValue={data.billing[dasKey] ? data.billing[dasKey]["Senior"].daily_rate.toFixed() : 0}
                    />
                }
            />
            <SimpleCard
                width='w-[30%]'
                title={`${getAverageRealDailyRate(dasKey)} €`}
                subTitle="TJM réel"
                iconComponent={TagIcon}
                topComponent={
                    <TopComponent
                        juniorValue={getRealDailyRate(dasKey, "Junior")}
                        confirmedValue={getRealDailyRate(dasKey, "Confirmé")}
                        seniorValue={getRealDailyRate(dasKey, "Senior")}
                    />
                }
            />
        </div>
    )
}

export default TjmTabChildren;

const TopComponent = ({juniorValue, confirmedValue, seniorValue}) => {
    return (
        <div className='flex justify-between bg-white rounded-b-md'>
            <div className='w-1/3 px-8 py-5'>
                <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                    <p className='text-gradient'>Junior</p>
                </div>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{juniorValue} €</p>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/3 px-8 py-5'>
                <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                    <p className='text-gradient'>Confirmé</p>
                </div>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{confirmedValue} €</p>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/3 px-8 py-5'>
                <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                    <p className='text-gradient'>Sénior</p>
                </div>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{seniorValue} €</p>
            </div>
        </div>
    )
}