import {displayErrors} from "../../../utils";

const Error = ({errors}) => {
    return (
        <>
            {
                errors ?
                    <div className='flex items-center justify-center w-full pt-4 text-red-500 whitespace-pre-wrap'>
                        {displayErrors(errors)}
                    </div> : null
            }
        </>
    )
}

export default Error;
