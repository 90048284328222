import React from 'react';
import {Route, Routes} from 'react-router-dom';
import OfficeManagerDashboardPage from '../pages/officeManagerDashboardPage/OfficeManagerDashboardPage';

function OfficeManagerDashboardRouter() {
    return (
        <Routes>
            <Route path='/*' element={<OfficeManagerDashboardPage/>}/>
        </Routes>
    );
}

export default OfficeManagerDashboardRouter;
