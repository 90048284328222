import * as z from 'zod';
import * as bankFunctions from './functions';

export const BankSchema = z.object({
    'id': z.number(),
    'name': z.string(),
    'subsidiary': z.string(),
    'is_active': z.boolean(),
    'iban': z.string(),
    'bic': z.string()
});

export const BankPartialSchema = BankSchema.partial();
export type BankPartial = z.infer<typeof BankPartialSchema>;
export type Bank = z.infer<typeof BankSchema>;

export function newBank(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...BankSchema.parse({...data}),
        ...bankFunctions
    };
}

export function parseBank(data: any, partial = false) {
    if (!partial) {
        return {
            ...BankSchema.parse(data),
            ...bankFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...BankPartialSchema.parse(data),
            ...bankFunctions
        };
    }
}
