import {HourglassFilled} from '@ant-design/icons';

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import {pendingProposalsChartConfig} from '../../configs/commercialTab/activityIndicatorsSection/pendingProposalsChartConfig';
import {transformThousandNumbers} from '../../../../utils';

const PendingProposals = ({count, days, euros, data}) => {
    const HourglassIcon = <HourglassFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    return (
        <div className='flex justify-between'>
            <div className='self-end w-[48%]'>
                <SimpleCard
                    width="w-full"
                    background="white"
                    font="orange"
                    lineColor="black"
                    title={count}
                    shadow={false}
                    borderSize='border'
                    subTitle={'Propositions en attente'}
                    iconComponent={HourglassIcon}
                    topComponent={<TopComponent day={days} euros={euros}/>}
                />
            </div>

            <div className='w-[48%] h-[330px]'>
                <DonutsChart data={data} config={pendingProposalsChartConfig} label=''/>
            </div>
        </div>
    )
}

export default PendingProposals;

const TopComponent = ({day, euros}) => {
    return (
        <div className='flex justify-between bg-white border-t border-gray-200 rounded-b-md'>
            <div className='w-1/2 px-8 pt-5 pb-16'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{day}</p>
                <p className='text-gradient'>Jours</p>
            </div>

            <div className='h-auto w-[1px] bg-gray-200'/>

            <div className='w-1/2 px-8 pt-5 pb-16'>
                <p style={{ fontFamily: 'Din condensed' }} className='mt-3 text-4xl text-gradient'>{transformThousandNumbers(euros)}</p>
                <p className='text-gradient'>Euros</p>
            </div>
        </div>
    )
}