import { useState, useEffect } from 'react';

import searchIcon from './../../../assets/icons/table/search-icon.png';

export const SearchInput = ({name, onChange, resetWhenValueChanges}) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            onChange(inputValue, name);
        }, 500);

        return () => clearTimeout(timer);
    }, [inputValue]);

    useEffect(() => {
        if (resetWhenValueChanges) {
            setInputValue('');
        }
    }, [resetWhenValueChanges]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <div className='flex flex-row items-center justify-between w-10/12 px-2 mx-auto bg-white rounded-[4px]'>
            <input
                type="text"
                name={name}
                value={inputValue}
                onChange={handleInputChange}
                className='w-3/4 text-base font-normal outline-none condensed-regular'
            />
            <img className='w-2.5 h-2.5' src={searchIcon} alt="search"/>
        </div>
    );
};