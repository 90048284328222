import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {notify} from '../../../../components/ui/Toast/Toast';
import {UserActions} from '../../../../actions';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import CheckboxInput from '../../../../components/ui/Inputs/CheckboxInput/CheckboxInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';
import SelectInput from '../../../../components/ui/Inputs/SelectInput';

const PersonalInformationForm = ({
    setView,
    setStaffingItemToBeUpdated,
    staffingUserToBeUpdated,
    setTabSelected,
    setStaffingUserToBeUpdated,
    setContracts,
    userData }) => {
    const [errors, setErrors] = useState();
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const defaultValues = {
        username: '',
        email: '',
        first_name: '',
        user_class: "",
        last_name: '',
        password: '',
        access: false
    };
    
    const {reset, handleSubmit ,control, formState: { isValid } } = useForm({defaultValues});

    useEffect(() => {

        if(staffingUserToBeUpdated) {
            const ressource = staffingUserToBeUpdated;
            
            let currentUserClass = userClass.find(el => el.value === ressource.user_class);
            currentUserClass = currentUserClass ? currentUserClass : '';
            
            reset({
                username: ressource.username,
                email: ressource.email,
                first_name: ressource.first_name,
                user_class: currentUserClass,
                last_name: ressource.last_name,
                password: '',
                access: ressource.access ?? false
            });
        }
    }, [staffingUserToBeUpdated]);

    const handleSubmitForm = async (data) => {
        setLoading(true);
        setDisabled(true);
        setErrors(null);

        const staffingItem = {
            id: data.id,
            username: data.username,
            email: data.email,
            first_name: data.first_name,
            user_class: data.user_class.value,
            last_name: data.last_name,
            access: data.access
        }

        if (staffingUserToBeUpdated) {
            const { id } = staffingUserToBeUpdated;
            const result = await UserActions.updateUser(userData, id, staffingItem);

            if (result.success) {
                notify(`Le profil a été mis à jour avec succès.`);
                // refreshData(); TODO: See if we need refresh data and witch data
            }
            else {
                setErrors(getErrors(result));
                notify('Le profil n\'a pas pu être mis à jour.');
            }
        }
        else {
            const result = await UserActions.createUser(userData, staffingItem);

            if (result.success) {
                notify(`Le profil a été crée avec succès.`);

                setStaffingUserToBeUpdated(result.data);
                setStaffingItemToBeUpdated(null);
                setContracts([]);
                setTabSelected(1)
            }
            else {
                setErrors(getErrors(result));
                notify('Le profil n\'a pas pu être créé.');
            }
        }

        setLoading(false);
        setDisabled(false);
    }

    const userClass = [
        {
            id: 1,
            name: 'Dirigeant',
            value: 'PA'
        },
        {
            id: 2,
            name: 'Manager',
            value: 'MA'
        },
        {
            id: 3,
            name: 'Office Manager',
            value: 'OM'
        },
        {
            id: 4,
            name: 'Consultant',
            value: 'CO'
        }
    ];
    
    return (
        <>
            <ContainerCard
                title={
                    staffingUserToBeUpdated ?
                        `${staffingUserToBeUpdated.first_name} ${staffingUserToBeUpdated.last_name}`
                        : 'Nouvelle ressource'
                }
                headerBackground='bg-[#F4F6F8]'
                coloredButtonDisplayed={false}
                overflow='overflow-visible'
                contentPadding='p-0'
            >
                    
                <div className='bg-[#F4F6F8] h-10 flex justify-start gap-6 px-6'>
                    <div className='background-gradient pb-[2px]'>
                        <p
                            className='h-full bg-[#F4F6F8] font-bold'
                            onClick={() =>  setTabSelected(0)}
                        >
                                Informations personnelles
                        </p>
                    </div>
                    <div className='cursor-pointer'>
                        <p
                            //TODO: if no personnal information make this tab not clickable
                            className={`h-full bg-[#F4F6F8] font-semibold ${staffingUserToBeUpdated ? "text-[#637381]" :  "text-[#6373815d]"}`}
                            onClick={() => staffingUserToBeUpdated && setTabSelected(1)}
                        >
                                    Contrats
                        </p>
                    </div>
                </div>
                
                <div className='px-6 py-4'>
                    <div className='flex items-center justify-end w-full mb-2 pr-[250px]'>
                        <div className='flex items-center w-36'>
                            <Controller
                                name="access"
                                control={control}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <CheckboxInput
                                        label='Accès'
                                        required={false}
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex items-center justify-between py-1 gap-28'>
                        <Controller
                            name="username"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom d\'utilisateur'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label="Nom d'utilisateur"
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="ex: KLM"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className='flex items-center justify-between py-1 gap-28'>
                        <Controller
                            name="first_name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le Prénom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Prénom'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Prénom"
                                    type="text"
                                />
                            )}
                        />
                        <Controller
                            name="last_name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Nom'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Nom"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className='flex items-center justify-between py-1 gap-28'>
                        <Controller
                            name="email"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir l\'e-mail'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Email'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Email"
                                    type="text"
                                />
                            )}
                        />
                        <Controller
                            name="user_class"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez sélectionner le profile'
                            }}}
                            render={({
                                field: {onChange, value, ref},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label="Profil"
                                    placeholder="Profil"
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={userClass}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className='py-1 w-fit'>
                        <Controller
                            name="password"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le mot de passe'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='MDP'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Mot de passe"
                                    type="password"
                                />
                            )}
                        />
                    </div>
                </div>
            </ContainerCard>

            <div className="flex flex-col justify-center pb-2 mt-6">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => {
                            //reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={'Sauvegarder'}
                        onClick={handleSubmit(handleSubmitForm)}
                        loading={loading}
                        disabled={disabled || !isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </>
    )
}

export default PersonalInformationForm;
