import {ACTION_CODE} from './_actionCode';
import {ProductionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_PRODUCTION';

export async function createProduction(userData: Record<string, any>, payloadProduction: any) {
    try {
        LoggerService.logInfo('Action - createProduction - Start');

        const createProductionResult = await ProductionService.createProduction(userData, payloadProduction);

        LoggerService.logInfo('Action - createProduction - End - Success');

        return ResultModel.newSuccessResult(createProductionResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createProduction - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
