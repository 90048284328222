import {AttachmentDao} from '../../dao';
import {
    ErrorModel
} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'DELETE_ATTACHMENT';

export async function deleteAttachment(
    userData: Record<string, string>,
    Id: string
) {
    try {
        const result = await AttachmentDao.deleteAttachment(userData.userToken, Id);

        return result;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'un justificatif',
            error: error
        });
    }
}
