import {Link} from 'react-router-dom';
import {HourglassFilled, CreditCardFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import {transformThousandNumbers} from '../../../../utils';

const Expenses = ({
    color,
    currentExpenseReportsData,
    toPayExpenseReportsData
}) => {

    const hourGlasIcon = <HourglassFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const creditCardIcon = <CreditCardFilled  style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className="my-10">
            <LineTitle title="Notes de frais"/>

            <div className="flex justify-between mt-5">
                <Link to='current-expense-reports' className='w-[48%]'>
                    <SimpleCard
                        width='w-full'
                        title={currentExpenseReportsData.count}
                        background={color === 'orange' ? 'orange' : 'blue'}
                        subTitle="Notes de frais en cours"
                        iconComponent={hourGlasIcon}
                        topComponent={<TopComponent color={color} value={currentExpenseReportsData.value}/>}
                    />
                </Link>

                <Link to='payable-expense-reports' className='w-[48%]'>
                    <SimpleCard
                        width='w-full'
                        title={toPayExpenseReportsData.count}
                        background={color === 'orange' ? 'orange' : 'blue'}
                        subTitle="Notes de frais à payer"
                        iconComponent={creditCardIcon}
                        topComponent={<TopComponent color={color} value={toPayExpenseReportsData.value}/>}
                    />
                </Link>
            </div>
        </div>
    )
}

export default Expenses;

export const TopComponent = ({ value, color }) => {
    return (
        <div className='relative z-20 bg-white'>
            <div className='px-6 py-5 bg-white border border-gray-200 rounded-b-md card-bottom-shadow'>
                <p style={{ fontFamily: 'Din Condensed' }} className={`text-4xl ${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>
                    {transformThousandNumbers(value)}
                </p>
                <p className={`${color === 'orange' ? 'text-gradient' : 'text-gradient-blue'}`}>Euros</p>
            </div>
        </div>
    )
}