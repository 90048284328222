import React from 'react';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import CoursesSection from '../sectionComponents/coursesSection/CoursesSection';
import InformationsSection from '../sectionComponents/InformationsSection';
import InvoicesSection from '../sectionComponents/InvoicesSection';
import MissionsSection from '../sectionComponents/MissionsSection';
import NotesAndDocuments from '../sectionComponents/NotesAndDocuments';
import ProductionsAndExpensesSection from '../sectionComponents/ProductionsAndExpensesSection';
import ReferencesSection from '../sectionComponents/referenceSection/ReferencesSection';
import SpendingsSection from '../sectionComponents/SpendingsSection';
import SubContractingSection from '../sectionComponents/SubContractingSection';

const DealDetailsNavigation = ({
    userData,
    dealData,
    missionsData,
    missionPaginationMetadata,
    handleGetAllMissionsData,
    missionTableLoading,
    spendingsData,
    spendingsTableLoading,
    setSpendingsFilterQuery,
    spendingsPaginationMetadata,
    handleGetAllSpendingsData,
    setSpendingsData,
    invoicesData,
    setInvoiceData,
    invoicesPaginationMetadata,
    invoicesTableLoading,
    handleGetAllInvoices,
    setInvoicesFilterQuery,
    contracorInvoiceData,
    setContractorInvoicesFilterQuery,
    setContractorInvoiceData,
    contractorInvoicesPaginationMetadata,
    handleGetAllContractorInvoicesData,
    contractorInvoicesTableLoading,
    productionsData,
    handleGetAllProductions,
    productionsPaginationMetadata,
    setProductionsFilterQuery,
    setExpensesFilterQuery,
    handleGetAllExpenses,
    expensesData,
    expensesPaginationMetadata,
    attachmentsData,
    buttonsDisabled,
    setButtonsDisabled,
    datePicker,
    setDatePicker,
    refreshDealData,
    refreshMissionsData,
    refreshSpendingsData,
    refreshContractorInvoiceData,
    refreshProductionsData,
    refreshExpensesData,
    refreshInvoicesData,
    refreshAttachmentsData,
    datePickerLoading,
    setDatePickerLoading,
    isTraining,
    isInternal,
    setMissionFilterQuery
}) => {

    // --- Gestion affichage des onglets ---
    const missionsTabVisibility = dealData.status >= 300 || isInternal;
    const spendingsTabVisibility = dealData.status >= 500 && !isInternal;
    const subContractingTabVisibility = isInternal || dealData.status >= 500;
    const invoicesTabVisibility = !isInternal && dealData.status >= 500 && !isInternal;
    const notesAndDocumentsTabVisibility = dealData.status >= 500 || isInternal;
    const productionsAndExpensesTabVisibility = dealData.status >= 500 || isInternal;
    const referencesTabVisibility = !isInternal && dealData.status >= 500 && !isInternal;
    const trainingTabVisibility = isTraining && dealData.status >= 500 && !isInternal;

    // --- Gestion affichage des infos du deal ---
    const salesCardVisibility = !isInternal && dealData.status !== 100 && !isInternal;
    const responseGroupVisibility = !isInternal && dealData.status >= 300 && !isInternal;
    const productionCardVisibility = dealData.status >= 300 && !isInternal;
    const budgetCardVisibility = dealData.status >= 300 && !isInternal;
    const cannotBeModified = dealData.status === 900;
    const leftPosition = dealData.status <= 300;

    const getLinkClasses = (isActive) => {
        if (isActive && isTraining) {
            return "pb-2 border-b-2 text-2xl font-bold text-gradient-blue border-[#01abe9]";
        }
        else if (isActive) {
            return "pb-2 border-b-2 text-2xl font-bold text-gradient border-[#df5035]";
        }
        else {
            return "pb-2 border-b-2 text-base font-medium text-[#646464] border-transparent";
        }
    };

    const getLinkStyle = (isActive) => {
        return { fontFamily: isActive ? 'DIN Condensed' : 'din-regular' };
    }

    return (
        <div className='px-14 pt-5 pb-32'>
            <div className={`flex flex-row items-center mb-6 ${leftPosition ? 'justify-start' : 'justify-center'}`}>
                <NavLink to="informations" className='px-4 pt-5 pb-1 mx-2'>
                    {
                        ({isActive}) => (
                            <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                {"Informations"}
                            </div>
                        )
                    }
                </NavLink>
                {
                    missionsTabVisibility ?
                        <NavLink to="missions" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Missions"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    productionsAndExpensesTabVisibility ?
                        <NavLink to="productions-and-expenses" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Temps & Frais"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    invoicesTabVisibility ?
                        <NavLink to="invoices" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Factures"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    subContractingTabVisibility ?
                        <NavLink to="sub-contracting" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Sous/co-traitance"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    spendingsTabVisibility ?
                        <NavLink to="spendings" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Achats TA"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    notesAndDocumentsTabVisibility ?
                        <NavLink to="notes-and-documents" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Notes & Documents"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    referencesTabVisibility ?
                        <NavLink to="references" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Références"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
                {
                    trainingTabVisibility ?
                        <NavLink to="courses" className='px-4 pt-5 pb-1 mx-2'>
                            {
                                ({isActive}) => (
                                    <div className={getLinkClasses(isActive)} style={getLinkStyle(isActive)}>
                                        {"Formations"}
                                    </div>
                                )
                            }
                        </NavLink>
                        : null
                }
            </div>
            <Routes>
                <Route path='informations' element={
                    <InformationsSection
                        data={dealData}
                        userData={userData}
                        refreshData={refreshDealData}
                        salesCardVisibility={salesCardVisibility}
                        responseGroupVisibility={responseGroupVisibility}
                        productionCardVisibility={productionCardVisibility}
                        budgetCardVisibility={budgetCardVisibility}
                        cannotBeModified={cannotBeModified}
                        blueStyle={isTraining}
                    />
                }/>
                <Route path='missions' element={
                    <MissionsSection
                        userData={userData}
                        missionsData={missionsData}
                        missionPaginationMetadata={missionPaginationMetadata}
                        handleGetAllMissionsData={handleGetAllMissionsData}
                        missionTableLoading={missionTableLoading}
                        refreshMissionsData={refreshMissionsData}
                        refreshDealData={refreshDealData}
                        dealData={dealData}
                        cannotBeModified={cannotBeModified}
                        blueStyle={isTraining}
                        setMissionFilterQuery={setMissionFilterQuery}
                        isInternal={isInternal}
                    />
                }/>
                <Route path='spendings' element={
                    <SpendingsSection
                        data={spendingsData}
                        setSpendingsData={setSpendingsData}
                        spendingsTableLoading={spendingsTableLoading}
                        setSpendingsFilterQuery={setSpendingsFilterQuery}
                        handleGetAllSpendingsData={handleGetAllSpendingsData}
                        spendingsPaginationMetadata={spendingsPaginationMetadata}
                        dealData={dealData}
                        refreshSpendingsData={refreshSpendingsData}
                        buttonsDisabled={buttonsDisabled}
                        setButtonsDisabled={setButtonsDisabled}
                        cannotBeModified={cannotBeModified}
                        blueStyle={isTraining}
                    />
                }/>
                <Route path='sub-contracting' element={
                    <SubContractingSection
                        dealData={dealData}
                        data={contracorInvoiceData}
                        setData={setContractorInvoiceData}
                        contractorInvoicesTableLoading={contractorInvoicesTableLoading}
                        setContractorInvoicesFilterQuery={setContractorInvoicesFilterQuery}
                        contractorInvoicesPaginationMetadata={contractorInvoicesPaginationMetadata}
                        handleGetAllContractorInvoicesData={handleGetAllContractorInvoicesData}
                        refreshData={refreshContractorInvoiceData}
                        buttonsDisabled={buttonsDisabled}
                        setButtonsDisabled={setButtonsDisabled}
                        cannotBeModified={cannotBeModified}
                        blueStyle={isTraining}
                    />
                }/>
                <Route path='invoices' element={
                    <InvoicesSection
                        invoicesData={invoicesData}
                        setInvoiceData={setInvoiceData}
                        invoicesPaginationMetadata={invoicesPaginationMetadata}
                        handleGetAllInvoices={handleGetAllInvoices}
                        dealData={dealData}
                        invoicesTableLoading={invoicesTableLoading}
                        setInvoicesFilterQuery={setInvoicesFilterQuery}
                        refreshInvoicesData={refreshInvoicesData}
                        buttonsDisabled={buttonsDisabled}
                        setButtonsDisabled={setButtonsDisabled}
                        cannotBeModified={cannotBeModified}
                        blueStyle={isTraining}
                    />
                }/>
                <Route path='notes-and-documents' element={
                    <NotesAndDocuments
                        dealData={dealData}
                        userData={userData}
                        refreshAttachmentsData = {refreshAttachmentsData}
                        refreshDealData={refreshDealData}
                        attachmentsData={attachmentsData}
                        buttonsDisabled={buttonsDisabled}
                        setButtonsDisabled={setButtonsDisabled}
                        cannotBeModified={cannotBeModified}
                        blueStyle={isTraining}
                    />
                }/>
                <Route path='productions-and-expenses' element={
                    <ProductionsAndExpensesSection
                        userData={userData}
                        dealData={dealData}
                        datePicker={datePicker}
                        setDatePicker={setDatePicker}
                        productionsData={productionsData}
                        setProductionsFilterQuery={setProductionsFilterQuery}
                        handleGetAllProductions={handleGetAllProductions}
                        productionsPaginationMetadata={productionsPaginationMetadata}
                        expensesData={expensesData}
                        expensesPaginationMetadata={expensesPaginationMetadata}
                        setExpensesFilterQuery={setExpensesFilterQuery}
                        handleGetAllExpenses={handleGetAllExpenses}
                        buttonsDisabled={buttonsDisabled}
                        setButtonsDisabled={setButtonsDisabled}
                        blueStyle={isTraining}
                        refreshProductionsData={refreshProductionsData}
                        refreshExpensesData={refreshExpensesData}
                        datePickerLoading={datePickerLoading}
                        setDatePickerLoading={setDatePickerLoading}
                        cannotBeModified={cannotBeModified}
                    />
                }/>
                <Route path='references' element={
                    <ReferencesSection
                        userData={userData}
                        dealData={dealData}
                        blueStyle={isTraining}
                        refreshData={refreshDealData}
                        cannotBeModified={cannotBeModified}
                    />
                }/>
                <Route path='courses' element={
                    <CoursesSection
                        userData={userData}
                        buttonsDisabled={buttonsDisabled}
                        setButtonsDisabled={setButtonsDisabled}
                        trainingId={dealData.training}
                        cannotBeModified={cannotBeModified}
                    />
                }/>
                <Route path='*' element={<Navigate to='../informations' replace/>}/>
            </Routes>
        </div>
    )
}

export default DealDetailsNavigation;
