import { useState, useEffect } from 'react';

import {frenchFormat, fetchSelectData, handleFilterList, useFilterQuery} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import { CustomerActions, ContractorActions } from '../../../actions';
import { SelectInput, TableDatePicker } from '../../../components/ui/Table';

export const contractorsTableConfig = ({
    displayedSection,
    handleDeleteInvoice,
    handlePayInvoice,
    actionButtonLoading,
    actionButtonDisabled,
    handleInvoicePreview,
    userData,
    filterContractorInvoices,
    tableType
}) => {
    const [customers, setCustomers] = useState([]);
    const [contractors, setContractors] = useState([]);
    const [filterQuery, setFilterQuery] = useState({
        customer: '',
        contractor: '',
        invoice_payment_before: '',
        invoice_payment_after: '',
        invoice_due_before: '',
        invoice_due_after: '',
        invoice_billing_before: '',
        invoice_billing_after: ''
    });

    const handleFilter = (value, name, date) => {
        handleFilterList(value, name, filterQuery, setFilterQuery, date);
    };

    useFilterQuery(filterQuery, filterContractorInvoices, tableType);

    // ↓ get data for select input
    const handleGetCustomers = async (search, page = 1) => {
        await fetchSelectData({action: CustomerActions.getAllCustomers, search:`&search=${search}`, page: page, setter: setCustomers, userData});
    };

    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({action: ContractorActions.getAllContractors, search:`&search=${search}`, page, setter: setContractors, userData, resultPath: 'data'});
    }

    useEffect(() => {
        // ↓ customer dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

        // ↓ users dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })
    }, []);

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Numéro facture',
                    value: 'id',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.invoice.official_id ? item.invoice.official_id : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Fournisseurs',
                    value: 'deal.production_lead',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={item.contractor.name} type='rounded'/>
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="contractor"
                        options={contractors?.options}
                        hasNextPage={contractors?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetContractors)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Client',
                    value: 'customer',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {

                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item?.invoice.customer.name ?? '-'}
                            </div>
                        );
                    },
                    filterComponent: () => <SelectInput
                        name="customer"
                        options={customers?.options?.sort((a, b) => a.name.localeCompare(b.name))}
                        hasNextPage={customers?.hasNextPage}
                        isSearchable={true}
                        onChange={handleFilter}
                        fetchFunction={(handleGetCustomers)}
                        labelKeys={['name']}
                        valueKey={'name'}
                        labelWidth='w-40'
                    />,
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Facturation',
                    value: 'billing_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.invoice.billing_date)}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='invoice_billing' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Échéance',
                    value: 'due_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.invoice.due_date ? frenchFormat(item.invoice.due_date) : "-"}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='invoice_due' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Paiement',
                    value: 'payment_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.invoice.payment_date ? frenchFormat(item.invoice.payment_date) : "-"}
                            </div>
                        );
                    },
                    filterComponent: () => (
                        <TableDatePicker name='invoice_payment' onChange={handleFilter}/>
                    ),
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net HT',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {(+item.invoice.billable + +item.invoice.expenses).toLocaleString() + " €"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net TVA',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        const getVatAmount = (amount, vat) => {
                            if (!amount || !vat) return 0;

                            const percent = (amount / 100) * vat;

                            return +(amount + percent).toLocaleString();
                        }

                        return (
                            <div className="flex flex-row items-center justify-center">
                                {getVatAmount(+item.billable, +item.billable_vat_rate) + getVatAmount(+item.expenses, +item.expenses_vat_rate).toLocaleString()} €
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">

                                {displayedSection === 'Factures fournisseurs en attente' ?
                                    <>
                                        <div className="w-0 h-0 px-2"/>
                                        <ActionsButton
                                            type="overview"
                                            onClick={() => handleInvoicePreview(item)}
                                        />
                                    </>
                                    :
                                    <>
                                        <ActionsButton
                                            type="pay"
                                            isLoading={actionButtonLoading === `${item.id}upd`}
                                            onClick={() => {
                                                handlePayInvoice(item)
                                            }}
                                            disabled={actionButtonDisabled}
                                            payConfirmationQuestion='Souhaitez-vous payer cette facture ?'
                                        />
                                        <ActionsButton
                                            type="delete"
                                            isLoading={actionButtonLoading === `${item.id}del`}
                                            onClick={() => handleDeleteInvoice(item)}
                                            disabled={actionButtonDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer cette facture ?'
                                        />
                                    </>
                                }

                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
