import {RobotFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import {etpChartConfig} from '../../configs/ressourceManagementTab/supportFunctions/etpChartConfig';

const SupportFunctions = ({support}) => {
    const RobotIcon = <RobotFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const data = [
        {
            type: 'Management',
            value: support.management
        },
        {
            type: 'Support',
            value: support.support
        }
    ];

    return (
        <div className="mt-14">
            <LineTitle title="Fonctions support" textSize='text-4xl'/>

            <div className='flex justify-center mt-5'>
                <SimpleCard
                    width='w-[48%]'
                    title={support.management + support.support}
                    subTitle="ETP"
                    iconComponent={RobotIcon}
                    topComponent={<TopComponent data={data} config={etpChartConfig}/>}
                />
            </div>
        </div>
    )
}

export default SupportFunctions;

const TopComponent = ({config, data}) => {
    return (
        <div className='flex justify-center bg-white rounded-b-md'>
            <DonutsChart data={data} config={config} label=''/>
        </div>
    )
}
