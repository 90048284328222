import React, {useState, useEffect} from 'react';

function TextAreaInput({
    onChange,
    value,
    placeholder,
    maxLength,
    required,
    readOnly,
    disabled,
    error,
    height = 'h-auto',
    color = 'orange'
}) {
    const [count, setCount] = useState(0);
    const [localValue, setLocalValue] = useState();
    const [limitReached, setLimitReached] = useState(false);

    useEffect(() => {
        if (count == maxLength) {
            setLimitReached(true);
        }
    }, [count]);

    useEffect(() => {
        if (value) {
            setCount(value.length);
        }
    }, [value]);

    return (
        <div className="flex flex-col">
            <div className="flex flex-col items-center w-full">
                <textarea
                    onChangeCapture={
                        (e) => {
                            setLocalValue(e.target.value);
                            onChange(e.target.value);
                        }}
                    className={`
                        w-full border border-solid border-[#D9D9D9] rounded-sl py-3 px-3 leading-tight text-[16px] ${height} 
                        ${color === 'orange' ? 'focus:border-[#E36D38] border-[#D9D9D9]' : 'hover:border-[#19b3cc] focus:border-[#19b3cc]'}
                        outline-none placeholder:text-[#000000] placeholder:opacity-20 disabled:bg-[#D9D9D9] text-[#212121]
                    `}
                    style={{resize: 'none', whiteSpace: 'pre-wrap'}}
                    maxLength={maxLength} onChange={(e) => setCount(e.target.value.length)}
                    placeholder={placeholder} disabled={disabled} readOnly={readOnly}
                    required={required} value={value ? value : localValue}
                />
                <div className={`flex justify-end w-full mt-1 text-[14px] ${limitReached ? 'text-[#E36D38]' : 'text-[#000000] opacity-20' }`}> {count}/{maxLength}</div>
            </div>
            {error &&
            <div className="text-xs italic text-red-500">
                {error.message}
            </div>
            }
        </div>
    );
}

export default TextAreaInput;
