export const getStatusId = (status: string) => {
    switch(status) {
        case 'Prévisionnelle':
            return 0;
        case 'À émettre':
            return 50;
        case 'À valider':
            return 100;
        case 'À verifier':
            return 200;
        case 'Émise':
            return 300;
        case 'Partiellement recouvrée':
            return 400;
        case 'Recouvrée':
            return 500;
        case 'Annulée':
            return 600;
    }
}
export const getStatusFromId = (status: number) => {
    switch(status) {
        case 0:
            return 'Prévisionnelle';
        case 50:
            return 'À émettre';
        case 100:
            return 'À valider' ;
        case 200:
            return 'À verifier';
        case 300:
            return 'Émise';
        case 400:
            return 'Partiellement recouvrée';
        case 500:
            return 'Recouvrée';
        case 600:
            return 'Annulée';
    }
}

export const getTypeId = (status: string) => {
    switch(status) {
        case 'Honoraires':
            return 1;
        case 'Mise à disposition de personnel':
            return 2;
        case 'Frais':
            return 3;
    }
}
export const getTypeFromId = (status: number) => {
    switch(status) {
        case 1:
            return 'Honoraires';
        case 2:
            return 'Mise à disposition de personnel';
        case 3:
            return 'Frais';
    }
}
