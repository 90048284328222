import ActivityIndicator from './ActivityIndicator';
import ChargeIndicator from './ChargeIndicator';

const ProductionTab = ({
    productionActivity,
    dasUsers,
    productionLoad,
    handleDatePickerChange,
    datePicker,
    dasKey
}) => {
    return (
        <div>
            <div className="mt-6">
                <ActivityIndicator
                    productionActivity={productionActivity}
                    dasKey={dasKey}
                />
                <ChargeIndicator
                    data={productionLoad}
                    dasUsers={dasUsers}
                    handleDatePickerChange={handleDatePickerChange}
                    datePicker={datePicker}
                    dasKey={dasKey}
                />
            </div>
        </div>
    )
}

export default ProductionTab;
