import { Column } from '@ant-design/plots';

const GroupedColumnPlotChart = ({ data, config }) => {

    const colors = [
        '#e67a3a',
        '#212121',
        '#f0a841'
    ];

    return (
        <Column {...config(data, colors)}/>
    )
}

export default GroupedColumnPlotChart;