import React from 'react';

import {standardFormat} from '../../../utils';

const MonthCell = ({
    monthData,
    monthTitle,
    textColor,
    setMonthSelectedNumber,
    currentMonth,
    cellStyle,
    yearNumber,
    calendarConfig,
    onCalendarCellClick
}) => {
    const handleClickOnCell = () => {
        setMonthSelectedNumber(currentMonth);

        if (onCalendarCellClick) {
            onCalendarCellClick({
                type: 'month',
                date: standardFormat(`${yearNumber}-${currentMonth + 1}-01`)
            });
        }
    };

    return (
        <div
            onClick={handleClickOnCell}
            className={`
                flex flex-col justify-between cursor-pointer h-24 border-t-2 py-1 px-2 ${cellStyle}
            `}
        >
            <p className={`${textColor} text-right font-bold text-lg`} style={{fontFamily: 'DIN Condensed'}}>
                {monthTitle}
            </p>
            {calendarConfig.monthCellEventsComponent(monthData, standardFormat(`${yearNumber}-${currentMonth + 1}-01`), currentMonth, yearNumber)}
        </div>
    );
};

export default MonthCell;
