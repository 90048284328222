import {Routes, Route} from 'react-router-dom';
import UserAccountPage from '../pages/userAccountPage/UserAccountPage';

const UserAccount = () => {
    return (
        <Routes>
            <Route path="/" element={<UserAccountPage/>}/>
        </Routes>
    )
}

export default UserAccount;