import {InvoiceDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_INVOICE';

export async function getAllInvoices(
    userData: Record<string, string>,
    optionalProps: string,
    page: number,
) {
    try {
        const invoices = await InvoiceDao.getAllInvoices(userData.userToken, optionalProps, page);

        // const invoiceParsed = invoices.results.map((invoice: Record<string, any>) => InvoiceModel.parseInvoice(invoice));

        const metadata = {
            count: invoices.count,
            next: invoices.next,
            previous: invoices.previous
        };

        return {
            metadata,
            data: invoices.results
        };

    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des factures',
            error: error
        });
    }
}
