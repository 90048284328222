import {useEffect, useState} from 'react';

import {ReferenceActions} from '../../../../../actions'
import AssignAReferentPopup from '../../../popups/referencePopUps/AssignAReferentPopup';
import CreateReferencePopup from '../../../popups/referencePopUps/CreateReferencePopup';
import Badge from '../../../../../components/ui/Badge';
import Button from '../../../../../components/ui/Button';
import ActionsButton from '../../../../../components/ui/ActionsButton';
import ReferenceDetails from './ReferenceDetails';
import Popup from '../../../../../components/ui/Popup/Popup';
import Loader from '../../../../../components/loaders/Loader';

const ReferencesSection = ({blueStyle, userData, dealData, refreshData, cannotBeModified}) => {
    const [reference, setReference] = useState(null);
    const [referentPopUpView, setReferentPopUpView] = useState(false);
    const [referencePopUpView, setReferencePopUpView] = useState(false);
    const [referenceLoading, setReferenceLoading] = useState(true);

    const userRole = userData.role;
    const userId = userData.userId;

    useEffect(() => {
        if (dealData.reference) {
            (async () => {
                const result = await ReferenceActions.getReferenceById(userData, dealData.reference);

                if (result.success) {
                    setReference(result.data);
                }

                setReferenceLoading(false);
            })();
        }
        else if (dealData.id) {
            setReference(null);
            setReferenceLoading(false);
        }
    }, [dealData]);

    return (
        <>
            {
                referenceLoading ?
                    <div className='mt-32'>
                        <Loader size={40}/>
                    </div>
                    : null
            }
            {
                !referenceLoading ?
                    <div
                        className='mb-6 rounded-lg'
                        style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', border: '1px solid rgba(0, 0, 0, 0.06)'}}
                    >
                        <div className='flex flex-row items-center justify-between px-6 py-4 border-b border-gray-200'>
                            <div className='flex items-center justify-center'>
                                <div className='mr-4 text-xl font-bold' style={{fontFamily: 'DIN Condensed'}}>Référence</div>
                                {
                                    (reference && reference.owner) ?
                                        <Badge content={reference.owner.username} type='round' color='#dc3832'/>
                                        : null
                                }
                            </div>
                            {
                                ((userRole === 'PA' || userRole === 'MA') || userId === dealData.production_lead?.id) ?
                                    <>
                                        {
                                            reference ?
                                                <>
                                                    {
                                                        cannotBeModified ?
                                                            null
                                                            :
                                                            <ActionsButton
                                                                type='edit'
                                                                onClick={() => setReferentPopUpView(true)}
                                                                blueStyle={blueStyle}
                                                            />
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        cannotBeModified ?
                                                            null
                                                            :
                                                            <Button
                                                                onClick={() => setReferentPopUpView(true)}
                                                                content="Choisir un référent"
                                                                type={blueStyle ? 'blue' : 'primary'}
                                                            />
                                                    }
                                                </>
                                        }
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                    : null
            }
            {
                reference && !referenceLoading ?
                    <ReferenceDetails
                        userData={userData}
                        setReferencePopUpView={setReferencePopUpView}
                        reference={reference}
                        setReference={setReference}
                        refreshData={refreshData}
                        blueStyle={blueStyle}
                        cannotBeModified={cannotBeModified}
                    />
                    : null
            }
            <Popup
                view={referentPopUpView}
                setView={setReferentPopUpView}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
            >
                <AssignAReferentPopup
                    userData={userData}
                    dealData={dealData}
                    setView={setReferentPopUpView}
                    refreshData={refreshData}
                    reference={reference}
                    blueStyle={blueStyle}
                />
            </Popup>
            <Popup
                view={referencePopUpView}
                setView={setReferencePopUpView}
                width={'1098px'}
                maxHeight='40rem'
            >
                <CreateReferencePopup
                    userData={userData}
                    setView={setReferencePopUpView}
                    reference={reference}
                    setReference={setReference}
                    blueStyle={blueStyle}
                />
            </Popup>
        </>
    );
}

export default ReferencesSection;
