import React, {useState} from 'react'
import DefaultClassificationSubsection from './ClassificationSectionSubsections/DefaultClassificationSubsection'
import TrainingSpecialtiesSubsection from './ClassificationSectionSubsections/TrainingSpecialtiesSubsection'
import SubsidiariesSubsection from './ClassificationSectionSubsections/SubsidiariesSubsection'
import BanksSubsection from './ClassificationSectionSubsections/BankSubsection'
import ExpenseTypeSubsection from './ClassificationSectionSubsections/ExpenseTypeSubsection'
import VehicleTypeSubsection from './ClassificationSectionSubsections/VehicleTypeSubsection'
import ActivitySubsection from './ClassificationSectionSubsections/ActivitySubsection'
import DealDasSubsection from './ClassificationSectionSubsections/DealDasSubsection'
import SideBar from '../../../../../components/ui/SideBar/SideBar'
import {
    CustomerCategoryActions,
    LocationActions,
    DealOriginsActions,
    DealLossMotivesActions,
    DealAbandonmentMotivesActions,
    DealDasActions,
    SubsidiaryActions,
    ProductionCategoryActions,
    ExpenseTypeActions,
    VehicleTypeActions,
    BankActions,
    PaymentMethodActions,
    DealTagsActions,
    TrainingProductOriginActions,
    TrainingObjectiveActions,
    TrainingStudentTypesActions,
    TrainingSpecialitiesActions,
    SeniorityActions,
    ActivitiesActions,
    ContractorCategoryActions,
    ActivityTypesActions,
    AttachmentTypeActions
} from '../../../../../actions';

function ClassificationSection({data, setData, loadingTable}) {

    const [displayedMenuSection, setDisplayedMenuSection] = useState("Clients");
    const [displayedSection, setDisplayedSection] = useState("Types");
    
    return (
        <div className="flex">
            <SideBar
                displayedMenuSection={displayedMenuSection}
                setDisplayedMenuSection={setDisplayedMenuSection}
                displayedSection={displayedSection}
                setDisplayedSection={setDisplayedSection}
            />
            {
                (displayedMenuSection === "Clients" && displayedSection === "Types") ?
                    <DefaultClassificationSubsection
                        data={data.CustomerCategoryData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name: "type de client",
                            gender: "masculin"
                        }}
                        dataName={"CustomerCategoryData"}
                        actionCreate={CustomerCategoryActions.createCustomerCategory}
                        actionUpdate={CustomerCategoryActions.updateCustomerCategoryById}
                        actionDelete={CustomerCategoryActions.deleteCustomerCategoryById}
                        actionRefreshData={CustomerCategoryActions.getAllCustomerCategories}
                    />
                    : null
            }

            {
                (displayedMenuSection === "Clients" && displayedSection === "Secteurs géographiques") ?
                    <DefaultClassificationSubsection
                        data={data.LocationData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"secteur géographique",
                            gender: "masculin"
                        }}
                        dataName={"LocationData"}
                        actionCreate={LocationActions.createLocation}
                        actionUpdate={LocationActions.updateLocation}
                        actionDelete={LocationActions.deleteLocation}
                        actionRefreshData={LocationActions.getAllLocation}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Affaires" && displayedSection === "Origines") ?
                    <DefaultClassificationSubsection
                        data={data.DealOriginsData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"origine",
                            gender: "féminin"
                        }}
                        dataName={"DealOriginsData"}
                        actionCreate={DealOriginsActions.createDealOrigin}
                        actionUpdate={DealOriginsActions.updateDealOrigin}
                        actionDelete={DealOriginsActions.deleteDealOrigin}
                        actionRefreshData={DealOriginsActions.getAllDealOrigins}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Affaires" && displayedSection === "Motifs de perte") ?
                    <DefaultClassificationSubsection
                        data={data.DealLossMotivesData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"motif de perte",
                            gender: "masculin"
                        }}
                        dataName={"DealLossMotivesData"}
                        actionCreate={DealLossMotivesActions.createDealLossMotive}
                        actionUpdate={DealLossMotivesActions.updateDealLossMotive}
                        actionDelete={DealLossMotivesActions.deleteDealLossMotive}
                        actionRefreshData={DealLossMotivesActions.getAllDealLossMotives}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Affaires" && displayedSection === "Motifs d'abandon") ?
                    <DefaultClassificationSubsection
                        data={data.DealAbandonmentMotivesActions}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"motif d'abandon",
                            gender: "masculin"
                        }}
                        dataName={"DealAbandonmentMotivesActions"}
                        actionCreate={DealAbandonmentMotivesActions.createDealAbandonmentMotive}
                        actionUpdate={DealAbandonmentMotivesActions.updateDealAbandonmentMotive}
                        actionDelete={DealAbandonmentMotivesActions.deleteDealAbandonmentMotive}
                        actionRefreshData={DealAbandonmentMotivesActions.getAllDealAbandonmentMotives}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Affaires" && displayedSection === "Types des documents") ?
                    <DefaultClassificationSubsection
                        data={data.AttachmentTypesData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"type de document",
                            gender: "masculin"
                        }}
                        dataName={"AttachmentTypesData"}
                        actionCreate={AttachmentTypeActions.createAttachmentType}
                        actionUpdate={AttachmentTypeActions.updateAttachmentType}
                        actionDelete={AttachmentTypeActions.deleteAttachmentType}
                        actionRefreshData={AttachmentTypeActions.getAllAttachmentTypes}
                    />
                    : null
            }
            {
                (displayedMenuSection === "DAS" && displayedSection === "DAS") ?
                    <DealDasSubsection
                        data={data.DealDasData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"DAS",
                            gender: "masculin"
                        }}
                        dataName={"DealDasData"}
                        actionCreate={DealDasActions.createDealDas}
                        actionUpdate={DealDasActions.updateDealDas}
                        actionDelete={DealDasActions.deleteDealDas}
                        actionRefreshData={DealDasActions.getAllDealDas}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Filliale" && displayedSection === "Filliale") ?
                    <SubsidiariesSubsection
                        data={data.SubsidiaryData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"filliale",
                            gender: "féminin"
                        }}
                        dataName={"SubsidiaryData"}
                        actionCreate={SubsidiaryActions.createSubsidiary}
                        actionUpdate={SubsidiaryActions.updateSubsidiaryById}
                        actionDelete={SubsidiaryActions.deleteSubsidiaryById}
                        actionRefreshData={SubsidiaryActions.getAllSubsidiaries}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Production" && displayedSection === "Types de production") ?
                    <DefaultClassificationSubsection
                        data={data.ProductionCategoryData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"type de production",
                            gender: "masculin"
                        }}
                        dataName={"ProductionCategoryData"}
                        actionCreate={ProductionCategoryActions.createProductionCategory}
                        actionUpdate={ProductionCategoryActions.updateProductionCategory}
                        actionDelete={ProductionCategoryActions.deleteProductionCategory}
                        actionRefreshData={ProductionCategoryActions.getAllProductionCategories}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Production" && displayedSection === "Types d'activité") ?
                    <DefaultClassificationSubsection
                        data={data.ActivityTypesData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"type d'activité'",
                            gender: "masculin"
                        }}
                        dataName={"ActivityTypesData"}
                        actionCreate={ActivityTypesActions.createActivityTypes}
                        actionUpdate={ActivityTypesActions.updateActivityTypes}
                        actionDelete={ActivityTypesActions.deleteActivityTypes}
                        actionRefreshData={ActivityTypesActions.getAllActivityTypes}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Production" && displayedSection === "Activités") ?
                    <ActivitySubsection
                        data={data.ActivityData}
                        dataActivity={data.ActivityTypesData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"activité",
                            gender: "féminin"
                        }}
                        dataName={"ActivityData"}
                        actionCreate={ActivitiesActions.createActivity}
                        actionUpdate={ActivitiesActions.updateActivity}
                        actionDelete={ActivitiesActions.deleteActivity}
                        actionRefreshData={ActivitiesActions.getAllActivities}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Frais" && displayedSection === "Types") ?
                    <ExpenseTypeSubsection
                        data={data.ExpenseTypeData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"type de frais",
                            gender: "masculin"
                        }}
                        dataName={"ExpenseTypeData"}
                        actionCreate={ExpenseTypeActions.createExpenseType}
                        actionUpdate={ExpenseTypeActions.updateExpenseType}
                        actionDelete={ExpenseTypeActions.deleteExpenseType}
                        actionRefreshData={ExpenseTypeActions.getAllExpenseTypes}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Frais" && displayedSection === "Barèmes") ?
                    <VehicleTypeSubsection
                        data={data.VehicleTypeData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"barème",
                            gender: "masculin"
                        }}
                        dataName={"VehicleTypeData"}
                        actionCreate={VehicleTypeActions.createVehicleType}
                        actionUpdate={VehicleTypeActions.updateVehicleType}
                        actionDelete={VehicleTypeActions.deleteVehicleType}
                        actionRefreshData={VehicleTypeActions.getAllVehicleTypes}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Factures" && displayedSection === "Banques") ?
                    <BanksSubsection
                        data={data.BankData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"banque",
                            gender: "masculin"
                        }}
                        dataName={"BankData"}
                        actionCreate={BankActions.createBank}
                        actionUpdate={BankActions.updateBankById}
                        actionDelete={BankActions.deleteBankById}
                        actionRefreshData={BankActions.getAllBanks}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Factures" && displayedSection === "Modes encaissement") ?
                    <DefaultClassificationSubsection
                        data={data.PaymentMethodData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"mode encaissement",
                            gender: "masculin"
                        }}
                        dataName={"PaymentMethodData"}
                        actionCreate={PaymentMethodActions.createPaymentMethod}
                        actionUpdate={PaymentMethodActions.updatePaymentMethodById}
                        actionDelete={PaymentMethodActions.deletePaymentMethodById}
                        actionRefreshData={PaymentMethodActions.getAllPaymentMethods}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Séniorité" && displayedSection === "Séniorité") ?
                    <DefaultClassificationSubsection
                        data={data.SeniorityData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"séniorité",
                            gender: "féminin"
                        }}
                        dataName={"SeniorityData"}
                        actionCreate={SeniorityActions.createSeniority}
                        actionUpdate={SeniorityActions.updateSeniority}
                        actionDelete={SeniorityActions.deleteSeniority}
                        actionRefreshData={SeniorityActions.getAllSeniority}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Références" && displayedSection === "Tags") ?
                    <DefaultClassificationSubsection
                        data={data.DealTagsData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"tag",
                            gender: "masculin"
                        }}
                        dataName={"DealTagsData"}
                        actionCreate={DealTagsActions.createDealTag}
                        actionUpdate={DealTagsActions.updateDealTag}
                        actionDelete={DealTagsActions.deleteDealTag}
                        actionRefreshData={DealTagsActions.getAllDealTags}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Sous/Co-traitants" && displayedSection === "Catégories") ?
                    <DefaultClassificationSubsection
                        data={data.ContractorCategoryData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"catégorie de sous/co-traitant",
                            gender: "féminin"
                        }}
                        dataName={"ContractorCategoryData"}
                        actionCreate={ContractorCategoryActions.createContractorCategory}
                        actionUpdate={ContractorCategoryActions.updateContractorCategory}
                        actionDelete={ContractorCategoryActions.deleteContractorCategory}
                        actionRefreshData={ContractorCategoryActions.getAllContractorCategories}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Formations" && displayedSection === "Nature de la formation") ?
                    <DefaultClassificationSubsection
                        data={data.TrainingProductOriginData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"nature de la formation",
                            gender: "féminin"
                        }}
                        dataName={"TrainingProductOriginData"}
                        actionCreate={TrainingProductOriginActions.createTrainingProductOrigin}
                        actionUpdate={TrainingProductOriginActions.updateTrainingProductOrigin}
                        actionDelete={TrainingProductOriginActions.deleteTrainingProductOrigin}
                        actionRefreshData={TrainingProductOriginActions.getAllTrainingProductOrigin}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Formations" && displayedSection === "Objectifs générales de formation") ?
                    <DefaultClassificationSubsection
                        data={data.TrainingObjectiveData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"objectif de formation",
                            gender: "masculin"
                        }}
                        dataName={"TrainingObjectiveData"}
                        actionCreate={TrainingObjectiveActions.createTrainingObjective}
                        actionUpdate={TrainingObjectiveActions.updateTrainingObjective}
                        actionDelete={TrainingObjectiveActions.deleteTrainingObjective}
                        actionRefreshData={TrainingObjectiveActions.getAllTrainingObjective}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Formations" && displayedSection === "Type de stagiaires") ?
                    <DefaultClassificationSubsection
                        data={data.TrainingStudentTypesData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"type de stagiaires",
                            gender: "masculin"
                        }}
                        dataName={"TrainingStudentTypesData"}
                        actionCreate={TrainingStudentTypesActions.createTrainingStudentType}
                        actionUpdate={TrainingStudentTypesActions.updateTrainingStudentType}
                        actionDelete={TrainingStudentTypesActions.deleteTrainingStudentType}
                        actionRefreshData={TrainingStudentTypesActions.getAllTrainingStudentTypes}
                    />
                    : null
            }
            {
                (displayedMenuSection === "Formations" && displayedSection === "Spécialité de formation") ?
                    <TrainingSpecialtiesSubsection
                        data={data.TrainingSpecialitiesData}
                        setData={setData}
                        loadingTable={loadingTable}
                        sectionName={{
                            name:"spécialité de formation",
                            gender: "féminin"
                        }}
                        dataName={"TrainingSpecialitiesData"}
                        actionCreate={TrainingSpecialitiesActions.createTrainingSpeciality}
                        actionUpdate={TrainingSpecialitiesActions.updateTrainingSpeciality}
                        actionDelete={TrainingSpecialitiesActions.deleteTrainingSpeciality}
                        actionRefreshData={TrainingSpecialitiesActions.getAllTrainingSpecialities}
                    />
                    : null
            }
        </div>
    )
}

export default ClassificationSection;
