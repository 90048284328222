import {Empty} from "antd";

import ContainerCard from "../../../../components/ui/ContainerCard/ContainerCard";
import GroupedColumnPlotChart from "../../../../components/ui/GroupedColumnPlotChart/GroupedColumnPlotChart";
import {ressourceNumberChartConfig} from "../../configs/ressourceManagementTab/dasTeamSection/ressourceNumberChartConfig";
import {dataPerSector} from "../../../codevDashboardPage/functions/aggregateRessourcesProjection";

const ResourcesNumber = ({data, dasKey}) => {

    return (
        <div className='mt-6'>
            <ContainerCard
                title={''}
                showHeader={false}
                coloredButtonDisplayed={false}
                height="min-h-[450px]"
            >
                {
                    data.projection[dasKey] ?
                        <GroupedColumnPlotChart
                            data={dataPerSector(data, dasKey)}
                            config={ressourceNumberChartConfig}
                        /> :
                        <div>
                            <h2
                                className="text-[#212121] font-bold mt-[9px]"
                                style={{fontFamily: 'DIN Condensed', fontSize: '22px', lineHeight: '25px'}}
                            >
                            Effectif
                            </h2>
                            <div className="mt-[100px]">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                            </div>
                        </div>
                }
            </ContainerCard>
        </div>
    );
};

export default ResourcesNumber;
