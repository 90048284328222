import React, { useState, useEffect } from 'react';
import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const FileUploadInput = ({
    onChange,
    setFilename,
    maxCount = 1,
    uploadText = 'Glissez déposez ou sélectionnez un fichier',
    uploadHint = 'Ajoutez ici un document (PDF, JPEG, PDF) lié à l\'affaire.',
    color = '#E36D38'
}) => {

    const [localFileList, setLocalFileList] = useState([]);

    const handleRemove = (file) => {
        const updatedFileList = localFileList.filter(item => item.uid !== file.uid);
        setLocalFileList(updatedFileList);
        setFilename("");
        message.success(`${file.name} supprimé`);
    };

    const handleBeforeUpload = (file) => {
        setLocalFileList([file]);
        setFilename(file.name);
        message.success(`${file.name} ajouté`);

        return false;
    };

    useEffect(() => {
        onChange(maxCount > 1 ? localFileList : localFileList[0]);
    }, [localFileList, maxCount, onChange]);

    const props = {
        onRemove: handleRemove,
        beforeUpload: handleBeforeUpload,
        maxCount,
        fileList: localFileList
    };

    return (
        <div className=''>
            <Dragger {...props}>
                <InboxOutlined
                    style={{
                        color: color,
                        fontSize: "42px",
                        width: "968px",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                />
                <p className="ant-upload-text">{uploadText}</p>
                <p className="ant-upload-hint">{uploadHint}</p>
            </Dragger>
        </div>
    )
}

export default FileUploadInput;
