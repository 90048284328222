import {DashboardFilled} from '@ant-design/icons';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard'

const LoadingRateCards = ({data}) => {

    const getPercent = (key) => {
        if (!data[key] || !data[key].billed_days || !data[key].produced_days) {
            return 0;
        }
        else {
            return Math.round((data[key].billed_days / data[key].produced_days) * 100);
        }
    }

    const dashboardIcon = <DashboardFilled style={{ fontSize: '30px', color: 'white' }}/>;

    return (
        <div className='flex justify-between mt-3'>
            <SimpleCard
                width='w-[31%]'
                iconComponent={dashboardIcon}
                title={`${getPercent('junior')} %`}
                background='orange'
                subTitle="Taux de facturation profil Junior"
            />

            <SimpleCard
                width='w-[31%]'
                iconComponent={dashboardIcon}
                title={`${getPercent('confirmed')} %`}
                background='orange'
                subTitle="Taux de facturation profil Confirmé"
            />

            <SimpleCard
                width='w-[31%]'
                iconComponent={dashboardIcon}
                title={`${getPercent('senior')} %`}
                background='orange'
                subTitle="Taux de facturation profil Sénior"
            />
        </div>
    )
}

export default LoadingRateCards;
