import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {frenchFormat} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';

export const expenseListConfig = (
    onDeleteExpense,
    deleteExpenseLoading,
    setIsExpensePopupOpen,
    setExpenseToBeUpdated,
    buttonsDisabled
) => {
    return {
        dropdown: false,
        dropdownLeftPadding: true,
        greyBackground: false,
        subline: true,
        columnConfig: [
            {
                label: '#',
                value: 'id',
                width: 'w-[4%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item.id}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Affaire',
                value: 'deal.short_name',
                width: 'w-[20%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center text-center">
                            {item.deal.short_name}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Type',
                value: 'type.name',
                width: 'w-[9%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item.type.name}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Date',
                value: 'date',
                width: 'w-[7%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {frenchFormat(item.date)}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Refact.',
                value: 'billable',
                width: 'w-[4%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {item.billable ? 'Oui' : 'Non'}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Montant',
                value: 'amount',
                width: 'w-[5%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+(parseInt(item.amount).toLocaleString())}
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: '€'
            },
            {
                label: 'TVA',
                value: 'vat',
                width: 'w-[5%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+(parseInt(item.vat).toLocaleString())}
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: '€'
            },
            {
                label: 'CO2',
                value: 'co2',
                width: 'w-[5%]',
                sort: true,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {+(item.co2)}
                        </div>
                    );
                },
                sumDisplayed: true,
                sumBy: 'value',
                sumLabel: ''
            },
            {
                label: 'notes',
                value: null,
                width: 'w-[10%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center text-center">
                            {item.notes ? item.notes : '-'}
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            },
            {
                label: 'Actions',
                value: null,
                width: 'w-[7%]',
                sort: false,
                component: (item) => {
                    return (
                        <div className="flex flex-row items-center justify-center">
                            {
                                item.parentStatus === 3 ?
                                    <>
                                        <EditOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                        <div className="w-0 h-0 px-2.5"/>
                                        <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/>
                                    </> :
                                    <>
                                        <ActionsButton
                                            type="edit"
                                            isLoading={false}
                                            onClick={() => {
                                                setIsExpensePopupOpen(true);
                                                setExpenseToBeUpdated(item);
                                            }}
                                            disabled={buttonsDisabled}
                                        />
                                        <div className="w-0 h-0 px-2"/>
                                        <ActionsButton
                                            type="delete"
                                            isLoading={deleteExpenseLoading === item.id}
                                            onClick={() => onDeleteExpense(item)}
                                            disabled={buttonsDisabled}
                                            deleteConfirmationQuestion='Souhaitez-vous supprimer ce frais ?'
                                        />
                                    </>
                            }
                        </div>
                    );
                },
                sumDisplayed: false,
                sumBy: '',
                sumLabel: ''
            }
        ]
    };
};
