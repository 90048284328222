import React, {useEffect, useState} from 'react';

import ContainerCard from '../../../components/ui/ContainerCard';
import Table from '../../../components/ui/Table';
import {exportDetailsDataToCsv} from '../functions/exportDataToCsv';

const DetailsTable = ({data, tableConfig, onNewLineButtonClick}) => {
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    // CSV export states
    const [csvProps, setCsvProps] = useState({
        filename: 'empty.csv',
        headers: [],
        data: []
    });
    
    const [csvExportLoading, setCsvExportLoading] = useState(false);

    useEffect( () => {
        if (Object.keys(data).length > 0) {
            setTableLoading(true);
            let aggregatedData = data.items;
            aggregatedData = aggregatedData.map((item) => {
                const getNetAmount = () => {
                    const vat = ((+item.quantity * +item.unit_price) / 100) * +item.vat;
                    const amount = +item.quantity * +item.unit_price;
                    
                    return +(amount + vat).toFixed(3)
                }

                return (
                    {
                        ...item,
                        amount: data.expenses,
                        net_amount: getNetAmount(),
                        ht_amount: +(+item.quantity * +item.unit_price).toFixed(3)
                    }
                );
            });
            setTableData(aggregatedData.reverse());
            setTableLoading(false);
        }
    }, [data])

    return (
        <ContainerCard
            title='Détails'
            coloredButtonDisplayed={data.status < 300}
            buttonContent='Nouvelle ligne'
            onButtonClick={onNewLineButtonClick}
            downloadButtonDisplayed={tableData.length > 0 && !tableLoading}
            onDownloadButtonClick={() => exportDetailsDataToCsv(
                tableData,
                setCsvProps,
                setCsvExportLoading
            )}
            csvProps={csvProps}
            csvExportLoading={csvExportLoading}
        >
            <Table
                data={tableData}
                tableConfig={tableConfig}
                loading={tableLoading}
            />
        </ContainerCard>
    )
}

export default DetailsTable
