import { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import {LoadingOutlined} from '@ant-design/icons';

import tableArrowDown from '../../../assets/icons/table/table-arrow-down.svg';
import Loader from '../../../components/loaders/Loader';

import './select-input.css'

/**
 * Composant SelectInput
 *
 * @component
 * @param {Object} props - Les props du composant
 * @param {Object} props.value - La valeur sélectionnée
 * @param {Function} props.onChange - La fonction de rappel appelée lorsque la sélection change (React Hook Form)
 * @param {Array} props.options - Les options disponibles pour le dropdown
 * @param {React.Ref} props.ref - La référence de l'input du dropdown
 * @param {Function} props.fetchFunction - La fonction de récupération des options supplémentaires (Optionnel)
 * @param {Array} props.labelKeys - Les clés des labels à afficher pour chaque option
 * @param {string} [props.placeholder='Sélectionnez une option'] - Le texte de l'espace réservé du dropdown (par défaut : 'Sélectionnez une option')
 * @returns {JSX.Element} Composant SelectInput
 */

const getOptionStyles = (borderColor, backgroundColor, menuMaxHeight) => ({
    control: (baseStyles, { isFocused }) => ({
        ...baseStyles,
        border: isFocused ? `1px solid ${borderColor}` : '1px solid #D9D9D9',
        borderRadius: '0',
        outline: 'none',
        boxShadow: 'none',
        height: '24px',
        minHeight: '24px',
        ':hover': {
            ...baseStyles[':hover'],
            border: `1px solid ${borderColor}`,
            cursor: 'pointer'
        }
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        height: 'fit-content',
        maxHeight: menuMaxHeight
    }),
    option: (baseStyles, { isFocused, isSelected, isDisabled }) => ({
        ...baseStyles,
        background: isDisabled
            ? undefined
            : isSelected
                ? backgroundColor
                : isFocused
                    ? backgroundColor
                    : undefined,
        color: isFocused ? 'white' : isSelected ? 'white' : 'black',
        fontSize: '14px',
        fontWeight: '400',
        minHeight: '28px',
        height: 'fit-content',
        margin: '0',
        padding: '3px 10px 0',
        ':hover': {
            ...baseStyles[':hover'],
            cursor: 'pointer'
        },
        ':active': {
            ...baseStyles[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? backgroundColor
                    : borderColor
                : undefined
        }
    })
});

export const SelectInput = ({
    onChange,
    options,
    valueKey,
    name,
    loading = false,
    fetchFunction,
    labelKeys,
    separator = ' ',
    isSearchable = false,
    hasNextPage = false,
    menuHeight = '350px',
    resetWhenValueChanges = null
}) => {
    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(null);

    const selectRef = useRef(null);

    const borderColor = '#E36D38';
    const backgroundColor = 'linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%)';
  
    const optionStyles = getOptionStyles(borderColor, backgroundColor, menuHeight);

    const createOptionsWithLabel = (data) => {
        return data.map((option) => {
            const label = labelKeys.map((key) => option[key]).join(separator);
    
            return { label, value: option };
        });
    };

    useEffect(() => {
        if (!options) return;
        setDisplayedOptions([
            { label: '', value: ''},
            ...createOptionsWithLabel(options)
        ]);
    }, [options]);

    useEffect(() => {
        if (fetchFunction && searchTerm) {
            setFetchLoading(true);

            const timer = setTimeout(() => {
                setDebouncedSearchTerm(searchTerm);
            }, 500);
      
            return () => {
                clearTimeout(timer);
            };
        }
        setDebouncedSearchTerm('');
    }, [searchTerm]);

    useEffect(() => {
        if (fetchFunction) {
            if (debouncedSearchTerm) {
                fetchFunction(debouncedSearchTerm);
            }
            else {
                fetchFunction('', 1);
            }
            setFetchLoading(false);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (resetWhenValueChanges) {
            setDefaultValue(null);
        }
    }, [resetWhenValueChanges]);

    const handleLoadMore = async () => {
        if (fetchLoading || !fetchFunction || searchTerm || debouncedSearchTerm || !hasNextPage) {
            return;
        }
        
        if (!(fetchFunction && !fetchLoading)) {
            return;
        }
        setFetchLoading(true);
            
        const nextPage = currentPage + 1;
        await fetchFunction('', nextPage);
        setCurrentPage(nextPage);
            
        setFetchLoading(false);
    };

    const handleChange = (selectedOption) => {
        setDefaultValue(selectedOption);

        if(selectedOption) {
            onChange(selectedOption.value[valueKey], name);
        }
        else {
            onChange('', name);
        }
    };

    const handleInputChange = async (inputValue) => {
        if (inputValue === '') {
            setSearchTerm('');
            setFetchLoading(false);

            return;
        }
      
        setSearchTerm(inputValue);
    };

    const formatOptionLabel = ({ label, value, ...rest }) => (
        <components.Option {...rest}>{value === '' ? '-' : label}</components.Option>
    );

    return (
        <>
            <Select
                ref={selectRef}
                options={displayedOptions}
                inputValue={searchTerm}
                value={defaultValue}
                placeholder=''
                menuPortalTarget={document.body}
                isSearchable={isSearchable}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onMenuScrollToBottom={handleLoadMore}
                noOptionsMessage={fetchLoading ? () => 'Chargement...' : () => "Aucune option"}
                styles={optionStyles}
                className="table-select-input-container"
                classNamePrefix="table-select-input"
                components={{
                    DropdownIndicator: fetchLoading ?
                        () =>  <LoadingOutlined style={{margin: '0 10px'}}/> :
                        () => <img src={tableArrowDown} alt="arrow-down"/>,
                    IndicatorSeparator: () => null,
                    Option: formatOptionLabel
                }}
            />
            {loading &&
                    <div className="absolute border border-[#D9D9D9] top-0 left-0 w-full h-full bg-[#EFEFEF]">
                        <div className="flex flex-row items-center justify-center w-full h-full">
                            <Loader size={10}/>
                            <span className="ml-1 text-xs text-[#646464]">Chargement</span>
                        </div>
                    </div>
            }
        </>
    );
};
