import {ACTION_CODE} from './_actionCode';
import {MissionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_MISSION';

export async function createMission(
    userData: Record<string, string>,
    newMission: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createMission - Start');

        const createMissionResult = await MissionService.createMission(userData, newMission);

        LoggerService.logInfo('Action - createMission - End - Success');

        return ResultModel.newSuccessResult(createMissionResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createMission - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
