import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import ContainerCard from '../../../../../components/ui/ContainerCard';
import TextInput from '../../../../../components/ui/Inputs/TextInput';
import Button from '../../../../../components/ui/Button';
import {notify} from '../../../../../components/ui/Toast/Toast';
import Error from '../../../../../components/ui/Error/Error';
import {getErrors} from '../../../../../utils';
import {ServicesActions} from "../../../../../actions"
import TextAreaInput from '../../../../../components/ui/Inputs/TextAreaInput';

const CreateNewOperation = ({
    userData,
    toBeUpdated,
    refreshData,
    setView
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    // -- react hook form --
    const defaultValues = {
        name: '',
        short_name: '',
        notes: ''
    };

    const {handleSubmit, control, reset , formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- fill expense values in form for update --
    useEffect(() => {
        if (toBeUpdated) {
            reset({
                name: toBeUpdated.name,
                short_name: toBeUpdated.short_name,
                notes: toBeUpdated.notes
            });
        }
    }, [toBeUpdated]);

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newItem = {
            name: data.name,
            short_name: data.short_name,
            notes: data.notes
        }

        if (!toBeUpdated) {
            const result = await ServicesActions.createService(userData.userToken, newItem);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                setView(false);
                notify("L'opération a bien été créée");
            }
        }
        else {
            const result = await ServicesActions.updateService(userData.userToken, toBeUpdated.id, newItem);
            
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                setView(false);
                notify("L'opération a bien été mise à jour");
            }
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={"Ajouter une operation"}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row justify-between px-4 pt-2 pb-1">
                <div className="mb-3">
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Nom complet'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nom complet"
                                type="text"
                            />
                        )}
                    />
                </div>
                <div className="mb-3">
                    <Controller
                        name="short_name"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Nom abrégé'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nom abrégé"
                                type="text"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            isDisabled={disabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={`${toBeUpdated ? "Modifier l'opération" : "Ajouter l'opération"}`}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewOperation;
