import {ErrorModel, ResultModel} from '../../models';
import {LoggerService, UserService} from '../../services';
import {ACTION_CODE} from './_actionCode';
const FUNCTION_CODE = 'SET_USER_PASSWORD';

export async function setUserPassword(userToken: string, userId: string, bodyPassword: any) {
    try {
        LoggerService.logInfo('Action - setUserPassword - Start');

        const setUserPassword = await UserService.setUserPassword(userToken, userId, bodyPassword);

        return ResultModel.newSuccessResult(setUserPassword);
    }
    catch (error) {
        LoggerService.logInfo('setUserPassword - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
