export const aggregateTotalTraitants = (data: Record<string, any>, traitant: string, type:string) => {
    let total = 0;

    for (const key in data) {

        type === "days" ? total += data[key][traitant].days :
            type === "commission" ? total += data[key][traitant].commission :
                type === "count" ? total += data[key][traitant].count :
                    type === "amount" ? total += data[key][traitant].amount :
                        null
    }

    return total;
}