import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import Button from '../../../../components/ui/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from './../../../../utils';
import { capitalize } from './../../../../utils';
import { LocationActions } from '../../../../actions';

const LocationPopup = ({
    setView,
    userData,
    refreshData
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const status = [
        {
            id: 1,
            name: "Actif",
            value: true
        },
        {
            id: 2,
            name: "Archivé",
            value: false
        }
    ];

    // -- react hook form --
    const defaultValues = {
        name: '',
        status: status[0]
    };
    
    const {handleSubmit, control, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newItem = {
            name: data.name,
            is_active: data.status.value
        }
        
        const result = await LocationActions.createLocation(userData, newItem);
        
        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData("", 1)
            setView(false);
            notify("Le secteur géographique a été ajouté avec succès");
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={`Ajouter un secteur géographique`}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="w-[800px] flex flex-row justify-between px-4 pt-2 pb-6">
                <div className="mb-3">
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                labelWidth='w-16'
                                width='w-[300px]'
                                label='Nom'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nom"
                                type="text"
                            />
                        )}
                    />
                </div>
                <div className="mb-3">
                    <Controller
                        name="status"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir un statut'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label='Statut'
                                placeholder='Statut'
                                labelWidth='w-16'
                                width='w-[300px]'
                                inputRef={ref}
                                error={error}
                                value={value}
                                options={status}
                                onChange={onChange}
                                labelKeys={['name']}
                                required={true}
                                disabled={disabled}
                            />
                        )
                        }
                    />
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content="Ajouter un secteur géographique"
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default LocationPopup;
