import {DashboardDao} from '../../../dao';
import {ErrorModel} from '../../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_SATISFACTION_SURVEYS';

export async function getSatisfactionSurveys(userData: Record<string, string>, params: Record<string, any>) {
    try {
        const result = await DashboardDao.OfficeManagerDao.getSatisfactionSurveys(userData.userToken, params);

        const metadata = {
            count: result.count,
            next: result.next,
            previous: result.previous
        };

        return {
            metadata,
            data: result.satisfaction_surveys
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
