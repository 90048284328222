import OfficeLoadTeamTabChildren from "../../components/productionTab/OfficeLoadTeamTabChildren";
import DasLoadTeamTabChildren from "../../components/productionTab/DasLoadTeamTabChildren";
import {dasTeamRessources, cabinetTeamRessources, calculateDasPercentageByDasLoad, calculateTotalLoadPercentage} from "../../functions/aggregateDasTeam";

export const dasTeamTabConfig = (productionLoad, selectedLoad, setSelectedLoad, users) => {

    const tabs = [{
        id: 1,
        label: 'Cabinet',
        optionalAction: () => setSelectedLoad({
            selected: "Cabinet",
            load: calculateTotalLoadPercentage(productionLoad.ressources)

        }),
        component: <OfficeLoadTeamTabChildren
            data={cabinetTeamRessources(productionLoad.ressources)}
        />

    }]

    for (const key in productionLoad.load) {
        const item = {
            id: key,
            label: key,
            optionalAction: () => setSelectedLoad({
                selected: key,
                load: calculateDasPercentageByDasLoad(productionLoad.ressources, key)

            }),
            component: <DasLoadTeamTabChildren
                data={dasTeamRessources(productionLoad.ressources, key)}
                users={users}
            />

        }
    
        tabs.push(item);
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
