import {ACTION_CODE} from './_actionCode';
import {TrainingSpecialitiesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'UPDATE_TRAINING_SPECIALITY';

export async function updateTrainingSpeciality(
    userData: Record<string, string>,
    id: number,
    updatedTrainingSpeciality: Record<string, any>,
) {
    try {

        LoggerService.logInfo('Action - updateTrainingSpeciality - Start');

        const updateTrainingSpecialityResult = await TrainingSpecialitiesService.updateTrainingSpeciality(userData, id, updatedTrainingSpeciality);

        LoggerService.logInfo('Action - updateTrainingSpeciality - End - Success');

        return ResultModel.newSuccessResult(updateTrainingSpecialityResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateTrainingSpeciality - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
