import {IdcardFilled} from '@ant-design/icons';
import Progress from 'antd/lib/progress';

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import Badge from '../../../../components/ui/Badge';
import {dasProductionChartConfig} from '../../configs/productionTab/chargeIndicatorSection/dasProductionChartConfig';
import {concernedDasRessourcesCount, userDasLoad} from '../../../codevDashboardPage/functions/aggregateLoad';

const DasLoadTabChildren = ({data, dasKey, dasUsers}) => {

    const IdcardFilledIcon = <IdcardFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    const chartData = [
        {
            type: 'Junior:',
            value: data.load[dasKey] ? data.load[dasKey]["Junior"] : 0
        },
        {
            type: 'Confirmé:',
            value: data.load[dasKey] ? data.load[dasKey]["Confirmé"] : 0
        },
        {
            type: 'Sénior:',
            value: data.load[dasKey] ? data.load[dasKey]["Sénior"] : 0
        }
    ];

    return (
        <div className='flex justify-between mt-10'>
            <div className='w-[48%] self-center'>
                <SimpleCard
                    width="w-full"
                    background="white"
                    font="orange"
                    lineColor="black"
                    title={data.load[dasKey] ? concernedDasRessourcesCount(data.ressources, dasKey) : 0}
                    shadow={false}
                    borderSize='border'
                    subTitle={"Consultants"}
                    iconComponent={IdcardFilledIcon}
                    topComponent={<TopComponent data={data.ressources} dasKey={dasKey} dasUsers={dasUsers}/>}
                />
            </div>
            <div className='w-[48%] h-auto'>
                <DonutsChart data={chartData} config={dasProductionChartConfig} label=''/>
            </div>
        </div>
    )
}

export default DasLoadTabChildren;

const TopComponent = ({data, dasKey, dasUsers}) => {

    const ressources = [];

    for (const ressource in data) {
        if (data[ressource].currentdas === dasKey && data[ressource].load[dasKey] > 0) {
            ressources.push({
                ressource: ressource,
                ...data[ressource]
            });
        }
    }

    return (
        <div className='flex flex-wrap pb-5 bg-white rounded-b-md'>
            {
                ressources.map((el, index) => {

                    const userInfos = dasUsers.filter((user) => user.username === el.ressource);

                    return (
                        <div key={index} className='flex items-center justify-between w-1/2 px-5 pt-5 gap-x-2'>
                            <Badge
                                style={{width: '48px'}}
                                content={userInfos.length > 0 ? userInfos : el.ressource}
                                type='rounded'
                            />
                            <Progress
                                percent={userDasLoad(el)}
                                showInfo={true}
                                strokeColor='#e98b4e'
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}
