import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ArrowLeftOutlined} from '@ant-design/icons';

import Table from '../../components/ui/Table';
import {notify} from '../../components/ui/Toast/Toast';
import {ExpenseReportActions} from '../../actions';
import {currentExpenseReportsTableConfig} from './configs/currentExpenseReportsTableConfig';

const CurrentExpenseReportsPage = ({
    data,
    refreshData,
    filterExpenseReports,
    tableType,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [actionButtonLoading, setActionButtonLoading] = useState(null);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleDeleteExpenseReport = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(item.id)

        const result = await ExpenseReportActions.deleteExpenseReportById(userData, item.id);

        if (result.success) {
            await refreshData(true);
            notify('La note de frais à bien été supprimé');
        }
        else {
            notify('La note de frais n\'a pas pu être supprimé');
        }

        setActionButtonLoading(null)
        setActionButtonDisabled(false);
    }

    const tableConfig = currentExpenseReportsTableConfig(
        handleDeleteExpenseReport,
        actionButtonLoading,
        actionButtonDisabled,
        filterExpenseReports,
        tableType,
        userData
    );

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/admin'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Notes de frais en cours (${paginationMetadata.filteredOutCount})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
        </>
    )
}

export default CurrentExpenseReportsPage;
