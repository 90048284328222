import {ACTION_CODE} from './_actionCode';
import {UserService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_VEHICLETYPE';

export async function updateUser(
    userData: Record<string, any>,
    id: number,
    payload: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateUserById - Start');

        const updateUserResult =
            await UserService.updateUser(userData, id, payload);

        LoggerService.logInfo('Action - updateUserById - End - Success');

        return ResultModel.newSuccessResult(updateUserResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateUserById - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur de mise à jour d\'un user',
            error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
