import * as z from 'zod';
import * as vacationFunctions from './functions';

export const VacationSchema = z.object({
    start: z.string(),
    start_morning: z.boolean(),
    end: z.string(),
    end_morning: z.boolean()
});

export const VacationPartialSchema = VacationSchema.partial();
export type VacationPartial = z.infer<typeof VacationPartialSchema>;
export type Vacation = z.infer<typeof VacationSchema>;

export function newVacation(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...VacationSchema.parse({...data}),
        ...vacationFunctions
    };
}

export function parseProduction(data: any, partial = false) {
    if (!partial) {
        return {
            ...VacationSchema.parse(data),
            ...vacationFunctions
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...VacationPartialSchema.parse(data),
            ...vacationFunctions
        };
    }
}
