import {useNavigate} from 'react-router-dom';

import Badge from "../../../../components/ui/Badge";
import {frenchFormat} from "../../../../utils";
import ActionsButton from "../../../../components/ui/ActionsButton";

export const customerInvoicesTableConfig = () => {
    const navigate = useNavigate();
    
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Numéro facture',
                    value: 'official_id',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.official_id ? `N°${item.official_id}` : "N/A"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        const spendingStatus = () => {
                            switch (item.status) {
                                case 0:
                                    return {status: 'Prévisionnelle', color: null};
                                case 50:
                                    return {status: 'A émettre', color: '#7FFFD4'};
                                case 100:
                                    return {status: 'À valider', color: '#f0a841'};
                                case 200:
                                    return {status: 'Validée', color: '#e36d38'};
                                case 300:
                                    return {status: 'Émise', color: '#ced95f'};
                                case 400:
                                    return {status: 'Partiellement Réouvrée', color: '#ced95f'};
                                case 500:
                                    return {status: 'Payée', color: '#ced95f'};
                                case 600:
                                    return {status: 'Annulée', color: '#ced95f'};
                                default:""

                                    return {status: 'Indéfini', color: '#212121'};
                            }
                        };

                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <Badge content={spendingStatus().status} color={spendingStatus().color}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Resp. production',
                    value: 'deal.production_lead.username',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <Badge content={[item.deal.production_lead]} type='round'/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Facturation',
                    value: 'billing_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {frenchFormat(item.billing_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Echéance',
                    value: 'due_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {frenchFormat(item.due_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Paiement',
                    value: 'payment_date',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.payment_date ? frenchFormat(item.payment_date) : '-'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net HT',
                    value: 'billable',
                    width: 'w-[12%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <p className={`${item.credit && 'text-red-500'}`}>
                                    {`${item.credit ? '-' : '' }${(+item.billable + +item.expenses).toFixed(2).toLocaleString()} €`}
                                </p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total TVA',
                    value: 'billable_vat',
                    width: 'w-[12%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <p className={`${item.credit && 'text-red-500'}`}>
                                    {`${item.credit ? '-' : ''}${(+item.billable + +item.billable * (+item.billable_vat_rate / 100) + +item.expenses + +item.expenses * (+item.expenses_vat_rate / 100))
                                        .toFixed(2).toLocaleString()} €`}
                                </p>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Détails',
                    value: null,
                    width: 'w-[10%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => navigate(`/invoices/${item.id}`)}
                                    disabled={false}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
