import React from 'react';

import Loader from '../../loaders/Loader';

const PageLoader = ({children, loading}) => {
    return (
        <>
            {
                loading ? (
                    <div className='fixed z-50 w-full max-w-[1600px] h-full backdrop-blur-sm'>
                        <div
                            className="fixed z-50 backdrop-blur-0 flex flex-col items-center justify-center text-[#646464] font-medium"
                            style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <Loader size={30}/>
                            Chargement
                        </div>
                    </div>
                ) : null
            }
            {children}
        </>
    )
}

export default PageLoader;
