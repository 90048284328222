import {ACTION_CODE} from './_actionCode';
import {ExpenseTypeService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_EXPENSETYPE';

export async function deleteExpenseType(userData: Record<string, any>, expenseTypeId: number) {
    try {
        LoggerService.logInfo('Action - deleteExpenseTypeById - Start');

        const deleteExpenseTypeByIdResult = await ExpenseTypeService.deleteExpenseType(userData, expenseTypeId);

        LoggerService.logInfo('Action - deleteExpenseTypeById - End - Success');

        return ResultModel.newSuccessResult(deleteExpenseTypeByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteExpenseTypeById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
