import {ExpenseAttachmentDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DOWNLOAD_EXPENSE_ATTACHMENT';

export async function downloadExpenseAttachment(userData: Record<string, string>, id: string) {
    try {
        const downloadExpenseAttachmentResult = await ExpenseAttachmentDao.downloadExpenseAttachment(userData.userToken, id);

        return downloadExpenseAttachmentResult;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des attachments de frais',
            error: error
        });
    }
}
