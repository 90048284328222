import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_ALL_EXPENSES';

export async function getAllExpenseAttachments(
    userToken: string,
    reportId: string
) {
    try {
        const response = await axios.get(
            // eslint-disable-next-line max-len
            `${URL}/expense-attachments/?report=${reportId}&page_size=2000&active=true`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la liste des pièces jointes',
            error: error
        });
    }
}
