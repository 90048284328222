import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

import {CustomerActions} from '../../../../actions';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard'
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput'
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const CustomerNotes = ({
    userData,
    data,
    customerId,
    setView,
    refreshCustomerData,
    blueStyle
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    // -- react hook form --
    const defaultValues = {
        notes: data.notes || ''
    };

    const {handleSubmit, control, formState: {isValid}} = useForm({defaultValues: defaultValues});

    const onSubmit = async (formData) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const dataUpdatedWithNotes = {
            notes: formData.notes
        }

        const result = await CustomerActions.updateCustomer(userData, dataUpdatedWithNotes, customerId);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshCustomerData();
            notify(data.notes ? 'La note à été modifiée' : 'La note à été créée');
            setView(false);
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard title='Nouvelle note' coloredButtonDisplayed={false}>
            <Controller
                name="notes"
                control={control}
                rules={{required: {
                    value: true,
                    message: 'Veuillez saisir une note'
                }}}
                render={({
                    field: {onChange, value},
                    fieldState: {error}
                }) => (
                    <TextAreaInput
                        label='notes'
                        onChange={onChange}
                        maxLength={100}
                        value={value}
                        error={error}
                        required={false}
                        placeholder="Tapez votre texte"
                        type="text"
                        height='h-24'
                    />
                )}
            />
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type='white'
                        content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content={data.notes ? 'Modifier la note' : 'Créer la note'}
                        loading={loading}
                        disabled={!isValid || disabled}
                        type={blueStyle ? 'blue' : 'primary'}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CustomerNotes;
