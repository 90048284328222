export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Octet'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const transformThousandNumbers = (number) => {
    if (isNaN(number)) {
        return '-';
    }
    else if (number > 999) {
        return `${+(+number / 1000).toFixed(1)} K`;
    }
    else {
        return +(+number).toFixed(2);
    }
}
