import {ACTION_CODE} from './_actionCode';
import {DealService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_DEAL';

export async function updateDeal(
    userData: Record<string, string>,
    updatedDeal: Record<string, any>,
    dealId: string
) {
    try {

        console.log('userData', userData);
        console.log('updatedDeal', updatedDeal);
        console.log('dealId', dealId);

        LoggerService.logInfo('Action - updateDeal - Start');

        const updateDealResult = await DealService.updateDeal(userData, updatedDeal, dealId);

        LoggerService.logInfo('Action - updateDeal - End - Success');

        return ResultModel.newSuccessResult(updateDealResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateDeal - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
