import {ServicesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_SERVICE';

export async function deleteService(token: string, projectId: number | string) {
    try {
        const result = await ServicesDao.deleteService(token, projectId);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du service',
            error: error
        });
    }
}
