import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../../../components/ui/Inputs/SelectInput/SelectInput';
import ContainerCard from '../../../../../../components/ui/ContainerCard';
import TextInput from '../../../../../../components/ui/Inputs/TextInput';
import Button from '../../../../../../components/ui/Button';
import TextAreaInput from '../../../../../../components/ui/Inputs/TextAreaInput';
import {notify} from '../../../../../../components/ui/Toast/Toast';
import Error from '../../../../../../components/ui/Error/Error';
import {getErrors} from './../../../../../../utils';
import {capitalize} from './../../../../../../utils';

const CreateNewActivity = ({
    setView,
    userData,
    sectionName,
    toBeUpdated,
    actionCreate,
    actionUpdate,
    refreshData,
    dataActivity

}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const work = [
        {
            id: 1,
            name: "Temps d'absence",
            value: false
        },
        {
            id: 2,
            name: "Présence",
            value: true
        }
    ];

    // -- react hook form --
    const defaultValues = {
        name: '',
        short_name: '',
        work: work[0],
        notes: '',
        activity_type: ''
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- fill expense values in form for update --
    useEffect(() => {
        if (toBeUpdated) {
            reset({
                name: toBeUpdated.name,
                short_name: toBeUpdated.short_name,
                work: toBeUpdated.work ? work[1] : work[0],
                notes: toBeUpdated.notes,
                activity_type: toBeUpdated.activity_type
            });
        }
    }, [toBeUpdated]);
    
    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const newItem = {
            name: data.name,
            short_name: data.short_name,
            work: data.work.value,
            notes: data.notes,
            activity_type: data.activity_type.name
        }

        if (!toBeUpdated) {
            const result = await actionCreate(userData.userToken, newItem);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                setView(false);
                notify(`${capitalize(sectionName.name)} a bien été créé${sectionName.gender === "masculin" ? "" : "e"}`);
            }
        }
        else {
            const result = await actionUpdate(userData.userToken, toBeUpdated.id, newItem);
            
            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                setView(false);
                notify(`${capitalize(sectionName.name)} a bien été mis${sectionName.gender === "masculin" ? "" : "e"} à jour`);
            }
        }

        setLoading(false);
        setDisabled(false);
    }

    return (
        <ContainerCard
            title={`${toBeUpdated ? `Modifier ${sectionName.gender === "masculin" ? "un" : "une"}` : `Ajouter un${sectionName.gender === "masculin" ? "" : "e"}`} ${sectionName.name}`}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex flex-row items-center justify-between px-4 pt-2 pb-1">
                <div className='flex flex-col'>
                    <div className="mb-3">
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Nom'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Nom"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="short_name"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez saisir le nom'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <TextInput
                                    label='Nom abrégé'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Nom abrégé"
                                    type="text"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="mb-3">
                        <Controller
                            name="work"
                            control={control}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Travail'
                                    placeholder='Travail'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={work}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={false}
                                    disabled={disabled}
                                />
                            )
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="activity_type"
                            control={control}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    label='Type d&apos;activité'
                                    placeholder='Type d&apos;activité'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={dataActivity}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={false}
                                    disabled={disabled}
                                />
                            )
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="py-4 mx-4 border-t border-borderGrey">
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value}
                    }) => (
                        <TextAreaInput
                            placeholder={'Tapez votre texte'}
                            maxLength={100}
                            onChange={onChange}
                            value={value}
                            isDisabled={disabled}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'primary'}
                        content={`${toBeUpdated ? `Modifier ${sectionName.gender === "masculin" ? "un" : "une"}` : `Ajouter un${sectionName.gender === "masculin" ? "" : "e"}`} ${sectionName.name}`}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateNewActivity;
