import {Navigate, useLocation, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const UserIsAuthenticated = () => {
    const location = useLocation();
    const userProfileData = useSelector((state) => state.UserReducer.userProfileData);

    const useAuth = () => {
        return userProfileData;
    };

    const isAuthenticated = useAuth();

    return isAuthenticated ? <Outlet/> : <Navigate to="/login" state={{from: location}} replace/>;

    /*
    if (isAuthenticated === null) {
        LoggerService.logInfo('[GUARD UserIsAuthenticated] - isAuthenticated is null - Loading');

        return <FullPageLoader/>;
    }
    else if (isAuthenticated === false) {
        LoggerService.logInfo('[GUARD UserIsAuthenticated] - isAuthenticated is false - Redirection to /login');

        return <Navigate to="/login" state={{from: location}} replace/>;
    }
    else {
        LoggerService.logInfo('[GUARD UserIsAuthenticated] - isAuthenticated is true - Redirection to children');

        return <Outlet/>;
    }
     */
};

export default UserIsAuthenticated;
