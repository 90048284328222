import {
    CustomerCategoryActions,
    LocationActions,
    DealOriginsActions,
    DealLossMotivesActions,
    DealAbandonmentMotivesActions,
    DealDasActions,
    SubsidiaryActions,
    ProductionCategoryActions,
    ExpenseTypeActions,
    VehicleTypeActions,
    BankActions,
    PaymentMethodActions,
    DealTagsActions,
    TrainingProductOriginActions,
    TrainingObjectiveActions,
    TrainingStudentTypesActions,
    TrainingSpecialitiesActions,
    SeniorityActions,
    ActivitiesActions,
    ContractorCategoryActions,
    ActivityTypesActions,
    AttachmentTypeActions
} from "../../../actions";
import {notify} from "../../../components/ui/Toast/Toast";

export const getClassificationData = async (
    userData: Record<string, any>,
) => {

    const tablesobject: any = {
        CustomerCategoryData: await CustomerCategoryActions.getAllCustomerCategories(userData, "&active=reset", 1),
        LocationData: await LocationActions.getAllLocation(userData, "&active=reset", 1),
        DealOriginsData: await DealOriginsActions.getAllDealOrigins(userData, "&active=reset", 1),
        DealLossMotivesData: await DealLossMotivesActions.getAllDealLossMotives(userData, "&active=reset", 1),
        DealAbandonmentMotivesActions: await DealAbandonmentMotivesActions.getAllDealAbandonmentMotives(userData, "&active=reset", 1),
        AttachmentTypesData: await AttachmentTypeActions.getAllAttachmentTypes(userData, "&active=reset", 1),
        DealDasData: await DealDasActions.getAllDealDas(userData, "&active=reset", 1),
        SubsidiaryData: await SubsidiaryActions.getAllSubsidiaries(userData, "&active=reset", 1),
        ProductionCategoryData: await ProductionCategoryActions.getAllProductionCategories(userData, "&active=reset", 1),
        ExpenseTypeData: await ExpenseTypeActions.getAllExpenseTypes(userData, "&active=reset", 1),
        VehicleTypeData: await VehicleTypeActions.getAllVehicleTypes(userData, "&active=reset", 1),
        BankData: await BankActions.getAllBanks(userData, "&active=reset", 1),
        PaymentMethodData: await PaymentMethodActions.getAllPaymentMethods(userData, "&active=reset", 1),
        DealTagsData: await DealTagsActions.getAllDealTags(userData, "&active=reset", 1),
        ActivityData: await ActivitiesActions.getAllActivities(userData.userToken, "&page_size=2000", 1),
        SeniorityData: await SeniorityActions.getAllSeniority(userData, "&active=reset", 1),
        TrainingProductOriginData: await TrainingProductOriginActions.getAllTrainingProductOrigin(userData, "&active=reset", 1),
        TrainingObjectiveData: await TrainingObjectiveActions.getAllTrainingObjective(userData, "&active=reset", 1),
        TrainingStudentTypesData: await TrainingStudentTypesActions.getAllTrainingStudentTypes(userData, "&active=reset", 1),
        TrainingSpecialitiesData: await TrainingSpecialitiesActions.getAllTrainingSpecialities(userData, "&active=reset", 1),
        ContractorCategoryData: await ContractorCategoryActions.getAllContractorCategories(userData, "&active=reset", 1),
        ActivityTypesData: await ActivityTypesActions.getAllActivityTypes(userData, "&active=reset", 1)
    };

    let success = true

    for (const key in tablesobject) {
        if (tablesobject[key].success === false) {
            success = false
        }

        if (tablesobject[key].success === true) {
            tablesobject[key].data.results ? tablesobject[key] = tablesobject[key].data.results : tablesobject[key] = tablesobject[key].data
        }
    }

    if (!success) {
        notify("Une erreur est survenue lors du chargement des données")
    }

    return tablesobject;
}
