import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

import {DealActions, DealAbandonmentMotivesActions} from '../../../../actions';
import PopUp from '../../../../components/ui/Popup'
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard'
import TextAreaInput from '../../../../components/ui/Inputs/TextAreaInput'
import Button from '../../../../components/ui/Button/Button';
import DropdownInput from '../../../../components/ui/Inputs/DropdownInput';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error';
import {getErrors} from '../../../../utils';

const AbandonedPopup = ({
    userData,
    view,
    setView,
    refreshDealData,
    dealId,
    blueStyle = false
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dealAbandonmentMotives, setDealAbandonmentMotives] = useState(null);
    const [errors, setErrors] = useState(null);

    // -- react hook form --
    const defaultValues = {
        abandonment_motive: '',
        notes: ''
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        (async () => {
            const result = await DealAbandonmentMotivesActions.getAllDealAbandonmentMotives(userData);

            if (result.success) {
                setDealAbandonmentMotives(result.data.results);
            }
            else {
                notify('Erreur de récuperation des motifs de réfus');
            }
        })();
    }, []);

    useEffect(() => {
        if (!view) {
            reset(defaultValues);
            setErrors(null);
        }
    }, [view]);

    const onSubmit = async (formData) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const payload = {
            abandonment_motive: formData.abandonment_motive.name,
            notes: formData.notes,
            status: 900
        }

        const result = await DealActions.updateDeal(userData, payload, dealId);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshDealData();
            notify("L'affaire a bien été abandonnée");
            setView(false);
            reset(defaultValues);
        }

        setLoading(false);
        setDisabled(false);
    }

    const handleCancel = () => {
        reset(defaultValues);
        setView(false);
    }

    return (
        <PopUp view={view} setView={setView} width="1098px">
            <ContainerCard title="Motif de l'abandon" coloredButtonDisplayed={false}>
                <div className="mb-4">
                    <Controller
                        name="abandonment_motive"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir un motif'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DropdownInput
                                color={blueStyle ? 'blue' : 'primary'}
                                label='Motif'
                                items={dealAbandonmentMotives}
                                loading={!dealAbandonmentMotives}
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                placeholder="Séléctionnez un motif"
                            />
                        )}
                    />
                </div>
                <Controller
                    name="notes"
                    control={control}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextAreaInput
                            color={blueStyle ? 'blue' : 'primary'}
                            label='notes'
                            onChange={onChange}
                            maxLength={100}
                            value={value}
                            error={error}
                            required={false}
                            placeholder="Tapez votre texte"
                            type="text"
                        />
                    )}
                />
                <div className="flex flex-col justify-center pb-2 mt-2">
                    <div className='flex flex-row mx-auto mt-8 w-fit'>
                        <Button
                            onClick={handleCancel}
                            type='white'
                            content='Annuler'
                        />
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            content={"Valider"}
                            loading={loading}
                            disabled={!isValid || disabled}
                            type={blueStyle ? 'blue' : 'primary'}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
            </ContainerCard>
        </PopUp>
    )
}

export default AbandonedPopup;
