import {ACTION_CODE} from './_actionCode';
import {SeniorityService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_SENIORITY';

export async function getAllSeniority(
    userData: Record<string, string>,
    params = "",
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllSeniority - Start');

        const getAllSeniorityResult = await SeniorityService.getAllSeniority(userData, params, page);

        LoggerService.logInfo('Action - getAllSeniority - End - Success');

        return ResultModel.newSuccessResult(getAllSeniorityResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllSeniority - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
