import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'DELETE_TRAINING_ATTACHMENT';

export async function deleteTrainingAttachment(userToken: string, id: string) {
    try {
        const response = await axios({
            method: 'delete',
            url: `${URL}/training-attachements/${id}/`,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la suppression de la pièce jointe',
            error: error
        });
    }
}
