import DonutsChart from '../../../../../components/ui/DonutsChart/DonutsChart';
import {etpProductionChartConfig} from './etpProductionChartConfig';
import {aggregateETPmMarginPerRole, aggregateETPMarginPerRoleCabinet} from '../../../functions/aggregateETPMargin';

export const etpProductionTabConfig = (by_status) => {

    const tabs = [
        {
            id: 1,
            label: 'Cabinet',
            component: <DonutsChart data={aggregateETPMarginPerRoleCabinet(by_status)} config={etpProductionChartConfig} label="€"/>
        }
    ]

    for (let key in by_status) {
        tabs.push({
            id: key,
            label: key,
            component: <DonutsChart label={'€'} data={aggregateETPmMarginPerRole(by_status, key)} config={etpProductionChartConfig}/>
        })
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
