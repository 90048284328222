import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';

import { TrainingAttendancesActions,  FileActions} from './../../../../actions'
import { standardFormat, frenchFormat } from '../../../../utils';
import FileDragNDropInput from './../../../../components/ui/Inputs/FileDragNDropInput';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button/Button';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import {notify} from '../../../../components/ui/Toast/Toast';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';

const TrainingAttendancePopup = ({
    setView,
    userData,
    trainingId,
    trainingAttendanceToEdit,
    refreshData
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const defaultValues = {
        date: '',
        duration: '',
        students: ''
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (trainingAttendanceToEdit) {
            reset({
                date: trainingAttendanceToEdit.date,
                duration: (+trainingAttendanceToEdit.duration).toString(),
                students: (+trainingAttendanceToEdit.students).toString()
            });
        }
    }, []);

    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        if (isValid) {
            let file = null;

            if (data.file && data.file.uid && data.file.type) {
                const newFile = {
                    "type": data.type,
                    "name": data.file.name,
                    "file": data.file
                };

                const result = await FileActions.createFile(userData, newFile);

                if (!result.success) {
                    notify('Une erreur est survenue lors de l\'enregistrement de la feuille d\'émargement.')
                }
                else {
                    file = result.data;
                }
            }

            const payload = {
                ...data,
                ...(file ? {file_id: file.id} : {}),
                date: standardFormat(data.date),
                training_id: trainingId
            };

            let result;

            if (trainingAttendanceToEdit) {
                result = await TrainingAttendancesActions.updateTrainingAttendance(userData, payload, trainingAttendanceToEdit?.id);
            }
            else {
                result = await TrainingAttendancesActions.createTrainingAttendance(userData, payload);
            }

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await refreshData();
                notify(`Le suivi de stagiaires a été ${trainingAttendanceToEdit ? 'modifier' : 'crée'} avec succès.`);
                setView(false);
            }

            setDisabled(false);
            setLoading(false);
        }
    };

    return (
        <ContainerCard
            coloredButtonDisplayed={false}
            title="Nouveau Checklist qualité - Suivi des stagiaires"
            overflow=''
        >
            <div className="flex flex-col h-auto">
                <div className='flex justify-between mb-3'>
                    <Controller
                        name="date"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Veuillez sélectionner une date de formation.'
                            }
                        }}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label="Date de formation"
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                width='w-72'
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between mb-3'>
                    <Controller
                        name="duration"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Veuillez saisir la durée de formation'
                            }
                        }}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Durée (h)'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Durée de la formation"
                                type="number"
                                width='w-72'
                                color='blue'
                            />
                        )}
                    />
                    <Controller
                        name="students"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Veuillez saisir le nombre de stagiaires'
                            }
                        }}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Nombre de stagiaires'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Nombre de stagiaires"
                                type="number"
                                width='w-72'
                                color='blue'
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex flex-col pt-6 mb-8 border-t border-gray-200">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p className="mb-4">Feuille d'&apos;émargement <span className="text-red-500">*</span></p>
                <Controller
                    name="file"
                    rules={{
                        required: {
                            value: !trainingAttendanceToEdit?.file,
                            message: 'Veuillez charger la feuille d\'émargement.'
                        }
                    }}
                    control={control}
                    render={({
                        field: {onChange}
                    }) => (
                        <FileDragNDropInput
                            onChange={onChange}
                            maxCount={1}
                            color="#00ABE9"
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={'blue'}
                        content={`${trainingAttendanceToEdit ? 'Modifier' : 'Ajouter'} le suivi`}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default TrainingAttendancePopup;