import {MissionDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_MISSION_BY_ID';

export async function updateMissionById(token:string, updatedMission:Record<string, string>) {
    try {

        const mission = await MissionDao.updateMissionById(token, updatedMission);

        return mission;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
