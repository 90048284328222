import {ACTION_CODE} from './_actionCode';
import {DealDasService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_DEALDAS';

export async function deleteDealDas(userData: Record<string, any>, dealDasId: number) {
    try {
        LoggerService.logInfo('Action - deleteDealDasById - Start');

        const deleteDealDasByIdResult = await DealDasService.deleteDas(userData, dealDasId);

        LoggerService.logInfo('Action - deleteDealDasById - End - Success');

        return ResultModel.newSuccessResult(deleteDealDasByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteDealDasById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
