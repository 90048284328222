import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {UserActions} from '../../../actions';
import formLogo from '../../../assets/logos/logo.png';

const LoginForm = () => {
    const dispatch = useDispatch();

    const [formValue, setFormValue] = useState({
        username: '',
        password: ''
    });

    const [errors, setErrors] = useState({
        username: '',
        password: ''
    });

    const handleChangeInput = (e, name) => {
        if (formValue.username.length || formValue.password.length) {
            setErrors({
                ...errors,
                username: '',
                password: ''
            });
        }

        setFormValue({
            ...formValue,
            [name]: e.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setErrors({
            username: !formValue.username ? 'Veuillez indiquer un nom d\'utilisateur valide' : '',
            password: !formValue.password ? 'Veuillez renseigner un mot de passe' : ''
        });

        if (formValue.password && formValue.username) {
            const connectUser = await UserActions.logIn(dispatch, formValue);

            if (!connectUser.success) {
                setErrors({
                    username: 'Nom d\'utilisateur ou mot de passe incorrect',
                    password: 'Nom d\'utilisateur ou mot de passe incorrect'
                });

            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="px-8 pt-8 pb-8 mb-4 bg-white rounded shadow">
            <img src={formLogo} alt="Logo" className="w-3/4 mx-auto mb-20"/>
            <div className="mb-4">
                <label className="block pb-2 text-sm font-bold text-gray-500">
                    {`Nom d'utilisateur`}
                </label>
                <input
                    className={`appearance-none border border-[#DBDBDB] rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline caret-black ${
                        errors.username ? 'border-red-500' : ''
                    }`}
                    onChange={(e) => handleChangeInput(e, 'username')}
                    type="text"
                    value={formValue.username}
                />
                <div className="h-4">
                    {errors.username && (
                        <p className="text-xs italic text-red-500">{errors.username}</p>
                    )}
                </div>
            </div>

            <div className="mb-4">
                <label className="block pb-2 text-sm font-bold text-gray-500">
                    Mot de passe
                </label>
                <input className={`appearance-none border border-[#DBDBDB] rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline ${ errors.password ? 'border-red-500' : ''}`}
                    onChange={(e) => handleChangeInput(e, 'password')}
                    type="password"
                    value={formValue.password}
                />
                <div className="h-4">
                    {errors.password && (
                        <p className="mt-1 text-xs italic text-red-500">{errors.password}</p>
                    )}
                </div>
            </div>

            <div className="flex items-center justify-center">
                <div className="p-[1px] rounded bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]">
                    <button
                        className={
                            'px-4 py-2 font-bold text-[#E36D38] rounded bg-white ' +
                            'hover:text-white hover:bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] ' +
                            'focus:outline-none focus:shadow-outline'
                        }
                        type="submit"
                    >
                    Se connecter
                    </button>
                </div>

                {/*
                    <a className="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="#">
                    Mot de passe oublier?
                </a> */}
            </div>
        </form>
    );
};

export default LoginForm;
