import {TrainingAttendancesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_TRAINING_ATTENDANCE';

export async function deleteTrainingAttendance(userData: Record<string, string>, dealId: string) {
    try {
        return await TrainingAttendancesDao.deleteTrainingAttendance(userData.userToken, dealId);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la suppression du suivi de stagiaire',
            error: error
        });
    }
}
