import {ACTION_CODE} from './_actionCode';
import {ExpenseService, ExpenseReportService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import dayjs from 'dayjs';
// import {standardFormat} from '../../utils';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_EXPENSE';

export async function createExpense(userData: Record<string, any>, payloadExpense: any) {
    let expenseReport;

    try {
        LoggerService.logInfo('Action - createExpense - Start');

        const getOpenExpenseReports = await ExpenseReportService.getAllExpenseReports(userData, "&status=0", 1);

        if (!getOpenExpenseReports.length) {
            
            const expenseReportDTO = {
                status: 0,
                ressource_id: userData.userId,
                payment_date: dayjs().format('YYYY-MM-DD')
            };

            const createNewExpenseReport = await ExpenseReportService.createExpenseReport(userData, expenseReportDTO);

            expenseReport = createNewExpenseReport;
        }
        else {
            expenseReport = getOpenExpenseReports[0];
        }

        const expense = {...payloadExpense};

        expense.report = expenseReport.id;

        const createExpenseResult = await ExpenseService.createExpense(userData, expense);

        LoggerService.logInfo('Action - createExpense - End - Success');

        return ResultModel.newSuccessResult(createExpenseResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createExpense - End - Error');

        if (expenseReport.expenses && !expenseReport.expenses.length){
            ExpenseReportService.deleteExpenseReportById(userData, expenseReport.id);

            LoggerService.logInfo('Note de frais vide supprimée');
        }

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
