import { ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Table from '../../components/ui/Table';
import InvoicePreviewPopup from './components/InvoicePreviewPopup';
import { contractorsTableConfig } from './config/contractorsTableConfig';

const ContractorInvoicesAwaitingPage = ({
    data,
    filterContractorInvoiceList,
    userData,
    tableType,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [itemSelected, setItemSelected] = useState(null);
    const [showInvoicePreviewPopup, setShowInvoicePreviewPopup] = useState(false);

    useEffect(() => {
        if (!showInvoicePreviewPopup) {
            setItemSelected(null);
        }
    }, [showInvoicePreviewPopup]);

    const handleInvoicePreview = (item) => {
        setItemSelected(item);
        setShowInvoicePreviewPopup(true);
    }

    const displayedSection = "Factures fournisseurs en attente";
    const tableConfig = contractorsTableConfig({
        displayedSection,
        handleInvoicePreview,
        filterContractorInvoiceList,
        userData,
        tableType
    });

    return (
        <>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures fournisseurs en attente (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />

            <InvoicePreviewPopup
                view={showInvoicePreviewPopup}
                setView={setShowInvoicePreviewPopup}
                item={itemSelected}
                type='contractor-invoice'
            />
        </>
    )
}

export default ContractorInvoicesAwaitingPage;
