import {ProjectsDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'DELETE_PROJECT';

export async function deleteProject(token: string, projectId: number | string) {
    try {
        const result = await ProjectsDao.deleteProject(token, projectId);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression du projet',
            error: error
        });
    }
}
