import ActionsButton from '../../../components/ui/ActionsButton/ActionsButton';
import {frenchFormat, addDays} from '../../../utils';

export const vacationsTable = (
    setNewProductionPopupVisible,
    setIsModifyActive,
    handleDeleteProduction,
    actionButtonLoading,
    actionButtonDisabled
) => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Date de début',
                    value: 'date',
                    width: 'w-[25%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-col items-center justify-center w-full">
                                <div>{frenchFormat(item.date)}</div>
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Date de fin',
                    value: null,
                    width: 'w-[25%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-col items-center justify-center w-full">
                                <div>{frenchFormat(addDays(item.date, item.produced_days))}</div>
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Temps',
                    value: null,
                    width: 'w-[18%]',
                    sort: false,
                    component: () => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <div className="text-white rounded-full bg-[#646464] w-fit px-2 pb-[1px] h-[21px] text-xs flex items-center">
                                    Congés
                                </div>
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Jours de congés',
                    value: 'produced_days',
                    width: 'w-[16%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.produced_days}
                            </div>);
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Actions',
                    value: null,
                    width: 'w-[16%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="edit"
                                    isLoading={false}
                                    onClick={
                                        () => {
                                            setIsModifyActive({...item, deal_name: 'Congés'});
                                            setNewProductionPopupVisible(true);
                                        }
                                    }
                                    disabled={actionButtonDisabled}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer ce congé ?'
                                />
                                <div className="w-0 h-0 px-2"/>
                                <ActionsButton
                                    type="delete"
                                    isLoading={actionButtonLoading === item.id}
                                    onClick={
                                        () => {
                                            handleDeleteProduction(item);
                                        }
                                    }
                                    disabled={actionButtonDisabled}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer ce congé ?'
                                />
                            </div>);
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
