import {useSelector} from 'react-redux';
import {BankFilled, FundFilled, EuroCircleFilled, CalculatorFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Datepicker from "../../../../components/ui/Datepicker";
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import Badge from "../../../../components/ui/Badge/Badge";
import {transformThousandNumbers} from '../../../../utils';
import {aggregateTotalTraitants} from '../../functions/aggregateExternalRessources';

const ExternalRessource = ({
    datePickerDate,
    handleDatePickerChange,
    externalRessources
}) => {

    const BankIcon = <BankFilled style={{ fontSize: '30px', color:'white' }}/>;
    const FundIcon = <FundFilled style={{ fontSize: '30px', color:'white' }}/>;
    const EuroCircleIcon = <EuroCircleFilled style={{ fontSize: '30px', color:'white' }}/>;
    const CalculatorIcon = <CalculatorFilled style={{ fontSize: '30px', color:'white' }}/>;

    const cotraitantsCount = aggregateTotalTraitants(externalRessources, "Cotraitants", "count")
    const sousTraitantsCount = aggregateTotalTraitants(externalRessources, "Sous-traitants", "count")

    const cotraitantsDays = aggregateTotalTraitants(externalRessources, "Cotraitants", "days")
    const sousTraitantsDays = aggregateTotalTraitants(externalRessources, "Sous-traitants", "days")

    const cotraitantsAmount = aggregateTotalTraitants(externalRessources, "Cotraitants", "amount")
    const sousTraitantsAmount = aggregateTotalTraitants(externalRessources, "Sous-traitants", "amount")

    const cotraitantsCommission = aggregateTotalTraitants(externalRessources, "Cotraitants", "commission")
    const sousTraitantsCommission = aggregateTotalTraitants(externalRessources, "Sous-traitants", "commission")

    return (
        <div className='mt-14'>
            <LineTitle title="Ressources externes" textSize='text-4xl'/>

            <div className='mt-10'>
                <Datepicker
                    defaultValue={datePickerDate}
                    onChange={handleDatePickerChange}
                    type='range'
                />

                <div className="flex flex-wrap justify-between mt-6 gap-y-7">
                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={cotraitantsCount + sousTraitantsCount}
                        borderSize='border'
                        subTitle='Structures mobilisées'
                        iconComponent={BankIcon}
                        topComponent={<TopComponent
                            jointContractors={cotraitantsCount}
                            Subcontractors={sousTraitantsCount}/>}
                        bottomComponent={<BottomComponent
                            data={externalRessources}
                            type='count'
                        />}
                    />

                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={cotraitantsDays + sousTraitantsDays}
                        borderSize='border'
                        subTitle='Jours de production confiés'
                        iconComponent={FundIcon}
                        topComponent={<TopComponent
                            jointContractors={cotraitantsDays}
                            Subcontractors={sousTraitantsDays}/>}

                        bottomComponent={<BottomComponent
                            data={externalRessources}
                            type='days'
                        />}
                    />

                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={transformThousandNumbers(cotraitantsAmount + sousTraitantsAmount)}
                        borderSize='border'
                        subTitle='Euros confiés'
                        iconComponent={EuroCircleIcon}
                        topComponent={<TopComponent
                            jointContractors={transformThousandNumbers(cotraitantsAmount)}
                            Subcontractors={transformThousandNumbers(sousTraitantsAmount)}
                        />}
                        bottomComponent={<BottomComponent
                            data={externalRessources}
                            type='amount'
                        />}
                    />

                    <SimpleCard
                        width="w-[48%]"
                        background="orange"
                        title={transformThousandNumbers(cotraitantsCommission + sousTraitantsCommission)}
                        borderSize='border'
                        subTitle='Marge retenue'
                        iconComponent={CalculatorIcon}
                        topComponent={<TopComponent
                            jointContractors={transformThousandNumbers(cotraitantsCommission)}
                            Subcontractors={transformThousandNumbers(sousTraitantsCommission)}
                        />}
                        bottomComponent={<BottomComponent
                            data={externalRessources}
                            type='commission'
                        />}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExternalRessource;

const TopComponent = ({ jointContractors, Subcontractors }) => {
    return (
        <div className="flex justify-around pb-3">
            <div className="flex flex-col justify-start w-32">
                <p className='text-white'>Cotraitants</p>
                <div className="my-2 bg-white w-full h-[1px]"/>
                <p style={{ fontFamily: 'Din condensed' }} className="text-4xl text-white">{jointContractors}</p>
            </div>

            <div className="flex flex-col justify-start w-32">
                <p className='text-white'>Sous-traitants</p>
                <div className="my-2 bg-white w-full h-[1px]"/>
                <p style={{ fontFamily: 'Din condensed' }} className="text-4xl text-white">{Subcontractors}</p>
            </div>
        </div>
    )
}

const BottomComponent = ({data, type}) => {

    const allDasS = useSelector((state) => state.DasReducer.das);

    let dasS = [];

    for (const key in data) {
        let currentDas = allDasS.filter((el) => el.name === key);

        dasS.push({
            das: key,
            valueSousTraitants: transformThousandNumbers(data[key].Cotraitants[type === 'count' ? 'count' : type === 'days' ? 'days' : type === 'amount' ? 'amount' : 'commission']),
            valueCotraitants: transformThousandNumbers(data[key]["Sous-traitants"][type === 'count' ? 'count' : type === 'days' ? 'days' : type === 'amount' ? 'amount' : 'commission']),
            dasObject: currentDas = currentDas.length > 0 ? currentDas[0] : null

        })
    }

    return (
        <div className='flex flex-wrap justify-start bg-white rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={el.das}
                            className={`
                            min-w-[33.333333%] grow py-6 px-9 border-t-[1px] border-[#d3d3d3]
                            ${(((index + 1) % 3 === 0) || ((index + 1) === dasS.length)) ? '' : 'border-r-[1px]'}
                        `}
                        >
                            <div className='flex items-center justify-between pb-2 border-b border-gray-200'>
                                <p className='text-gradient'>{el.das}</p>
                                <Badge
                                    content={el.dasObject ? [el.dasObject.manager] : '-'}
                                    color={el.dasObject ? null : '#bdc3c7'}
                                    type='round'
                                />
                            </div>
                            <div className="flex justify-between">
                                <div className="text-[#F0A841] mt-2">
                                    <p style={{fontFamily: 'Din condensed'}} className="text-2xl">{el.valueSousTraitants}</p>
                                    <p className="text-xs">Cotraitants</p>
                                    <div className="h-[1px] w-full mt-1.5 bg-[#F0A841]"/>
                                </div>

                                <div className="text-[#DC3832] mt-2">
                                    <p style={{fontFamily: 'Din condensed'}} className="text-2xl">{el.valueCotraitants}</p>
                                    <p className="text-xs">Sous-trait.</p>
                                    <div className="h-[1px] w-full mt-1.5 bg-[#DC3832]"/>
                                </div>
                            </div>
                            <div className='h-auto w-[1px] bg-gray-200'/>
                        </div>)
                })
            }
        </div>
    )
}