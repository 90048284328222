import {DashboardActions} from "../../../actions";
import {standardFormat, firstDayMonth, lastDayMonth} from "../../../utils";

export const getResourceManagementTabData = async (
    userData,
    notify,
    setRessources,
    externalRessourcesDatePicker,
    setExternalRessources
) => {
    // ↓ «Équipe du DAS» data
    const ressourcesResult = await DashboardActions.CodevActions.getRessources(userData, "");

    if (ressourcesResult.success) {
        setRessources(ressourcesResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des équipes du DAS");
    }

    // ↓ «Ressources externes» data
    const externalRessourcesResult = await DashboardActions.CodevActions.getExternalRessources(
        userData,
        `?start_date=${standardFormat(externalRessourcesDatePicker.startDate)}&end_date=${standardFormat(externalRessourcesDatePicker.endDate)}`
    );

    if (externalRessourcesResult.success) {
        setExternalRessources(externalRessourcesResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des ressources externes");
    }
}

export const handleExternalRessourcesDatePickerChange = async (
    e,
    userData,
    notify,
    setLoading,
    setExternalRessourcesDatePicker,
    setExternalRessources
) => {
    setLoading(true);

    const dates = {
        startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
        endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    };

    setExternalRessourcesDatePicker(dates);

    const externalRessourcesResult = await DashboardActions.CodevActions.getExternalRessources(
        userData,
        `?start_date=${standardFormat(dates.startDate)}&end_date=${standardFormat(dates.endDate)}`
    );

    if (externalRessourcesResult.success) {
        setExternalRessources(externalRessourcesResult.data);
    }
    else {
        notify("Une erreur est survenue lors de la récuperation des ressources externes");
    }

    setLoading(false);
};
