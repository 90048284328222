import {useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, message, Upload} from 'antd';

const FileUploadInput = ({label, onChange}) => {

    const [localFileList, setLocalFileList] = useState([]);

    const props = {
        onRemove: (file) => {
            setLocalFileList([]);
            message.success(`${file.name} supprimé`)

        },
        beforeUpload: (file) => {
            setLocalFileList([...localFileList, file]);
            message.success(`${file.name} ajouté`);

            return false;
        },
        onChange: () => onChange(localFileList[0]),
        maxCount: 1,
        localFileList
    };

    return (
        <div className="flex flex-col ">
            <div className="flex flex-row items-center w-full">
                <div className="flex flex-row w-36">
                    <label className="flex flex-row items-center font-normal text-sm text-[#646464]">{label}
                    </label>
                    <Upload
                        {...props}>
                        <Button icon={<UploadOutlined/>}>Ajouter un fichier</Button>
                    </Upload>
                </div>
            </div>
        </div>
    )
}

export default FileUploadInput;
