import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Tooltip from 'antd/lib/tooltip';

import {CustomerActions} from '../../../../actions'
import arrowIcon from '../../../../assets/icons/page/WhiteArrowLeft.png';
import deleteIcon from '../../../../assets/icons/actionsbutton/white-delete.png';
import Badge from '../../../../components/ui/Badge';
import Loader from '../../../../components/loaders/Loader';
import {notify} from '../../../../components/ui/Toast/Toast';
import ConfirmationPopup from '../../../../components/ui/ConfirmationPopup';

const CustomerDetailsBanner = ({data, userData}) => {
    const navigate = useNavigate();

    const isAllowedToDelete = userData?.role === "CO" && data?.deal?.production_lead?.id !== userData?.id ? false : true;

    const [deleteCustomerLoading, setDeleteCustomerLoading] = useState(false);
    const [deleteCustomerConfirmationView, setDeleteCustomerConfirmationView] = useState(false);

    const handleDeleteCustomer = async () => {
        setDeleteCustomerLoading(true);

        const result = await CustomerActions.deleteCustomer(userData, data.id);

        if (result.success) {
            navigate(`/my-organization/customers/`, {state: 'customer_deleted'});
        }
        else {
            notify('Le client n\'a pas pu être supprimée');
        }
        setDeleteCustomerLoading(false);
    };

    return (
        <>
            <div className='h-28 w-full bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841] px-20 py-5 fixed z-50 flex flex-col justify-start items-center shadow-[0_2px_8px_rgba(0,0,0,0.25)]'>
                <div className='flex flex-row items-center justify-between w-full'>
                    <button onClick={() => navigate(-1)} className='p-4'>
                        <img src={arrowIcon} alt='return' className='w-5'/>
                    </button>

                    <div className='grid flex-col justify-items-center'>

                        <div className='flex flex-row items-end'>

                            <div className='text-4xl font-bold text-white' style={{fontFamily: 'DIN Condensed'}}>
                                {data?.name}
                            </div>

                            <div className='m-2 text-sm text-white' style={{fontFamily: 'DIN Condensed'}}>
                                (ID: {data?.id})
                            </div>

                        </div>

                        <div className='text-base text-white' style={{fontFamily: 'DIN Condensed'}}>
                            {data?.location}
                        </div>

                    </div>

                    {
                        isAllowedToDelete ?
                            <Tooltip title='Supprimer client'>
                                <button onClick={() => setDeleteCustomerConfirmationView(true)} disabled={deleteCustomerLoading} className='p-4'>
                                    {
                                        deleteCustomerLoading ?
                                            <Loader size={20} color='#212121'/> :
                                            <img src={deleteIcon} alt='delete' className='w-5'/>
                                    }
                                </button>
                            </Tooltip>
                            : <div className='w-5'/>
                    }
                </div>
                <Badge
                    content={''}
                    color={'#212121'}
                    borderColor='#212121'
                />
            </div>
            <ConfirmationPopup
                view={deleteCustomerConfirmationView}
                setView={setDeleteCustomerConfirmationView}
                title='Souhaitez-vous supprimer ce client ?'
                onConfirm={handleDeleteCustomer}
            />
        </>
    )
}

export default CustomerDetailsBanner
