import {useState, useEffect} from 'react';
import Popup from '../../../../components/ui/Popup';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const SpendingContractorPreviewPopup = ({
    view,
    setView,
    item
}) => {
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        if (item && item.file) {
            setFileUrl(item.file.file);
        }
    }, [item, view]);
  
    const docs = [{uri: fileUrl}];

    return (
        <Popup view={view} setView={setView} maxheight='h-[400px]'>
            <DocViewer
                style={{height: '60vh', width: '850px'}}
                documents={docs}
                initialActiveDocument={docs[0]}
                pluginRenderers={DocViewerRenderers}
                config={{ header: {disableHeader: true},
                    pdfZoom: {
                        defaultZoom: 1.1,
                        zoomJump: 0.2
                    }
                }}
            />
        </Popup>
    );
}

export default SpendingContractorPreviewPopup;