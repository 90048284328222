import {ACTION_CODE} from './_actionCode';
import {AttachmentTypeDao} from '../../dao';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_ATTACHEMENT_TYPE';

export async function deleteAttachmentType(userData: Record<string, any>, id: string) {
    try {
        LoggerService.logInfo('Action - deleteAttachmentType - Start');

        const deleteAttachmentTypeResult = await AttachmentTypeDao.deleteAttachmentType(userData.userToken, id);

        LoggerService.logInfo('Action - deleteAttachmentType - End - Success');

        return ResultModel.newSuccessResult(deleteAttachmentTypeResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteAttachmentType - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'un frais',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
