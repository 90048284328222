import {ACTION_CODE} from './_actionCode';
import {DealOriginsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_DEALORIGIN';

export async function deleteDealOrigin(userData: Record<string, any>, dealOriginId: number) {
    try {
        LoggerService.logInfo('Action - deleteDealOriginById - Start');

        const deleteDealOriginByIdResult = await DealOriginsService.deleteDealOrigin(userData, dealOriginId);

        LoggerService.logInfo('Action - deleteDealOriginById - End - Success');

        return ResultModel.newSuccessResult(deleteDealOriginByIdResult);
    }
    catch (error: any) {
        LoggerService.logInfo('Action - deleteDealOriginById - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la suppression d\'une banque',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
