export const getUserDefaultDashboard = (userRole) => {
    switch (userRole) {
        case 'PA':
            return 'admin';
        case 'MA':
            return 'das';
        case 'OM':
            return 'office-manager';
        default:
            return 'consultant';
    }
}