import React from 'react';
import {RobotFilled} from '@ant-design/icons';
import {IdcardFilled} from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle/LineTitle';
import Datepicker from '../../../../components/ui/Datepicker/Datepicker';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';
import {transformThousandNumbers} from '../../../../utils';

const ResourceCosts = ({
    data,
    datePickerValue,
    onDatePickerChange,
    getPercent
}) => {
    const robotIcon = <RobotFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const idcardIcon = <IdcardFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getSupportSum = () => {
        return Math.round(
            data.support.salary + data.support.fees + data.support.expenses
        );
    }

    const getManagementSum = () => {
        return Math.round(
            data.management.salary + data.management.fees + data.management.expenses
        );
    }

    const getBusinessSum = () => {
        let sum = 0;

        for(const key in data.business) {
            sum += data.business[key]['junior'];
            sum += data.business[key]['confirmed'];
            sum += data.business[key]['senior'];
        }

        return sum.toFixed();
    }

    return (
        <div className='my-10'>
            <LineTitle title='Coûts des moyens'/>
            <div className='mt-10 mb-6'>
                <Datepicker
                    type='range'
                    defaultValue={datePickerValue}
                    onChange={onDatePickerChange}
                />
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col w-1/2'>
                    <SimpleCard
                        width='w-full'
                        height='h-fit'
                        iconComponent={robotIcon}
                        title={transformThousandNumbers(getSupportSum())}
                        sideTitle={`(${getPercent(getSupportSum())}% de la marge brute)`}
                        subTitle="Fonctions supports dédiées"
                        background='orange'
                        bottomComponent={
                            <BottomLeftComponent
                                salaryData={data.support.salary}
                                feesData={data.support.fees}
                                expensesData={data.support.expenses}
                                getPercent={getPercent}
                            />}
                    />
                    <div className='my-3'/>
                    <SimpleCard
                        width='w-full'
                        height='h-fit'
                        iconComponent={robotIcon}
                        title={transformThousandNumbers(getManagementSum())}
                        sideTitle={`(${getPercent(getManagementSum())}% de la marge brute)`}
                        subTitle="Management / gestion"
                        background='orange'
                        bottomComponent={
                            <BottomLeftComponent
                                salaryData={data.management.salary}
                                feesData={data.management.fees}
                                expensesData={data.management.expenses}
                                getPercent={getPercent}
                            />}
                    />
                </div>
                <div className='mx-5'/>
                <SimpleCard
                    width='w-1/2'
                    height='h-fit'
                    iconComponent={idcardIcon}
                    borderSize='border'
                    title={transformThousandNumbers(getBusinessSum())}
                    sideTitle={`(${getPercent(getBusinessSum())}% de la marge brute)`}
                    subTitle="Salaires des consultants"
                    background='orange'
                    topComponent={<TopRigthComponent data={data.business} getPercent={getPercent}/>}
                    bottomComponent={<BottomRightComponent data={data.business} getPercent={getPercent}/>}
                />
            </div>
        </div>
    )
}

export default ResourceCosts;

const BottomLeftComponent = ({
    salaryData,
    feesData,
    expensesData,
    getPercent
}) => {
    return (
        <div className={`flex flex-row items-center rounded-b-md justify-between w-full bg-white`}>
            <div className='w-1/3 py-6 px-9 border-t-[1px] border-r-[1px] border-[#d3d3d3]'>
                <span className='text-sm font-medium text-gradient'>Salaire</span>
                <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(salaryData)} `}
                    </span>
                    <span className='text-sm font-normal text-gradient'>{`(${getPercent(salaryData)}%)`}</span>
                </div>
            </div>
            <div className='w-1/3 py-6 px-9 border-t-[1px] border-r-[1px] border-[#d3d3d3]'>
                <span className='text-sm font-medium text-gradient'>Honoraires</span>
                <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(feesData)} `}
                    </span>
                    <span className='text-sm font-normal text-gradient'>{`(${getPercent(feesData)}%)`}</span>
                </div>
            </div>
            <div className='w-1/3 py-6 px-9 border-t-[1px] border-[#d3d3d3]'>
                <span className='text-sm font-medium text-gradient'>Frais</span>
                <div className='h-[1px] w-full mt-2 mb-3 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(expensesData)} `}
                    </span>
                    <span className='text-sm font-normal text-gradient'>{`(${getPercent(expensesData)}%)`}</span>
                </div>
            </div>
        </div>
    )
}

const TopRigthComponent = ({data, getPercent}) => {
    
    const getValueSum = (key) => {
        let sum = 0;

        for(const k in data) {
            sum += data[k][key];
        }

        return sum;
    }

    return (
        <div className='flex flex-row items-center justify-between w-full bg-transparent'>
            <div className='w-1/3 pb-3 px-9'>
                <span className='text-sm font-medium text-white'>Junior</span>
                <div className='h-[1px] w-full mt-1 mb-2 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-white' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(getValueSum('junior'))} `}
                    </span>
                    <span className='text-sm font-normal text-white'>
                        {`(${getPercent(getValueSum('junior'))}%)`}
                    </span>
                </div>
            </div>
            <div className='w-1/3 pb-3 px-9'>
                <span className='text-sm font-medium text-white'>Confirmé</span>
                <div className='h-[1px] w-full mt-1 mb-2 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-white' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(getValueSum('confirmed'))} `}
                    </span>
                    <span className='text-sm font-normal text-white'>
                        {`(${getPercent(getValueSum('confirmed'))}%)`}
                    </span>
                </div>
            </div>
            <div className='w-1/3 pb-3 px-9'>
                <span className='text-sm font-medium text-white'>Sénior</span>
                <div className='h-[1px] w-full mt-1 mb-2 bg-[#d3d3d3]'/>
                <div>
                    <span className='text-4xl font-bold text-white' style={{fontFamily: 'Din Condensed'}}>
                        {`${transformThousandNumbers(getValueSum('senior'))} `}
                    </span>
                    <span className='text-sm font-normal text-white'>
                        {`(${getPercent(getValueSum('senior'))}%)`}
                    </span>
                </div>
            </div>
        </div>
    )
}

const BottomRightComponent = ({data, getPercent}) => {

    const getDasSum = (dasObject) => {
        return dasObject.junior + dasObject.confirmed + dasObject.senior;
    }

    let dasS = [];

    for (const key in data) {
        dasS.push({
            id: key,
            das: key,
            juniorValue: transformThousandNumbers(data[key].junior),
            juniorPercent: getPercent(data[key].junior),
            confirmedValue: transformThousandNumbers(data[key].confirmed),
            confirmedPercent: getPercent(data[key].confirmed),
            seniorValue: transformThousandNumbers(data[key].senior),
            seniorPercent: getPercent(data[key].senior),
            totalValue: transformThousandNumbers(getDasSum(data[key])),
            totalPercent: getPercent(getDasSum(data[key]))
        })
    }
    
    return (
        <div className='flex flex-col w-full rounded-b-md'>
            {
                dasS.map((el, index) => {
                    return (
                        <div
                            key={el.id}
                            className={`
                                flex flex-row items-end w-full bg-white h-[116px] px-9 py-4
                                ${((index + 1) === dasS.length) ? '' : 'border-b-[1px] border-[#e2e2e2]'}
                            `}
                        >
                            <div className='w-1/3'>
                                <div className='flex flex-col justify-start'>
                                    <span className='mb-2 text-sm font-medium text-gradient'>{el.das}</span>
                                    <div className='flex items-center justify-center w-full border border-[#f0f0f0]'>
                                        <div className='flex flex-col items-start'>
                                            <span className='text-sm font-medium text-[#212121]'>Junior</span>
                                            <div>
                                                <span className='text-xl font-bold text-[#212121]' style={{fontFamily: 'Din Condensed'}}>
                                                    {`${el.juniorValue} `}
                                                </span>
                                                <span className='text-xs font-normal text-[#212121]'>
                                                    {`(${el.juniorPercent}%)`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'>
                                <div className='flex items-center justify-center w-full border border-[#f0f0f0]'>
                                    <div className='flex flex-col items-start'>
                                        <span className='text-sm font-medium text-[#212121]'>Confirmé</span>
                                        <div>
                                            <span className='text-xl font-bold text-[#212121]' style={{fontFamily: 'Din Condensed'}}>
                                                {`${el.confirmedValue} `}
                                            </span>
                                            <span className='text-xs font-normal text-[#212121]'>
                                                {`(${el.confirmedPercent}%)`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'>
                                <div className='flex flex-col items-center'>
                                    <div>
                                        <span className='text-2xl font-bold text-gradient' style={{fontFamily: 'Din Condensed'}}>
                                            {`${el.totalValue} `}
                                        </span>
                                        <span className='text-xs font-normal text-gradient'>
                                            {`(${el.totalPercent}%)`}
                                        </span>
                                    </div>
                                    <div className='flex items-center justify-center w-full border border-[#f0f0f0]'>
                                        <div className='flex flex-col items-start'>
                                            <span className='text-sm font-medium text-[#212121]'>Sénior</span>
                                            <div>
                                                <span className='text-xl font-bold text-[#212121]' style={{fontFamily: 'Din Condensed'}}>
                                                    {`${el.seniorValue} `}
                                                </span>
                                                <span className='text-xs font-normal text-[#212121]'>
                                                    {`(${el.seniorPercent}%)`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}
