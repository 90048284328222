import {ErrorModel, ResultModel} from '../../models';
import {LoggerService, UserService} from '../../services';
import {ACTION_CODE} from './_actionCode';
const FUNCTION_CODE = 'GET_USER';

export async function getUser(userToken: string, id: number) {
    try {
        LoggerService.logInfo('Action - getUser - Start');

        const getUserId = await UserService.getUser(userToken, id);

        return ResultModel.newSuccessResult(getUserId);
    }
    catch (error) {
        LoggerService.logInfo('getUser - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
