import {Link} from 'react-router-dom';
import {FileExclamationFilled, CreditCardFilled} from '@ant-design/icons';

import Title from '../../../components/ui/Title';
import SimpleCard from '../../../components/ui/SimpleCard/SimpleCard';

const ExpenseIndicator = ({totalExpensesToCheckItems, totalExpensesToPayItems, currentDate}) => {

    const expensesToCheckIcon = <FileExclamationFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;
    const expensesToPayIcon = <CreditCardFilled style={{ fontSize: '30px', color: '#ffff' }} twoToneColor="#ffff"/>;

    return (
        <div className='my-10'>
            <Title title={currentDate} textSize='text-3xl'/>
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <Link style={{width: '49%'}} to='expenses-to-check'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={expensesToCheckIcon}
                        title={totalExpensesToCheckItems}
                        subTitle="Notes de frais à vérifier"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '49%'}} to='expenses-to-pay'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={expensesToPayIcon}
                        title={totalExpensesToPayItems}
                        subTitle="Notes de frais à payer"
                        hoverEffect={true}
                    />
                </Link>
            </div>
        </div>
    )
}

export default ExpenseIndicator