import {HourglassFilled} from '@ant-design/icons';
import Progress from 'antd/lib/progress';

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import DonutsChart from '../../../../components/ui/DonutsChart/DonutsChart';
import Badge from '../../../../components/ui/Badge';
import {dasChartConfig} from '../../configs/productionTab/dasChartConfig';
import {concernedDasRessourcesCount, userDasLoad} from "../../functions/aggregateLoad"

const DasLoadTabChildren = ({das, productionLoad, selectedLoad, users}) => {

    const HourglassIcon = <HourglassFilled style={{ fontSize: '30px', color: '#e98b4e' }}/>;

    const data = [
        {
            type: 'Junior:',
            value: productionLoad.load[das].Junior
        },
        {
            type: 'Confirmé:',
            value: productionLoad.load[das].Confirmé
        },
        {
            type: 'Sénior:',
            value: productionLoad.load[das].Sénior
        }
    ];

    return (
        <div className='flex justify-between mt-10'>
            <div className='w-[48%] self-center'>
                <SimpleCard
                    width="w-full"
                    background="white"
                    font="orange"
                    lineColor="black"
                    title={concernedDasRessourcesCount(productionLoad.ressources, das)}
                    shadow={false}
                    borderSize='border'
                    subTitle={"Consultants"}
                    iconComponent={HourglassIcon}
                    topComponent={<TopComponent
                        selectedLoad={selectedLoad}
                        data={productionLoad.ressources}
                        users={users}
                    />}
                />
            </div>

            <div className='w-[48%] h-auto'>
                <DonutsChart data={data} config={dasChartConfig} label=''/>
            </div>
        </div>
    )
}

export default DasLoadTabChildren;

const TopComponent = ({data, selectedLoad, users}) => {

    const ressources = [];
    for (const ressource in data) {
        if (data[ressource].currentdas === selectedLoad.selected && data[ressource].load[selectedLoad.selected] > 0) {
            ressources.push({ressource: ressource, ...data[ressource]});
        }
    }

    return (

        <div className='flex flex-wrap pb-5 bg-white rounded-b-md'>
            {ressources.map((el, index) => {

                const userInfos = users.filter((user) => user.username === el.ressource);

                return (
                    <div key={index} className='flex items-center justify-between w-1/2 px-5 pt-5 gap-x-2'>
                        <Badge
                            style={{ width: '48px' }}
                            content={userInfos.length > 0 ? userInfos : el.ressource}
                            type='rounded'
                        />
                        <Progress
                            percent={userDasLoad(el)}
                            showInfo={true}
                            strokeColor='#e98b4e'
                        />
                    </div>
                )
            })}
        </div>
    )
    
}