export const resultsDto = {

    from(resultsResult) {

        const getGlobalData = () => {
            let dasS = {};

            if (!resultsResult.global) {
                return dasS;
            }
            else {
                for (const key in resultsResult.global) {
                    dasS[key] = {
                        "revenue": resultsResult.global?.[key]?.revenue ?? 0,
                        "contractors": resultsResult.global?.[key]?.contractors ?? 0,
                        "expenses": resultsResult.global?.[key]?.expenses ?? 0,
                        "procurements": resultsResult.global?.[key]?.procurements ?? 0,
                        "gross_margin": resultsResult.global?.[key]?.gross_margin ?? 0,
                        "salary": resultsResult.global?.[key]?.salary ?? 0,
                        "etp": resultsResult.global?.[key]?.etp ?? 0
                    }
                }

                return dasS;
            }
        }

        const getByStatusData = () => {
            let dasS = {};

            if (!resultsResult.by_status) {
                return dasS;
            }
            else {
                for (const key in resultsResult.by_status) {
                    dasS[key] = {
                        "junior": {
                            "gross_margin": resultsResult.by_status?.[key]?.Junior?.gross_margin ?? 0,
                            "salary": resultsResult.by_status?.[key]?.Junior?.salary ?? 0,
                            "etp": resultsResult.by_status?.[key]?.Junior?.etp ?? 0
                        },
                        "confirmed": {
                            "gross_margin": resultsResult.by_status?.[key]?.Confirmé?.gross_margin ?? 0,
                            "salary": resultsResult.by_status?.[key]?.Confirmé?.salary ?? 0,
                            "etp": resultsResult.by_status?.[key]?.Confirmé?.etp ?? 0
                        },
                        "senior": {
                            "gross_margin": resultsResult.by_status?.[key]?.Senior?.gross_margin ?? 0,
                            "salary": resultsResult.by_status?.[key]?.Senior?.salary ?? 0,
                            "etp": resultsResult.by_status?.[key]?.Senior?.etp ?? 0
                        },
                        "intern": {
                            "gross_margin": resultsResult.by_status?.[key]?.Stagiaire?.gross_margin ?? 0,
                            "salary": resultsResult.by_status?.[key]?.Stagiaire?.salary ?? 0,
                            "etp": resultsResult.by_status?.[key]?.Stagiaire?.etp ?? 0
                        }
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "global": getGlobalData(),
                "by_status": getByStatusData()
            }
        );
    }
}
