const roadStepConfig = {
    color: 'orange',
    width: 'w-7/12',
    items: [
        {
            title: 'En cours'
        },
        {
            title: 'À vérifier'
        },
        {
            title: 'À payer'
        },
        {
            title: 'Payée'
        }
    ]
};

export default roadStepConfig;
