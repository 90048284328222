import {Progress} from 'antd';
import {Empty} from 'antd';

import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import Badge from '../../../../components/ui/Badge';
import ProgressComponent from '../../../../components/ui/ProgressComponent/ProgressComponent';
import {dasTeamRessources, calculateMainPercentage, calculateDasPercentage} from '../../../codevDashboardPage/functions/aggregateDasTeam';

const DasLoadTeamTabChildren = ({data, dasKey, dasUsers}) => {

    const ressourcesData = dasTeamRessources(data.ressources, dasKey);

    return (
        <>
            {
                ressourcesData.length > 0 ?
                    <div className='flex flex-wrap justify-start'>
                        {ressourcesData.map((el, index) => {

                            const userInfos = dasUsers.filter((user) => user.username === el.username);

                            return (
                                <SimpleCard
                                    key={index}
                                    customStyle={`mt-7 ${index % 2 === 0 ? 'mr-5' : ''}`}
                                    className="mt-7"
                                    width="w-[32%]"
                                    background="white"
                                    font="orange"
                                    showLine={false}
                                    titleSize="text-2xl"
                                    title={userInfos.length > 0 ? `${userInfos[0]?.first_name} ${userInfos[0]?.last_name}` : "No data"}
                                    shadow={false}
                                    borderSize='border'
                                    iconComponent={<Badge content={userInfos.length > 0 ? userInfos : el.username} type='rounded'/>}
                                    topComponent={<TopComponent title="En Attente des données" percent={calculateMainPercentage(el)}/>}
                                    bottomComponent={<BottomComponent data={el}/>}
                                />
                            )
                        })}
                    </div> :
                    <div className='flex items-center justify-center w-full h-[400px]'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                    </div>
            }
        </>
    )
}

export default DasLoadTeamTabChildren;

const TopComponent = ({ title, percent }) => {
    return (
        <div className="pb-5">
            <div className="px-5 my-5">
                <Badge content={title}/>
            </div>
            <ProgressComponent textGradient={true} percent={percent}/>
        </div>
    )
}

const BottomComponent = ({ data }) => {

    const dasS = [];

    for (const key in data.load) {
        dasS.push({
            key: key,
            value: data.load[key]
        })
    }

    return (
        <div className='px-5 bg-white rounded-b-lg pb-14'>
            <div className='h-[1px] w-full bg-black my-10'/>

            {dasS.map((el, index) => {
                return (
                    <div key={index} className='flex items-center w-10/12'>
                        <p style={{fontFamily: 'Din condensed'}} className='w-20 text-2xl'>{el.key}</p>
                        <Progress percent={calculateDasPercentage(data, el.key)} showInfo={true} strokeColor={'#e98b4e'} trailColor={'#F5F5F580'}/>
                    </div>
                )
            })}
        </div>
    )
}
