import storage from 'redux-persist/lib/storage';

export const SET_GLOBALS = 'SET_GLOBALS';

export const persistUserConfig = {
    key: 'globals',
    storage
};

/* eslint-disable no-prototype-builtins */
const initialState = {
    vat: null
};

function GlobalsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_GLOBALS: {
            return Object.assign({}, state, {
                vat: action.payload.vat
            });
        }
        default:
            break;
    }

    return state;
}

export default GlobalsReducer;
