import React from 'react';

const DayCell = ({
    day,
    dayData,
    isClickable,
    cellStyle,
    textColor,
    handleSetCurrentDay,
    isOpenDays,
    dayDate,
    calendarConfig,
    onCalendarCellClick
}) => {
    const handleClickOnCell = () => {
        isClickable &&
            handleSetCurrentDay(day);

        if (isOpenDays && onCalendarCellClick) {
            onCalendarCellClick({
                type: 'day',
                date: dayDate
            });
        }
    };

    return (
        <div
            onClick={handleClickOnCell}
            className={`
                    flex flex-col justify-between h-28 border-t-2 py-1 px-2
                    ${cellStyle} ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'}
                `}
        >
            <p className={`${textColor} text-right text-sm`} style={{fontFamily: 'Roboto'}}>
                {day < 10 ? `0${day}` : day}
            </p>
            {isOpenDays && (calendarConfig.dayCellEventsComponent(dayData, dayDate))}
        </div>
    );
};

export default DayCell;
