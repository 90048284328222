import {BankDao} from '../../dao';
import {ErrorModel, BankModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_BANKS';

export async function getAllBanks(
    userData: Record<string, string>,
    params: string,
    page: number
) {
    try {
        const banks = await BankDao.getAllBanks(userData.userToken, params, page);

        const bankParsed = banks.results.map((bank: Record<string, any>) => BankModel.parseBank(bank));

        return bankParsed;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
