import {DealLossMotivesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_DEAL_ORIGINS';

export async function getAllDealLossMotives(
    userData: Record<string, string>,
    params: string,
    page: number,
) {
    try {
        const result = await DealLossMotivesDao.getAllDealLossMotives(userData, params, page);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des motifs de refus des affaires',
            error: error
        });
    }
}
