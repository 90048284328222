import {ACTION_CODE} from './_actionCode';
import {ExpenseService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_AGREGATED_CALENDAR_EXPENSES_BY_MONTH';

export async function getAggregatedCalendarExpensesByMonth(
    userData: Record<string, string>,
    before: string,
    after: string,
    query = ''
) {
    try {
        LoggerService.logInfo('Action - getAggregatedCalendarExpensesByMonth - Start');

        const getAggregatedCalendarExpensesByMonthResult = await ExpenseService.getAggregatedCalendarExpensesByMonth(userData, before, after, query);

        LoggerService.logInfo('Action - getAggregatedCalendarExpensesByMonth - End - Success');

        return ResultModel.newSuccessResult(getAggregatedCalendarExpensesByMonthResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAggregatedCalendarExpensesByMonth - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des frais.',
            error: error
        });

        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
