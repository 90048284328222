export const profitability = {

    from(profitabilityResult) {

        const getDasS = () => {
            let dasS = {};

            for (const key in profitabilityResult) {
                dasS[key] = {
                    "revenue": profitabilityResult[key]?.revenue ?? 0,
                    "gross_margin": profitabilityResult[key]?.gross_margin ?? 0,
                    "costs": profitabilityResult[key]?.costs ?? 0,
                    "deals": {
                        "loss": {
                            "count": profitabilityResult[key]?.deals?.loss?.count ?? 0,
                            "value": profitabilityResult[key]?.deals?.loss?.value ?? 0
                        },
                        "below_average": {
                            "count": profitabilityResult[key]?.deals?.below_average?.count ?? 0,
                            "value": profitabilityResult[key]?.deals?.below_average?.value ?? 0
                        },
                        "above_average": {
                            "count": profitabilityResult[key]?.deals?.above_average?.count ?? 0,
                            "value": profitabilityResult[key]?.deals?.above_average?.value ?? 0
                        }
                    }
                }
            }

            return dasS;
        }

        return getDasS();
    }
}
