import React from 'react';
import {Route, Routes} from 'react-router-dom';

import DasDashboardPage from '../pages/dasDashboardPage/DasDashboardPage';

function DasDashboardRouter() {
    return (
        <Routes>
            <Route path='/*' element={<DasDashboardPage/>}/>
        </Routes>
    );
}

export default DasDashboardRouter;
