import {ACTION_CODE} from './_actionCode';
import {ActivitiesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'UPDATE_ACTIVITY';

export async function updateActivity(token = '', activityId: number | string, payload: Record<string, unknown>) {
    try {
        LoggerService.logInfo('Action - updateActivity - Start');

        const result = await ActivitiesService.updateActivity(token, activityId, payload);

        LoggerService.logInfo('Action - updateActivity - End - Success');

        return ResultModel.newSuccessResult(result);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateActivity - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la mise à jour de l\'activité',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
