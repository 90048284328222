import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import Table from "../../../../components/ui/Table/Table";
import ContributionToMarginChart from "./ContributionToMarginChart";
import {contributionToMarginData} from "../../configs/resultIndicatorTab/contributionToMarginSection/contributionToMarginData";
import {contributionToMarginTableConfig} from "../../configs/resultIndicatorTab/contributionToMarginSection/contributionToMarginTableConfig";

const ContributionToMargin = ({data, dasKey}) => {

    return (
        <div className='mt-14'>
            <LineTitle title="Contribution à la marge" textSize='text-4xl'/>
            <div className="mt-6">
                <Table
                    data={contributionToMarginData(data.global, dasKey)}
                    tableConfig={contributionToMarginTableConfig()}
                />
            </div>
            <div className="mt-5">
                <ContributionToMarginChart
                    data={data.by_status}
                    dasKey={dasKey}
                />
            </div>
        </div>
    )
}

export default ContributionToMargin;
