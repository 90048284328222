import ContainerCard from '../../../../components/ui/ContainerCard';
import Table from '../../../../components/ui/Table';
import {customerInvoicesTableConfig} from '../../configs/sectionConfigs/customerInvoicesTableConfig';

const InvoicesSection = ({
    invoiceData,
    invoicesTableLoading,
    invoicesPaginationMetadata,
    handleGetAllInvoices
}) => {
    const invoicesTableConfig = customerInvoicesTableConfig();

    return (
        <ContainerCard
            title='Factures'
            coloredButtonDisplayed={false}
        >
            <Table
                data={invoiceData}
                tableConfig={invoicesTableConfig}
                loading={invoicesTableLoading}
                paginationMetadata={invoicesPaginationMetadata}
                onPageNumberChange={handleGetAllInvoices}
            />
        </ContainerCard>
    )
}

export default InvoicesSection;
