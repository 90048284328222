import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';

import SelectInput from '../../../../../components/ui/Inputs/SelectInput/SelectInput';
import DateInput from '../../../../../components/ui/Inputs/DateInput/DateInput';
import deleteIcon from '../../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../../assets/icons/blueStyle/delete-blue.png';
import Tooltip from 'antd/lib/tooltip';
import {frenchFormat} from '../../../../../utils/dateFormatting';
import TextInput from '../../../../../components/ui/Inputs/TextInput';

// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const RessourcesModule = ({
    control,
    disabled,
    handleGetRessources,
    productionRessourcesValues,
    setProductionRessourcesValues,
    ressources,
    errors
    
}) => {

    // --------------------------------------------
    // Temporary
    const blueStyle = false;

    // Get dropdows data
    useEffect(() => {

        // ↓ ressources dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();

    }, []);

    // --------------------------------------------
    // ↓ Functions local to Ressources Module ↓
    // --------------------------------------------

    const handleAddSale = () => {
        if (productionRessourcesValues.length < 10) {
            setProductionRessourcesValues((prevState) => [...prevState, {id: Math.random(), ressource: null, intervention_days: 0}]);
        }
    }

    const handleRemoveSale = (id) => {
        setProductionRessourcesValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleSaleValueChange = (e, id, type) => {
        setProductionRessourcesValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }

    const filterOutAlreadySelected = (options, selected) => {
        
        if (!options) return;

        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.id === obj2?.ressource?.id
            )
        );

        return filteredArray;
    };

    return (
        <>
            <div className="w-full flex flex-col items-center px-4 pb-6 border-b border-gray-200">
                <div className="flex justify-start text-gradient w-[110%] pb-2 mb-6 border-b border-gray-200">
                    Ressources
                </div>
                <div className='flex justify-start w-full pb-3'>
                    <Controller
                        name="production_lead"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le nom du responsable de l\'affaire'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label="Responsable"
                                placeholder="Responsable"
                                inputRef={ref}
                                error={error}
                                value={value}
                                options={ressources?.options}
                                hasNextPage={ressources?.hasNextPage}
                                fetchFunction={handleGetRessources}
                                onChange={onChange}
                                labelKeys={['name']}
                                isSearchable={true}
                                disabled={disabled}
                                required={true}
                                labelWidth='w-40'
                                loading={!ressources}
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between w-full'>
                    <Controller
                        name="mission_start_date"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez selectionner une date'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label={"Début du chantier"}
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                            />
                        )}
                    />
                    <Controller
                        name="mission_end_date"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez selectionner une date'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label={"Fin du chantier"}
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                            />
                        )}
                    />
                </div>
                
            </div>
            <div className="w-full flex flex-col items-center px-4 pb-6 border-b border-gray-200">
                <div className='w-full mt-6'>
                    {
                        productionRessourcesValues.map((sale, index) => {
                            return (
                                <div
                                    key={sale.id}
                                >
                                    <div className='w-full flex flex-row justify-between  mb-3.5'>
                                        <div className='flex'>
                                            <div className='mr-10'>
                                                <SelectInput
                                                    placeholder='Trigramme'
                                                    width='w-32'
                                                    value={sale.ressource}
                                                    options={filterOutAlreadySelected(ressources?.options, productionRessourcesValues)}
                                                    onChange={(e) => handleSaleValueChange(e, sale.id, 'ressource')}
                                                    labelKeys={['username']}
                                                    disabled={disabled}
                                                    fetchFunction={handleGetRessources}
                                                    hasNextPage={ressources?.hasNextPage}
                                                    isSearchable={true}
                                                    loading={!ressources}
                                                />
                                            </div>
                                            <TextInput
                                                label=''
                                                onChange={(e) => handleSaleValueChange(e, sale.id, 'intervention_days')}
                                                value={sale.percent}
                                                error={null}
                                                required={true}
                                                disabled={disabled}
                                                placeholder="Jours d'intervention"
                                                type="number"
                                                width='w-62'
                                                customStyle='mr-10'
                                            />
                                        </div>
                                        <div className='flex justify-between'>
                                            {
                                                index === 0 ?
                                                    <div className=''>
                                                        <Controller
                                                            name="budget"
                                                            control={control}
                                                            rules={{required: {
                                                                value: true,
                                                                message: 'Veuillez selectionner une date'
                                                            }}}
                                                            render={({
                                                                field: {onChange, value},
                                                                fieldState: {error}
                                                            }) => (
                                                                <TextInput
                                                                    label=''
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    error={null}
                                                                    required={true}
                                                                    disabled={disabled}
                                                                    placeholder="Dépenses prévues"
                                                                    type="number"
                                                                    width='w-62'
                                                                    customStyle='mr-10'
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    : <button
                                                        onClick={() => handleRemoveSale(sale.id)}
                                                        disabled={disabled}
                                                        className="flex items-center justify-center p-1 bg-transparent"
                                                    >
                                                        <Tooltip title='Supprimer'>
                                                            <div>
                                                                <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                            </div>
                                                        </Tooltip>
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className='w-full flex items-center  mb-6'>
                        <Tooltip title='Ajouter une ressource'>
                            <button
                                disabled={!(productionRessourcesValues.length < 10)}
                                onClick={handleAddSale}
                                className={`whitespace-nowrap cursor-pointer text-sm text-gradient ${productionRessourcesValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"}`}
                            >
                                <p className='hover:underline'>Ajouter une ressource</p>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RessourcesModule;
