import {TrainingAttendancesDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'UPDATE_TRAINING_ATTENDANCE';

export async function updateTrainingAttendance(
    userData: Record<string, string>,
    updatedTrrainingAttendance: Record<string, any>,
    id: string
) {

    try {
        return await TrainingAttendancesDao.updateTrainingAttendance(userData.userToken, updatedTrrainingAttendance, id);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mis à jour du suivi de stagiaire',
            error: error
        });
    }
}
