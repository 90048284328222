import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import { getCurrentMonthAndYear } from '../../../utils/getCurrentYearProjection';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function StackedGroupedColumnPlotChart({data}) {

    const config = {
        plugins: {
            title: {
                display: true,
                align: 'start',
                color: '#212121',
                text: 'Projection des besoins des ressources',
                font: {
                    size: 24,
                    weight: 'bold',
                    family: 'Din condensed'
                }
            },
            legend: {
                align: "end"
            }
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        }
    };

    //construct real data from backend

    const labels = data.labels

    const dataSetProductionLoad = data.labels.map((label) => data.chartData.find((item) => item.load_type === 'Charge de production' && item.period === label).value);

    const dataSetPrevisionalLoad = data.labels.map((label) => data.chartData.find((item) => item.load_type === 'Charge prévisionnelle' && item.period === label).value);

    const dataSetRessourcesLoad = data.labels.map((label) => data.chartData.find((item) => item.load_type === 'Niveau de ressources humaines' && item.period === label).value);

    const dataChart = {
        labels,
        datasets: [
            {
                label: 'Charge de production',
                data: dataSetProductionLoad,
                backgroundColor: '#e67a3a',
                stack: 'Stack 0'
            },
            {
                label: 'Charge prévisionnelle',
                data: dataSetPrevisionalLoad,
                backgroundColor: '#f0a841',
                stack: 'Stack 0'
            },
            {
                label: 'Niveau de ressources humaines',
                data: dataSetRessourcesLoad,
                backgroundColor: '#212121',
                stack: 'Stack 1'
            }
        ]
    };

    return <Bar className="w-full h-full" options={config} data={dataChart}/>;
}