import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ExpensesPage from '../pages/expensesPage/ExpensesPage';
import ExpensesReportPageDetails from '../pages/expensesPage/ExpensesReportPageDetails';

function ExpensesRoute() {
    return (
        <Routes>
            <Route path='/' element={<ExpensesPage/>}/>

            <Route path='/:id' element={<ExpensesReportPageDetails/>}/>
        </Routes>

    );
}

export default ExpensesRoute;
