import {DashboardDao} from '../../../dao';
import {ErrorModel} from '../../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ADMIN_DASHBOARD_CONTRACTORS';

export async function getContractors(userData: Record<string, string>, params: string) {
    try {
        const result = await DashboardDao.AdminDao.getContractors(userData.userToken, params);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
