export const expenses = {

    from(expensesResult) {

        const getByDasData = () => {
            let dasS = {};

            for (const key in expensesResult.by_das) {
                dasS[key] = {
                    "expenses": expensesResult.by_das?.[key]?.expenses ?? 0,
                    "procurements": expensesResult.by_das?.[key]?.procurements ?? 0,
                    "billable_expenses": expensesResult.by_das?.[key]?.billable_expenses ?? 0,
                    "details": {
                        "Voyage et déplacement": expensesResult.by_das?.[key]?.details?.['Voyage et déplacement'] ?? 0,
                        "Frais de mission": expensesResult.by_das?.[key]?.details?.['Frais de mission'] ?? 0,
                        "Achats société": expensesResult.by_das?.[key]?.details?.['Achats société'] ?? 0,
                        "Autres": expensesResult.by_das?.[key]?.details?.['Autres'] ?? 0
                    }
                }
            }

            return dasS;
        }

        const getByOfficeData = () => {
            let dasS = {};

            for (const key in expensesResult.by_office) {
                dasS[key] = {
                    "details": {
                        "Voyage et déplacement": expensesResult.by_office?.[key]?.details?.['Voyage et déplacement'] ?? 0,
                        "Frais de mission": expensesResult.by_office?.[key]?.details?.['Frais de mission'] ?? 0,
                        "Achats société": expensesResult.by_office?.[key]?.details?.['Achats société'] ?? 0,
                        "Autres": expensesResult.by_office?.[key]?.details?.['Autres'] ?? 0
                    }
                }
            }

            return dasS;
        }

        return (
            {
                "gross_margin": expensesResult.gross_margin ?? 0,
                "by_das": getByDasData(),
                "by_office": getByOfficeData()
            }
        );
    }
}
