import {CreditCardFilled, BookFilled, EuroCircleFilled, HourglassFilled, SnippetsFilled, TrophyFilled} from '@ant-design/icons';

import LineTitle from "../../../../components/ui/LineTitle/LineTitle";
import SimpleCard from "../../../../components/ui/SimpleCard/SimpleCard";
import ProgressComponent from '../../../../components/ui/ProgressComponent/ProgressComponent';

const Commercial = ({ sales }) => {

    if (Object.keys(sales).length === 0) {
        return null;
    }

    const { analyzed, commissions, objective, pending, responded, won } = sales;

    let progressValue = (won.budget_share / objective) * 100;
    const tooltipValue = +objective - +won.budget_share + ' €';

    progressValue = Math.trunc(progressValue);

    const CreditCardIcon = <CreditCardFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const BookIcon = <BookFilled style={{ fontSize: '30px', color:'white' }}/>;
    const EuroIcon = <EuroCircleFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const SnippetsIcon = <SnippetsFilled  style={{ fontSize: '30px', color: '#e77e3b' }}/>;
    const TrophyIcon = <TrophyFilled style={{ fontSize: '30px', color: '#e77e3b' }}/>;
    const hourglassIcon = <HourglassFilled style={{ fontSize: '30px', color: '#e77e3b' }}/>;

    return (
        <div className="my-10">
            <LineTitle title="Commercial"/>
            
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <SimpleCard
                    width='w-[31%]'
                    title={objective + ' €'}
                    showLine={false}
                    subTitle="Objectif contractuel de CA"
                    iconComponent={CreditCardIcon}
                    topComponent={<ProgressComponent style='white' tooltipValue={tooltipValue} percent={progressValue}/>}
                    backgroundHeight='h-auto'
                />

                <SimpleCard
                    width='w-[31%]'
                    title={analyzed}
                    subTitle="Cahiers des charges étudiés"
                    iconComponent={BookIcon}
                />

                <SimpleCard
                    width='w-[31%]'
                    title={commissions + ' €'}
                    subTitle="Commissions commerciales"
                    iconComponent={EuroIcon}
                />

                <SimpleCard
                    width='w-[31%]'
                    title={responded.count}
                    subTitle="Propositions faites"
                    background="white"
                    font="orange"
                    lineColor="black"
                    iconComponent={SnippetsIcon}
                    // eslint-disable-next-line max-len
                    topComponent={<TopComponent totalEuros={responded.budget} chargeableEuros={responded.budget_share} chargeableEurosPercentage={responded.budget_share || responded.budget === 0 ? ((responded.budget_share / responded.budget) * 100).toFixed(0) : 0}/>}
                />

                <SimpleCard
                    width='w-[31%]'
                    title={pending.count}
                    subTitle="Propositions en attente"
                    background="white"
                    font="orange"
                    lineColor="black"
                    iconComponent={hourglassIcon}
                    // eslint-disable-next-line max-len
                    topComponent={<TopComponent totalEuros={pending.budget} chargeableEuros={pending.budget_share} chargeableEurosPercentage={pending.budget_share || pending.budget === 0 ? ((pending.budget_share / pending.budget) * 100).toFixed(0) : 0}/>}
                />

                <SimpleCard
                    width='w-[31%]'
                    title={won.count}
                    subTitle="Propositions gagnées"
                    background="white"
                    font="orange"
                    lineColor="black"
                    iconComponent={TrophyIcon}
                    topComponent={<TopComponent totalEuros={won.budget} chargeableEuros={won.budget_share} chargeableEurosPercentage={'50'}/>}
                />
            </div>
        </div>
    )
}

export default Commercial;

const TopComponent = ({ totalEuros, chargeableEuros, chargeableEurosPercentage }) => {
    return (
        <div className='flex justify-between px-6 pb-6'>
            <div className='flex flex-col items-start justify-center w-[40%]'>
                <p style={{ fontFamily: 'Din Condensed' }} className='mb-1 text-4xl text-gradient'>{totalEuros || 0} €</p>
                <p className='text-sm text-center text-gradient'>Euros totaux</p>
            </div>

            <div className='flex flex-col items-start justify-center w-[40%]'>
                <div className="flex items-center justify-between w-full">
                    <p style={{ fontFamily: 'Din Condensed' }} className='mb-1 text-4xl text-gradient'>{chargeableEuros || 0} €</p>
                    <p className="bg-[#646464] text-white text-xs py-0.5 px-2 rounded-full block">{chargeableEurosPercentage}%</p>
                </div>
                <p className='text-sm text-center text-gradient'>Euros imputables</p>
            </div>
        </div>
    )
}
