import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';

import InvoicePreviewPopup from './components/InvoicePreviewPopup';
import {InvoiceActions} from '../../actions';
import {Toast, notify} from '../../components/ui/Toast/Toast';
import Table from '../../components/ui/Table';
import {invoicesTableConfigSend} from './config/invoicesTableConfigSend';

const InvoicesToIssuePage = ({
    data,
    refreshData,
    setActionButtonLoading,
    setActionButtonDisabled,
    actionButtonDisabled,
    actionButtonLoading,
    filterInvoicesList,
    userData,
    tableType,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [itemSelected, setItemSelected] = useState(null);
    const [showInvoicePreviewPopup, setShowInvoicePreviewPopup] = useState(false);

    useEffect(() => {
        if (!showInvoicePreviewPopup) {
            setItemSelected(null);
        }
    }, [showInvoicePreviewPopup]);

    const handleInvoicePreview = (item) => {
        setItemSelected(item);
        setShowInvoicePreviewPopup(true);
    }

    const handleDeleteInvoice = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${item.id}del`);

        const result = await InvoiceActions.deleteInvoiceById(userData, item.id);

        if (result.success) {
            await refreshData(true);
            notify('Votre facture à été supprimée');
        }
        else {
            notify('Votre facture n\'a pas pu être supprimée');
        }
        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    const handleSendInvoice = async (item) => {
        setActionButtonDisabled(true);
        setActionButtonLoading(`${item.id}upd`);

        const result = await InvoiceActions.updateInvoiceById(userData, item.id, {status: 300});

        if (result.success) {
            await refreshData(true);
            notify('Votre facture à été émise');
        }
        else {
            notify('Votre facture n\'a pas pu être émise');
        }

        setActionButtonLoading(null);
        setActionButtonDisabled(false);
    };

    const tableConfig = invoicesTableConfigSend(
        handleDeleteInvoice,
        handleSendInvoice,
        actionButtonLoading,
        actionButtonDisabled,
        handleInvoicePreview,
        filterInvoicesList,
        tableType,
        userData
    );

    return (
        <>
            <Toast/>
            <div className='flex items-center justify-between my-10'>
                <Link to='/dashboard/office-manager'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Factures à émettre (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />

            <InvoicePreviewPopup
                view={showInvoicePreviewPopup}
                setView={setShowInvoicePreviewPopup}
                item={itemSelected}
                type='invoice'
            />
        </>
    )
}

export default InvoicesToIssuePage;
