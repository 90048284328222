import CreatableSelect from 'react-select/creatable';
import {components} from 'react-select';

import './tag-input.css';

const TagInput = ({
    onChange,
    data,
    suggestions,
    error,
    errorMessage = 'Veuillez saisir au moins un tag',
    label = 'Tags',
    required = false,
    labelStyle = 'text-sm text-[#646464] w-36',
    placeholder = 'Tags',
    disabled = false,
    indicatorMessage = null
}) => {

    const formatCreateLabel = (inputValue) => {
        return `Créer "${inputValue}"`;
    };

    return (
        <div>
            <div className='flex items-center'>
                <p className={labelStyle}>
                    {label}
                    {required && <span className='text-red-500'> *</span>}
                </p>
                <CreatableSelect
                    value={data}
                    isMulti
                    name="tags"
                    isDisabled={disabled}
                    options={suggestions}
                    classNamePrefix="tag-input"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    onChange={onChange}
                    placeholder={placeholder}
                    formatCreateLabel={formatCreateLabel}
                    components={{ ClearIndicator }}
                    noOptionsMessage={() => 'Aucune option'}
                />
            </div>
            {
                indicatorMessage ?
                    <div
                        dangerouslySetInnerHTML={{__html: indicatorMessage}}
                        className={`text-[#8c8c8c] italic mb-4 text-[13px] ${label ? 'pl-36' : ''}`}
                    /> : null
            }
            {error && <p className='text-xs italic text-red-500'>{errorMessage}</p>}
        </div>
    )
}

export default TagInput;

const ClearIndicator = ({ children, ...props }) => (
    <components.ClearIndicator
        {...props}
        innerProps={{
            ...props.innerProps,
            onMouseDown: (e) => {
                e.stopPropagation();
                props.innerProps.onMouseDown(e);
            }
        }}
    >
        {children}
    </components.ClearIndicator>
);
