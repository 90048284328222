import {ACTION_CODE} from './_actionCode';
import {ContractorInvoicesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_CONTRACTOR_INVOICE';

export async function createContractorInvoice(
    userData: Record<string, string>,
    newContractorInvoice: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - createContractorInvoice - Start');

        const createContractorInvoiceResult = await ContractorInvoicesService.createContractorInvoice(userData, newContractorInvoice);

        LoggerService.logInfo('Action - createContractorInvoice - End - Success');

        return ResultModel.newSuccessResult(createContractorInvoiceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createContractorInvoice - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
