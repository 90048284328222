export const marginProducedTableConfig = () => {
    return (
        {
            dropdown: false,
            dropdownLeftPadding: true,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: '-',
                    value: null,
                    width: 'w-[20%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-start w-full">
                                {item.name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Chiffre d’affaires HT',
                    value: 'turnover',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.turnover.toFixed(2) + ' €'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Sous-traitance',
                    value: 'sub_contracting',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.sub_contracting.toFixed(2) + ' €'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Note de frais',
                    value: 'expense_report',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.expense_report.toFixed(2) + ' €'}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Achats directs',
                    value: 'direct_purchases',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.direct_purchases.toFixed(2) + ' €'}
                            </div>
                        )
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Marge brute',
                    value: 'gross_margin',
                    width: 'w-[15%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.gross_margin.toFixed(2) + ' €'}
                                <span className="ml-2 text-[11px] text-gray-400">({item.gross_margin_percentage + ' %'})</span>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
