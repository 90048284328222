
import DonutsChart from "../../../../../components/ui/DonutsChart/DonutsChart";
import {marginProducedChartConfig} from "./marginProducedChartConfig";
import {aggregateMarginPerStatus, aggregateMarginPerStatusTotal} from "../../../functions/aggregateMarginProduced";

export const marginProducedTabConfig = (by_status) => {

    console.log("marginProducedChartConfig???", marginProducedChartConfig());

    const tabs = [{
        id: 1,
        label: 'Cabinet',
        component: <DonutsChart label={'€'} data={aggregateMarginPerStatusTotal(by_status)} config={marginProducedChartConfig}/>
    }]

    for (let key in by_status) {
        tabs.push({
            id: key,
            label: key,
            component: <DonutsChart label={'€'} data={aggregateMarginPerStatus(by_status, key)} config={marginProducedChartConfig}/>
        })
    }

    return (
        {
            width: 'w-full',
            height: 'h-auto',
            showCardHeader: false,
            childOverflow: 'overflow-none',
            tapSelectorPosition: 'self-start',
            tabs: tabs
        }
    );
};
