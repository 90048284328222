import {ProductionCategoryDao} from '../../dao';
import {ErrorModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';
const FUNCTION_CODE = 'GET_ALL_PRODUCTION_CATEGORIES';

export async function getAllProductionCategories(userData: Record<string, string>, params: string, page: number) {
    try {
        const result = await ProductionCategoryDao.getAllProductionCategories(userData.userToken, params, page);

        return result;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
