import {ErrorModel, ResultModel} from '../../models';
import {Dispatch, AnyAction} from '@reduxjs/toolkit';
import {LoggerService} from '../../services';
import {SET_USER_PROFILE} from '../../reducers/UserReducer';
const FUNCTION_CODE = 'LOG_OUT';

export async function logOut(dispatch: Dispatch<AnyAction>) {
    try {
        LoggerService.logInfo('Action - logOut- Start');

        dispatch({
            type: SET_USER_PROFILE,
            payload: null
        });

        LoggerService.logInfo('Action - logOut - End - Success');

        return ResultModel.newSuccessResult();
    }
    catch (error) {
        LoggerService.logInfo('logOut - Error');
        const newErr = ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
