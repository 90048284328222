import {ACTION_CODE} from './_actionCode';
import {DashboardService} from '../../../services';
import {ErrorModel, ResultModel} from '../../../models';
import {LoggerService} from '../../../services';
const FUNCTION_CODE = 'GEY_SALES_ACTIVITY';

export async function getSalesActivity(userData: Record<string, any>, params: string) {
    try {
        LoggerService.logInfo('Action - getSalesActivity - Start');

        const getSalesActivity =
            await DashboardService.CodevService.getSalesActivity(userData, params);

        LoggerService.logInfo('Action - getSalesActivity - End - Success');

        return ResultModel.newSuccessResult(getSalesActivity);
    }
    catch (error) {
        LoggerService.logInfo('Action - getSalesActivity - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
