import {ACTION_CODE} from './_actionCode';
import {DealTagsService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'GET_ALL_DEAL_TAGS';

export async function getAllDealTags(
    userData: Record<string, string>,
    params = "",
    page = 1
) {
    try {
        LoggerService.logInfo('Action - getAllDealTags - Start');

        const getAllDealTagsResult = await DealTagsService.getAllDealTags(userData, params, page);

        LoggerService.logInfo('Action - getAllDealTags - Success');

        return ResultModel.newSuccessResult(getAllDealTagsResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - getAllDealTags - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récupération de la liste des tags',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
