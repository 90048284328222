import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ArrowLeftOutlined} from '@ant-design/icons';

import Table from '../../components/ui/Table';
import {currentMissionsTableConfig} from './configs/currentMissionsTableConfig';
import edit from '../../assets/icons/actionsbutton/edit.png';
import {MissionActions} from '../../actions';
import {notify} from '../../components/ui/Toast/Toast';
import {addMissionKeys} from '../../utils';
import Error from '../../components/ui/Error/Error';
import {getErrors} from '../../utils';

const CurrentMissionsPage = ({
    data,
    refreshData,
    filterMissionList,
    handleMissionNavigation,
    paginationMetadata,
    onPageNumberChange,
    tableLoading
}) => {
    const [tableEdit, setTableEdit] =  useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] =  useState(false);
    const [errors, setErrors] = useState(null);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const tableConfig = currentMissionsTableConfig(tableEdit, filterMissionList, userData, handleMissionNavigation);

    const onSaveFunction = async (submitData) => {
        const updateResult = submitData.map(async (el) => {

            const missionToUpdate = data.find((mission) => mission.id === el.id);

            const dataKeys = Object.keys(el);

            const isSame = dataKeys.every((key) => el[key] === missionToUpdate[key]);

            if (isSame) {
                return;
            }

            const payload = {...missionToUpdate, ...el}

            return await MissionActions.updateMissionById(userData, payload);
        })

        const resolve = await Promise.all(updateResult);

        const results = resolve.filter(Boolean)

        if (results.every((result) => result.success)) {
            await refreshData();
            setTableEdit(false);
            setSubmitButtonLoading(false);
            notify('Missions mises à jour');
        }
        else {
            const errorResults = results.filter((result) => !result.success);

            const allErrors = errorResults.map((el) => {
                const err = getErrors(el);

                return err[0];
            })

            const assimilatedErrors = allErrors.filter((el, index) => {
                return index === allErrors.findIndex(other => el.title === other.title && el.message === other.message);
            });

            setSubmitButtonLoading(false);

            setErrors(assimilatedErrors);
        }
    }

    useEffect(() => {
        if (!tableEdit) {
            setErrors(null);
        }
    }, [tableEdit])

    return (
        <>
            <div className='flex items-center justify-between pt-10 pb-5'>
                <Link to='/dashboard/consultant'>
                    <ArrowLeftOutlined style={{fontSize: '25px'}}/>
                </Link>
                <h1
                    className='text-3xl font-bold'
                    style={{fontFamily: 'DIN Condensed'}}
                >
                    {`Missions en cours (${paginationMetadata.totalItems})`}
                </h1>
                <div/>
            </div>
            <div className='flex justify-end min-h-[36px]'>
                {
                    !tableEdit &&
                        <button onClick={() => setTableEdit(true)} className='p-2'>
                            <img src={edit} alt="download" className='w-5'/>
                        </button>
                }
            </div>
            <Table
                data={addMissionKeys(data)}
                tableConfig={tableConfig}
                showSubmitButtons={tableEdit}
                setShowSubmitButtons={setTableEdit}
                onSaveFunction={onSaveFunction}
                submitButtonLoading={submitButtonLoading}
                setSubmitButtonLoading={setSubmitButtonLoading}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onPageNumberChange}
                loading={tableLoading}
            />
            <Error errors={errors}/>
        </>
    )
}

export default CurrentMissionsPage;
