import {Link} from 'react-router-dom';
import {RocketTwoTone, IdcardTwoTone, BulbTwoTone, BookFilled, FileFilled, HourglassFilled } from '@ant-design/icons';

import LineTitle from '../../../../components/ui/LineTitle';
import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';

const ActivityIndicator = ({
    totalMissionItems,
    totalpilotedDealItems,
    totalInternalProjectsItems,
    totalSpecificationsStudyItems,
    totalProposalsInProgressItems,
    totalProposalsDeliveredItems
}) => {

    const rocketIcon = <RocketTwoTone style={{ fontSize: '30px' }} twoToneColor="#ffff"/>;
    const idCardIcon = <IdcardTwoTone style={{ fontSize: '30px' }} twoToneColor="#ffff"/>;
    const bulbIcon = <BulbTwoTone style={{ fontSize: '30px' }} twoToneColor="#ffff"/>;
    const bookIcon = <BookFilled style={{ fontSize: '30px', color: '#e77e3b' }} twoToneColor="#e77e3b"/>;
    const fileIcon = <FileFilled style={{ fontSize: '30px', color: '#e77e3b' }} twoToneColor="#e77e3b"/>;
    const hourglassIcon = <HourglassFilled style={{ fontSize: '30px', color: '#e77e3b' }} twoToneColor="#e77e3b"/>;

    return (
        <div className='my-14'>
            <LineTitle title="Indicateurs d'activité"/>
            <div className='flex flex-wrap justify-between mt-5 gap-x-4 gap-y-5'>
                <Link style={{width: '31%'}} to='current-missions'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={rocketIcon}
                        title={totalMissionItems || '0'}
                        subTitle="Missions en cours"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '31%'}} to='piloted-deals'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={idCardIcon}
                        title={totalpilotedDealItems || '0'}
                        subTitle="Affaires pilotées"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '31%'}} to='internal-projects'>
                    <SimpleCard
                        width='w-full'
                        iconComponent={bulbIcon}
                        title={totalInternalProjectsItems || '0'}
                        subTitle="Projets internes"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '31%'}} to='specifications-study'>
                    <SimpleCard
                        borderSize='p-[2px]'
                        width='w-full'
                        iconComponent={bookIcon}
                        borderColor='orange'
                        lineColor="orange"
                        background='white'
                        font='orange'
                        title={totalSpecificationsStudyItems || '0'}
                        subTitle="Cahiers des charges à étudier"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '31%'}} to='proposals-in-progress'>
                    <SimpleCard
                        borderSize='p-[2px]'
                        width='w-full'
                        iconComponent={fileIcon}
                        borderColor='orange'
                        lineColor="orange"
                        background='white'
                        font='orange'
                        title={totalProposalsInProgressItems || '0'}
                        subTitle="Propositions à rédiger"
                        hoverEffect={true}
                    />
                </Link>
                <Link style={{width: '31%'}} to='proposals-delivered'>
                    <SimpleCard
                        borderSize='p-[2px]'
                        width='w-full'
                        iconComponent={hourglassIcon}
                        borderColor='orange'
                        lineColor="orange"
                        background='white'
                        font='orange'
                        title={totalProposalsDeliveredItems || '0'}
                        subTitle="Propositions en attente"
                        hoverEffect={true}
                    />
                </Link>
            </div>
        </div>
    )
}

export default ActivityIndicator