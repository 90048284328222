import {ACTION_CODE} from './_actionCode';
import {ProductionService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'UPDATE_PRODUCTIONS';

export async function updateProduction(
    userData: Record<string, any>,
    productionId: string,
    payloadUpdate: Record<string, any>
) {
    try {
        LoggerService.logInfo('Action - updateProduction - Start');

        const updateProductionResult =
            await ProductionService.updateProduction(userData, productionId, payloadUpdate);

        LoggerService.logInfo('Action - updateProduction - End - Success');

        return ResultModel.newSuccessResult(updateProductionResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateProduction - End - Error');
        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
