import * as z from 'zod';

export const ProductOriginSchema = z.object({
    "id": z.number(),
    "is_active": z.boolean(),
    "name": z.string()
});

export const ProductOriginPartialSchema = ProductOriginSchema.partial();
export type ProductOriginPartial = z.infer<typeof ProductOriginPartialSchema>;
export type ProductOrigin = z.infer<typeof ProductOriginSchema>;

export function newProductOrigin(data: unknown) {
    if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }

    if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }

    return {
        ...ProductOriginSchema.parse({...data})
    };
}

export function parseProductOrigin(data: any, partial = false) {
    if (!partial) {
        return {
            ...ProductOriginSchema.parse(data)
        };
    }
    else if (data === null || data === undefined) {
        throw new Error('Payload can\'t be null or undefined.');
    }
    else if (typeof data !== 'object') {
        throw new Error('Payload has to be an object');
    }
    else if (Object.keys(data).length === 0) {
        throw new Error('Payload Object can\'t be null');
    }
    else {
        return {
            ...ProductOriginPartialSchema.parse(data)
        };
    }
}
