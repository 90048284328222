import {useState} from "react";
import {UpOutlined,  DownOutlined} from "@ant-design/icons";

import Table from "../Table";

const NotificationCard = ({
    titleIconComponent,
    subTitle,
    title,
    tableData,
    tableConfig
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const UpOutIcon =  <UpOutlined style={{ color: '#fff', fontSize: '20px'}}/>;
    const DownOutIcon = <DownOutlined style={{ color: '#fff', fontSize: '20px'}}/>;

    return (
        <div className="rounded-lg card-shadow">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={`transition-all hover:opacity-90 px-6 py-4 bg-[#DC3832] rounded-t-lg ${!isOpen && 'rounded-b-lg'} cursor-pointer`}
            >
                <div className="flex flex-row items-center justify-between">
                    <div className="flex items-center mb-[11px]">
                        <p style={{ fontFamily: 'Din Condensed' }} className="ml-2 mr-5 text-3xl font-bold -mb-[11px] text-white">
                            {title}
                        </p>
                        {titleIconComponent}
                    </div>
                    {isOpen ? UpOutIcon : DownOutIcon}
                </div>
                {
                    subTitle ?
                        <p style={{ fontFamily: 'DIN condensed' }} className="mt-2 text-xl text-white">{subTitle}</p>
                        : null
                }
                <div className={`h-[1px] bg-white mt-4`}/>
            </div>
            {
                isOpen ?
                    <div id="skip" className="p-5 bg-white rounded-b-md">
                        <Table
                            data={tableData}
                            tableConfig={tableConfig}
                        />
                    </div>
                    : null
            }
        </div>
    );
}

export default NotificationCard;
