
// Section is an array of objects with the following structure:
// sections={
// [{name:"Factures à éditer", icon:<FireOutlined/>},
//  {name: "Factures à émettre", icon:<PlusOutlined/>}
// ]

function TabNavigator({
    displayedSection,
    setDisplayedSection,
    sections,
    onClick = null,
    borderBottom = false
}) {
    const handleClick = (section) => {
        setDisplayedSection(section.name)
        
        if (onClick) {
            onClick()
        }
    };

    return (
        <div className="w-100% flex flex-row items-center justify-center ">
            <div className={`flex flex-row ${borderBottom ? "border-b-2 border-[#F0F0F0]" : ""} w-fit items-center justify-center text-[#646464] text-base font-normal`}>
                {sections.map((section, index) => {
                    return (
                        <div className="flex items-center justify-between mx-10" key={index}>
                            <div className={`flex mr-2 ${displayedSection === section.name && "text-[#DC3832]"}`}>
                                {section.icon && section.icon}
                            </div>
                            <button
                                onClick={() =>
                                    handleClick(section)
                                }
                                className={`
                                    py-[10px] border-b
                                    ${displayedSection === section.name ? 'text-[#DC3832] border-[#DC3832]' : 'border-transparent'}
                                `}
                            >
                                {section.name}
                            </button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TabNavigator;
