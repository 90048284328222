import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";

const FilePreviewPopup = ({url}) => {
  
    const docs = [{uri: url}];

    return (
        <DocViewer
            style={{height: '60vh', width: '850px'}}
            documents={docs}
            initialActiveDocument={docs[0]}
            pluginRenderers={DocViewerRenderers}
            config={{ header: {
                disableHeader: true
            },
            pdfZoom: {
                defaultZoom: 1.1,
                zoomJump: 0.2
            }
            }}
        />
    );
}

export default FilePreviewPopup;
