import {ACTION_CODE} from './_actionCode';
import {TrainingSpecialitiesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'DELETE_TRAINING_SPECIALITY';

export async function deleteTrainingSpeciality(userData: Record<string, string>, id: number) {
    try {
        LoggerService.logInfo('Action - deleteTrainingSpeciality - Start');

        const deleteTrainingSpecialityResult = await TrainingSpecialitiesService.deleteTrainingSpeciality(userData, id);

        LoggerService.logInfo('Action - deleteTrainingSpeciality - End - Success');

        return ResultModel.newSuccessResult(deleteTrainingSpecialityResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - deleteTrainingSpeciality - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
