import {ErrorModel} from '../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'UPDATE_REFERENCE';

export async function updateReference(
    userToken: string,
    updatedReference: Record<string, unknown>,
    id: string
) {
    try {
        const response = await axios({
            method: 'patch',
            url: `${URL}/references/${id}/`,
            data: updatedReference,
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${userToken}`
            }
        });

        return response.data;
    }
    catch (error: any) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Erreur lors de la mise à jour de la reference.',
            error: error
        });
    }
}
