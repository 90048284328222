import {ErrorModel} from '../../../models';
import {DAO_CODE} from './_daoCode';
import axios from 'axios';
import {URL} from '../../root';
import {salesOverviewDto} from './defaultResponseModel/salesOverview';
const FUNCTION_CODE = 'GET_SALES_ACTIVITY';

export async function getSalesOverview(userToken: string, params: string) {
    try {
        const response = await axios.get(
            `${URL}/dashboards/codev/sales-overview/${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return salesOverviewDto.from(response.data);
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
    }
}
