import {ACTION_CODE} from './_actionCode';
import {ExpenseTypeService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';
const FUNCTION_CODE = 'CREATE_EXPENSETYPE';

export async function createExpenseType(userData: Record<string, any>, expenseType: Record<string, any>) {
    try {
        LoggerService.logInfo('Action - createExpenseType - Start');

        const createExpenseTypeResult = await ExpenseTypeService.createExpenseType(userData, expenseType);

        LoggerService.logInfo('Action - createExpenseType - End - Success');

        return ResultModel.newSuccessResult(createExpenseTypeResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createExpenseType - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la création d‘une banque.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
