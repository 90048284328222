import { useState } from 'react';
import dayjs from 'dayjs';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import Popup from '../../../components/ui/Popup';
import DateInput from '../../../components/ui/Inputs/DateInput/DateInput';

const InvoicePaymentDatePopup = ({view, setView, onSubmit}) => {
    const [date, setDate] = useState(dayjs());

    const handleDateChange = (e) => {
        setDate(e);
    }

    const handleSubmit = () => {
        onSubmit(new Date(date));
    }

    return (
        <Popup view={view} setView={setView} width='450px'>
            <div className='px-[10px]'>
                <div className='flex items-start justify-start mb-4'>
                    <ExclamationCircleOutlined style={{fontSize: '20px', color: '#faad14'}}/>
                    <h1 className='ml-5 text-xl font-bold' style={{fontFamily: 'DIN Condensed'}}>
                    Date de paiement de la facture
                    </h1>
                </div>
                <DateInput
                    label={'Date'}
                    onChange={handleDateChange}
                    value={date}
                    error={null}
                    required={true}
                    disabled={null}
                />
                <div className='flex items-center justify-start mt-6'>
                    <button className={`
                    bg-white text-[#262626] border-[#D9D9D9]
                    mr-2 items-center px-4 py-2 font-medium
                    rounded-md border transition-all w-44
                `}>
                    Annuler
                    </button>
                    <button
                        onClick={handleSubmit}
                        className={`
                        bg-gradient-to-br from-[#DC3832] via-[#E36D38] to-[#F0A841]
                        text-white border-white ml-2 items-center px-4 py-2 font-medium
                        rounded-md border transition-all w-44
                        `}
                    >
                    Valider
                    </button>
                </div>
            </div>
        </Popup>
    );
}

export default InvoicePaymentDatePopup;
