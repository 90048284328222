import {ExpenseDao} from '../../dao';
import {ErrorModel, ExpenseModel} from '../../models';
import {SERVICE_CODE} from './_serviceCode';

const FUNCTION_CODE = 'GET_ALL_EXPENSES';

export async function getAllExpenses(
    userData: Record<string, string>,
    before: string,
    after: string,
    optionalProps?: Record<string, string>,
    query?: string
) {
    try {
        const expenses = await ExpenseDao.getAllExpenses(userData, before, after, optionalProps, query);

        const expenseParsed = expenses.results.map((expense: Record<string, any>) => ExpenseModel.parseExpense(expense));

        const metadata = {
            count: expenses.count,
            next: expenses.next,
            previous: expenses.previous
        };

        return {
            metadata,
            data: expenseParsed
        };
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${SERVICE_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des frais',
            error: error
        });
    }
}
