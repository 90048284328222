import {useEffect, useRef} from 'react';
import {standardFormat} from '../utils';

export const handleFilterList = (value, name, filterQuery, setFilterQuery, date = false) => {
    if (date) {
        setFilterQuery({
            ...filterQuery,
            [name ? `${name}_before` : 'before']: value[1] ? standardFormat(value[1]) : null,
            [name ? `${name}_after` : 'after']: value[0] ? standardFormat(value[0]) : null
        });
    }
    else {
        setFilterQuery({
            ...filterQuery,
            [name]: value
        });
    }
};

export const useFilterQuery = (filterQuery, filterList, tableType = null) => {
    const isFirstRender = useRef(true);
    const initialFilterQuery = useRef(filterQuery);

    useEffect(() => {

        // cette condition empêche les requêtes inutile à l'initialisation du composant parent
        if (!isFirstRender.current || JSON.stringify(filterQuery) !== JSON.stringify(initialFilterQuery.current)) {

            isFirstRender.current = false;
    
            if (filterQuery) {
                const queryParams = [];
                    
                Object.entries(filterQuery).forEach(([columnName, filterValue]) => {
                    if (filterValue || filterValue === 0) {
                        queryParams.push(`${columnName}=${filterValue}`);
                    }
                });
            
                const stringQueryParams = queryParams.join('&');
               
                filterList(stringQueryParams, tableType);
            }
        }

        // if (filterQuery) {
        //     const queryParams = [];
                    
        //     Object.entries(filterQuery).forEach(([columnName, filterValue]) => {
        //         if (filterValue || filterValue === 0) {
        //             queryParams.push(`${columnName}=${filterValue}`);
        //         }
        //     });
            
        //     const stringQueryParams = queryParams.join('&');
               
        //     filterList(stringQueryParams, tableType);
        // }
    }, [filterQuery]);
};
