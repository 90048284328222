import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import JSZip from 'jszip';

// import {firstDayMonth, lastDayMonth} from '../../utils';
//import Datepicker from "../../components/ui/Datepicker/Datepicker";
//import SearchInput from "../../components/ui/Inputs/SearchInput";
import {ReferenceActions} from './../../actions'
import {notify, Toast} from '../../components/ui/Toast/Toast';
import Layout from "../../components/layout/Layout";
import Button from "../../components/ui/Button/Button";
import CheckboxInput from "../../components/ui/Inputs/CheckboxInput/CheckboxInput";
import plusIcon from './../../assets/icons/button/plus.png'
import Table from '../../components/ui/Table/Table';
import NewReferencePopUp from './components/NewReferencePopUp';
import AssignatedReferenceBanner from './components/AssignatedReferenceBanner';
import downloadIcon from './../../assets/icons/button/download.png'
import {referencesTableConfig} from './configs/referencesTableConfig';

const ReferencesPage = () => {
    // const initDate = {
    //     startDate: firstDayMonth(new Date()),
    //     endDate: lastDayMonth(new Date())
    // };
    // const [filteredReferences, setFilteredReferences] = useState([]);
    // const [searchValue, setSearchValue] = useState('');
    // const [datePickerDate, setDatePickerDate] = useState(initDate);

    // references data
    const [allReferences, setAllReferences] = useState([]);
    const [referencePaginationMetadata, setReferencePaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [referenceFilterQuery, setReferenceFilterQuery] = useState('');
    
    const [referencesChecked, setReferencesChecked] = useState([]);
    const [showReferencesCheckboxes, setShowReferencesCheckboxes] = useState(false);
    const [referencesToCreateByUser, setReferencesToCreateByUser] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [showNewReferencePopUp, setShowNewReferencePopUp] = useState(false);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const getAllReferences = async (page, activeLoading = true) => {
        if (activeLoading) {
            setLoadingTable(true);
            setAllReferences([]);
        }

        const result = await ReferenceActions.getAllReferences(
            userData,
            `page_size=${referencePaginationMetadata.pageSize}&${referenceFilterQuery}`,
            page
        );

        if (!result.success) {
            notify('Une erreur est survenue. Veuillez réessayer.')
        }
        else {
            setAllReferences(result.data.results);

            setReferencePaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (referencePaginationMetadata.totalItems !== result.metadata.count) {
                setReferencePaginationMetadata(prevState => ({...prevState, totalItems: result.metadata.count}));
            }
        }

        setLoadingTable(false);
    };

    const refreshReferencesData = async () => {
        await getAllReferences(referencePaginationMetadata.currentPage, false);
    }

    useEffect(() => {
        getAllReferences(1);
    }, [referenceFilterQuery]);

    const isReferenceCreated = (reference) => {
        return (!reference.actions &&
                !reference.budget &&
                !reference.contacts.length &&
                !reference.context &&
                !reference.name &&
                !reference.realisation_date &&
                !reference.project_management &&
                !reference.tags.length &&
                !reference.title &&
                !reference.customer_logo &&
                !reference.cocontractors_id)
    }

    useEffect(() => {
        if (allReferences.length > 0) {
            const filteredData = allReferences.filter((reference) => {
                return reference?.owner?.id === userData.userId && isReferenceCreated(reference);
            });

            setReferencesToCreateByUser(filteredData);
        }
    }, [allReferences]);

    // useEffect(() => {
    //     if (searchValue.length > 0) {
    //         const filteredData = allReferences.filter((reference) => {
    //             if (reference.name) {
    //                 return reference.name.toLowerCase().includes(searchValue.toLowerCase());
    //             }
    //         });

    //         setFilteredReferences(filteredData);
    //     }
    //     else {
    //         setDatePickerDate(initDate);
    //         setFilteredReferences(allReferences);
    //     }
    // }, [searchValue]);

    // const handleDatePickerChange = async (e) => {
    //     setDatePickerDate({
    //         startDate: e.startDate ? e.startDate : firstDayMonth(new Date()),
    //         endDate: e.endDate ? e.endDate : lastDayMonth(new Date())
    //     });
    // };

    // useEffect(() => {
    //     const dateStart = Date.parse(datePickerDate.startDate);
    //     const dateEnd = Date.parse(datePickerDate.endDate);

    //     const filteredData = allReferences.filter((reference) => {
    //         const referenceDate = Date.parse(reference.realisation_date);

    //         return referenceDate >= dateStart && referenceDate <= dateEnd || !referenceDate;
    //     });

    //     setSearchValue('');

    //     setFilteredReferences(filteredData);
    // }, [datePickerDate, allReferences]);

    const handleCheckReference = (item) => {
        if (referencesChecked.find((reference) => reference.id === item.id)) {
            const filteredData = referencesChecked.filter((reference) => reference.id !== item.id);
            setReferencesChecked(filteredData);
        }
        else {
            setReferencesChecked([...referencesChecked, item]);
        }
    }

    const handleExportReferences = async () => {
        setLoadingDownload(true);

        if (referencesChecked.length === 0) {
            notify('Aucune référence sélectionnée.');

            return;
        }

        if (referencesChecked.length === 1) {
            const reference = referencesChecked[0];
            const result = await ReferenceActions.downloadReferenceById(userData, reference.id);

            if (!result.success) {
                notify('Une erreur est survenue pendant le téléchargement de la référence. Veuillez réessayer.');

                return;
            }

            const url = URL.createObjectURL(result.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = (reference.name || reference.title || 'reference') + '.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            notify(`La référence a été téléchargée avec succès.`);
            setLoadingDownload(false);

            return;
        }

        const zip = new JSZip();
        const promises = referencesChecked.map(async (reference) => {
            const result = await ReferenceActions.downloadReferenceById(userData, reference.id);

            if (!result.success) {
                notify('Une erreur est survenue pendant le téléchargement de la référence. Veuillez réessayer.');

                return;
            }

            zip.file((reference.name || reference.title || 'reference') + '.pdf', result.data);
        });

        await Promise.all(promises);

        const blob = await zip.generateAsync({ type: 'blob' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'references.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        notify(`Les références ont été téléchargées avec succès.`);
        setLoadingDownload(false);
    };

    const filterReferenceList = async (query) => {
        setReferenceFilterQuery(query);
    }

    // ↓ Table configs
    const tableConfig = referencesTableConfig({
        handleCheckReference,
        showReferencesCheckboxes,
        referencesChecked,
        filterReferenceList,
        userData
    });

    return (
        <Layout>
            <Toast/>
            <div className="px-20 mt-10">
                <div className="flex items-center justify-between mb-10">
                    <p style={{fontFamily: 'DIN Condensed'}} className="text-4xl">
                        Les références
                        <span className="text-xs ml-1 text-[#646464]">
                            ({referencePaginationMetadata.totalItems})
                        </span>
                    </p>
                    <Button icon={plusIcon} content="Nouvelle référence" onClick={() => setShowNewReferencePopUp(true)}/>
                </div>
                {
                    referencesToCreateByUser.length > 0 ?
                        <div className='flex flex-col gap-y-3 mt-7 mb-14'>
                            {
                                referencesToCreateByUser.map(reference => {
                                    return (
                                        <AssignatedReferenceBanner
                                            key={`reference-${reference.id}`}
                                            refreshData={refreshReferencesData}
                                            userData={userData}
                                            reference={reference}
                                        />
                                    )
                                })
                            }
                        </div> : null
                }
                {/* <SearchInput value={searchValue} setValue={setSearchValue} placeholder="Rechercher une référence"/> */}
                <div className="flex items-center justify-end mt-16 mb-6">
                    {/* <Datepicker
                        defaultValue={datePickerDate}
                        onChange={handleDatePickerChange}
                        type='range'
                    /> */}
                    <div className='flex items-center justify-end gap-x-6'>
                        {
                            showReferencesCheckboxes ?
                                <Button
                                    loading={loadingDownload}
                                    disabled={!referencesChecked.length}
                                    onClick={handleExportReferences}
                                    type='dark'
                                    icon={downloadIcon}
                                    content="Exporter"
                                /> : null
                        }
                        <CheckboxInput onChange={() => setShowReferencesCheckboxes(!showReferencesCheckboxes)} label="Sélection multiple"/>
                    </div>
                </div>
                <div className='mb-10'>
                    <Table
                        tableConfig={tableConfig}
                        data={allReferences}
                        loading={loadingTable}
                        paginationMetadata={referencePaginationMetadata}
                        onPageNumberChange={getAllReferences}
                    />
                </div>
            </div>
            <NewReferencePopUp
                view={showNewReferencePopUp}
                setView={setShowNewReferencePopUp}
                userData={userData}
                refreshData={refreshReferencesData}
                references={allReferences}
            />
        </Layout>
    );
}

export default ReferencesPage;
