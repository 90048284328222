import ContainerCard from '../../../components/ui/ContainerCard';
import {frenchFormat} from '../../../utils';

const ExpenseInfoCard = ({startDate, endDate}) => {
    return (
        <div className='my-6 px-14'>
            <ContainerCard title="Informations sur la note de frais" coloredButtonDisplayed={false}>
                <div className='text-[#646464] text-sm'>
                    <div className='flex justify-between mt-3'>
                        <div className='flex items-center justify-center'>
                            <div className='mr-4'>Date de début:</div>
                            <div>{frenchFormat(startDate)}</div>
                        </div>
                        <div className='flex items-center justify-center'>
                            <div className='mr-4'>Date de fin:</div>
                            <div>{frenchFormat(endDate)}</div>
                        </div>
                    </div>
                </div>
            </ContainerCard>
        </div>
    );
};

export default ExpenseInfoCard;
