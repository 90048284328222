import DasTeam from './DasTeam'
import ExternalRessource from './ExternalRessource'
import SupportFunctions from './SupportFunctions'

const RessourceManagementTab = ({
    ressources,
    externalRessources,
    datePickerDate,
    handleDatePickerChange
}) => {

    return (
        <div>
            <DasTeam ressources={ressources}/>
            <SupportFunctions support={ressources.support}/>
            <ExternalRessource
                datePickerDate={datePickerDate}
                handleDatePickerChange={handleDatePickerChange}
                externalRessources={externalRessources}
            />
        </div>
    )
}

export default RessourceManagementTab