import {DashboardFilled, CalendarFilled, CarryOutFilled} from '@ant-design/icons';

import SimpleCard from '../../../../components/ui/SimpleCard/SimpleCard';

const InvoiceCards = ({data, dasKey}) => {

    const DashboardIcon = <DashboardFilled style={{ fontSize: '30px', color:'white' }}/>;
    const CalendarIcon = <CalendarFilled style={{ fontSize: '30px', color: 'white' }}/>;
    const CarryOutIcon = <CarryOutFilled style={{ fontSize: '30px', color: 'white' }}/>;

    const getBillingRate = (key) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            let billedDays = 0;

            for (const k in data.billing[key]) {
                billedDays += data.billing[key][k].billed_days;
            }

            let producedDays = 0;

            for (const k in data.billing[key]) {
                producedDays += data.billing[key][k].produced_days;
            }

            if (!billedDays || !producedDays) {
                return 0;
            }
            else {
                return ((billedDays / producedDays) * 100).toFixed();
            }
        }
    }

    const getBilledDays = (key) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            let billedDays = 0;

            for (const k in data.billing[key]) {
                billedDays += data.billing[key][k].billed_days;
            }

            return billedDays.toFixed();
        }
    }

    const getProducedDays = (key) => {
        if (!data.billing[key]) {
            return 0;
        }
        else {
            let producedDays = 0;

            for (const k in data.billing[key]) {
                producedDays += data.billing[key][k].produced_days;
            }

            return producedDays.toFixed();
        }
    }

    return (
        <div className='flex justify-between mt-6'>
            <SimpleCard
                width='w-[32%]'
                title={`${getBillingRate(dasKey)} %`}
                subTitle="Taux de facturation du DAS"
                iconComponent={DashboardIcon}
            />
            <SimpleCard
                width='w-[32%]'
                title={getBilledDays(dasKey)}
                subTitle="Jours facturés"
                iconComponent={CalendarIcon}
            />
            <SimpleCard
                width='w-[32%]'
                title={getProducedDays(dasKey)}
                subTitle="Jours travaillés"
                iconComponent={CarryOutIcon}
            />
        </div>
    )
}

export default InvoiceCards;
