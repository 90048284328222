import React, {useState} from 'react';
import Empty from 'antd/lib/empty';
import {DeleteOutlined, FileTextOutlined} from '@ant-design/icons';

import AttachmentPopup from '../../popups/attachmentPopup/AttachmentPopup';
import ContainerCard from '../../../../components/ui/ContainerCard';
import ActionsButton from '../../../../components/ui/ActionsButton';
import {AttachmentActions, CustomerActions} from '../../../../actions';
import {capitalize, formatBytes} from '../../../../utils';
import {notify} from '../../../../components/ui/Toast/Toast';
import ImagePopup from '../../popups/attachmentPopup/ImagePopup';
import CustomerNotesPopup from '../customersDetailComponents/CustomerNotesPopup';
import Popup from '../../../../components/ui/Popup/Popup';

const NotesAndDocuments = ({
    userData,
    customerData,
    attachmentsData,
    refreshAttachmentsData,
    refreshCustomerData,
    buttonsDisabled,
    setButtonsDisabled
}) => {
    const [deleteNoteLoading, setDeleteNoteLoading] = useState(false);
    const [downloadAttachmentLoading, setDownloadAttachmentLoading] = useState(false);
    const [deleteAttachmentLoading, setDeleteAttachmentLoading] = useState(false);
    const [attachmentPopupView, setAttachmentPopupView] = useState(false);
    const [notesPopupView, setNotesPopupView] = useState(false);
    const [picturePopupVisible, setPicturePopupVisible] = useState(false);
    const [imageClickedUrl, setImageClickedUrl] = useState('');

    // note functions
    const handleDeleteNote = async () => {
        setDeleteNoteLoading(true);

        const result = await CustomerActions.updateCustomer(userData , { notes: '' }, customerData.id);

        if (result.success) {
            await refreshCustomerData();
            notify('La note à été supprimée');
        }
        else {
            notify('Une erreur est survenue lors de la suppression de la note');
        }

        setDeleteNoteLoading(false);
    }

    // attachment functions
    const handleButtonNewAttachmentClick = () => {
        if (!attachmentBlockedAction) {
            setAttachmentPopupView(true);
        }
    }

    const handleAttachmentItemClick = (url) => {
        setPicturePopupVisible(true)
        setImageClickedUrl(url)
    }

    const handleDownloadButtonClick = async (id, name) => {
        setButtonsDisabled(true);
        setDownloadAttachmentLoading(id);

        const downloadAttachment = await AttachmentActions.downloadAttachment(userData, id);

        if (!downloadAttachment.success) {
            notify('Votre document n\'a pas pu être téléchargé');
            setDownloadAttachmentLoading(null);

            return;
        }

        const blob = new Blob([downloadAttachment.data]);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();

        setDownloadAttachmentLoading(null);
        setButtonsDisabled(false);
    }

    const handleDeleteButtonClick = async (id) => {
        setButtonsDisabled(true);
        setDeleteAttachmentLoading(id);

        const deleteAttachment = await AttachmentActions.deleteAttachment(userData, id);

        if (deleteAttachment.success) {
            await refreshAttachmentsData();
            notify('Le document a bien été supprimé');
        }
        else {
            notify('Une erreur est survenue lors de la suppression du document');
        }

        setDeleteAttachmentLoading(null);
        setButtonsDisabled(false);
    }

    const isImage = (filename) => {
        const regex = /\.(jpg|jpeg|png|gif|bmp)$/i;

        return regex.test(filename);
    }

    // À checker si c'est bien le statut 3 qui est considéré comme terminé et donc non modifiable (voir page details expense)
    const attachmentBlockedAction = attachmentsData.status === 3;

    return (
        customerData ?
            <div className='flex items-start justify-center'>
                <ContainerCard
                    title='Notes'
                    buttonContent={customerData?.notes ? 'Modifier la note' : 'Nouvelle note'}
                    onButtonClick={() => setNotesPopupView(true)}
                    width='w-1/2'
                    height='min-h-[320px]'
                    margin='mr-5'
                >
                    {
                        customerData?.notes  ?
                            customerData.notes
                            : <div className='flex items-center justify-center w-full h-full'>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                            </div>
                    }
                    {
                        customerData?.notes ?
                            <div className="absolute bottom-3 right-3 ">
                                <ActionsButton
                                    onClick={handleDeleteNote}
                                    type='delete'
                                    isLoading={deleteNoteLoading}
                                    deleteConfirmationQuestion='Souhaitez-vous supprimer cette note ?'
                                />
                            </div>
                            : null
                    }
                </ContainerCard>
                <ContainerCard
                    title='Documents'
                    buttonContent='Nouveau document'
                    coloredButtonDisabled={attachmentBlockedAction}
                    onButtonClick={handleButtonNewAttachmentClick}
                    width='w-1/2'
                    height='min-h-[320px]'
                    margin='ml-5'
                >
                    {
                        attachmentsData.length > 0 ?
                            attachmentsData.map((attachment) => {
                                return (
                                    <React.Fragment key={attachment.id}>
                                        <div
                                            className={`
                                        flex items-center justify-center w-full py-7
                                        shadow-none hover:shadow-[0_2px_4px_rgba(0,0,0,0.25)] transition-all px-5
                                    `}
                                        >
                                            <div
                                                onClick={() => handleAttachmentItemClick(attachment.url)}
                                                className="flex items-center justify-start w-full hover:cursor-pointer"
                                            >
                                                {isImage(attachment.name) ? (
                                                    <img src={attachment.url} className='object-cover w-12 h-12 mr-4'/>
                                                ) : (
                                                    <FileTextOutlined className='mr-4' style={{fontSize: '48px' ,color: '#e5754b'}}/>
                                                )}
                                                <div className='w-[40%]'>
                                                    {
                                                        attachment.name.length > 20 ?
                                                            `${capitalize(attachment.name.split('.')[0].slice(0, 15))}(..).${attachment.name.split('.')[1]}` :
                                                            capitalize(attachment.name)
                                                    }
                                                </div>
                                                <div className='w-[30%] flex justify-center items-center'>
                                                    <div className='w-20'>
                                                        {formatBytes(attachment.size, 0)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[30%] flex justify-end items-center'>
                                                <ActionsButton
                                                    type='download'
                                                    onClick={() => handleDownloadButtonClick(attachment.id, attachment.name)}
                                                    isLoading={downloadAttachmentLoading === attachment.id}
                                                    disabled={buttonsDisabled}
                                                />
                                                <div className='px-3'/>
                                                {
                                                    attachmentBlockedAction ?
                                                        <DeleteOutlined style={{fontSize: '18px' ,color: '#dfe4ea'}}/> :
                                                        <ActionsButton
                                                            type='delete'
                                                            onClick={() => handleDeleteButtonClick(attachment.id)}
                                                            isLoading={deleteAttachmentLoading === attachment.id}
                                                            disabled={buttonsDisabled}
                                                            deleteConfirmationQuestion='Souhaitez-vous supprimer ce document ?'
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <hr className='border-[#f0f0f0] mx-5 mt-[-1px]'/>
                                    </React.Fragment>
                                );
                            }) :
                            <div className='flex items-center justify-center w-full h-full'>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                            </div>
                    }
                </ContainerCard>
                <Popup
                    view={attachmentPopupView}
                    setView={setAttachmentPopupView}
                    width={'1098px'}
                >
                    <AttachmentPopup
                        userData={userData}
                        customerData={customerData}
                        setView={setAttachmentPopupView}
                        refreshData={refreshAttachmentsData}
                    />
                </Popup>
                <Popup
                    view={notesPopupView}
                    setView={setNotesPopupView}
                    width="1098px"
                >
                    <CustomerNotesPopup
                        userData={userData}
                        data={customerData}
                        customerId={customerData.id}
                        setView={setNotesPopupView}
                        refreshCustomerData={refreshCustomerData}
                    />
                </Popup>
                <ImagePopup
                    view={picturePopupVisible}
                    setView={setPicturePopupVisible}
                    url={imageClickedUrl}
                />
            </div>
            : null
    );
}

export default NotesAndDocuments;
