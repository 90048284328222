import {ACTION_CODE} from './_actionCode';
import {TrainingAttendancesService} from '../../services';
import {ErrorModel, ResultModel} from '../../models';
import {LoggerService} from '../../services';

const FUNCTION_CODE = 'UPDATE_TRAINING_ATTENDANCE';

export async function updateTrainingAttendance(
    userData: Record<string, string>,
    updatedTrainingAttendance: Record<string, any>,
    id: string
) {
    try {

        LoggerService.logInfo('Action - updateTrainingAttendance - Start');

        const updateTrainingAttendanceResult = await TrainingAttendancesService.updateTrainingAttendance(userData, updatedTrainingAttendance, id);

        LoggerService.logInfo('Action - updateTrainingAttendance - End - Success');

        return ResultModel.newSuccessResult(updateTrainingAttendanceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateTrainingAttendance - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
