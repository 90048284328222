import {Tooltip} from 'antd';

import {frenchFormat} from '../../../utils';
import ActionsButton from '../../../components/ui/ActionsButton';
import Badge from '../../../components/ui/Badge';
import checkIcon from '../../../assets/icons/table/check-rounded.png';

export const invoicesConfig = (handleRedirect, key) => {
    let actionTitle;

    switch (key) {
        case 'invoices_to_validate':
            actionTitle = 'Actions';
            break;
        case 'invoices_to_pay':
            actionTitle = 'Pdf';
            break;
        default:
            actionTitle = 'Details';
            break;
    }

    const getInvoiceStatus = (data) => {
        if (data.credit) return {label: 'Avoir', color: '#f9d828', textColor: 'black'};

        switch (data.status) {
            case 0:
                return {label: 'Prévisionnelle', color: null, textColor: 'white'};
            case 50:
                return {label: 'À émettre', color: '#7FFFD4', textColor: 'white'};
            case 100:
                return {label: 'À valider', color: '#f0a841', textColor: 'white'};
            case 200:
                return {label: 'Validée', color: '#e36d38', textColor: 'white'};
            case 300:
                return {label: 'Émise', value: 300, color: '#ced95f', textColor: 'white'}
            case 400:
                return {label: 'Partiellement Réouvrée', color: '#ced95f', textColor: 'white'};
            case 500:
                return {label: 'Payée', color: '#ced95f', textColor: 'white'};
            case 600:
                return {label: 'Annulée', color: '#212121', textColor: 'white'};
            default:
                return {status: 'Indéfini', color: '#6c757d', textColor: 'white'};
        }
    };

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: false,
            columnConfig: [
                {
                    label: 'Numéro facture',
                    value: 'id',
                    width: 'w-[7%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center w-full">
                                {item.id}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Affaire',
                    value: 'deal.short_name',
                    width: 'w-[18%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {item.deal.short_name}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        const statusData = getInvoiceStatus(item);

                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={statusData.label} color={statusData.color} textColor={statusData.textColor}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Resp. Production',
                    value: 'deal.production_lead',
                    width: 'w-[10%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <Badge content={[item.deal.production_lead]} type='rounded'/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Facturation',
                    value: 'billing_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.billing_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Echéance',
                    value: 'due_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {frenchFormat(item.due_date)}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Paiement',
                    value: 'payment_date',
                    width: 'w-[9%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {item.payment_date ? frenchFormat(item.payment_date) : "-"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net HT',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {(+item.billable + +item.expenses).toFixed(2) + " €"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Total net TVA',
                    value: null,
                    width: 'w-[9%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                {(+item.billable + +item.billable * (+item.billable_vat_rate / 100) + +item.expenses + +item.expenses * (+item.expenses_vat_rate / 100) ).toFixed(2) + " €"}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: actionTitle,
                    value: null,
                    width: 'w-[7%]',
                    sort: false,
                    component: (item) => {
                        if (key === 'invoices_to_validate') {
                            return (
                                <div className="flex flex-row items-center justify-center">
                                    <div className='flex items-center justify-between w-full px-2'>
                                        <ActionsButton
                                            type="overview"
                                            onClick={() => handleRedirect({type: 'invoice', key: 'overview'}, item)}
                                        />
                                        <Tooltip title="Valider">
                                            <img
                                                onClick={() => handleRedirect({type: 'invoice', key: 'validate'}, item)}
                                                className='w-5 h-5 cursor-pointer'
                                                src={checkIcon}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        }
                        else if (key === 'invoices_to_pay') {
                            return (
                                <div className='flex items-center justify-between w-full px-2'>
                                    <ActionsButton
                                        type="overview"
                                        onClick={() => handleRedirect({type: 'invoice', key: 'overview'}, item)}
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="flex flex-row items-center justify-center">
                                    <ActionsButton
                                        type="details"
                                        isLoading={false}
                                        onClick={() => handleRedirect({type: 'invoice', key}, item)}
                                    />
                                </div>
                            );
                        }
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
