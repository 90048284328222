export const grossMargin = {

    from(grossMarginResult) {

        const getGlobalData = () => {
            let dasS = {};

            if (!grossMarginResult.global) {
                return dasS;
            }
            else {
                for (const key in grossMarginResult.global) {
                    dasS[key] = {
                        "revenue": grossMarginResult.global?.[key]?.revenue ?? 0,
                        "contractors": grossMarginResult.global?.[key]?.contractors ?? 0,
                        "expenses": grossMarginResult.global?.[key]?.expenses ?? 0,
                        "procurements": grossMarginResult.global?.[key]?.procurements ?? 0,
                        "gross_margin": grossMarginResult.global?.[key]?.gross_margin ?? 0
                    }
                }

                return dasS;
            }
        }

        const getByStatusData = () => {
            let dasS = {};

            if (!grossMarginResult.by_status) {
                return dasS;
            }
            else {
                for (const key in grossMarginResult.by_status) {
                    dasS[key] = {
                        "junior": grossMarginResult.by_status?.[key]?.junior ?? 0,
                        "confirmed": grossMarginResult.by_status?.[key]?.confirmed ?? 0,
                        "senior": grossMarginResult.by_status?.[key]?.senior ?? 0
                    }
                }

                return dasS;
            }
        }

        return (
            {
                "global": getGlobalData(),
                "by_status": getByStatusData()
            }
        )
    }
}
